import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { fetchLocalities } from "../common/lookupSlice";
import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  Breadcrumbs,
  Link,
  Snackbar
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { termsCondBelongingTypes } from "../common/constants";

let errorMsg;

function CompanyTermsConditionsDetailForm(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { id } = useParams();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [comapnyTermConData, setComapnyTermConData] = useState({});
  let { termsConditions, declaration, termsConditionsType } = comapnyTermConData;
  const [isError, setIsError] = useState(false);
  const { handleSubmit, errors, register } = useForm({});
  const [create, setCreate] = React.useState({});
  const [inputValue, setInputValue] = React.useState('');
  const [saved, setSaved] = React.useState(false);
  // var queryStringData = window.location.search;
  // var chars = queryStringData.split('=');
  // let isPm = (chars[1]) ? chars[1].toUpperCase() === "YES" : false;
  // const [isPrimaryData, setIsPrimary] = useState(isPm);

  const fetchData = async (id) => {
    if (id) {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/orgtermscond/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setComapnyTermConData(result.data);
        setInputValue(result.data && result.data.termsConditionsType)
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
    }
  }

  useEffect(() => {
    fetchData(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (comapnyTermConData) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    let isContinue = true;
    comapnyTermConData.id = id;
    //comapnyTermConData.state = selectedState.length>0 ? selectedState[0].code:'';

    if (isContinue) {
      try {
        let newTermCond;
        if (id) {
          await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/orgtermscond/${id}`,
            comapnyTermConData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          newTermCond = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/orgtermscond`,
            comapnyTermConData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        if (props.isPopup) {
          props.success(newTermCond.data.comapnyTermConData);
        } else {
          dispatch(fetchLocalities());
          setIsProcessing(false);
          if (props.job) {
            props.cancel();
            props.onCreate(newTermCond && newTermCond.data && newTermCond.data.comapnyTermConData)
          }
          else {
            setCreate({
              success: true
            });
            setSaved(true);
          }
          setTimeout(()=>{
            window.location.href = `/orgconfigtermscondlist`;
          }, 2000);
        }

      } catch (err) {
        console.log("err.response.data.message", err.response.data.message);
        errorMsg = (err.response.data.message && err.response.data.message.length > 0 && err.response.data.message[1] === "fullName must be unique") ? "Terms Conditions Type has to be unique" : err.response.data.message;
        setIsError(true);
        setIsProcessing(false);
        setSaved(false);
      }
    } else {
      setIsProcessing(false);
    }
  };

  let cancelClick = () => {
    window.location.href = `/orgconfigtermscondlist`;
  }

  return (
    <div>
      {!props.isPopup && <Grid container>
        <Grid item container xs={12} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/configrationhome")}>
                Configuration
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/orgconfigtermscondlist")}>
                Terms and Conditions
              </Link>
              <Typography component="span" color="textPrimary">
                {id ? "Edit Terms and Conditions" : "New Terms and Conditions"}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="supplier-form">
          <Grid container spacing={2}>
            <Grid item container md={12} xs={12} spacing={1}>
            </Grid>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={3} xs={3}>
                  <Typography className="supplier-form-label" variant="caption">Terms and Conditions Belonging Type *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        setComapnyTermConData({ ...comapnyTermConData, termsConditionsType: newValue.label })
                        // setInputValue(newValue.label)
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    fullWidth
                    disabled={(id) ? true : false}
                    value={termsConditionsType}
                    inputValue={inputValue}
                    options={termsCondBelongingTypes}
                    getOptionLabel={(option) => (typeof option.label === 'string' ? option.label : '')}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      size="small"
                      name={`termsConditionsType`}
                      error={Boolean(errors.termsConditionsType)}
                      inputRef={register({
                        required: "Select Terms and Conditions Belonging Type"
                      })}
                    />}
                    filterOptions={(options, termCond) =>
                      options.filter((option) =>
                        typeof option.label === 'string' &&
                        option.label.toLowerCase().includes(termCond.inputValue.toLowerCase())
                      )
                    }
                  />
                  <FormHelperText error={Boolean(errors.termsConditionsType)}>
                    {errors.termsConditionsType && errors.termsConditionsType.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={3} xs={3}>
                  <Typography className="supplier-form-label" variant="caption">Terms and Conditions *</Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    error={Boolean(errors.termsConditions)}
                    inputRef={register({
                      required: "Enter the Terms and Conditions",
                      minLength: {
                        value: 50,
                        message: "Minimum 50 characters required",
                      },
                      maxLength: {
                        value: 1000,
                        message: "Maximum 1000 characters required",
                      },
                    })}
                    value={termsConditions}
                    onChange={e => setComapnyTermConData({ ...comapnyTermConData, termsConditions: e.target.value })}
                    size="small"
                    name="termsConditions"
                    placeholder="Ex : I agree to the Terms and Conditions, acknowledge the Privacy Policy, and accept the Refund Policy."
                    multiline
                    maxRows={8}
                    minRows={6}

                  />
                  <FormHelperText error>
                    {errors.termsConditions && errors.termsConditions.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={3} xs={3}>
                  <Typography className="supplier-form-label" variant="caption">Declarations *</Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    error={Boolean(errors.declaration)}
                    inputRef={register({
                      required: "Enter the Declarations",
                      minLength: {
                        value: 30,
                        message: "Minimum 30 characters required",
                      },
                      maxLength: {
                        value: 400,
                        message: "Maximum 400 characters required",
                      },
                    })}
                    value={declaration}
                    onChange={e => setComapnyTermConData({ ...comapnyTermConData, declaration: e.target.value })}
                    size="small"
                    name="declaration"
                    placeholder="Ex : I certify the information provided is accurate."
                    multiline
                    maxRows={6}
                    minRows={4}

                  />
                  <FormHelperText error>
                    {errors.declaration && errors.declaration.message}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid
            item
            container
            xs={6}
            justifyContent={isProcessing ? "flex-end" : "flex-start"}
          >
            {isProcessing ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}

            {isError ? (
              <Grid item>
                <Typography error>
                  <FormHelperText error>{errorMsg}</FormHelperText>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button size="small" type="submit"
                variant="contained" color="primary" disabled={saved}>
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => props.job ? props.cancel() : cancelClick()}
                color="secondary"
                size="small"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" name="isDirty" value="true" ref={register()} />
        <input type="hidden" name="id" ref={register()} />
      </form>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id ? "Term and Condtions updated successfully"
            : "Term and Condtions created successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default CompanyTermsConditionsDetailForm;
