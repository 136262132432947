import { createSlice } from "@reduxjs/toolkit";

export const confirmationDialogSlice = createSlice({
  name: "confirmationDialog",
  initialState: {
    modalProps: {
      isModalOpen: false,
      dialogText: "",
      secondayBtnText: "",
      primaryBtnText: "",
      handleCloseModal: null,
      isProcessing: false,
    },
  },
  reducers: {
    openModal: (state, action) => {
      state.modalProps.isModalOpen = true;
      state.modalProps.dialogText = action.payload.dialogText;
      state.modalProps.secondayBtnText = action.payload.secondayBtnText;
      state.modalProps.primaryBtnText = action.payload.primaryBtnText;
      state.modalProps.handleCloseModal = action.payload.handleCloseModal;
    },
    modalInProcessing: (state) => {
      state.modalProps.isProcessing = true;
    },
    closeModal: (state) => {
      state.modalProps.isModalOpen = false;
      state.modalProps.handleCloseModal = null;
      state.modalProps.isProcessing = false;
    },
  },
});

export const {
  openModal,
  closeModal,
  modalInProcessing,
} = confirmationDialogSlice.actions;

export const modalProps = (state) => state.confirmationDialog.modalProps;

export default confirmationDialogSlice.reducer;
