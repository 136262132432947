import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Card,
  Collapse
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const PO = (props) => {
  const location = useLocation();
  const customerData = location.state;
  const CustomerId = customerData && customerData.id;
  const [open, setOpen] = React.useState([]);
  const [poData, setPoData] = useState([]);
  const [poInnerData, setPoInnerData] = useState([]);
  const [poNo, setPoNo] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPoLoading, setIsPoLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const loadData = async () => {
    setIsLoading(true);
    try {
      const poResult = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/pos/openOrders/${CustomerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const validPoData = poResult.data.openOrders ? poResult.data.openOrders : [];
      const poData = validPoData ? validPoData.length : 0;
      const poCollapse = [];
      for (let step = 0; step < poData; step++) {
        poCollapse[step] = false;
      }
      setOpen(poCollapse)
      setPoData(validPoData);
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.message !== `Customer Purchase Orders Not found`) {
        props.setIsError(true);
      }
    }
    setIsLoading(false);
    setRefresh(false);
  };

  const loadPoData = async () => {
    setIsPoLoading(true)
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/purchaseOrders/${poNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPoInnerData(result.data.purchaseOrders && result.data.purchaseOrders.lineItems
        ? result.data.purchaseOrders.lineItems : [])

    } catch (err) {
      setPoInnerData([]);
    }
    setIsPoLoading(false)
  }
  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(poNo){
      loadPoData();
    }
  }, [poNo]); // eslint-disable-line react-hooks/exhaustive-deps



  const handlePoClick = async (poNum) => {
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/pos/clone/${poNum}/?locationId=${props.store}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const productList = result && result.data.clonedPurchaseOrder && result.data.clonedPurchaseOrder.lineItems ? result.data.clonedPurchaseOrder.lineItems : [];
    const products = productList.filter(x=>x.product !==null && (x.product && x.product.inventories
      && x.product.inventories[0] && x.product.inventories[0].openingQty>0))
    const sales = products.map(x => {
      const avail =  x.product && x.product.inventories
      && x.product.inventories[0] ? x.product.inventories[0].openingQty : 0
      return {
        "productName": x.product && x.product.productName,
        "productId": x.product && x.product.id,
        "HSNCode": x.product && x.product.HSNCode,
        "productData": JSON.stringify({CGST:x.product && x.product.CGST,IGST:x.product && x.product.IGST,SGST:x.product && x.product.SGST}),
        "qty": x.qty && x.qty>avail ? avail:x.qty,
        "rate": x.negotiatedPrice,
        "disc": x.discountPct?x.discountPct:"0.00",
        "avail": avail,
        "uom": x.product && x.product.uom ? x.product.uom :"OTH",
        "isQtyDecimal": x.product && x.product.isQtyDecimal ? true :false,
      }
    })
    if (props.setSelectedPo) {
      props.remove();
      props.setShowPo(false)
      props.setSelectedPo({ poNum: poNum, products: sales })
    }
  }

  return (
    <div>
      <div>
        <Card>
          <TableContainer component={Paper} >
            <Table style={{ paddingTop: 10 }} size="small">
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                  <TableBody>
                    {poData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={props.modal ? 6 : 7} align="center">
                          <Typography variant="subtitle1">
                          Customer Purchase Orders not found
                      </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                        poData.map((row, index) => (
                          <React.Fragment>
                            <TableRow
                              style={{ cursor: props.modal ? "pointer" : "" }}
                              key={row.id}
                              hover={true}
                              onClick={(e) =>
                                props.onRowSelect && props.onRowSelect(row)
                              }
                            >
                              <TableCell>
                                <IconButton aria-label="expand row" size="small" onClick={() => {
                                  const clone = _.cloneDeep(open)
                                  clone.forEach((x, y) => {
                                    if (y === index) {
                                      clone[y] = !clone[y]
                                    }
                                    else {
                                      clone[y] = false
                                    }
                                  })
                                  setPoNo(row.poNum)
                                  setOpen(clone)
                                }
                                }>
                                  {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                              </TableCell>
                              <TableCell component="th" scope="row" >
                                {row.poNum}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={open[index]} timeout="auto" unmountOnExit>
                                  <Box mt={2}>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                      onClick={() => handlePoClick(row.poNum)}
                                      disabled={poInnerData.length === 0}>Select CO</Button>
                                  </Box>
                                  <Box mt={2} mb={2}>
                                    <TableContainer component={Paper} >
                                      <Table style={{ paddingTop: 10 }} size="small">
                                        <TableHead>
                                          <TableRow>
                                            <StyledHeaderCell>Product</StyledHeaderCell>
                                            <StyledHeaderCell align="left">Product Rate</StyledHeaderCell>
                                            <StyledHeaderCell align="left">Order Quantity</StyledHeaderCell>
                                            <StyledHeaderCell align="left">Fulfilled Quantity</StyledHeaderCell>
                                          </TableRow>
                                        </TableHead>
                                        {isPoLoading ? (
                                          <TableBody>
                                            <TableRow>
                                              <TableCell colSpan={7} align="center">
                                                <CircularProgress />
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        ) : (
                                            <TableBody>
                                              {poInnerData.length === 0 ? (
                                                <TableRow>
                                                  <TableCell colSpan={props.modal ? 6 : 7} align="center">
                                                    <Typography variant="subtitle1">
                                                      Nothing Purchased.
                      </Typography>
                                                  </TableCell>
                                                </TableRow>
                                              ) : (
                                                  poInnerData.map((row, index) => (
                                                    <TableRow
                                                      style={{ cursor: props.modal ? "pointer" : "" }}
                                                      key={row.id}
                                                      hover={true}
                                                      onClick={(e) =>
                                                        props.onRowSelect && props.onRowSelect(row)
                                                      }
                                                    >
                                                      <TableCell component="th" scope="row">
                                                        {row.product && row.product.productName}
                                                      </TableCell>
                                                      <TableCell align="left">
                                                        {row.negotiatedPrice}
                                                      </TableCell>
                                                      <TableCell align="left">
                                                        {row.orderQty}
                                                      </TableCell>
                                                      <TableCell align="left">
                                                        {row.fulfilledQty}
                                                      </TableCell>
                                                    </TableRow>
                                                  ))
                                                )}
                                            </TableBody>
                                          )}
                                      </Table>
                                    </TableContainer>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow></React.Fragment>
                        ))
                      )}
                  </TableBody>
                )}
            </Table>
          </TableContainer>

        </Card>
      </div>
    </div>
  );
};

export default PO;
