import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import axios from "axios";
import PrintIcon from '@material-ui/icons/Print';
import MailIcon from '@material-ui/icons/Mail';
import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import MaterialRequestForm from "./materialRequestForm";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";
import { useReactToPrint } from "react-to-print";
import { formatDate } from '../common/util';
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import { currentUser } from "../login/loginSlice";
import { bankDetails, termsAndConditions } from "../common/lookupSlice"
// import "../customers.css";
import { useSelector } from "react-redux";
import CompToPrintEstimate from '../jobs/CompOrderToPrintEstimate';
import _ from "lodash";

const PurchaseMaterialRequest = (props) => {
  const pageSize = 10;
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const orgBankDetails = useSelector(bankDetails);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const isAdminOrAccountant = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role => (role.name === "ADMIN") || (role.name === "ACCOUNTANT"));
  const [count, setCount] = useState();
  const [materialReqForm, setMaterialReqForm] = useState({ open: false });
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const componentRefMaterialReq = useRef();
  const [materialReq, setMaterialReq] = useState({});
  const token = localStorage.getItem("authToken");
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSendInvoice, setSendInvoice] = useState(false);
  const [popHeader, setPopHeader] = useState();
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [emailSendData, setEmailSendData] = useState({
    emailId: '',
    emailSubject: ''
  });
  let { emailId, emailSubject } = emailSendData;
  let isPrintDisable = true;

  const onClickPo = async (mrNum) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/materialrequest/${mrNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      let modifiedContacts = result.data.materialRequest.lineItems.map((obj) => {
        let findOpenQty = (obj.product && obj.product.inventories && obj.product.inventories.length > 0) ? obj.product.inventories.filter((invData) => {
          return (invData && invData.location && invData.location.type && invData.location.type.code && invData.location.type.code === "CORPORATE") ? invData : false;
        }) : [];
        obj.availableQty = (findOpenQty && findOpenQty.length > 0) ? findOpenQty[0].openingQty : 0;
        obj.contactId = obj.id;
        obj.negotiatedPrice = obj.negotiatedPrice && (Math.round(obj.negotiatedPrice * 100) / 100).toFixed(2)
        obj.productCode = obj.product && obj.product.productName
        obj.uom = obj.product && obj.product.uom
        delete obj["id"];
        return obj;
      });
      result.data.materialRequest.lineItems = modifiedContacts;
      setMaterialReqForm({
        ...materialReqForm,
        data: result.data.materialRequest,
        mode: "U",
        open: true,
      });
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/materialrequest`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.materialRequest);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  // useEffect(() => {
  //   loadData();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [query, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (refresh) {
  //     loadData();
  //   }
  // }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps


  const handlePrint = useReactToPrint({
    content: () => componentRefMaterialReq.current,
  });


  const setPoNumAction = async (id) => {
    try {
      // let ser = [];
      const getData = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/materialrequest/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      // ser = (getData && getData.data && getData.data.materialRequest && getData.data.materialRequest.lineItems && getData.data.materialRequest.lineItems.length > 0) ? getData.data.materialRequest.lineItems : [];
      let orgData = (getData && getData.data && getData.data.materialRequest && getData.data.materialRequest.org) ? getData.data.materialRequest.org : {};
      const lineItemData = getData && getData.data && getData.data.materialRequest && getData.data.materialRequest.lineItems ? getData.data.materialRequest.lineItems.map((item, key) => {
        return {
          "lineNo": key + 1,
          "productName": item.product && item.product.productName,
          "HSNCode": item.product && item.product.HSNCode,
          "uom": item.product && item.product.uom,
          "qty": item.requiredQty,
          "purchasePrice": item.negotiatedPrice,
          "amount": item.amount
        }
      }) : []

      let estimateData = {
        "OrderNo": id,
        "invoiceNum": id,
        "invoiceDate": (getData && getData.data && getData.data.materialRequest.requireDate) ? getData.data.materialRequest.requireDate : new Date(),
        "org": orgData,
        "lineItems": lineItemData,
        "isSupplier": true
      }
      // return estimateData;
      setMaterialReq({ ...materialReq, ...estimateData });
      handlePrint();
    }
    catch (err) {
      console.log(err);
    }
  }



  const openEmailInovice = async (mrNum) => {
    try {
      // let ser = [];
      const getData = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/materialrequest/${mrNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      // ser = (getData && getData.data && getData.data.materialRequest && getData.data.materialRequest.lineItems && getData.data.materialRequest.lineItems.length > 0) ? getData.data.materialRequest.lineItems : [];
      let orgData = (getData && getData.data && getData.data.materialRequest && getData.data.materialRequest.org) ? getData.data.materialRequest.org : {};


      const lineItemData = getData && getData.data && getData.data.materialRequest && getData.data.materialRequest.lineItems ? getData.data.materialRequest.lineItems.map((item, key) => {
        return {
          "lineNo": key + 1,
          "productName": item.product && item.product.productName,
          "HSNCode": item.product && item.product.HSNCode,
          "uom": item.product && item.product.uom,
          "qty": item.requiredQty,
          "purchasePrice": item.negotiatedPrice,
          "amount": item.amount
        }
      }) : []

      let estimateData = {
        "OrderNo": mrNum,
        "invoiceNum": mrNum,
        "invoiceDate": (getData && getData.data && getData.data.materialRequest.requireDate) ? getData.data.materialRequest.requireDate : new Date(),
        "org": orgData,
        "lineItems": lineItemData,
        "isSupplier": true
      }

      // return estimateData;
      setMaterialReq({ ...materialReq, ...estimateData });
      setSendInvoice(true);
      setPopHeader("Mail Material Requests");
      setEmailSendData({
        emailSubject: "Mail Material Requests",
        emailId: ""
      })
    }
    catch (err) {
      console.log(err);
    }
  }

  const onCloseSendInvoice = () => {
    setSendInvoice(false);
    setEmailSendData({ emailId: '', emailSubject: '' })
  }
  const onSendEmailInvoice = async () => {
    let isEmail = false;
    let isSubject = (emailSubject && emailSubject.length > 1) ? true : false;
    if (emailId && emailId.length > 0) {
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds, (data) => {
        if (!mailformat.test(String(data).toLowerCase())) {
          isEmail = false;
        }
      })
    }
    if (isEmail && isSubject) {
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData = () => {
        return (
          <CompToPrintEstimate ref={componentRefMaterialReq} customerInv={materialReq} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
        )
      }
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to', emailId)
      bodyFormData.append('subject', emailSubject)
      bodyFormData.append('invoice', renderToString(invoiceBodyData()))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
        bodyFormData,
        config
      );
      if (result.status === 200) {
        setIsSuccess(true)
        setSendInvoice(false);
        setEmailSendData({ emailId: '' })
      }
    } else {
      if (!isEmail) {
        setEmailHelperText(true);
      }
      if (!isSubject) {
        setEmailSubjectHelperText(true);
      }
    }
  }
  return (
    <div>
      {props.modal ? null : (
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/purchasehome")}>
                  Purchase
                </Link>
                <Typography component="span" color="textPrimary">
                  Material Requests
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1} style={{ marginTop: '2%' }}>
            <Grid item xs={12} md={7}>
              <Typography>
                Material Requests for Purchase
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} >
              {isAdminOrAccountant && <Button color="primary" variant="contained" style={{ float: 'right', marginRight: "15px" }}
                onClick={() => {
                  setMaterialReqForm({ ...materialReqForm, data: {}, mode: "C", open: true });
                }}> Add New </Button>}

            </Grid>
          </Grid>
        </Grid>
      )}
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Material Request No.</StyledHeaderCell>
                <StyledHeaderCell align="left">Created Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Order Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Count</StyledHeaderCell>
                {/* <StyledHeaderCell align="center">Print</StyledHeaderCell> */}
                {/* <StyledHeaderCell align="center">Email</StyledHeaderCell> */}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => {
                    return (
                      <TableRow
                        style={{ cursor: props.modal ? "pointer" : "" }}
                        key={row.id}
                        hover={true}
                        onClick={(e) =>
                          props.onRowSelect && props.onRowSelect(row)
                        }
                      >
                        <TableCell align="left" className="underLine" style={{ cursor: "pointer" }}>
                          <Link onClick={() => {
                            onClickPo(row.mrNum);
                          }}>{row.mrNum}
                          </Link></TableCell>
                        <TableCell align="left">{row.createdAt ? formatDate(row.createdAt) : ''}</TableCell>
                        <TableCell align="left">{row.requireDate ? formatDate(row.requireDate) : ''}</TableCell>
                        <TableCell align="left">&nbsp;{row.lineItems && row.lineItems.length > 0 ? row.lineItems.length : '0'}</TableCell>
                        {!isPrintDisable && <><TableCell align="center">
                          <span onClick={() => { setPoNumAction(row.mrNum) }}>
                            <PrintIcon />
                          </span>
                        </TableCell>
                          <TableCell align="center">
                            <span onClick={() => openEmailInovice(row.mrNum)}>
                              <MailIcon />
                            </span>
                          </TableCell></>}
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Box display="none">
        <CompToPrintEstimate ref={componentRefMaterialReq} customerInv={materialReq} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={materialReqForm.open}
        onClose={() => {
          setMaterialReqForm({ ...materialReqForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={10} alignItems="center">
              <Grid item>
                {materialReqForm.mode === "C"
                  ? "Add Material Request" : "Edit Material Request"}
              </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    setMaterialReqForm({ ...materialReqForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <MaterialRequestForm
            form={materialReqForm}
            cancel={() => {
              setMaterialReqForm({ ...materialReqForm, open: false });
            }}
            success={() => {
              setMaterialReqForm({ ...materialReqForm, open: false, success: true });
            }}
            loadData={loadData}
            readOnly={!isAdminOrAccountant}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {popHeader ? popHeader : "Mail Material Request"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '180px' }}>
          <Grid container>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span" >Mail to *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                value={emailId}
                onChange={e => setEmailSendData({ ...emailSendData, emailId: e.target.value })}
              />
              {isEmailHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter valid email id
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: '10px' }}>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span">Subject *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                value={emailSubject}
                onChange={e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value })}
              />
              {isEmailSubjectHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter email subject
              </FormHelperText>}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              onClick={onSendEmailInvoice}
              color="secondary"
              size="small"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={materialReqForm.success}
        onClose={() => setMaterialReqForm({ ...materialReqForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setMaterialReqForm({ ...materialReqForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {materialReqForm.mode === "C"
            ? "Material Request created successfully"
            : "Material Request updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
        autoHideDuration={3000}
      >
        <MuiAlert
          onClose={() => {
            setIsSuccess(false);
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          Mail Sent successfully
        </MuiAlert>
      </Snackbar>

      <Grid item>

      </Grid>
    </div>
  );
};

export default PurchaseMaterialRequest;
