import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { fetchProductLookup, locations } from "../common/lookupSlice";

import {
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorIcon from "@material-ui/icons/Error";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import {
  brands,
  manufacturers,
  productTypes,
  productCategories,
  uoms,
} from "../common/lookupSlice";

let errorMsg;
let method;
function ProductForm(props) {
  const dispatch = useDispatch();
  const [uploads, setUploads] = React.useState({
    U: { status: "" },
    M: { status: "" },
    W: { status: "" },
  });
  const allInventoryLocations = useSelector(locations);
  const  corporateLoc = allInventoryLocations.find((loc) => { return loc.type && loc.type.code === "CORPORATE"});
  let locId = corporateLoc && corporateLoc.id;
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const isEdit = props.form.mode === "C" ? false : true;
  const [date, setDate] = useState(props.form.data.GSTApplicableDate ? moment(props.form.data.GSTApplicableDate).format('DD/MM/YYYY') : null);
  const { handleSubmit, control, errors, reset, register } = useForm({
    defaultValues: {
      id: props.form.data.id,
      productName: props.form.data.productName,
      productCode: props.form.data.productCode,
      aliases: props.form.data.aliases,
      model: props.form.data.model,
      serialNumber: props.form.data.serialNumber,
      uom: props.form.data.uom,
      isServiceable: props.form.mode === "C" ? true : props.form.data.isServiceable,
      isGSTApplicable: props.form.mode === "C" ? true : props.form.data.isGSTApplicable,
      GSTApplicableDate: props.form.data.GSTApplicableDate ? moment(props.form.data.GSTApplicableDate).format('DD/MM/YYYY') : props.form.data.GSTApplicableDate,
      GSTCalculationType: props.form.data.GSTCalculationType,
      HSNCode: props.form.data.HSNCode,
      GSTTaxability: props.form.data.GSTTaxability,
      IGST: props.form.data.IGST,
      CGST: props.form.data.CGST,
      SGST: props.form.data.SGST,
      cess: props.form.data.cess,
      isDirty: "true",
      isSpecial: (props.form.data.isSpecial) ? true : false,
      userManual: props.form.data.userManual,
      warrantyCard: props.form.data.warrantyCard,
      machineDiagram: props.form.data.machineDiagram,
      mfgId: props.form.data.manufacturer ? props.form.data.manufacturer.id : props.form.data.manufacturer,
      brandId: props.form.data.brand ? props.form.data.brand.id : props.form.data.brand,
      typeId: props.form.data.type ? props.form.data.type.id : props.form.data.type,
      categoryId: props.form.data.category ? props.form.data.category.id : props.form.data.category
    },
  });

  const onSubmit = async (data) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    method = "";
    try {
      data = JSON.parse(JSON.stringify(data));
      if (data.GSTApplicableDate) {
        var GSTDateArray = data.GSTApplicableDate && data.GSTApplicableDate.split("/");
        data.GSTApplicableDate = new Date(GSTDateArray[1] + '/' + GSTDateArray[0] + '/' + GSTDateArray[2]);
      }
      let productRes;
      if (props.form.mode === "C") {
        data.SGST = Number(data.SGST)
        data.CGST = Number(data.CGST)
        data.cess = Number(data.cess)
        data.IGST = Number(data.IGST)
        method = "creating";
        productRes = await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/products`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (productRes && productRes.data && productRes.data.productId) {
          await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/inventory/newproductinv`,
            {
              productId: productRes.data.productId,
              locId: locId
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }

      } else if (props.form.mode === "U") {
        data.SGST = Number(data.SGST)
        data.CGST = Number(data.CGST)
        data.cess = Number(data.cess)
        data.IGST = Number(data.IGST)
        method = "updating";
        await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/products/${props.form.data.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      dispatch(fetchProductLookup());
      setIsProcessing(false);
      props.success(productRes.data && productRes.data.productJSON, props.form.lineKey);
    } catch (err) {
      errorMsg = err.response.data.message === "Validation error" ? "Product name/code already exists" : `Error occured while ${method} product`;
      setIsError(true);
      setIsProcessing(false);
    }
  };
  const prodMfgs = useSelector(manufacturers);
  const prodTypes = useSelector(productTypes);
  const prodUOMs = useSelector(uoms);
  const prodBrands = useSelector(brands);
  const prodCategories = useSelector(productCategories);
  const onFileInputChange = async (e, type) => {
    try {
      if (type === "U") {
        setUploads({
          ...uploads,
          U: { status: "WIP" },
        });
      }
      if (type === "W") {
        setUploads({
          ...uploads,
          W: { status: "WIP" },
        });
      }
      if (type === "M") {
        setUploads({
          ...uploads,
          M: { status: "WIP" },
        });
      }
      const data = new FormData();
      data.append("file", e.target.files[0]);
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/uploadfile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (type === "U") {
        props.form.data.userManual = result.data.publicUrl;
        setUploads({
          ...uploads,
          U: { status: "DONE", url: result.data.publicUrl },
        });
      } else if (type === "W") {
        props.form.data.warrantyCard = result.data.publicUrl;
        setUploads({
          ...uploads,
          W: { status: "DONE", url: result.data.publicUrl },
        });
      } else if (type === "M") {
        props.form.data.machineDiagram = result.data.publicUrl;
        setUploads({
          ...uploads,
          M: { status: "DONE", url: result.data.publicUrl },
        });
      }
    } catch (err) {
      if (type === "U") {
        setUploads({
          ...uploads,
          U: { status: "ERR" },
        });
      }
      if (type === "W") {
        setUploads({
          ...uploads,
          W: { status: "ERR" },
        });
      }
      if (type === "M") {
        setUploads({
          ...uploads,
          M: { status: "ERR" },
        });
      }
      console.log(err);
    }
  };

  const handleDateChange = (value) => {
    setDate(value)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={6}>
            <Typography variant="caption">Product Name <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.productName)}
              inputRef={register({
                required: "Minimum 5 char required",
                pattern: {
                  value: /^[a-zA-Z0-9-_ .,:*()/+$#&]{5,}$/,
                  message: "Minimum 5 char required",
                },
              })}
              InputProps={{
                autoFocus: true,
                name: "productName",
              }} disabled={isEdit || props.disabled}
              size="small"
              name="productName"
            />
            <FormHelperText error>
              {errors.productName && errors.productName.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="caption">Product Code <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.productCode)}
              inputRef={register({
                required: "Minimum 5 char required",
                pattern: {
                  value: /^[a-zA-Z0-9-_ .,:*()/+$#&]{5,}$/,
                  message: "Minimum 5 char required",
                },
              })} disabled={isEdit || props.disabled}
              size="small"
              name="productCode"
            />
            <FormHelperText error>
              {errors.productCode && errors.productCode.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography variant="caption">Aliases (comma separated)</Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              rowsMax={3}
              error={Boolean(errors.aliases)}
              inputRef={register({
                pattern: {
                  value: /^[a-zA-Z0-9-_, ]*$/,
                  message: "Alphanumeric, hyphen and underscore allowed",
                },
              })}
              size="small"
              name="aliases"
              disabled={props.disabled}
            />
            <FormHelperText error>
              {errors.aliases && errors.aliases.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={4} xs={12}>
            <Typography variant="caption">Model</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.model)}
              inputRef={register({
                pattern: {
                  value: /^[a-zA-Z0-9-_,/]*$/,
                  message: "Alphanumeric, hyphen and underscore allowed",
                },
              })}
              size="small"
              name="model"
              disabled={props.disabled}
            />
            <FormHelperText error>
              {errors.model && errors.model.message}
            </FormHelperText>
          </Grid>

          <Grid item md={4} xs={12}>
            <Typography variant="caption">Serial Number</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.serialNumber)}
              inputRef={register({
                pattern: {
                  value: /^[a-zA-Z0-9-_ ]*$/,
                  message: "Alphanumeric, hyphen and underscore allowed",
                },
              })}
              size="small"
              name="serialNumber"
              disabled={props.disabled}
            />
            <FormHelperText error>
              {errors.serialNumber && errors.serialNumber.message}
            </FormHelperText>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="caption">Manufacturer <span style={{ color: 'red' }}>*</span></Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={Boolean(errors.mfgId)}
            >
              <Controller
                as={
                  <Select disabled={props.disabled}>
                    {prodMfgs.map(function (mfg) {
                      return (
                        <MenuItem key={mfg.code} value={mfg.id}>
                          {mfg.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="mfgId"
                size="small"
                variant="outlined"
                control={control}
                rules={{ required: "Manufacturer is required" }}
                defaultValue=""
              />
            </FormControl>
            <FormHelperText error>
              {errors.mfgId && errors.mfgId.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1} alignItems="center">
          <Grid item md={4} xs={12}>
            <Typography variant="caption">Brand <span style={{ color: 'red' }}>*</span></Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={Boolean(errors.brandId)}
            >
              <Controller
                as={
                  <Select disabled={props.disabled}>
                    {prodBrands.map(function (brand) {
                      return (
                        <MenuItem key={brand.code} value={brand.id}>
                          {brand.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="brandId"
                size="small"
                variant="outlined"
                control={control}
                rules={{ required: "Brand is required" }}
                defaultValue=""
              />
            </FormControl>
            <FormHelperText error>
              {errors.brandId && errors.brandId.message}
            </FormHelperText>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="caption">Type <span style={{ color: 'red' }}>*</span></Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={Boolean(errors.typeId)}
            >
              <Controller
                as={
                  <Select disabled={props.disabled}>
                    {prodTypes.map(function (type) {
                      return (
                        <MenuItem key={type.code} value={type.id}>
                          {type.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="typeId"
                size="small"
                variant="outlined"
                control={control}
                rules={{ required: "Type is required" }}
                defaultValue=""
              />
            </FormControl>
            <FormHelperText error>
              {errors.typeId && errors.typeId.message}
            </FormHelperText>
          </Grid>

          <Grid item md={4} xs={12}>
            <Typography variant="caption">Category <span style={{ color: 'red' }}>*</span></Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={Boolean(errors.categoryId)}
            >
              <Controller
                as={
                  <Select disabled={props.disabled}>
                    {prodCategories.map(function (cat) {
                      return (
                        <MenuItem key={cat.code} value={cat.id}>
                          {cat.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="categoryId"
                size="small"
                variant="outlined"
                control={control}
                rules={{ required: "Category is required" }}
                defaultValue=""
              />
            </FormControl>

            <FormHelperText error>
              {errors.categoryId && errors.categoryId.message}
            </FormHelperText>
          </Grid>

          <Grid item md={4} xs={12}>
            <Typography variant="caption">UOM <span style={{ color: 'red' }}>*</span></Typography>
            <Autocomplete
              freeSolo
              value={props.form.data.uom}
              rules={{ required: "UOM is required" }}
              options={prodUOMs} disabled={isEdit || props.disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  name="uom"
                  fullWidth
                  error={Boolean(errors.uom)}
                  inputRef={register({
                    required: "Minimum 1 char required",
                    pattern: {
                      value: /^[a-zA-Z]{1,}$/,
                      message: "Minimum 1 char required",
                    },
                  })}
                  InputProps={{
                    autoFocus: false,
                    name: "uom",
                  }}
                  disabled={isEdit || props.disabled}
                />
              )}
            />
            <FormHelperText error>
              {errors.uom && errors.uom.message}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item md={4} xs={12}>
                <Typography variant="caption">Serviceable</Typography>
                <Controller
                  as={Checkbox}
                  name="isServiceable"
                  type="checkbox"
                  inputRef={register({})}
                  control={control}
                  disabled={props.disabled}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography variant="caption">Special</Typography>
                <Controller
                  as={Checkbox}
                  name="isSpecial"
                  type="checkbox"
                  inputRef={register({})}
                  control={control}
                  disabled={props.disabled}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography variant="caption">GST Applicable</Typography>
                <Controller
                  as={Checkbox}
                  name="isGSTApplicable"
                  type="checkbox"
                  inputRef={register({})}
                  control={control}
                  disabled={isEdit || props.disabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1} alignItems="center">
          <Grid item md={6} xs={12}>
            <Typography variant="caption">GST Applicable Date <span style={{ color: 'red' }}>*</span></Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                helperText=""
                error={Boolean(errors.GSTApplicableDate)}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                inputRef={register({
                  required: "GST Applicable Date required",
                  pattern: {
                    value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                    message: "GST Applicable Date required",
                  },
                })}
                name="GSTApplicableDate"
                value={date}
                disabled={isEdit || props.disabled}
                onChange={(value) => handleDateChange(value)}
              />
            </MuiPickersUtilsProvider>
            <FormHelperText error>
              {errors.GSTApplicableDate && errors.GSTApplicableDate.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">GST Calculation Type</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.GSTCalculationType)}
              inputRef={register({
                pattern: {
                  value: /^[a-zA-Z0-9-_ .,:*()/+$#&]{2,}$/,
                  message: "Provide valid GST Calculation Type",
                },
              })}
              disabled={isEdit || props.disabled}
              size="small"
              name="GSTCalculationType"
            />
            <FormHelperText error>
              {errors.GSTCalculationType && errors.GSTCalculationType.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1} alignItems="center">
          <Grid item md={6} xs={12}>
            <Typography variant="caption">HSN Code <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.HSNCode)}
              inputRef={register({
                required: "Provide valid HSN code",
                pattern: {
                  value: /^[a-zA-Z0-9-_ .,:*()/+$#&]{2,}$/,
                  message: "Provide valid HSN code",
                },
              })}
              disabled={isEdit || props.disabled}
              size="small"
              name="HSNCode"
            />
            <FormHelperText error>
              {errors.HSNCode && errors.HSNCode.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">GST Taxability <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.GSTTaxability)}
              inputRef={register({
                required: "Provide valid GST Taxability",
                pattern: {
                  value: /^[a-zA-Z0-9-_ .,:*()/+$#&]{2,}$/,
                  message: "Provide valid GST Taxability",
                },
              })} disabled={isEdit || props.disabled}
              size="small"
              name="GSTTaxability"
            />
            <FormHelperText error>
              {errors.GSTTaxability && errors.GSTTaxability.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1} alignItems="center">
          <Grid item md={3} xs={12}>
            <Typography variant="caption">IGST <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.IGST)}
              inputRef={register({
                required: "IGST is required",
                pattern: {
                  value: /^[0-9]{0,2}\.?[0-9]{0,2}$/,
                  message: "IGST is required",
                },
              })} disabled={isEdit || props.disabled}
              size="small"
              name="IGST"
            />
            <FormHelperText error>
              {errors.IGST && errors.IGST.message}
            </FormHelperText>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant="caption">CGST <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.CGST)}
              inputRef={register({
                required: "CGST is required",
                pattern: {
                  value: /^[0-9]{0,2}\.?[0-9]{0,2}$/,
                  message: "Provide a valid CGST rate",
                },
              })} disabled={isEdit || props.disabled}
              size="small"
              name="CGST"
            />
            <FormHelperText error>
              {errors.CGST && errors.CGST.message}
            </FormHelperText>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant="caption">SGST <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.SGST)}
              inputRef={register({
                required: "SGST is required",
                pattern: {
                  value: /^[0-9]{0,2}\.?[0-9]{0,2}$/,
                  message: "Provide a valid SGST rate",
                },
              })} disabled={isEdit || props.disabled}
              size="small"
              name="SGST"
            />
            <FormHelperText error>
              {errors.SGST && errors.SGST.message}
            </FormHelperText>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant="caption">CESS</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.cess)}
              inputRef={register({
                pattern: {
                  required: "CESS is required",
                  value: /^[0-9]{0,2}\.?[0-9]{0,2}$/,
                  message: "Provide a valid CESS rate",
                },
              })} disabled={isEdit || props.disabled}
              size="small"
              name="cess"
            />
            <FormHelperText error>
              {errors.cess && errors.cess.message}
            </FormHelperText>
          </Grid>
        </Grid>


      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid item container xs={8} alignItems="center">
          <input
            style={{ display: "none" }}
            id="user-manual-file"
            type="file"
            name="file"
            onChange={(e) => onFileInputChange(e, "U")}
            disabled={props.disabled}
          />
          <Grid item xs={5}>
            <Typography variant="caption">User Manual</Typography>
          </Grid>
          <Grid item xs={3}>
            <label htmlFor="user-manual-file">
              <Button variant="outlined" component="span" color={props.disabled ? "default" : "secondary"} disabled={props.disabled}>
                <CloudUploadIcon />
              </Button>
            </label>
          </Grid>
          <Grid item xs={2}>
            {uploads.U.status === "DONE" ? (
              <IconButton
                onClick={(e) => window.open(props.form.data.userManual)}
              >
                <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
              </IconButton>
            ) : uploads.U.status === "WIP" ? (
              <CircularProgress color="secondary" />
            ) : uploads.U.status === "ERR" ? (
              <ErrorIcon color="secondary" />
            ) : props.form.data.userManual ? (
              <IconButton
                onClick={(e) => window.open(props.form.data.userManual)}
              >
                <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
              </IconButton>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid item container xs={8} alignItems="center">
          <input
            style={{ display: "none" }}
            id="machine-diagram-file"
            type="file"
            name="file"
            onChange={(e) => onFileInputChange(e, "M")}
            disabled={props.disabled}
          />
          <Grid item xs={5}>
            <Typography variant="caption">Machine Diagram</Typography>
          </Grid>
          <Grid item xs={3}>
            <label htmlFor="machine-diagram-file">
              <Button variant="outlined" component="span" color={props.disabled ? "default" : "secondary"} disabled={props.disabled}>
                <CloudUploadIcon />
              </Button>
            </label>
          </Grid>
          <Grid item xs={2}>
            {uploads.M.status === "DONE" ? (
              <IconButton
                onClick={(e) => window.open(props.form.data.machineDiagram)}
              >
                <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
              </IconButton>
            ) : uploads.M.status === "WIP" ? (
              <CircularProgress color="secondary" />
            ) : uploads.M.status === "ERR" ? (
              <ErrorIcon color="secondary" />
            ) : props.form.data.machineDiagram ? (
              <IconButton
                onClick={(e) => window.open(props.form.data.machineDiagram)}
              >
                <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
              </IconButton>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid item container xs={8} alignItems="center">
          <input
            style={{ display: "none" }}
            id="warranty-file"
            type="file"
            name="file"
            onChange={(e) => onFileInputChange(e, "W")}
            disabled={props.disabled}
          />
          <Grid item xs={5}>
            <Typography variant="caption">Warranty Card</Typography>
          </Grid>
          <Grid item xs={3}>
            <label htmlFor="warranty-file">
              <Button variant="outlined" component="span" color={props.disabled ? "default" : "secondary"} disabled={props.disabled} >
                <CloudUploadIcon />
              </Button>
            </label>
          </Grid>
          <Grid item xs={2}>
            {uploads.W.status === "DONE" ? (
              <IconButton
                onClick={(e) => window.open(props.form.data.warrantyCard)}
              >
                <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
              </IconButton>
            ) : uploads.W.status === "WIP" ? (
              <CircularProgress color="secondary" />
            ) : uploads.W.status === "ERR" ? (
              <ErrorIcon color="secondary" />
            ) : props.form.data.warrantyCard ? (
              <IconButton
                onClick={(e) => window.open(props.form.data.warrantyCard)}
              >
                <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
              </IconButton>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            {props.disabled ? null : <Button
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Save
            </Button>}
          </Grid>
          <Grid item>
            {props.disabled ? null : <Button
              size="small"
              type="button"
              onClick={(e) => reset()}
              variant="contained"
              color="secondary"
            >
              Reset
            </Button>}
          </Grid>
        </Grid>
      </Grid>
      <input type="hidden" name="isDirty" value="true" ref={register()} />
      {/* <input type="hidden" name="itemId" ref={register()} />
      <input type="hidden" name="id" ref={register()} /> */}
      <input
        type="hidden"
        name="userManual"
        value={props.form.data.userManual}
        ref={register()}
      />
      <input
        type="hidden"
        name="warrantyCard"
        value={props.form.data.warrantyCard}
        ref={register()}
      />
      <input
        type="hidden"
        name="machineDiagram"
        value={props.form.data.machineDiagram}
        ref={register()}
      />
    </form>
  );
}

export default ProductForm;
