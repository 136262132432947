import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from '../common/util';
import { withStyles } from '@material-ui/styles';
import _ from "lodash";

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '1.6',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};
const tableStyle = {
  border: '1px solid #ccc',
  borderCollapse: 'collapse',
}

class CompToPrintInv extends React.Component {
  componentWillReceiveProps() {
  }
  render() {
    const { classes, loggedInUser, orgTermsAndConditions } = this.props;
    const invDetails = this.props.customerInv != null && this.props.customerInv;
    const lineItems = this.props.customerInv != null && this.props.customerInv.lineItems;
    let isSpecialLineItems = [];
    let subtotal = 0;
    const filterLineItems = _.filter(lineItems, (data, index) => {
      let isReturn = true;
      if (data.isSpecial) {
        isReturn = false;
        isSpecialLineItems.push(data);
      } else {
        subtotal += data.amount
      }
      return isReturn;
    })
    const isSupplier = this.props.customerInv && this.props.customerInv.isSupplier
    const customer = this.props.customerInv != null && this.props.customerInv.customer && this.props.customerInv.customer;
    const custContacts = customer && customer.contacts && customer.contacts;
    const hsnSummary = invDetails && invDetails.HSNSummary;
    let hsnTotal = 0;
    let hsnCGSTTotal = 0;
    let hsnSGSTTotal = 0;
    let hsnIGSTTotal = 0;
    hsnSummary && hsnSummary.map((row) => (
      hsnTotal += row.Total));
    hsnSummary && hsnSummary.map((row) => (
      hsnCGSTTotal += row.CGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnSGSTTotal += row.SGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnIGSTTotal += row.IGSTtotal));
    let quantity = 0;
    invDetails.lineItems && invDetails.lineItems.map((row) => (
      quantity += row.qty));
    const invType = this.props.customerInv != null && this.props.customerInv.type;
    const isOrder = invType && invType.code === "ORDER";
    let isIntraState = false;
    if (invDetails && invDetails.org) {
      isIntraState = (customer && customer.state && invDetails.org.state && (invDetails.org.state === customer.state)) ? true : false;
    }
    const companyPrintLogo = loggedInUser && loggedInUser.org && loggedInUser.org.printLogo;
    let orgTerms = "";
    if (orgTermsAndConditions && orgTermsAndConditions.length > 0) {
      let termsConditionsType = isOrder ? (isSupplier) ? "Supplier Purchase Order" : "Customer Purchase Order" : (isSupplier) ? "Supplier Purchase Quotation" : "Customer Purchase Quotation";
      let findOrgCond = orgTermsAndConditions.find((orgCond) => {
        return (orgCond && orgCond.termsConditionsType && (orgCond.termsConditionsType === termsConditionsType)) ? orgCond : null;
      })
      orgTerms = findOrgCond && findOrgCond.termsConditions
    }

    return (
      <div>
        <div align="center">
          <img alt="comLogo" src={companyPrintLogo}
            style={{ height: "100px", width: "200px" }} />
        </div>
        <div style={divStyle} align="center">
          {/* Invoice  */}
          {/* <Helmet><title>Print Invoice</title></Helmet> */}
          <Helmet><title></title></Helmet>
          <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', textAlign: 'center', fontWeight: 'bold', lineHeight: '2', }}>{(invDetails && invDetails.type && invDetails.type.code && invDetails.type.code === "ORDER") ? "Customer Purchase Order Invoice" : invDetails.type && invDetails.type.description}</span>
          <table style={tableStyle} width="95%" border="1px" color="#ccc">
            <tr>
              <td rowSpan="2" width="50%">
                <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name}<br /></b></span>
                <span style={pStyle2}>
                  {invDetails && invDetails.org && (invDetails.org.address1)}
                  <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address2)}</div>
                  <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address3 + '-' + invDetails.org.pincode)}</div>
                  <span style={pStyle2}>{invDetails && invDetails.org && invDetails.org.phoneNo ? invDetails.org.phoneNo : ""}<br /></span>
                </span>
                <span style={pStyle2}>GSTIN/UIN: {invDetails && invDetails.org && invDetails.org.GSTIN ? invDetails.org.GSTIN : "Not Available"}<br /></span>
                <span style={pStyle2}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /></span>
              </td>
              <td width="25%" height="10%" valign="top"><span style={pStyle2}>Order No.<br /><b>{this.props.customerInv && this.props.customerInv.OrderNo}</b></span></td>
              <td width="25%" valign="top"><span style={pStyle2}>Dated. <br /><b>{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</b></span></td>
            </tr>
            <tr>
              <td colspan="2">
                <span style={pStyle2}>
                  Customer <br /> <b>{customer && customer.fullName}</b><br />
                  {customer && (customer.address1 + ' \n' + customer.address2 + ' \n' + customer.address3 + ' \n' + customer.state + '-' + customer.pincode)}<br />
                  GSTIN/UIN: {customer && customer.GSTIN}<br />
                  State: {customer && customer.state} {(customer && customer.GSTIN) ? ' Code: ' + customer.GSTIN.slice(0, 2) : ' '}<br />
                  Contact Person: {custContacts ? custContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                  Contact: {custContacts ? custContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                </span>
              </td>
            </tr>
          </table>
          <div style={{ width: '100%', padding: '5px 2px' }}>
            <table style={tableStyle} width="95%">
              <thead>
                <tr>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Sl No.</span></td>
                  <td width="25%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Description of Goods/Services</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>GST Rate</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Quantity</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>per</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Disc.%</span></td>
                  <td width="15%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                </tr>
              </thead>
              <tbody>
                {filterLineItems && filterLineItems.length === 0 && isSpecialLineItems && isSpecialLineItems.length === 0 ? (
                  <tr>
                    <td colspan="9" align="center">
                      <span style={pStyle2}>No records found</span>
                    </td>
                  </tr>
                ) : (
                  filterLineItems && filterLineItems.map((row, index) => (
                    <tr
                      key={index}
                      hover={true}
                    >
                      <td align="center" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {index + 1}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.productName}</b>
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.HSNCode}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.IGST} %
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.qty} {row.uom}</b>
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.unitPrice ? (Math.round(row.unitPrice * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.uom}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.discountPct ? (Math.round(row.discountPct * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</b>
                        </span>
                      </td>
                    </tr>
                  ))
                )}
                {filterLineItems && filterLineItems.length > 0 ?
                  <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>{subtotal ? (Math.round(subtotal * 100) / 100).toFixed(2) : '0.00'}</span>
                    </td>
                  </tr>
                  : null}
                {isSpecialLineItems && isSpecialLineItems.length > 0 && isSpecialLineItems.map((row, index) => (
                  <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>{row.productName}</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr>
                ))
                }
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>CGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.CGST ? (Math.round(invDetails.CGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>SGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.SGST ? (Math.round(invDetails.SGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {(!isIntraState) && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>IGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.IGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {invDetails.roundOff !== 0 && <tr>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}>&nbsp;</td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><i>{invDetails.roundOff < 0 ? 'Less:' : ''}</i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={pStyle2}><b><i>Round Off</i></b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.roundOff ? (invDetails.roundOff > 0 ? (Math.round(invDetails.roundOff * 100) / 100).toFixed(2) : '(-)' + (Math.round(invDetails.roundOff * (-100)) / 100).toFixed(2)) : '0.00'}</b></span>
                  </td>
                </tr>}
                <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{quantity}</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.total ? (Math.round(Math.ceil(invDetails.total) * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody>
                <tr>
                  <td width="50%" style={{ borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Amount Chargeable(in words) :</span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}><i>E. &amp; O.E</i>&nbsp;</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.amountInWords}</b><br /><br /></span>
                  </td>
                </tr>
                {isOrder ? null :
                  <tr style={{ fontFamily: 'Arial, sans-serif' }}>
                    <td colspan="2">
                      <span style={{ margin: "auto", display: "table", textDecoration: "underline", fontSize: "16px" }}><b>Terms and Conditions</b></span>
                      <ol type="1" style={{ fontSize: "13px", fontFamily: 'Arial, sans-serif' }}>
                        {orgTerms}
                      </ol>
                    </td>
                  </tr>
                }

                <tr>
                  <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Customer Seal and Signature<br /><br /><br /></span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(CompToPrintInv);