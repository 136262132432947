/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import {
  useForm,
  Controller,
  useFieldArray,
  FormContext,
} from "react-hook-form";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import "react-html5-camera-photo/build/css/index.css";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { journalEntryParentAccounts, journalEntryChildAccounts } from "../common/lookupSlice";
import { useLocation } from "react-router-dom";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import DateFnsUtils from "@date-io/date-fns";
import Link from "@material-ui/core/Link";
import Customers from "../customers/Customer";
import SupplierSelection from "../purchase/SupplierSelection";
import CompToPrintReceipt from '../jobs/CompToPrintReceipt';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Grid,
  MenuItem,
  Box,
  FormControl,
  FormHelperText,
  Select,
  TextField,
  Typography,
  Breadcrumbs,
  IconButton,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  InputAdornment
} from "@material-ui/core";
import { currentUser } from "../login/loginSlice";
import MuiAlert from "@material-ui/lab/Alert";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DirectPaymentComp = (props) => {
  const token = localStorage.getItem("authToken");
  const componentRefInv = useRef();
  const buttonRef = useRef();
  const loggedInUser = useSelector(currentUser);
  const companyPrintLogo = loggedInUser && loggedInUser.org && loggedInUser.org.printLogo;
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  const JEParentAccounts = useSelector(journalEntryParentAccounts);
  const JEChildAccounts = useSelector(journalEntryChildAccounts);
  const [selectCustorSupType, setCustorSupType] = useState("Customer");
  const [costCenterName, setCostCenterName] = useState('');
  const [isError, setIsError] = useState(false);
  const [errMessage, setErrorMsg] = useState("");
  const [invReceipt, setInvoice] = useState(null);
  const [receiptNum, setInvoiceNo] = useState();
  const [costCenterList, setCostCenterList] = useState([]);
  const [isDebitorCreditErrorIndex, setDebitorCreditValidation] = useState({});
  const [selectedParty, setSelectParty] = useState({ open: false, details: null });
  const [notes, setNotes] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedPo, setSelectedAccount] = useState({ spoNum: "", accounts: [] });
  const [childAccountInLineItemLevel, setChildAccount] = useState({});
  const [partyInLineItemLevel, setPartyRequire] = useState({});

  useEffect(() => {
    if (receiptNum) {
      buttonRef && buttonRef.current && buttonRef.current.click()
    }
  }, [receiptNum]);

  const loadData = async (COMPANY_NAME_IN_ERPNEXT) => {
    let count = 0;
    try {
      if(COMPANY_NAME_IN_ERPNEXT){
        let queryCostCenter = {
          fields: ["name", "owner"],
          filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT, disabled: 0, is_group: 0 })
        }
        const resultCostCenter = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Cost Center`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: queryCostCenter,
          }
        );
        setCostCenterList(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data);
        setCostCenterName(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data && resultCostCenter.data.data.length > 0 && resultCostCenter.data.data[0].name)
      }
    }
    catch (err) {
      console.log("err",err);
    }
  };

  useEffect (()=>{
    loadData(COMPANY_NAME_IN_ERPNEXT)
  },[COMPANY_NAME_IN_ERPNEXT])

  const { handleSubmit, control, errors, reset, register, setValue, getValues } = useForm({
    defaultValues: {
      accounts: selectedPo.accounts ? selectedPo.accounts : [],
    },
  });
  const { fields, append, insert, remove, prepend } = useFieldArray({
    control,
    name: "accounts",
  });

  let cancelClick = () => {
    window.location.href = `/accountshome`;
  }


  let onSubmit = async (data) => {
    if (data && data.accounts && data.accounts.length > 1) {
      let isErrorZeroAmount = false;
      let isErrorGreaterZero = false;
      let isErrorMesg = "";
      let errIndex = [];
      const accountLists = data && data.accounts && await data.accounts.map((x, y) => {
        if ((parseFloat(x.creditAmount) === 0) && (parseFloat(x.debitAmount) === 0)) {
          errIndex.push(y);
          isErrorZeroAmount = true
        } else if ((parseFloat(x.creditAmount) > 0) && (parseFloat(x.debitAmount) > 0)) {
          errIndex.push(y);
          isErrorGreaterZero = true
        }
        // (x.avail <= 0) && count++;
        let item = {
          supplierId: (x.partyType === "Supplier") ? x.partyId : "",
          customerId: (x.partyType === "Customer") ? x.partyId : "",
          parentAccountType: x.parentAccountType,
          childAccount: x.childAccount,
          partyType: x.partyType,
          creditAmount: parseFloat(x.creditAmount),
          debitAmount: parseFloat(x.debitAmount)
        }

        return item;
      })
      if (isErrorZeroAmount) {
        isErrorMesg = (isErrorMesg && isErrorMesg.length > 0) ? `${isErrorMesg}, "Both Debit and Credit values cannot be zero"` : `"Both Debit and Credit values cannot be zero"`;
      }
      if (isErrorGreaterZero) {
        isErrorMesg = (isErrorMesg && isErrorMesg.length > 0) ? `${isErrorMesg}, "You cannot credit and debit same account at the same time"` : `"You cannot credit and debit same account at the same time"`;
      }
      if (!(errIndex.length > 0) && accountLists.length > 1) {
        setDebitorCreditValidation([]);
        let totalCreditAmount = 0;
        let totalDebitAmount = 0;
        await data.accounts.forEach((x, y) => {
          totalCreditAmount += parseFloat(x.creditAmount);
          totalDebitAmount += parseFloat(x.debitAmount);
        })
        if (totalCreditAmount === totalDebitAmount) {
          let jePayload = {
            totalDebit: totalDebitAmount,
            totalCredit: totalCreditAmount,
            remarkNote: notes,
            lineItems: accountLists,
            costCenter: costCenterName
          }
          try {
            let resJE = await axios.post(
              `${process.env.REACT_APP_SERVICE_URL}/journalentry`,
              jePayload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setInvoiceNo(resJE && resJE.data && resJE.data.receiptNum);
            setIsSuccess(true)
            setIsSaved(true);
          } catch (err) {
            console.log("err", err);
            setIsError(true);
            setErrorMsg("Some error occured. Please retry after sometime.")
          }
        } else {
          let diff = totalDebitAmount - totalCreditAmount;
          isErrorMesg = `Total Debit must be equal to Total Credit. The difference is ${diff}`
          setIsError(true);
          setErrorMsg(isErrorMesg);
        }
      } else {
        setIsError(true);
        setErrorMsg(isErrorMesg);
        setDebitorCreditValidation(errIndex);
      }
    } else {
      setIsError(true)
      setErrorMsg("Enter At least two Account, You cannot credit and debit same account at the same time")
    }
  }

  const getReceiptInvoice = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/journalentry/${receiptNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const rcInv = result.data && result.data.journalEntries;
      return rcInv;
    }
    catch (err) {
      setIsError(err)
    }
  }


  const handleInvoicePrint = async () => {
    try {
      const data = await getReceiptInvoice();
      setInvoice(data);
    } catch (err) {
      setIsError(true);
    }
  }

  return (
    <FormContext >

      <Grid container >
        <Grid item xs={6}>
          <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={() => history.push("/")}>
              Home
            </Link>
            <Typography component="span" color="textPrimary" style={{ fontWeight: 'bold' }}>
              Create Journal Enrty
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>

        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ marginTop: '10px' }}>
        <Grid item container md={4} xs={12} direction="column">
          <Grid
            item
            container
            spacing={2}
            md={12}
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={4} xs={4}>
              <Grid item container justifyContent="flex-end">
                <Grid item>
                  <Box fontWeight={"fontWeightBold"}>Journal Entry Number</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} xs={8}>
              <TextField
                name="id"
                fullWidth
                variant="outlined"
                size="small"
                placeholder={"Auto Generate"}
                disabled
                // value={data.id}
                InputProps={{
                  style: { color: "black" },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={4} xs={12} direction="column">
          <Grid
            item
            container
            spacing={2}
            md={12}
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={4} xs={4}>
              <Grid item container justifyContent="flex-end">
                <Grid item>
                  <Box fontWeight={"fontWeightBold"}>Posting Date</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  size="small"
                  inputVariant="outlined"
                  format="dd/MM/yyyy HH:mm:ss"
                  name="createdOn"
                  disabled={true}
                  value={new Date()}
                // onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
              {/* <TextField
                  name="createdOn"
                  disabled
                  fullWidth
                  InputProps={{
                    style: { color: "black" },
                  }}
                  variant="outlined"
                  size="small"
                  inputRef={register}
                /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={4} xs={12} direction="column">
          <Grid
            item
            container
            spacing={2}
            md={12}
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={4} xs={4}>
              <Grid item container justifyContent="flex-end">
                <Grid item>
                  <Box fontWeight={"fontWeightBold"}>Cost Center *</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} xs={8}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  name="locality"
                  value={costCenterName}
                  style={{ width: "100%" }}
                  disabled={receiptNum}
                  onChange={(e) => {
                    setCostCenterName(e.target.value);
                  }}
                  variant="outlined"
                >
                  {costCenterList.map(function (costCenter) {
                    return (
                      <MenuItem key={costCenter} value={costCenter.name}>
                        {costCenter.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container >
          <Grid item xs={12} style={{ marginTop: '20px' }}>

            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledHeaderCell align="left" style={{ width: "15%" }}>Account Type *</StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "15%" }}>Account *</StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "15%" }}>Party Type</StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "15%" }}>Party</StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "15%" }}>Debit *</StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "15%" }}>Credit *</StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "10%" }}></StyledHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((purchase, index) => {
                    return (
                      <TableRow key={purchase.id}>
                        <TableCell align="left" style={{ width: "15%" }}>
                          <Autocomplete
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const accountKey1 = `accounts[${index}].parentAccountType`;
                                const accountKey2 = `accounts[${index}].childAccount`;
                                const accountKey3 = `accounts[${index}].partyType`;
                                const accountKey4 = `accounts[${index}].partyName`;
                                const accountKey5 = `accounts[${index}].partyId`;
                                const accountKey6 = `accounts[${index}].debitAmount`;
                                const accountKey7 = `accounts[${index}].creditAmount`;
                                await setValue(accountKey1, newValue.label);
                                await setValue(accountKey2, "");
                                await setValue(accountKey3, "");
                                await setValue(accountKey4, "");
                                await setValue(accountKey5, null);
                                await setValue(accountKey6, 0);
                                await setValue(accountKey7, 0);
                                let setobj = JSON.parse(JSON.stringify({ ...childAccountInLineItemLevel }));
                                setobj[`account_${index}`] = JEChildAccounts[newValue.label]
                                setChildAccount(setobj)
                                let setobj2 = JSON.parse(JSON.stringify({ ...partyInLineItemLevel }));
                                setobj2[`account_${index}`] = "";
                                setPartyRequire(setobj2)
                                setValue(accountKey2, "");
                              }
                            }}
                            onInputChange={(event, newInputValue) => {
                              // setInputValue(newInputValue);
                            }}
                            style={{ width: 140 }}
                            options={(JEParentAccounts && JEParentAccounts.length > 0) ? JEParentAccounts : []}
                            getOptionLabel={(option) => (typeof option.label === 'string' ? option.label : '')}
                            renderInput={(params) => <TextField {...params}
                              variant="standard"
                              name={`accounts[${index}].parentAccountType`}
                              error={Boolean(errors.accounts &&
                                errors.accounts[index] &&
                                errors.accounts[index].parentAccountType)}
                              inputRef={register({
                                required: "Select Account Type"
                              })}
                            />}
                            filterOptions={(options, accountType) =>
                              options.filter((option) =>
                                typeof option.label === 'string' &&
                                option.label.toLowerCase().includes(accountType.inputValue.toLowerCase())
                              )
                            }
                            disabled={isSaved}
                          />
                          <FormHelperText error>
                            {errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].parentAccountType &&
                              errors.accounts[index].parentAccountType.message}
                          </FormHelperText>
                        </TableCell>
                        <TableCell align="left" style={{ width: "15%" }}>
                          <Autocomplete
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const accountKey2 = `accounts[${index}].childAccount`;
                                const accountKey3 = `accounts[${index}].partyType`;
                                const accountKey4 = `accounts[${index}].partyName`;
                                const accountKey5 = `accounts[${index}].partyId`;
                                const accountKey6 = `accounts[${index}].debitAmount`;
                                const accountKey7 = `accounts[${index}].creditAmount`;
                                await setValue(accountKey2, newValue.name);
                                await setValue(accountKey3, (newValue.name === "Debtors - LWORLD") ? "Customer" :
                                  (newValue.name === "Creditors - LWORLD") ? "Supplier" : "");
                                await setValue(accountKey4, "");
                                await setValue(accountKey5, null);
                                await setValue(accountKey6, 0);
                                await setValue(accountKey7, 0);

                                let setobj = JSON.parse(JSON.stringify({ ...partyInLineItemLevel }));
                                setobj[`account_${index}`] = (newValue.name === "Debtors - LWORLD") ? "Customer" :
                                  (newValue.name === "Creditors - LWORLD") ? "Supplier" : "";
                                setPartyRequire(setobj)
                              }
                            }}
                            onInputChange={(event, newInputValue) => {
                              // setInputValue(newInputValue);
                            }}
                            style={{ width: 140 }}
                            options={(childAccountInLineItemLevel && childAccountInLineItemLevel[`account_${index}`] && childAccountInLineItemLevel[`account_${index}`].length > 0) ? childAccountInLineItemLevel[`account_${index}`] : []}
                            getOptionLabel={(option) => (typeof option.name === 'string' ? option.name : '')}
                            renderInput={(params) => <TextField {...params}
                              variant="standard"
                              name={`accounts[${index}].childAccount`}
                              error={Boolean(errors.accounts &&
                                errors.accounts[index] &&
                                errors.accounts[index].childAccount)}
                              inputRef={register({
                                required: "Select Account"
                              })}
                            />}
                            filterOptions={(options, accountName) =>
                              options.filter((option) =>
                                typeof option.name === 'string' &&
                                option.name.toLowerCase().includes(accountName.inputValue.toLowerCase())
                              )
                            }
                            disabled={isSaved}
                          />
                          <FormHelperText error>
                            {errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].childAccount &&
                              errors.accounts[index].childAccount.message}
                          </FormHelperText>
                        </TableCell>
                        <TableCell align="left" style={{ width: "15%" }}>
                          <TextField
                            variant="standard"
                            style={{ width: "140px" }}
                            inputRef={register({})}
                            size="small"
                            name={`accounts[${index}].partyType`}
                            key={'partyType'}
                            disabled
                          />
                          <FormHelperText error>
                            {errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].partyType &&
                              errors.accounts[index].partyType.message}
                          </FormHelperText>
                        </TableCell>
                        <TableCell align="left" style={{ width: "15%" }}>
                          <TextField
                            variant="standard"
                            error={Boolean(
                              errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].partyName
                            )}
                            required={(partyInLineItemLevel && partyInLineItemLevel[`account_${index}`] && ((partyInLineItemLevel[`account_${index}`] === "Customer") || (partyInLineItemLevel[`account_${index}`] === "Supplier"))) ? "Party is required" : false}
                            inputRef={register({
                              required: ((partyInLineItemLevel && partyInLineItemLevel[`account_${index}`] && ((partyInLineItemLevel[`account_${index}`] === "Customer") || (partyInLineItemLevel[`account_${index}`] === "Supplier")))) ? "Party is required" : false,
                            })}
                            onClick={
                              (e) => {
                                setSelectParty({ ...selectedParty, open: true, lineKey: index, create: true })
                              }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ cursor: "pointer" }} >
                                  {((partyInLineItemLevel && partyInLineItemLevel[`account_${index}`] && ((partyInLineItemLevel[`account_${index}`] === "Customer") || (partyInLineItemLevel[`account_${index}`] === "Supplier"))) && !isSaved) && <SearchIcon onClick={
                                    (e) => {
                                      setSelectParty({ ...selectedParty, open: true })
                                      setCustorSupType(partyInLineItemLevel[`account_${index}`])
                                    }}
                                  />}
                                </InputAdornment>
                              ),
                              style: { fontSize: "14px" }
                            }}
                            size="small"
                            name={`accounts[${index}].partyName`}
                            style={{ fontSize: "14px !important", width: "140px" }}
                            multiline
                            minRows={1}
                            maxRows={3}
                            disabled
                          />
                          <TextField
                            variant="standard"
                            fullWidth
                            error={Boolean(
                              errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].partyId
                            )}
                            inputRef={register({
                            })}
                            size="small"
                            style={{ display: "none" }}
                            name={`accounts[${index}].partyId`}
                            disabled
                          />


                          <FormHelperText error>
                            {errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].partyName &&
                              errors.accounts[index].partyName.message}
                          </FormHelperText>
                        </TableCell>
                        <TableCell align="left" style={{ width: "15%" }}>
                          <TextField
                            error={Boolean(
                              errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].debitAmount
                            ) || (_.includes(isDebitorCreditErrorIndex, index)) ? true : false}
                            type="number"
                            style={{ width: "140px" }}
                            inputProps={{ style: { textAlign: 'right' } }}
                            inputRef={register({
                              pattern: {
                                value: /^(?:\d*\.\d{1,2}|\d+)$/,
                                message: "Provide valid Debit Amount",
                              },
                            })}
                            disabled={isSaved}
                            size="small"
                            name={`accounts[${index}].debitAmount`}
                            variant="standard"
                          />
                          <FormHelperText error={Boolean(
                            errors.accounts &&
                            errors.accounts[index] &&
                            errors.accounts[index].debitAmount
                          ) || (_.includes(isDebitorCreditErrorIndex, index)) ? true : false}>
                            {(errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].debitAmount) ?
                              errors.accounts[index].debitAmount.message : (_.includes(isDebitorCreditErrorIndex, index)) ? "Please Verify" : ""}

                          </FormHelperText>
                        </TableCell>
                        <TableCell align="left" style={{ width: "15%" }}>
                          <TextField
                            error={Boolean(
                              errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].creditAmount
                            ) || (_.includes(isDebitorCreditErrorIndex, index)) ? true : false}
                            style={{ width: "140px" }}
                            type="number"
                            inputProps={{ style: { textAlign: 'right' } }}
                            inputRef={register({
                              pattern: {
                                value: /^(?:\d*\.\d{1,2}|\d+)$/,
                                message: "Provide valid Credit Amount",
                              },
                            })}
                            disabled={isSaved}
                            size="small"
                            name={`accounts[${index}].creditAmount`}
                            variant="standard"
                          />
                          <FormHelperText error={Boolean(
                            errors.accounts &&
                            errors.accounts[index] &&
                            errors.accounts[index].creditAmount
                          ) || (_.includes(isDebitorCreditErrorIndex, index)) ? true : false}>

                            {(errors.accounts &&
                              errors.accounts[index] &&
                              errors.accounts[index].creditAmount) ?
                              errors.accounts[index].creditAmount.message : (_.includes(isDebitorCreditErrorIndex, index)) ? "Please Verify" : ""}
                          </FormHelperText>
                        </TableCell>
                        <TableCell align="right" style={{ width: "10%" }} >


                          <IconButton
                            size="small"
                            disabled={isSaved}
                            onClick={(e) => {
                              remove(index);
                            }}
                          >
                            <DeleteIcon color={(isSaved) ? "" : "secondary"} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  <TableRow key={'addCol'} colSpan={7} align="left" >
                    <Button
                      color={""}
                      fullWidth
                      style={{ margin: "10px 0px 10px 20px" }}
                      disabled={isSaved}
                      variant="contained"
                      size="medium"
                      startIcon={<AddIcon color={(isSaved) ? "" : "secondary"} />}
                      onClick={(e) => {
                        const saleVal = getValues({ nest: true })["accounts"];
                        if (saleVal && saleVal.length && saleVal.length > 0) {
                          insert(saleVal.length + 1, {
                            partyName: "",
                            partyId: null,
                            parentAccountType: "",
                            childAccount: "",
                            partyType: "",
                            creditAmount: 0,
                            debitAmount: 0
                          });
                        } else {
                          setSelectedAccount({ accounts: [] })
                          append({
                            partyName: "",
                            partyId: null,
                            parentAccountType: "",
                            childAccount: "",
                            partyType: "",
                            creditAmount: 0,
                            debitAmount: 0
                          });
                        }
                      }
                      }
                    // style={{ whiteSpace: "nowrap" }}
                    >
                      Add Account
                    </Button>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: "15px" }}>
          <Grid item xs={12}>
            <Box fontWeight={"fontWeightBold"}>
              Notes
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              InputProps={{
                style: { color: "black" },
              }}
              onChange={(e) => { setNotes(e.target.value) }}
              name="notes"
              value={notes}
              placeholder="eg: XYZ Journal Entry"
              minRows={3}
              maxRows={5}
              size="small"
              disabled={isSaved}
            />
          </Grid>
        </Grid>
        {fields && fields.length !== 0 &&
          <Grid container justifyContent="flex-end" spacing={3} style={{ display: 'flex', alignItems: 'baseline', marginTop: "30px" }}>
            {isSaved && <Grid item>
              <Box display="none">
                <CompToPrintReceipt ref={componentRefInv} receiptInv={invReceipt} companyPrintLogo={companyPrintLogo} journalEntry={true} />
                <ReactToPrint
                  onBeforeGetContent={() => handleInvoicePrint(receiptNum)}
                  trigger={() => <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    ref={buttonRef}
                  >
                    PRINT Receipt
                  </Button>}
                  content={() => componentRefInv.current} />
              </Box>
            </Grid>}
            <Grid item>
              <Button
                size="medium"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSaved}
                style={{ marginRight: 10, width: 100 }}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="medium"
                onClick={cancelClick}
                color="secondary"
                variant="contained"
                style={{ marginRight: 10, width: 100 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>}
        {/* Select Customer or Supplier */}
        <Dialog fullWidth={true} maxWidth={"md"}
          open={selectedParty.open}>
          <Grid container>
            <Grid item container xs={12} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectParty({ ...selectedParty, open: false })
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <DialogContent>
            {(selectCustorSupType === "Customer") ?
              <Customers
                modal
                onRowSelect={async (customer) => {
                  if (selectedParty.lineKey >= 0) {
                    const accountKey4 = `accounts[${selectedParty.lineKey}].partyName`;
                    const accountKey5 = `accounts[${selectedParty.lineKey}].partyId`;
                    const accountKey6 = `accounts[${selectedParty.lineKey}].debitAmount`;
                    const accountKey7 = `accounts[${selectedParty.lineKey}].creditAmount`;
                    await setValue(accountKey4, customer && customer.fullName);
                    await setValue(accountKey5, customer.id);
                    await setValue(accountKey6, 0);
                    await setValue(accountKey7, 0);
                  }
                  setSelectParty({ ...selectedParty, open: false })
                }}
              /> :
              <SupplierSelection
                modal
                onRowSelect={async (supplier) => {
                  if (selectedParty.lineKey >= 0) {
                    const accountKey4 = `accounts[${selectedParty.lineKey}].partyName`;
                    const accountKey5 = `accounts[${selectedParty.lineKey}].partyId`;
                    const accountKey6 = `accounts[${selectedParty.lineKey}].debitAmount`;
                    const accountKey7 = `accounts[${selectedParty.lineKey}].creditAmount`;
                    await setValue(accountKey4, supplier && supplier.fullName);
                    await setValue(accountKey5, supplier.id);
                    await setValue(accountKey6, 0);
                    await setValue(accountKey7, 0);
                  }
                  setSelectParty({ ...selectedParty, open: false })
                }}
              />}
          </DialogContent>
        </Dialog>
      </form>

      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => {
          setIsError(false)
          setErrorMsg("")
        }}
      >
        <MuiAlert
          onClose={() => {
            setIsError(false)
            setErrorMsg("")
          }}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          {errMessage ? errMessage : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        autoHideDuration={5000}
        onClose={() => {
          setIsSuccess(false)
        }}
      >
        <MuiAlert
          onClose={() => {
            setIsSuccess(false)
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          Journal Entry Successfully Created
        </MuiAlert>
      </Snackbar>

    </FormContext>
  );
};

export default DirectPaymentComp;
