import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
} from "@material-ui/core";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

const Discounts = (props) => {
  const data = props.discountInfo ? props.discountInfo:[];
  return (
    <div>
      <div>
        <Card>
          <TableContainer component={Paper} >
            <Table style={{ paddingTop: 10 }} size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell>Sale Value</StyledHeaderCell>
                  <StyledHeaderCell align="left">Discounts</StyledHeaderCell>
                </TableRow>
              </TableHead>
              {(
                  <TableBody>
                    {data.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={props.modal ? 6 : 7} align="center">
                          <Typography variant="subtitle1">
                            Discounts not found
                      </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                        data.map((row, index) => (
                          <TableRow
                            style={{ cursor: props.modal ? "pointer" : "" }}
                            key={row.id}
                            hover={true}
                            onClick={(e) =>
                              props.onRowSelect && props.onRowSelect(row)
                            }
                          >
                            <TableCell component="th" scope="row">
                              {row.saleFromRange && row.saleToRange ?
                                `${row.saleFromRange} to  ${row.saleToRange}` :
                                row.saleFromRange ? `> ${row.saleFromRange}` : `< ${row.saleToRange}`}
                            </TableCell>
                            <TableCell align="left">
                              {row.discount}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                  </TableBody>
                )}
            </Table>
          </TableContainer>
        </Card>
      </div>
    </div>
  );
};

export default Discounts;
