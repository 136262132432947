import React, { useState } from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Customer from '../customers/Customer';
import Product from '../pos/POSProduct'
import SwipeableViews from 'react-swipeable-views';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import { currentUser } from "../login/loginSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  appwidth: {
    width: '100%',
    backgroundColor: '#4B9170'
  },
  tab:{
    backgroundColor: '#FFFF',
    width: '100%'
  }
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const loggedInUser = useSelector(currentUser);
  const history = useHistory();
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const [value, setValue] = useState(0);
  const isBillingAgent =
    roles.findIndex((role) => role.name === "BILLING AGENT") > -1 ? true : false;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid>
    <div className={classes.root}>
      <div className={classes.main}>
      <Grid container spacing={0}>
      <Grid container xs={12}  alignItems="flex-end" justify="flex-end">
        <Box mb={1} mr={2}>
        <Button
        variant="contained"
        onClick={() => history.push("/invoicePos")}
        color="primary"
        size="small"
        >Reprint Invoice
        </Button>
        </Box>
        <Box mb={1} >
        <Button
        variant="contained"
        onClick={() => history.push("/posProdInventory")}
        color="primary"
        size="small"
        >Product Inventory
        </Button>
        </Box>
        </Grid>
       <Grid item xs={12}>
      <Paper square className={classes.appwidth}>
        <Tabs value={value} 
        onChange={handleChange} 
        aria-label="pos tabs" 
        indicatorColor="primary"
        textColor="pos"
        >
          <Tab label="Customer" style={{color:"white",fontWeight:800}} {...a11yProps(0)} />
          <Tab label="Product" style={{color:"white",fontWeight:800}} {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <SwipeableViews
        index={value}
      >
      <TabPanel className={classes.tab} value={value} index={value}>
      <Customer isBillingAgent={isBillingAgent}/>
      </TabPanel>
      <TabPanel className={classes.tab} value={value} index={value}>
        <Product />
      </TabPanel>
      </SwipeableViews> 
      </Grid>
      </Grid>
      </div>
    </div>
    </Grid>
  );
}
