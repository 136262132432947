import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { locations } from "../common/lookupSlice";
import axios from "axios";
import _ from "lodash";
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Card,
  Collapse
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const PO = (props) => {
  // const location = useLocation();
  const [open, setOpen] = React.useState([]);
  const [mrData, setMrData] = useState([]);
  const [mrInnerData, setMrInnerData] = useState([]);
  const [mrNo, setMrNo] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMrLoading, setIsMrLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const allInventoryLocations = useSelector(locations);
  const  corporateLoc = allInventoryLocations.find((loc) => { return loc.type && loc.type.code === "CORPORATE"});
  let locId = corporateLoc && corporateLoc.id;
  const loadData = async () => {
    setIsLoading(true);
    try {
      const mrResult = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/materialrequest`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const validPoData = mrResult.data.materialRequest ? mrResult.data.materialRequest : [];
      const mrData = validPoData ? validPoData.length : 0;
      const poCollapse = [];
      for (let step = 0; step < mrData; step++) {
        poCollapse[step] = false;
      }
      setOpen(poCollapse)
      setMrData(validPoData);
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.message !== `Material Request Not found`) {
        props.setIsError(true);
      }
    }
    setIsLoading(false);
    setRefresh(false);
  };

  const loadPoData = async () => {
    setIsMrLoading(true)
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/materialrequest/${mrNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMrInnerData(result.data.materialRequest && result.data.materialRequest.lineItems
        ? result.data.materialRequest.lineItems : [])

    } catch (err) {
      setMrInnerData([]);
    }
    setIsMrLoading(false)
  }
  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mrNo) {
      loadPoData();
    }
  }, [mrNo]); // eslint-disable-line react-hooks/exhaustive-deps



  const handlePoClick = async (mrNum) => {
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/materialrequest/${mrNum}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const productList = result && result.data.materialRequest && result.data.materialRequest.lineItems ? result.data.materialRequest.lineItems : [];
    // const products = productList.filter(x=>x.product !==null && (x.product && x.product.inventories
    //   && x.product.inventories[0] && x.product.inventories[0].openingQty>0))
    const sales = productList.map(x => {
      const avail = (x.product && x.product.inventories && x.product.inventories.length > 0) ?
        x.product.inventories.find((inv) => {
          return inv.locationId === locId
        }) : 0;
      let availQty = avail.openingQty;
      return {
        "contactId":"",
        "productCode": x.product && x.product.productName,
        "productId": `${x.product && x.product.id}`,
        "id":x.product && x.product.id,
        "availableQty": availQty,
        "requiredQty": x.requiredQty,
        "uom": x.product && x.product.uom ? x.product.uom : "OTH"
      }
    })
    if (props.setSelectedMr) {
      props.remove();
      props.setShowMr(false)
      props.setSelectedMr({ mrNum: mrNum, products: sales })
    }
  }

  return (
    <div>
      <div>
        <Card>
          <TableContainer component={Paper} >
            <Table style={{ paddingTop: 10 }} size="small">
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {mrData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={props.modal ? 6 : 7} align="center">
                        <Typography variant="subtitle1">
                          Material Request not found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    mrData.map((row, index) => (
                      <React.Fragment>
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => {
                              const clone = _.cloneDeep(open)
                              clone.forEach((x, y) => {
                                if (y === index) {
                                  clone[y] = !clone[y]
                                }
                                else {
                                  clone[y] = false
                                }
                              })
                              setMrNo(row.mrNum)
                              setOpen(clone)
                            }
                            }>
                              {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row" >
                            {row.mrNum}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open[index]} timeout="auto" unmountOnExit>
                              <Box mt={2}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  onClick={() => handlePoClick(row.mrNum)}
                                  disabled={mrInnerData.length === 0}>Select MR</Button>
                              </Box>
                              <Box mt={2} mb={2}>
                                <TableContainer component={Paper} >
                                  <Table style={{ paddingTop: 10 }} size="small">
                                    <TableHead>
                                      <TableRow>
                                        <StyledHeaderCell>Product</StyledHeaderCell>
                                        <StyledHeaderCell align="left">Required Qty</StyledHeaderCell>
                                        <StyledHeaderCell align="left">UOM</StyledHeaderCell>
                                      </TableRow>
                                    </TableHead>
                                    {isMrLoading ? (
                                      <TableBody>
                                        <TableRow>
                                          <TableCell colSpan={7} align="center">
                                            <CircularProgress />
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    ) : (
                                      <TableBody>
                                        {mrInnerData.length === 0 ? (
                                          <TableRow>
                                            <TableCell colSpan={props.modal ? 6 : 7} align="center">
                                              <Typography variant="subtitle1">
                                                No Data.
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        ) : (
                                          mrInnerData.map((row, index) => (
                                            <TableRow
                                              style={{ cursor: props.modal ? "pointer" : "" }}
                                              key={row.id}
                                              hover={true}
                                              onClick={(e) =>
                                                props.onRowSelect && props.onRowSelect(row)
                                              }
                                            >
                                              <TableCell component="th" scope="row">
                                                {row.product && row.product.productName}
                                              </TableCell>
                                              <TableCell align="left">
                                                {row.requiredQty}
                                              </TableCell>
                                              <TableCell align="left">
                                              {row.product && row.product.uom}
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        )}
                                      </TableBody>
                                    )}
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow></React.Fragment>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

        </Card>
      </div>
    </div>
  );
};

export default PO;
