import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from '../common/util';
import { withStyles } from '@material-ui/styles';
import { transportModes } from "../common/constants"

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '1.6',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};
const tableStyle = {
  border: '1px solid #ccc',
  borderCollapse: 'collapse',
}

class CompToPrintInv extends React.Component {
  render() {
    const { classes, loggedInUser, orgBankDetails, orgTermsAndConditions } = this.props;
    const companyPrintLogo = loggedInUser && loggedInUser.org && loggedInUser.org.printLogo;
    const invDetails = this.props.customerInv != null && this.props.customerInv;
    const bankDetails = (invDetails && invDetails.companyBankdetails) ? invDetails.companyBankdetails : orgBankDetails;
    const lineItems = this.props.customerInv != null && this.props.customerInv.lineItems;
    const labourCharges = this.props.customerInv != null && this.props.customerInv.labourCharges;
    const transportCharges = this.props.customerInv != null && this.props.customerInv.transportCharges;
    const customer = this.props.customerInv != null && this.props.customerInv.customer && this.props.customerInv.customer;
    const invShipDetails = this.props.customerInv != null && this.props.customerInv.invShipDetails && this.props.customerInv.invShipDetails;
    const invEWayDetails = this.props.customerInv != null && this.props.customerInv.invEWayDetails && this.props.customerInv.invEWayDetails;
    // const invDispatchDetails = this.props.customerInv != null && this.props.customerInv.invDispatchDetails && this.props.customerInv.invDispatchDetails;
    const invCustmerDetails = this.props.customerInv != null && this.props.customerInv.invCustmerDetails && this.props.customerInv.invCustmerDetails;
    const custContacts = customer && customer.contacts && customer.contacts;
    const hsnSummary = invDetails && invDetails.HSNSummary;
    // const invOrgDetails = this.props.customerInv != null && this.props.customerInv.org && this.props.customerInv.org;
    let hsnTotal = 0;
    let hsnCGSTTotal = 0;
    let hsnSGSTTotal = 0;
    let hsnIGSTTotal = 0;
    hsnSummary && hsnSummary.map((row) => (
      hsnTotal += row.Total));
    hsnSummary && hsnSummary.map((row) => (
      hsnCGSTTotal += row.CGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnSGSTTotal += row.SGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnIGSTTotal += row.IGSTtotal));
    let quantity = 0;
    invDetails.lineItems && invDetails.lineItems.map((row) => (
      quantity += row.qty));
    let isIntraState = false;
    if (invDetails && invDetails.org) {
      if (invCustmerDetails) {
        isIntraState = (invCustmerDetails && invCustmerDetails.state && invDetails.org.state && (invDetails.org.state === invCustmerDetails.state)) ? true : false;
      } else {
        isIntraState = (customer && customer.state && invDetails.org.state && (invDetails.org.state === customer.state)) ? true : false;
      }
    }
    let transType = "";
    if (invEWayDetails && invEWayDetails.transportMode) {
      transType = transportModes.find((data) => {
        return (data.id === invEWayDetails.transportMode) ? data : null;
      })
    }
    let orgDeclaration = "";
    if (orgTermsAndConditions && orgTermsAndConditions.length > 0) {
      let findOrgCond = orgTermsAndConditions.find((orgCond) => {
        return (orgCond && orgCond.termsConditionsType && (orgCond.termsConditionsType === "Credit Note")) ? orgCond : null;
      })
      orgDeclaration = findOrgCond && findOrgCond.declaration
    }

    return (
      <div >
        <div className="row">
          {/* <div align="left" className="col-sm-6">
            <img alt="comLogo" src={companyPrintLogo} 
            style={{ height: "100px" }} />
          </div> */}
          {(invDetails && invDetails.eInvIRN) ? <table style={{ width: "100%", height: "250px", margin: "0px 15px", padding: "0px" }}>
            <tr>
              <td align="left" style={{ verticalAlign: "bottom", paddingBottom: "15px" }}>
                <span style={pStyle}><b>IRN</b>&nbsp;{invDetails && invDetails.eInvIRN}</span><br></br><br></br>
                <span style={pStyle}><b>Ack No. </b>&nbsp;{invDetails && invDetails.eInvAckNo}</span><br></br><br></br>
                <span style={pStyle}><b>Ack Date </b>&nbsp;{invDetails && invDetails.eInvAckDt && formatDate(invDetails.eInvAckDt)}</span>
              </td>
              <td align="right" style={{ height: "250px" }}>{(invDetails && invDetails.eInvQRCodeImg) ? <img alt="qrCodeImg" src={invDetails.eInvQRCodeImg}
                style={{ width: "250px", height: "250px", alignItems: "right" }} /> : null} </td>
            </tr>
          </table> : null}
        </div>
        <div style={divStyle} align="center">
          {/* Invoice  */}
          {/* <Helmet><title>Print Invoice</title></Helmet> */}
          <Helmet><title></title></Helmet>
          <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', textAlign: 'center', fontWeight: 'bold', lineHeight: '2', }}>Credit Note</span>
          <table style={tableStyle} width="95%" border="1px" color="#ccc">
            <tr>
              <td rowSpan="4" width="60%">
                <table width="100%" border="0px" color="white" >
                  <tr>
                    <td width="60%">
                      <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name.toUpperCase()}<br /></b></span>
                      <span style={pStyle2}>
                        {invDetails && invDetails.location && (invDetails.location.address1.toUpperCase())}
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address2.toUpperCase())}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address3.toUpperCase() + '-' + invDetails.location.pincode)}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.locationstate ? invDetails.locationstate.name : invDetails.location && invDetails.location.state}</div>
                        <span style={pStyle2}>{invDetails && invDetails.location && invDetails.location.mobileNumber ? invDetails.location.mobileNumber : ""}<br /></span>
                      </span>
                      <span style={pStyle2}>GSTIN/UIN: {invDetails && invDetails.location && invDetails.location.GSTIN ? invDetails.location.GSTIN : invDetails && invDetails.org && invDetails.org.GSTIN ? invDetails.org.GSTIN : "Not Available"}<br /></span>
                      <span style={pStyle2}>Email: {invDetails && invDetails.location && invDetails.location.email ? invDetails.location.email : invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /></span>

                    </td>
                    <td rowSpan="4" width="50%" align="right">
                      <img alt="comLogo" src={companyPrintLogo}
                        style={{ height: "100px", width: "200px" }} />
                    </td>
                  </tr>
                </table>
              </td>
              <td width="20%">
                {(invDetails && invDetails.eInvEwbNo) ? <div>
                  <table>
                    <tr>
                      <td style={pStyle2}>
                        Invoice No.
                      </td>
                      <td style={pStyle2} align="right">
                        e-Way Bill No.
                      </td>
                    </tr>
                    <tr>
                      <td style={pStyle2}>
                        <b>{this.props.customerInv && this.props.customerInv.creditNoteNum}</b>
                      </td>
                      <td style={pStyle2} align="right">
                        <b>{invDetails.eInvEwbNo}</b>
                      </td>
                    </tr>
                  </table>
                </div> :
                  <div><span style={pStyle2}>Invoice No.</span><br />
                    <span style={pStyle2}><b>{this.props.customerInv && this.props.customerInv.creditNoteNum}</b></span></div>}


              </td>
              <td width="20%"><span style={pStyle2}>Dated. <br /><b>{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</b></span></td>
            </tr>
            <tr>
              <td><span style={pStyle2}>Delivery Note.<br />&nbsp;</span></td>
              <td><span style={pStyle2}>Mode/Terms of Payment<br /><b>{this.props.customerInv &&
                this.props.customerInv.payments && this.props.customerInv.payments.length > 0 &&
                this.props.customerInv.payments[0].paymentMode &&
                this.props.customerInv.payments[0].paymentMode.description}</b></span></td>
            </tr>
            <tr>
              <td><span style={pStyle2}>Reference No. & Date <br />&nbsp;</span><span style={pStyle2}>{(invEWayDetails && invEWayDetails.vehicleNumber) ? invEWayDetails.vehicleNumber : invDetails && invDetails.vehicleNo}&nbsp;{(invDetails && (invDetails.vehicleNo || (invEWayDetails && invEWayDetails.vehicleNumber)) && this.props.customerInv && this.props.customerInv.invoiceDate) ? <b>Dt. </b> : ""}{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</span></td>
              <td><span style={pStyle2}>Other Reference(s)<br />&nbsp;</span><span style={pStyle2}><b>{invDetails && invDetails.jobs && "JC-" + invDetails.jobs.id}</b></span></td>

            </tr>
            <tr>
              <td><span style={pStyle2}>Buyer's Order No.<br /><b>{this.props.customerInv && this.props.customerInv.purchaseOrder && this.props.customerInv.purchaseOrder.poNum}</b></span></td>
              <td><span style={pStyle2}>Dated<br /><b>{this.props.customerInv && this.props.customerInv.purchaseOrder && formatDate(this.props.customerInv.purchaseOrder.orderDate)}</b></span></td>
            </tr>
            <tr>
              <td rowSpan="4">
                <span style={pStyle2}>
                  Consignee (Ship to)<br /> <b>{(invShipDetails) ? invShipDetails.tradeName : customer && customer.fullName}</b><br />
                  {(invShipDetails) ? (invShipDetails.address1 + ' \n' + invShipDetails.address2 + ' \n' + invShipDetails.address3 + ' \n' + invShipDetails.state + '-' + invShipDetails.pincode) :
                    customer && (customer.address1 + ' \n' + customer.address2 + ' \n' + customer.address3 + ' \n' + customer.state + '-' + customer.pincode)}<br />
                  GSTIN/UIN: {(invShipDetails) ? invShipDetails.GSTIN : customer && customer.GSTIN}<br />
                  State: {(invShipDetails) ? invShipDetails.state : customer && customer.state} {(invShipDetails && invShipDetails.GSTIN) ? ' Code: ' + invShipDetails.GSTIN.slice(0, 2) : (customer && customer.GSTIN) ? ' Code: ' + customer.GSTIN.slice(0, 2) : ' '}<br />
                  Contact Person: {custContacts ? custContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                  Contact: {custContacts ? custContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                </span>
              </td>
              <td><span style={pStyle2}>Dispatch Document No.<br />  <b>{(invEWayDetails && invEWayDetails.transporterDocNo) ? invEWayDetails.transporterDocNo : invDetails && invDetails.dispatchDocNo}</b></span></td>
              <td><span style={pStyle2}>Delivery Note Date<br />&nbsp;</span></td>
            </tr>
            <tr>
              <td><span style={pStyle2}>Dispatched through<br />{(transType) ? <b>{transType.id}-{transType.name}</b> : <b>{invDetails && invDetails.dispatchThrough}</b>}</span></td>
              <td><span style={pStyle2}>Destination<br /><b>{invShipDetails ? invShipDetails.address3 + " " + invShipDetails.locality :
                invCustmerDetails ? invCustmerDetails.address3 + " " + invCustmerDetails.locality : customer ? customer.address3 + " " + customer.locality : ""}</b></span></td>
            </tr>
            <tr>
              <td><span style={pStyle2}>Vehicle No.<br /><b>{(invEWayDetails && invEWayDetails.vehicleNumber) ? invEWayDetails.vehicleNumber : invDetails && invDetails.vehicleNo}</b></span></td>
              <td><span style={pStyle2}>Vehicle LR-RR<br /><b>{invDetails && invDetails.vehicleLLR}</b></span></td>
            </tr>
            <tr>
              <td colspan="2" style={{ borderBottom: '1px solid #fff' }}><span style={pStyle2}></span></td>
            </tr>
            <tr>
              <td>
                <span style={pStyle2}>
                  Buyer (Bill to) <br /> <b>{(invCustmerDetails) ? invCustmerDetails.tradeName : customer && customer.fullName}</b><br />
                  {(invCustmerDetails) ? (invCustmerDetails.address1 + ' \n' + invCustmerDetails.address2 + ' \n' + invCustmerDetails.address3 + ' \n' + invCustmerDetails.state + '-' + invCustmerDetails.pincode) :
                    customer && (customer.address1 + ' \n' + customer.address2 + ' \n' + customer.address3 + ' \n' + customer.state + '-' + customer.pincode)}<br />
                  GSTIN/UIN: {(invCustmerDetails) ? invCustmerDetails.GSTIN : customer && customer.GSTIN}<br />
                  State: {(invCustmerDetails) ? invCustmerDetails.state : customer && customer.state} {(invCustmerDetails && invCustmerDetails.GSTIN) ? ' Code: ' + invCustmerDetails.GSTIN.slice(0, 2) : (customer && customer.GSTIN) ? ' Code: ' + customer.GSTIN.slice(0, 2) : ' '}<br />
                  Contact Person: {custContacts ? custContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                  Contact: {custContacts ? custContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                </span>
              </td>
              <td colspan="2"></td>
            </tr>
          </table>
          <div style={{ width: '100%', padding: '5px 2px' }}>
            <table style={tableStyle} width="95%">
              <thead>
                <tr>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Sl No.</span></td>
                  <td width="25%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Description of Goods/Services</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>GST Rate</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Quantity</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>per</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Disc.%</span></td>
                  <td width="15%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                </tr>
              </thead>
              <tbody>
                {((lineItems && lineItems.length === 0) && (labourCharges === 0 && transportCharges === 0)) ? (
                  <tr>
                    <td colspan="9" align="center">
                      <span style={pStyle2}>No records found</span>
                    </td>
                  </tr>
                ) : (
                  lineItems && lineItems.map((row, index) => (
                    <tr
                      key={index}
                      hover={true}
                    >
                      <td align="center" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {index + 1}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.productName}</b>
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.HSNCode}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.IGST} %
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.qty} {row.uom}</b>
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.unitPrice ? (Math.round(row.unitPrice * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.uom}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.discountPct ? (Math.round(row.discountPct * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</b>
                        </span>
                      </td>
                    </tr>
                  ))
                )}
                {lineItems && lineItems.length > 0 &&
                  <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>{invDetails.subtotal ? (Math.round(invDetails.subtotal * 100) / 100).toFixed(2) : '0.00'}</span>
                    </td>
                  </tr>
                }
                {labourCharges && labourCharges > 0 ? <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Labour Charges</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.labourCharges ? (Math.round(invDetails.labourCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr> : ''}
                {transportCharges && transportCharges > 0 ? <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Transportation Charges (Sales)/packing</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.transportCharges ? (Math.round(invDetails.transportCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr> : ''}
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>CGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.CGST ? (Math.round(invDetails.CGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>SGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.SGST ? (Math.round(invDetails.SGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {(!isIntraState) && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>IGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.IGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {invDetails.roundOff !== 0 && <tr>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}>&nbsp;</td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><i>{invDetails.roundOff < 0 ? 'Less:' : ''}</i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={pStyle2}><b><i>Round Off</i></b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.roundOff ? (invDetails.roundOff > 0 ? (Math.round(invDetails.roundOff * 100) / 100).toFixed(2) : '(-)' + (Math.round(invDetails.roundOff * (-100)) / 100).toFixed(2)) : '0.00'}</b></span>
                  </td>
                </tr>}
                <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{quantity}</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.total ? (Math.round(invDetails.total * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody>
                <tr>
                  <td width="50%" style={{ borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Amount Chargeable(in words) :</span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}><i>E. &amp; O.E</i>&nbsp;</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.amountInWords}</b><br /><br /></span>
                  </td>
                </tr>
                {invDetails.remarkNote ? <tr>
                  <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                    <span style={pStyle}><b>Remarks :</b>&nbsp;{invDetails.remarkNote}<br /><br /></span>
                  </td>
                </tr> : null}
                <tr>
                  <td colspan="2">
                    &nbsp;<br />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <span style={pStyle2}>Company’s PAN : <b>{invDetails && invDetails.org && invDetails.org.PAN ? invDetails.org.PAN : "Not Available"}</b></span>
                  </td>
                </tr>
                <tr>
                  <td width="50%" align="left">
                    <span style={pStyle2}><u>Declaration</u><br />{orgDeclaration}</span>
                  </td>
                  <td>
                    <span style={pStyle2}>Company's Bank Details<br />
                      Bank Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{bankDetails && bankDetails.bankName}</b><br />
                      A/c No. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{bankDetails && bankDetails.acNumber}</b><br />
                      Branch &amp; IFS Code &nbsp;: <b>{bankDetails && bankDetails.branchName} &amp; {bankDetails && bankDetails.ifsCode}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Customer Seal and Signature<br /><br /><br /></span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* HSN Summary Section */}
          {/* {(hsnSummary && hsnSummary.length > 0) &&
            <div className={classes.print}>
              <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '15px', textAlign: 'center', fontWeight: 'bold', lineHeight: '1.6', }}>
                Tax Invoice<br />(Tax Analysis)
              </span>
              <div>
                <table style={tableStyle} width="90%" border="1px" color="#ccc">
                  <tr>
                    <td align="left" width="50%" style={{ borderRight: '1px solid #fff', borderBottom: '1px solid #fff' }}>
                      <span style={pStyle}>Invoice No. <b>{this.props.customerInv && this.props.customerInv.creditNoteNum}</b></span>
                    </td>
                    <td align="right" width="50%" style={{ borderBottom: '1px solid #fff' }}>
                      <span style={pStyle}>Dated <b>{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</b>&nbsp;</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center">
                      <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name}</b></span><br />
                      <span style={pStyle}>{invDetails && invDetails.org && (invDetails.org.address1)}</span><br />
                      <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address2)}</div></span>
                      <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address3 + '-' + invDetails.org.pincode)}</div></span>
                      <span style={pStyle}>{invDetails && invDetails.org && invDetails.org.phoneNo ? invDetails.org.phoneNo : ""}<br /></span>
                      <span style={pStyle}>State :{invDetails && invDetails.org && (invDetails.org.state)} Code: {invDetails && invDetails.org && invDetails.org.GSTIN.slice(0, 2)}</span><br />
                      <span style={pStyle}>GSTIN/UIN: {invDetails && invDetails.org && invDetails.org.GSTIN ? invDetails.org.GSTIN : "Not Available"}</span><br />
                      <span style={pStyle}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}</span><br />
                      <span style={pStyle}>Party : <span style={{ fontWeight: 'bold' }}>{(invCustmerDetails) ? invCustmerDetails.tradeName : customer && customer.fullName}</span><br />
                        <span style={pStyle}>
                          <div style={{ whiteSpace: 'pre-line' }}>
                            {(invCustmerDetails && invCustmerDetails.address1) ? invCustmerDetails.address1 : (customer && customer.address1) ? customer.address1 + ' \n' : ''}
                            {(invCustmerDetails && invCustmerDetails.address2) ? invCustmerDetails.address2 : (customer && customer.address2) ? customer.address2 + ' \n' : ''}
                            {(invCustmerDetails && invCustmerDetails.address3) ? invCustmerDetails.address3 : (customer && customer.address3 + '-' + customer.pincode) ? customer.address3 + '-' + customer.pincode + ' \n' : ''}
                          </div>
                        </span>
                        <span style={pStyle}>GSTIN/UIN: {(invCustmerDetails && invCustmerDetails.GSTIN) ? invCustmerDetails.GSTIN : (customer && customer.GSTIN) ? customer.GSTIN : 'Not Available'}</span><br />
                        <span style={pStyle}></span>State: {(invCustmerDetails && invCustmerDetails.state) ? invCustmerDetails.state : (customer && customer.state) ? customer.state : 'Not Available'} {(invCustmerDetails && invCustmerDetails.GSTIN) ? ' Code: ' + invCustmerDetails.GSTIN.slice(0, 2) : (customer && customer.GSTIN) ? ' Code: ' + customer.GSTIN.slice(0, 2) : ' '}</span><br />
                    </td>
                  </tr>
                </table>
                {(!isIntraState) ?
                  (
                    <div>
                      <table style={tableStyle} width="90%">
                        <thead>
                          <tr>
                            <td align="center" width="50%" rowSpan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                            <td align="center" width="15%" rowSpan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Taxable <br />Value</span></td>
                            <td align="center" width="20%" colSpan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Integrated Tax</span></td>
                            <td align="center" width="15%" rowSpan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Total <br /> Tax Amount</span></td>
                          </tr>
                          <tr>
                            <td align="center" width="7%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                            <td align="center" width="13%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                          </tr>
                        </thead>
                        <tbody>
                          {hsnSummary && hsnSummary.length === 0 ? (
                            <tr>
                              <td colSpan={7} align="center">
                                No records found
                              </td>
                            </tr>
                          ) : (
                            hsnSummary && hsnSummary.map((row, index) => (
                              <tr
                                key={index}
                                hover={true}
                              >
                                <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.HSNCode}</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.Total ? (Math.round(row.Total * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.IGST ? (Math.round(row.IGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.IGSTtotal ? (Math.round(row.IGSTtotal * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{(row.IGSTtotal ? (Math.round(row.IGSTtotal * 100) / 100).toFixed(2) : '0.00')}</span></td>
                              </tr>
                            ))
                          )}
                          <tr>
                            <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                            <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnTotal ? (Math.round(hsnTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                            <td align="right" colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnIGSTTotal ? (Math.round(hsnIGSTTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                            <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{invDetails && invDetails.totalTax ? (Math.round(invDetails.totalTax * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.printTogether}>
                        <table style={tableStyle} width="90%">
                          <tbody>
                            <tr>
                              <td colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                <span style={pStyle2}>Tax Amount (in words) :</span> <span style={pStyle}><b>{invDetails && invDetails.taxAmountInWords}</b></span><br /><br />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                <span style={pStyle2}><br /><br /><br /></span>
                              </td>
                              <td width="50%" align="right" style={{ borderRight: '1px solid #ccc' }}>
                                <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <table style={tableStyle} width="90%">
                        <thead>
                          <tr>
                            <td align="center" width="30%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                            <td align="center" width="15%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Taxable <br />Value</span></td>
                            <td align="center" width="20%" colspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Central Tax</span></td>
                            <td align="center" width="20%" colspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>State Tax</span></td>
                            <td align="center" width="15%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Total <br /> Tax Amount</span></td>
                          </tr>
                          <tr>
                            <td align="center" width="7%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                            <td align="center" width="13%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                            <td align="center" width="7%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                            <td align="center" width="13%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                          </tr>
                        </thead>
                        <tbody>
                          {hsnSummary && hsnSummary.length === 0 ? (
                            <tr>
                              <td colSpan={5} align="center">
                                No records found
                              </td>
                            </tr>
                          ) : (
                            hsnSummary && hsnSummary.map((row, index) => (
                              <tr
                                key={index}
                                hover={true}
                              >
                                <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.HSNCode}</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.Total ? (Math.round(row.Total * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.CGST ? (Math.round(row.CGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.CGSTtotal ? (Math.round(row.CGSTtotal * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.SGST ? (Math.round(row.SGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{row.SGSTtotal ? (Math.round(row.SGSTtotal * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>{(Math.round((row.CGSTtotal + row.SGSTtotal) * 100) / 100).toFixed(2)}</span></td>
                              </tr>
                            ))
                          )}
                          <tr>
                            <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                            <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnTotal ? (Math.round(hsnTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                            <td align="right" colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnCGSTTotal ? (Math.round(hsnCGSTTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                            <td align="right" colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnSGSTTotal ? (Math.round(hsnSGSTTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                            <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{invDetails && invDetails.totalTax ? (Math.round(invDetails.totalTax * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.printTogether}>
                        <table style={tableStyle} width="90%">
                          <tbody>
                            <tr>
                              <td colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                <span style={pStyle2}>Tax Amount (in words) :</span> <span style={pStyle}><b>{invDetails && invDetails.taxAmountInWords}</b></span><br /><br />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc' }}>
                                <span style={pStyle2}><br /><br /><br /></span>
                              </td>
                              <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>} */}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CompToPrintInv);