import React, { useState, useEffect } from "react";
import { Box, Breadcrumbs, Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice";
import { locations } from "../common/lookupSlice"
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import SearchProduct from "../productGroups/searchProductSale";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";

const InventoryMovement = () => {

  const history = useHistory();
  const location = useLocation();
  const voucherData = location.state;
  const token = localStorage.getItem("authToken");
  const loggedInUser = useSelector(currentUser);
  const allLocations = useSelector(locations);
  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isStockEntry = (chars[1]) ? chars[1].toUpperCase() === "STOCKENTRY" : false;
  const allInventoryLocations = loggedInUser && loggedInUser.locations ? loggedInUser.locations : [];
  const inventoryLocations = allInventoryLocations.filter((loc) => { return loc.type !== "CORPORATE" && loc.GSTIN && loc.ERPWarehouseId });
  const initialLoc = (isStockEntry) ? allLocations.find((loc) => {
    return loc.type && loc.type.code === "CORPORATE"
  })
    : inventoryLocations && inventoryLocations[0];
  const [selectProduct, setSelectProduct] = useState({ open: false, test: null });
  const [store, setStore] = useState((voucherData && voucherData.sourceLocation) ? voucherData.sourceLocation.id : initialLoc && initialLoc.id);
  const [disStore, setDislocStore] = useState((voucherData && voucherData.destinationLocation) ? voucherData.destinationLocation.id : null);
  const [safetyStockWarning, setSafetyStockWarning] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [isSaved, setIsSaved] = useState(voucherData ? (voucherData.stockVoucherNum && true) : false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const { handleSubmit, control, errors, reset, register, setValue, getValues } = useForm({
    defaultValues: {
      sales: voucherData ? voucherData.lineItems : [],
    },
  });

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "sales",
  });

  const onSubmit = async (data) => {
    try {
      const postreq = { sourceLocationId: (isStockEntry) ? null : store, destinationLocationId: data.locality };
      const lineItems = data && data.sales && data.sales.map(x => {
        const item = {
          productId: x.productId && Number(x.productId),
          movedQty: x.qty && Number(x.qty),
          productRate: Number(x.rate),
          landingCost: (x.landingCost) ? Number(x.landingCost) : 0
        }
        return item;
      });
      postreq.lineItems = lineItems;
      postreq.stockEntryType = (isStockEntry) ? "STOCKENTRY" : "STOCKMOVEMENT"

      let corporateLoc = await allLocations && allLocations.find((loc) => {
        return loc.type && loc.type.code === "CORPORATE"
      })
      postreq.corporateLocId = corporateLoc && corporateLoc.id
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/stockvoucher`,
        postreq,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setIsSaved(true);
        setMessage(result && result.data && result.data.message + " " + result.data.stockVoucherId);
        setIsSuccess(true);
      }
    } catch (err) {
      setIsError(true)
    }
  }

  const handleClose = () => {
    reset();
  }

  useEffect(() => {
    reset();
  }, [store]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
        <Grid container xs={12} spacing={2}>
          <Grid item container xs={12} direction="column">
            <Grid item>
              <Box>
                <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                  <Link color="inherit" onClick={() => history.push("/")} className="underLine">
                    Home
                  </Link>
                  <Link color="inherit" onClick={() => { window.location.href = '/inventoryhome' }} className="underLine">
                    Inventory
                  </Link>
                  <Typography component="span" color="textPrimary">
                    {(isStockEntry) ? "Stock Entry" : "Inventory Movement Entry"}
                  </Typography>
                </Breadcrumbs>
              </Box>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={2} style={{ marginLeft: '0px' }} >
            {(isStockEntry) ? null : <Grid item container xs={3} md={3} >
              <Typography style={{ width: '100%' }}>
                <Box variant="caption" style={{ fontWeight: "bold" }}>Source Location</Box>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                >
                  <Select style={{ color: "black" }}
                    name="locality"
                    value={store}
                    onChange={(e) => {
                      setStore(e.target.value);
                    }}
                    variant="outlined"
                    disabled={(voucherData && voucherData.stockVoucherNum) || isSaved}
                  >
                    {inventoryLocations.map(function (locality) {
                      return (
                        <MenuItem key={locality} value={locality.id} disabled={(disStore === locality.id)}>
                          {locality.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl></Typography>
            </Grid>}
            <Grid item container xs={3} md={3} >
              <Typography style={{ width: '100%' }}>
                <Box variant="caption" style={{ fontWeight: "bold" }}>Target Location</Box>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={Boolean(errors.locality)}
                >
                  <Controller
                    as={
                      <Select style={{ color: "black" }} onClick={(e) => {
                        setDislocStore(e.target.value);
                      }}>
                        {inventoryLocations.map(function (locality) {
                          return (
                            <MenuItem key={locality} value={locality.id} disabled={(store === locality.id)}>
                              {locality.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                    name="locality"
                    size="small"
                    variant="outlined"
                    rules={{ required: "Store is required" }}
                    control={control}
                    disabled={(voucherData && voucherData.stockVoucherNum) || isSaved}
                    defaultValue={voucherData ? (voucherData.destinationLocation && voucherData.destinationLocation.id) : ""}
                  />
                </FormControl>
                <FormHelperText error>
                  {errors.locality && errors.locality.message}
                </FormHelperText></Typography>
            </Grid>
          </Grid>
          {inventoryLocations.length > 0 ? <Grid item container xs={12} direction="column">
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledHeaderCell align="left">Product Name</StyledHeaderCell>
                    {!(isSaved) && <StyledHeaderCell align="left">Add Qty</StyledHeaderCell>}
                    {(isSaved) && <StyledHeaderCell align="left">Added Qty</StyledHeaderCell>}
                    <StyledHeaderCell align="left">Cost Rate Per Unit</StyledHeaderCell>
                    {(isStockEntry) && <StyledHeaderCell align="left">Landing Cost Per Unit</StyledHeaderCell>}
                    {!(voucherData && voucherData.stockVoucherNum) && !(isStockEntry) && <StyledHeaderCell align="left">Available Qty</StyledHeaderCell>}
                    <StyledHeaderCell align="left">UOM</StyledHeaderCell>
                    {!(isSaved) && <StyledHeaderCell align="left"></StyledHeaderCell>}
                  </TableRow>
                </TableHead>
                {fields.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <Button
                          size="small"
                          variant="contained"
                          align="center"
                          color="primary"
                          onClick={(e) => {
                            append({
                              moveId: "",
                              productName: "",
                              qty: "",
                              rate: "",
                              avail: "",
                              uom: "",
                            });
                          }}
                        >
                          Add Product
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                <TableBody>
                  {fields.map((move, index) => (
                    <TableRow key={move.id}>
                      <TableCell align="left">
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productName
                          )}
                          inputRef={register({
                            required: "Product is required",
                          })}
                          onClick={(e) => {
                            !(isSaved) && setSelectProduct({ ...selectProduct, open: true, lineKey: index, create: true })
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                                {!(isSaved) && <SearchIcon onClick={(e) => setSelectProduct({ ...selectProduct, open: true })} />}
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          name={`sales[${index}].productName`}
                          key={move.id} disabled
                          defaultValue={move.productName ? move.productName : move.product ? (move.product.productName && move.product.productName) : ''}
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productId
                          )}
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].productId`}
                          key={move.id} disabled
                          defaultValue={move.productId ? move.productId : move.product ? (move.product.productId && move.product.productId) : ''}
                        // defaultValue={props.form.data.sales && (props.form.data.sales)[index] ? (props.form.data.sales)[index].product && (props.form.data.sales)[index].product.id:''}
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productData
                          )}
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].productData`}
                          key={move.id}
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productName &&
                            errors.sales[index].productName.message}
                        </FormHelperText>
                        <Dialog
                          fullWidth={true}
                          maxWidth={"sm"}
                          open={selectProduct.open}
                          onClose={() => {
                            setSelectProduct({ ...selectProduct, open: false });
                          }}
                        >
                          <DialogTitle>
                            <Grid container>
                              <Grid item container xs={9} alignItems="center">
                                <Grid item>
                                  Select Product
                                </Grid>
                              </Grid>
                              <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
                                <Grid item>
                                  <IconButton
                                    onClick={() => {
                                      setSelectProduct({ ...selectProduct, open: false });
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </DialogTitle>
                          <DialogContent >
                            <SearchProduct
                              form={selectProduct}
                              setValue={setValue}
                              getValues={getValues}
                              sales={getValues({ nest: true })["sales"]}
                              setIsWarning={setIsWarning}
                              noQtyValidation={isStockEntry}
                              saleIdentifier={true}
                              isInventoryMove={true}
                              setSafetyStockWarning={setSafetyStockWarning}
                              addonData={{ locationId: store }}
                              cancel={() => {
                                setSelectProduct({ ...selectProduct, open: false });
                              }}
                              success={() => {
                                setSelectProduct({ ...selectProduct, open: false, success: true });
                              }}
                            />
                          </DialogContent>
                        </Dialog>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].qty
                          )}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Quantity is required",
                            pattern: {
                              value: /^[1-9]+[0-9]*$/,
                              message: "Quantity is required",
                            },
                          })}
                          onChange={(e) => {
                            const qty = e.target.value;
                            const saleVal = getValues({ nest: true })["sales"];
                            if (!(isStockEntry) && saleVal[index] && qty >= Number(saleVal[index].avail)) {
                              const qtyKey = `sales[${index}].qty`;
                              setValue(qtyKey, Number(saleVal[index].avail))
                            }
                          }}

                          size="small"
                          name={`sales[${index}].qty`}
                          defaultValue={move.qty ? move.qty : move.movedQty ? move.movedQty : ''}
                          variant="standard" disabled={(voucherData && voucherData.stockVoucherNum) || isSaved}
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].qty &&
                            errors.sales[index].qty.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].rate
                          )}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={(isStockEntry) ? register({
                            required: "Rate is required",
                            pattern: {
                              value: /^[1-9]\d*(\.\d+)?$/,  // This allows numbers greater than 0, including decimals
                              message: "Rate must be greater than 0",
                            },
                          }) : register({})
                          }
                          size="small"
                          name={`sales[${index}].rate`}
                          disabled={(voucherData && voucherData.stockVoucherNum) || isSaved || !(isStockEntry)}
                          defaultValue={move.rate ? move.rate : move.product ? (move.product.purchasePrice && move.product.purchasePrice) : 0}
                          variant="standard"
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].rate &&
                            errors.sales[index].rate.message}
                        </FormHelperText>
                        <TextField
                          variant="standard"
                          fullWidth
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].actualRate`}
                          key={move.id} disabled={(voucherData && voucherData.stockVoucherNum) || isSaved}
                        />
                      </TableCell>
                      {(isStockEntry) && <TableCell align="left">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          // inputRef={register({
                          // })}
                          inputRef={register({
                          })}
                          size="small"
                          name={`sales[${index}].landingCost`}
                          disabled={(voucherData && voucherData.stockVoucherNum) || isSaved}
                          defaultValue={move.landingCost ? move.landingCost : 0}
                          variant="standard"
                        />
                      </TableCell>}
                      {!(voucherData && voucherData.stockVoucherNum) && !(isStockEntry) && <TableCell align="left">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          disabled
                          size="small"
                          name={`sales[${index}].avail`}
                          defaultValue={move.avail}
                          inputRef={register({
                          })}
                          variant="standard"
                        />
                      </TableCell>}
                      <TableCell align="left">
                        <TextField
                          disabled
                          size="small"
                          name={`sales[${index}].uom`}
                          inputRef={register({
                          })}
                          defaultValue={move.uom ? move.uom : move.product ? (move.product.uom && move.product.uom) : ''}
                          variant="standard"
                        />
                      </TableCell>
                      {!(isSaved) && <TableCell align="right" style={{ width: '8%' }}>
                        <IconButton
                          size="small"
                          onClick={(e) =>
                            insert(index + 1, {
                              moveId: "",
                              productName: "",
                              qty: "",
                              rate: "",
                              avail: "",
                              uom: "",
                              productId: "",
                            })
                          }
                        >
                          <AddIcon color="secondary" />
                        </IconButton>
                        &nbsp;
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            remove(index);
                          }}
                        >
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid> : ""}
        </Grid>
        {fields.length !== 0 && <Grid item container xs={12} justifyContent="flex-end">
          {isSaved ? "" : <Box mt={2}>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
            >
              Save
            </Button>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => handleClose()}
            >
              Clear
            </Button>
          </Box>}
        </Grid>}
        <Snackbar
          open={isWarning}
          autoHideDuration={3000}
          onClose={() => setIsWarning(false)}
        >
          <MuiAlert
            onClose={() => setIsWarning(false)}
            elevation={6}
            variant="filled"
            severity="warning"
          >
            {"Product is currently not available"}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={() => setIsError(false)}
        >
          <MuiAlert
            onClose={() => setIsError(false)}
            elevation={6}
            variant="filled"
            severity="error"
          >
            {message ? message : "Some error occured. Please retry after sometime."}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={isSuccess}
          onClose={() => setIsSuccess(false)}
          autoHideDuration={5000}
        >
          <MuiAlert
            onClose={() => setIsSuccess(false)}
            elevation={6}
            variant="filled"
            severity="success"
          >
            {message && message}
          </MuiAlert>
        </Snackbar>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={safetyStockWarning}
          onClose={() => {
            setSafetyStockWarning(false);
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item container xs={10} alignItems="center">
                <Grid item >
                  <Typography>Product availability is low. Check Safety Stock.</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={2} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setSafetyStockWarning(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} >
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => setSafetyStockWarning(false)}
                    color="primary"
                    size="medium"
                    variant="contained"
                    style={{ marginRight: 10 }}
                  >
                    OK
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogTitle>
        </Dialog>
      </form>
    </Grid>
  );
};

export default InventoryMovement;