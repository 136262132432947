import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ERPBankAccountList } from "../common/lookupSlice";
import { jsonToCsv, downloadCSVFile } from '../common/util';
import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  Breadcrumbs,
  Link,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { sampleBankTransactionTemplate } from "../common/constants";

let errorMsg;

function SuppliersForm(props) {
  const token = localStorage.getItem("authToken");
  const { id } = useParams();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [fileData, setFileData] = useState("");
  const { handleSubmit, errors, register } = useForm({});
  const [create, setCreate] = React.useState({});
  // const stateObj = useSelector(custStates);
  // const states = stateObj ? stateObj.map(state => state.name) : [];
  const bankAccountList = useSelector(ERPBankAccountList);
  const [accountValue, setAccountValue] = React.useState('');
  const [inputAccountalue, setInputAccountValue] = React.useState('');
  const [saved, setSaved] = React.useState(false);
  // var queryStringData = window.location.search;
  // var chars = queryStringData.split('=');
  // let isPm = (chars[1]) ? chars[1].toUpperCase() === "YES" : false;

  const fetchData = async (id) => {
    if (id) {
      try {
        // const result = await axios.get(
        //   `${process.env.REACT_APP_SERVICE_URL}/bankdetials/${id}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );
        // setSupplierData(result.data);
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
    }
  }

  useEffect(() => {
    fetchData(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFileInputChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        setFileData(file);
      }
    } catch (err) {
      console.log(err);
      setFileData("");
    }
  };

  const onSubmit = async (comapnyBankData) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    let isContinue = (accountValue && accountValue.bank && fileData) ? true : false;
    comapnyBankData.id = id;
    //comapnyBankData.state = selectedState.length>0 ? selectedState[0].code:'';
    comapnyBankData.bankId = accountValue && accountValue.bank;
    if (isContinue) {
      try {
        let newUpload;
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/upload/banktransaction/importid`,
          comapnyBankData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then(async (res1) => {
            var erpNextRes1 = res1 && res1.data && res1.data.erpRes;
            let formData = new FormData();
            formData.append('file', fileData);
            formData.append('is_private', 1);
            formData.append('folder', "Home");
            formData.append('doctype', "Bank Statement Import");
            formData.append('docname', erpNextRes1 && erpNextRes1.name);
            formData.append('fieldname', "import_file");
            formData.append('file_name', fileData && fileData.name)
            await axios.post(
              `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/upload/banktransaction/file`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
              .then(async (res2) => {
                var erpNextRes2 = res2 && res2.data && res2.data.erpRes;
                let payload = {
                  erpResData1: erpNextRes1,
                  erpResData2: erpNextRes2
                }
                await axios.post(
                  `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/upload/banktransaction/importidsave`,
                  payload,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                  .then(async (res3) => {
                    let payloadlast = {
                      erpResData1: erpNextRes1
                    }
                    await axios.post(
                      `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/upload/banktransaction/startimport`,
                      payloadlast,
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                      .then(async (res3) => {
                        if (props.isPopup) {
                          props.success(newUpload.data);
                        } else {
                          setIsProcessing(false);
                          setCreate({
                            success: true
                          });
                          setSaved(true);
                          setTimeout(() => {
                            window.location.href = `/bankaccountstatementimportlist`;
                          }, 2000);
                        }
                      })
                      .catch((err) => {
                        errorMsg = err.response.data.message;
                        setIsError(true);
                        setIsProcessing(false);
                        setSaved(false);
                      });
                  })
                  .catch((err) => {
                    console.log("err.response.data.message", err.response.data.message);
                    errorMsg = err.response.data.message;
                    setIsError(true);
                    setIsProcessing(false);
                    setSaved(false);
                  });
              })
              .catch((err) => {
                console.log("err.response.data.message", err.response.data.message);
                errorMsg = err.response.data.message;
                setIsError(true);
                setIsProcessing(false);
                setSaved(false);
              });
          })
          .catch((err) => {
            console.log("err.response.data.message", err.response.data.message);
            errorMsg = err.response.data.message;
            setIsError(true);
            setIsProcessing(false);
            setSaved(false);
          });
      } catch (err) {
        console.log("err.response.data.message", err.response.data.message);
        errorMsg = (err.response.data.message && err.response.data.message.length > 0 && err.response.data.message[1] === "fullName must be unique") ? "Supplier Name has to be unique" : err.response.data.message;
        setIsError(true);
        setIsProcessing(false);
        setSaved(false);
      }
    } else {
      setIsProcessing(false);
      setIsWarning(true);
    }
  };

  let cancelClick = () => {
    window.location.href = `/bankaccountstatementimportlist`;
  }

  const handleDownload = async () => {
    try {
      const customerSaleData = sampleBankTransactionTemplate;
      let data = jsonToCsv(customerSaleData)

      downloadCSVFile(data, `Bank Transaction Template - ${new Date()}`)
    } catch (err) {
      setIsError(true);
    }
  }

  return (
    <div>
      {!props.isPopup && <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/accountshome")}>
                Accounting
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/bankaccountstatementimportlist")}>
                Bank Statement Upload
              </Link>
              <Typography component="span" color="textPrimary">
                Upload Statement
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="supplier-form">
          <Grid container spacing={2}>
            <Grid item container md={12} xs={12} spacing={1}>
            </Grid>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Bank Account *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    value={accountValue}
                    onChange={(event, newValue) => {
                      setAccountValue(newValue);
                    }}
                    inputValue={inputAccountalue}
                    onInputChange={(event, newInputValue) => {
                      setInputAccountValue(newInputValue);
                    }}
                    options={bankAccountList}
                    getOptionLabel={(option) => (typeof option.name === 'string' ? option.name : '')}
                    filterOptions={(options, addrType) =>
                      options.filter((option) =>
                        typeof option.name === 'string' &&
                        option.name.toLowerCase().includes(addrType.inputValue.toLowerCase())
                      )
                    }
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      name="bankAccount"
                      fullWidth
                      size="small"
                      error={Boolean(errors.bankAccount)}
                      inputRef={register({
                        required: "Required"
                      })} />}
                  />
                </Grid>
                <Grid item md={2} xs={2}></Grid>
                <Grid item md={4} xs={4} style={{ alignContent: "center" }}>
                  <label >
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={handleDownload}
                      startIcon={<CloudDownloadIcon />}
                    >
                      Download Template
                    </Button>
                  </label>

                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Upload File *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Grid>
                    <input
                      style={{ display: "none" }}
                      id="company-header-file"
                      type="file"
                      accept=".csv, .xls, .xlsx"
                      name="logo"
                      onChange={(e) => onFileInputChange(e)}
                    />
                    <label htmlFor="company-header-file">
                      <Button variant="outlined" component="span" color="secondary">
                        <CloudUploadIcon />
                      </Button>
                    </label>
                  </Grid>
                  <Grid>
                    <FormHelperText>
                      Only Excel sheets are supported. Please upload files with the following extensions: .csv, .xls, or .xlsx.
                    </FormHelperText>
                  </Grid>

                </Grid>
                <Grid item md={6} xs={6} style={{ paddingTop: "15px" }}>
                  {(fileData && fileData.name) ? <FormHelperText> <b>File Selected "{fileData.name}"</b></FormHelperText> : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid
            item
            container
            xs={6}
            justifyContent={isProcessing ? "flex-end" : "flex-start"}
          >
            {isProcessing ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}

            {isError ? (
              <Grid item>
                <Typography error>
                  <FormHelperText error>{errorMsg}</FormHelperText>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button size="small" type="submit"
                variant="contained" color="primary" disabled={saved}>
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => props.job ? props.cancel() : cancelClick()}
                color="secondary"
                size="small"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" name="id" ref={register()} />
      </form>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isWarning}
        autoHideDuration={5000}
        onClose={() => setIsWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          Please Select the Bank Account and File.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          File upload successfully
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default SuppliersForm;
