import React, { useState } from "react";
import {
  Dialog,
  Grid,
  Box,
  TextField,
  Paper,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  FormHelperText,
  DialogContent,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Customers from "../customers/Customer";
import { useFormContext } from "react-hook-form";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CustomerDetails = (props) => {
  const { register, errors } = useFormContext();
  const [customerModal, setCustomerModal] = useState(false);
  const renderAddress = () => {
    if (!props.data.customer) return "";
    return (
      props.data.customer.fullName +
      "\n" +
      (props.data.customer.address1 ? props.data.customer.address1 : "-") +
      "\n" +
      (props.data.customer.address2 ? props.data.customer.address2 : "-") +
      "\n" +
      (props.data.customer.address3 ? props.data.customer.address3 : "-")
    );
  };

  const mainContent = (props) => {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item container md={4} xs={12} direction="column">
          <Grid
            item
            container
            spacing={2}
            md={12}
            xs={12}
            alignItems="center"
            justify="center"
          >
            <Grid item md={5} xs={5}>
              <Grid item container justify="flex-start">
                <Grid item>
                  <Box fontWeight={"fontWeightBold"}>Mobile Number</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={7} xs={7}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                error={Boolean(errors.customerId)}
                InputProps={{
                  style: { color: "black" },
                }}
                value={
                  props.data.customer ? props.data.customer.mobileNumber : ""
                } disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={4} xs={12} direction="column">
          <Grid
            item
            container
            spacing={2}
            md={12}
            xs={12}
            alignItems="center"
            justify="center"
          >
            <Grid item md={2} xs={5}>
              <Grid item container justify="flex-end">
                <Grid item>
                  <Box fontWeight={"fontWeightBold"}>Email</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={10} xs={7}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                error={Boolean(errors.customerId)}
                InputProps={{
                  style: { color: "black" },
                }} disabled
                value={props.data.customer ? props.data.customer.email : ""}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* {props.handleCustomerSelection ? (
          <Grid item container md={4} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
            >
              <Grid item md={12} xs={12}>
                <Grid item container justify="flex-end">
                  <Grid item>
                    <IconButton onClick={() => setCustomerModal(true)}>
                      <SearchIcon color="secondary" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null} */}

        <Grid item container md={12} xs={12} direction="column">
          <Grid item container spacing={2} md={12} xs={12} alignItems="center">
            <Grid item md={12} xs={12}>
              <Grid item container justify="flex-start" direction="column">
                <Grid item md={12} xs={12}>
                  <Grid item container justify="flex-start">
                    <Grid item>
                      <Box fontWeight={"fontWeightBold"}>Name and Address</Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    error={Boolean(errors.customerId)}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    rows={3}
                    rowsMax={5}
                    size="small"
                    value={renderAddress()} disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const renderInCreateMode = (props) => {
    return (
      <>
        <Box fontWeight={"fontWeightBold"}>
          Customer<span style={{color: 'red'}}>*</span>
          <IconButton
            style={{ marginLeft: 20 }}
            onClick={() => setCustomerModal(true)}
          >
            <SearchIcon color="secondary" />
          </IconButton>
        </Box>
        <fieldset style={{ marginTop: 10 }}>{mainContent(props)}</fieldset>
        <FormHelperText error>
          {errors.customerId && errors.customerId.message}
        </FormHelperText>
      </>
    );
  };

  const renderInEditMode = (props) => {
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Box fontWeight={"fontWeightBold"}>Customer</Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{mainContent(props)}</ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  return (
    <div style={{ paddingTop: 20 }}>
      {props.handleCustomerSelection ? (
        renderInCreateMode(props)
      ) : (
        <Paper elevation={3}>{renderInEditMode(props)}</Paper>
      )}
      {props.handleCustomerSelection ? (
        <Dialog fullWidth={true} maxWidth={"md"} open={customerModal}>
          <Grid container>
            <Grid item container xs={12} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setCustomerModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <DialogContent>
            <Customers
              modal
              job={true}
              onRowSelect={(customer) => {
                props.handleCustomerSelection(customer);
                setCustomerModal(false);
              }}
            />
          </DialogContent>
        </Dialog>
      ) : null}
      <input
        type="hidden"
        name="customerId"
        value={props.data.customerId}
        ref={register({
          required: "Customer Details required. Please select.",
        })}
      />
    </div>
  );
};

export default CustomerDetails;
