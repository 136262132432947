import React from "react";
import ExpandableTable from "../common/ExpandableTable";
import { dataWithSubRows } from "../common/constants";

const Login = (props) => {
  let columsWithSubRows = [{
    header: 'First Name',
    accessorKey: 'firstName',
},
{
    header: 'Last Name',
    accessorKey: 'lastName',
},
{
    header: 'Age',
    accessorKey: 'age',
},
{
    header: 'Visits',
    accessorKey: 'visits',
},
{
    header: 'Status',
    accessorKey: 'status',
},
{
    header: 'Profile Progress',
    accessorKey: 'progress',
}]
  return (
    <>
      <ExpandableTable dataWithSubRows={dataWithSubRows} columsWithSubRows={columsWithSubRows} />
    </>
  );
};

export default Login;
