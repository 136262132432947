import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  FormControl,
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  CardHeader,
  Card
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ManageProductForm from "./ManageProductForm";

import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

import {
  productGroups
} from "../common/lookupSlice";

import { API_ALL_PRODUCT_GROUPS } from "../../config/constants";
import { formatDate } from '../common/util'
import { currentUser } from "../login/loginSlice";
const ManageProducts = (props) => {
  const prodGrps = useSelector(productGroups);
  const groupNames = prodGrps ? prodGrps.map(loc => loc.name) : [];
  const location = useLocation();
  const itemNameRef = useRef();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  const id = location.state && location.state.id ? location.state.id : null;
  const initialId = prodGrps && prodGrps[0] ? prodGrps[0].id : '';
  const pageSize = 10;
  const history = useHistory();
  const [count, setCount] = useState();
  const [prodItemForm, setProdItemForm] = useState({ open: false });
  const [group, setGroup] = useState(id ? id : initialId);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  let defaultGrp = prodGrps.filter(x => x.id === id)
  const [value, setValue] = React.useState(id && defaultGrp.length > 0 ? defaultGrp[0].name : prodGrps && prodGrps[0] ? prodGrps[0].name : '');
  const [inputValue, setInputValue] = React.useState('');
  const token = localStorage.getItem("authToken");

  const resetQuery = () => {
    setQuery({
      ...query,
      groupType: "",
      page: 1,
    });
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCT_GROUPS}/${group}/items`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.items);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    loadData();
  }, [group]); // eslint-disable-line react-hooks/exhaustive-deps


  const editProduct = async (itemId) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCT_GROUPS}/${group}/items`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setProdItemForm({
        ...prodItemForm,
        data: (result.data.items.filter(item => item.product.id === itemId))[0],
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  const handleManageDiscountsClick = () => {
    const grpId = group;
    history.push({
      pathname: '/manageDiscounts',
      state: { id: grpId }
    })
  }

  const handleReset = () => {
    setQuery({
      ...query,
      productName: undefined,
      page: 1,
    });
    itemNameRef.current.value = null;
  }


  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      productName: e.target.elements.itemName.value,
      page: 1,
    });
  };

  //On success of add or edit, reset the query to load data again
  useEffect(() => {
    if (prodItemForm.success) {
      resetQuery();
    }
  }, [prodItemForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Grid container>
        <Grid item container md={6} xs={12} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/productGroups")}>
                <Typography component="span" color="textPrimary" >
                  Product Group
                </Typography>
              </Link>
              <Typography component="span" color="textPrimary">
                Manage Products
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={4} xs={6} lg={3}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Group</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete size="small"
                  value={value}
                  onChange={(event, newValue) => {
                    const grpIndex = event.target.getAttribute("data-option-index");
                    const grpId = grpIndex ? prodGrps[grpIndex].id : prodGrps[0].id;
                    setValue(newValue);
                    setGroup(grpId)
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={groupNames}
                  disableClearable
                  renderInput={(params) => <TextField {...params}
                    variant="outlined"
                    name="loc"
                  />}
                />
              </Grid>
            </Grid>
            <Grid item container md={4} xs={6} lg={3}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={itemNameRef}
                    InputProps={{
                      name: "itemName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={12} xs={12} lg={6}>
              <Box mt={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Search
                </Button>
              </Box>
              <Box mt={2} ml={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleReset()}
                >
                  Reset
                </Button>
              </Box>
              {isAuditor ? null : <Box mt={2} ml={2}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setProdItemForm({ ...prodItemForm, data: {}, mode: "C", open: true });
                  }}
                >
                  Add New Product Item
                </Button>
              </Box>}
              <Box mt={2} ml={2}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => handleManageDiscountsClick()}
                >
                  Manage Discounts
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 10 }}>
        <Card>
          <CardHeader title={
            (<Typography display="block" variant="overline" style={{ fontWeight: "Bold", marginBottom: "-11px" }}>
              Product Group : {value}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {query.productName && `Filtered by product: ${query.productName}`}
            </Typography>)} />

          <TableContainer component={Paper} >
            <Table style={{ paddingTop: 10 }} size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell>Product Code</StyledHeaderCell>
                  <StyledHeaderCell align="left">Product Name</StyledHeaderCell>
                  <StyledHeaderCell align="left">Start Date</StyledHeaderCell>
                  <StyledHeaderCell align="left">Active</StyledHeaderCell>
                  {isAuditor ? null : <StyledHeaderCell align="center">Action</StyledHeaderCell>}
                </TableRow>
              </TableHead>
              {isLoading && initialId ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {data.length === 0 || !initialId ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((row, index) => (
                      <TableRow
                        style={{ cursor: props.modal ? "pointer" : "", }}
                        key={row.id}
                        hover={true}
                        onClick={(e) =>
                          props.onRowSelect && props.onRowSelect(row)
                        }
                      >
                        <TableCell component="th" scope="row">
                          {row.product && row.product.productCode}
                        </TableCell>
                        <TableCell align="left">
                          {row.product && row.product.productName}
                        </TableCell>
                        <TableCell align="left">
                          {row.startDate ? formatDate(row.startDate) : ''}
                        </TableCell>
                        <TableCell align="left">
                          {row.isActive === true ? 'Yes' : 'No'}
                        </TableCell>
                        {isAuditor ? null : <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              editProduct(row.product.id);
                            }}
                          >
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={prodItemForm.open}
        onClose={() => {
          setProdItemForm({ ...prodItemForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {prodItemForm.mode === "C"
                  ? "Add Product Group Item"
                  : "Edit Product Group Item"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProdItemForm({ ...prodItemForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '280px' }}>
          <ManageProductForm
            form={prodItemForm}
            groupId={group}
            cancel={() => {
              setProdItemForm({ ...prodItemForm, open: false });
            }}
            success={() => {
              setProdItemForm({ ...prodItemForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={prodItemForm.success}
        onClose={() => setProdItemForm({ ...prodItemForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setProdItemForm({ ...prodItemForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {prodItemForm.mode === "C"
            ? "Product Item added successfully"
            : "Product Item updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ManageProducts;
