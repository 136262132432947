import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocalities, custStates, ERPBankChartOfAccountList, ERPBankList } from "../common/lookupSlice";
import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  Breadcrumbs,
  Link,
  Snackbar,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { extractERPNextErrorMessage } from "../common/util";

let errorMsg;

function BankDetailsFrom(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { id } = useParams();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [comapnyBankData, setSupplierData] = useState({});
  const bankChartOfAccountList = useSelector(ERPBankChartOfAccountList);
  const bankList = useSelector(ERPBankList);
  let { acHolderName, acNumber, ifsCode, branchName, address, branchCode } = comapnyBankData;
  const [isError, setIsError] = useState(false);
  const { handleSubmit, errors, control, register } = useForm({});
  const [create, setCreate] = React.useState({});
  const stateObj = useSelector(custStates);
  const states = stateObj ? stateObj.map(state => state.name) : [];
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [accountValue, setAccountValue] = React.useState('');
  const [inputAccountalue, setInputAccountValue] = React.useState('');
  const [bankValue, setBankValue] = React.useState('');
  const [inputBankValue, setBankInputValue] = React.useState('');
  const [saved, setSaved] = React.useState(false);
  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isPm = (chars[1]) ? chars[1].toUpperCase() === "YES" : false;
  const [isPrimaryData, setIsPrimary] = useState(isPm);

  const fetchData = async (id) => {
    if (id) {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/bankdetials/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSupplierData(result.data);
        const stateIndex = states.indexOf(result.data.companyBankdetailsState.name)
        setValue(states[stateIndex])
        setAccountValue((result.data && result.data.chartOfAccount) ? result.data.chartOfAccount : "");
        setInputAccountValue((result.data && result.data.chartOfAccount) ? result.data.chartOfAccount : "");
        setBankValue((result.data && result.data.bankName) ? result.data.bankName : "");
        setBankInputValue((result.data && result.data.bankName) ? result.data.bankName : "")
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
    }
  }

  useEffect(() => {
    fetchData(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (comapnyBankData) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    let isContinue = true;
    const selectedState = stateObj.filter(x => x.name === value);
    comapnyBankData.isPrimary = isPrimaryData;
    comapnyBankData.id = id;
    //comapnyBankData.state = selectedState.length>0 ? selectedState[0].code:'';
    comapnyBankData.state = selectedState && selectedState[0] ? selectedState[0].code : '';

    if (isContinue) {
      try {
        let newBankDetails;
        let bankAccountRes;
        if (id) {
          newBankDetails = await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/bankdetials/${id}`,
            comapnyBankData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          bankAccountRes = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/bankaccount`,
            comapnyBankData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          let erpResDocs = bankAccountRes.data && bankAccountRes.data.erpRes.docs && bankAccountRes.data.erpRes.docs.length > 0 && bankAccountRes.data.erpRes.docs[0];
          comapnyBankData.ERPId = erpResDocs && erpResDocs.name;
          if (bankAccountRes && bankAccountRes.data && bankAccountRes.data.status === "SUCCESS") {
            newBankDetails = await axios.post(
              `${process.env.REACT_APP_SERVICE_URL}/bankdetials`,
              comapnyBankData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }


        }
        if (props.isPopup && newBankDetails) {
          props.success(newBankDetails.data.comapnyBankData);
        } else if (newBankDetails) {
          dispatch(fetchLocalities());
          setIsProcessing(false);
          if (props.job) {
            props.cancel();
            props.onCreate(newBankDetails && newBankDetails.data && newBankDetails.data.comapnyBankData)
          }
          else {
            setCreate({
              success: true
            });
            setSaved(true);
          }
          window.location.href = `/companyconfigbankdetaillist`;
        }

      } catch (err) {
        console.log("err.response.data.message", err.response.data.message);
        if (err.response.data.message && err.response.data.message.length > 0 && err.response.data.message[1] === "fullName must be unique") {
          errorMsg = "Bank Name has to be unique"
        } else {
          errorMsg = extractERPNextErrorMessage(err.response.data.message);
        }
        setIsError(true);
        setIsProcessing(false);
        setSaved(false);
      }
    } else {
      setIsProcessing(false);
    }
  };

  let cancelClick = () => {
    window.location.href = `/companyconfigbankdetaillist`;
  }

  return (
    <div>
      {!props.isPopup && <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/configrationhome")}>
                Configuration
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/companyconfigbankdetaillist")}>
                Bank Details
              </Link>
              <Typography component="span" color="textPrimary">
                {id ? "Edit Bank Details" : "New Bank Details"}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>}
      <Grid container style={{ marginTop: "10px" }}>
        <Grid item container xs={6} direction="column">
          <Typography variant={props.right ? "h7" : "h6"}>
            Bank Details
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            {id ? null : <Button
              color="primary"
              variant="contained"
              onClick={() => { history.push("/chartofaccountsconfig?action=NEW") }}
            >
              Add Chart Of Account
            </Button>}
          </Grid>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="supplier-form">
          <Grid container spacing={2}>
            <Grid item container md={12} xs={12} spacing={1}>
            </Grid>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Bank Name *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    value={bankValue}
                    onChange={(event, newValue) => {
                      setBankValue(newValue);
                    }}
                    inputValue={inputBankValue}
                    onInputChange={(event, newInputValue) => {
                      setBankInputValue(newInputValue);
                    }}
                    options={bankList}
                    getOptionLabel={(option) => (typeof option.name === 'string' ? option.name : '')}
                    filterOptions={(options, addrType) =>
                      options.filter((option) =>
                        typeof option.name === 'string' &&
                        option.name.toLowerCase().includes(addrType.inputValue.toLowerCase())
                      )
                    }
                    disabled={id ? true : false}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      name="bankName"
                      fullWidth
                      size="small"
                      error={Boolean(errors.bankName)}
                      inputRef={register({
                        required: "Required"
                      })} />}
                  />
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Company Account *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    value={accountValue}
                    onChange={(event, newValue) => {
                      setAccountValue(newValue);
                    }}
                    inputValue={inputAccountalue}
                    onInputChange={(event, newInputValue) => {
                      setInputAccountValue(newInputValue);
                    }}
                    options={bankChartOfAccountList}
                    getOptionLabel={(option) => (typeof option.name === 'string' ? option.name : '')}
                    filterOptions={(options, addrType) =>
                      options.filter((option) =>
                        typeof option.name === 'string' &&
                        option.name.toLowerCase().includes(addrType.inputValue.toLowerCase())
                      )
                    }
                    disabled={id ? true : false}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      name="chartOfAccount"
                      fullWidth
                      size="small"
                      error={Boolean(errors.chartOfAccount)}
                      inputRef={register({
                        required: "Required"
                      })} />}
                  />
                </Grid>
                <Grid item md={12} xs={12} >{id ? null :<FormHelperText style={{ textAlign: "center" }}>If you need to add a new company account or bank, please do so under the 'Bank Accounts' section in the Chart of Accounts.</FormHelperText>}</Grid>
              </Grid>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Account Name *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    disabled={id ? true : false}
                    error={Boolean(errors.acHolderName)}
                    inputRef={register({
                      required: "Enter the Account Name"
                    })}
                    value={acHolderName}
                    onChange={e => setSupplierData({ ...comapnyBankData, acHolderName: e.target.value })}
                    size="small"
                    name="acHolderName"
                  />
                  <FormHelperText error>
                    {errors.acHolderName && errors.acHolderName.message}
                  </FormHelperText>
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Account Number *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.acNumber)}
                    inputRef={register({
                      required: "Enter the Account Number"
                    })}
                    value={acNumber}
                    onChange={e => setSupplierData({ ...comapnyBankData, acNumber: e.target.value })}
                    size="small"
                    name="acNumber"
                  />
                  <FormHelperText error>
                    {errors.acNumber && errors.acNumber.message}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">IFS Code *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.ifsCode)}
                    inputRef={register({
                      required: "Enter the IFS Code"
                    })}
                    value={ifsCode}
                    onChange={e => setSupplierData({ ...comapnyBankData, ifsCode: e.target.value })}
                    size="small"
                    name="ifsCode"
                  />
                  <FormHelperText error>
                    {errors.ifsCode && errors.ifsCode.message}
                  </FormHelperText>
                </Grid>

                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Branch Name *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField variant="outlined" value={branchName}
                    size="small" name="branchName"
                    onChange={e => setSupplierData({ ...comapnyBankData, branchName: e.target.value })}
                    error={Boolean(errors.branchName)}
                    fullWidth inputRef={register({
                      required: "Enter the Branch Name"
                    })} />
                  <FormHelperText error>
                    {errors.branchName && errors.branchName.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Address</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField variant="outlined" value={address}
                    size="small" name="address"
                    onChange={e => setSupplierData({ ...comapnyBankData, address: e.target.value })}
                    error={Boolean(errors.address)}
                    fullWidth inputRef={register({
                      // pattern: {
                      //   value: /^[a-zA-Z0-9 ]{3,}$/,
                      //   message: "Minimum of 3 characters required",
                      // },
                    })} />
                  <FormHelperText error>
                    {errors.address && errors.address.message}
                  </FormHelperText>
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Branch Code</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.branchCode)}
                    inputRef={register({
                    })}
                    value={branchCode}
                    onChange={e => setSupplierData({ ...comapnyBankData, branchCode: e.target.value })}
                    size="small"
                    name="branchCode"
                  />
                  <FormHelperText error>
                    {errors.branchCode && errors.branchCode.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">State</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    // fullWidth
                    options={states}
                    // style={{ width: 215 }}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      name="st"
                      fullWidth
                      size="small"
                      error={Boolean(errors.st)}
                      inputRef={register({
                        required: "Select the State"
                      })} />}
                  />
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Default</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <FormControlLabel
                    fullWidth
                    variant="outlined"
                    size="small"
                    // inputRef={register({
                    // })}
                    value={isPrimaryData}
                    checked={isPrimaryData}
                    error={errors.isPrimary}
                    control={<Controller
                      as={
                        <Checkbox
                          color="primary"
                        />
                      }
                      name="isPrimary"
                      control={control}
                      onChange={(e) => {
                        if (e && e.length > 1) {
                          setIsPrimary(e[1]);
                        }
                      }}
                    />}
                    label=""
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid
            item
            container
            xs={6}
            justifyContent={isProcessing ? "flex-end" : "flex-start"}
          >
            {isProcessing ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}

            {errorMsg ? (
              <Grid item>
                <Typography error>
                  <FormHelperText error>{errorMsg}</FormHelperText>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button size="small" type="submit"
                variant="contained" color="primary" disabled={saved}>
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => props.job ? props.cancel() : cancelClick()}
                color="secondary"
                size="small"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" name="isDirty" value="true" ref={register()} />
        <input type="hidden" name="id" ref={register()} />
      </form>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id ? "Bank Details updated successfully"
            : "Bank Details created successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default BankDetailsFrom;
