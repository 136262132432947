import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import CustomerForm from "../customers/customerOrders/PurchaseForm";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";
import { formatDate } from '../common/util';
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import { API_PURCHASE_ORDER } from "../../config/constants";
import { currentUser } from "../login/loginSlice";
import { useSelector } from "react-redux";

const PurchaseOrder = (props) => {
  const pageSize = 10;
  const location = useLocation();
  const productData = location.state;
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const isAdminOrAccountant = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role=>(role.name === "ADMIN") ||  (role.name === "ACCOUNTANT"));
  const [count, setCount] = useState();
  const [custForm, setCustForm] = useState({ open: false });
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1 });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const token = localStorage.getItem("authToken");

  const editCustomer = async (poNum) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/${poNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      let modifiedContacts = result.data.purchaseOrders.lineItems.map((obj) => {
        obj.contactId = obj.id;
        obj.negotiatedPrice= obj.negotiatedPrice && (Math.round(obj.negotiatedPrice * 100) / 100).toFixed(2)
        delete obj["id"];
        return obj;
      });
      result.data.purchaseOrders.lineItems = modifiedContacts;
      setCustForm({
        ...custForm,
        data: result.data.purchaseOrders,
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const prodId = productData && productData.id;
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/purchaseOrders/?productId=${prodId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.openOrders);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [custForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {props.modal ? null : (
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/inventorySync")}>
                 Daily Inventory Sync
                </Link>
                <Typography component="span" color="textPrimary">
                  Product Purchase Orders
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1} style={{marginTop: '2%'}}>
          <Grid item xs={12} md={9}>
              <Typography>
                Product Name: {productData && productData.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Customer Purchase Order</StyledHeaderCell>
                <StyledHeaderCell align="left">Order Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Status</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => (
                    <TableRow
                      style={{ cursor: props.modal ? "pointer" : "" }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }>
                       <TableCell align="left" className="underLine" style={{cursor: "pointer"}}>
                        <Link onClick={() => {
                              editCustomer(row.poNum);
                            }}>{row.poNum}
                        </Link></TableCell>
                        
                      <TableCell align="left">{row.orderDate ? formatDate(row.orderDate):''}</TableCell>
                      <TableCell align="left">{row.status.description}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={custForm.open}
        onClose={() => {
          setCustForm({ ...custForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={10} alignItems="center">
              <Grid item>
                {custForm.mode === "C"
                  ? "Add Customer Purchase Order" : isAdminOrAccountant  ? "Edit Customer Purchase Order": "Customer Purchase Order Details"}
              </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    setCustForm({ ...custForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <CustomerForm
            form={custForm}
            cancel={() => {
              setCustForm({ ...custForm, open: false });
            }}
            success={() => {
              setCustForm({ ...custForm, open: false, success: true });
            }}
            readOnly={!isAdminOrAccountant }
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={custForm.success}
        onClose={() => setCustForm({ ...custForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCustForm({ ...custForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {custForm.mode === "C"
            ? "Customer Purchase Order created successfully"
            : "Customer Purchase Order updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default PurchaseOrder;
