import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from '../common/util';
import { withStyles } from '@material-ui/styles';
// import { transportModes } from "../common/constants"

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '1.6',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};
const tableStyle = {
  border: '1px solid #ccc',
  borderCollapse: 'collapse',
}

class CompToPrintInv extends React.Component {
  render() {
    const { classes, loggedInUser, orgTermsAndConditions } = this.props;
    const invDetails = this.props.supplierPurInv != null && this.props.supplierPurInv;
    console.log("invDetails", invDetails);
    const lineItems = this.props.supplierPurInv != null && this.props.supplierPurInv.lineItems;
    const labourCharges = this.props.supplierPurInv != null && this.props.supplierPurInv.labourCharges;
    const transportCharges = this.props.supplierPurInv != null && this.props.supplierPurInv.transportCharges;
    const supplier = this.props.supplierPurInv != null && this.props.supplierPurInv.supplier && this.props.supplierPurInv.supplier;
    const supContacts = supplier && supplier.contacts && supplier.contacts;
    const hsnSummary = invDetails && invDetails.HSNSummary;
    const printType = invDetails && invDetails.type && invDetails.type.code;
    // const paymentSummary = (invDetails && invDetails.paymentSummary) ? invDetails.paymentSummary : [];
    const shipToAddrs = (invDetails && invDetails.shipToLocation) ? invDetails.shipToLocation : invDetails && invDetails.location;
    // const invOrgDetails = this.props.supplierPurInv != null && this.props.supplierPurInv.org && this.props.supplierPurInv.org;
    let hsnTotal = 0;
    let hsnCGSTTotal = 0;
    let hsnSGSTTotal = 0;
    let hsnIGSTTotal = 0;
    hsnSummary && hsnSummary.map((row) => (
      hsnTotal += row.Total));
    hsnSummary && hsnSummary.map((row) => (
      hsnCGSTTotal += row.CGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnSGSTTotal += row.SGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnIGSTTotal += row.IGSTtotal));
    let quantity = 0;
    invDetails.lineItems && invDetails.lineItems.map((row) => (
      quantity += row.qty));
    // const invType = this.props.supplierPurInv != null && this.props.supplierPurInv.type;
    // const isDeposit = invType && invType.code === "DEPOSIT";
    let isIntraState = false;
    if (invDetails && invDetails.location) {
      isIntraState = (supplier && supplier.state && invDetails.location.state && (invDetails.location.state === supplier.state)) ? true : false;
    }
    const companyPrintLogo = loggedInUser && loggedInUser.org && loggedInUser.org.printLogo;
    let orgDeclaration = "";
    let termCodtype = (printType === "QUOTATION") ? "Supplier Purchase Quotation" : "Supplier Purchase Order";
    if (orgTermsAndConditions && orgTermsAndConditions.length > 0) {
      let findOrgCond = orgTermsAndConditions.find((orgCond) => {
        return (orgCond && orgCond.termsConditionsType && (orgCond.termsConditionsType === termCodtype)) ? orgCond : null;
      })
      orgDeclaration = findOrgCond && findOrgCond.termsConditions
    }
    return (
      <div >
        <div style={divStyle} align="center">
          {/* Invoice  */}
          {/* <Helmet><title> Print Invoice</title></Helmet> */}
          <Helmet><title></title></Helmet>
          <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', textAlign: 'center', fontWeight: 'bold', lineHeight: '2', }}>{termCodtype}</span>
          <table style={tableStyle} width="95%" border="1px" color="#ccc">
            <tr>
              <td rowSpan="3" width="60%">
                <table width="100%" border="0px" color="white" >
                  <tr>
                    <td width="60%">
                      <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name.toUpperCase()}<br /></b></span>
                      <span style={pStyle2}>
                        {invDetails && invDetails.location && (invDetails.location.address1.toUpperCase())}
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address2.toUpperCase())}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address3.toUpperCase() + '-' + invDetails.location.pincode)}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && invDetails.location.state ? invDetails.location.state : invDetails.location && invDetails.location.state}</div>
                        <span style={pStyle2}>{invDetails && invDetails.location && invDetails.location.mobileNumber ? invDetails.location.mobileNumber : ""}<br /></span>
                      </span>
                      <span style={pStyle2}>GSTIN/UIN: {invDetails && invDetails.location && invDetails.location.GSTIN ? invDetails.location.GSTIN : "Not Available"}<br /></span>
                      <span style={pStyle2}>Email: {invDetails && invDetails.location && invDetails.location.email ? invDetails.location.email : (invDetails.org) ? invDetails.org.email : "Not Available"}<br /></span>

                    </td>
                    <td rowSpan="4" width="40%" align="right">
                      <img alt="comLogo" src={companyPrintLogo}
                        style={{ height: "100px", width: "200px" }} />
                    </td>
                  </tr>
                </table>
              </td>
              <td width="20%">

                <div><span style={pStyle2}>Invoice No.</span><br />
                  <span style={pStyle2}><b>{this.props.supplierPurInv && this.props.supplierPurInv.invoiceNum}</b></span></div>


              </td>
              <td width="20%"><span style={pStyle2}>Dated. <br /><b>{this.props.supplierPurInv && formatDate(this.props.supplierPurInv.invoiceDate)}</b></span></td>
            </tr>
            {!(printType === "QUOTATION") ? <><tr>
              <td><span style={pStyle2}>Supplier Invoice No.<br />&nbsp;</span><span style={pStyle2}><b>{invDetails && invDetails.supplierInvoiceNo}</b></span></td>
              <td><span style={pStyle2}>Other Reference(s)<br />&nbsp;</span><span style={pStyle2}><b></b></span></td>

            </tr>
              <tr>
                <td><span style={pStyle2}>Supplier's Order No.<br /><b>{this.props.supplierPurInv && this.props.supplierPurInv.supplierPurchaseOrder && this.props.supplierPurInv.supplierPurchaseOrder.spoNum}</b></span></td>
                <td><span style={pStyle2}>Order Required Date<br /><b>{this.props.supplierPurInv && this.props.supplierPurInv.supplierPurchaseOrder && formatDate(this.props.supplierPurInv.supplierPurchaseOrder.orderRequiredDate)}</b></span></td>
              </tr></> : null}
            <tr>
              {/* <td rowSpan="1"> */}
              {!(printType === "QUOTATION") ? <td>
                <span style={pStyle2}>
                  Consignee(Ship To)<br /> <b>{invDetails && invDetails.org && invDetails.org.name.toUpperCase()}</b><br />
                  {shipToAddrs && (shipToAddrs.address1 + ' \n' + shipToAddrs.address2 + ' \n' + shipToAddrs.address3 + ' \n' + shipToAddrs.state + '-' + shipToAddrs.pincode)}<br />
                  GSTIN/UIN: {shipToAddrs && shipToAddrs.GSTIN}<br />
                  State: {shipToAddrs && shipToAddrs.state} {(shipToAddrs && shipToAddrs.GSTIN) ? ' Code: ' + shipToAddrs.GSTIN.slice(0, 2) : ' '}<br />
                  Pan Number: {shipToAddrs && shipToAddrs.panNumber}<br />
                  Contact: {shipToAddrs && shipToAddrs.GSTIN}
                </span>
              </td> : null}
              <td colspan="2">
                <span style={pStyle2}>
                  Supplier{!(printType === "QUOTATION") ? "(Bill From)" : null}<br /> <b>{supplier && supplier.fullName}</b><br />
                  {supplier && (supplier.address1 + ' \n' + supplier.address2 + ' \n' + supplier.address3 + ' \n' + supplier.state + '-' + supplier.pincode)}<br />
                  GSTIN/UIN: {supplier && supplier.GSTIN}<br />
                  State: {supplier && supplier.state} {(supplier && supplier.GSTIN) ? ' Code: ' + supplier.GSTIN.slice(0, 2) : ' '}<br />
                  Contact Person: {supContacts ? supContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                  Contact: {supContacts ? supContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                </span>
              </td>
            </tr>
          </table>
          <div style={{ width: '100%', padding: '5px 2px' }}>
            <table style={tableStyle} width="95%">
              <thead>
                <tr>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Sl No.</span></td>
                  <td width="25%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Description of Goods/Services</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>GST Rate</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Quantity</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>per</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Disc.%</span></td>
                  <td width="15%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                </tr>
              </thead>
              <tbody>
                {((lineItems && lineItems.length === 0) && (labourCharges === 0 && transportCharges === 0)) ? (
                  <tr>
                    <td colspan="9" align="center">
                      <span style={pStyle2}>No records found</span>
                    </td>
                  </tr>
                ) : (
                  lineItems && lineItems.map((row, index) => (
                    <tr
                      key={index}
                      hover={true}
                    >
                      <td align="center" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {index + 1}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.productName}</b>
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.HSNCode}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.IGST} %
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.qty} {row.uom}</b>
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.unitPrice ? (Math.round(row.unitPrice * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.uom}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.discountPct ? (Math.round(row.discountPct * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</b>
                        </span>
                      </td>
                    </tr>
                  ))
                )}
                {lineItems && lineItems.length > 0 &&
                  <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>{invDetails.subtotal ? (Math.round(invDetails.subtotal * 100) / 100).toFixed(2) : '0.00'}</span>
                    </td>
                  </tr>
                }
                {labourCharges && labourCharges > 0 ? <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Labour Charges</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.labourCharges ? (Math.round(invDetails.labourCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr> : ''}
                {transportCharges && transportCharges > 0 ? <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Transportation Charges (Sales)/packing</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.transportCharges ? (Math.round(invDetails.transportCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr> : ''}
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>CGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.CGST ? (Math.round(invDetails.CGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>SGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.SGST ? (Math.round(invDetails.SGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {(!isIntraState) && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>IGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.IGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {invDetails.roundOff !== 0 && <tr>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}>&nbsp;</td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><i>{invDetails.roundOff < 0 ? 'Less:' : ''}</i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={pStyle2}><b><i>Round Off</i></b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.roundOff ? (invDetails.roundOff > 0 ? (Math.round(invDetails.roundOff * 100) / 100).toFixed(2) : '(-)' + (Math.round(invDetails.roundOff * (-100)) / 100).toFixed(2)) : '0.00'}</b></span>
                  </td>
                </tr>}
                <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{quantity}</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.total ? (Math.round(invDetails.total * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody>
                <tr>
                  <td width="50%" style={{ borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Amount Chargeable(in words) :</span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}><i>E. &amp; O.E</i>&nbsp;</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.amountInWords}</b><br /><br /></span>
                  </td>
                </tr>
                {invDetails.remarkNote ? <tr>
                  <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                    <span style={pStyle}><b>Remarks :</b>&nbsp;{invDetails.remarkNote}<br /><br /></span>
                  </td>
                </tr> : null}
                <tr>
                  <td colspan="2">
                    &nbsp;<br />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <span style={pStyle2}>Company’s PAN : <b>{invDetails && invDetails.location && invDetails.location.panNumber ? invDetails.location.panNumber : "Not Available"}</b></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" width="100%" align="left">
                    <span style={pStyle2}><b>Term and Conditions</b><br />{orgDeclaration}</span>
                  </td>
                  {/* <td>
                      <span style={pStyle2}>Company's Bank Details<br />
                        Bank Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{orgBankDetails && orgBankDetails.bankName}</b><br />
                        A/c No. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{orgBankDetails && orgBankDetails.acNumber}</b><br />
                        Branch &amp; IFS Code &nbsp;: <b>{orgBankDetails && orgBankDetails.branchName} &amp; {orgBankDetails && orgBankDetails.ifsCode}</b>
                      </span>
                    </td> */}
                </tr>
                <tr>
                  <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Supplier Seal and Signature<br /><br /><br /></span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CompToPrintInv);