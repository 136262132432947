export const API_AUTHENTICATE = "auth";
export const API_CURRENT_USER = "auth/currentuser";
export const API_REST_PASSWORD = "auth/passwordreset";
export const API_ROLES = "roles";
export const API_ALL_CUSTOMERS = "customers";
export const API_ALL_DRAFT_BY_CUSTOMER ="draftsale"
export const API_CUSTOMER_DISCOUNT = "discounts";
export const CUSTOMER_HISTORY = "history";
export const API_CUSTOMER_ORDER = "orders";
export const API_PURCHASE_ORDER = "purchaseOrders";
export const API_PO_LOOKUP = "seed/lookup";
export const API_LOCALITIES = API_ALL_CUSTOMERS + "/locality";
export const API_ALL_USERS = "users";
export const API_ALL_PRODUCTS = "products";
export const API_PROD_NAME = API_ALL_PRODUCTS + "?productName=Deposit";
export const API_ALL_PRODUCT_GROUPS = "productGroups";
export const API_INVENTORY = "inventory";
export const API_LOCATION = "locations";
export const API_ALL_JOBS = "jobs";
export const API_PRODUCT_LOOKUP = API_ALL_PRODUCTS + "/seed/lookup";
export const API_PRODUCT_GROUPS_LOOKUP = API_ALL_PRODUCT_GROUPS + "/seed/lookup";
export const API_JOBS_SUMMARY = API_ALL_JOBS + "/status/summary";
export const API_JOB_LOOKUP = API_ALL_JOBS + "/seed/lookup";
