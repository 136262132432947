import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocalities, supTypes, custStates } from "../common/lookupSlice";
import {
  Typography,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Breadcrumbs,
  Link,
  Snackbar
} from "@material-ui/core";
import SupplierProductForm from "./supplierProductForm"
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { currentUser } from "../login/loginSlice";

let errorMsg;

function SuppliersForm(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { id } = useParams();
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  const [isProcessing, setIsProcessing] = useState(false);
  const [supplierData, setSupplierData] = useState({});
  let { fullName, email, mobileNumber, address1, address2, address3, pincode, locality, GSTIN, supTypeId } = supplierData;
  const [supType, setSupType] = useState();
  const [isError, setIsError] = useState(false);
  const [isItemRomve, setIsRemoveItem] = useState(false);
  const { handleSubmit, errors, register, getValues } = useForm({});
  const [inputFields, setInputFields] = useState([]);
  const [create, setCreate] = React.useState({});
  const stateObj = useSelector(custStates);
  const types = useSelector(supTypes);
  const states = stateObj ? stateObj.map(state => state.name) : [];
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [saved, setSaved] = React.useState(false);
  const [isGSTNErr, setGSTNErr] = useState(false);
  const [isPincodeErr, setPincodeErr] = useState(false);
  const [pincodeErrMsg, setPincodeErrMsg] = useState(false);
  const [prodItemForm, setProdItemForm] = useState({ open: false, mode: "C" });
  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isQuotation = (chars[1]) ? chars[1].toUpperCase() === "QUOTATION" : false;
  let isPO = (chars[1]) ? chars[1].toUpperCase() === "PURCHASEORDER" : false;
  let isDebitNote = (chars[1]) ? chars[1].toUpperCase() === "DEBITNOTE" : false;

  const fetchData = async (id) => {
    if (id) {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/suppliers/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let modifiedContacts = result.data.contacts.map((obj) => {
          obj.contactId = obj.id;
          delete obj["id"];
          return obj;
        });
        result.data.contacts = modifiedContacts;
        setInputFields(result.data.contacts);
        setSupplierData(result.data);
        setSupType(result.data.supplierType.id);
        const stateIndex = states.indexOf(result.data.supplierState.name)
        setValue(states[stateIndex])
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
    }
  }

  useEffect(() => {
    fetchData(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  }

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  }
  const onSubmit = async (supplierData) => {
    const contacts = getValues({ nest: true })["contacts"];
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    let isContinue = true;
    const selectedState = stateObj.filter(x => x.name === value);
    supplierData.contacts = inputFields;
    supplierData.GSTIN = GSTIN;
    supplierData.id = id;
    //supplierData.state = selectedState.length>0 ? selectedState[0].code:'';
    supplierData.state = selectedState && selectedState[0] ? selectedState[0].code : '';


    if (supplierData.pincode && selectedState && selectedState[0]) {
      let testPinCode = parseInt(supplierData.pincode.toString().slice(0, 3));
      if (testPinCode >= parseInt(selectedState[0].pinCodeSR) && testPinCode <= parseInt(selectedState[0].pinCodeER)) {
        setPincodeErr(false);
      } else {
        setPincodeErr(true);
        setPincodeErrMsg("Pincode not matching to state.");
        isContinue = false;
      }
    } else {
      isContinue = false;
      setPincodeErrMsg("Enter Valid Pincode.");
      setPincodeErr(true);
    }
    supplierData.supTypeId = supType ? supType : 1;
    if (supplierData.contacts) {
      let modifiedContacts = contacts ? contacts.map(obj => {
        obj.id = Number(obj.id);
        return obj;
      }) : [];
      supplierData.contacts = JSON.stringify(modifiedContacts);
    }
    if (isContinue) {
      setGSTNErr(false);
      try {
        var newSupplier;
        if (id) {
          await axios.patch(
            `${process.env.REACT_APP_SERVICE_URL}/suppliers/${id}`,
            supplierData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          newSupplier = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/suppliers`,
            supplierData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        if (props.isPopup) {
          props.success(newSupplier.data.supplierData);
        } else {
          dispatch(fetchLocalities());
          setIsProcessing(false);
          if (props.job) {
            props.cancel();
            props.onCreate(newSupplier && newSupplier.data && newSupplier.data.supplierData)
          }
          else {
            setCreate({
              success: true
            });
            setSaved(true);
          }
          setTimeout(() => {
            let supData = newSupplier && newSupplier.data;
            if (isDebitNote) {
              window.location.href = `/suppliers?from=debitnote`;
            } else if (isPO) {
              history.push({ state: supData })
              window.location.href = `/supplierOrder/${(supData.id)?supData.id:supData.supplierId}?from=purchaseorder`;
            } else if (isQuotation) {
              history.push({ state: supData })
              window.location.href = `/supplierOrder/${(supData.id)?supData.id:supData.supplierId}?from=quotation`;
            } else {
              window.location.href = `/supplierForm/${(supData.id)?supData.id:supData.supplierId}`;
            }
          }, 2000);

        }

      } catch (err) {
        console.log("err.response.data.message", err.response.data.message);
        errorMsg = (err.response.data.message && err.response.data.message.length > 0 && err.response.data.message[1] === "fullName must be unique") ? "Supplier Name has to be unique" : err.response.data.message;
        setIsError(true);
        setIsProcessing(false);
        setSaved(false);
      }
    } else {
      setIsProcessing(false);
    }
  };

  let cancelClick = () => {
    if (isDebitNote) {
      window.location.href = `/suppliers?from=debitnote`;
    } else if (isPO) {
      window.location.href = `/suppliers?from=purchaseorder`;
    } else if (isQuotation) {
      window.location.href = `/suppliers?from=quotation`;
    } else {
      window.location.href = `/suppliers`;
    }
  }

  const handleRemoveItems = async (productId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVICE_URL}/suppliers/${id}/items/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsRemoveItem(true)
      fetchData(id);
    } catch (err) {
      setIsError(true)
    }
  }

  return (
    <div>
      {!props.isPopup && <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
                Suppliers
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="supplier-form">
          <Grid container spacing={2}>
            <Grid item container md={props.isPopup ? 12 : 9} xs={props.isPopup ? 12 : 9} spacing={1}>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Supplier Number</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={id}
                    disabled={true}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container md={props.isPopup ? 12 : 9} xs={props.isPopup ? 12 : 9} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Full Name *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors.fullName)}

                    inputRef={register({
                      required: "Minimum 5 letters required. No digits.",
                      // pattern: {
                      //   value: /^[a-zA-Z ]{5,}$/,
                      //   message: "Minimum 5 letters required. No digits.",
                      // },
                    })}
                    disabled={(id || isAuditor) ? true : false}
                    InputProps={{
                      autoFocus: true,
                      name: "fullName",
                    }} value={fullName}
                    onChange={e => setSupplierData({ ...supplierData, fullName: e.target.value })}
                    size="small"
                    name="fullName"
                  />
                  <FormHelperText error>
                    {errors.fullName && errors.fullName.message}
                  </FormHelperText>
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption" style={{ float: "right" }}>
                    Supplier Type *
                  </Typography>
                </Grid>
                <Grid item md={3} xs={3}>
                  <TextField select
                    value={supTypeId ? supTypeId : supType ? supType : ''}
                    size="small" fullWidth
                    onChange={(e) => {
                      setSupplierData({ ...supplierData, supTypeId: e.target.value })
                      setSupType(e.target.value)
                    }}
                    disabled={isAuditor}
                    SelectProps={{ native: true }}
                    variant="outlined"
                    error={Boolean(errors.supTypeId)}
                    inputRef={register({
                      required: "Select the Supplier Type"
                    })}
                    name="supTypeId"
                  >
                    <option key={"type"} value=''>Select Type</option>
                    {types && Object.values(types).map((obj) => {
                      const { id, description } = obj;
                      return <option key={id} value={id} >{description} </option>
                    })}
                  </TextField>
                  <FormHelperText error>
                    {errors.supTypeId && errors.supTypeId.message}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
            {id && !isAuditor && !props.isPopup && <Grid item container md={3} xs={3}>
              <SupplierProductForm
                form={prodItemForm}
                supplierId={id}
                fetchData={fetchData}
                cancel={() => {
                  setProdItemForm({ ...prodItemForm, open: false });
                }}
                success={() => {
                  setProdItemForm({ ...prodItemForm, open: false, success: true });
                }}
                disabled={isAuditor}
              />
            </Grid>}
            <Grid item container md={props.isPopup ? 12 : 9} xs={props.isPopup ? 12 : 9} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Building/Floor No</Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.address1)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    disabled={isAuditor}
                    value={address1}
                    onChange={e => setSupplierData({ ...supplierData, address1: e.target.value })}
                    size="small"
                    name="address1"
                  />
                  <FormHelperText error>
                    {errors.address1 && errors.address1.message}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Street Name</Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.address2)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    disabled={isAuditor}
                    value={address2}
                    onChange={e => setSupplierData({ ...supplierData, address2: e.target.value })}
                    size="small"
                    name="address2"
                  />
                  <FormHelperText error>
                    {errors.address2 && errors.address2.message}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">City</Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.address3)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    disabled={isAuditor}
                    value={address3}
                    onChange={e => setSupplierData({ ...supplierData, address3: e.target.value })}
                    size="small"
                    name="address3"
                  />
                  <FormHelperText error>
                    {errors.address3 && errors.address3.message}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">Pincode *</Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.pincode) || isPincodeErr}
                  type="number"
                  inputRef={register({
                    required: "6 digit pincode is required",
                    pattern: {
                      value: /^[0-9]{6}$/,
                      message: "6 digit pincode is required",
                    },
                  })} value={pincode}
                  disabled={isAuditor}
                  onChange={e => setSupplierData({ ...supplierData, pincode: e.target.value })}
                  size="small"
                  name="pincode"
                />
                <FormHelperText error={Boolean(errors.pincode) || isPincodeErr}>
                  {(isPincodeErr) ? pincodeErrMsg : errors.pincode && errors.pincode.message}
                </FormHelperText>
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">Locality</Typography>
              </Grid>
              <Grid item md={3} xs={3}>
                <TextField variant="outlined" value={locality}
                  size="small" name="locality"
                  onChange={e => setSupplierData({ ...supplierData, locality: e.target.value })}
                  error={Boolean(errors.locality)}
                  disabled={isAuditor}
                  fullWidth inputRef={register({
                    // pattern: {
                    //   value: /^[a-zA-Z0-9 ]{3,}$/,
                    //   message: "Minimum of 3 characters required",
                    // },
                  })} />
                <FormHelperText error>
                  {errors.locality && errors.locality.message}
                </FormHelperText>
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">Mobile Number *</Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <TextField variant="outlined" fullWidth error={Boolean(errors.mobileNumber)}
                  onChange={e => setSupplierData({ ...supplierData, mobileNumber: e.target.value })}
                  type="number" inputRef={register({
                    required: "Enter valid 10 digit mobile number",
                    pattern: {
                      value: /^[6789]{1}[0-9]{9}$/,
                      message: "Enter valid 10 digit mobile number",
                    },
                  })} value={mobileNumber} size="small" name="mobileNumber" disabled={isAuditor} />
                <FormHelperText error>
                  {errors.mobileNumber && errors.mobileNumber.message}
                </FormHelperText>
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">State *</Typography>
              </Grid>
              <Grid item md={3} xs={3}>
                <Autocomplete size="small"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  disabled={isAuditor}
                  options={states}
                  style={{ width: 215 }}
                  renderInput={(params) => <TextField {...params}
                    variant="outlined"
                    name="st"
                    error={Boolean(errors.st)}
                    inputRef={register({
                      required: "Required"
                    })} />}
                />
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">Email</Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <TextField
                  variant="outlined"
                  fullWidth onChange={e => setSupplierData({ ...supplierData, email: e.target.value })}
                  type="email" value={email}
                  error={Boolean(errors.email)}
                  inputRef={register({
                    // minLength: {
                    //   value: 5,
                    //   message: "Minimum 5 characters required",
                    // },
                  })}
                  disabled={isAuditor}
                  size="small"
                  name="email"
                />
                <FormHelperText error>
                  {errors.email && errors.email.message}
                </FormHelperText>
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">GSTIN *</Typography>
              </Grid>
              <Grid item md={3} xs={3}>
                <TextField value={GSTIN}
                  variant="outlined" fullWidth
                  onChange={e => setSupplierData({ ...supplierData, GSTIN: e.target.value })}
                  size="small" name="gstin"
                  error={Boolean(errors.gstin) || isGSTNErr}
                  inputRef={register({
                    required: "GSTIN Required"
                  })}
                  disabled={isAuditor}
                />
                <FormHelperText error={Boolean(errors.gstin) || isGSTNErr}>
                  {(isGSTNErr) ? errorMsg : errors.gstin && errors.gstin.message}
                </FormHelperText>
              </Grid>
            </Grid>
            {id && !props.isPopup && <Grid item container md={3} xs={3} style={{ height: "350px", overflow: "scroll" }}>
              <TableContainer component={Paper}>
                <Table style={{ paddingTop: 10 }} size="small">
                  <TableHead>
                    <TableRow>
                      <StyledHeaderCell>Supplier Product</StyledHeaderCell>
                      {isAuditor ? null : <StyledHeaderCell align="left">Action</StyledHeaderCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {supplierData && supplierData.supplierItems && supplierData.supplierItems.length > 0 ? supplierData.supplierItems.map((item) => {
                      return <TableRow key={item.productId} style={{ outline: "thin solid" }}>
                        <TableCell component="th" align="left" className="supplier-form-label" style={{ border: "0px", textAlign: "left" }}>
                          {item && item.product && item.product.productName}
                        </TableCell>
                        {isAuditor ? null : <TableCell align="left" style={{ border: "0px" }}>
                          {/* <IconButton onClick={() => handleRemoveItems(item.productId)}> */}
                          <span onClick={() => handleRemoveItems(item.productId)}>
                            <DeleteIcon color="secondary" style={{ cursor: "pointer" }} />
                          </span>
                          {/* </IconButton> */}
                        </TableCell>}
                      </TableRow>
                    }) : null}
                    {supplierData && supplierData.supplierItems && supplierData.supplierItems.length === 0 ? (
                      <TableRow>
                        <TableCell align="center" colSpan={"2"}>
                          <Typography variant="subtitle1">
                            No records found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>}
          </Grid>
        </div>
        <div style={{ paddingTop: 40, paddingBottom: 40 }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell align="left">Contact Name</StyledHeaderCell>
                  <StyledHeaderCell align="left">Mobile Number</StyledHeaderCell>
                  {isAuditor ? null : <StyledHeaderCell align="left">Action</StyledHeaderCell>}
                </TableRow>
              </TableHead>
              {inputFields.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography variant="subtitle1">
                        No contacts added.
                      </Typography>
                      {isAuditor ? null : <IconButton
                        size="medium"
                        align="center"
                        onClick={() => setInputFields([...inputFields, {
                          contactId: "",
                          contactName: "",
                          mobileNumber: "",
                        }])}>
                        <PersonAddIcon color="primary" />
                      </IconButton>}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : null}
              <TableBody>
                {inputFields.map((inputField, index) => (
                  <TableRow key={inputField.contactId}>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name={`contacts[${index}].id`}
                      defaultValue={inputField.contactId}
                      ref={register({})}
                    />
                    <TableCell align="left">
                      <TextField
                        error={Boolean(
                          errors.contacts &&
                          errors.contacts[index] &&
                          errors.contacts[index].contactName
                        )}
                        inputRef={register({
                          required: "Min 3 characters required",
                          pattern: {
                            value: /^[a-zA-z0-9 ]{3,}$/,
                            message: "Min 3 characters required",
                          },
                        })}
                        //   name="contactName"
                        //value={cont && cont[index] && (cont[index]).contactName }
                        disabled={isAuditor}
                        name={`contacts[${index}].contactName`}
                        defaultValue={
                          inputField.contactName
                        }
                        onChange={event => handleChangeInput(index, event)}
                      />
                      <FormHelperText error>
                        {errors.contacts &&
                          errors.contacts[index] &&
                          errors.contacts[index].contactName &&
                          errors.contacts[index].contactName.message}
                      </FormHelperText></TableCell>
                    <TableCell>
                      <TextField
                        error={Boolean(
                          errors.contacts &&
                          errors.contacts[index] &&
                          errors.contacts[index].mobileNumber
                        )}
                        type="number"
                        inputRef={register({
                          required: "Enter valid 10 digit mobile number",
                          pattern: {
                            value: /^[6789]{1}[0-9]{9}$/,
                            message: "Enter valid 10 digit mobile number",
                          },
                        })}
                        // name="mobileNumber"
                        //value={cont && cont[index] && (cont[index]).mobileNumber }

                        name={`contacts[${index}].mobileNumber`}
                        defaultValue={
                          inputField.mobileNumber
                        }
                        disabled={isAuditor}
                        onChange={event => handleChangeInput(index, event)}
                      />
                      <FormHelperText error>
                        {errors.contacts &&
                          errors.contacts[index] &&
                          errors.contacts[index].mobileNumber &&
                          errors.contacts[index].mobileNumber.message}
                      </FormHelperText></TableCell>
                    {isAuditor ? null : <TableCell align="left">
                      <IconButton onClick={() => setInputFields([...inputFields, {
                        contactId: "",
                        contactName: "",
                        mobileNumber: "",
                      }])}>
                        <AddIcon color="secondary" />
                      </IconButton>
                      <IconButton onClick={() => handleRemoveFields(index)}>
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid
            item
            container
            xs={6}
            justifyContent={isProcessing ? "flex-end" : "flex-start"}
          >
            {isProcessing ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}

            {isError ? (
              <Grid item>
                <Typography error>
                  <FormHelperText error>{errorMsg}</FormHelperText>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            {isAuditor ? null : <Grid item>
              <Button size="small" type="submit"
                variant="contained" color="primary" disabled={saved}>
                Save
              </Button>
            </Grid>}
            <Grid item>
              <Button
                variant="contained"
                onClick={() => props.job ? props.cancel() : cancelClick()}
                color="secondary"
                size="small"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" name="isDirty" value="true" ref={register()} />
        <input type="hidden" name="id" ref={register()} />
      </form>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id ? "Supplier updated successfully"
            : "Supplier created successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isItemRomve}
        autoHideDuration={3000}
        onClose={() => setIsRemoveItem(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setIsRemoveItem(false)}
          severity="success">
          Item Removed by Supplier List
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default SuppliersForm;
