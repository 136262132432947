import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  Select,
  MenuItem
  
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import SearchProduct from '../../productGroups/searchProduct';
import { useParams } from "react-router-dom";
import { partTypes } from '../../../components/common/lookupSlice';
import { useSelector } from "react-redux";

let errorMsg;
function ManageProductPartsForm(props) {

  const { id } = useParams();
  const [selectProduct, setSelectProduct] = useState({ open: false });
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [prodId, setProdId] = useState(props.form.data && props.form.data.part ? props.form.data.part.id:null);
  const { handleSubmit, control, errors, register, getValues , setValue } = useForm({
    defaultValues: {
      productId: props.form.data.part && props.form.data.part.productName,
      typeId: props.form.data.type ? props.form.data.type.id : '',
    },
  });
 
  const customerTypes = useSelector(partTypes);
  const onSubmit = async (data) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    try {
      if (props.form.mode === "C") {
        errorMsg = 'Could not add part to product';
        data.productId = Number(prodId);
        const reqData = {
          parts:JSON.stringify([data])
        }
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/products/${id}/parts`,
          reqData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else if (props.form.mode === "U") {
      errorMsg = 'Could not update part to product';
      const reqData = {
        typeId:Number(data.typeId)
      }
      await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/products/${id}/parts/${props.form.data.id}`,
          reqData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setIsProcessing(false);
      props.success();
    } catch (err) {
      errorMsg = err.response && err.response.data.message &&
        (err.response.data.message[0] === "productId must be unique" || err.response.data.message[0] === "Validation error") ? "Product Item already exists" : errorMsg;
      setIsError(true);
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">{ props.form.mode === "U" ? 'Part Name':'Search Part' }</Typography>
            <TextField
              variant="outlined"
              fullWidth
              disabled={true}
              error={Boolean(errors.productId)}
              inputRef={register({
                required: "Product is required",
              })}
              onClick={(e)=> props.form.mode === "U" ? '':setSelectProduct({ ...selectProduct, open: true})}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{cursor:"pointer"}}>
                    <SearchIcon  onClick={(e)=>  props.form.mode === "U" ? '':setSelectProduct({ ...selectProduct, open: true})}/>
                  </InputAdornment>
                ),
              }}
              size="small"
              name="productId"
              value={getValues('productId')}
            />
            <FormHelperText error>
              {errors.productId && errors.productId.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Part Type</Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={Boolean(errors.typeId)}
            >
              <Controller
                as={
                  <Select>
                    {customerTypes.map(function (type,idx) {
                      return (
                        <MenuItem key={type.code} value={type.id}>
                          {type.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="typeId"
                size="small"
                variant="outlined"
                rules={{ required: "Parts type is required" }}
                control={control}
                defaultValue=""
              />
            </FormControl>
            <FormHelperText error>
              {errors.typeId && errors.typeId.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={selectProduct.open}
        onClose={() => {
          setSelectProduct({ ...selectProduct, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Select Product
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectProduct({ ...selectProduct, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <SearchProduct
            form={selectProduct}
            setValue = {setValue}
            setProdId={setProdId}
            cancel={() => {
              setSelectProduct({ ...selectProduct, open: false });
            }}
            success={() => {
              setSelectProduct({ ...selectProduct, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
    </form>
  );
}

export default ManageProductPartsForm;
