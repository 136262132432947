import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { API_ALL_PRODUCTS } from "../../../config/constants";

import { custTypes } from "../../common/lookupSlice";
import _ from 'lodash';
import moment from 'moment'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

let errorMsg;
function ProductDiscountForm(props) {
  const { id } = useParams();
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [date, setDate] = useState({start: props.form.data.startDate ? moment(props.form.data.startDate).format('DD/MM/YYYY'):null,end:props.form.data.endDate ? moment(props.form.data.endDate).format('DD/MM/YYYY'):null});
  const { handleSubmit, control, errors, register } = useForm({
    defaultValues: {
      id: props.form.data.id && props.form.data.id,
      custTypeId: props.form.data.customerType ? props.form.data.customerType.id : '',
      discount: props.form.data.discount && (Math.round(props.form.data.discount * 100) / 100).toFixed(2),
      startDate: date.start,
      endDate: date.end,
      qtyFromRange: props.form.data.qtyFromRange,
      qtyToRange: props.form.data.qtyToRange,
      isActive: props.form.mode === "C" ? true:props.form.data.isActive
    },
  });
 
  const onSubmit = async (data) => {
    setIsProcessing(true);
    setIsError(false);    
    if((data.startDate)){
      var startDateArrayD = data.startDate && data.startDate.split("/");
      data.startDate = startDateArrayD[1] + '/' + startDateArrayD[0] + '/' + startDateArrayD[2]
    } else{
      data.startDate = null;
    }
    if((data.endDate)){
      var endDateArrayD = data.endDate && data.endDate.split("/");
      data.endDate = endDateArrayD[1] + '/' + endDateArrayD[0] + '/' + endDateArrayD[2]
    } else {
      data.endDate = null;
    }
    errorMsg = "";
    try {
      if(data.qtyToRange && data.qtyFromRange && (parseInt(data.qtyToRange) > parseInt(data.qtyFromRange))){
      if (props.form.mode === "C") {
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}/discounts`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else if (props.form.mode === "U") {
      const discountId = props.form.data.id;
      await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}/discounts/${discountId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setIsProcessing(false);
      props.success();
    }else{
      errorMsg = "Quantity To Range is greater than From Range";
      setIsError(true);
      setIsProcessing(false);
    }
    } catch (err) {
      errorMsg = err.response.data.message;
      setIsError(true);
      setIsProcessing(false);
    }
  };
  
  const customerTypes = useSelector(custTypes);
 
  const handleDateChange = (value,type) => {
    const clonedDate = _.cloneDeep(date);
    clonedDate[type] = value
    setDate(clonedDate)
  };

  const dateValidation=()=>{
    if (props.form.mode === "U"){
      let sDate = date.start instanceof Date;
      let eDate = date.end instanceof Date;
      if(!sDate && !(date.start === null)){
        var startDateArray = date.start && date.start.split("/");
        date.start = new Date(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
      }
      if(!eDate && !(date.end === null)){
        var endDateArray = date.end && date.end.split("/");
        date.end = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
      }
    }
    const start = moment(date.start);
    const end = moment(date.end);
    const diff = end.diff(start, 'day');
    return diff >= 0 || !date.start || !date.end;
  }

  const endDateValidation=()=>{
    const today = moment();
    const end = moment(date.end);
    const diff = end.diff(today, 'minutes');
    return diff > -2
  }

  const startDateAvl=props.form.data.startDate ? moment(props.form.data.startDate):moment();
  const today=moment();
  const diff = today.diff(startDateAvl, 'day');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
      <Grid item container md={12} xs={12} spacing={1}>
      <Grid item md={6} xs={12}>
        <Typography variant="caption">Quantity From Range</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.qtyFromRange)}
              inputRef={register({
                required: "Quantity is required",
                pattern: {
                  value: /^[0-9]*.[0-9]{0,3}$/ ,
                  message: "minimum 1 digit is required",
                },
              })}
              size="small"
              name="qtyFromRange"
            />
            <FormHelperText error>
              {errors.qtyFromRange && errors.qtyFromRange.message}
            </FormHelperText>
          </Grid>

          <Grid item md={6} xs={12}>
        <Typography variant="caption">Quantity To Range</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.qtyToRange)}
              inputRef={register({
                required: "Quantity is required",
                pattern: {
                  value: /^[0-9]*.[0-9]{0,3}$/ ,
                  message: "minimum 1 digit is required",
                },
              })}
              size="small"
              name="qtyToRange"
            />
            <FormHelperText error>
              {errors.qtyToRange && errors.qtyToRange.message}
            </FormHelperText>
          </Grid>
      </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">{'Discount %'}</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.discount)}
              inputRef={register({
                required: "Discount is required",
                pattern: {
                  required: "Enter discount",
                  value: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/,
                  message: "Provide a valid discount rate",
                },
              })}
              size="small"
              disabled = {props.form.mode !== "C" && !(diff<=0) && props.form.data.isActive}
              name="discount"
            />
            <FormHelperText error>
              {errors.discount && errors.discount.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Customer Type</Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={Boolean(errors.custTypeId)}
            >
              <Controller
                as={
                  <Select>
                    {customerTypes.map(function (type,idx) {
                      return (
                        <MenuItem key={type.code} value={type.id}>
                          {type.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="custTypeId"
                size="small"
                variant="outlined"
                rules={{ required: "Customer type is required" }}
                control={control}
                defaultValue=""
              />
            </FormControl>
            <FormHelperText error>
              {errors.custTypeId && errors.custTypeId.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
          <Typography variant="caption">Start Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                  helperText=""
                    size="small"
                    error={Boolean(errors.startDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    inputRef={register({
                      required: "Start date is required",
                      pattern: {
                       value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                      validate:()=> dateValidation() || 'Start date should be lesser than end date'
                    })}
                    name="startDate"
                    value={date.start}
                    onChange={(value)=>handleDateChange(value,"start")}
                    disabled = {props.form.mode !== "C" && !(diff<=0) && props.form.data.isActive}
                    disablePast={true}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.startDate && errors.startDate.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
          <Typography variant="caption">End Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    error={Boolean(errors.endDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    inputRef={register({
                      required: "End date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                        validate:()=> endDateValidation() || 'End date cannot be lesser than today'
                      },
                    })}
                    name="endDate"
                    value={date.end}
                    onChange={(value)=>handleDateChange(value,"end")}
                    disablePast={true}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.endDate && errors.endDate.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
                <Typography variant="caption">Active</Typography>
                <Controller
              as={Checkbox}
              name="isActive"
              type="checkbox"
              inputRef={register({})}
              control={control}
            />
              </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default ProductDiscountForm;
