import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  FormControl,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Select,
  TableRow,
  Paper,
  CircularProgress,
  Box
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import ProductForm from "./ProductGroupForm";

import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

import {
  productGroupTypes
} from "../common/lookupSlice";

import { API_ALL_PRODUCT_GROUPS } from "../../config/constants";
import { currentUser } from "../login/loginSlice";

const ProductGroups = (props) => {
  const prodGrpTypes = useSelector(productGroupTypes);
  const pageSize = 10;
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  const [count, setCount] = useState();
  const [prodForm, setProdForm] = useState({ open: false });
  const [groupType, setGroupType] = useState("");

  const [data, setData] = useState([]);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const token = localStorage.getItem("authToken");

  const resetQuery = () => {
    setGroupType("");
    setQuery({
      ...query,
      groupType: "",
      page: 1,
    });
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCT_GROUPS}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.productGroups);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      typeId:
        e.target.elements.typeId.value !== "All"
          ? e.target.elements.typeId.value
          : "",
      page: 1,
    });
  };

  const editProduct = async (id) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCT_GROUPS}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setProdForm({
        ...prodForm,
        data: result.data,
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  //On success of add or edit, reset the query to load data again
  useEffect(() => {
    if (prodForm.success) {
      resetQuery();
    }
  }, [prodForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductGroupClick = (id) => {
    history.push({
      pathname: '/manageProducts',
      state: { id: id }
    })
  }
  return (
    <div>
      <Grid container>
        <Grid item container md={6} xs={12} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
                Product Group
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={2} xs={12}>
              <Typography variant="caption">Product Group Type</Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ display: "flex" }}
              >
                <Select
                  name="groupType"
                  style={{ display: "flex" }}
                  value={groupType}
                  onChange={(e) => {
                    setGroupType(e.target.value);
                    setQuery({
                      ...query,
                      typeId: e.target.value !== "All" ? e.target.value : "",
                      page: 1,
                    });
                  }}
                  variant="outlined"
                  size="small"
                >
                  <MenuItem key={0} value={"All"}>
                    All
                  </MenuItem>
                  {prodGrpTypes.map((grpType, index) => {
                    return (
                      <MenuItem key={index + 1} value={grpType.id}>
                        {grpType.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={10} xs={12}>
              <Box display="flex" justifyContent="flex-end" mt={4} mb={2} mr={0} >
                {isAuditor ? null : <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setProdForm({ ...prodForm, data: {}, mode: "C", open: true });
                  }}
                >
                  Add New Product Group
                </Button>}
              </Box>
            </Grid>
          </Grid>
        </form>

      </div>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Product Group Code</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Group Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Group Type</StyledHeaderCell>
                {isAuditor ?null:<StyledHeaderCell align="center">Action</StyledHeaderCell>}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      //style={{ cursor: props.modal ? "pointer" : "" , }}
                      style={{
                        cursor: props.modal ? "pointer" : "",
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }
                    >
                      <TableCell component="th" scope="row" style={{ cursor: "pointer" }} className="underLine" onClick={() => handleProductGroupClick(row.id)}>
                        {row.code}
                      </TableCell>
                      <TableCell align="left">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        {row.type && row.type.description}
                      </TableCell>
                      {isAuditor ?null:<TableCell align="center">
                        <IconButton
                          onClick={() => {
                            editProduct(row.id);
                          }}
                        >
                          <EditIcon color="secondary" />
                        </IconButton>
                      </TableCell>}
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={prodForm.open}
        onClose={() => {
          setProdForm({ ...prodForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {prodForm.mode === "C"
                  ? "Add Product Group"
                  : "Edit Product Group"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProdForm({ ...prodForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '220px' }}>
          <ProductForm
            form={prodForm}
            cancel={() => {
              setProdForm({ ...prodForm, open: false });
            }}
            success={() => {
              setProdForm({ ...prodForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={prodForm.success}
        onClose={() => setProdForm({ ...prodForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setProdForm({ ...prodForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {prodForm.mode === "C"
            ? "Product Group created successfully"
            : "Product Group updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ProductGroups;
