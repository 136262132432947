import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import awaitingParts from "../../images/AwaitingforParts.svg";
import pendingDelivery from "../../images/PendingDelivery.svg";
import pendingWork from "../../images/PendingWork.svg";
import pendingApproval from "../../images/PendingApproval.svg";
import workComplete from "../../images/WorkComplete.svg";
import unAssigned from "../../images/unAssigned.svg";
import createNew from "../../images/createJob.svg";
import invoiceGenerated from "../../images/invoiceGenerated.svg";
import { API_JOBS_SUMMARY } from "../../config/constants";

import { Link, Chip, Typography, Paper, Box, Grid } from "@material-ui/core";

const StatusDashboard = () => {
  const token = localStorage.getItem("authToken");

  const history = useHistory();
  const [stats, setStats] = useState([]);
  const icons = {
    PD: pendingDelivery,
    PA: pendingApproval,
    AP: awaitingParts,
    PW: pendingWork,
    WC: workComplete,
    UA: unAssigned,
    IG: invoiceGenerated,
    CR: createNew
  };

  const fetchStatsSummary = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_JOBS_SUMMARY}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const statsData =result.data ? result.data:[]
      const create = { 
        code: "CR",
        description: "Create New",
        id: 0,
        count: "+",
       }
      statsData.unshift(create)   
      setStats(statsData);
    } catch (err) {
      setStats([]);
    }
  };
  useEffect(() => {
    fetchStatsSummary();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid
      className="dashboard-grid-container"
      container
      align="center"
      alignItems="center"
      justify="center"
      spacing={2}
    >
      {stats.map((stat, index) => (
        <Grid item md={3} xs={6}>
          <Link
            component="button"
            style={{ textDecoration: "none" }}
            onClick={() => {
              if(stat.code==="CR"){
                history.push({
                  pathname: "/job",
                });
              }
              else{
              history.push({
                pathname: "/jobs",
                state: {
                  statusCode: stat.id,
                },
              });
            }
            }}
          >
            <Paper
              elevation={3}
              style={{ align: "center", width: 200, height: 200 }}
            >
              <Box
                style={{ paddingTop: 20 }}
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <img src={icons[stat.code]} alt="code" />
              </Box>
              <Box
                style={{
                  paddingTop: 30,
                  color: "#0C4B60",
                }}
                fontSize={14}
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <Typography>
                  <b>{stat.description}</b>
                </Typography>
              </Box>
              <Box
                style={{ paddingTop: 10 }}
                alignItems="center"
                justifyContent="center"
              >
                <Chip clickable label={stat.count} color="secondary" />
              </Box>
            </Paper>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatusDashboard;
