import React, { useState, useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice"
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Typography, FormHelperText, Button, Box, Select, FormControl, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import moment from 'moment';

const ERPNextTrailBalanceReport = (props) => {
  const history = useHistory();
  const [columsWithSubRows, setColumns] = useState([]);
  const [dataWithSubRows, setData] = useState([]);
  const [fiscalYearList, setFiscalYearList] = useState([]);
  const [costCenterList, setCostCenterList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [fiscalYearDate, setFiscalYearDate] = useState({ fiscalYearStartDate: '', fiscalYearEndDate: '' });
  const [currentFiscalYear, setCurrentFiscalYear] = useState('');
  const token = localStorage.getItem("authToken");
  const loggedInUser = useSelector(currentUser);
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  const [filters, setFilter] = useState({
    company: COMPANY_NAME_IN_ERPNEXT,
    presentation_currency: '',
    fiscal_year: '',
    from_date: '',
    to_date: '',
    cost_center: '',
    project: '',
    finance_book: '',
    with_period_closing_entry: 0,
    show_zero_values: 0,
    show_unclosed_fy_pl_balances: 0,
    include_default_book_entries: 0,
    show_net_values: 0
  });
  const [fiscalDate, setFiscalDate] = useState({ from_date: null, to_date: null });

  let getFiscalYearDate = (fiscalYear) => {
    const [startYear, endYear] = fiscalYear.split('-').map(Number);

    // Assuming fiscal year starts on April 1st
    const fiscalYearStartMonth = 3;

    const fiscalYearStartDate = new Date(startYear, fiscalYearStartMonth, 1);
    const fiscalYearEndDate = new Date(endYear, fiscalYearStartMonth, 0);
    return { fiscalYearStartDate: fiscalYearStartDate, fiscalYearEndDate: fiscalYearEndDate }
  }

  const getCurrentFiscalYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    // Assuming fiscal year starts on April 1st
    const fiscalYearStartMonth = 3;
    if (today.getMonth() < fiscalYearStartMonth) {
      // Current date is before the fiscal year start month
      return `${currentYear - 1}-${currentYear}`;
    } else {
      // Current date is on or after the fiscal year start month
      return `${currentYear}-${currentYear + 1}`;
    }
  };

  const loadData = async (authToken, filters, currentFiscalYear, dataConvert) => {
    try {
      let tempFilters = JSON.parse(JSON.stringify(filters));
      if (!tempFilters.fiscal_year) {
        tempFilters.fiscal_year = currentFiscalYear;
      }
      let query = {
        report_name: 'Trial Balance',
        filters: JSON.stringify(tempFilters)
      }
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextreport/trailBalance`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: query,
        }
      );
      if (result && result.data && result.data.data.length > 0) {
        let resData;
        if (tempFilters && tempFilters.presentation_currency) {
          resData = await dataConvert(result.data.data, `${tempFilters.presentation_currency}`);
        } else {
          resData = await dataConvert(result.data.data, "INR");
        }

        setData(resData);
        setColumns(result.data.columns);
      } else if (result && result.data && result.data.data.length === 0) {
        setData([]);
        setColumns(result.data.columns);
      }

    } catch (err) {
      setColumns([]);
      setData({});
    }
  };

  let getDefaultData = async (authToken, COMPANY_NAME_IN_ERPNEXT) => {
    let queryCostCenter = {
      fields: ["name", "owner"],
      filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT})
    }
    const resultCostCenter = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Cost Center`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: queryCostCenter,
      }
    );
    setCostCenterList(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data);

    let queryFiscalYear = {
      fields: ["name", "owner"]
    }
    const resultFiscalYear = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Fiscal Year`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: queryFiscalYear,
      }
    );
    setFiscalYearList(resultFiscalYear && resultFiscalYear.data && resultFiscalYear.data.data)

    let queryCurrency = {
      fields: ["name", "owner"],
      filters: JSON.stringify({ enabled: 1 })
    }
    const resultCurrency = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Currency`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: queryCurrency,
      }
    );
    setCurrencyList(resultCurrency && resultCurrency.data && resultCurrency.data.data)
  }
  useEffect(() => {
    getDefaultData(token, COMPANY_NAME_IN_ERPNEXT);
  }, [token, COMPANY_NAME_IN_ERPNEXT]);

  useEffect(() => {
    const formatNumbersInJSON = (data, currency) => {
      const formattedData = JSON.parse(JSON.stringify(data)); // Create a deep copy of the original data

      const traverse = (node) => {
        for (const key in node) {
          if (typeof node[key] === 'object' && node[key] !== null) {
            traverse(node[key]);
          } else if (typeof node[key] === 'number') {
            // Format numbers with two decimal places and currency symbol
            node[key] = node[key].toLocaleString('en-IN', {
              style: 'currency',
              currency,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          }
          // else if (typeof node[key] === 'string') {
          //   // Remove single quotes in strings
          //   node[key] = node[key].replace(/'/g, '');
          // }
        }
      };

      traverse(formattedData);
      return formattedData;
    };
    if (currentFiscalYear) {
      loadData(token, filters, currentFiscalYear, formatNumbersInJSON);
    } else {
      let fiscalYear = getCurrentFiscalYear()
      let getFiscalYearDateRes = getFiscalYearDate(fiscalYear);
      setFiscalYearDate({ fiscalYearStartDate: getFiscalYearDateRes.fiscalYearStartDate, fiscalYearEndDate: getFiscalYearDateRes.fiscalYearEndDate })
      setCurrentFiscalYear(fiscalYear);
      setFilter({ ...filters, fiscal_year: fiscalYear, presentation_currency: 'INR' })
    }

  }, [token, filters, currentFiscalYear]);

  const columns = useMemo(
    () => columsWithSubRows,
    [columsWithSubRows],
  );
  const data = useMemo(
    () => dataWithSubRows,
    [dataWithSubRows],
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: true,
    enableSubRowSelection: true,
    enableExpanding: true,
    getSubRows: (row) => row.subRows,
    initialState: { expanded: false },
    paginateExpandedRows: false,
    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableTopToolbar: true,
    enableFilters: false,
    enableDensityToggle: false,
    enableHiding: false,
    getRowId: (row) => row.account,

  });

  const { handleSubmit, control, errors, register } = useForm();

  const handleSearch = (data) => {
    // data.preventDefault();
    let from_date = "";
    let to_date = "";
    if (data && data.from_date) {
      from_date = moment(data.from_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (data && data.to_date) {
      to_date = moment(data.to_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    setFilter({
      company: COMPANY_NAME_IN_ERPNEXT,
      fiscal_year: (data.fiscal_year) ? data.fiscal_year : currentFiscalYear,
      from_date: from_date,
      to_date: to_date,
      cost_center: (data.cost_center) ? data.cost_center : '',
      project: (data.project) ? data.project : '',
      finance_book: (data.finance_book) ? data.finance_book : '',
      presentation_currency: (data.presentation_currency) ? data.presentation_currency : 'INR',
      with_period_closing_entry: (data.with_period_closing_entry) ? 1 : 0,
      show_zero_values: (data.show_zero_values) ? 1 : 0,
      show_unclosed_fy_pl_balances: (data.show_unclosed_fy_pl_balances) ? 1 : 0,
      include_default_book_entries: (data.include_default_book_entries) ? 1 : 0,
      show_net_values: (data.show_net_values) ? 1 : 0
    });
  };

  const dateValidation = () => {
    const start = moment(fiscalDate.from_date);
    const end = moment(fiscalDate.to_date);
    const diff = end.diff(start, 'day');
    return diff >= 0 || !fiscalDate.from_date || !fiscalDate.to_date;
  }

  const handleDateChange = (value, field) => {
    const clonedDate = _.cloneDeep(fiscalDate);
    clonedDate[field] = value;
    setFiscalDate(clonedDate)
  };

  const handleReset = (e) => {
    setFilter({
      company: COMPANY_NAME_IN_ERPNEXT,
      fiscal_year: currentFiscalYear,
      from_date: '',
      to_date: '',
      cost_center: '',
      project: '',
      finance_book: '',
      presentation_currency: 'INR',
      with_period_closing_entry: 0,
      show_zero_values: 0,
      show_unclosed_fy_pl_balances: 0,
      include_default_book_entries: 0,
      show_net_values: 0
    });
  }

  // const onFiscalYearChange = async (val) => {
  //   if (val) {
  //     let getFiscalYearDateRes = await getFiscalYearDate(val);
  //     const clonedDate = _.cloneDeep(fiscalYearDate);
  //     clonedDate['fiscalYearStartDate'] = getFiscalYearDateRes.fiscalYearStartDate;
  //     clonedDate['fiscalYearEndDate'] = getFiscalYearDateRes.fiscalYearEndDate;
  //     setFiscalYearDate(clonedDate)
  //   }
  //   setFilter({ ...filters, fiscal_year: val })
  // }
  return (
    <>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href=""
                onClick={() => history.push("/")}
              >
                Home
              </Link>
              <Link color="inherit" href=""
                onClick={() => history.push("/reports")}
              >
                Reports
              </Link>
              <Typography component="span" color="textPrimary">
                Trail Balance
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      {(currentFiscalYear && (fiscalYearList && fiscalYearList.length > 0) && (currencyList && currencyList.length > 0)) ?
        <form onSubmit={handleSubmit(handleSearch)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Fiscal Year</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.fiscal_year}
                >
                  <Controller
                    as={
                      <Select value={filters.fiscal_year}>
                        {fiscalYearList && fiscalYearList.length > 0 && fiscalYearList.map(function (fiscalYear, index) {
                          return (
                            <MenuItem key={`fiscalyear-${index}`} value={fiscalYear.name}>
                              {fiscalYear.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                    name="fiscal_year"
                    size="small"
                    variant="outlined"
                    control={control}
                    // rules={{ required: "Fiscal Year is required" }}
                    // onChange={(e) => {
                    //   if (e && e.length > 1) {
                    //     console.log("e[1].props && e[1].props.value", e[1].props && e[1].props.value);
                    //     onFiscalYearChange(e[1].props && e[1].props.value);
                    //   }
                    // }}
                    defaultValue={filters.fiscal_year}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Start Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk={false}
                    size="small"
                    helperText=''
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="from_date"
                    error={Boolean(errors.from_date)}
                    value={fiscalDate.from_date}
                    onChange={(value) => handleDateChange(value, "from_date")}
                    minDateMessage=''
                    minDate={fiscalYearDate.fiscalYearStartDate}
                    maxDate={fiscalYearDate.fiscalYearEndDate}
                    inputRef={register({
                      validate: (fiscalDate.from_date && fiscalDate.to_date) ? () => dateValidation() || 'Start date should be lesser than end date' : ""
                    })}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
                  {errors.from_date && errors.from_date.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">End Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    name="to_date"
                    value={fiscalDate.to_date}
                    error={Boolean(errors.to_date)}
                    onChange={(value) => handleDateChange(value, "to_date")}
                    minDateMessage=''
                    minDate={fiscalYearDate.fiscalYearStartDate}
                    maxDate={fiscalYearDate.fiscalYearEndDate}
                    inputRef={register({
                      validate: (fiscalDate.from_date && fiscalDate.to_date) ? () => dateValidation() || 'End date can be greater than start date' : ""
                    })}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
                  {errors.to_date && errors.to_date.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Currency</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.presentation_currency}
                >
                  <Controller
                    as={
                      <Select
                        value={filters.presentation_currency}
                        onChange={(e) => {
                          setFilter({ ...filters, presentation_currency: e.target.value });
                        }}
                      >
                        {currencyList && currencyList.length > 0 && currencyList.map(function (presentation_currency, index) {
                          return (
                            <MenuItem key={`presentation_currency-${index}`} value={presentation_currency.name}>
                              {presentation_currency.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                    name="presentation_currency"
                    size="small"
                    variant="outlined"
                    control={control}
                    // rules={{ required: "Fiscal Year is required" }}
                    defaultValue={filters.presentation_currency}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Cost Center</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.cost_center}
                >
                  <Controller
                    as={
                      <Select value={filters.cost_center}
                        onChange={(e) => {
                          setFilter({ ...filters, cost_center: e.target.value });
                        }}
                      >
                        <MenuItem key={`cost_center-0`} value={''}>
                          Default
                        </MenuItem>
                        {costCenterList && costCenterList.length > 0 && costCenterList.map(function (cost_center, index) {
                          return (
                            <MenuItem key={`cost_center-${index + 1}`} value={cost_center.name}>
                              {cost_center.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                    name="cost_center"
                    size="small"
                    variant="outlined"
                    control={control}
                    // rules={{ required: "Fiscal Year is required" }}
                    defaultValue={filters.cost_center}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Finance Book</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  error={errors.finance_book}
                >
                  <Controller
                    as={
                      <Select value={filters.finance_book}
                        onChange={(e) => {
                          setFilter({ ...filters, finance_book: e.target.value });
                        }}
                      >
                        <MenuItem key={`finance_book-0`} value={''}>
                          Default
                        </MenuItem>
                        {/* {costCenterList && costCenterList.length > 0 && costCenterList.map(function (cost_center, index) {
                          return (
                            <MenuItem key={`cost_center-${index+1}`} value={cost_center.name}>
                              {cost_center.name}
                            </MenuItem>
                          );
                        })} */}
                      </Select>
                    }
                    name="finance_book"
                    size="small"
                    variant="outlined"
                    control={control}
                    // rules={{ required: "Fiscal Year is required" }}
                    defaultValue={filters.finance_book}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <FormControlLabel
                fullWidth
                variant="outlined"
                size="small"
                error={errors.with_period_closing_entry}
                control={<Controller
                  as={
                    <Checkbox
                      color="primary"
                    />
                  }
                  name="with_period_closing_entry"
                  control={control}
                  onChange={(e) => {
                    if (e && e.length > 1) {
                      setFilter({ ...filters, with_period_closing_entry: e[1] });
                    }
                  }}
                />}
                label={
                  <Typography variant="caption">
                    Period Closing Entry
                  </Typography>
                }
              />
            </Grid>
            <Grid item container md={2} xs={6}>
              <FormControlLabel
                fullWidth
                variant="outlined"
                size="small"
                error={errors.show_zero_values}
                control={<Controller
                  as={
                    <Checkbox
                      color="primary"
                    />
                  }
                  name="show_zero_values"
                  control={control}
                  onChange={(e) => {
                    if (e && e.length > 1) {
                      setFilter({ ...filters, show_zero_values: e[1] });
                    }
                  }}
                />}
                label={
                  <Typography variant="caption">
                    Show zero values
                  </Typography>
                }
              />
            </Grid>
            <Grid item container md={2} xs={6}>
              <FormControlLabel
                fullWidth
                variant="outlined"
                size="small"
                error={errors.include_default_book_entries}
                control={<Controller
                  as={
                    <Checkbox
                      color="primary"
                    />
                  }
                  name="include_default_book_entries"
                  control={control}
                  onChange={(e) => {
                    if (e && e.length > 1) {
                      setFilter({ ...filters, include_default_book_entries: e[1] });
                    }
                  }}
                />}
                label={
                  <Typography variant="caption">
                    Include Default FB Entries
                  </Typography>
                }
              />
            </Grid>
            <Grid item container md={2} xs={6}>
              <FormControlLabel
                fullWidth
                variant="outlined"
                size="small"
                error={errors.show_unclosed_fy_pl_balances}
                control={<Controller
                  as={
                    <Checkbox
                      color="primary"
                    />
                  }
                  name="show_unclosed_fy_pl_balances"
                  control={control}
                  onChange={(e) => {
                    if (e && e.length > 1) {
                      setFilter({ ...filters, show_unclosed_fy_pl_balances: e[1] });
                    }
                  }}
                />}
                label={
                  <Typography variant="caption">
                    Show unclosed fiscal year's P&L balances
                  </Typography>
                }
              />
            </Grid>
            <Grid item container md={3} xs={6}>
              <FormControlLabel
                fullWidth
                variant="outlined"
                size="small"
                error={errors.show_net_values}
                control={<Controller
                  as={
                    <Checkbox
                      color="primary"
                    />
                  }
                  name="show_net_values"
                  control={control}
                  onChange={(e) => {
                    if (e && e.length > 1) {
                      setFilter({ ...filters, show_net_values: e[1] });
                    }
                  }}
                />}
                label={
                  <Typography variant="caption">
                    Show net values in opening and closing columns
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item container justifyContent={"flex-end"} md={12} xs={12}>
              <Box mt={2} mr={2}>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Generate Report
                </Button>
              </Box>
              <Box mt={2}>
                <Button size="small"
                  onClick={handleReset} variant="contained" color="secondary">
                  clear</Button>
              </Box>
            </Grid>
          </Grid>
        </form> : null}
      <Grid style={{ padding: "10px" }}></Grid>
      {columsWithSubRows && columsWithSubRows.length > 0 ?
        <MaterialReactTable table={table} /> :
        <label>Report Data not found</label>}
    </>
  );
};

export default ERPNextTrailBalanceReport;
