import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
import axios from "axios";
import { useSelector } from "react-redux";
import { locations } from '../../../components/common/lookupSlice';
import { useParams } from "react-router";

let errorMsg;
function ManageProductInvForm(props) {

  const { id } = useParams();
  const token = localStorage.getItem("authToken");
  const inventoryLocations = useSelector(locations);
  const [isProcessing, setIsProcessing] = useState(false);
  const [ subLocation, setSubLocation ] = useState(props.form.data.subLocation?props.form.data.subLocation:'');
  const [isError, setIsError] = useState(false);
  const { handleSubmit, errors, register, control } = useForm({
    defaultValues: {
      locationId: props.form.data.location && props.form.data.location.id,
      safetyStock: props.form.data.safetyStock,
    },
  });
  const onSubmit = async (data) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    try {
      if(props.form.data.id){
        const reqData = {
        safetyStock:Number(data.safetyStock),
        subLocation: subLocation
      }
      await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/inventory/${props.form.data.id}`,
          reqData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }else{
        const modData = {
          productId: Number(id),
          locationId: Number(data.locationId),
          openingQty: 0,
          openingRate: 0,
          openingAmt: 0,
          safetyStock:Number(data.safetyStock),
          subLocation: subLocation
        }
        const reqData = {
          items:JSON.stringify([modData])
        }
        await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/inventory/`,
            reqData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
      setIsProcessing(false);
      props.success();
    } catch (err) {
      errorMsg = (err.response.data && err.response.data.message && err.response.data.message[1])?err.response.data.message[1]:'Error occured while editing product inventory';
      setIsError(true);
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Location Name</Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small" disabled={props.form.data.id?true:false}
              error={Boolean(errors.locationId)}
            >
              <Controller
                as={
                  <Select>
                    {inventoryLocations.map(function (location,idx) {
                      return (
                        <MenuItem key={location.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="locationId"
                size="small"
                variant="outlined"
                rules={{ required: "Location is required" }}
                control={control}
                defaultValue=""
              />
            </FormControl>
            <FormHelperText error>
              {errors.typeId && errors.typeId.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Safety Stock</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.safetyStock)}
              inputRef={register({
                required: "Safety Stock is required",
                pattern: {
                  value: /^[0-9]*.[0-9]{0,3}$/ ,
                  message: "minimum 1 digit is required",
                },
              })}
              size="small"
              name="safetyStock"
            />
            <FormHelperText error>
              {errors.safetyStock && errors.safetyStock.message}
            </FormHelperText>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant="caption">Sub Location</Typography>
            <TextField
              variant="outlined"
              fullWidth onChange = {e => setSubLocation( e.target.value)}
              size="small"
              name="subLocation"
              value={subLocation}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default ManageProductInvForm;
