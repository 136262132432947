export const formatDate = (date) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const updatedDate = date ? new Date(date): new Date();
  return `${("0" + updatedDate.getDate()).slice(-2)} ${monthNames[updatedDate.getMonth()]} ${updatedDate.getFullYear()}`;
};

export const jsonToCsv = (data) => {
  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(','));
  for(const row of data){
      const values = headers.map(header =>{
          const escaped = (''+row[header]).replace(/"/g,'\\"');
          return escaped !== "null" ? `"${escaped}"`: '';
      });
      csvRows.push(values.join(','));
  }
 return csvRows.join('\n');
}

export const downloadCSVFile = (data,name) =>{
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', `${name}.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}


export const  extractERPNextErrorMessage = (errorString) => {
  // Extract the HTML part of the error message using a regular expression
  const htmlPartMatch = errorString && errorString.match(/'([^']*)'/);
  if (htmlPartMatch && htmlPartMatch[1]) {
      // Create a temporary DOM element to parse the HTML
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlPartMatch[1];
      // Extract the text content from the parsed HTML
      const textContent = tempElement.textContent || tempElement.innerText || "";
      // Construct the full error message without HTML tags
      const remainingMessage = errorString.split(htmlPartMatch[0])[1].trim();
      const cleanRemainingMessage = remainingMessage.replace(/<[^>]*>/g, "");
      const errorMessage = textContent + cleanRemainingMessage;
      return errorMessage.trim();
  } else {
      return errorString;
  }
}