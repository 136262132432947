import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice"
import { locations } from "../common/lookupSlice"
import axios from "axios";
import { Box, Breadcrumbs, Button, FormHelperText, Grid, Link, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import moment from 'moment';
import { Autocomplete } from "@material-ui/lab";

const ERPNextStockLedgerReport = (props) => {
  const history = useHistory();
  const [columsWithSubRows, setColumns] = useState([]);
  const [dataWithSubRows, setData] = useState([]);
  const token = localStorage.getItem("authToken");
  const loggedInUser = useSelector(currentUser);
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  const allInventoryLocations = useSelector(locations);
  const inventoryLocations = allInventoryLocations.filter((loc) => { return loc.type && loc.type.code !== "CORPORATE" && loc.ERPWarehouseId });
  const [filterDateRange, setDateRangeFilter] = useState({ from_date: null, to_date: null });
  const [isDateRangeFilterError, setDateRangeFilterError] = useState(false);
  const [fiscalYearDate, setFiscalYearDate] = useState({ fiscalYearStartDate: '', fiscalYearEndDate: '' });
  const [currentFiscalYear, setCurrentFiscalYear] = useState(null);
  const [filters, setFilter] = useState({
    company: COMPANY_NAME_IN_ERPNEXT,
    valuation_field_type: "Currency",
    from_date: '',
    to_date: ''
  })

  let getFiscalYearDate = (fiscalYear) => {
    const [startYear, endYear] = fiscalYear.split('-').map(Number);

    // Assuming fiscal year starts on April 1st
    const fiscalYearStartMonth = 3;

    const fiscalYearStartDate = new Date(startYear, fiscalYearStartMonth, 1);
    const fiscalYearEndDate = new Date(endYear, fiscalYearStartMonth, 0);
    return { fiscalYearStartDate: fiscalYearStartDate, fiscalYearEndDate: fiscalYearEndDate }
  }

  const getCurrentFiscalYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    // Assuming fiscal year starts on April 1st
    const fiscalYearStartMonth = 3;
    if (today.getMonth() < fiscalYearStartMonth) {
      // Current date is before the fiscal year start month
      return `${currentYear - 1}-${currentYear}`;
    } else {
      // Current date is on or after the fiscal year start month
      return `${currentYear}-${currentYear + 1}`;
    }
  };

  const loadData = async (authToken, filterData, dataConvert) => {
    try {
      // if (warehouse) {
      //   filterData.warehouse = warehouse;
      // }
      let query = {
        report_name: 'Stock Ledger',
        filters: `${JSON.stringify(filterData)}`
      }
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextreport/stockLedger`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: query,
        }
      );
      if (result && result.data && result.data.data.length > 0) {
        const resData = await dataConvert(result.data.data, 'INR');
        setData(resData);
        setColumns(result.data.columns);
      } else if (result && result.data && result.data.data.length === 0) {
        setData([]);
        setColumns(result.data.columns);
      }

    } catch (err) {
      setColumns([]);
      setData([]);
    }
  };

  useEffect(() => {
    if (currentFiscalYear) {
      const formatNumbersInJSON = (data, currency) => {
        const iconNeedFields = [
          "incoming_rate",
          "valuation_rate",
          "in_out_rate",
          "stock_value",
          "stock_value_difference"
        ]; // Fields that need currency formatting

        const formattedData = JSON.parse(JSON.stringify(data)); // Create a deep copy of the original data

        const traverse = (node) => {
          for (const key in node) {
            if (typeof node[key] === 'object' && node[key] !== null) {
              traverse(node[key]); // Recursively traverse nested objects
            } else if (typeof node[key] === 'number' && iconNeedFields.includes(key)) {
              // Format only specific fields with two decimal places and currency symbol
              node[key] = node[key].toLocaleString('en-IN', {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            }
          }
        };

        formattedData.forEach(item => traverse(item)); // Traverse each item in the array
        return formattedData;
      };
      loadData(token, filters, formatNumbersInJSON);
    } else {
      let fiscalYear = getCurrentFiscalYear()
      let getFiscalYearDateRes = getFiscalYearDate(fiscalYear);
      setFiscalYearDate({ fiscalYearStartDate: getFiscalYearDateRes.fiscalYearStartDate, fiscalYearEndDate: getFiscalYearDateRes.fiscalYearEndDate })
      setDateRangeFilter({ from_date: getFiscalYearDateRes.fiscalYearStartDate, to_date: getFiscalYearDateRes.fiscalYearEndDate })
      let from_date = "";
      let to_date = "";
      if (getFiscalYearDateRes && getFiscalYearDateRes.fiscalYearStartDate) {
        from_date = moment(getFiscalYearDateRes.fiscalYearStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      if (getFiscalYearDateRes && getFiscalYearDateRes.fiscalYearEndDate) {
        to_date = moment(getFiscalYearDateRes.fiscalYearEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      setFilter({
        ...filters,
        from_date: from_date,
        to_date: to_date
      })
      setCurrentFiscalYear(fiscalYear);
    }
  }, [token, currentFiscalYear, filters]);

  const dateValidation = (from_date, to_date) => {
    if (from_date && to_date) {
      const start = moment(from_date);
      const end = moment(to_date);
      const diff = end.diff(start, 'day');
      return (diff >= 0) ? true : false;
    }
    return true
  }

  const handleDateChange = (value, field) => {
    const clonedDate = _.cloneDeep(filterDateRange);
    clonedDate[field] = value;
    setDateRangeFilter(clonedDate)
    if (field === 'from_date') {
      let isValidate = dateValidation(value, filterDateRange.to_date);
      setDateRangeFilterError(!isValidate);
    } else {
      let isValidate = dateValidation(filterDateRange.from_date, value);
      setDateRangeFilterError(!isValidate);
    }

  };

  const handleSearch = () => {
    // data.preventDefault();
    let from_date = "";
    let to_date = "";
    if (filterDateRange && filterDateRange.from_date) {
      from_date = moment(filterDateRange.from_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (filterDateRange && filterDateRange.to_date) {
      to_date = moment(filterDateRange.to_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    setFilter({
      ...filters,
      from_date: from_date,
      to_date: to_date
    });
  };

  const handleReset = () => {
    setFilter({
      ...filters,
      from_date: fiscalYearDate.fiscalYearStartDate,
      to_date: fiscalYearDate.fiscalYearEndDate
    });
  };

  const columns = useMemo(
    () => columsWithSubRows,
    [columsWithSubRows],
  );
  const data = useMemo(
    () => dataWithSubRows,
    [dataWithSubRows],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false,
    enableSubRowSelection: true,
    enableExpanding: true,
    getSubRows: (row) => row.subRows,
    initialState: { expanded: false },
    paginateExpandedRows: false,
    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableTopToolbar: true,
    enableFilters: false,
    enableDensityToggle: false,
    enableHiding: false,
    getRowId: (row) => row.account
  });

  return (
    <>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href=""
                onClick={() => history.push("/")}
              >
                Home
              </Link>
              <Link color="inherit" href=""
                onClick={() => history.push("/inventoryhome")}
              >
                Inventory
              </Link>
              <Typography component="span" color="textPrimary">
                Stock Ledger Report
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">From Date</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={false}
                  size="small"
                  helperText=''
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  name="from_date"
                  value={filterDateRange.from_date}
                  onChange={(value) => handleDateChange(value, "from_date")}
                />
              </MuiPickersUtilsProvider>
              {isDateRangeFilterError && <FormHelperText error={true}>
                Filter Form Date Should be less than To Date
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">To Date</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  helperText=""
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  name="to_date"
                  value={filterDateRange.to_date}
                  onChange={(value) => handleDateChange(value, "to_date")}
                />
              </MuiPickersUtilsProvider>
              {isDateRangeFilterError && <FormHelperText error={true}>
                Filter To Date Should be greater than From Date
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Location Name</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                options={inventoryLocations}
                size="small"
                getOptionLabel={(option) => option.name}
                onChange={(e, val) => {
                  if (val) {
                    setFilter({
                      ...filters,
                      warehouse: val.ERPWarehouseId,
                    });
                  }
                }}
                onInputChange={(e, val) => {
                  if (!val) {
                    setFilter({
                      ...filters,
                      warehouse: val,
                    });
                  }
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item container justifyContent={"flex-end"} md={12} xs={12}>
            <Box mt={2} mr={2}>
              <Button
                size="small"
                onClick={handleSearch}
                variant="contained"
                color="primary"
              >
                Generate Report
              </Button>
            </Box>
            <Box mt={2}>
              <Button size="small"
                onClick={handleReset}
                variant="contained"
                color="secondary">
                clear
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
      <Grid style={{ padding: "10px" }}></Grid>
      {columsWithSubRows && columsWithSubRows.length > 0 ?
        <MaterialReactTable table={table} /> :
        <label>Report Data not found</label>}
    </>
  );
};

export default ERPNextStockLedgerReport;
