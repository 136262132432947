import React, { useEffect, useState } from "react";
import { CircularProgress,Breadcrumbs, Button, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Snackbar, Box } from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { API_ALL_DRAFT_BY_CUSTOMER } from "../../config/constants";
import axios from "axios";
import { formatDate } from '../common/util';
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import Pagination from "@material-ui/lab/Pagination";
import MuiAlert from "@material-ui/lab/Alert";
import moment from 'moment';
import _ from "lodash";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function CustomerDraft() {

  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const customerData = location.state;
  const token = localStorage.getItem("authToken");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const pageSize = 10;  
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize, customerId: id });
  const [ customer, setCustomer ] = useState({});
  const [count, setCount] = useState();  
  const [refresh, setRefresh] = useState(false);
  const [isError, setIsError] = useState(false);
  const [saleDate, setSaleDate] = useState({startDate:null,endDate:null});

const loadData = async () =>{
  if(id){
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_DRAFT_BY_CUSTOMER}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCustomer(result.data.draftSales[0].customer);
      setCount(result.data.count);
      setData(result.data.draftSales);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }    
    setRefresh(false);
  }};

  useEffect(()=> {
    loadData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query, 
      startDate: saleDate.startDate ? moment(saleDate.startDate).format('DD/MM/YYYY'):"",
      endDate: saleDate.endDate ? moment(saleDate.endDate).format('DD/MM/YYYY'):"",
      page: 1,
    });
  };
  
  const handleDateChange = (value,field) => {
    const clonedDate = _.cloneDeep(saleDate);
    clonedDate[field] = value;
    setSaleDate(clonedDate)
  };

  const handleReset = (e) =>{
    window.location.reload();
    // setSaleDate({startDate:null,endDate:null});
    // setQuery({ page: 1, pageSize: pageSize })
  }
  
  return(
    <div>
      <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/customerForm/" + id)}>
                  Customers
                </Link>
                <Typography component="span" color="textPrimary">
                  Customer Draft Sales
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>
        <Grid container >
          <Typography style={{marginTop: '3%'}}>
            Customer Number : {customer&&customer.mobileNumber} | Customer Name: {customer&&customer.fullName}
          </Typography>
        </Grid>
        <div style={{paddingTop:10}}>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Start Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="startDate"
                    value={saleDate.startDate}
                    onChange={(value)=>handleDateChange(value,"startDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">End Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    name="endDate"
                    value={saleDate.endDate}
                    onChange={(value)=>handleDateChange(value,"endDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item>
            <Box mt={2}>
            <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Generate Report
            </Button>
            </Box>
            </Grid>
            <Grid item container md={2} xs={6}>
            
              <Box mt={2} mr={2}>
                <Button size="small"
                  onClick={handleReset} variant="contained" color="secondary">
                  clear</Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
        <div style={{ paddingTop: 40, paddingBottom: 40 }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left">Draft Invoice ID</StyledHeaderCell>
                <StyledHeaderCell align="left">Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Location</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ):
            <TableBody>
            {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) :    
                  data.map((inputField, index) =>{
            return (<TableRow key={index}>
              <TableCell align="left">
                <Link onClick={() => history.push({
                  pathname:"/recordSales/" + inputField.draftNum,
                  state: customerData && customerData})}>{inputField.draftNum}</Link>
              </TableCell>
            <TableCell align="left">{inputField.updatedAt && formatDate(inputField.updatedAt)}
            </TableCell>
            <TableCell align="left">{inputField.location?.name}
            </TableCell>
            {/* <TableCell align="right">{inputField.total && (Math.round(inputField.total * 100) / 100).toFixed(2)}
            </TableCell> */}
          </TableRow>
        )}) }
        </TableBody>}
        </Table></TableContainer></div>
        {data && count > pageSize ? (
          <Grid container justify="flex-end">
            <Grid item>
              <Pagination
                style={{ paddingTop: 15 }}
                count={
                  Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
                }
                color="secondary"
                variant="outlined"
                defaultPage={1}
                page={query.page}
                onChange={(event, value) => {
                  setQuery({ ...query, page: value });
                }}
                size="medium"
              />
            </Grid>
          </Grid>
        ) : (
            <div />
          )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
         Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
    </div>
  )
}

export default CustomerDraft;