import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import {
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import CompToPrintInv from '../reports/CompToPrintInv';
import ReactToPrint from "react-to-print";
import { currentUser } from "../login/loginSlice";
import { bankDetails, termsAndConditions } from "../common/lookupSlice"

const CustomerSales = (props) => {
  const pageSize = 10;
  const history = useHistory();
  const componentRefInv = useRef();
  const [count, setCount] = useState();
  const loggedInUser = useSelector(currentUser);
  const orgBankDetails = useSelector(bankDetails);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize, startDate: moment().format('DD/MM/YYYY') });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const token = localStorage.getItem("authToken");
  const [customerInv, setCustomerInv] = useState([]);

  const getCustomerInvoice = async (invNo) => {
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/invoices/${invNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const sales = result.data && result.data;
    return sales;
  }
  
   const handlePrintInv = async (invNo) => {
    try {
      const data = await getCustomerInvoice(invNo);
      setCustomerInv(data);      
    } catch (err) {
      console.log(err);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
        } else {
          console.log("Couldn't get invoice data.");
        }
    }
  } 
  

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/reports/customerSales`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.invoices);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div>
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Typography component="span" color="textPrimary">
                  Daily Sales
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Invoice No.</StyledHeaderCell>
                <StyledHeaderCell align="left">Customer Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Sale Amount</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row, index) => (
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <ReactToPrint onBeforeGetContent={()=>handlePrintInv(row.invoiceNum)}
                          trigger={() =>  <TableCell align="left">
                            <Link className="underLine" style={{cursor: "pointer" }}>{row.invoiceNum}</Link>
                          </TableCell>} content={() => componentRefInv.current}/>
                          <TableCell align="left">
                            {row.customer && row.customer.fullName}
                          </TableCell>
                          <TableCell align="left">
                            {row.subtotal}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
      </div>
      <Box display="none">
        <CompToPrintInv ref={componentRefInv} customerInv={customerInv} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
      </Box>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
          <div />
        )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CustomerSales;
