import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice"
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Box, Breadcrumbs, Button, FormHelperText, Grid, Link, Typography, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import moment from 'moment';

const ERPNextBankReconciliationStatement = (props) => {
  const history = useHistory();
  const [columsWithSubRows, setColumns] = useState([]);
  const [dataWithSubRows, setData] = useState([]);
  const token = localStorage.getItem("authToken");
  const loggedInUser = useSelector(currentUser);
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const orgCreatedAt = loggedInUser && loggedInUser.org && loggedInUser.org.createdAt;
  let orgFormatedDate = (orgCreatedAt) ? moment(new Date(orgCreatedAt), 'DD/MM/YYYY').format('YYYY-MM-DD') : "";
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  const [bankAccountList, setBankAccountList] = useState([]);
  const [accountValue, setAccountValue] = React.useState('');
  const [inputAccountalue, setInputAccountValue] = React.useState('');
  const [filterDateRange, setDateRangeFilter] = useState(null);
  const [isDateRangeFilterError, setDateRangeFilterError] = useState(false);
  const [filters, setFilter] = useState({
    company: COMPANY_NAME_IN_ERPNEXT,
    report_date: orgFormatedDate,
    account: ''
  })

  const loadData = async (authToken, filterData, dataConvert) => {
    try {
      let query = {
        report_name: 'Bank Reconciliation Statement',
        filters: `${JSON.stringify(filterData)}`
      }
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextreport/bankReconciliationStatement`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: query,
        }
      );
      if (result && result.data && result.data.data.length > 0) {
        const resData = await dataConvert(result.data.data, 'INR');
        setData(resData);
        setColumns(result.data.columns);
      } else if (result && result.data && result.data.data.length === 0) {
        setData([]);
        setColumns(result.data.columns);
      }

    } catch (err) {
      setColumns([]);
      setData([]);
    }
  };
  const defaultData = async (authToken, filterData) => {
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/Account?isBankReconciliationStatementReport=${true}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
    if (result && result.data && result.data.dataList.length > 0) {
      setBankAccountList(result.data.dataList);
      setAccountValue(result.data.dataList[0].name);
      setInputAccountValue(result.data.dataList[0].name);
      const today = new Date();
      let year = today.getFullYear();
      const month = today.getMonth();
      // If the current month is before April (0-indexed, so 3 is April), set the year to the previous year
      if (month < 3) {
        year -= 1;
      }

      // Create a new Date object for April 1st of the calculated year
      const financialYearStart = new Date(year, 3, 1);
      setDateRangeFilter((filterData && filterData.report_date) ? filterData.report_date : financialYearStart);
      setFilter({
        ...filterData,
        report_date: (filterData && filterData.report_date) ? filterData.report_date : financialYearStart,
        account: result.data.dataList[0].name
      });
    } else {
      setBankAccountList([]);
      setAccountValue('');
      setInputAccountValue('');
    }
  }
  useEffect(() => {
    if (filters.account && filters.report_date) {
      const formatNumbersInJSON = (data, currency) => {
        const formattedData = JSON.parse(JSON.stringify(data)); // Create a deep copy of the original data

        const traverse = (node) => {
          for (const key in node) {
            if (typeof node[key] === 'object' && node[key] !== null) {
              traverse(node[key]);
            } else if (typeof node[key] === 'number') {
              // Format numbers with two decimal places and currency symbol
              node[key] = node[key].toLocaleString('en-IN', {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            }
            // else if (typeof node[key] === 'string') {
            //   // Remove single quotes in strings
            //   node[key] = node[key].replace(/'/g, '');
            // }
          }
        };

        traverse(formattedData);
        return formattedData;
      };
      loadData(token, filters, formatNumbersInJSON);
    } else {
      defaultData(token, filters);
    }
  }, [token, filters]);

  const handleDateChange = (value) => {
    let clonedDate = _.cloneDeep(filterDateRange);
    clonedDate = value;
    setDateRangeFilter(clonedDate)
    if (!value) {
      setDateRangeFilterError(true)
    }
  };

  const handleSearch = () => {
    // data.preventDefault();
    let report_date = "";
    if (filterDateRange) {
      report_date = moment(new Date(filterDateRange), 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    setFilter({
      ...filters,
      report_date: report_date,
      account: (typeof accountValue === 'string') ? accountValue : accountValue && accountValue.name
    });
  };

  const handleReset = () => {
    const today = new Date();
    let year = today.getFullYear();
    const month = today.getMonth();
    // If the current month is before April (0-indexed, so 3 is April), set the year to the previous year
    if (month < 3) {
      year -= 1;
    }

    // Create a new Date object for April 1st of the calculated year
    const financialYearStart = new Date(year, 3, 1);
    setDateRangeFilter(financialYearStart);
    setFilter({
      ...filters,
      report_date: financialYearStart,
      account: bankAccountList && bankAccountList.length > 0 && bankAccountList[0].name
    });
  };

  const columns = useMemo(
    () => columsWithSubRows,
    [columsWithSubRows],
  );
  const data = useMemo(
    () => dataWithSubRows,
    [dataWithSubRows],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false,
    enableSubRowSelection: true,
    enableExpanding: true,
    getSubRows: (row) => row.subRows,
    initialState: { expanded: false },
    paginateExpandedRows: false,
    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableTopToolbar: true,
    enableFilters: false,
    enableDensityToggle: false,
    enableHiding: false,
    getRowId: (row) => row.account
  });

  return (
    <>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/accountshome")}>
                Accounting
              </Link>
              <Typography component="span" color="textPrimary">
                Bank Reconciliation Statement Report
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Bank Account</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                value={accountValue}
                onChange={(event, newValue) => {
                  setAccountValue(newValue);
                }}
                inputValue={inputAccountalue}
                onInputChange={(event, newInputValue) => {
                  setInputAccountValue(newInputValue);
                }}
                options={bankAccountList}
                getOptionLabel={(option) => (typeof option.name === 'string' ? option.name : '')}
                filterOptions={(options, addrType) =>
                  options.filter((option) =>
                    typeof option.name === 'string' &&
                    option.name.toLowerCase().includes(addrType.inputValue.toLowerCase())
                  )
                }
                renderInput={(params) => <TextField {...params}
                  variant="outlined"
                  name="bankAccount"
                  fullWidth
                  size="small"
                />}
              />
            </Grid>
          </Grid>
          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Date</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={false}
                  size="small"
                  helperText=''
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  name="report_date"
                  value={filterDateRange}
                  onChange={(value) => handleDateChange(value, "report_date")}
                />
              </MuiPickersUtilsProvider>
              {isDateRangeFilterError && <FormHelperText error={true}>
                Filter Form Date Should be less than To Date
              </FormHelperText>}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item container justifyContent={"flex-end"} md={12} xs={12}>
            <Box mt={2} mr={2}>
              <Button
                size="small"
                onClick={handleSearch}
                variant="contained"
                color="primary"
              >
                Generate Report
              </Button>
            </Box>
            <Box mt={2}>
              <Button size="small"
                onClick={handleReset}
                variant="contained"
                color="secondary">
                clear
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
      <Grid style={{ padding: "10px" }}></Grid>
      {columsWithSubRows && columsWithSubRows.length > 0 ?
        <MaterialReactTable table={table} /> :
        <label>Report Data not found</label>}
    </>
  );
};

export default ERPNextBankReconciliationStatement;
