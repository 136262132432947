import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Typography,
  TextField,
  Snackbar,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  Radio,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";

import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../../common/StyledHeaderCell";
import { useForm } from "react-hook-form";

const SearchProductGroups = (props) => {
  const { register, getValues } = useForm()
  const pageSize = 3;
  const [count, setCount] = useState();
  const [product, setProduct] = useState(null);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/productGroups?name=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.productGroups);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = () => {
    if (product) {
      if(props.lineIdentifier){
      const productCodeKey = `lineItems[${props.form.lineKey}].name`;
      const productIdKey = `lineItems[${props.form.lineKey}].id`;
      props.setValue(productCodeKey, product.name)
      props.setValue(productIdKey, product.id)
      }
      else{
      props.setValue("productId", product.name)
      props.setProdId(product.id)
      }
      props.success()
    }
    else {
      props.cancel()
    }
  }

  
  
  const handleKeyDownProducGroup = (event) => {
    if (event.key === 'Enter') {
      setSearch(getValues('productId'));
    }
  };


  return (
    <div>
      <Grid item md={6} xs={12}>
        <Typography variant="caption">Product Code</Typography>
        <TextField
          variant="outlined"
          fullWidth
          inputRef={register({})}
          InputProps={{
            name: "productId",
            autoFocus: true,
            endAdornment: (
              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                <SearchIcon onClick={() => { setSearch(getValues('productId')) }} />
              </InputAdornment>
            ),
          }}
          size="small"
          name="productId"
          onKeyDown={handleKeyDownProducGroup}
        />
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="center">Select</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Group Name</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row, index) => (
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "", }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell component="th" scope="row" align="center"> 
                            <Radio
                              checked={product && product.id === row.id}
                              onChange={() => setProduct(row)}
                              value={row.id}
                              name="productRadio"
                              inputProps={{ 'aria-label': 'A' }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {row.name}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>

      ) : (
          <div />
        )}
      <Grid
        item
        container
        xs={12}
        spacing={2}
        alignItems="flex-start"
        justify="flex-end"
      >
        <Grid item>
          <Box mt={8}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default SearchProductGroups;
