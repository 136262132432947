import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
import axios from "axios";
import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
// import { expensesAccount } from "../common/lookupSlice";
import { Autocomplete } from "@material-ui/lab";
import { Controller, useForm } from "react-hook-form";

const Customer = (props) => {
  // const availCustLocalities = useSelector(localities);
  // const expensesAccountList = useSelector(expensesAccount);
  // const custLocalities = availCustLocalities ? availCustLocalities.filter(loc => loc !== "") : [];
  const pageSize = 10;
  const history = useHistory();
  const titleRef = useRef();
  const accountTypeRef = useRef();
  // const loggedInUser = useSelector(currentUser);
  // const location = useLocation();
  // const customerData = location.state && !(location.state.click);
  const [count, setCount] = useState();
  // const [deepMobileNumSearch, setDeepMobileNumSearch] = useState(false);
  const { control } = useForm({});
  const [data, setData] = useState([]);
  const [parentAccountData, setParentAccountData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [popupErrorMsg, setPopUpErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [accountData, setAccountData] = useState({ accountName: "", parentAccount: "", id: null, success: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [isNewAccount, setAddAccount] = useState({ mode: "", open: false, data: {} });
  // const idRef = useRef();
  // const bankNameRef = useRef();
  // const acHolderNameRef = useRef();
  // const branchNameRef = useRef();
  const token = localStorage.getItem("authToken");
  const [isGroup, setIsGroup] = useState(false);
  // const [isTempGroup, setTempIsGroup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  // const [isTempDisabled, setTempIsDisabled] = useState(false);
  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      let apiQuery = JSON.parse(JSON.stringify(query));
      apiQuery["fields"] = ["company", "cost_center_name", "creation", "disabled", "docstatus", "doctype", "is_group", "modified", "modified_by", "name", "old_parent", "owner", "parent_cost_center"];
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/Cost Center`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: apiQuery,
        }
      );
      setCount(result.data.count);
      setData(result.data.dataList);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };
  const defaultData = async (authToken) => {
    try {
      let apiQuery = {};
      apiQuery["fields"] = ["company", "cost_center_name", "creation", "disabled", "docstatus", "doctype", "is_group", "modified", "modified_by", "name", "old_parent", "owner", "parent_cost_center"];
      apiQuery["is_group"] = 1;
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/Cost Center`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: apiQuery,
        }
      );
      setParentAccountData(result.data.dataList);
    } catch (err) {
      setParentAccountData([]);
    }
  };

  useEffect(() => {
    defaultData(token);
  }, [token]);

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps


  // On success of add or edit, reset the query to load data agai

  const onSubmit = async () => {
    if (accountData.accountName && accountData.parentAccount) {
      setPopUpErrorMessage("");
      setIsProcessing(true)
      var resAccount;
      try {
        let payload = JSON.parse(JSON.stringify(accountData));
        payload.isGroup = isGroup;
        payload.isDisabled = isDisabled;
        if ((isNewAccount.mode === "Edit") && accountData.id) {
          resAccount = await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/costcenter/${accountData.id}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else if (isNewAccount.mode === "New") {
          resAccount = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/costcenter`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        if (resAccount) {
          setIsProcessing(false)
          setAccountData({ accountName: "", parentAccount: "", success: true })
          setAddAccount({ mode: "", open: false, data: {} })
          setIsGroup(false);
          setIsDisabled(false);
          // setTempIsGroup(false);
          // setTempIsDisabled(false);
          loadData();
          defaultData(token)
        } else {
          setIsProcessing(false)
        }
      } catch (err) {
        let errorMsg = err.response.data.message;
        setPopUpErrorMessage(errorMsg);
        setIsError(true)
        setIsProcessing(false)
      }
    } else {
      setIsProcessing(false)
      setPopUpErrorMessage("Enter Cost Center Name and Parent Cost Center")
    }
  }


  const cancelClick = () => {
    setPopUpErrorMessage("");
    setIsProcessing(false)
    setAccountData({ accountName: "", parentAccount: "" })
    setAddAccount({ mode: "", open: false, data: {} })
    setIsGroup(false);
    setIsDisabled(false);
    // setTempIsGroup(false);
    // setTempIsDisabled(false);
  }



  const handleCodeChange = (event) => {
    setAccountData({ ...accountData, accountName: event.target.value })
  };
  const handleDescriptionChange = (event) => {
    setAccountData({ ...accountData, parentAccount: event.target.value })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      accountName: e.target.elements.accountTitle.value,
      parentCostCenter: e.target.elements.parentCostCenter.value,
      page: 1,
    });
  };

  const resetQuery = () => {
    titleRef.current.value = "";
    accountTypeRef.current.value = "";
    setQuery({
      ...query,
      accountName: titleRef.current.value,
      parentCostCenter: accountTypeRef.current.value,
      page: 1,
    });
  };
  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/configrationhome")}>
                Configuration
              </Link>
              <Typography component="span" color="textPrimary">
                Cost Center
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setPopUpErrorMessage("");
              setAccountData({ accountName: "", parentAccount: "" })
              setAddAccount({ mode: "New", open: true, data: {} });
              setIsGroup(false);
              setIsDisabled(false);
              // setTempIsGroup(false);
              // setTempIsDisabled(false);
            }}
          >
            Add Cost Center
          </Button>
        </Grid>
      </Grid>
      <div>
        <Typography variant={props.right ? "h7" : "h6"}>
          {props.modal ? "Cost Center Selection" : "Cost Center Search"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Cost Center
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={titleRef}
                    InputProps={{
                      name: "accountTitle",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Parent Cost Center
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  fullWidth
                  options={parentAccountData}
                  getOptionLabel={(option) => (typeof option.name === 'string' ? option.name : '')}
                  renderInput={(params) => <TextField {...params}
                    variant="outlined"
                    size="small"
                    name={`parentCostCenter`}
                    inputRef={accountTypeRef}
                  />}
                  filterOptions={(options, addrType) =>
                    options.filter((option) =>
                      typeof option.name === 'string' &&
                      option.name.toLowerCase().includes(addrType.inputValue.toLowerCase())
                    )
                  }
                />
              </Grid>
            </Grid>
            {(props.modal) ? <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item style={{ marginTop: '20px' }} md={12} xs={12}>
                <Button size="small" type="submit" variant="contained" color="primary">
                  Search</Button>
                <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                  onClick={() => resetQuery()} variant="contained" color="secondary">
                  clear</Button>
              </Grid>
            </Grid> :
              <Grid item container justifyContent={"flex-end"} md={2} xs={6}>
                <Box mt={2} mr={0}>
                  <Button size="small" type="submit" variant="contained" color="primary">
                    Search</Button>
                </Box>
                <Box mt={2} mr={0}>
                  <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                    onClick={() => resetQuery()} variant="contained" color="secondary">
                    clear</Button>
                </Box>
              </Grid>}
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 20 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left">Cost Center</StyledHeaderCell>
                <StyledHeaderCell align="left">Parent Cost Center</StyledHeaderCell>
                <StyledHeaderCell align="left">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={props.modal || props.right ? 5 : 6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => {
                    return (
                      <TableRow
                        style={{
                          cursor: props.modal || props.right ? "pointer" : "",
                          whiteSpace: 'normal',
                          wordBreak: 'break-word'
                        }}
                        key={row.name}
                        hover={true}
                        onClick={(e) =>
                          props.onRowSelect && props.onRowSelect(row)
                        }
                      >
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{(row.parent_cost_center) ? row.parent_cost_center : "--"}</TableCell>
                        <TableCell align="left">
                          <IconButton onClick={() => {
                            if (row.parent_cost_center) {
                              setAddAccount({ mode: "Edit", open: true, data: row })
                              setIsGroup(row.is_group);
                              setIsDisabled(row.disabled)
                              // setTempIsGroup(row.is_group);
                              // setTempIsDisabled(row.disabled);
                              setAccountData({
                                accountName: row.name,
                                parentAccount: row.parent_cost_center,
                                id: row.name,
                                costCenterName: row.cost_center_name,
                                oldParent: row.old_parent,
                                creation: row.creation,
                                modified: row.modified
                              })
                            } else {
                              setInfoMessage("This cost center has no parent cost center, so it can't be edited.")
                              setIsInfo(true)
                            }
                          }}>
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isNewAccount.open}
        onClose={() => {
          setAddAccount({ mode: "", open: false, data: {} });
          setIsGroup(false);
          setIsDisabled(false);
          // setTempIsGroup(false);
          // setTempIsDisabled(false);
          setAccountData({ accountName: "", parentAccount: "" });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {(isNewAccount && isNewAccount.mode === "New") ? "Add Cost Center" : "Edit Cost Center"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setAddAccount({ mode: "", open: false, data: {} });
                    setIsGroup(false);
                    setIsDisabled(false);
                    // setTempIsGroup(false);
                    // setTempIsDisabled(false);
                    setAccountData({ accountName: "", parentAccount: "" })
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={6} xs={6} spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography className="pop-up-form-labell" variant="caption">Cost Center Name *</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    id="accountName"
                    variant="outlined"
                    fullWidth
                    value={accountData.accountName}
                    onChange={handleCodeChange}
                    size="small"
                    disabled={(isNewAccount && isNewAccount.mode === "New") ? false : true}
                  />
                </Grid>
              </Grid>
              <Grid item container md={6} xs={6} spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography className="pop-up-form-labell" variant="caption">Parent Cost Center *</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    <Select
                      id="account-select"
                      // multiple
                      disabled={(isNewAccount && isNewAccount.mode === "New") ? false : true}
                      value={accountData.parentAccount}
                      onChange={handleDescriptionChange}
                    >
                      {parentAccountData && parentAccountData.length > 0 && parentAccountData.map(function (account, index) {
                        return (
                          <MenuItem key={`account-${index + 1}`} value={account.name}>
                            {account.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container md={6} xs={6} spacing={1}>
                <FormControlLabel
                  fullWidth
                  variant="outlined"
                  size="small"
                  // inputRef={register({
                  // })}
                  disabled={(isNewAccount && isNewAccount.mode === "Edit") ? true : false}
                  value={isGroup}
                  checked={isGroup}
                  // error={errors.isPrimary}
                  control={<Controller
                    as={
                      <Checkbox
                        color="primary"
                      />
                    }
                    name="isGroup"
                    control={control}
                    onChange={(e) => {
                      if (e && e.length > 1) {
                        setIsGroup(e[1]);
                      }
                    }}
                  />}
                  label="is Group"
                />
              </Grid>
              <Grid item container md={6} xs={6} spacing={1} style={{ display: (isNewAccount && isNewAccount.mode === "Edit") ? "" : "none" }}>
                <FormControlLabel
                  fullWidth
                  variant="outlined"
                  size="small"
                  // inputRef={register({
                  // })}
                  disabled={(isNewAccount && isNewAccount.mode === "New") ? true : false}
                  value={isDisabled}
                  checked={isDisabled}
                  // error={errors.isPrimary}
                  control={<Controller
                    as={
                      <Checkbox
                        color="primary"
                      />
                    }
                    name="isDisabled"
                    control={control}
                    onChange={(e) => {
                      if (e && e.length > 1) {
                        setIsDisabled(e[1]);
                      }
                    }}
                  />}
                  label="Disabled"
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "20px", color: "red" }}>
              <Grid item md={12} xs={12} >
                <Typography className="pop-up-form-labell" variant="caption" >{popupErrorMsg}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: 10 }}>
              <Grid
                item
                container
                xs={6}
                justifyContent={isProcessing ? "flex-end" : "flex-start"}
              >
                {isProcessing ? (
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                ) : null}
              </Grid>
              <Grid
                item
                container
                xs={6}
                spacing={2}
                alignItems="flex-start"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button size="small" onClick={() => { onSubmit() }}
                    variant="contained" color="primary">
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => cancelClick()}
                    color="secondary"
                    size="small"
                    type="reset"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {popupErrorMsg ? popupErrorMsg : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={accountData.success}
        onClose={() => setAccountData({ ...accountData, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setAccountData({ ...accountData, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {accountData.mode === "New"
            ? "Cost Center created successfully"
            : "Cost Center updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isInfo}
        onClose={() => setIsInfo(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => { setIsInfo(false); setInfoMessage("") }}
          elevation={6}
          variant="filled"
          severity="info"
        >
          {infoMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Customer;
