import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { fetchProductGroupsLookup } from "../common/lookupSlice";

import {
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import axios from "axios";
import {
  productGroupTypes,
} from "../common/lookupSlice";

let errorMsg;
function ProductGroupForm(props) {
  const dispatch = useDispatch();

  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const { handleSubmit, control, errors, register } = useForm({
    defaultValues: {
      id: props.form.data.id,
      code: props.form.data.code,
      name: props.form.data.name,
      typeId: props.form.data.type ? props.form.data.type.id : ''
    },
  });

  const onSubmit = async (data) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    try {
      if (props.form.mode === "C") {
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/productGroups`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else if (props.form.mode === "U") {
        await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/productGroups/${props.form.data.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      dispatch(fetchProductGroupsLookup());
      setIsProcessing(false);
      props.success();
    } catch (err) {
      errorMsg = 'Error occured while creating product group';
      errorMsg = err.response && err.response.data.message && err.response.data.message[0] === "Validation error" ? "Product Group Name/Code already exists" : errorMsg;
      if(err.response && err.response.data.message){
        if(err.response.data.message[1] === "code must be unique"){
          errorMsg = "Product Group Code already exists"
        } 
        else if(err.response.data.message[1] === "name must be unique"){
          errorMsg = "Product Group Name already exists"
        }
      }
      setIsError(true);
      setIsProcessing(false);
    }
  };
  const prodGroupTypes = useSelector(productGroupTypes);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Product Group Code</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.code)}
              inputRef={register({
                required: "Group code is required",
                pattern: {
                  required: "Enter product group code",
                  value: /^[a-zA-Z0-9-_,/]*$/,
                  message: "Alphanumeric, hyphen and underscore allowed",
                },
              })}
              size="small"
              name="code"
            />
            <FormHelperText error>
              {errors.code && errors.code.message}
            </FormHelperText>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="caption">Product Group Name</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.name)}
              inputRef={register({
                required: "Group name is required",
                // pattern: {
                //   value: /^[a-zA-Z0-9-_,/]*$/,
                //   message: "Alphanumeric, hyphen, space and underscore allowed",
                // },

              })}
              size="small"
              name="name"
            />
            <FormHelperText error>
              {errors.name && errors.name.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Product Group Type</Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={Boolean(errors.typeId)}
            >
              <Controller
                as={
                  <Select>
                    {prodGroupTypes.map(function (type,idx) {
                      return (
                        <MenuItem key={type.code} value={type.id}>
                          {type.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="typeId"
                size="small"
                variant="outlined"
                rules={{ required: "Group type is required" }}
                control={control}
                defaultValue=""
              />
            </FormControl>
            <FormHelperText error>
              {errors.typeId && errors.typeId.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="secondary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default ProductGroupForm;
