import React from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice";
import companyAddress from "../../images/companyAddress.svg";
import company from "../../images/company.svg";
import bankDetails from "../../images/bankDetails.svg";
import gstSetUp from "../../images/gstSetUp.svg";
import chartOfAccounts from "../../images/chartOfAccounts.svg";
import costCenter from "../../images/costCenter.svg";
import termsandConditions from "../../images/termsandConditions.svg";

const AccountHome = (props) => {
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const orgCode = loggedInUser && loggedInUser.org && loggedInUser.org.code;
  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
                Configuration
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Grid item container xs={12} md={4} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold", display: "inline-block" }}>Quick Links</ListSubheader>} ></List>
          <List style={{ display: "inline-flex", marginTop: "-20px" }}>
            <ListItem style={{ width: "200px", fontWeight: "bold" }}>
              <img src={company} alt="company" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push(`/companyconfig/${orgCode}`)}
              >
                Company
              </Link>
            </ListItem>
            <ListItem style={{ width: "200px" }}>
              <img src={gstSetUp} alt="gstSetUp" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/gstsetupconfig")}
              >
                GST Setup
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>
      <div>
        <Grid item container xs={12} md={3} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold" }}>Configuration</ListSubheader>} >
            <ListItem>
              <ListItemIcon>
                <img src={company} alt="Company" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push(`/companyconfig/${orgCode}`)}
              >
                Company
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={companyAddress} alt="Addresses" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/companyconfigaddresslist")}
              >
                Addresses
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={gstSetUp} alt="GST-Setup" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/gstsetupconfig")}
              >
                GST Setup
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={chartOfAccounts} alt="Chart-of-Account" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/chartofaccountsconfig")}
              >
                Chart of Account
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={bankDetails} alt="Bank-Details" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/companyconfigbankdetaillist")}
              >
                Bank Details
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={costCenter} alt="Cost-Center" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/costcentersconfig")}
              >
                Cost Center
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={termsandConditions} alt="TermAndConditions" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/orgconfigtermscondlist")}
              >
                Terms And Conditions
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>
    </div>
  );
};

export default AccountHome;
