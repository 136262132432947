import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Typography,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  // FormControl,
  // Select,
  // MenuItem,
  Breadcrumbs,
  Link,
  Checkbox,
  Box
} from "@material-ui/core";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import _ from 'lodash';
// import moment from 'moment';
import SearchIcon from "@material-ui/icons/Search";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import UnCheckedBox from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckedBox from "@material-ui/icons/CheckBox";
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import { locations } from '../common/lookupSlice';
import axios from "axios";
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice";
import { termsAndConditions } from "../common/lookupSlice"
import { renderToString } from 'react-dom/server'
import SearchProduct from "../productGroups/searchProduct";
import CompToPrintEstimate from './CompToPrintQuotation';
import ProductForm from "../products/ProductForm";
import SupplierForm from "./supplierForm";
import SelectMaterialRequest from "./selectMaterialRequest";
// import Pagination from "@material-ui/lab/Pagination";
let errorMsg;

function PurchaseForm(props) {
  // const { id } = useParams();
  const history = useHistory();
  const inventoryLocations = useSelector(locations);
  const loggedInUser = useSelector(currentUser);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const componentRefReqQuotation = useRef();
  // const targetWarehousesList = inventoryLocations.filter(loc => loc.type !== "CORPORATE");
  const corporateLocation = inventoryLocations.filter(loc => loc.type && loc.type.code === "CORPORATE");
  const corporateLocationId = corporateLocation.length > 0 ? corporateLocation[0].id : 1;
  const [selectProduct, setSelectProduct] = useState({ open: false, test: null });
  const [selectNewProduct, setSelectNewProduct] = useState({ open: false, test: null });
  const [selectNewSupplier, setSelectNewSupplier] = useState({ open: false });
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSafetyStock, setSafetyStock] = useState(false);
  const [isErrorSup, setIsErrorSup] = useState(false);
  const [errQtyProIndex, setErrQtyProd] = useState([]);
  const [form, setFormData] = useState({ mode: "C", data: { mrNum: "" } });
  // const [count, setCount] = useState();
  let [supplierIds, setSelectSuppliers] = useState([])
  // const pageSize = 10;
  const [date, setDate] = useState({ requireDate: null });

  const [showPo, setShowMr] = useState(false);
  const [selectedMr, setSelectedMr] = useState({ mrNum: "", products: [] });

  const { handleSubmit, control, errors, register, setValue, getValues } = useForm({
    defaultValues: {
      mrNum: "",
      requireDate: date.requireDate,
      lineItems: []
    },
  });
  const [supplierData, setSupplierData] = useState([]);
  const [additionalSupplierData, setAdditionalSupplierData] = useState([]);

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "lineItems",
  });

  let loadSupplierData = async (prods, authToken) => {
    let apiResProductIds = prods && prods.length > 0 && prods.map((prod) => {
      return prod.productId;
    })
    if (apiResProductIds && apiResProductIds.length > 0) {
      const supplierResData = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/suppliers/turn/supplieritems`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: { productIds: `${(apiResProductIds)}` },
        }
      );
      setSupplierData(supplierResData.data.supplier);
    }
  }
  useEffect(() => {
    if (selectedMr && selectedMr.mrNum) {
      console.log("selectedMr.products", selectedMr.products);
      append(selectedMr.products);
      loadSupplierData(selectedMr.products, token)
    }
  }, [selectedMr, append, token]);


  const onSubmit = async (data) => {
    console.log("data", data);
    if (data.requireDate) {
      var startDateArray = data.requireDate && data.requireDate.split("/");
      data.requireDate = startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2];
    }
    let errqtyProd = [];
    if (supplierIds && supplierIds.length > 0) {
      setIsErrorSup(false);
    }
    if (data.lineItems) {
      delete data.id;
      setIsProcessing(true);
      setIsError(false);
      errorMsg = "";
      if (data.lineItems) {
        let modifiedItems = data.lineItems.map((obj, objIndex) => {
          obj.id = obj.contactId;
          let isQtyErr = false;
          if (obj.isQtyDecimal === "false") {
            if (!(Number.isInteger(parseFloat(obj.requiredQty)))) {
              isQtyErr = true;
            }
          }
          if (isQtyErr) {
            errqtyProd.push(objIndex)
          }
          delete obj["id"];
          return obj;
        });
        data.lineItems = JSON.stringify(modifiedItems);
      } else {
        data.lineItems = "[]";
      }
      if (errqtyProd.length === 0 && supplierIds && supplierIds.length > 0) {
        data.statusId = 1;
        data.supplierIds = supplierIds;
        let invoiceBodyData = () => {
          return (
            <CompToPrintEstimate ref={componentRefReqQuotation} customerInv={data} loggedInUser={loggedInUser} orgTermsAndConditions={orgTermsAndConditions} />
          )
        }
        data.quotationHtml = renderToString(invoiceBodyData());
        try {
          await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/supplierrequestquotation/`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsProcessing(false);
          window.location.href = `/supplierquotationrequests`;
          setFormData({
            ...form, mode: "C"
          })
        } catch (err) {
          errorMsg = err.response.data.message
          setIsError(true);
          setIsProcessing(false);
        }

      } else {
        console.log("error");
        setErrQtyProd(errqtyProd);
        setIsProcessing(false);
        if (supplierIds && supplierIds.length < 1) {
          setIsErrorSup(true);
        }

      }
    } else {
      errorMsg = "Product is required";
      setIsError(true);
    }
  };

  const handleDateChange = (value, type) => {
    const clonedDate = _.cloneDeep(date);
    clonedDate[type] = value
    setDate(clonedDate)
  };

  useEffect(() => {

  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrintTest = async (index, fields) => {
    if (fields && fields.length > 0) {
      await fields.splice(index, 1);
      let productIds = fields.filter((data) => {
        return data && data.productId && (parseInt(data.productId) > 0)
      })
      let apiResProductIds = productIds && productIds.length > 0 && productIds.map((prod) => {
        return prod.productId;
      })
      if (apiResProductIds && apiResProductIds.length > 0) {
        const supplierResData = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/suppliers/turn/supplieritems`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { productIds: `${(apiResProductIds)}` },
          }
        );
        setSupplierData(supplierResData.data.supplier);
      }
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item container xs={8} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/purchasehome")}>
                Purchase
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/supplierquotationrequests")}>
                Supplier Request Quotations
              </Link>
              <Typography component="span" color="textPrimary">
                Add Request Quotations
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid item container xs={4} spacing={1} style={{ marginTop: '2%' }}>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} >
          <Grid item container md={12} xs={12} spacing={1} alignItems="center">
            <Grid item md={4} xs={4}>
              <Typography variant="caption">Material Request No. </Typography>
              <TextField
                variant="outlined"
                fullWidth placeholder="Auto Generated"
                InputProps={{
                  autoFocus: true,
                  name: "mrNum",
                }} value={form.data.mrNum}
                size="small"
                name="mrNum" disabled />
            </Grid>
            <Grid item md={4} xs={4}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <Typography variant="caption">Require Date *</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      size="small"
                      fullWidth
                      helperText=""
                      error={Boolean(errors.requireDate)}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      InputProps={{
                        style: { color: "black" },
                      }}
                      inputRef={register({
                        required: "Date is required",
                        pattern: {
                          value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                        },
                      })}
                      name="requireDate"
                      minDate={(form.mode === "U") ? form && form.data && form.data.requireDate : new Date()}
                      value={date.requireDate}
                      disabled={props.readOnly}
                      onChange={(value) => handleDateChange(value, "requireDate")}
                    />
                  </MuiPickersUtilsProvider>
                  <FormHelperText error>
                    {errors.requireDate && errors.requireDate.message}
                  </FormHelperText>
                </Grid>
              </Grid>


            </Grid>
            <Grid item md={4} xs={4} style={{ marginTop: '20px', textAlign: "right" }}>
              <Button size="small" variant="contained" color="primary"
                onClick={async (e) => {
                  if (fields && fields.length) {
                    await insert(fields && fields.length, {
                      contactId: "",
                      productId: "",
                      productCode: "",
                      requiredQty: "",
                      uom: ""
                    });
                  } else {
                    await append({
                      contactId: "",
                      productId: "",
                      productCode: "",
                      requiredQty: "",
                      uom: "",
                    });
                  }
                  setSelectNewProduct({
                    ...selectNewProduct, open: true, mode: "C",
                    lineItems: form.data.lineItems,
                    lineKey: (fields && fields.length) ? fields && fields.length - 1 : 0, data: {}
                  })
                }}>
                Add New Product</Button>&emsp;
              <Button size="small" variant="contained" color="primary"
                onClick={async (e) => {
                  setSelectNewSupplier({
                    ...selectNewSupplier, open: true, mode: "C", data: {}
                  })
                }}
              >
                Add Supplier</Button>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={2}>
            <Grid item container xs={6} md={6} justifyContent="flex-start" >
              <IconButton
                onClick={() => {
                  setShowMr(true);
                }}
                style={{ backgroundColor: 'transparent' }} >
                <Typography style={{ fontWeight: "bold", color: "black", pointerEvents: "none" }}> Material Request:</Typography>
                &nbsp;
                <Typography>{selectedMr && selectedMr.mrNum}</Typography>
                &nbsp;
                <SearchIcon />
              </IconButton></Grid>
            <Grid item container xs={6} md={6} justifyContent="flex-end" >
              <Controller
                as={Checkbox}
                name="purchasePrice"
                type="checkbox"
                onChange={(e) => { setSafetyStock(e[1]) }}
                checked={isSafetyStock}
                control={control}
              /><Box mt={1.7} ml={2}>
                <Typography>Filter Below Item Safety Stock</Typography>
              </Box>

            </Grid>
          </Grid>
        </Grid>
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          {fields.map((contact, index) => (
            <>
              <input
                type="hidden"
                name={`lineItems[${index}].contactId`}
                value={`${contact.contactId}`}
                ref={register()}
              />
              <input
                type="hidden"
                name={`lineItems[${index}].contactId`}
                value={`${contact.contactId}`}
                ref={register()}
              />
            </>
          ))}
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell align="left" style={{ width: "30%" }} colSpan={4}>Product Name</StyledHeaderCell>
                  <StyledHeaderCell align="left">Required Qty</StyledHeaderCell>
                  <StyledHeaderCell align="left">Available Qty</StyledHeaderCell>
                  <StyledHeaderCell align="left">UOM</StyledHeaderCell>
                  <StyledHeaderCell align="left">Action</StyledHeaderCell>
                </TableRow>
              </TableHead>
              {fields.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      {/* <Typography variant="subtitle1">
                        Nothing Material Request.
                      </Typography> */}
                      <Button
                        variant="contained"
                        onClick={async (e) => {
                          await append({
                            contactId: "",
                            productId: "",
                            requiredQty: "",
                            uom: "",
                          });
                          setErrQtyProd([]);
                        }}
                        color="primary"
                        size="small"
                      >
                        ADD PRODUCT
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : null}

              <TableBody>
                {fields.map((contact, index) => {
                  return (
                    <TableRow key={contact.id}>
                      <TableCell align="left" colSpan={4}>
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.lineItems &&
                            errors.lineItems[index] &&
                            errors.lineItems[index].productCode
                          )}
                          inputRef={register({
                            required: "Product is required",
                          })}
                          onClick={(e) => setSelectProduct({ ...selectProduct, open: true, lineKey: index, lineItems: form.data.lineItems, create: form.mode === "C" })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                                <SearchIcon onClick={(e) => setSelectProduct({ ...selectProduct, open: true })} />
                              </InputAdornment>
                            ),
                            style: { fontSize: "14px" },
                          }}
                          multiline
                          minRows={1}
                          maxRows={3}
                          size="small"
                          name={`lineItems[${index}].productCode`}
                          key={contact.id} disabled
                          defaultValue={contact.productCode}
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.lineItems &&
                            errors.lineItems[index] &&
                            errors.lineItems[index].productId
                          )}
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`lineItems[${index}].productId`}
                          key={contact.id} disabled
                          defaultValue={form.data.lineItems && (form.data.lineItems)[index] ? (form.data.lineItems)[index].product && (form.data.lineItems)[index].product.id :
                            selectedMr.products[index] ? selectedMr.products[index].productId : contact.productId ? contact.productId : ''}
                        />
                        <FormHelperText error>
                          {errors.lineItems &&
                            errors.lineItems[index] &&
                            errors.lineItems[index].productCode &&
                            errors.lineItems[index].productCode.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          error={Boolean(
                            errors.lineItems &&
                            errors.lineItems[index] &&
                            errors.lineItems[index].requiredQty
                          ) || (_.includes(errQtyProIndex, index)) ? true : false}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Required",
                            pattern: {
                              value: /^[0-9]*.[0-9]{0,3}$/,
                              message: "Qty should be greater than 0",
                            },
                          })}
                          size="small"
                          name={`lineItems[${index}].requiredQty`}
                          defaultValue={contact.requiredQty} disabled={props.readOnly}
                          variant="standard"
                        />
                        <FormHelperText error>
                          {(errors.lineItems &&
                            errors.lineItems[index] &&
                            errors.lineItems[index].requiredQty &&
                            errors.lineItems[index].requiredQty.message) || ((_.includes(errQtyProIndex, index)) ? true : false) ? "Enter Valid Qty" : ""}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            pattern: {
                              value: /^[0-9]*.[0-9]{0,3}$/,
                              message: "Qty should be greater than 0",
                            },
                          })}
                          size="small"
                          name={`lineItems[${index}].availableQty`}
                          defaultValue={contact.availableQty} disabled={true}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          disabled
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          inputRef={register({})}
                          name={`lineItems[${index}].uom`}
                          defaultValue={contact.uom}
                          variant="standard"
                          fullWidth
                        />
                      </TableCell>
                      {(!props.readOnly) && <TableCell align="left" style={{ width: '12%' }}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            insert(index + 1, {
                              contactId: "",
                              productId: "",
                              productCode: "",
                              requiredQty: "",
                              availableQty: "",
                              negotiatedPrice: "",
                              discountPct: "",
                              isQtyDecimal: false,
                            });
                            setErrQtyProd([]);
                          }
                          }
                        >
                          <AddIcon color="secondary" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            remove(index);
                            setErrQtyProd([]);
                            handlePrintTest(index, fields)
                          }}
                        >
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                      }
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Serach And Select Product */}
          <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={selectProduct.open}
            onClose={() => {
              setSelectProduct({ ...selectProduct, open: false });
            }}
          >
            <DialogTitle>
              <Grid container>
                <Grid item container xs={9} alignItems="center">
                  <Grid item>
                    Select Product
                  </Grid>
                </Grid>
                <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setSelectProduct({ ...selectProduct, open: false });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent >
              <SearchProduct
                form={selectProduct}
                setValue={setValue}
                getValues={getValues}
                exist={true}
                isSupplierQuotationReq={true}
                lineIdentifier={true}
                setSupplierData={setSupplierData}
                addonData={{ customerType: props.customerTypeId, locationId: corporateLocationId }}
                cancel={() => {
                  setSelectProduct({ ...selectProduct, open: false });
                }}
                success={() => {
                  setSelectProduct({ ...selectProduct, open: false, success: true });
                }}
              />
            </DialogContent>
          </Dialog>
          {/* Add New Product form */}
          <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={selectNewProduct.open}
            onClose={() => {
              setSelectNewProduct({ ...selectNewProduct, open: false });
            }}
          >
            <DialogTitle>
              <Grid container>
                <Grid item container xs={9} alignItems="center">
                  <Grid item>
                    Add Product
                  </Grid>
                </Grid>
                <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setSelectNewProduct({ ...selectNewProduct, open: false });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent >
              <ProductForm
                form={selectNewProduct}
                cancel={() => {
                  setSelectNewProduct({ ...selectNewProduct, open: false });
                }}
                success={(prodRes, lineIndex) => {
                  const productCodeKey = `lineItems[${lineIndex}].productCode`;
                  const availableQtyKey = `lineItems[${lineIndex}].availableQty`;
                  const fullfilledQtyKey = `lineItems[${lineIndex}].fulfilledQty`;
                  const productIdKey = `lineItems[${lineIndex}].productId`;
                  const productPrice = `lineItems[${lineIndex}].negotiatedPrice`;
                  const isQtyDecimal = `lineItems[${lineIndex}].isQtyDecimal`;
                  const uom = `lineItems[${lineIndex}].uom`;

                  setValue(productPrice, prodRes && prodRes.price ? (Math.round(prodRes.price * 100) / 100).toFixed(2) : '0.00') //set the product price if we are getting it from api
                  setValue(productCodeKey, prodRes && prodRes.productName)
                  setValue(isQtyDecimal, false)
                  setValue(availableQtyKey, prodRes && prodRes.availableQty ? prodRes.availableQty : 0)
                  setValue(fullfilledQtyKey, prodRes && prodRes.fulfilledQty ? prodRes.fulfilledQty : 0)
                  setValue(productIdKey, prodRes && prodRes.id)
                  setValue(uom, prodRes && prodRes.uom);
                  setSelectNewProduct({ ...selectNewProduct, open: false, success: true });
                }}
                isSupplierQuotationReq={true}
                setValue={setValue}
                getValues={getValues}
                setSupplierData={setSupplierData}
              />
            </DialogContent>
          </Dialog>
          {/* Add New Supplier form */}
          <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={selectNewSupplier.open}
            onClose={() => {
              setSelectNewSupplier({ ...selectNewSupplier, open: false });
            }}
          >
            <DialogTitle>
              <Grid container>
                <Grid item container xs={9} alignItems="center">
                  <Grid item>
                    Add Supplier
                  </Grid>
                </Grid>
                <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setSelectNewSupplier({ ...selectNewSupplier, open: false });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent >
              <SupplierForm
                form={selectNewSupplier}
                cancel={() => {
                  setSelectNewSupplier({ ...selectNewSupplier, open: false });
                }}
                success={(supData) => {
                  setAdditionalSupplierData([...additionalSupplierData, supData])
                  setSelectNewSupplier({ ...selectNewSupplier, open: false, success: true });
                }}
                isPopup={true}
                setSupplierData={setAdditionalSupplierData}
              />
            </DialogContent>
          </Dialog>
          {/* Select Material Request */}
          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={showPo}
            onClose={() => {
              setShowMr(false);
            }}
          >
            <DialogTitle>
              <Grid container>
                <Grid item container xs={9} alignItems="center">
                  <Grid item>
                    Select Material Request
                  </Grid>
                </Grid>
                <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setShowMr(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent >
              <SelectMaterialRequest remove={remove} setIsError={setIsError} setSelectedMr={setSelectedMr} setShowMr={setShowMr} />
            </DialogContent>
          </Dialog>
        </div>
        <Grid style={{ paddingTop: 30 }}>
          <TableContainer component={Paper}>
            <Table style={{ paddingTop: 10 }} size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell>Supplier Number</StyledHeaderCell>
                  <StyledHeaderCell align="left">Supplier Name</StyledHeaderCell>
                  <StyledHeaderCell align="left">Locality</StyledHeaderCell>
                  <StyledHeaderCell align="left">Mobile Number</StyledHeaderCell>
                  <StyledHeaderCell align="left">Email</StyledHeaderCell>
                  <StyledHeaderCell align="center">Action</StyledHeaderCell>

                </TableRow>
              </TableHead>
              {((supplierData && supplierData.length && supplierData.length > 0) || (additionalSupplierData && additionalSupplierData.length && additionalSupplierData.length > 0)) ?
                <TableBody>
                  {supplierData.map((row) => (
                    <TableRow
                      style={{
                        cursor: "pointer",
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                      key={row.id}
                      hover={true}
                      onClick={(e) => { }}
                    >
                      <TableCell component="th" scope="row">{row.id}</TableCell>
                      <TableCell align="left">{row.supplier && row.supplier.fullName}</TableCell>
                      <TableCell align="left">{row.supplier && row.supplier.locality}</TableCell>
                      <TableCell align="left">{row.supplier && row.supplier.mobileNumber}</TableCell>
                      <TableCell align="left">{row.supplier && row.supplier.email}</TableCell>
                      <TableCell align="center">
                        {(_.includes(supplierIds, row.supplier.id)) ? <IconButton onClick={(e) => {
                          setSelectSuppliers(supplierIds.filter((item) => item !== row.supplier.id));
                        }}>
                          <CheckedBox color="secondary" />
                        </IconButton> :
                          <IconButton onClick={(e) => { setSelectSuppliers([...supplierIds, row.supplier.id]) }}>
                            <UnCheckedBox color="secondary" />
                          </IconButton>}
                      </TableCell>
                    </TableRow>
                  ))
                  }
                  {additionalSupplierData.map((supplier) => (
                    <TableRow
                      style={{
                        cursor: "pointer",
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                      key={supplier.id}
                      hover={true}
                      onClick={(e) => { }}
                    >
                      <TableCell component="th" scope="row">{supplier.id}</TableCell>
                      <TableCell align="left">{supplier && supplier.fullName}</TableCell>
                      <TableCell align="left">{supplier && supplier.locality}</TableCell>
                      <TableCell align="left">{supplier && supplier.mobileNumber}</TableCell>
                      <TableCell align="left">{supplier && supplier.email}</TableCell>
                      <TableCell align="center">
                        {(_.includes(supplierIds, supplier.id)) ? <IconButton onClick={(e) => {
                          setSelectSuppliers(supplierIds.filter((item) => item !== supplier.id));
                        }}>
                          <CheckedBox color="secondary" />
                        </IconButton> :
                          <IconButton onClick={(e) => { setSelectSuppliers([...supplierIds, supplier.id]) }}>
                            <UnCheckedBox color="secondary" />
                          </IconButton>}
                      </TableCell>
                    </TableRow>
                  ))
                  }
                </TableBody> :
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      <Typography variant="subtitle1">
                        Please add an associated product for a supplier or add a new supplier.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>}
            </Table>
          </TableContainer>
        </Grid>

        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid
            item
            container
            xs={6}
            justifyContent={isProcessing ? "flex-end" : "flex-start"}
          >
            {isProcessing ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}
            {isError ? (
              <Grid item>
                <Typography error>
                  <FormHelperText error>{errorMsg}</FormHelperText>
                </Typography>
              </Grid>
            ) : null}
            {isErrorSup && supplierIds && supplierIds.length < 1 ? (
              <Grid item>
                <Typography error>
                  <FormHelperText error>Select any one Supplier</FormHelperText>
                </Typography>
              </Grid>
            ) : null}

          </Grid>
          {(!props.readOnly) && <Grid
            container
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  window.location.href = `/supplierquotationrequests`;
                }}
                color="primary"
                size="small"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={handleSubmit((data) => { onSubmit(data) })}
              >
                Save
              </Button>
            </Grid>
          </Grid>}
        </Grid>
        <input type="hidden" name="id" ref={register()} />

      </form>
    </div >
  );
}

export default PurchaseForm;
