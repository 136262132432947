import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ProductPriceForm from "./ProductPriceForm";
import ProductDiscountForm from './ProductDiscountForm';

import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { StyledHeaderCell } from "../../common/StyledHeaderCell";
import { formatDate } from '../../common/util';
import { API_ALL_PRODUCTS } from "../../../config/constants";
import { currentUser } from "../../login/loginSlice";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const ManageProductPriceForm = (props) => {
  const { handleSubmit, errors, register } = useForm({
  });
  const { id } = useParams();
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [landingCostEdit, setLandingCostEdit] = useState(false);
  const [ prodData, setProdData ] = useState();
  const [ isServiceable, setIsServiceable ] = useState(false);
  const [ isGSTApplicable, setIsGSTApplicable ] = useState(false);  
  const [ priceData, setPriceData ] = useState([]);
  const [ selectProductPrice, setSelectProductPrice ] = useState({ open: false});
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("authToken");  
  const [discountForm, setDiscountForm] = useState({ open: false });
  const [ discountData, setDiscountData ] = useState([]);
  const [ purchasePrice, setPurchasePrice ] = useState();
  const [ landingCost, setLandingCost ] = useState({data:'',success:false});
  const loggedInUser = useSelector(currentUser);
  const isPricingAdmin = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role=>role.name === "PRICING ADMIN");
  
  async function fetchData(){
    setIsError(false);
    setIsLoading(true);
    if(id){
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProdData(result.data);
        setIsServiceable(result.data.isServiceable?true:false);
        setIsGSTApplicable(result.data.isGSTApplicable?true:false);
        setPriceData(result.data && result.data.prices);
        setDiscountData(result.data && result.data.discounts);
        setPurchasePrice(result.data && result.data.purchasePrice);
        setLandingCost({...landingCost,data:result.data && result.data.landingCost})
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsError(true);
        setIsLoading(false);
      }
    }}

  useEffect(()=> {
    fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=> {
    fetchData();
  }, [selectProductPrice.success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=> {
    fetchData();
  }, [discountForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=> {
    fetchData();
  }, [landingCost.success]); // eslint-disable-line react-hooks/exhaustive-deps

  const editLandingCost = () => {
    setLandingCostEdit(true)
  }

  const handleLandingCost = async (data) => {
    try{
    await axios.patch(
      `${process.env.REACT_APP_SERVICE_URL}/products/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLandingCost({...landingCost,success:true})
    setLandingCostEdit(false)
    }
    catch (err) {
      setIsError(true);
    }
  }
  const editProductPrice = async (priceId) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}/prices`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSelectProductPrice({
        ...selectProductPrice,
        data: (result.data.prices.filter(price=> price.id === priceId)[0]),
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  const editProductDiscount = async (itemId) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}/discounts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDiscountForm({
        ...discountForm,
        data: (result.data.discounts.filter(disc =>disc.id === itemId))[0],
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  return (
    <div>
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/products")}>
                  Products</Link>
                <Link color="inherit" href="" onClick={() => history.push("/manageProdGroups/"+id)}>
                  Manage Products</Link>
                <Typography component="span" color="textPrimary">
                  Manage Price</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid item container xs={6} direction="column">
          <Grid item>
            <Button color="primary" variant="contained" style={{float:'right'}}
              onClick={() => history.push("/manageProdGroups/" + id)}>Manage Products</Button>
          </Grid>
        </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
        <Typography component="span" color="primary">
          Products Details : {prodData && prodData.productName}</Typography>
        </Grid>
        <Grid className="product-form">
        <Grid item container md={8} xs={8} spacing={1}>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">Product Code</Typography>
              <TextField variant="outlined" fullWidth value={prodData && prodData.productCode} size="small" name="productCode" disabled/>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">Manufacturer</Typography>
              <TextField variant="outlined" fullWidth value={prodData && prodData.manufacturer && prodData.manufacturer.description} size="small" name="mfgId" disabled/>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">Model</Typography>
            <TextField variant="outlined" fullWidth value={prodData && prodData.model} size="small" name="model" disabled/>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">UOM</Typography>
            <TextField variant="outlined" fullWidth value={prodData && prodData.uom} size="small" name="model" disabled/>
          </Grid>
        </Grid>

        <Grid item container md={8} xs={8} spacing={1}>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">Serviceable</Typography>
            <FormControlLabel style={{float: "center", marginLeft: "1%"}}
              control={
                <Checkbox checked={isServiceable} name="isServiceable"
                  style={{ color: "#0C4B60"}} color="primary"/>} disabled/>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">GST Applicable</Typography>
            <FormControlLabel style={{float: "center", marginLeft: "1%"}}
              control={
                <Checkbox checked={isGSTApplicable} name="isGSTApplicable"
                  style={{ color: "#0C4B60"}} color="primary"/>} disabled/>
          </Grid>
        </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{marginTop: '2%'}}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Typography component="span" color="primary">
            GST Details</Typography>
        </Grid>

        <Grid className="product-form">
        <Grid item container md={10} xs={10} spacing={1}>
          <Grid item md={2} xs={2}>
          <Typography variant="caption">GST Calculation Type</Typography>
            <TextField variant="outlined" fullWidth value={prodData && prodData.GSTCalculationType}
              size="small" name="GSTCalculationType" disabled/>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography variant="caption">GST Taxability</Typography>
              <TextField variant="outlined" fullWidth value={prodData && prodData.GSTTaxability} 
                size="small" name="GSTTaxability" disabled/>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography variant="caption">IGST</Typography>
            <TextField variant="outlined" inputProps={{style: { textAlign: 'right' }}} fullWidth value={prodData && prodData.IGST ? (Math.round(prodData.IGST * 100) / 100).toFixed(2):'0.00'}
             size="small" name="IGST" disabled/>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography variant="caption">CGST</Typography>
            <TextField variant="outlined" inputProps={{style: { textAlign: 'right' }}} fullWidth value={prodData && prodData.CGST ? (Math.round(prodData.CGST * 100) / 100).toFixed(2):'0.00'}
             size="small" name="SGST" disabled/>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography variant="caption">SGST</Typography>
            <TextField variant="outlined" inputProps={{style: { textAlign: 'right' }}} fullWidth value={prodData && prodData.SGST ? (Math.round(prodData.SGST * 100) / 100).toFixed(2):'0.00'}
             size="small" name="IGST" disabled/>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography variant="caption">CESS</Typography>
            <TextField variant="outlined" inputProps={{style: { textAlign: 'right' }}} fullWidth value={prodData && prodData.cess ? (Math.round(prodData.cess * 100) / 100).toFixed(2):'0.00'}
             size="small" name="cess" disabled/>
          </Grid>
        </Grid>
      </Grid>
      </Grid>

      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
              <StyledHeaderCell align="left">Landing Cost</StyledHeaderCell>
                {isPricingAdmin && <StyledHeaderCell align="center">Action</StyledHeaderCell>}
              </TableRow>
            </TableHead>
            {isLoading  ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Grid container>
                        <Grid item xs={2} align="left">
                    <Typography variant="subtitle1">
                        Landing Cost
                      </Typography>
                      </Grid>
                      <Grid item xs={3}>
                    <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.landingCost)}
              inputRef={register({
                required: "Landing cost is required",
                pattern: {
                  required: "Landing cost is required",
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Provide a valid Price",
                },
              })}
              inputProps={{style: { textAlign: 'right' }}}
              size="small"
              name="landingCost"
              disabled={!landingCostEdit}
              defaultValue={landingCost.data ? (Math.round(landingCost.data * 100) / 100).toFixed(2):""}
            />
            <FormHelperText error>
              {errors.landingCost && errors.landingCost.message}
            </FormHelperText>
            </Grid>
            {landingCostEdit && <Grid item xs={4} align="left">
              <Grid>
                    <Button style={{marginLeft:'4px'}} onClick={handleSubmit(handleLandingCost)} 
                    color="primary" variant="contained">
                        Save
                      </Button>
                      <Button onClick={()=> setLandingCostEdit(false)}style={{marginLeft:'4px'}} color="secondary" variant="contained">
                        Cancel
                      </Button>
                      </Grid>
                      </Grid>}
            </Grid>
                    </TableCell>
                    {isPricingAdmin && <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              editLandingCost();
                            }}
                          >
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>}
                  </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>

      <div style={{ marginTop: 10 }}>
          <Grid container spacing={2} alignItems="center">
              <Grid item md={6} xs={6}>
                <Typography color="primary">Product Price Details</Typography>
              </Grid>
              <Grid item md={6} xs={6}>
              {isPricingAdmin && <Box display="flex" justifyContent="flex-end">
              <Button color="primary"variant="contained"
                onClick={() => {
                  setSelectProductPrice({ ...selectProductPrice, data: {}, mode: "C", open: true });
                }}>Add New Product Price</Button>
                </Box>}
              </Grid>
            </Grid>
      </div>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
              <StyledHeaderCell align="left">Customer Type</StyledHeaderCell>
                <StyledHeaderCell align="left">Price</StyledHeaderCell>
                <StyledHeaderCell align="left">Start Date</StyledHeaderCell>
                <StyledHeaderCell align="left">End Date</StyledHeaderCell>
                {isPricingAdmin && <StyledHeaderCell align="center">Action</StyledHeaderCell>}
              </TableRow>
            </TableHead>
            {isLoading  ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {priceData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  priceData.map((row, index) => (
                    <TableRow key={row.id} hover={true}>
                      <TableCell component="th" scope="row">
                        { row.customerType.description }
                      </TableCell>
                      <TableCell align="right">
                        {row.price && (Math.round(row.price * 100) / 100).toFixed(2)} 
                      </TableCell>
                      <TableCell align="left">
                        { row.startDate ? formatDate(row.startDate):'' }
                      </TableCell>
                      <TableCell align="left">
                        { row.endDate ? formatDate(row.endDate):''  }
                      </TableCell>
                      {isPricingAdmin && <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              editProductPrice(row.id, row);
                            }}
                          >
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>}
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      <div style={{ marginTop: 10 }}>
          <Grid container spacing={2} alignItems="center">
              <Grid item md={6} xs={6}>
                <Typography color="primary">Product Discount Details</Typography>
              </Grid>
              <Grid item md={6} xs={6}>
              {isPricingAdmin && <Box display="flex" justifyContent="flex-end">
              <Button color="primary"variant="contained"
                onClick={() => {
                  setDiscountForm({ ...discountForm, data: {}, mode: "C", open: true });
                }}>Add New Product Discount</Button>
                </Box>}
              </Grid>
            </Grid>
      </div>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
              <StyledHeaderCell align="left">Customer Type</StyledHeaderCell>
                <StyledHeaderCell align="left">Qty From Range</StyledHeaderCell>
                <StyledHeaderCell align="left">Qty To Range</StyledHeaderCell>
                <StyledHeaderCell align="left">Discount</StyledHeaderCell>
                <StyledHeaderCell align="left">Start Date</StyledHeaderCell>
                <StyledHeaderCell align="left">End Date</StyledHeaderCell>
                {isPricingAdmin && <StyledHeaderCell align="center">Action</StyledHeaderCell>}
              </TableRow>
            </TableHead>
            {isLoading  ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {discountData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  discountData.map((row, index) => (
                    <TableRow key={row.id} hover={true}>
                      <TableCell component="th" scope="row">
                        { row.customerType.description }
                      </TableCell>
                      <TableCell align="right">
                        { row.qtyFromRange }
                      </TableCell>
                      <TableCell align="right">
                        { row.qtyToRange }
                      </TableCell>
                      <TableCell align="right">
                        { row.discount && (Math.round(row.discount * 100) / 100).toFixed(2) }
                      </TableCell>
                      <TableCell align="left">
                        { row.startDate ? formatDate(row.startDate):'' }
                      </TableCell>
                      <TableCell align="left">
                        { row.endDate ? formatDate(row.endDate):''  }
                      </TableCell>
                      {isPricingAdmin && <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              editProductDiscount(row.id);
                            }}>
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>}
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={selectProductPrice.open}
        onClose={() => {
          setSelectProductPrice({ ...selectProductPrice, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {selectProductPrice.mode === "C"
                  ? "Add Product Price"
                  : "Edit Product Price"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectProductPrice({ ...selectProductPrice, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{height:'220px'}}>
          <ProductPriceForm
            form={selectProductPrice} prodData={prodData}
            purchasePrice={purchasePrice}
            landingCost={landingCost && landingCost.data}
            cancel={() => {
              setSelectProductPrice({ ...selectProductPrice, open: false });
            }}
            success={() => {
              setSelectProductPrice({ ...selectProductPrice, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={discountForm.open}
        onClose={() => {
          setDiscountForm({ ...discountForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {discountForm.mode === "C"
                  ? "Add Product Discount"
                  : "Edit Product Discount"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setDiscountForm({ ...discountForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '340px' }}>
          <ProductDiscountForm
            form={discountForm}
            cancel={() => {
              setDiscountForm({ ...discountForm, open: false });
            }}
            success={() => {
              setDiscountForm({ ...discountForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={selectProductPrice.success || discountForm.success}
        onClose={() => discountForm.success ? setDiscountForm({ ...discountForm, success: false }) : setSelectProductPrice({ ...selectProductPrice, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setSelectProductPrice({ ...selectProductPrice, success: false })}
          elevation={6} variant="filled" severity="success">
          {selectProductPrice.mode === "C"
            ? "Product Price sdded successfully"
            : "Product Price updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={discountForm.success}
        onClose={() => setDiscountForm({ ...discountForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setDiscountForm({ ...discountForm, success: false })}
          elevation={6} variant="filled" severity="success">
          {discountForm.success && discountForm.mode === "C"
            ? "Product Discount added successfully"
            : "Product Discount updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={landingCost.success}
        onClose={() => setLandingCost({ ...landingCost, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setLandingCost({ ...landingCost, success: false })}
          elevation={6} variant="filled" severity="success">
             Product Landing Cost updated successfully
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ManageProductPriceForm;
