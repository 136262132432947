import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {formatDate} from '../common/util';
import moment from 'moment';
import {
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";

const CustomerSales = (props) => {
  const custNameRef = useRef();
  const pageSize = 10;
  const history = useHistory();
  const [isFirst,setIsFirst]=useState(true);
  const [count, setCount] = useState();
  const [errMessage, setErrMessage] = useState('');
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess,setIsSuccess]=useState(false)
  // const [paymentId,setPaymentId] = useState('');
  const [productName, setProductName] = useState('');
  const [saleDate, setSaleDate] = useState({startDate:null,endDate:null});
  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    if(query.productName){
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/reports/previoussale`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: query,
          }
        );
        setCount(result.data.count);
        setData(result.data.invoices);
      } catch (err) {
        setCount(0);
        setData([]);
        setIsError(true);
      }
    }else{
      if(!isFirst){
        setIsError(true);
        setErrMessage("Enter Product to Search.")
      }
    }
    setIsFirst(false)
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      customerName: custNameRef.current.value,
      // paymentModeId: paymentId,
      startDate: saleDate.startDate ? moment(saleDate.startDate).format('DD/MM/YYYY'):"",
      endDate: saleDate.endDate ? moment(saleDate.endDate).format('DD/MM/YYYY'):"",
      productName:productName,
      page: 1,
    });
  };

  const handleDateChange = (value,field) => {
    const clonedDate = _.cloneDeep(saleDate);
    clonedDate[field] = value;
    setSaleDate(clonedDate)
  };

  const handleReset = (e) =>{
    window.location.reload();
    // setTypeId('');
    // setPaymentId('');
    // setSaleDate({startDate:null,endDate:null});
    // setQuery({ page: 1, pageSize: pageSize })
  }

  return (
    <div>
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/reports")}>
                  Reports
                </Link>
                <Typography component="span" color="textPrimary">
                Product Price Report
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          {/* <Grid item container xs={6} direction="column" style={{textAlign:"right"}}>
              Note : Kindly add details to search
            </Grid> */}
        </Grid>
      <div style={{paddingTop:10}}>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Customer Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={custNameRef}
                    InputProps={{
                      name: "custName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <FormControl style={{ display: "flex" }}>
                  <TextField
                   onChange={(e, val) => {
                      setProductName(e.target.value);
                  }}
                    InputProps={{
                      name: "productName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Start Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="startDate"
                    value={saleDate.startDate}
                    onChange={(value)=>handleDateChange(value,"startDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">End Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    name="endDate"
                    value={saleDate.endDate}
                    onChange={(value)=>handleDateChange(value,"endDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item container md={4} xs={6}>
              <Box mt={2} mr={2}>
                <Button
                      size="small"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Generate Report
                </Button>
              </Box>
              <Box mt={2} mr={0}>
              <Button size="small"
                  onClick={handleReset} variant="contained" color="secondary">
                    clear
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left">Customer Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Product HSN Code</StyledHeaderCell>
                <StyledHeaderCell align="left">Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Discount</StyledHeaderCell>
                <StyledHeaderCell align="right">Billed Price (Excl. GST)</StyledHeaderCell>
                <StyledHeaderCell align="left">Payment Type</StyledHeaderCell>
                <StyledHeaderCell align="left">Invoice Type</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row, index) =>(
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell align="left">
                            {row.customer && row.customer.fullName}
                          </TableCell>
                          <TableCell align="left">
                          {row.lineItems&&row.lineItems.length>0&&row.lineItems[0]&&row.lineItems[0].productName}
                          </TableCell>
                          <TableCell align="left">
                          {row.lineItems&&row.lineItems.length>0&&row.lineItems[0]&&row.lineItems[0].HSNCode}
                          </TableCell>
                          <TableCell align="left">
                            {row.invoiceDate && formatDate(row.invoiceDate)}
                          </TableCell>
                          <TableCell align="right">
                            {row.lineItems&&row.lineItems.length>0&&row.lineItems[0]&& row.lineItems[0].discountPct}
                          </TableCell>
                          <TableCell align="right">
                            {row.lineItems&&row.lineItems.length>0&&row.lineItems[0]&& row.lineItems[0].qty&&row.lineItems[0].amount && (Math.round((row.lineItems[0].amount/row.lineItems[0].qty) * 100) / 100).toFixed(2)}
                          </TableCell>
                          <TableCell align="left">
                            {row.payments && row.payments[0] && row.payments[0].paymentMode && row.payments[0].paymentMode.description}
                          </TableCell>
                          <TableCell align="left" >
                            {row.type && row.type.code}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container>
        <Grid item container xs={6} direction="column" style={{textAlign:"left", paddingTop:"15px"}}>
        Note : Kindly add details to search
      </Grid>
      </Grid>
        )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {errMessage ? errMessage : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setIsSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {"Thanks for shopping!"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CustomerSales;
