import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress
} from "@material-ui/core";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import productJobImg from "../../images/Productjob.svg";
import customerjobImg from "../../images/Customerjob.svg";
import axios from "axios";

const DailyJobSyncDashboard = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [pData, setPData] = useState(null);
  const [custData, setCustData] = useState(null);


  let onProductJobClick = async () => {
    setIsLoading(true);
    let prodRes = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/bulkproductdataload`);
    setPData(prodRes && prodRes.data);
    setIsLoading(false)
  }
  let onCustJobClick = async () => {
    setIsLoading(true)
    let custRes =await axios.post(`${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/bulkcustomerdataload`);
    setCustData(custRes && custRes.data);
    setIsLoading(false)
  }


  return (
    <div>
      {isLoading?<div className="loader">
        <CircularProgress />
      </div>:null}
      <div>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary" style={{ fontWeight: 'bold' }}>
                Data Sync
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </div >
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Data Sync to ERP Next</StyledHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Grid container>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Products
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={productJobImg}
                            alt="product job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Synced Products : {pData && pData.successRES && pData.successRES.length}</Typography>
                          <Typography>UnSynced Products : {pData && pData.errorRES && pData.errorRES.length}</Typography>
                          <Typography className="errorMsg">Message : {(pData && pData.message) ? pData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                            onClick={onProductJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Customer
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={customerjobImg}
                            alt="customer job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                        <Typography>Synced Customers : {custData && custData.successRES && custData.successRES.length}</Typography>
                          <Typography>UnSynced Customers : {custData && custData.errorRES && custData.errorRES.length}</Typography>
                          <Typography className="errorMsg">Message : {(custData && custData.message) ? custData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                            onClick={onCustJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default DailyJobSyncDashboard;
