import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from '../common/util';
import { withStyles } from '@material-ui/styles';
import { transportModes } from "../common/constants"

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '1.6',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};
const tableStyle = {
  border: '1px solid #ccc',
  borderCollapse: 'collapse',
}

class CompToPrintInv extends React.Component {
  render() {
    const { classes, loggedInUser, orgBankDetails, orgTermsAndConditions } = this.props;
    const companyPrintLogo = loggedInUser && loggedInUser.org && loggedInUser.org.printLogo;
    const invDetails = this.props.purchaseInv != null && this.props.purchaseInv;
    const bankDetails =  (invDetails && invDetails.companyBankdetails) ? invDetails.companyBankdetails : orgBankDetails;
    const lineItems = this.props.purchaseInv != null && this.props.purchaseInv.lineItems;
    const labourCharges = this.props.purchaseInv != null && this.props.purchaseInv.labourCharges;
    const transportCharges = this.props.purchaseInv != null && this.props.purchaseInv.transportCharges;
    const supplier = this.props.purchaseInv != null && this.props.purchaseInv.supplier && this.props.purchaseInv.supplier;
    const invShipDetails = invDetails && invDetails.location;
    const invEWayDetails = this.props.purchaseInv != null && this.props.purchaseInv.invEWayDetails && this.props.purchaseInv.invEWayDetails;
    // const invDispatchDetails = this.props.purchaseInv != null && this.props.purchaseInv.invDispatchDetails && this.props.purchaseInv.invDispatchDetails;
    const invCustmerDetails = this.props.purchaseInv != null && this.props.purchaseInv.invCustmerDetails && this.props.purchaseInv.invCustmerDetails;
    const custContacts = supplier && supplier.contacts && supplier.contacts;
    const hsnSummary = invDetails && invDetails.HSNSummary;
    // const invOrgDetails = this.props.purchaseInv != null && this.props.purchaseInv.org && this.props.purchaseInv.org;
    let hsnTotal = 0;
    let hsnCGSTTotal = 0;
    let hsnSGSTTotal = 0;
    let hsnIGSTTotal = 0;
    hsnSummary && hsnSummary.map((row) => (
      hsnTotal += row.Total));
    hsnSummary && hsnSummary.map((row) => (
      hsnCGSTTotal += row.CGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnSGSTTotal += row.SGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnIGSTTotal += row.IGSTtotal));
    let quantity = 0;
    invDetails.lineItems && invDetails.lineItems.map((row) => (
      quantity += row.qty));
    let isIntraState = false;
    if (invDetails && invDetails.org) {
      if (invCustmerDetails) {
        isIntraState = (invCustmerDetails && invCustmerDetails.state && invDetails.org.state && (invDetails.org.state === invCustmerDetails.state)) ? true : false;
      } else {
        isIntraState = (supplier && supplier.state && invDetails.org.state && (invDetails.org.state === supplier.state)) ? true : false;
      }
    }
    let transType = "";
    if (invEWayDetails && invEWayDetails.transportMode) {
      transType = transportModes.find((data) => {
        return (data.id === invEWayDetails.transportMode) ? data : null;
      })
    }
    let orgDeclaration = "";
    if (orgTermsAndConditions && orgTermsAndConditions.length > 0) {
      let findOrgCond = orgTermsAndConditions.find((orgCond) => {
        return (orgCond && orgCond.termsConditionsType && (orgCond.termsConditionsType === "Debit Note")) ? orgCond : null;
      })
      orgDeclaration = findOrgCond && findOrgCond.declaration
    }

    return (
      <div >
        <div className="row">
          {(invDetails && invDetails.eInvIRN) ? <table style={{ width: "100%", height: "250px", margin: "0px 15px", padding: "0px" }}>
            <tr>
              <td align="left" style={{ verticalAlign: "bottom", paddingBottom: "15px" }}>
                <span style={pStyle}><b>IRN</b>&nbsp;{invDetails && invDetails.eInvIRN}</span><br></br><br></br>
                <span style={pStyle}><b>Ack No. </b>&nbsp;{invDetails && invDetails.eInvAckNo}</span><br></br><br></br>
                <span style={pStyle}><b>Ack Date </b>&nbsp;{invDetails && invDetails.eInvAckDt && formatDate(invDetails.eInvAckDt)}</span>
              </td>
              <td align="right" style={{ height: "250px" }}>{(invDetails && invDetails.eInvQRCodeImg) ? <img alt="qrCodeImg" src={invDetails.eInvQRCodeImg}
                style={{ width: "250px", height: "250px", alignItems: "right" }} /> : null} </td>
            </tr>
          </table> : null}
        </div>
        <div style={divStyle} align="center">
          {/* Invoice  */}
          {/* <Helmet><title>Print Invoice</title></Helmet> */}
          <Helmet><title></title></Helmet>
          <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', textAlign: 'center', fontWeight: 'bold', lineHeight: '2', }}>Debit Note</span>
          <table style={tableStyle} width="95%" border="1px" color="#ccc">
            <tr>
              <td rowSpan="4" width="60%">
                <table width="100%" border="0px" color="white" >
                  <tr>
                    <td width="60%">
                      <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name.toUpperCase()}<br /></b></span>
                      <span style={pStyle2}>
                        {invDetails && invDetails.location && (invDetails.location.address1.toUpperCase())}
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address2.toUpperCase())}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address3.toUpperCase() + '-' + invDetails.location.pincode)}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.locationstate ? invDetails.locationstate.name : invDetails.location && invDetails.location.state}</div>
                        <span style={pStyle2}>{invDetails && invDetails.location && invDetails.location.mobileNumber ? invDetails.location.mobileNumber : ""}<br /></span>
                      </span>
                      <span style={pStyle2}>GSTIN/UIN: {invDetails && invDetails.location && invDetails.location.GSTIN ? invDetails.location.GSTIN : invDetails && invDetails.org && invDetails.org.GSTIN ? invDetails.org.GSTIN : "Not Available"}<br /></span>
                      <span style={pStyle2}>Email: {invDetails && invDetails.location && invDetails.location.email ? invDetails.location.email : invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /></span>

                    </td>
                    <td rowSpan="4" width="50%" align="right">
                      <img alt="comLogo" src={companyPrintLogo}
                        style={{ height: "100px", width: "200px" }} />
                    </td>
                  </tr>
                </table>
              </td>
              <td width="20%">
                {(invDetails && invDetails.eInvEwbNo) ? <div>
                  <table>
                    <tr>
                      <td style={pStyle2}>
                        Invoice No.
                      </td>
                      <td style={pStyle2} align="right">
                        e-Way Bill No.
                      </td>
                    </tr>
                    <tr>
                      <td style={pStyle2}>
                        <b>{this.props.purchaseInv && this.props.purchaseInv.debitNoteNum}</b>
                      </td>
                      <td style={pStyle2} align="right">
                        <b>{invDetails.eInvEwbNo}</b>
                      </td>
                    </tr>
                  </table>
                </div> :
                  <div><span style={pStyle2}>Invoice No.</span><br />
                    <span style={pStyle2}><b>{this.props.purchaseInv && this.props.purchaseInv.debitNoteNum}</b></span></div>}


              </td>
              <td width="20%"><span style={pStyle2}>Dated. <br /><b>{this.props.purchaseInv && formatDate(this.props.purchaseInv.invoiceDate)}</b></span></td>
            </tr>
            <tr>
              <td><span style={pStyle2}>Delivery Note.<br />&nbsp;</span></td>
              <td><span style={pStyle2}>Mode/Terms of Payment<br /><b>{this.props.purchaseInv &&
                this.props.purchaseInv.payments && this.props.purchaseInv.payments.length > 0 &&
                this.props.purchaseInv.payments[0].paymentMode &&
                this.props.purchaseInv.payments[0].paymentMode.description}</b></span></td>
            </tr>
            <tr>
              <td><span style={pStyle2}>Reference No. & Date <br />&nbsp;</span><span style={pStyle2}>{(invEWayDetails && invEWayDetails.vehicleNumber) ? invEWayDetails.vehicleNumber : invDetails && invDetails.vehicleNo}&nbsp;{(invDetails && (invDetails.vehicleNo || (invEWayDetails && invEWayDetails.vehicleNumber)) && this.props.purchaseInv && this.props.purchaseInv.invoiceDate) ? <b>Dt. </b> : ""}{this.props.purchaseInv && formatDate(this.props.purchaseInv.invoiceDate)}</span></td>
              <td><span style={pStyle2}>Other Reference(s)<br />&nbsp;</span><span style={pStyle2}><b>{invDetails && invDetails.jobs && "JC-" + invDetails.jobs.id}</b></span></td>

            </tr>
            <tr>
              <td><span style={pStyle2}>Buyer's Order No.<br /><b>{this.props.purchaseInv && this.props.purchaseInv.purchaseOrder && this.props.purchaseInv.purchaseOrder.poNum}</b></span></td>
              <td><span style={pStyle2}>Dated<br /><b>{this.props.purchaseInv && this.props.purchaseInv.purchaseOrder && formatDate(this.props.purchaseInv.purchaseOrder.orderDate)}</b></span></td>
            </tr>
            <tr>
              <td rowSpan="4">
                <span style={pStyle2}>
                  Consignee (Ship to)<br /> <b>{invDetails && invDetails.org && invDetails.org.name}</b><br />
                  {(invShipDetails) ? (invShipDetails.address1 + ' \n' + invShipDetails.address2 + ' \n' + invShipDetails.address3 + ' \n' + invShipDetails.state + '-' + invShipDetails.pincode) :
                    supplier && (supplier.address1 + ' \n' + supplier.address2 + ' \n' + supplier.address3 + ' \n' + supplier.state + '-' + supplier.pincode)}<br />
                  GSTIN/UIN: {(invShipDetails) ? invShipDetails.GSTIN : supplier && supplier.GSTIN}<br />
                  State: {(invShipDetails) ? invShipDetails.state : supplier && supplier.state} {(invShipDetails && invShipDetails.GSTIN) ? ' Code: ' + invShipDetails.GSTIN.slice(0, 2) : (supplier && supplier.GSTIN) ? ' Code: ' + supplier.GSTIN.slice(0, 2) : ' '}<br />
                  Contact Person: {custContacts ? custContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                  Contact: {custContacts ? custContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                </span>
              </td>
              <td><span style={pStyle2}>Dispatch Document No.<br />  <b>{(invEWayDetails && invEWayDetails.transporterDocNo) ? invEWayDetails.transporterDocNo : invDetails && invDetails.dispatchDocNo}</b></span></td>
              <td><span style={pStyle2}>Delivery Note Date<br />&nbsp;</span></td>
            </tr>
            <tr>
              <td><span style={pStyle2}>Dispatched through<br />{(transType) ? <b>{transType.id}-{transType.name}</b> : <b>{invDetails && invDetails.dispatchThrough}</b>}</span></td>
              <td><span style={pStyle2}>Destination<br /><b>{invShipDetails ? invShipDetails.address3 + " " + invShipDetails.locality :
                invCustmerDetails ? invCustmerDetails.address3 + " " + invCustmerDetails.locality : supplier ? supplier.address3 + " " + supplier.locality : ""}</b></span></td>
            </tr>
            <tr>
              <td><span style={pStyle2}>Vehicle No.<br /><b>{(invEWayDetails && invEWayDetails.vehicleNumber) ? invEWayDetails.vehicleNumber : invDetails && invDetails.vehicleNo}</b></span></td>
              <td><span style={pStyle2}>Vehicle LR-RR<br /><b>{invDetails && invDetails.vehicleLLR}</b></span></td>
            </tr>
            <tr>
              <td colspan="2" style={{ borderBottom: '1px solid #fff' }}><span style={pStyle2}></span></td>
            </tr>
            <tr>
              <td>
                <span style={pStyle2}>
                  Buyer (Bill to) <br /> <b>{(invCustmerDetails) ? invCustmerDetails.tradeName : supplier && supplier.fullName}</b><br />
                  {(invCustmerDetails) ? (invCustmerDetails.address1 + ' \n' + invCustmerDetails.address2 + ' \n' + invCustmerDetails.address3 + ' \n' + invCustmerDetails.state + '-' + invCustmerDetails.pincode) :
                    supplier && (supplier.address1 + ' \n' + supplier.address2 + ' \n' + supplier.address3 + ' \n' + supplier.state + '-' + supplier.pincode)}<br />
                  GSTIN/UIN: {(invCustmerDetails) ? invCustmerDetails.GSTIN : supplier && supplier.GSTIN}<br />
                  State: {(invCustmerDetails) ? invCustmerDetails.state : supplier && supplier.state} {(invCustmerDetails && invCustmerDetails.GSTIN) ? ' Code: ' + invCustmerDetails.GSTIN.slice(0, 2) : (supplier && supplier.GSTIN) ? ' Code: ' + supplier.GSTIN.slice(0, 2) : ' '}<br />
                  Contact Person: {custContacts ? custContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                  Contact: {custContacts ? custContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                </span>
              </td>
              <td colspan="2"></td>
            </tr>
          </table>
          <div style={{ width: '100%', padding: '5px 2px' }}>
            <table style={tableStyle} width="95%">
              <thead>
                <tr>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Sl No.</span></td>
                  <td width="25%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Description of Goods/Services</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>GST Rate</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Quantity</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>per</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Disc.%</span></td>
                  <td width="15%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                </tr>
              </thead>
              <tbody>
                {((lineItems && lineItems.length === 0) && (labourCharges === 0 && transportCharges === 0)) ? (
                  <tr>
                    <td colspan="9" align="center">
                      <span style={pStyle2}>No records found</span>
                    </td>
                  </tr>
                ) : (
                  lineItems && lineItems.map((row, index) => (
                    <tr
                      key={index}
                      hover={true}
                    >
                      <td align="center" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {index + 1}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.productName}</b>
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.HSNCode}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.IGST} %
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.qty} {row.uom}</b>
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.unitPrice ? (Math.round(row.unitPrice * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.uom}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.discountPct ? (Math.round(row.discountPct * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</b>
                        </span>
                      </td>
                    </tr>
                  ))
                )}
                {lineItems && lineItems.length > 0 &&
                  <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>{invDetails.subtotal ? (Math.round(invDetails.subtotal * 100) / 100).toFixed(2) : '0.00'}</span>
                    </td>
                  </tr>
                }
                {labourCharges && labourCharges > 0 ? <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Labour Charges</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.labourCharges ? (Math.round(invDetails.labourCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr> : ''}
                {transportCharges && transportCharges > 0 ? <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Transportation Charges (Sales)/packing</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.transportCharges ? (Math.round(invDetails.transportCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr> : ''}
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>CGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.CGST ? (Math.round(invDetails.CGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>SGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.SGST ? (Math.round(invDetails.SGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {(!isIntraState) && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>IGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.IGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {invDetails.roundOff !== 0 && <tr>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}>&nbsp;</td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><i>{invDetails.roundOff < 0 ? 'Less:' : ''}</i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={pStyle2}><b><i>Round Off</i></b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.roundOff ? (invDetails.roundOff > 0 ? (Math.round(invDetails.roundOff * 100) / 100).toFixed(2) : '(-)' + (Math.round(invDetails.roundOff * (-100)) / 100).toFixed(2)) : '0.00'}</b></span>
                  </td>
                </tr>}
                <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{quantity}</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.total ? (Math.round(invDetails.total * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody>
                <tr>
                  <td width="50%" style={{ borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Amount Chargeable(in words) :</span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}><i>E. &amp; O.E</i>&nbsp;</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.amountInWords}</b><br /><br /></span>
                  </td>
                </tr>
                {invDetails.remarkNote ? <tr>
                  <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                    <span style={pStyle}><b>Remarks :</b>&nbsp;{invDetails.remarkNote}<br /><br /></span>
                  </td>
                </tr> : null}
                <tr>
                  <td colspan="2">
                    &nbsp;<br />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <span style={pStyle2}>Company’s PAN : <b>{invDetails && invDetails.org && invDetails.org.PAN ? invDetails.org.PAN : "Not Available"}</b></span>
                  </td>
                </tr>
                <tr>
                  <td width="50%" align="left">
                    <span style={pStyle2}><u>Declaration</u><br />{orgDeclaration}</span>
                  </td>
                  <td>
                    <span style={pStyle2}>Company's Bank Details<br />
                      Bank Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{bankDetails && bankDetails.bankName}</b><br />
                      A/c No. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{bankDetails && bankDetails.acNumber}</b><br />
                      Branch &amp; IFS Code &nbsp;: <b>{bankDetails && bankDetails.branchName} &amp; {bankDetails && bankDetails.ifsCode}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Supplier Seal and Signature<br /><br /><br /></span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CompToPrintInv);