import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocalities, custStates } from "../common/lookupSlice";
import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  Breadcrumbs,
  Link,
  Snackbar,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import { gstCategories } from "../common/constants";
import { locationTypes } from "../common/lookupSlice";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

let errorMsg;

function CompanyAddressForm(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { id } = useParams();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [companyAddressData, setcompanyAddressData] = useState({});
  let { name, addressType, locationTypeId, mobileNumber, address1, address2, address3, pincode, locality, GSTIN, panNumber, gstCategory } = companyAddressData;
  const allLocationTypesData = useSelector(locationTypes);
  const locationTypesData = allLocationTypesData && allLocationTypesData.filter(loc => loc.code && loc.code !== "CORPORATE")
  console.log("locationTypesData", locationTypesData);
  const [isError, setIsError] = useState(false);
  const [isItemRomve, setIsRemoveItem] = useState(false);
  const { handleSubmit, errors, control, register } = useForm({});
  // const [inputFields, setInputFields] = useState([]);
  const [create, setCreate] = React.useState({});
  const stateObj = useSelector(custStates);
  const states = stateObj ? stateObj.map(state => state.name) : [];
  const [value, setValue] = React.useState('');
  const [addrType, setAddressType] = React.useState('');
  const [GSTType, setGSTType] = React.useState('');
  // const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [saved, setSaved] = React.useState(false);
  const [isGSTNErr, setGSTNErr] = useState(false);
  const [isPincodeErr, setPincodeErr] = useState(false);
  const [pincodeErrMsg, setPincodeErrMsg] = useState(false);
  // const [prodItemForm, setProdItemForm] = useState({ open: false, mode: "C" });
  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isPm = (chars[1]) ? chars[1].toUpperCase() === "YES" : false;
  const [isPrimaryData, setIsPrimary] = useState(isPm);

  const fetchData = async (id) => {
    if (id) {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/companyaddress/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAddressType(result.data && result.data.addressType)
        setGSTType(result.data && result.data.gstCategory)
        setIsPrimary(result.data && result.data.isPrimary)
        await setcompanyAddressData(result.data);
        const stateIndex = states.indexOf(result.data.locationState.name)
        setValue(states[stateIndex])

      } catch (err) {
        console.log(err);
        setIsError(true);
      }
    }
  }

  useEffect(() => {
    fetchData(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (compAddressData) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    let isContinue = true;
    const selectedState = stateObj.filter(x => x.name === value);
    compAddressData.GSTIN = GSTIN;
    compAddressData.id = id;
    compAddressData.isPrimary = isPrimaryData;
    compAddressData.state = selectedState && selectedState[0] ? selectedState[0].code : '';
    compAddressData.addressType = addrType;
    compAddressData.description = `${compAddressData.name} - ${addrType}`;
    compAddressData.locationTypeId = companyAddressData.locationTypeId;
    if (compAddressData.pincode && selectedState && selectedState[0]) {
      let testPinCode = parseInt(compAddressData.pincode.toString().slice(0, 3));
      if (testPinCode >= parseInt(selectedState[0].pinCodeSR) && testPinCode <= parseInt(selectedState[0].pinCodeER)) {
        setPincodeErr(false);
      } else {
        setPincodeErr(true);
        setPincodeErrMsg("Pincode not matching to state.");
        isContinue = false;
      }
    } else {
      isContinue = false;
      setPincodeErrMsg("Enter Valid Pincode.");
      setPincodeErr(true);
    }
    if (gstCategory && !(gstCategory === "Unregistered") && !(GSTIN && GSTIN.length === 15)) {
      setGSTNErr(true)
      isContinue = false;
      errorMsg = "Enter Valid GSTIN"
    }

    if (isContinue) {
      setGSTNErr(false);
      try {
        let newAddress;
        if (id) {
          await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/companyaddress/${id}`,
            compAddressData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          newAddress = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/companyaddress`,
            compAddressData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        if (props.isPopup) {
          props.success(newAddress.data.companyAddressData);
        } else {
          dispatch(fetchLocalities());
          setIsProcessing(false);
          if (props.job) {
            props.cancel();
            props.onCreate(newAddress && newAddress.data && newAddress.data.companyAddressData)
          }
          else {
            setCreate({
              success: true
            });
            setSaved(true);
          }
          // let addrData = newAddress && newAddress.data && newAddress.data.companyAddressData;
          setTimeout(()=>{
            window.location.href = `/companyconfigaddresslist`;
          }, 2000);
        }

      } catch (err) {
        console.log("err.response.data.message", err.response.data.message);
        errorMsg = (err.response.data.message && err.response.data.message.length > 0 && err.response.data.message[1] === "fullName must be unique") ? "Address Name has to be unique" : err.response.data.message;
        setIsError(true);
        setIsProcessing(false);
        setSaved(false);
      }
    } else {
      setIsProcessing(false);
    }
  };

  let cancelClick = () => {
    window.location.href = `/companyconfigaddresslist`;
  }
  console.log("addrType", addrType);
  return (
    <div>
      {!props.isPopup && <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/configrationhome")}>
                Configuration
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/companyconfigaddresslist")}>
                Address
              </Link>
              <Typography component="span" color="textPrimary">
                {id ? "Edit Address" : "New Address"}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="supplier-form">
          <Grid container spacing={2}>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Location Name *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    error={Boolean(errors.name)}
                    inputRef={register({
                      required: "Location Name is required",
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={name}
                    onChange={e => setcompanyAddressData({ ...companyAddressData, name: e.target.value })}
                    size="small"
                    name="name"
                  />
                  <FormHelperText error>
                    {errors.name && errors.name.message}
                  </FormHelperText>
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Location Type *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        setcompanyAddressData({ ...companyAddressData, locationTypeId: newValue.id })
                        setAddressType(newValue.description)
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setAddressType(newInputValue);
                    }}
                    fullWidth
                    disabled={(id) ? true : false}
                    value={locationTypeId}
                    inputValue={addrType}
                    options={locationTypesData}
                    getOptionLabel={(option) => (typeof option.description === 'string' ? option.description : '')}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      size="small"
                      name={`locationTypeId`}
                      error={Boolean(errors.locationTypeId)}
                      inputRef={register({
                        required: "Select Address Type"
                      })}
                    />}
                    filterOptions={(options, addrType) =>
                      options.filter((option) =>
                        typeof option.description === 'string' &&
                        option.description.toLowerCase().includes(addrType.inputValue.toLowerCase())
                      )
                    }
                  />
                  <FormHelperText error={Boolean(errors.locationTypeId)}>
                    {errors.locationTypeId && errors.locationTypeId.message}
                  </FormHelperText>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    inputRef={register()}
                    style={{ display: "none" }}
                    value={addressType}
                    size="small"
                    name="addressType"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Address 1 *</Typography>
                </Grid>
                <Grid item md={10} xs={10}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.address1)}
                    inputRef={register({
                      required: "Address line 1 is required",
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={address1}
                    onChange={e => setcompanyAddressData({ ...companyAddressData, address1: e.target.value })}
                    size="small"
                    name="address1"
                  />
                  <FormHelperText error>
                    {errors.address1 && errors.address1.message}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Address 2</Typography>
                </Grid>
                <Grid item md={10} xs={10}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.address2)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={address2}
                    onChange={e => setcompanyAddressData({ ...companyAddressData, address2: e.target.value })}
                    size="small"
                    name="address2"
                  />
                  <FormHelperText error>
                    {errors.address2 && errors.address2.message}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">City/Town *</Typography>
                </Grid>
                <Grid item md={10} xs={10}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.address3)}
                    inputRef={register({
                      required: "City/Town is required",
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={address3}
                    onChange={e => setcompanyAddressData({ ...companyAddressData, address3: e.target.value })}
                    size="small"
                    name="address3"
                  />
                  <FormHelperText error>
                    {errors.address3 && errors.address3.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Pincode *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors.pincode) || isPincodeErr}
                    type="number"
                    inputRef={register({
                      required: "6 digit pincode is required",
                      pattern: {
                        value: /^[0-9]{6}$/,
                        message: "6 digit pincode is required",
                      },
                    })} value={pincode}
                    onChange={e => setcompanyAddressData({ ...companyAddressData, pincode: e.target.value })}
                    size="small"
                    name="pincode"
                  />
                  <FormHelperText error={Boolean(errors.pincode) || isPincodeErr}>
                    {(isPincodeErr) ? pincodeErrMsg : errors.pincode && errors.pincode.message}
                  </FormHelperText>
                </Grid>

                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Locality</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField variant="outlined" value={locality}
                    size="small" name="locality"
                    onChange={e => setcompanyAddressData({ ...companyAddressData, locality: e.target.value })}
                    error={Boolean(errors.locality)}
                    fullWidth inputRef={register({
                      // pattern: {
                      //   value: /^[a-zA-Z0-9 ]{3,}$/,
                      //   message: "Minimum of 3 characters required",
                      // },
                    })} />
                  <FormHelperText error>
                    {errors.locality && errors.locality.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Mobile Number *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField variant="outlined" fullWidth error={Boolean(errors.mobileNumber)}
                    onChange={e => setcompanyAddressData({ ...companyAddressData, mobileNumber: e.target.value })}
                    type="number" inputRef={register({
                      required: "Enter valid 10 digit mobile number",
                      pattern: {
                        value: /^[6789]{1}[0-9]{9}$/,
                        message: "Enter valid 10 digit mobile number",
                      },
                    })} value={mobileNumber} size="small" name="mobileNumber" />
                  <FormHelperText error>
                    {errors.mobileNumber && errors.mobileNumber.message}
                  </FormHelperText>
                </Grid>

                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">State *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    // fullWidth
                    options={states}
                    // style={{ width: 215 }}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      name="st"
                      fullWidth
                      size="small"
                      error={Boolean(errors.st)}
                      inputRef={register({
                        required: "Required"
                      })} />}
                  />
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">GST Category *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        setcompanyAddressData({ ...companyAddressData, gstCategory: newValue.label })
                        setGSTType(newValue.label)
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setGSTType(newInputValue)
                    }}
                    inputValue={GSTType}
                    fullWidth
                    value={gstCategory}
                    options={gstCategories}
                    getOptionLabel={(option) => (typeof option.label === 'string' ? option.label : '')}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      size="small"
                      name={`gstCategory`}
                      error={Boolean(errors.gstCategory)}
                      inputRef={register({
                        required: "Select GST Category"
                      })}
                    />}
                    filterOptions={(options, gstinType) =>
                      options.filter((option) =>
                        typeof option.label === 'string' &&
                        option.label.toLowerCase().includes(gstinType.inputValue.toLowerCase())
                      )
                    }
                  />
                  <FormHelperText error={Boolean(errors.gstCategory)}>
                    {errors.gstCategory && errors.gstCategory.message}
                  </FormHelperText>
                </Grid>

                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">GSTIN</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField value={GSTIN}
                    variant="outlined" fullWidth
                    onChange={e => setcompanyAddressData({ ...companyAddressData, GSTIN: e.target.value })}
                    size="small" name="gstin"
                    error={Boolean(errors.gstin) || isGSTNErr}
                    // inputRef={register({
                    //   required: "GSTIN Required",
                    //   pattern: {
                    //     value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    //     message: "Enter Valid GSTIN",
                    //   },
                    // })}
                    inputRef={register({
                      pattern: {
                        value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                        message: "Enter Valid GSTIN",
                      },
                    })}
                  />
                  <FormHelperText error={Boolean(errors.gstin) || isGSTNErr}>
                    {(isGSTNErr) ? errorMsg : errors.gstin && errors.gstin.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">PAN Number</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField value={panNumber}
                    variant="outlined" fullWidth
                    onChange={e => setcompanyAddressData({ ...companyAddressData, panNumber: e.target.value })}
                    size="small" name="panNumber"
                    inputRef={register({
                    })}
                  />
                  <FormHelperText error={Boolean(errors.panNumber)}>
                    {errors.panNumber && errors.panNumber.message}
                  </FormHelperText>
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Default</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <FormControlLabel
                    fullWidth
                    variant="outlined"
                    size="small"
                    // inputRef={register({
                    // })}
                    value={isPrimaryData}
                    checked={isPrimaryData}
                    error={errors.isPrimary}
                    control={<Controller
                      as={
                        <Checkbox
                          color="primary"
                        />
                      }
                      name="isPrimary"
                      control={control}
                      onChange={(e) => {
                        console.log(e[1]);
                        if (e && e.length > 1) {
                          setIsPrimary(e[1]);
                        }
                      }}
                    />}
                    label=""
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid
            item
            container
            xs={6}
            justifyContent={isProcessing ? "flex-end" : "flex-start"}
          >
            {isProcessing ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}

            {isError ? (
              <Grid item>
                <Typography error>
                  <FormHelperText error>{errorMsg}</FormHelperText>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button size="small" type="submit"
                variant="contained" color="primary" disabled={saved}>
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => props.job ? props.cancel() : cancelClick()}
                color="secondary"
                size="small"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" name="isDirty" value="true" ref={register()} />
        <input type="hidden" name="id" ref={register()} />
      </form>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id ? "Address updated successfully"
            : "Address created successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isItemRomve}
        autoHideDuration={3000}
        onClose={() => setIsRemoveItem(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setIsRemoveItem(false)}
          severity="success">
          Item Removed by Address List
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default CompanyAddressForm;
