import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import { locations } from '../../common/lookupSlice';
import axios from "axios";
import PrintIcon from '@material-ui/icons/Print';
import MailIcon from '@material-ui/icons/Mail';
import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import PurchaseForm from "./SupplierPurchaseForm";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";
import { useReactToPrint } from "react-to-print";
import { formatDate } from '../../common/util';
import { StyledHeaderCell } from "../../common/StyledHeaderCell";
import { currentUser } from "../../login/loginSlice";
import { bankDetails, termsAndConditions } from "../../common/lookupSlice"
// import "../customers.css";
import { useSelector } from "react-redux";
import CompToPrintEstimate from '../../purchase/CompToPrintSupplierPurchaseOrder';
import _ from "lodash";

const PurchaseOrder = (props) => {
  const pageSize = 10;
  const { id } = useParams();
  const location = useLocation();
  const customerTypeId = location.state;
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const orgBankDetails = useSelector(bankDetails);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  const orgName = loggedInUser && loggedInUser.org && loggedInUser.org.name;
  const isAdminOrAccountant = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role => (role.name === "ADMIN") ||  (role.name === "ACCOUNTANT"));
  const inventoryLocations = useSelector(locations);
  const [costCenterList, setCostCenterList] = useState([]);
  const corporateLocation = inventoryLocations.filter(loc => loc.type && loc.type.code === "CORPORATE");
  const corporateLocationId = corporateLocation.length > 0 ? corporateLocation[0].id : 1;
  const [count, setCount] = useState();
  const [spoForm, setSPOForm] = useState({ open: false });
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [name, setName] = useState();
  const [existSupData, setExistSupData] = useState();
  const [no, setNo] = useState();
  const componentRefEstimate = useRef();
  const [quote, setQuote] = useState({});
  const token = localStorage.getItem("authToken");
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSendInvoice, setSendInvoice] = useState(false);
  const [popHeader, setPopHeader] = useState();
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [emailSendData, setEmailSendData] = useState({
    emailId: '',
    emailSubject: ''
  });
  let { emailId, emailSubject } = emailSendData;

  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isQuotation = (chars[1]) ? chars[1].toUpperCase() === "QUOTATION": false;
  let isPO = (chars[1]) ? chars[1].toUpperCase() === "PURCHASEORDER": false;

  const onClickPo = async (poNum) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseorder/${poNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      let modifiedContacts = result.data.purchaseOrders.lineItems.map((obj) => {
        obj.contactId = obj.id;
        obj.negotiatedPrice = obj.negotiatedPrice && (Math.round(obj.negotiatedPrice * 100) / 100).toFixed(2)
        obj.discountPct = obj.discountPct && (Math.round(obj.discountPct * 100) / 100).toFixed(2)
        obj.productCode = obj.product && obj.product.productName
        delete obj["id"];
        return obj;
      });
      result.data.purchaseOrders.lineItems = modifiedContacts;
      setSPOForm({
        ...spoForm,
        data: result.data.purchaseOrders,
        mode: "U",
        open: true,
      });
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
  };

  
  const defaultLoadData = async (COMPANY_NAME_IN_ERPNEXT) => {
    try {
      let queryCostCenter = {
        fields: ["name", "owner"],
        filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT, disabled: 0, is_group: 0 })
      }
      const resultCostCenter = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Cost Center`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: queryCostCenter,
        }
      );
      setCostCenterList(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data);
    }
    catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    defaultLoadData(COMPANY_NAME_IN_ERPNEXT);
  }, [COMPANY_NAME_IN_ERPNEXT]); // eslint-disable-line react-hooks/exhaustive-deps


  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/suppliers/${id}/orders?typeCode=${(isQuotation)?"QUOTATION":(isPO)?"ORDER":""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setExistSupData(result.data.supplier);
      setName(result.data.supplier.fullName);
      setNo(result.data.supplier.mobileNumber);
      setCount(result.data.count);
      setData(result.data.orders);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [spoForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps


  const handlePrint = useReactToPrint({
    content: () => componentRefEstimate.current,
  });


  const setPoNumAction = async (id) => {
    try {
      let ser = [];
      const getData = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseorder/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      ser = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.lineItems && getData.data.purchaseOrders.lineItems.length > 0) ? getData.data.purchaseOrders.lineItems : [];
      let supplierData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.supplier) ? getData.data.purchaseOrders.supplier : {};
      let orgData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.org) ? getData.data.purchaseOrders.org : {};
      let orderType = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.type) ? getData.data.purchaseOrders.type : {};
      const lineItemsReq = ser && ser.map(x => {
        const lineItem = {
          productId: x.product && x.product.id && Number(x.product.id),
          qty: x.fulfillingQty && Number(x.fulfillingQty),
          overridenUnitPrice: x.negotiatedPrice && Number(x.negotiatedPrice),
          overridenDiscountPcnt: x.discountPct && Number(x.discountPct),
        }
        return lineItem;
      });
      const reqObj = {
        supplierId: supplierData.id,
        isSupplierPo:true,
        locationId: corporateLocationId,
        lineItems: lineItemsReq
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/pos/spo/calculate`,
        reqObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let calculate = (result && result.data) ? result.data : null
      const lineItemData = calculate && calculate.lineItems ? calculate.lineItems.map((item, key) => {
        return {
          "lineNo": key + 1,
          "productName": item.productName,
          "HSNCode": item.HSNCode,
          "IGST": item.IGST,
          "CGST": item.CGST,
          "SGST": item.SGST,
          "uom": item.uom,
          "qty": item.qty,
          "unitPrice": item.unitPrice,
          "salePrice": item.salePrice,
          "discountPct": item.discountPct,
          "amount": item.amount,
          "isSpecial": item.isSpecial
        }
      }) : []

      let estimateData = {
        "OrderNo": id,
        "invoiceNum":id,
        "type":orderType,
        "invoiceDate": (getData && getData.data && getData.data.purchaseOrders.orderDate)?getData.data.purchaseOrders.orderDate:new Date(),
        "subtotal": calculate.subtotal,
        "CGST": calculate.CGST,
        "SGST": calculate.SGST,
        "IGST": calculate.IGST,
        "total": calculate.total,
        "roundOff": calculate.total ? (Math.round((Math.round(calculate.total) - calculate.total) * 100) / 100).toFixed(2) : 0,
        "org": orgData,
        "lineItems": lineItemData,
        "customer": supplierData,
        "amountInWords": calculate.amountInWords,
        "isSupplier": true,
        "supplier": (getData && getData.data && getData.data.purchaseOrders.supplier)?getData.data.purchaseOrders.supplier:null,
        "location":(getData && getData.data && getData.data.purchaseOrders.location)?getData.data.purchaseOrders.location:null,
        "shipToLocation":(getData && getData.data && getData.data.purchaseOrders.shipToOrderLoc)?getData.data.purchaseOrders.shipToOrderLoc:null,
      }
      // return estimateData;
      setQuote({ ...quote, ...estimateData });
      handlePrint();
    }
    catch (err) {
      console.log(err);
    }
  }



  const openEmailInovice = async (poNum) => {
    try {
      let ser = [];
      const getData = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseorder/${poNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      ser = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.lineItems && getData.data.purchaseOrders.lineItems.length > 0) ? getData.data.purchaseOrders.lineItems : [];
      let supplierData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.supplier) ? getData.data.purchaseOrders.supplier : {};
      let orderType = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.type) ? getData.data.purchaseOrders.type : {};
      let orgData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.org) ? getData.data.purchaseOrders.org : {};
      const lineItemsReq = ser && ser.map(x => {
        const lineItem = {
          productId: x.product && x.product.id && Number(x.product.id),
          qty: x.fulfillingQty && Number(x.fulfillingQty),
          overridenUnitPrice: x.negotiatedPrice && Number(x.negotiatedPrice),
          overridenDiscountPcnt: x.discountPct && Number(x.discountPct),
        }
        return lineItem;
      });
      const reqObj = {
        supplierId: supplierData.id,
        isSupplierPo:true,
        locationId: corporateLocationId,
        lineItems: lineItemsReq
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/pos/spo/calculate`,
        reqObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let calculate = (result && result.data) ? result.data : null
      const lineItemData = calculate && calculate.lineItems ? calculate.lineItems.map((item, key) => {
        return {
          "lineNo": key + 1,
          "productName": item.productName,
          "HSNCode": item.HSNCode,
          "IGST": item.IGST,
          "CGST": item.CGST,
          "SGST": item.SGST,
          "uom": item.uom,
          "qty": item.qty,
          "unitPrice": item.unitPrice,
          "salePrice": item.salePrice,
          "discountPct": item.discountPct,
          "amount": item.amount,
          "isSpecial": item.isSpecial
        }
      }) : []

      let estimateData = {
        "OrderNo": poNum,
        "invoiceNum":poNum,
        "type":orderType,
        "invoiceDate":  (getData && getData.data && getData.data.purchaseOrders.orderDate)?getData.data.purchaseOrders.orderDate:new Date(),
        "subtotal": calculate.subtotal,
        "CGST": calculate.CGST,
        "SGST": calculate.SGST,
        "IGST": calculate.IGST,
        "total": calculate.total,
        "roundOff": calculate.total ? (Math.round((Math.round(calculate.total) - calculate.total) * 100) / 100).toFixed(2) : 0,
        "org": orgData,
        "lineItems": lineItemData,
        "customer": supplierData,
        "amountInWords": calculate.amountInWords,
        "isSupplier": true
      }
      // return estimateData;
      setQuote({ ...quote, ...estimateData });
      setSendInvoice(true);
      setPopHeader( orderType && orderType.code === "ORDER" ? "Mail Supplier Purchase Order" : "Mail "+orderType.description);
      setEmailSendData({
        emailSubject: orderType && orderType.code === "ORDER" ? `${orgName} - Supplier Purchase Order` : `${orgName} - `+orderType.description,
        emailId: supplierData && supplierData.email
      })
    }
    catch (err) {
      console.log(err);
    }
  }

  const onCloseSendInvoice = () => {
    setSendInvoice(false);
    setEmailSendData({ emailId: '', emailSubject: '' })
  }
  const onSendEmailInvoice = async () => {
    let isEmail = false;
    let isSubject = (emailSubject && emailSubject.length > 1) ? true : false;
    if (emailId && emailId.length > 0) {
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds, (data) => {
        if (!mailformat.test(String(data).toLowerCase())) {
          isEmail = false;
        }
      })
    }
    if (isEmail && isSubject) {
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData = () => {
        return (
          <CompToPrintEstimate ref={componentRefEstimate} supplierPurInv={quote} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions}/>
        )
      }
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to', emailId)
      bodyFormData.append('subject', emailSubject)
      bodyFormData.append('invoice', renderToString(invoiceBodyData()))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
        bodyFormData,
        config
      );
      if (result.status === 200) {
        setIsSuccess(true)
        setSendInvoice(false);
        setEmailSendData({ emailId: '' })
      }
    } else {
      if (!isEmail) {
        setEmailHelperText(true);
      }
      if (!isSubject) {
        setEmailSubjectHelperText(true);
      }
    }
  }
  return (
    <div>
      {props.modal ? null : (
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/purchasehome")}>
                  Purchase
                </Link>
                <Link color="inherit" href="" onClick={() => history.push(
                  (isPO)?"/suppliers?from=purchaseorder"
                  :(isQuotation)?"/suppliers?from=quotation"
                  :"/suppliers/")}>
                  {(isPO)?"Supplier Purchase Order"
                  :(isQuotation)?"Quotation"
                  :"Supplier"}
                </Link>
                <Typography component="span" color="textPrimary">
                  {(isQuotation)?"Supplier Quotation"
                  :"Supplier Order"}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1} style={{ marginTop: '2%' }}>
            <Grid item xs={12} md={7}>
              <Typography>
              Supplier Number : {no} | Supplier Name: {name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} >
              {isAdminOrAccountant  && <Button color="primary" variant="contained" style={{ float: 'right', marginRight: "15px" }}
                onClick={() => {
                  setSPOForm({ ...spoForm, data: {}, mode: "C", open: true });
                }}> Add New {(isQuotation) ? "Quotation" : "Order"} </Button>}

            </Grid>
          </Grid>
        </Grid>
      )}
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Supplier Purchase Order</StyledHeaderCell>
                <StyledHeaderCell align="left">Order Required Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Order Required Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Status</StyledHeaderCell>
                <StyledHeaderCell align="center">Print</StyledHeaderCell>
                <StyledHeaderCell align="center">Email</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => {
                    return (
                      <TableRow
                        style={{ cursor: props.modal ? "pointer" : "" }}
                        key={row.id}
                        hover={true}
                        onClick={(e) =>
                          props.onRowSelect && props.onRowSelect(row)
                        }>
                        <TableCell align="left" className="underLine" style={{cursor: "pointer" }}>
                          <Link onClick={() => {
                            onClickPo(row.spoNum);
                          }}>{row.spoNum}
                          </Link></TableCell>

                        <TableCell align="left">{row.orderRequiredDate ? formatDate(row.orderRequiredDate) : ''}</TableCell>
                        <TableCell align="left">{row.orderReceivingDate ? formatDate(row.orderReceivingDate) : ''}</TableCell>
                        <TableCell align="left">{row.status.description}</TableCell>
                        <TableCell align="center">
                          <span onClick={() => { setPoNumAction(row.spoNum) }}>
                            <PrintIcon />
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span onClick={() => openEmailInovice(row.spoNum)}>
                            <MailIcon />
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Box display="none">
        <CompToPrintEstimate ref={componentRefEstimate} supplierPurInv={quote} isQuotation={isQuotation} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions}/>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={spoForm.open}
        onClose={() => {
          setSPOForm({ ...spoForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={10} alignItems="center">
              <Grid item>
                {spoForm.mode === "C"
                  ? (isQuotation)?"Add Quotation":"Add Supplier Purchase Order" 
                  : isAdminOrAccountant  ? (isQuotation)?"Edit Quotation":"Edit Supplier Purchase Order" 
                  :  (isQuotation)?"Quotation Details":"Supplier Purchase Order Details"}
              </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    setSPOForm({ ...spoForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <PurchaseForm
            form={spoForm}
            isQuotation={isQuotation}
            isPO={isPO}
            cancel={() => {
              setSPOForm({ ...spoForm, open: false });
            }}
            success={() => {
              setSPOForm({ ...spoForm, open: false, success: true });
            }}
            readOnly={!isAdminOrAccountant }
            customerTypeId={customerTypeId}
            existSupData={existSupData}
            costCenterList={costCenterList}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
              {popHeader ? popHeader : "Email Supplier Purchase Order"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '180px' }}>
          <Grid container>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span" >Mail to *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                value={emailId}
                onChange={e => setEmailSendData({ ...emailSendData, emailId: e.target.value })}
              />
              {isEmailHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter valid email id
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: '10px' }}>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span">Subject *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                value={emailSubject}
                onChange={e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value })}
              />
              {isEmailSubjectHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter email subject
              </FormHelperText>}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              onClick={onSendEmailInvoice}
              color="secondary"
              size="small"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={spoForm.success}
        onClose={() => setSPOForm({ ...spoForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setSPOForm({ ...spoForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {spoForm.mode === "C"
            ? "Supplier Purchase Order created successfully"
            : "Supplier Purchase Order updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
        autoHideDuration={3000}
      >
        <MuiAlert
          onClose={() => {
            setIsSuccess(false);
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          Mail Sent successfully
        </MuiAlert>
      </Snackbar>

      <Grid item>

      </Grid>
    </div>
  );
};

export default PurchaseOrder;
