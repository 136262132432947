import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomerForm from "./CustomerDiscountForm";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";
import { formatDate } from '../../common/util';
import { StyledHeaderCell } from "../../common/StyledHeaderCell";
import { API_ALL_CUSTOMERS, API_CUSTOMER_DISCOUNT } from "../../../config/constants";
import { currentUser } from "../../login/loginSlice";
import "../customers.css";

const CustomerDiscount = (props) => {
  const pageSize = 10;
  const { id } = useParams();
  const history = useHistory();

  const [count, setCount] = useState();
  const [custForm, setCustForm] = useState({ open: false });  
  const [deletedDiscount, setDeletedDiscount] = useState({ open: false });
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ name, setName ] = useState();
  const [ no, setNo] = useState();

  const token = localStorage.getItem("authToken");

  const editCustomer = async (discount_id) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/customers/${id}/${API_CUSTOMER_DISCOUNT}/${discount_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setCustForm({
        ...custForm,
        data: result.data,
        mode: "U",
        open: true,
      });
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
  };

  const deleteDiscount = async (discount_id) => {
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_SERVICE_URL}/customers/${id}/${API_CUSTOMER_DISCOUNT}/${discount_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setDeletedDiscount({
        ...deletedDiscount,
        success: true,
        message: result.data
      })
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_CUSTOMERS}/${id}/${API_CUSTOMER_DISCOUNT}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setName(result.data.fullName);
      setNo(result.data.mobileNumber);
      setCount(result.data.discounts.count);
      setData(result.data.discounts);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (custForm.success || deletedDiscount.success) {
      loadData();
    }
  }, [custForm.success || deletedDiscount.success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const loggedInUser = useSelector(currentUser);
  const isPricingAdmin = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role=>role.name === "PRICING ADMIN");
  return (
    <div>
      {props.modal ? null : (
        <Grid container>
          <Grid item container xs={12} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/customerForm/" + id)}>
                  Customers
                </Link>
                <Typography component="span" color="textPrimary">
                  Customer Discounts
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1} style={{marginTop: '2%'}}>
          <Grid item xs={12} lg={9}>
              <Typography>
              Customer Number : {no} | Customer Name: {name}
              </Typography>
            </Grid>
          <Grid item xs={12} lg={3} >
            {isPricingAdmin &&
              <Button color="primary" variant="contained"   disabled = {!isPricingAdmin} style={{float: 'right'}} onClick={() => {
                  setCustForm({ ...custForm, data: {}, mode: "C", open: true });
                }}> Add New </Button>}
            </Grid>
          </Grid>
        </Grid>
      )}
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Sale Value</StyledHeaderCell>
                <StyledHeaderCell align="left">Discount %</StyledHeaderCell>
                <StyledHeaderCell align="left">Start Date</StyledHeaderCell>
                <StyledHeaderCell align="left">End Date</StyledHeaderCell>
                {isPricingAdmin && <StyledHeaderCell align="left">Action</StyledHeaderCell>}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => (
                    <TableRow
                      style={{ cursor: props.modal ? "pointer" : "" }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }
                    >
                      <TableCell align="left" >{row.saleFromRange}-{row.saleToRange}</TableCell>
                      <TableCell align="center" style={{paddingRight:'60px'}}>{row.discount && (Math.round(row.discount * 100) / 100).toFixed(2)}</TableCell>
                      <TableCell align="left">{row.startDate ? formatDate(row.startDate):''}</TableCell>
                      <TableCell align="left">{row.endDate ? formatDate(row.endDate):''}</TableCell>
                      {isPricingAdmin &&<TableCell align="left" style={{paddingLeft: '0px'}}>
                          <IconButton 
                            onClick={() => {
                              editCustomer(row.id);
                            }}><EditIcon color="secondary" /></IconButton>
                          <IconButton
                            onClick={(e) => {
                              deleteDiscount(row.id);
                            }}><DeleteIcon color="secondary" /></IconButton>
                        </TableCell>}
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={custForm.open}
        onClose={() => {
          setCustForm({ ...custForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={10} alignItems="center">
              <Grid item>
                {custForm.mode === "C"
                  ? "Add Customer Discount"
                  : "Edit Customer Discount"}
              </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    setCustForm({ ...custForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <CustomerForm
            form={custForm}
            cancel={() => {
              setCustForm({ ...custForm, open: false });
            }}
            success={() => {
              setCustForm({ ...custForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={custForm.success || deletedDiscount.success}
        onClose={() => {
          setCustForm({ ...custForm, success: false });
          setDeletedDiscount({ ...deletedDiscount, success: false });}}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => {
            setCustForm({ ...custForm, success: false });
            setDeletedDiscount({ ...deletedDiscount, success: false });}}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {deletedDiscount.success ? "Discount deleted successfully": custForm.mode === "C"
            ? "Discount created successfully"
            : "Discount updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CustomerDiscount;