import React, { useState, useRef, useEffect } from "react";

import axios from "axios";

import {
  IconButton,
  Typography,
  TextField,
  Snackbar,
  FormControl,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import ProductForm from "../products/ProductForm";

import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";

import { API_ALL_PRODUCTS } from "../../config/constants";

import "../products/products.css";
const Products = (props) => {
  const pageSize = 10;
  const [count, setCount] = useState();
  const [prodForm, setProdForm] = useState({ open: false });
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize, custTypeId:1 , locationId: props.store});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [bucket,setBucket] =useState('')
  const itemNameRef = useRef();
  const token = localStorage.getItem("authToken");
  const gcpUrl = `${process.env.REACT_APP_GCP_URL}${bucket}/`;
  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/pos/${API_ALL_PRODUCTS}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.products);
      setBucket(result.data.bucketName)
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({
      ...query,
      locationId: props.store,   
      page: 1,
    });
  }, [props.store]); // eslint-disable-line react-hooks/exhaustive-deps

  
  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      productName: e.target.elements.itemName.value,   
      page: 1,
    });
  };

  const handleProductClick = (product) =>{
    if(props.setProduct && props.setChange){
      props.setProduct(product)
      props.setChange(!(props.change))
    }
  }

  return (
    <div>
      <div className="user-filter-grids">
        <Typography variant="h6">
          {props.modal ? "Product Selection" : "Product Search"}
        </Typography>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={4} xs={4}>
              <Grid item md={12} xs={12}>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={itemNameRef}
                    InputProps={{
                      name: "itemName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            
            <Grid item container alignItems="center" md={4} xs={6}>
              <Grid item>
              <Button
          variant="contained"
          color="primary"
          size="small"
          autoFocus
          type="submit"
        >
         Search
        </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }} >
        <TableContainer component={Paper} style={{overflowX: 'hidden'}}>
          <Table style={{ paddingTop: 10 }} size="small">
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={props.modal ? 6 : 7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 6 : 7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <Grid container style={{margin: 10}}>
                        <Grid item alignItems="center" xs={2} md={2}>
                          <Box display="flex" justifyContent="center">
                            {row.product && row.product.images ?
                            <img src={row.product.images[0] && `${gcpUrl}${row.product.images[0].image}`} alt="prdimage" width="50px" height="50px"/>:
                            <Typography>Not Available</Typography>}
                          </Box>
                        </Grid>
                        <Grid item xs={8} md={8} onClick={()=> props.po ? '':handleProductClick(row)} style={{backgroundColor: '#EDF0EC',cursor:props.po ? '':"pointer"}}>
                          <Typography>{row.product && row.product.productName}</Typography>
                          <Typography>INR : {row.product && row.product.price}</Typography>
                          <Typography>Available Qty : {row.availableQty} | Location : {row.location && row.location.name}</Typography>
                          <Typography>Sub-Location : {row.subLocation && row.subLocation}</Typography>
                        </Grid>
                    </Grid>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={prodForm.open}
        onClose={() => {
          setProdForm({ ...prodForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {prodForm.mode === "C"
                  ? "Add Product"
                  : "Edit Product"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProdForm({ ...prodForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ProductForm
            form={prodForm}
            cancel={() => {
              setProdForm({ ...prodForm, open: false });
            }}
            success={() => {
              setProdForm({ ...prodForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={prodForm.success}
        onClose={() => setProdForm({ ...prodForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setProdForm({ ...prodForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {prodForm.mode === "C"
            ? "Product created successfully"
            : "Product updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Products;
