import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {
  openModal,
  // closeModal,
  // modalInProcessing
} from "../common/confirmationDialogSlice";
import { currentUser } from "../login/loginSlice"
import { Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { localities } from "../common/lookupSlice";

const Customer = (props) => {
  // const availCustLocalities = useSelector(localities);
  // const custLocalities = availCustLocalities ? availCustLocalities.filter(loc => loc !== "") : [];
  const pageSize = 5;
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(currentUser);
  const orgCode = loggedInUser && loggedInUser.org && loggedInUser.org.code;
  // const location = useLocation();
  // const customerData = location.state && !(location.state.click);
  const [count, setCount] = useState();
  const [orgdata, setOrgData] = useState({});
  const [inputValue, setInputValue] = React.useState('');
  // const [deepMobileNumSearch, setDeepMobileNumSearch] = useState(false);
  const { control } = useForm({});
  const [data, setData] = useState([]);
  const [masterUOMdata, setMasterUOMData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [popupErrorMsg, setPopUpErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [uomData, setUOMData] = useState({ code: "", description: "", isDecimal: false, id: null, success: false });
  const [gstConfigData, setGSTConfigData] = useState({
    enableEInvoiceFeature: false,
    enableEWaybillFeature: false,
    companyTurnOver: 0,
    eWayBillThreshold: 0
  });
  const [helperTextMsg, setHelperTextMsg] = useState({ companyTurnOverHelperText: "", eWayBillThresholdHelperText: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [isNewUOM, setAddUOM] = useState({ mode: "", open: false, data: {} });
  const [isConfirmBox, setIsConfirmBox] = React.useState(false);
  // const idRef = useRef();
  // const bankNameRef = useRef();
  // const acHolderNameRef = useRef();
  // const branchNameRef = useRef();
  const token = localStorage.getItem("authToken");

  // var queryStringData = window.location.search;
  // var chars = queryStringData.split('=');
  // let isQuotation = (chars[1]) ? chars[1].toUpperCase() === "QUOTATION" : false;
  // let isPO = (chars[1]) ? chars[1].toUpperCase() === "PURCHASEORDER" : false;
  // const resetQuery = () => {
  //   idRef.current.value = "";
  //   bankNameRef.current.value = "";
  //   acHolderNameRef.current.value = "";
  //   branchNameRef.current.value = "";
  //   // setSearchLocality("");
  //   // setDeepMobileNumSearch(false);
  //   setQuery({
  //     ...query,
  //     id: idRef.current.value,
  //     bankName: bankNameRef.current.value,
  //     acHolderName: acHolderNameRef.current.value,
  //     branchName: branchNameRef.current.value,
  //   });
  // };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/orguom`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      const resOrg = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/org/${orgCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrgData(resOrg.data);
      await setGSTConfigData({
        ...gstConfigData,
        enableEInvoiceFeature: resOrg?.data?.isGenerateEInvoice,
        enableEWaybillFeature: resOrg?.data?.isGenerateEWayBill,
        companyTurnOver: (resOrg && resOrg.data && resOrg.data.companyTurnOver) ? resOrg.data.companyTurnOver : 0,
        eWayBillThreshold: (resOrg && resOrg.data && resOrg.data.eWayBillThresholdLimit) ? resOrg.data.eWayBillThresholdLimit : 0,
      });

      setCount(result.data.count);
      setData(result.data.uom);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  const loadMasterData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/uom`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setMasterUOMData(result.data.uom);
    } catch (err) {
      setMasterUOMData([]);
    }
  };

  useEffect(() => {
    loadMasterData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps


  // On success of add or edit, reset the query to load data agai

  const onSubmit = async () => {
    if (uomData.code && uomData.description) {
      setPopUpErrorMessage("");
      setIsProcessing(true)
      var resUom;
      try {
        if ((isNewUOM.mode === "Edit") && uomData.id) {
          resUom = await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/orguom/${uomData.id}`,
            uomData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else if (isNewUOM.mode === "New") {
          resUom = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/orguom`,
            uomData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        if (resUom && resUom.data) {
          setIsProcessing(false)
          setUOMData({ code: "", description: "", isDecimal: false, success: true })
          setAddUOM({ ...isNewUOM, open: false, data: {} })
          loadData();
        } else {
          setIsProcessing(false)
          setIsError(true)
          setPopUpErrorMessage("Something went wrong")
        }
      } catch (err) {
        let errorMsg = err.response.data.message;
        setPopUpErrorMessage(errorMsg && errorMsg.length > 0 && errorMsg[0])
        setIsError(true)
        setIsProcessing(false)
      }
    } else {
      setIsProcessing(false)
      setPopUpErrorMessage("Enter UOM Code and Description")
    }
  }

  const onComapnyConfigSubmit = async () => {
    setPopUpErrorMessage("");
    setSuccessMsg("");
    setIsProcessing(true)
    try {
      let companyConfigData = {}
      if (gstConfigData.enableEInvoiceFeature) {
        companyConfigData.isGenerateEInvoice = true;
        companyConfigData.companyTurnOver = gstConfigData.companyTurnOver;
      }
      if (gstConfigData.enableEWaybillFeature) {
        companyConfigData.isGenerateEWayBill = true;
        companyConfigData.eWayBillThresholdLimit = gstConfigData.eWayBillThreshold;
      }

      var resComp = await axios.put(
        `${process.env.REACT_APP_SERVICE_URL}/roles/org/${orgCode}`,
        companyConfigData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (resComp) {
        setIsProcessing(false);
        setOrgData(resComp.data);
        await setGSTConfigData({
          ...gstConfigData,
          enableEInvoiceFeature: resComp?.data?.isGenerateEInvoice,
          enableEWaybillFeature: resComp?.data?.isGenerateEWayBill,
          companyTurnOver: (resComp && resComp.data && resComp.data.companyTurnOver) ? resComp.data.companyTurnOver : 0,
          eWayBillThreshold: (resComp && resComp.data && resComp.data.eWayBillThresholdLimit) ? resComp.data.eWayBillThresholdLimit : 0,
        });
        setSuccessMsg("GST Setup Configuration Updated.");
        setIsSuccess(true);
      } else {
        setIsProcessing(false)
      }
    } catch (err) {
      let errorMsg = err.response.data.message;
      setPopUpErrorMessage(errorMsg && errorMsg.length > 0 && errorMsg[0])
      setIsError(true)
      setIsProcessing(false)
    }
  }

  const cancelClick = () => {
    setPopUpErrorMessage("");
    setIsProcessing(false)
    setUOMData({ code: "", description: "", isDecimal: false })
    setAddUOM({ ...isNewUOM, open: false, data: {} })
  }



  const handleCodeChange = (value) => {
    setUOMData({ ...uomData, code: value })
  };
  const handleDescriptionChange = (event) => {
    setUOMData({ ...uomData, description: event.target.value })
  };

  const enableEInvoiceOnchange = (val) => {
    if (val) {
      setGSTConfigData({ ...gstConfigData, enableEInvoiceFeature: val });
      setIsConfirmBox(false)
    } else {
      setGSTConfigData({ ...gstConfigData, enableEInvoiceFeature: val });
      setIsConfirmBox(false)
    }
  };

  const sayOk = () => {
    setIsConfirmBox(false)
  }
  const confirmDialogBox = async (val) => {
    if (val) {
      const dialogText = "Once you enable the e-invoice feature, disable the option to turn it off.";
      setIsConfirmBox(true)
      dispatch(
        openModal({
          dialogText: dialogText,
          secondayBtnText: "Yes",
          primaryBtnText: "Cancel",
          handleCloseModal: enableEInvoiceOnchange,
        })
      );
    } else {
      if (orgdata.isGenerateEInvoice) {
        setIsConfirmBox(true)
        const dialogText = "Once you enable the e-invoice feature, you cannot turn it off.";
        dispatch(
          openModal({
            dialogText: dialogText,
            secondayBtnText: "Ok",
            primaryBtnText: "Cancel",
            handleCloseModal: sayOk,
          })
        );
      } else {
        setGSTConfigData({ ...gstConfigData, enableEInvoiceFeature: val });
        setIsConfirmBox(false)
      }

    }
  }



  const enableEWayBillOnchange = (val) => {
    if (val) {
      setGSTConfigData({ ...gstConfigData, enableEWaybillFeature: val });
      setIsConfirmBox(false)
    } else {
      setGSTConfigData({ ...gstConfigData, enableEWaybillFeature: val });
      setIsConfirmBox(false)
    }
  };
  const sayOkEWayBill = () => {
    setIsConfirmBox(false)
  }
  const confirmDialogBoxEWayBill = async (val) => {
    if (val) {
      const dialogText = "Once you enable the e-Way Bill feature, disable the option to turn it off.";
      setIsConfirmBox(true)
      dispatch(
        openModal({
          dialogText: dialogText,
          secondayBtnText: "Yes",
          primaryBtnText: "Cancel",
          handleCloseModal: enableEWayBillOnchange,
        })
      );
    } else {
      if (orgdata.isGenerateEWayBill) {
        setIsConfirmBox(true)
        const dialogText = "Once you enable the e-Way Bill feature, you cannot turn it off.";
        dispatch(
          openModal({
            dialogText: dialogText,
            secondayBtnText: "Ok",
            primaryBtnText: "Cancel",
            handleCloseModal: sayOkEWayBill,
          })
        );
      } else {
        setGSTConfigData({ ...gstConfigData, enableEWaybillFeature: val });
        setIsConfirmBox(false)
      }

    }
  }

  const handleCompanyTurnOverChange = (event) => {
    const value = event.target.value;
    setHelperTextMsg({ ...helperTextMsg, companyTurnOverHelperText: "" })
    setGSTConfigData({ ...gstConfigData, companyTurnOver: value })
    // if (parseFloat(value) >= 50000000) {
    //   setHelperTextMsg({ ...helperTextMsg, companyTurnOverHelperText: "" })
    //   setGSTConfigData({ ...gstConfigData, companyTurnOver: event.target.value })
    // } else {
    //   setHelperTextMsg({ ...helperTextMsg, companyTurnOverHelperText: "If e-Invoice feature Company TurnOver 5Cr+" })
    // }
  };
  const handleEWayBillThresholdChange = (event) => {
    const value = event.target.value;
    setHelperTextMsg({ ...helperTextMsg, eWayBillThresholdHelperText: "" })
    setGSTConfigData({ ...gstConfigData, eWayBillThreshold: value })
    // if (parseFloat(value) >= 50000) {
    //   setHelperTextMsg({ ...helperTextMsg, eWayBillThresholdHelperText: "" })
    //   setGSTConfigData({ ...gstConfigData, eWayBillThreshold: event.target.value })
    // } else {
    //   setHelperTextMsg({ ...helperTextMsg, eWayBillThresholdHelperText: "If e-Waybill feature Threshold limit 50000" })
    // }
  };

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/configrationhome")}>
                Configuration
              </Link>
              <Typography component="span" color="textPrimary">
                GST Setup
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >

        </Grid>
      </Grid>
      <Grid container style={{ margin: "20px 0px 30px 0px" }}>
        <Grid item container md={12} xs={12} spacing={2} className="div-border-margin">
          <Grid item container md={6} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography className="pop-up-form-labell" variant="caption">Company Annual Turnover for e-Invoice</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                id="uomCode"
                variant="outlined"
                type="number"
                fullWidth

                value={gstConfigData.companyTurnOver}
                onChange={handleCompanyTurnOverChange}
                size="small"
                // disabled={!gstConfigData.enableEInvoiceFeature}
                helperText={helperTextMsg.companyTurnOverHelperText}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                fullWidth
                variant="outlined"
                size="small"
                // inputRef={register({
                // })}
                value={gstConfigData.enableEInvoiceFeature}
                checked={gstConfigData.enableEInvoiceFeature}
                control={<Controller
                  as={
                    <Checkbox
                      color="primary"
                    />
                  }
                  name="enableEInvoiceFeature"
                  control={control}
                  onChange={(e) => {
                    if (e && e.length > 1) {
                      confirmDialogBox(e[1])
                    }
                  }}
                />}
                label="Enable e-Invoice Features"
              />
            </Grid>

          </Grid>
          <Grid item container md={6} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography className="pop-up-form-labell" variant="caption">Invoice Value Threshold for e-Waybill Generation</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                id="uomdescription"
                variant="outlined"
                fullWidth
                type="number"
                value={gstConfigData.eWayBillThreshold}
                onChange={handleEWayBillThresholdChange}
                size="small"
                // inputRef={register({})}
                // disabled={!gstConfigData.enableEWaybillFeature}
                helperText={helperTextMsg.eWayBillThresholdHelperText}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                fullWidth
                variant="outlined"
                size="small"
                // inputRef={register({
                // })}
                value={gstConfigData.enableEWaybillFeature}
                checked={gstConfigData.enableEWaybillFeature}
                control={<Controller
                  as={
                    <Checkbox
                      color="primary"
                    />
                  }
                  name="enableEWaybillFeature"
                  control={control}
                  onChange={(e) => {
                    if (e && e.length > 1) {
                      confirmDialogBoxEWayBill(e[1])
                    }
                  }}
                />}
                label="Enable e-Waybill Features"
              />
            </Grid>

          </Grid>
          <Grid container spacing={2} style={{ padding: 10 }}>
            <Grid
              item
              container
              xs={6}
              justifyContent={isProcessing ? "flex-end" : "flex-start"}
            >
              {isProcessing ? (
                <Grid item>
                  <CircularProgress />
                </Grid>
              ) : null}
            </Grid>
            <Grid
              item
              container
              xs={6}
              spacing={2}
              alignItems="flex-start"
              justifyContent="flex-end"
            >
              <Grid item>
                <Button size="small" onClick={() => {
                  onComapnyConfigSubmit()
                }}
                  variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      {isConfirmBox ?
        <ConfirmationDialog onClose={handleCodeChange} />
        : null}
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Typography variant={props.right ? "h7" : "h6"}>
            {props.modal ? "UOM Mapping Selection" : "UOM Mapping"}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => { setAddUOM({ ...isNewUOM, mode: "New", open: true, data: {} }); }}
            >
              Add UOM
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 20 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>UOM Id</StyledHeaderCell>
                <StyledHeaderCell align="left">UOM Code</StyledHeaderCell>
                <StyledHeaderCell align="left">Description</StyledHeaderCell>
                <StyledHeaderCell align="left">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={props.modal || props.right ? 5 : 6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => (
                    <TableRow
                      style={{
                        cursor: props.modal || props.right ? "pointer" : "",
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }
                    >
                      <TableCell component="th" scope="row">{row.id}</TableCell>
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="left">
                        <IconButton onClick={async () => {
                          await setUOMData({ code: row.code, isDecimal: row.isDecimal, description: row.description, id: row.id })
                          await setInputValue(row.code)
                          await setAddUOM({ ...isNewUOM, mode: "Edit", open: true, data: row })
                        }}>
                          <EditIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isNewUOM.open}
        onClose={cancelClick}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {(isNewUOM && isNewUOM.mode === "New") ? "Add UOM" : "Edit UOM"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={cancelClick}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={6} xs={6} spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography className="pop-up-form-labell" variant="caption">Code*</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  {(isNewUOM && isNewUOM.mode === "New") ?
                    <Autocomplete
                      onChange={async (event, newValue) => {
                        if (newValue) {
                          setInputValue(newValue.code);
                          setUOMData({ ...uomData, code: newValue.code, description: newValue.description })
                        } else {
                          setInputValue('');
                          setUOMData({ ...uomData, code: null })
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      fullWidth
                      value={uomData}
                      inputValue={inputValue}
                      options={masterUOMdata}
                      getOptionLabel={(option) => (typeof option.code === 'string' ? option.code : '')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                        />
                      )}
                      filterOptions={(options, state) =>
                        options.filter((option) =>
                          typeof option.code === 'string' &&
                          option.code.toLowerCase().includes(state.inputValue.toLowerCase())
                        )
                      }
                    />
                    :
                    <TextField
                      id="uomCode"
                      variant="outlined"
                      fullWidth
                      disabled
                      value={uomData.code}
                      size="small"
                    />}
                </Grid>
              </Grid>
              <Grid item container md={6} xs={6} spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography className="pop-up-form-labell" variant="caption">Description*</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    id="uomdescription"
                    variant="outlined"
                    fullWidth
                    value={uomData.description}
                    onChange={handleDescriptionChange}
                    onClick={(e) => { e.preventDefault() }}
                    size="small"
                  // inputRef={register({})}
                  />
                </Grid>
              </Grid>
              <Grid item container md={6} xs={6} spacing={1}>
                <FormControlLabel
                  fullWidth
                  variant="outlined"
                  size="small"
                  // inputRef={register({
                  // })}
                  value={uomData.isDecimal}
                  checked={uomData.isDecimal}
                  // error={errors.isPrimary}
                  control={<Controller
                    as={
                      <Checkbox
                        color="primary"
                      />
                    }
                    name="isDecimal"
                    control={control}
                    onChange={(e) => {
                      if (e && e.length > 1) {
                        setUOMData({ ...uomData, isDecimal: e[1] });
                      }
                    }}
                  />}
                  label="Is Decimal"
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "20px", color: "red" }}>
              <Grid item md={12} xs={12} >
                <Typography className="pop-up-form-labell" variant="caption" >{popupErrorMsg}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: 10 }}>
              <Grid
                item
                container
                xs={6}
                justifyContent={isProcessing ? "flex-end" : "flex-start"}
              >
                {isProcessing ? (
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                ) : null}
              </Grid>
              <Grid
                item
                container
                xs={6}
                spacing={2}
                alignItems="flex-start"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button size="small" onClick={() => { onSubmit() }}
                    variant="contained" color="primary">
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => cancelClick()}
                    color="secondary"
                    size="small"
                    type="reset"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {popupErrorMsg ? popupErrorMsg : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        autoHideDuration={5000}
        onClose={() => {
          setIsSuccess(false);
          setSuccessMsg("");
        }}
      >
        <MuiAlert
          onClose={() => {
            setIsSuccess(false);
            setSuccessMsg("");
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {successMsg ? successMsg : "Successfuly Updated"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={uomData.success}
        onClose={() => setUOMData({ ...uomData, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setUOMData({ ...uomData, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {isNewUOM.mode === "New"
            ? "UOM created successfully"
            : "UOM updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Customer;
