import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import counterReducer from "../features/counter/counterSlice";
import loginReducer from "../components/login/loginSlice";
import lookupReducer from "../components/common/lookupSlice";
import confirmationDialogReducer from "../components/common/confirmationDialogSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["login", "lookup"],
};

const reducers = {
  counter: counterReducer,
  login: loginReducer,
  confirmationDialog: confirmationDialogReducer,
  lookup: lookupReducer,
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

persistStore(store);

// const store = configureStore({
//   reducer: reducers,
// });

export default store;
