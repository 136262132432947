import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { renderToString } from 'react-dom/server'
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import PrintIcon from '@material-ui/icons/Print';
import MailIcon from '@material-ui/icons/Mail';
import { jsonToCsv, downloadCSVFile } from '../common/util';
import {
  Typography,
  Breadcrumbs,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Link,
  Select,
  MenuItem,
  Snackbar,
  FormControl,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import ReactToPrint from "react-to-print";
import {
  poStatus,
  poTypes
} from "../common/lookupSlice";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DateFnsUtils from "@date-io/date-fns";
import { formatDate } from '../common/util';
import { API_PURCHASE_ORDER } from "../../config/constants";
import PurchaseForm from "../customers/customerOrders/PurchaseForm";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import { currentUser } from "../login/loginSlice";
import { bankDetails, termsAndConditions, bankList, locations } from "../common/lookupSlice"
import { useReactToPrint } from "react-to-print";
import CompToPrintEstimate from '../jobs/CompOrderToPrintEstimate';
import CompToPrintCustomerSaleOrder from '../customers/CompToPrintCustomerSaleOrder';
import { Helmet } from "react-helmet";
// import { receiverEmailId } from "../../components/common/constants";

class CompToPrint extends React.Component {
  render() {
    return (
      <Table style={{ paddingTop: 10 }} size="small">
        <TableHead>
          <TableRow>
            <StyledHeaderCell align="left">Order No.</StyledHeaderCell>
            <StyledHeaderCell align="left">Customer Name</StyledHeaderCell>
            <StyledHeaderCell align="left">Order Type</StyledHeaderCell>
            <StyledHeaderCell align="left">Order Date</StyledHeaderCell>
            <StyledHeaderCell align="left">Status</StyledHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.customerOrder.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography variant="subtitle1">
                  No records found
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            this.props.customerOrder.map((row, index) => (
              <TableRow
                key={index}
                hover={true}
              >
                <TableCell component="th" scope="row">
                  {row.poNum}
                </TableCell>
                <TableCell align="left">
                  {row.name}
                </TableCell>
                <TableCell align="left">
                  {row.orderType}
                </TableCell>
                <TableCell align="left">
                  {row.orderDate && formatDate(row.orderDate)}
                </TableCell>
                <TableCell align="left">
                  {row.status}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    );
  }
}



const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};

class CompToPrintOrder extends React.Component {
  render() {
    return (
      <div>
        <label style={{ textAlign: "left" }}>
          Hello, Here is the list of Open Customer Purchase Orders. For more information, kindly login to the application.
        </label><br></br>
        <div style={divStyle} align="center">
          {/* Invoice  */}

          {/* <Helmet><title> Print Invoice</title></Helmet> */}
          <Helmet><title></title></Helmet>
          {/* <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', textAlign: 'center', fontWeight: 'bold', lineHeight: '2', }}>Order Invoice</span> */}
          <table style={{ paddingTop: 10 }} size="small">
            <thead>
              <tr>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Order No.</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Customer Name</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Order Type</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Order Date</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Status</span></td>
              </tr>
            </thead>
            <tbody>
              {this.props.customerOrder.length === 0 ? (
                <tr>
                  <td colspan="9" align="center">
                    <span style={pStyle2}>No records found</span>
                  </td>
                </tr>
              ) : (
                this.props.customerOrder.map((row, index) => (
                  <tr
                    id={index}
                  >
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.poNum}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.name}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.orderType}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.orderDate && formatDate(row.orderDate)}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.status}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}


const CustomerOrders = (props) => {
  const componentRef = useRef();
  const poStatusLookUp = useSelector(poStatus);
  const poTypesLookUp = useSelector(poTypes);
  const loggedInUser = useSelector(currentUser);
  const orgBankDetails = useSelector(bankDetails);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const orgBankList = useSelector(bankList);
  const orgName = loggedInUser && loggedInUser.org && loggedInUser.org.name;
  const isAdminOrAccountant = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role => (role.name === "ADMIN") ||  (role.name === "ACCOUNTANT"));
  const pageSize = 10;
  const allInventoryLocations = useSelector(locations);
  const  corporateLoc = allInventoryLocations.find((loc) => { return loc.type && loc.type.code === "CORPORATE"});
  let locId = corporateLoc && corporateLoc.id;
  const history = useHistory();
  const { handleSubmit, control, reset } = useForm({});
  const [custForm, setCustForm] = useState({ open: false });
  const [count, setCount] = useState();
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [orderDate, setOrderDate] = useState(null);
  const [customerOrder, setCustomerOrder] = useState([])
  const token = localStorage.getItem("authToken");
  const componentRefEstimate = useRef();
  const componentRefOrder = useRef();
  const [quote, setQuote] = useState({});

  const [isSuccess, setIsSuccess] = useState(false)
  const [isSendInvoice, setSendInvoice] = useState(false);
  const [isSendPO, setSendPO] = useState(false);
  const [popHeader, setPopHeader] = useState();
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [emailSendData, setEmailSendData] = useState({
    emailId: '',
    emailSubject: ''
  });
  let { emailId, emailSubject } = emailSendData;
  const [existCustData, setExistCustData] = useState();

  const editCustomer = async (poNum) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/${poNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      let exstCust;
      if (result.data && result.data.purchaseOrders && result.data.purchaseOrders.customer) {
        exstCust = JSON.parse(JSON.stringify(result.data.purchaseOrders.customer))
        exstCust.org = result.data.purchaseOrders.org;
      }
      setExistCustData(exstCust);
      let modifiedContacts = result.data.purchaseOrders.lineItems.map((obj) => {
        obj.contactId = obj.id;
        obj.negotiatedPrice = obj.negotiatedPrice && (Math.round(obj.negotiatedPrice * 100) / 100).toFixed(2)
        obj.discountPct = obj.discountPct && (Math.round(obj.discountPct * 100) / 100).toFixed(2)
        obj.productCode = obj.product && obj.product.productName
        delete obj["id"];
        return obj;
      });
      result.data.purchaseOrders.lineItems = modifiedContacts;
      setCustForm({
        ...custForm,
        data: result.data.purchaseOrders,
        mode: "U",
        open: true,
      });
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/reports/customerOrders`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.purchaseOrders);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    setQuery({
      ...query,
      typeId: e.typeId,
      orgBankId: e.orgBankId,
      statusId: e.statusId,
      orderDate: orderDate ? moment(orderDate).format('DD/MM/YYYY') : "",
      page: 1,
    });
  };

  const handleDownload = async () => {
    try {
      const customerOrderData = await getCustomerOrders();
      const data = jsonToCsv(customerOrderData)
      downloadCSVFile(data, "customerOrders")
    } catch (err) {
      setIsError(true);
    }
  }

  const getCustomerOrders = async () => {
    const clonedQuery = _.cloneDeep(query);
    delete clonedQuery.page;
    delete clonedQuery.pageSize;
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/reports/customerOrders`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: clonedQuery,
      }
    );
    const orders = result.data && result.data.purchaseOrders.map(order => {
      return {
        name: order.customer.fullName,
        poNum: order.poNum,
        orderType: order.type.code,
        orderDate: order.orderDate,
        status: order.status.code
      }
    });
    return orders;
  }


  const handlePrint = async () => {
    try {
      const data = await getCustomerOrders();
      setCustomerOrder(data);
    } catch (err) {
      setIsError(true);
    }
  }

  const handleDateChange = (value) => {
    setOrderDate(value)
  };

  const handleCustomerClick = (id) => {
    history.push(`/customerForm/${id}`)
  }

  // const handlePoClick = (name) =>{
  //   history.push(`/customerForm/${name}`)
  //  }

  const handleReset = (e) => {
    reset({
      typeId: "",
      statusId: "",
      orgBankId: ""
    })
    setOrderDate(null)
    setQuery({ page: 1, pageSize: pageSize })
  }


  const handleOrderPrint = useReactToPrint({
    content: () => componentRefOrder.current,
  });

  const handleEstimatePrint = useReactToPrint({
    content: () => componentRefEstimate.current,
  });


  const setPoNumAction = async (id, type) => {
    try {
      let ser = [];
      const getData = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      ser = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.lineItems && getData.data.purchaseOrders.lineItems.length > 0) ? getData.data.purchaseOrders.lineItems : [];
      let customerData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.customer) ? getData.data.purchaseOrders.customer : {};
      let orgData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.org) ? getData.data.purchaseOrders.org : {};
      let orderType = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.type) ? getData.data.purchaseOrders.type : {};
      const lineItemsReq = ser && ser.map(x => {
        const lineItem = {
          productId: x.product && x.product.id && Number(x.product.id),
          qty: x.orderQty && Number(x.orderQty),
          overridenUnitPrice: x.negotiatedPrice && Number(x.negotiatedPrice)
        }
        return lineItem;
      });
      const reqObj = {
        customerId: customerData.id,
        custTypeId: customerData.custTypeId,
        locationId: locId,
        lineItems: lineItemsReq
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
        reqObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let calculate = (result && result.data) ? result.data : null
      const lineItemData = calculate && calculate.lineItems ? calculate.lineItems.map((item, key) => {
        return {
          "lineNo": key + 1,
          "productName": item.productName,
          "HSNCode": item.HSNCode,
          "IGST": item.IGST,
          "CGST": item.CGST,
          "SGST": item.SGST,
          "uom": item.uom,
          "qty": item.qty,
          "unitPrice": item.unitPrice,
          "salePrice": item.salePrice,
          "discountPct": item.discountPct,
          "amount": item.amount,
          "isSpecial": item.isSpecial
        }
      }) : []

      let estimateData = {
        "OrderNo": id,
        "invoiceNum": id,
        "type": orderType,
        "invoiceDate": new Date(),
        "subtotal": calculate.subtotal,
        "CGST": calculate.CGST,
        "SGST": calculate.SGST,
        "IGST": calculate.IGST,
        "total": calculate.total,
        "roundOff": calculate.total ? (Math.round((Math.round(calculate.total) - calculate.total) * 100) / 100).toFixed(2) : 0,
        "org": orgData,
        "lineItems": lineItemData,
        "customer": customerData,
        "amountInWords": calculate.amountInWords,
        "location": (getData && getData.data && getData.data.purchaseOrders.location) ? getData.data.purchaseOrders.location : null,
        "companyBankdetails": (getData && getData.data && getData.data.purchaseOrders.companyBankdetails) ? getData.data.purchaseOrders.companyBankdetails : null,
      }
      // return estimateData;
      setQuote({ ...quote, ...estimateData });
      if (type === "ORDER") {
        handleOrderPrint();
      } else {
        handleEstimatePrint();
      }
    }
    catch (err) {
      console.log(err);
    }
  }



  const openEmailInovice = async (poNum) => {
    try {
      let ser = [];
      const getData = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/${poNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      ser = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.lineItems && getData.data.purchaseOrders.lineItems.length > 0) ? getData.data.purchaseOrders.lineItems : [];
      let customerData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.customer) ? getData.data.purchaseOrders.customer : {};
      let orderType = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.type) ? getData.data.purchaseOrders.type : {};
      let orgData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.org) ? getData.data.purchaseOrders.org : {};
      const lineItemsReq = ser && ser.map(x => {
        const lineItem = {
          productId: x.product && x.product.id && Number(x.product.id),
          qty: x.orderQty && Number(x.orderQty),
          overridenUnitPrice: x.negotiatedPrice && Number(x.negotiatedPrice)
        }
        return lineItem;
      });
      const reqObj = {
        customerId: customerData.id,
        custTypeId: customerData.custTypeId,
        locationId: locId,
        lineItems: lineItemsReq
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
        reqObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let calculate = (result && result.data) ? result.data : null
      const lineItemData = calculate && calculate.lineItems ? calculate.lineItems.map((item, key) => {
        return {
          "lineNo": key + 1,
          "productName": item.productName,
          "HSNCode": item.HSNCode,
          "IGST": item.IGST,
          "CGST": item.CGST,
          "SGST": item.SGST,
          "uom": item.uom,
          "qty": item.qty,
          "unitPrice": item.unitPrice,
          "salePrice": item.salePrice,
          "discountPct": item.discountPct,
          "amount": item.amount,
          "isSpecial": item.isSpecial
        }
      }) : []

      let estimateData = {
        "OrderNo": poNum,
        "invoiceNum": poNum,
        "type": orderType,
        "invoiceDate": new Date(),
        "subtotal": calculate.subtotal,
        "CGST": calculate.CGST,
        "SGST": calculate.SGST,
        "IGST": calculate.IGST,
        "total": calculate.total,
        "roundOff": calculate.total ? (Math.round((Math.round(calculate.total) - calculate.total) * 100) / 100).toFixed(2) : 0,
        "org": orgData,
        "lineItems": lineItemData,
        "customer": customerData,
        "amountInWords": calculate.amountInWords,
        "location": (getData && getData.data && getData.data.purchaseOrders.location) ? getData.data.purchaseOrders.location : null,
        "companyBankdetails": (getData && getData.data && getData.data.purchaseOrders.companyBankdetails) ? getData.data.purchaseOrders.companyBankdetails : null,
      }
      // return estimateData;
      setQuote({ ...quote, ...estimateData });
      setSendInvoice(true);
      setPopHeader(orderType && orderType.code === "ORDER" ? "Mail Customer Purchase Order" : "Mail " + orderType.description);
      setEmailSendData({
        emailSubject: orderType && orderType.code === "ORDER" ? `${orgName} - Customer Purchase Order` : `${orgName} - ` + orderType.description,
        emailId: customerData && customerData.email
      })
    }
    catch (err) {
      console.log(err);
    }
  }

  const onCloseSendInvoice = () => {
    setSendInvoice(false);
    setSendPO(false);
    setEmailSendData({ emailId: '', emailSubject: '' })
  }
  const onSendEmailInvoice = async () => {
    let isEmail = false;
    let isSubject = (emailSubject && emailSubject.length > 1) ? true : false;
    if (emailId && emailId.length > 0) {
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds, (data) => {
        if (!mailformat.test(String(data).toLowerCase())) {
          isEmail = false;
        }
      })
    }
    if (isEmail && isSubject) {
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData = "<div></div>"
      if (isSendPO) {
        invoiceBodyData = () => {
          return (
            <CompToPrintOrder ref={componentRef} customerOrder={customerOrder} />
          )
        }
      } else if (quote && quote.type && quote.type.code && quote.type.code === "ORDER") {
        invoiceBodyData = () => {
          return (
            <CompToPrintCustomerSaleOrder ref={componentRefOrder} customerInv={quote} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
          )
        }
      } else {
        invoiceBodyData = () => {
          return (
            <CompToPrintEstimate ref={componentRefEstimate} customerInv={quote} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
          )
        }
      }

      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to', emailId)
      bodyFormData.append('subject', emailSubject)
      bodyFormData.append('invoice', renderToString(invoiceBodyData()))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
        bodyFormData,
        config
      );
      if (result.status === 200) {
        setIsSuccess(true)
        setSendInvoice(false);
        setSendPO(false);
        setEmailSendData({ emailId: '' })
      }
    } else {
      if (!isEmail) {
        setEmailHelperText(true);
      }
      if (!isSubject) {
        setEmailSubjectHelperText(true);
      }
    }
  }


  const handleOpenOrderMail = async () => {
    try {
      const data = await getCustomerOrders();
      setCustomerOrder(data);
      setEmailSendData({ emailId: "", emailSubject: `${orgName} - Customer Purchase Order` })
      setSendPO(true)
    } catch (err) {
      setIsError(true);
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/reports")}>
                Reports
              </Link>
              <Typography component="span" color="textPrimary">
                Customer Open Orders
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <form onSubmit={handleSubmit(handleSearch)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Order Type</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth variant="outlined" size="small">
                  <Controller
                    as={
                      <Select>
                        {poTypesLookUp.map(function (type) {
                          return (
                            <MenuItem key={type.id} value={type.id}>
                              {type.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    } name="typeId" size="small" variant="outlined"
                    control={control} defaultValue="" />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Order Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    helperText=""
                    size="small"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    name="orderDate"
                    value={orderDate}
                    onChange={(value) => handleDateChange(value)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Status</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth variant="outlined" size="small">
                  <Controller
                    as={
                      <Select>
                        {poStatusLookUp.map(function (type) {
                          return (
                            <MenuItem key={type.id} value={type.id}>
                              {type.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    } name="statusId" size="small" variant="outlined"
                    control={control} defaultValue="" />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Bank Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth variant="outlined" size="small">
                  <Controller
                    as={
                      <Select>
                        {orgBankList.map(function (bank) {
                          return (
                            <MenuItem key={bank.id} value={bank.id}>
                              {bank.bankName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    } name="orgBankId" size="small" variant="outlined"
                    control={control} defaultValue="" />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container justify={"flex-end"} md={12} xs={12}>
              <Grid item>
                <Box mt={2} mr={4}>
                  <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Generate Report
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={2} mr={4}>
                  <Button
                    size="small"
                    onClick={handleReset}
                    variant="contained"
                    color="secondary"
                  >
                    clear
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={2} mr={4}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<MailIcon />}
                    onClick={handleOpenOrderMail}
                  >
                    Mail
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={2} mr={4}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Download
                  </Button></Box>
              </Grid>
              <Grid item>
                <Box mt={2}>
                  <ReactToPrint
                    onBeforeGetContent={handlePrint}
                    trigger={() => <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<PrintIcon />}

                    >
                      Print
                    </Button>}
                    content={() => componentRef.current}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left">Order No.</StyledHeaderCell>
                <StyledHeaderCell>Customer Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Order Type</StyledHeaderCell>
                <StyledHeaderCell align="left">Order Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Status</StyledHeaderCell>
                <StyledHeaderCell align="center">Print</StyledHeaderCell>
                <StyledHeaderCell align="center">Mail</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      style={{ cursor: props.modal ? "pointer" : "" }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }
                    >
                      <TableCell align="left" style={{ cursor: "pointer" }} className="underLine" >
                        <Link onClick={() => {
                          editCustomer(row.poNum);
                        }}>{row.poNum}
                        </Link>
                      </TableCell>
                      <TableCell component="th" scope="row" style={{ cursor: "pointer" }} className="underLine" onClick={() => handleCustomerClick(row.customer && row.customer.id)}>
                        {row.customer && row.customer.fullName}
                      </TableCell>
                      <TableCell align="left">
                        {row.type && row.type.description}
                      </TableCell>
                      <TableCell align="left">
                        {row.orderDate && formatDate(row.orderDate)}
                      </TableCell>
                      <TableCell align="left">
                        {row.status && row.status.description}
                      </TableCell>
                      <TableCell align="center">
                        <span onClick={() => { setPoNumAction(row.poNum, row.type && row.type.code) }}>
                          <PrintIcon />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span onClick={() => openEmailInovice(row.poNum)}>
                          <MailIcon />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box display="none">
          <CompToPrint ref={componentRef} customerOrder={customerOrder} />
        </Box>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}

      <Box display="none">
        <CompToPrintEstimate ref={componentRefEstimate} customerInv={quote} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
        <CompToPrintCustomerSaleOrder ref={componentRefOrder} customerInv={quote} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={custForm.open}
        onClose={() => {
          setCustForm({ ...custForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={10} alignItems="center">
              <Grid item>
                {custForm.mode === "C"
                  ? "Customer Purchase Order Details" : "Customer Purchase Order Details"}
              </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    setCustForm({ ...custForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <PurchaseForm
            form={custForm}
            existCustData={existCustData}
            cancel={() => {
              setCustForm({ ...custForm, open: false });
            }}
            success={() => {
              setCustForm({ ...custForm, open: false, success: true });
            }}
            readOnly={!isAdminOrAccountant }
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice || isSendPO}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {isSendPO ? "Mail Open Order" : popHeader ? popHeader : "Mail Invoice"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '180px' }}>
          <Grid container>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span" >Mail to *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                value={emailId}
                onChange={e => setEmailSendData({ ...emailSendData, emailId: e.target.value })}
              />
              {isEmailHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter valid email id
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: '10px' }}>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span">Subject *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                value={emailSubject}
                onChange={e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value })}
              />
              {isEmailSubjectHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter email subject
              </FormHelperText>}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              onClick={onSendEmailInvoice}
              color="secondary"
              size="small"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={custForm.success}
        onClose={() => setCustForm({ ...custForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCustForm({ ...custForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {custForm.mode === "C"
            ? "Customer Purchase Order created successfully"
            : "Customer Purchase Order updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
        autoHideDuration={3000}
      >
        <MuiAlert
          onClose={() => {
            setIsSuccess(false);
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          Mail Sent successfully
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CustomerOrders;
