import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { fetchLocalities } from "../common/lookupSlice";

import {
  Typography,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import axios from "axios";
import { localities } from "../common/lookupSlice";

let errorMsg;

function CustomerForm(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const { handleSubmit, control, errors, reset, register } = useForm({
    defaultValues: {
      id: props.form.data.id,
      fullName: props.form.data.fullName,
      externalCustNum: props.form.data.externalCustNum,
      email: props.form.data.email,
      mobileNumber: props.form.data.mobileNumber,
      address: props.form.data.address,
      pincode: props.form.data.pincode,
      locality: props.form.data.locality,
      state: props.form.data.state,
      isDirty: props.form.data.isDirty,
      contacts: props.form.data.contacts,
    },
  });

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "contacts",
  });
  const onSubmit = async (data) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    if (data.contacts) {
      let modifiedContacts = data.contacts.map((obj) => {
        obj.id = obj.contactId;
        delete obj["contactId"];
        return obj;
      });
      data.contacts = modifiedContacts;
    }
    try {
      if (props.form.mode === "C") {
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/customers`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else if (props.form.mode === "U") {
        await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/customers/${props.form.data.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      dispatch(fetchLocalities());
      setIsProcessing(false);
      props.success();
    } catch (err) {
      console.log(err.response.data.message);
      errorMsg = err.response.data.message;
      setIsError(true);
      setIsProcessing(false);
    }
  };
  const custLocalities = useSelector(localities);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography variant="caption">Full Name *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.fullName)}
              inputRef={register({
                required: "Minimum 5 letters required. No digits.",
                pattern: {
                  value: /^[a-zA-Z ]{5,}$/,
                  message: "Minimum 5 letters required. No digits.",
                },
              })}
              InputProps={{
                autoFocus: true,
                name: "fullName",
              }}
              size="small"
              name="fullName"
            />
            <FormHelperText error>
              {errors.fullName && errors.fullName.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography variant="caption">Address *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              rowsMax={3}
              type="adress"
              error={Boolean(errors.address)}
              inputRef={register({
                required: "Minimum 10 characters required",
                minLength: {
                  value: 5,
                  message: "Minimum 10 characters required",
                },
              })}
              size="small"
              name="address"
            />
            <FormHelperText error>
              {errors.address && errors.address.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Pincode *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.pincode)}
              type="number"
              inputRef={register({
                required: "6 digit pincode is required",
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: "6 digit pincode is required",
                },
              })}
              size="small"
              name="pincode"
            />
            <FormHelperText error>
              {errors.pincode && errors.pincode.message}
            </FormHelperText>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="caption">Locality *</Typography>
            <Autocomplete
              freeSolo
              value={props.form.data.locality}
              options={custLocalities}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  name="locality"
                  error={Boolean(errors.locality)}
                  fullWidth
                  inputRef={register({
                    required: "Locality is required",
                    pattern: {
                      value: /^[a-zA-Z0-9 ]{3,}$/,
                      message: "Minimum of 3 characters required",
                    },
                  })}
                />
              )}
            />
            <FormHelperText error>
              {errors.locality && errors.locality.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Mobile Number *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.mobileNumber)}
              type="number"
              inputRef={register({
                required: "10 digit mobile number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "10 digit mobile number is required",
                },
              })}
              size="small"
              name="mobileNumber"
            />
            <FormHelperText error>
              {errors.mobileNumber && errors.mobileNumber.message}
            </FormHelperText>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="caption">Email *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              type="email"
              error={Boolean(errors.email)}
              inputRef={register({
                required: "Minimum 5 characters required",
                minLength: {
                  value: 5,
                  message: "Minimum 5 characters required",
                },
              })}
              size="small"
              name="email"
            />
            <FormHelperText error>
              {errors.email && errors.email.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 40, paddingBottom: 40 }}>
        {fields.map((contact, index) => (
          <>
            <input
              type="hidden"
              name={`contacts[${index}].contactId`}
              value={`${contact.contactId}`}
              ref={register()}
            />
            <input
              type="hidden"
              name={`contacts[${index}].contactId`}
              value={`${contact.contactId}`}
              ref={register()}
            />
          </>
        ))}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left">Contact Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Mobile Number</StyledHeaderCell>
                <StyledHeaderCell align="left"></StyledHeaderCell>
              </TableRow>
            </TableHead>
            {fields.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Typography variant="subtitle1">
                      No contacts added.
                    </Typography>
                    <IconButton
                      size="medium"
                      align="center"
                      onClick={(e) => {
                        append({
                          contactId: "",
                          contactName: "",
                          mobileNumber: "",
                        });
                      }}
                    >
                      <PersonAddIcon color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}

            <TableBody>
              {fields.map((contact, index) => (
                <TableRow key={contact.id}>
                  <TableCell align="left">
                    <TextField
                      error={Boolean(
                        errors.contacts &&
                          errors.contacts[index] &&
                          errors.contacts[index].contactName
                      )}
                      inputRef={register({
                        required: "Min 3 characters required",
                        pattern: {
                          value: /^[a-zA-z0-9 ]{3,}$/,
                          message: "Min 3 characters required",
                        },
                      })}
                      size="small"
                      name={`contacts[${index}].contactName`}
                      defaultValue={contact.contactName}
                      variant="standard"
                    />
                    <FormHelperText error>
                      {errors.contacts &&
                        errors.contacts[index] &&
                        errors.contacts[index].contactName &&
                        errors.contacts[index].contactName.message}
                    </FormHelperText>
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      error={Boolean(
                        errors.contacts &&
                          errors.contacts[index] &&
                          errors.contacts[index].mobileNumber
                      )}
                      inputRef={register({
                        required: "10 digit required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "10 digit required",
                        },
                      })}
                      size="small"
                      name={`contacts[${index}].mobileNumber`}
                      defaultValue={contact.mobileNumber}
                      variant="standard"
                    />
                    <FormHelperText error>
                      {errors.contacts &&
                        errors.contacts[index] &&
                        errors.contacts[index].mobileNumber &&
                        errors.contacts[index].mobileNumber.message}
                    </FormHelperText>
                  </TableCell>
                  <TableCell align="left">
                  <IconButton
                      size="small"
                      onClick={(e) =>
                        insert(index + 1, {
                          contactId: "",
                          contactName: "",
                          mobileNumber: "",
                        })
                      }
                    >
                      <AddIcon color="secondary" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        remove(index);
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="secondary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              type="button"
              onClick={(e) => reset()}
              variant="contained"
              color="secondary"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <input type="hidden" name="isDirty" value="true" ref={register()} />
      <input type="hidden" name="id" ref={register()} />
    </form>
  );
}

export default CustomerForm;
