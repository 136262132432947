import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  MenuItem,
  Button,
  Grid,
  Select,
  CircularProgress,
  Checkbox,
  ListItemText,
  Radio
} from "@material-ui/core";
import axios from "axios";
import { roles, locations } from "../common/lookupSlice";

let errorMsg;
function UserForm(props) {
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoc, setIsLoc] = useState(false);
  const [isRole, setIsRole] = useState(false);
  const { handleSubmit, control, errors, register, getValues } = useForm({
    defaultValues: {
      id: props.form.data.id,
      firstName: props.form.data.firstName,
      lastName: props.form.data.lastName,
      userName: props.form.data.userName,
      email: props.form.data.email,
      mobileNumber: props.form.data.mobileNumber,
      locs: props.form.data.locations ? props.form.data.locations:[],
      roles: props.form.data.roles ? props.form.data.roles:[]
    },
  });
  const onSubmit = async (data) => {
    const clonedData = _.cloneDeep(data);
    clonedData.locations = loc ? (loc.map(x=>x.id)).toString():[].toString()
    clonedData.roles = rolesData ? (rolesData.map(x=>x.id)).toString():[].toString()
    delete clonedData.locs
    setIsProcessing(true);
    setIsError(false);
    setIsRole(false)
    setIsLoc(false)
    errorMsg = "";
    try {
      if(clonedData && clonedData.roles && clonedData.roles.length >0 ){
        if(clonedData && clonedData.locations && clonedData.locations.length >0 ){
          if (props.form.mode === "C") {
            await axios.post(`${process.env.REACT_APP_SERVICE_URL}/users`, clonedData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          } else if (props.form.mode === "U") {
            await axios.patch(
              `${process.env.REACT_APP_SERVICE_URL}/users/${props.form.data.userName}`,
              clonedData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
          setIsProcessing(false);
          props.success();
        }else{
          setIsLoc(true);
          setIsProcessing(false);
        }
      }else{
        setIsRole(true);
        setIsProcessing(false);
      }
    } catch (err) {
      errorMsg = err.response.data.message;
      setIsError(true);
      setIsProcessing(false);
    }
  };
  const userRoles = useSelector(roles);
  const allLocationData = useSelector(locations);
  const locationData =  allLocationData.filter(loc=> loc.type && loc.type.code!=="CORPORATE")
  const [loc, setLoc] = useState(getValues("locs"))
  const [rolesData, setRoles] = useState(getValues("roles"))

  const handleCheckboxClicks = (val, dataId,field) => {
    let selectedBox = [];
    if(field === "roles"){
      selectedBox = rolesData ? rolesData:[];
    }
    else{
      selectedBox = loc ? loc:[];
    }
    const selectedIndex = selectedBox.map(function (e) { return e.id; }).indexOf(dataId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedBox, val);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedBox.slice(1));
    } else if (selectedIndex === selectedBox.length - 1) {
      newSelected = newSelected.concat(selectedBox.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedBox.slice(0, selectedIndex),
        selectedBox.slice(selectedIndex + 1),
      );
    }
    if(field === "roles"){
      setRoles(newSelected);
      if(newSelected && newSelected.length >0){setIsRole(false)}
    }
    else{
      setLoc(newSelected);
      if(newSelected && newSelected.length >0){setIsLoc(false)};
    }
  };

  const handleRadioClicks = (val, dataId,field) =>{
    let newSelected = [];
    newSelected.push(val)
    setRoles(newSelected);
  }
  const dropdownValue = (field) => {
    let selectedType = [];
    if(field === "roles"){
      if(rolesData && rolesData.length>1){
        let findPrice = rolesData.find((role) => {return role.name === "PRICING ADMIN"});
        return [`${findPrice.description}`]
      }else{
        selectedType=rolesData ? rolesData.map(type => type.description) : []
      }
    }
    else{
      selectedType=loc ? loc.map(type => type.name) : [];
    }
    const value = selectedType.join(", ");
    let renderVal;
    if(field === "roles"){
    renderVal = _.isEmpty(rolesData) ? "" : value.length > 27 ? `${value.substring(0, 24)}...` : value;
    }
    else{
      renderVal = _.isEmpty(loc) ? "" : value.length > 27 ? `${value.substring(0, 24)}...` : value;
    }
    return renderVal;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">FirstName *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.firstName)}
              inputRef={register({
                required: "Minimum 2 letters required. No digits.",
                pattern: {
                  value: /^[a-zA-Z]{2,}$/,
                  message: "Minimum 2 letters required. No digits.",
                },
              })}
              InputProps={{
                autoFocus: true,
                name: "firstName",
              }}
              size="small"
              name="firstName"
            />
            <FormHelperText error>
              {errors.firstName && errors.firstName.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">LastName</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.lastName)}
              inputRef={register({
                pattern: {
                  value: /^[a-zA-Z0-9]*$/,
                  message: "Only Alphanumeric allowed",
                },
              })}
              size="small"
              name="lastName"
            />
            <FormHelperText error>
              {errors.lastName && errors.lastName.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">User Name *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.userName)}
              inputRef={register({
                required: "Minimum 6 alphanumeric characters required",
                pattern: {
                  value: /^[A-Za-z0-9]{6,}$/,
                  message: "Minimum 6 alphanumeric characters required",
                },
              })}
              size="small"
              name="userName"
            />
            <FormHelperText error>
              {errors.userName && errors.userName.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Password *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              type="password"
              error={Boolean(errors.password)}
              inputRef={register({
                required:
                  props.form.mode === "U"
                    ? false
                    : "Minimum 8 characters required",
                minLength: {
                  value: 8,
                  message: "Minimum 8 characters required",
                },
              })}
              size="small"
              name="password"
            />
            <FormHelperText error>
              {errors.password && errors.password.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography variant="caption">Email *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              type="email"
              error={Boolean(errors.email)}
              inputRef={register({
                required: "Minimum 5 characters required",
                minLength: {
                  value: 5,
                  message: "Minimum 5 characters required",
                },
              })}
              size="small"
              name="email"
            />
            <FormHelperText error>
              {errors.email && errors.email.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Mobile Number *</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.mobileNumber)}
              type="number"
              inputRef={register({
                required: "10 digit mobile number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "10 digit mobile number is required",
                },
              })}
              size="small"
              name="mobileNumber"
            />
            <FormHelperText error>
              {errors.mobileNumber && errors.mobileNumber.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Roles *</Typography>
            <FormControl
              required={true}
              fullWidth
              placeholder="Roles for the user"
              variant="outlined"
              size="small"
              error={Boolean(errors.roles)||(isRole && rolesData && rolesData.length === 0)}
            >
              <Controller
                as={
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    multiple
                    variant="outlined"
                    disableUnderline
                    displayEmpty={true}
                    renderValue={() => dropdownValue("roles")}
                  >

                    {userRoles.map((obj) => {
                      const { id, description } = obj;
                      return (
                        <MenuItem dense={true} key={id} value={obj}>
                        <Radio onChange={() => handleRadioClicks(obj, id,"roles")}
                        checked={rolesData && rolesData.some((x) =>{
                          if(id === 1){
                            let findAdm = _.find(rolesData, (data) => {
                              return data.id === id
                            })
                            if(findAdm){
                              let findPrice = _.find(rolesData, (data)=>{
                                return data.id === 5;
                              })
                              return (findPrice)?false:true;
                            }else{
                              return false;
                            }
                          }else{
                            return x.id === id
                          }
                          })} />
                        <ListItemText primary={description} />
                      </MenuItem>
                      )
                    })}
                  </Select>
                }
                name="roles"
                size="small"
                variant="outlined"
                rules={{
                  validate: value => {
                    return value.length !== 0
                  }
                }}
                control={control}
                defaultValue={props.form.data.roles ? props.form.data.roles:[]}
              />
            </FormControl>
            <FormHelperText error>
              {(errors.roles || (isRole && rolesData && rolesData.length === 0))? "Role is required" :""}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">Location *</Typography>
            <FormControl
              fullWidth
              placeholder="Location for the user"
              variant="outlined"
              size="small"
              error={Boolean(errors.locs) || (isLoc && loc && loc.length === 0)}
            >
              <Controller
                as={
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    multiple
                    variant="outlined"
                    disableUnderline
                    displayEmpty={true}
                    renderValue={() => dropdownValue()}
                  >

                    {locationData.map((obj) => {
                      const { id, name } = obj;
                      return <MenuItem dense={true} key={id} value={obj}>
                        <Checkbox onClick={() => handleCheckboxClicks(obj, id)} checked={loc && loc.some(x => x.id === id)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    })}
                  </Select>
                }
                name="locs"
                size="small"
                variant="outlined"
                rules={{
                  validate: value => {
                    return value.length !== 0
                  }
                }}
                control={control}
                defaultValue={props.form.data.locations ? props.form.data.locations:[]}
              />
            </FormControl>
            <FormHelperText error>
              {(errors.locs || (isLoc && loc && loc.length === 0))?"Location is required" :""}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="secondary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <input type="hidden" name="id" ref={register()} />
    </form>
  );
}

export default UserForm;
