import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    currentUser: null,
  },
  reducers: {
    logout: (state) => {
      state.currentUser = null;
      localStorage.removeItem("authToken");
      window.location.href ='/';
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export const { logout, setCurrentUser } = loginSlice.actions;

export const currentUser = (state) => state.login.currentUser;

export default loginSlice.reducer;
