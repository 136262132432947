import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
  
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import _ from 'lodash';
import moment from 'moment'
import SearchProduct from './searchProduct';

let errorMsg;
function ManageProductForm(props) {
  const [selectProduct, setSelectProduct] = useState({ open: false });
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [prodId, setProdId] = useState(props.form.data && props.form.data.product ? props.form.data.product.id:null);
  const [date, setDate] = useState({start: props.form.data.startDate ? moment(props.form.data.startDate).format('DD/MM/YYYY'):moment().format('DD/MM/YYYY'),end:props.form.data.endDate ? moment(props.form.data.endDate).format('DD/MM/YYYY'):null});
  const { handleSubmit, control, errors, register, getValues , setValue } = useForm({
    defaultValues: {
      productId: props.form.data.product && props.form.data.product.productName,
      startDate: date.start,
      endDate: date.end,
      isActive: props.form.mode === "C" ? true:props.form.data.isActive
    },
  });
 
  const onSubmit = async (data) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    try {
      if (props.form.mode === "C") {
        data.productId = Number(prodId)
        if(!(data.startDate)) data.startDate = null;
        if(!(data.endDate)) data.endDate = null;
        data = JSON.parse(JSON.stringify(data))
        if(data.startDate){
          var startDateArray = data.startDate && data.startDate.split("/");
          data.startDate = new Date(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
        }
        if(data.endDate){
          var endtDateArray = data.endDate && data.endDate.split("/");
          data.endDate = new Date(endtDateArray[1] + '/' + endtDateArray[0] + '/' + endtDateArray[2]);
        }
        const reqData = {
          items:JSON.stringify([data])
        }
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/productGroups/${props.groupId}/items`,
          reqData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else if (props.form.mode === "U") {
      data.productId = Number(prodId)
      if(!(data.startDate)) data.startDate = null;
      if(!(data.endDate)) data.endDate = null;
      data = JSON.parse(JSON.stringify(data))
        if(data.startDate){
          var startDateArrayU = data.startDate && data.startDate.split("/");
          data.startDate = new Date(startDateArrayU[1] + '/' + startDateArrayU[0] + '/' + startDateArrayU[2]);
        }
        if(data.endDate){
          var endtDateArrayU = data.endDate && data.endDate.split("/");
          data.endDate = new Date(endtDateArrayU[1] + '/' + endtDateArrayU[0] + '/' + endtDateArrayU[2]);
        }
      await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/productGroups/${props.groupId}/item/${prodId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setIsProcessing(false);
      props.success();
    } catch (err) {
      errorMsg = 'Error occured while creating product group item';
      errorMsg = err.response && err.response.data.message &&
        (err.response.data.message[0] === "productId must be unique" || err.response.data.message[0] === "Validation error") ? "Product Item already exists" : errorMsg;
      setIsError(true);
      setIsProcessing(false);
    }
  };
 
  const handleDateChange = (value,type) => {
    const clonedDate = _.cloneDeep(date);
    clonedDate[type] = value
    setDate(clonedDate)
  };

  // const dateValidation=()=>{
  //   const start = moment(date.start);
  //   const end = moment(date.end);
  //   const diff = end.diff(start, 'day');
  //   return diff >= 0 || !date.start || !date.end;
  // }
  
  const dateValidation=()=>{
    let sDate = date.start instanceof Date;
    let eDate = date.end instanceof Date;
    if(!sDate && !(date.start === null)){
      var startDateArray = date.start && date.start.split("/");
      date.start = new Date(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
    }
    if(!eDate && !(date.end === null)){
      var endDateArray = date.end && date.end.split("/");
      date.end = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
    }
    const start = moment(date.start);
    const end = moment(date.end);
    const diff = end.diff(start, 'day');
    return diff >= 0 || !date.start || !date.end;
  }

  const startDateAvl=props.form.data.startDate ? moment(props.form.data.startDate):moment();
  const today=moment();
  const diff = today.diff(startDateAvl, 'day');


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">{ props.form.mode === "U" ? 'Product Name':'Search Product' }</Typography>
            <TextField
              variant="outlined"
              fullWidth
              disabled={true}
              error={Boolean(errors.productId)}
              inputRef={register({
                required: "Product is required",
              })}
              onClick={(e)=> props.form.mode === "U" ? '':setSelectProduct({ ...selectProduct, open: true})}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{cursor:"pointer"}}>
                    <SearchIcon  onClick={(e)=>  props.form.mode === "U" ? '':setSelectProduct({ ...selectProduct, open: true})}/>
                  </InputAdornment>
                ),
              }}
              size="small"
              name="productId"
              value={getValues('productId')}
            />
            <FormHelperText error>
              {errors.productId && errors.productId.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
          <Typography variant="caption">Start Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    error={Boolean(errors.startDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    inputRef={register({
                      required: "Start date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                      validate:()=> dateValidation() || 'Start date should be lesser than end date'
                    })}
                    name="startDate"
                    value={date.start}
                    disablePast={true}
                    minDateMessage=''
                    maxDate="9999-12-31"
                    onChange={(value)=>handleDateChange(value,"start")}
                    disabled = {props.form.mode !== "C" && !(diff<=0)}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.startDate && errors.startDate.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
          <Typography variant="caption">End Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    error={Boolean(errors.endDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    inputRef={register({
                      required: "End date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                    })}
                    name="endDate"
                    value={date.end}
                    maxDate="9999-12-31"
                    onChange={(value)=>handleDateChange(value,"end")}
                    disablePast={true}
                    minDateMessage=''
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.endDate && errors.endDate.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
                <Typography variant="caption">Active</Typography>
                <Controller
              as={Checkbox}
              name="isActive"
              type="checkbox"
              inputRef={register({})}
              control={control}
            />
              </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
        <Grid item>
          <Button
            size="small"
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="secondary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={selectProduct.open}
        onClose={() => {
          setSelectProduct({ ...selectProduct, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Select Product
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectProduct({ ...selectProduct, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <SearchProduct
            form={selectProduct}
            setValue = {setValue}
            setProdId={setProdId}
            cancel={() => {
              setSelectProduct({ ...selectProduct, open: false });
            }}
            success={() => {
              setSelectProduct({ ...selectProduct, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
    </form>
  );
}

export default ManageProductForm;
