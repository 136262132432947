import React, { useState, useRef, useEffect } from "react";

import { useHistory } from "react-router-dom";
import axios from "axios";
import { jsonToCsv, downloadCSVFile } from '../common/util';
import {
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import ReactToPrint from "react-to-print";
import moment from 'moment';
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Reconcile from '../../images/Reconcile.svg';
import { formatDate } from '../common/util';
import _ from "lodash";
import Order from '../../images/Order.svg';
import highStock from "../../images/highStock.svg";
import lowStock from "../../images/lowStock.svg";

class CompToPrint extends React.Component {
  render() {
    return (
      <Table style={{ paddingTop: 10 }} size="small">
        <TableHead>
          <TableRow>
          <StyledHeaderCell>Product</StyledHeaderCell>
          <StyledHeaderCell align="left">Open Order Qty</StyledHeaderCell>
          <StyledHeaderCell align="left">Inventoy Available</StyledHeaderCell>
          <StyledHeaderCell align="left">Inventory Status</StyledHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.dailyInvSynch.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography variant="subtitle1">
                  No records found
                      </Typography>
              </TableCell>
            </TableRow>
          ) : (
              this.props.dailyInvSynch.map((row, index) => (
                <TableRow
                  key={row.id}
                  hover={true}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    {row.pendingQty}
                  </TableCell>
                  <TableCell align="left">
                    {row.availability}
                  </TableCell>
                  <TableCell align="left">
                    {row.status}
                  </TableCell>
                </TableRow>
              ))
            )}
        </TableBody>
      </Table>
    );
  }
}

const DailySync = (props) => {
  const componentRef = useRef();
  const pageSize = 10;
  const history = useHistory();
  const [count, setCount] = useState();
  const todayDate = moment(new Date());
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dailyInvSynch, setDailyInvSynch] = useState([])
  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/reports/backOrderinsight`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownload = async () => {
    try {
      const dailySyncData = await getDailyInvSync();
      const data = jsonToCsv(dailySyncData)
      downloadCSVFile(data, "dailySyncData")
    } catch (err) {
      setIsError(true);
    }
  }

  const getDailyInvSync = async () => {
    const clonedQuery = _.cloneDeep(query);
    delete clonedQuery.page;
    delete clonedQuery.pageSize;
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/reports/backOrderinsight`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: clonedQuery,
      }
    );
    const orders = result.data && result.data.map(item => {
      return {
        name: item.productName,
        pendingQty: item.pendingQty,
        availability: item.openingQty,
        status: item.OOSFlag ? "Available":"Out Of Stock"
      }
    });
    return orders;
  }


  const handlePrint = async () => {
    try {
      const data = await getDailyInvSync();
      setDailyInvSynch(data);
    } catch (err) {
      setIsError(true);
    }
  }

  const handleProductClick = (name,id,uom) => {
    history.push({
      pathname: '/inventoryProduct',
      state: { id: name, prodId: id ,uom:uom,page:"inSight"}
    })
  }

  const handleProductOrderClick = (name,id) => {
    history.push({
      pathname: '/productPurchaseOrders',
      state: { name: name, id: id,page:"inSight"}
    })
  }

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
                </Link>
              <Link color="inherit" href="" onClick={() => history.push("/reports")}>
                Reports
                </Link>
              <Typography component="span" color="textPrimary">
                Replenishment Insight
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={6} xs={6}>
              <Grid item>
                <Typography>Daily Replenishment Insight: {formatDate(todayDate)}</Typography>
              </Grid>
            </Grid>
            <Grid item container justify={"flex-end"} md={6} xs={6}>
              <Grid item>
                <Box mt={2} mr={4}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Download
            </Button></Box>
              </Grid>
              <Grid item>
                <Box mt={2}>
                  <ReactToPrint
                    onBeforeGetContent={handlePrint}
                    trigger={() => <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<PrintIcon />}>
                      Print </Button>}
                    content={() => componentRef.current}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Product</StyledHeaderCell>
                <StyledHeaderCell align="left">Open Order Qty</StyledHeaderCell>
                <StyledHeaderCell align="left">Inventoy Available</StyledHeaderCell>
                <StyledHeaderCell align="left">Inventory Status</StyledHeaderCell>
                <StyledHeaderCell align="left">Locate Inventory</StyledHeaderCell>
                <StyledHeaderCell align="left">Link To Open Orders</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row, index) => (
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell component="th" scope="row" >
                            {row.productName}
                          </TableCell>
                          <TableCell align="right">
                            {row.pendingQty}
                          </TableCell>
                          <TableCell align="right">
                            {row.openingQty}
                          </TableCell>
                          <TableCell align="center">
                            {row.OOSFlag ?  <img src={highStock} alt="highStock" />:<img src={lowStock} alt="lowStock" />}
                          </TableCell>
                          <TableCell align="left">
                             <img src={Reconcile} style={{cursor: "pointer" }} alt="reconcile" onClick={() => handleProductClick(row.productName,row.productId,row.uom || "NA")}/>
                            </TableCell>
                            <TableCell align="left">
                             <img src={Order} style={{cursor: "pointer" }} alt="reconcile" onClick={() => handleProductOrderClick(row.productName,row.productId)}/>
                            </TableCell>
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
        <Box display="none">
          <CompToPrint ref={componentRef} dailyInvSynch={dailyInvSynch} />
        </Box>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
          <div />
        )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default DailySync;
