import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import {
  IconButton,
  Typography,
  InputAdornment,
  FormHelperText,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  CardHeader,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import SearchProduct from '../productGroups/searchProduct';

import {
  locations
} from "../common/lookupSlice";
import { API_INVENTORY } from "../../config/constants";


const Inventory = (props) => {
  const location = useLocation();
  const availableLocations = useSelector(locations);
  const id = location.state && location.state.id ? location.state.id : null;
  const unit = location.state && location.state.uom ? location.state.uom : "";
  const selectedId = location.state && location.state.prodId ? location.state.prodId : null;
  const [prodId, setProdId] = useState(selectedId ? selectedId : null);
  const pageSize = 10;
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const [count, setCount] = useState();
  const [uom, setUom] = useState(unit);
  const [corporateCount,setCorporateCount] = useState(0)
  const [selectProduct, setSelectProduct] = useState({ open: false });
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const token = localStorage.getItem("authToken");
  const [ackForm, setAckForm] = useState({ open: false });
  const { errors, register, getValues, setValue } = useForm({
    defaultValues: {
      productId: id ? id : null,
      lineItems: []
    },
  });
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize, productName: getValues('productId') })
  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    setEdit(false);
    try {
      if(query.productName){
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_INVENTORY}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      const filteredLoc = availableLocations.filter(l => l.type.code !== "CORPORATE")
      let newLocationData = [];
      filteredLoc.forEach(loc => {
        let newLocation;
        const locData = result.data && result.data.rows && result.data.rows.filter(x => x.location.id === loc.id)
        if (locData && locData[0]) {
          newLocation = {
            "location": {
              "id": locData[0].location.id,
              "name": locData[0].location.name,
              "type":{
                "code":locData[0].location.type.code
              }
            },
            "subLocation": locData[0].subLocation,
            "openingQty": locData[0].openingQty
          }

        }
        else {
          newLocation = {
            "location": {
              "id": loc.id,
              "name": loc.name,
              "type":{
                "code":"LocationCode"
              }
            },
            "product": {
              "uom": uom
            },
            "openingQty": 0
          }
        }
        newLocationData.push(newLocation)
      })
      const corporateData = result.data && result.data.rows.filter(prod => prod.location.type.code === "CORPORATE");
      const corporateQtyCount = corporateData && corporateData[0] ? corporateData[0].openingQty : 0;
      setCorporateCount(corporateQtyCount)
      let locCounts = newLocationData ? newLocationData.reduce((x,y)=>x+(y.openingQty ? y.openingQty:0) ,0):0;
      setAckForm({ ...ackForm, open: locCounts !== corporateQtyCount });
      setData(newLocationData);
    }
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({
      ...query,
      productName: getValues('productId'),
      page: 1,
    });
  }, [prodId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      productName: e.target.elements.itemName.value,
      page: 1,
    });
  };

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
                </Link>
              <Typography component="span" color="textPrimary">
                Product Inventory</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    disabled={true}
                    error={Boolean(errors.productId)}
                    inputRef={register({
                      required: "Product is required",
                    })}
                    onClick={(e) => setSelectProduct({ ...selectProduct, open: true })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ cursor: "pointer" }}>
                          <SearchIcon onClick={(e) => setSelectProduct({ ...selectProduct, open: true })} />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    name="productId"
                    value={getValues('productId')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={selectProduct.open}
            onClose={() => {
              setSelectProduct({ ...selectProduct, open: false });
            }}
          >
            <DialogTitle>
              <Grid container>
                <Grid item container xs={9} alignItems="center">
                  <Grid item>
                    Select Product
              </Grid>
                </Grid>
                <Grid item container xs={3} alignItems="center" justify="flex-end">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setSelectProduct({ ...selectProduct, open: false });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent >
              <SearchProduct
                form={selectProduct}
                setValue={setValue}
                setProdId={setProdId}
                setUom={setUom}
                cancel={() => {
                  setSelectProduct({ ...selectProduct, open: false });
                }}
                success={() => {
                  setSelectProduct({ ...selectProduct, open: false, success: true });
                }}
              />
            </DialogContent>
          </Dialog>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>

        <Card>
          <CardHeader title={
            (<Typography display="block" variant="overline" style={{ fontWeight: "Bold", color: "#085394" }}>
              {`Total Inventory : ${corporateCount} ${uom}`}
            </Typography>)}/>
          <TableContainer component={Paper}>
            <Table style={{ paddingTop: 10 }} size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell>Product Location</StyledHeaderCell>
                  <StyledHeaderCell align="left">Product Count</StyledHeaderCell>
                  <StyledHeaderCell align="left">Sub-Location</StyledHeaderCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                  <TableBody>
                    {data.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={props.modal ? 6 : 7} align="center">
                          <Typography variant="subtitle1">
                            No records found
                      </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                        data.map((row, index) => (
                          row.location.type.code !== "CORPORATE" && <TableRow
                            style={{ cursor: props.modal ? "pointer" : "" }}
                            key={row.id}
                            hover={true}
                            onClick={(e) =>
                              props.onRowSelect && props.onRowSelect(row)
                            }
                          >
                            <TableCell >
                              {row.location && row.location.name}
                            </TableCell>
                            <TableCell align="left">

                              <TextField
                                id="standard-basic"
                                disabled={!edit || (row.location && row.location.type && row.location.type.code === "CORPORATE")}
                                inputRef={register({
                                  required: "Product Count is required",
                                  pattern: {
                                    value: /^\d+$/,
                                    message: "Provide a valid count",
                                  },
                                })}
                                inputProps={{style: { textAlign: 'right' }}}
                                name={`lineItems[${index}]`}
                                defaultValue={row.openingQty} />

                              <FormHelperText error>
                                {errors.lineItems &&
                                  errors.lineItems[index] &&
                                  errors.lineItems[index].message}
                              </FormHelperText>
                            </TableCell>
                            <TableCell align="left">
                              {row.subLocation && row.subLocation}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                  </TableBody>
                )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
          <div />
        )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Inventory;
