import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon
} from "@material-ui/core";
import { currentUser } from "../login/loginSlice";
import InventoryMovement from "../../images/inventoryMovement.svg";
import ProductInventory from "../../images/productInventory.svg";
import StockEntry from "../../images/stockEntry.svg";
import StockLedgerReport from "../../images/stockLedgerReport.svg";

const InventoryHome = (props) => {
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAdmin =
    roles.findIndex((role) => role.name === "ADMIN") > -1
      ? true
      : false;

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
              Inventory
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Grid item container xs={12} md={4} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold", display: "inline-block" }}>Quick Links</ListSubheader>} ></List>
          <List style={{ display: "inline-flex", marginTop: "-20px" }}>
            <ListItem style={{ width: "200px", fontWeight: "bold" }}>
              <img src={InventoryMovement} alt="InventoryMovement" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/inventoryMovement")}
              >
                Inventory Movement
              </Link>
            </ListItem>
            <ListItem style={{ width: "200px", fontWeight: "bold"  }}>
              <img src={StockEntry} alt="StockEntry" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/inventoryMovement?from=StockEntry")}
              >
                Stock Entry
              </Link>
            </ListItem>
            {isAdmin ?
              <ListItem style={{ width: "300px", fontWeight: "bold"  }}>
                <img src={ProductInventory} alt="ProductInventory" className="quickIconPadding" />
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => history.push("/inventory")}
                >
                  Product Inventory Report
                </Link>
              </ListItem> : null}
          </List>
        </Grid>
      </div>
      <div>
        <Grid item container xs={12} md={3} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold" }}>Purchase</ListSubheader>} >
            <ListItem>
              <ListItemIcon>
                <img src={InventoryMovement} alt="InventoryMovement" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/inventoryMovement")}
              >
                Inventory Movement
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={StockEntry} alt="StockEntry" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/inventoryMovement?from=STOCKENTRY")}
              >
                Stock Entry
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={ProductInventory} alt="ProductInventory" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/inventory")}
              >
                Product Inventory Report
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={StockLedgerReport} alt="StockLedgerReport" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/stockledgerreport")}
              >
                Stock Ledger Report
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>
    </div>
  );
};

export default InventoryHome;
