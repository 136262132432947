import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  CardHeader,
  Card
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ManageDiscountForm from "./ManageDiscountForm";

import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

import {
  productGroups
} from "../common/lookupSlice";


import { currentUser } from "../login/loginSlice";

import { API_ALL_PRODUCT_GROUPS } from "../../config/constants";
import { formatDate } from '../common/util'
const ManageProducts = (props) => {
  const prodGrps = useSelector(productGroups);
  const location = useLocation();
  const id = location.state && location.state.id ? location.state.id : null;
  const initialId = prodGrps && prodGrps[0] ? prodGrps[0].id : '';
  const initialCode = prodGrps && prodGrps[0] ? id ? (prodGrps.filter(grp=>grp.id===id)[0]).code:prodGrps[0].code : '';
  const pageSize = 10;
  const history = useHistory();

  const [count, setCount] = useState();
  const [discountForm, setDiscountForm] = useState({ open: false });
  const [group, setGroup] = useState(id ? id : initialId);
  const [code, setCode] = useState(initialCode);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const token = localStorage.getItem("authToken");

  const resetQuery = () => {
    setQuery({
      ...query,
      groupType: "",
      page: 1,
    });
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCT_GROUPS}/${group}/discounts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.discounts);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [group]); // eslint-disable-line react-hooks/exhaustive-deps

  const editProduct = async (itemId) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCT_GROUPS}/${group}/discounts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDiscountForm({
        ...discountForm,
        data: (result.data.discounts.filter(disc =>disc.id === itemId))[0],
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  //On success of add or edit, reset the query to load data again
  useEffect(() => {
    if (discountForm.success) {
      resetQuery();
    }
  }, [discountForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleManageProductsClick = () => {
    const grpId = group;
    history.push({
      pathname: '/manageProducts',
      state: { id: grpId }
  })
}
const loggedInUser = useSelector(currentUser);
const isPricingAdmin = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role=>role.name === "PRICING ADMIN");
  return (
    <div>
      <Grid container>
        <Grid item container md={6} xs={12} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
                </Link>
              <Link color="inherit" href="" onClick={() => history.push("/productGroups")}>
                <Typography component="span" color="textPrimary" >
                  Product Group
                </Typography>
              </Link>
              <Typography component="span" color="textPrimary">
                Manage Discounts
                </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={2} xs={12}>
            <Typography style={{fontSize:'15px'}}variant="caption">Product Group</Typography>
            <Autocomplete
              id="combo-box-demo"
              options={prodGrps}
              getOptionLabel={(option) => option.code}
              style={{ width: 300 }}
              size="small"
              onChange={(e, val) => {
                if (val) {
                  setGroup(val.id);
                  setCode(val.code)
                }
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          </Grid>
          <Grid item md={10} xs={12}>
            <Box display="flex" justifyContent="flex-end" mt={4} mb={2} mr={0} >
              { isPricingAdmin && <Button
                color="primary"
                variant="contained"
                style={{marginRight: '25px'}}
                onClick={() => {
                  setDiscountForm({ ...discountForm, data: {}, mode: "C", open: true });
                }}
                disabled = {!isPricingAdmin}
              >
                Add New Discount
              </Button>}
              <Button
                color="primary"
                variant="contained"
                onClick={() => 
                  handleManageProductsClick()
                }
              >
                Manage Products
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: 30 }}>
      <Card>
        <CardHeader title={
                (<Typography display="block" variant="overline" style={{fontWeight:"Bold",marginBottom: "-11px"}}>
                    Product group : {code ? code : prodGrps[0].code}
                </Typography>)}  />
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Discount Rate</StyledHeaderCell>
                <StyledHeaderCell align="left">Start Date</StyledHeaderCell>
                <StyledHeaderCell align="left">End Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Active</StyledHeaderCell>
                { isPricingAdmin &&  <StyledHeaderCell align="center">Action</StyledHeaderCell>}
              </TableRow>
            </TableHead>
            {isLoading && initialId ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 || !initialId ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row, index) => (
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "", }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell component="th" scope="row">
                            {row.discount }
                          </TableCell>
                          <TableCell align="left">
                            {row.startDate ? formatDate(row.startDate) : ''}
                          </TableCell>
                          <TableCell align="left">
                            {row.endDate ? formatDate(row.endDate) : ''}
                          </TableCell>
                          <TableCell align="left">
                            {row.isActive === true ? 'Yes' : 'No'}
                          </TableCell>
                          { isPricingAdmin && <TableCell align="center">
                            <IconButton
                              onClick={() => {
                                editProduct(row.id);
                              }}
                            >
                              <EditIcon color="secondary" />
                            </IconButton>
                          </TableCell>}
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
        </Card>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
          <div />
        )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={discountForm.open}
        onClose={() => {
          setDiscountForm({ ...discountForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {discountForm.mode === "C"
                  ? "Add Product Group Discount"
                  : "Edit Product Group Discount"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setDiscountForm({ ...discountForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '280px' }}>
          <ManageDiscountForm
            form={discountForm}
            groupId={group}
            cancel={() => {
              setDiscountForm({ ...discountForm, open: false });
            }}
            success={() => {
              setDiscountForm({ ...discountForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={discountForm.success}
        onClose={() => setDiscountForm({ ...discountForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setDiscountForm({ ...discountForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {discountForm.mode === "C"
            ? "Product Item added successfully"
            : "Product Item updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ManageProducts;
