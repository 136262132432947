import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Card,
  Collapse
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const CustomerInvSelect = (props) => {
  const location = useLocation();
  const custData = location.state;
  const custId = custData && custData.id;
  const [open, setOpen] = React.useState([]);
  const [spoData, setSPoData] = useState([]);
  const [poInnerData, setPoInnerData] = useState([]);
  const [invoiceNo, setSaleInvoiceNo] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPoLoading, setIsPoLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const loadData = async () => {
    setIsLoading(true);
    try {
      const spoResult = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/reports/customerSales?customerId=${custId}&filterStatus=UNFULFILLED`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const validPoData = spoResult.data.invoices ? spoResult.data.invoices : [];
      const spoData = validPoData ? validPoData.length : 0;
      const poCollapse = [];
      for (let step = 0; step < spoData; step++) {
        poCollapse[step] = false;
      }
      setOpen(poCollapse)
      setSPoData(validPoData);
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.message !== `Sale Invoice is Not Avaiable`) {
        props.setIsError(true);
      }
    }
    setIsLoading(false);
    setRefresh(false);
  };

  const loadPoData = async () => {
    setIsPoLoading(true)
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/clone/${invoiceNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPoInnerData(result.data.clonedInvoice && result.data.clonedInvoice.lineItems
        ? result.data.clonedInvoice.lineItems : [])
    } catch (err) {
      setPoInnerData([]);
    }
    setIsPoLoading(false)
  }
  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (invoiceNo) {
      loadPoData();
    }
  }, [invoiceNo]); // eslint-disable-line react-hooks/exhaustive-deps



  const handlePoClick = async (invoiceNum, row) => {
    console.log("row", row);
    props.setStore && props.setStore(row && row.locationId);
    props.setSelectInvData && props.setSelectInvData(row);
    props.setCostCenterName && props.setCostCenterName(row && row.erpNextcostCenter);
    props.setLocName && props.setLocName(row && row.location && row.location.name);
    props.setBank && props.setBank(row && row.orgBankId);
    props.setBankName && props.setBankName(row && row.companyBankdetails && row.companyBankdetails.bankName);
    
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/invoices/clone/${invoiceNum}/?locationId=${row && row.locationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const productList = result && result.data.clonedInvoice && result.data.clonedInvoice.lineItems ? result.data.clonedInvoice.lineItems : [];
    const products = productList.filter(x => x.product !== null && (x.returnQty < x.qty))
    const sales = products.map(x => {
      const avail = x.product && x.product.inventories
        && x.product.inventories[0] ? x.product.inventories[0].openingQty : 0
      return {
        "productName": x.product && x.product.productName,
        "productId": x.product && x.product.id,
        "HSNCode": x.HSNCode,
        "productData": JSON.stringify({ CGST: x.product && x.product.CGST, IGST: x.product && x.product.IGST, SGST: x.product && x.product.SGST }),
        "qty": x.qty - x.returnQty,
        "rate": x.unitPrice,
        "disc": x.discountPct ? x.discountPct : "0.00",
        "avail": avail,
        "uom": x.uom ? x.uom : "OTH",
        "isQtyDecimal": x.product && x.product.isQtyDecimal ? true : false,
        "unFulFilledQty": x.qty - x.returnQty
      }
    })
    if (props.setSelectedSPo) {
      props.remove();
      props.setShowSPo(false)
      props.setSelectedSPo({ invoiceNum: invoiceNum, products: sales })
    }
  }
  return (
    <div>
      <div>
        <Card>
          <TableContainer component={Paper} >
            <Table style={{ paddingTop: 10 }} size="small">
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {spoData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={props.modal ? 6 : 7} align="center">
                        <Typography variant="subtitle1">
                          Customer Sale Invoice not found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    spoData.map((row, index) => (
                      <React.Fragment>
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => {
                              const clone = _.cloneDeep(open)
                              clone.forEach((x, y) => {
                                if (y === index) {
                                  clone[y] = !clone[y]
                                }
                                else {
                                  clone[y] = false
                                }
                              })
                              setSaleInvoiceNo(row.invoiceNum)
                              setOpen(clone)
                            }
                            }>
                              {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row" >
                            {row.invoiceNum}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open[index]} timeout="auto" unmountOnExit>
                              <Box mt={2}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  onClick={() => handlePoClick(row.invoiceNum, row)}
                                  disabled={poInnerData.length === 0}>Select Invoice</Button>
                              </Box>
                              <Box mt={2} mb={2}>
                                <TableContainer component={Paper} >
                                  <Table style={{ paddingTop: 10 }} size="small">
                                    <TableHead>
                                      <TableRow>
                                        <StyledHeaderCell>Product</StyledHeaderCell>
                                        <StyledHeaderCell align="left">Product Rate</StyledHeaderCell>
                                        <StyledHeaderCell align="left">Saled Quantity</StyledHeaderCell>
                                        <StyledHeaderCell align="left">Returned Quantity</StyledHeaderCell>
                                      </TableRow>
                                    </TableHead>
                                    {isPoLoading ? (
                                      <TableBody>
                                        <TableRow>
                                          <TableCell colSpan={7} align="center">
                                            <CircularProgress />
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    ) : (
                                      <TableBody>
                                        {poInnerData.length === 0 ? (
                                          <TableRow>
                                            <TableCell colSpan={props.modal ? 6 : 7} align="center">
                                              <Typography variant="subtitle1">
                                                Nothing Purchased.
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        ) : (
                                          poInnerData.map((row, index) => (
                                            <TableRow
                                              style={{ cursor: props.modal ? "pointer" : "" }}
                                              key={row.id}
                                              hover={true}
                                              onClick={(e) =>
                                                props.onRowSelect && props.onRowSelect(row)
                                              }
                                            >
                                              <TableCell component="th" scope="row">
                                                {row.product && row.product.productName}
                                              </TableCell>
                                              <TableCell align="left">
                                                {row.salePrice}
                                              </TableCell>
                                              <TableCell align="left">
                                                {row.qty}
                                              </TableCell>
                                              <TableCell align="left">
                                                {row.returnQty}
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        )}
                                      </TableBody>
                                    )}
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow></React.Fragment>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

        </Card>
      </div>
    </div>
  );
};

export default CustomerInvSelect;
