import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Typography,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  // FormControl,
  // Select,
  // MenuItem
} from "@material-ui/core";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import _ from 'lodash';
import moment from 'moment';
import SearchIcon from "@material-ui/icons/Search";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import { locations } from '../common/lookupSlice';
import axios from "axios";
import { useSelector } from "react-redux";
import SearchProduct from "../productGroups/searchProduct";
let errorMsg;

function PurchaseForm(props) {
  // const { id } = useParams();
  const inventoryLocations = useSelector(locations);
  // const targetWarehousesList = inventoryLocations.filter(loc => loc.type !== "CORPORATE");
  const corporateLocation = inventoryLocations.filter(loc => loc.type && loc.type.code === "CORPORATE");
  const corporateLocationId = corporateLocation.length > 0 ? corporateLocation[0].id : 1;
  const [selectProduct, setSelectProduct] = useState({ open: false, test: null });
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errQtyProIndex, setErrQtyProd] = useState([]);
  const [date, setDate] = useState({ requireDate: props.form.data.requireDate ? moment(props.form.data.requireDate).format('DD/MM/YYYY') : null });
  const { handleSubmit, control, errors, register, setValue, getValues } = useForm({
    defaultValues: {
      mrNum: props.form.data.mrNum,
      requireDate: date.requireDate,
      lineItems: props.form.data.lineItems
    },
  });

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "lineItems",
  });

  const onSubmit = async (data) => {
    console.log("submit", data);
    if (data.requireDate) {
      var startDateArray = data.requireDate && data.requireDate.split("/");
      data.requireDate = startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2];
    }
    let errqtyProd = [];
    if (data.lineItems) {
      delete data.id;
      setIsProcessing(true);
      setIsError(false);
      errorMsg = "";
      if (data.lineItems) {
        let modifiedItems = data.lineItems.map((obj, objIndex) => {
          obj.id = obj.contactId;
          let isQtyErr = false;
          if (obj.isQtyDecimal === "false") {
            if (!(Number.isInteger(parseFloat(obj.requiredQty)))) {
              isQtyErr = true;
            }
          }
          if (isQtyErr) {
            errqtyProd.push(objIndex)
          }
          delete obj["id"];
          return obj;
        });
        data.lineItems = JSON.stringify(modifiedItems);
      } else {
        data.lineItems = "[]";
      }
      if (errqtyProd.length === 0) {
        try {
          if (props.form.mode === "C") {
            await axios.post(
              `${process.env.REACT_APP_SERVICE_URL}/materialrequest/`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else if (props.form.mode === "U") {
            await axios.patch(
              `${process.env.REACT_APP_SERVICE_URL}/materialrequest/${props.form.data.mrNum}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
          setIsProcessing(false);
          props.success();
          props.loadData();
        } catch (err) {
          errorMsg = err.response.data.message;
          setIsError(true);
          setIsProcessing(false);
        }

      } else {
        setErrQtyProd(errqtyProd);
        setIsProcessing(false);
      }
    } else {
      errorMsg = "Product is required";
      setIsError(true);
    }
  };

  const handleDateChange = (value, type) => {
    const clonedDate = _.cloneDeep(date);
    clonedDate[type] = value
    setDate(clonedDate)
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignContent="center" alignItems="baseline">
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={4} xs={4}>
            <Typography variant="caption">Material Request No. </Typography>
            <TextField
              variant="outlined"
              fullWidth placeholder="Auto Generated"
              InputProps={{
                autoFocus: true,
                name: "mrNum",
              }} value={props.form.data.mrNum}
              size="small"
              name="mrNum" disabled />
          </Grid>
          <Grid item md={4} xs={4}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Require Date *</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    fullWidth
                    helperText=""
                    error={Boolean(errors.requireDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    inputRef={register({
                      required: "Date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                    })}
                    name="requireDate"
                    minDate={(props.form.mode === "U") ? props.form && props.form.data && props.form.data.requireDate : new Date()}
                    value={date.requireDate}
                    disabled={props.readOnly}
                    onChange={(value) => handleDateChange(value, "requireDate")}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
                  {errors.requireDate && errors.requireDate.message}
                </FormHelperText>
              </Grid>
            </Grid>


          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 40, paddingBottom: 40 }}>
        {fields.map((contact, index) => (
          <>
            <input
              type="hidden"
              name={`lineItems[${index}].contactId`}
              value={`${contact.contactId}`}
              ref={register()}
            />
            <input
              type="hidden"
              name={`lineItems[${index}].contactId`}
              value={`${contact.contactId}`}
              ref={register()}
            />
          </>
        ))}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left" style={{ width: "30%" }} colSpan={4}>Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Required Qty</StyledHeaderCell>
                <StyledHeaderCell align="left">Available Qty</StyledHeaderCell>
                <StyledHeaderCell align="left">UOM</StyledHeaderCell>
                <StyledHeaderCell align="left">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {fields.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <Typography variant="subtitle1">
                      Nothing Material Request.
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        append({
                          contactId: "",
                          productId: "",
                          requiredQty: "",
                          uom: "",
                        });
                        setErrQtyProd([]);
                      }}
                      color="primary"
                      size="small"
                    >
                      ADD PRODUCT
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}

            <TableBody>
              {fields.map((contact, index) => {
                return (
                  <TableRow key={contact.id}>
                    <TableCell align="left" colSpan={4}>
                      <TextField
                        variant="standard"
                        fullWidth
                        error={Boolean(
                          errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].productCode
                        )}
                        inputRef={register({
                          required: "Product is required",
                        })}
                        onClick={(e) => setSelectProduct({ ...selectProduct, open: true, lineKey: index, lineItems: props.form.data.lineItems, create: props.form.mode === "C" })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" style={{ cursor: "pointer" }}>
                              <SearchIcon onClick={(e) => setSelectProduct({ ...selectProduct, open: true })} />
                            </InputAdornment>
                          ),
                          style: { fontSize: "14px" },
                        }}
                        multiline
                        minRows={1}
                        maxRows={3}
                        size="small"
                        name={`lineItems[${index}].productCode`}
                        key={contact.id} disabled
                        defaultValue={contact.productCode}
                      />
                      <TextField
                        variant="standard"
                        fullWidth
                        error={Boolean(
                          errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].productId
                        )}
                        inputRef={register({
                        })}
                        size="small"
                        style={{ display: "none" }}
                        name={`lineItems[${index}].productId`}
                        key={contact.id} disabled
                        defaultValue={props.form.data.lineItems && (props.form.data.lineItems)[index] ? (props.form.data.lineItems)[index].product && (props.form.data.lineItems)[index].product.id : ''}
                      />
                      <FormHelperText error>
                        {errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].productCode &&
                          errors.lineItems[index].productCode.message}
                      </FormHelperText>
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        error={Boolean(
                          errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].requiredQty
                        ) || (_.includes(errQtyProIndex, index)) ? true : false}
                        inputProps={{ style: { textAlign: 'right' } }}
                        inputRef={register({
                          required: "Required",
                          pattern: {
                            value: /^[0-9]*.[0-9]{0,3}$/,
                            message: "Qty should be greater than 0",
                          },
                        })}
                        size="small"
                        name={`lineItems[${index}].requiredQty`}
                        defaultValue={contact.requiredQty} disabled={props.readOnly}
                        variant="standard"
                      />
                      <FormHelperText error>
                        {(errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].requiredQty &&
                          errors.lineItems[index].requiredQty.message) || ((_.includes(errQtyProIndex, index)) ? true : false) ? "Enter Valid Qty" : ""}
                      </FormHelperText>
                    </TableCell>
                    
                    {/* <TableCell align="right">
                      <FormControl
                        fullWidth
                        variant="standard"
                        size="small"
                        error={Boolean(
                          errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].targetWarehouse
                        )}
                      >
                        <Controller
                          as={
                            <Select value={contact.targetWarehouse}
                            >
                              {targetWarehousesList && targetWarehousesList.length > 0 && targetWarehousesList.map(function (cost_center, index) {
                                return (
                                  <MenuItem key={`cost_center-${index + 1}`} value={cost_center.name}>
                                    {cost_center.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          }
                          
                          name={`lineItems[${index}].targetWarehouse`}
                          size="small"
                          variant="standard"
                          control={control}
                    
                          defaultValue={contact.targetWarehouse}
                        />
                      </FormControl>
                      <FormHelperText error>
                        {(errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].targetWarehouse &&
                          errors.lineItems[index].targetWarehouse.message)}
                      </FormHelperText>
                    </TableCell> */}
                     <TableCell align="right">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({})}
                          size="small"
                          name={`lineItems[${index}].availableQty`}
                          defaultValue={props.form.data.lineItems && (props.form.data.lineItems)[index] ? (props.form.data.lineItems)[index].availableQty : contact.availableQty} 
                          disabled={true}
                          variant="standard"
                        />
                      </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        inputProps={{ style: { textAlign: 'right' } }}
                        size="small"
                        inputRef={register({})}
                        name={`lineItems[${index}].uom`}
                        defaultValue={contact.uom}
                        variant="standard"
                        fullWidth
                      />
                    </TableCell>
                    {(!props.readOnly) && <TableCell align="left" style={{ width: '12%' }}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          insert(index + 1, {
                            contactId: "",
                            productId: "",
                            productCode: "",
                            requiredQty: "",
                            availableQty: "",
                            negotiatedPrice: "",
                            discountPct: "",
                            isQtyDecimal: false,
                          });
                          setErrQtyProd([]);
                        }
                        }
                      >
                        <AddIcon color="secondary" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          remove(index);
                          setErrQtyProd([]);
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </TableCell>
                    }
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={selectProduct.open}
          onClose={() => {
            setSelectProduct({ ...selectProduct, open: false });
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item container xs={9} alignItems="center">
                <Grid item>
                  Select Product
                </Grid>
              </Grid>
              <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setSelectProduct({ ...selectProduct, open: false });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent >
            <SearchProduct
              form={selectProduct}
              setValue={setValue}
              getValues={getValues}
              exist={true}
              isUOMNeeded={true}
              lineIdentifier={true}
              isMaterialReq={true}
              addonData={{ customerType: props.customerTypeId, locationId: corporateLocationId }}
              cancel={() => {
                setSelectProduct({ ...selectProduct, open: false });
              }}
              success={() => {
                setSelectProduct({ ...selectProduct, open: false, success: true });
              }}
            />
          </DialogContent>
        </Dialog>
      </div>

      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justifyContent={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        {(!props.readOnly) && <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSubmit((data) => { onSubmit(data) })}
            >
              Save
            </Button>
          </Grid>
        </Grid>}
      </Grid>
      <input type="hidden" name="id" ref={register()} />

    </form>
  );
}

export default PurchaseForm;
