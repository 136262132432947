import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  Snackbar,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router-dom";
import { supplySubTypes, vehicleTypes, supplyTypes, transportModes } from "../common/constants"

let errorMsg;

function CustomersForm(props) {
  const { id } = useParams();
  const history = useHistory();
  const [ eWayBillData, setEWayBillData ] = useState((props.eWayBillData)?props.eWayBillData:{});
  let { transporterId,transporterName, distance, transporterDocNo, transporterDocDate, vehicleNumber, vehicleType, transportMode, supplyType, supplySubType} = (eWayBillData)?eWayBillData:props.eWayBillData;
  const [isError, setIsError] = useState(false);
  const { handleSubmit, errors, register, getValues} = useForm({});
  const [ create, setCreate ] = React.useState({});
  const [errTransportMode, setErrTransportMode] = React.useState(false);
  const [errSupplyType, setErrSupplyType] = React.useState(false);
  const [errSupplySubType, setErrSupplySubType] = React.useState(false);
  const [isVehicleNoRequired, setVehicleNoRequired] = React.useState(false);
  const [isVehicleNoPt, setVehicleNoPt] = React.useState(false);
  const [isVehicleTypeRequired, setVehicleTypeRequired] = React.useState(false);
  const [isTranIdRequired, setTranIdRequired] = React.useState(false);
  const [isTranNameRequired, setTranNameRequired] = React.useState(false);
  const [isTransDocDateRequired, setTransDocDateRequired] = React.useState(false);
  const [isTranDocNoRequired, setTranDocNoRequired] = React.useState(false);

  useEffect(()=> {
    if(props.eWayBillData){
      let eWayBillData = props.eWayBillData;
      setEWayBillData(eWayBillData);
    }
  }, [props.eWayBillData]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async () => {
    setErrTransportMode((eWayBillData.transportMode)?false:true);
    setErrSupplyType((eWayBillData.supplyType)?false:true);
    setErrSupplySubType((eWayBillData.supplySubType)?false:true);
    if(eWayBillData.transportMode && eWayBillData.supplyType && eWayBillData.supplySubType){
      if(eWayBillData.transportMode==="1"){
        let isVehicleNum = false;
        if(eWayBillData.vehicleNumber){
          if (/^[0-9a-zA-Z]+$/.test(eWayBillData.vehicleNumber)) {
            isVehicleNum = true;
            setVehicleNoPt(false);
            setVehicleNoRequired(false);
          }else{
            setVehicleNoPt(true);
            setVehicleNoRequired(true);
          }
        }else{
          setVehicleNoPt(false);
          setVehicleNoRequired(true);
        } 
        setVehicleTypeRequired((eWayBillData.vehicleType)?false:true);
        setTranIdRequired(false);
        setTranNameRequired(false);
        setTransDocDateRequired(false);
        setTranDocNoRequired(false);
        if(eWayBillData.vehicleNumber && eWayBillData.vehicleType && isVehicleNum){
          let editCust = {
            transporterName: eWayBillData.transporterName,
            transporterId: eWayBillData.transporterId,
            distance: eWayBillData.distance,
            transporterDocNo: eWayBillData.transporterDocNo, 
            transporterDocDate: eWayBillData.transporterDocDate, 
            vehicleNumber: eWayBillData.vehicleNumber, 
            vehicleType: eWayBillData.vehicleType, 
            transportMode: eWayBillData.transportMode,
            supplySubType:eWayBillData.supplySubType,
            supplyType:eWayBillData.supplyType
          }
          props.onEditDataSubmit(editCust);
          if(getValues){}
        }
      }else{
        setVehicleNoRequired(false);
        setVehicleTypeRequired(false);
        setTranIdRequired((eWayBillData.transporterId)?false:true);
        setTranNameRequired((eWayBillData.transporterName)?false:true);
        setTransDocDateRequired((eWayBillData.transporterDocDate)?false:true);
        setTranDocNoRequired((eWayBillData.transporterDocNo)?false:true);
        if(eWayBillData.transporterId && eWayBillData.transporterName && eWayBillData.transporterDocDate && eWayBillData.transporterDocNo){
          let editCust = {
            transporterName: eWayBillData.transporterName,
            transporterId: eWayBillData.transporterId,
            distance: eWayBillData.distance,
            transporterDocNo: eWayBillData.transporterDocNo, 
            transporterDocDate: eWayBillData.transporterDocDate, 
            vehicleNumber: eWayBillData.vehicleNumber, 
            vehicleType: eWayBillData.vehicleType, 
            transportMode: eWayBillData.transportMode,
            supplySubType:eWayBillData.supplySubType,
            supplyType:eWayBillData.supplyType
          }
          props.onEditDataSubmit(editCust);
          if(getValues){}
        }
      } 
    }
  };
  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
       
     
        <Grid item container md={12} xs={12} spacing={1}>
        <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Transporter Name</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Transporter GSTIN</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Distance*</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.transporterName)|| isTranNameRequired}
              inputRef={(isTranNameRequired)?register({
                required: "Trasport Name required",
                // pattern: {
                //   value: /^[a-zA-Z ]{5,}$/,
                //   message: "Minimum 5 letters required. No digits.",
                // },
              }):register({})}
              InputProps={{
                autoFocus: true,
                name: "transporterName",
              }} value={transporterName}
              onChange = {e => setEWayBillData({ ...eWayBillData, transporterName: e.target.value})}
              size="small"
              name="transporterName"
            />
            <FormHelperText error={Boolean(errors.transporterName) || isTranNameRequired}>
            {(Boolean(errors.transporterName) || isTranNameRequired)?"Transporter Name required":null}
            </FormHelperText>
          </Grid>
          <Grid item md={4} xs={4}>
                <TextField variant="outlined" fullWidth error={Boolean(errors.transporterId) || isTranIdRequired}
                  onChange = {e => setEWayBillData({ ...eWayBillData, transporterId: e.target.value})}
                    // type="number" 
                    inputRef={(isTranIdRequired || (eWayBillData.transporterId && eWayBillData.transporterId.length>0))?register({
                      required: "Valid GSTIN is required",
                      pattern: {
                        value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                        message: "Valid GSTIN is required",
                      },
                    }):register({})} value={transporterId} size="small" name="transporterId" />
              <FormHelperText error={isTranIdRequired || Boolean(errors.transporterId)}>
              {(isTranIdRequired || Boolean(errors.transporterId))?"Valid GSTIN is required":null}
            </FormHelperText>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              defaultValue={distance}
              fullWidth
              error={Boolean(errors.distance)}
              type="number"
              inputRef={register({
                required: "Distance is required",
                pattern: {
                  value:/^[1-9]+[0-9]*$/,
                  message: "Distance is required",
                },
              })} 
              value={distance}
              onChange = {e => setEWayBillData({ ...eWayBillData, distance: e.target.value})}
              size="small"
              name="distance"
            />
            <FormHelperText error>
              {errors.distance && errors.distance.message}
            </FormHelperText>
          </Grid>
       </Grid>
       <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Dispatch Through / Transport Mode *</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Dispatch Doc No. / Transport Document No.</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Document Date</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
          >
            <Select
              name="transportMode"
              value={transportMode}
              onChange = {e => setEWayBillData({ ...eWayBillData, transportMode: e.target.value})}
              variant="outlined"
              required={true}
              error={errTransportMode}
            >
              {transportModes.map(function (transportModeData,index) {
                return (
                  <MenuItem key={index} value={transportModeData.id}>
                    {transportModeData.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {errTransportMode && 
          <FormHelperText style={{color:'red'}}>
                  Transport Mode is required
            </FormHelperText>}
          </Grid>
          <Grid item md={4} xs={4}>
               <TextField variant="outlined" value={transporterDocNo}
                 size="small" name="transporterDocNo"
                 onChange = {e => setEWayBillData({ ...eWayBillData, transporterDocNo: e.target.value})}
                 error={Boolean(errors.transporterDocNo)}
                 fullWidth 
                 inputRef={isTranDocNoRequired?register({
                  required: "Trasport document number is required",
                    // pattern: {
                    //   value: /^[a-zA-Z ]{5,}$/,
                    //   message: "Minimum 5 letters required. No digits.",
                    // },
                  }):register({})}
                 />
           <FormHelperText error={isTranDocNoRequired || Boolean(errors.transporterDocNo)}>
             {(isTranDocNoRequired || Boolean(errors.transporterDocNo))?"Transporter Document number is required":null}
           </FormHelperText>
         </Grid>
          <Grid item md={4} xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                  error={isTransDocDateRequired}
                    size="small"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    name="transporterDocDate"
                    helperText=""
                    value={transporterDocDate?transporterDocDate:null}
                    onChange = {value => setEWayBillData({ ...eWayBillData, transporterDocDate: value})}
                  />
                </MuiPickersUtilsProvider>
                {isTransDocDateRequired && 
          <FormHelperText style={{color:'red'}}>
                  Transport document date required
            </FormHelperText>}
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Vehicle Type</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Vehicle No.</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Supply Type</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="vehicleType"
                value={vehicleType}
                onChange = {e => setEWayBillData({ ...eWayBillData, vehicleType: e.target.value})}
                variant="outlined"
              >
                {vehicleTypes.map(function (vehicleTypeData, index) {
                  return (
                    <MenuItem key={index} value={vehicleTypeData.id}>
                      {vehicleTypeData.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {isVehicleTypeRequired && 
            <FormHelperText style={{color:'red'}}>
                    Vehicle type is required
              </FormHelperText>}
          </Grid>
          <Grid item md={4} xs={4}>
               <TextField variant="outlined" value={vehicleNumber}
                 size="small" name="vehicleNumber"
                 onChange = {e => setEWayBillData({ ...eWayBillData, vehicleNumber: e.target.value})}
                 error={Boolean(errors.vehicleNumber)}
                 fullWidth 
                 inputRef={isVehicleNoRequired?register({
                  required: "Enter valid vehicle number",
                    // pattern: {
                    //   value: /^[a-zA-Z0-9]$/,
                    //   message: "Enter valid vehicle number",
                    // },
                  }):register({})}
                 />
           <FormHelperText error={isVehicleNoRequired || Boolean(errors.vehicleNumber)}>
             {(isVehicleNoRequired || Boolean(errors.vehicleNumber))?(isVehicleNoPt)?"Enter valid Vehicle number":"Vehicle Number is required":null}
           </FormHelperText>
         </Grid>
          <Grid item md={4} xs={4}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="supplyType"
                value={supplyType}
                onChange = {e => setEWayBillData({ ...eWayBillData, supplyType: e.target.value})}
                variant="outlined"
                error={errSupplyType}
                inputRef={register({
                  required: "Supply Type is required"
                })}
              >
                {supplyTypes.map(function (supplyTypeData, index) {
                  return (
                    <MenuItem key={index} value={supplyTypeData.name}>
                      {supplyTypeData.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormHelperText error={errSupplyType}>
              {errSupplyType && "Suppy Type is Required"}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Supply Sub-Type</Typography>
          </Grid>
          <Grid item md={4} xs={4}></Grid>
          <Grid item md={4} xs={4}></Grid>
          <Grid item md={4} xs={4}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="supplySubType"
                value={supplySubType}
                onChange = {e => setEWayBillData({ ...eWayBillData, supplySubType: e.target.value})}
                variant="outlined"
                error={errSupplySubType}
                inputRef={register({
                  required: "Supply Sub-Type is required"
                })}
              >
                {supplySubTypes.map(function (supplySubTypeData, index) {
                  return (
                    <MenuItem key={index} value={supplySubTypeData.name}>
                      {supplySubTypeData.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormHelperText error={errSupplySubType}>
              {errSupplySubType && "Supply Sub-Type is required"}
            </FormHelperText>
          </Grid>
          <Grid item md={4} xs={4}></Grid>
          <Grid item md={4} xs={4}></Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={6}
          justify={"flex-start"}
        >
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button size="small" type="submit"
              variant="contained" color="primary" >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => props.onEditCancel ? props.onEditCancel():history.push("/recordSales")}
              color="secondary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
    <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={() => setIsError(false)}
        >
          <MuiAlert
            onClose={() => setIsError(false)}
            elevation={6}
            variant="filled"
            severity="error"
          >
            Some error occured. Please retry after sometime.
        </MuiAlert>
        </Snackbar>
    <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id?"Customer updated successfully"
            : "Customer created successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default CustomersForm;
