import React, { useEffect, useState } from "react";
import {
  Typography,
  FormHelperText,
  TextField,
  Button,
  Grid,
  Box,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API_ALL_PRODUCTS } from "../../config/constants";

const Calculator = (props) => {
  const [total, setTotal] = useState('');
  const [onPrice, onSetPrice] = useState(0);
  const [onMRP, onSetMRP] = useState(0);
  const [productIGST, setProductIGST] = useState(null);
  const [productSGST, setProductSGST] = useState(null);
  const [productCGST, setProductCGST] = useState(null);
  const token = localStorage.getItem("authToken");
  
  const { handleSubmit, errors , register} = useForm({
    defaultValues: {
      price: onPrice,
      mrp:onMRP,
      discount: props.priceData && props.priceData.discount ? (Math.round(props.priceData.discount * 100) / 100).toFixed(2):'0.00',
      igst: productIGST ? productIGST : props.priceData && props.priceData.igst ? props.intraState ? (Math.round(props.priceData.igst * 100) / 100).toFixed(2):'0.00':'0.00',
      sgst: productSGST ? productSGST : props.priceData && props.priceData.sgst ? !(props.intraState) ? (Math.round(props.priceData.sgst * 100) / 100).toFixed(2):'0.00':'0.00',
      cgst: productCGST ? productCGST : props.priceData && props.priceData.cgst ? !(props.intraState )? (Math.round(props.priceData.cgst * 100) / 100).toFixed(2):'0.00':'0.00',
      qty: props.priceData && props.priceData.qty ?props.priceData.qty:'0',
      total: ''
    }
  })
  const handleCalculate = (data) => {
    let { price, mrp, discount, igst, sgst, cgst, qty } = data;
     if(mrp && mrp >0){
      let taxRate = parseFloat(sgst)+parseFloat(cgst)+parseFloat(igst);
      let taxAmt =  mrp * taxRate/(100 + taxRate)
      let tempPrice = mrp - taxAmt;
      onSetPrice(tempPrice && (Math.round(tempPrice * 100) / 100).toFixed(2));
      price = tempPrice;
    }else if(price && price >0){
      let sgstTemp = (price * sgst) / 100;
      let cgstTemp = (price * cgst) / 100;
      let igstTemp = (price * igst) / 100;
      let tempMRP = parseFloat(price) + parseFloat(sgstTemp) + parseFloat(cgstTemp) + parseFloat(igstTemp);
      onSetMRP(tempMRP && (Math.round(tempMRP * 100) / 100).toFixed(2));
    }

    let discountedPrice = (price * discount) / 100;
    let subPrice = price - discountedPrice;
    let qtyPrice = subPrice && ((Math.round(subPrice * 100) / 100).toFixed(2)) * qty;
    let sgstVal = (qtyPrice * sgst) / 100;
    let cgstVal = (qtyPrice * cgst) / 100;
    let igstVal = (qtyPrice * igst) / 100;
    let totalVal = qtyPrice + sgstVal + cgstVal + igstVal;
    setTotal(totalVal && (Math.round(totalVal * 100) / 100).toFixed(2))
  }

  const handleClose = () => {
    props.cancel();
  }

  const loadData = async () => {
    if(props.priceData.productId){
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${props.priceData.productId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setProductIGST(result.data && result.data.IGST ? props.intraState ? (Math.round(result.data.IGST * 100) / 100).toFixed(2):'0.00':'0.00');
      setProductSGST(result.data && result.data.SGST ? !(props.intraState) ? (Math.round(result.data.SGST * 100) / 100).toFixed(2):'0.00':'0.00');
      setProductCGST(result.data && result.data.CGST ? !(props.intraState) ? (Math.round(result.data.CGST * 100) / 100).toFixed(2):'0.00':'0.00');
    } catch (err) {
      console.log(err);
    }
  }
  };
  
  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {props.priceData && props.priceData.productName && <Grid container>
        <Grid item md={12} xs={12} style={{paddingBottom:"10px"}}>
          <Typography variant="label">{props.priceData.productName}</Typography>
        </Grid>
      </Grid>}
      <Grid item container md={12} xs={12} spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography variant="caption">MRP (Rate inclusive of Tax) Per Unit</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            name="mrp"
            // disabled={(onPrice && onPrice >0)?true:false}
            inputProps={{ style: { textAlign: 'right' } }}
            inputRef={register()}
            value={onMRP}
            onChange={(e)=>{onSetMRP(e.target.value)}}
          /></Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="caption">Price (Rate exclusive of Tax) Per Unit</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            name="price"
            disabled={(onMRP && onMRP >0)?true:false}
            inputProps={{ style: { textAlign: 'right' } }}
            inputRef={register()}
            value={onPrice}
            onChange={(e)=>{onSetPrice(e.target.value)}}
          /></Grid>    
        <Grid item container md={6} xs={12}>
          <Typography variant="caption">Quantity</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            // type="number"
            name="qty"
            error={Boolean(errors.qty)}
            inputProps={{ style: { textAlign: 'right' } }}
            inputRef={register(
              {
                required: "Quantity is required",
                pattern: {
                  required: "Enter quantity",
                  value:  /^[0-9]*.[0-9]{0,3}$/,
                  message: "Provide a valid quantity",
                },
              }
            )}
          />
          <FormHelperText error>
            {errors.qty && errors.qty.message}
          </FormHelperText>
        </Grid>
        <Grid item container md={6} xs={12}>
          <Typography variant="caption">IGST</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            name="igst" value={productIGST}
            inputProps={{ style: { textAlign: 'right' } }}
            inputRef={register()}
            disabled
          /></Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="caption">Discount</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            name="discount"
            error={Boolean(errors.discount)}
            inputProps={{ style: { textAlign: 'right' } }}
            inputRef={register({
              required: "Discount is required",
              pattern: {
                required: "Enter discount",
                value: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/,
                message: "Provide a valid discount rate",
              },
            })}
          />
          <FormHelperText error>
            {errors.discount && errors.discount.message}
          </FormHelperText>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="caption">CGST</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            name="cgst" value={productCGST}
            inputProps={{ style: { textAlign: 'right' } }}
            inputRef={register()}
            disabled
          /></Grid>
        <Grid item container md={6} xs={12}>
          <Typography variant="caption">Total</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            name="details"
            inputProps={{ style: { textAlign: 'right' , fontWeight: 'bold', color:"#0C4B60"} }}
            value={total}
            disabled
          /></Grid>
        <Grid item container md={6} xs={12}>
          <Typography variant="caption">SGST</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            name="sgst" value={productSGST}
            inputProps={{ style: { textAlign: 'right' } }}
            inputRef={register()}
            disabled
          /></Grid>
        <Grid item md={12} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Box mr={2} mt={4}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSubmit(handleCalculate)}
              >
                Calculate
            </Button></Box>
            <Box mt={4}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
            </Button></Box>
          </Box></Grid>
      </ Grid>
    </div>
  );
};

export default Calculator;
