import React, { useState, useRef, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { renderToString } from 'react-dom/server'
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Snackbar,
  CircularProgress
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CompToPrintInv from './CompToPrintCreditNote';
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { currentUser } from "../login/loginSlice";
import { locations, bankDetails, termsAndConditions } from "../common/lookupSlice"
import { useForm, useFieldArray } from "react-hook-form";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import calculateIcon from '../../images/calculate.svg';
import calculateDisabledIcon from '../../images/calculator_disabled.svg';
import infoIcon from '../../images/info.svg';
import infoIconDisabled from '../../images/infoDisabled.svg';
import axios from "axios";
/* import Payment from '../RecordSaleNew/payment'; */
import Calculator from '../recordSales/calculator';
import PriceDetermine from '../recordSales/priceDetermination';
// import Discount from './discounts';
// import ErrorLists from './errorLists';
import CustomerInvSelect from './customerInvSelect';
import ConfirmationDialog from "../common/ConfirmationDialog";
import {
  openModal,
  closeModal,
  modalInProcessing
} from "../common/confirmationDialogSlice";
import moment from 'moment';
// import "./recordSale.css";


const CreditNoteComp = (props) => {
  const loggedInUser = useSelector(currentUser);
  const orgBankDetails = useSelector(bankDetails);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const { id } = useParams();
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAdmin =
    roles.findIndex((role) => role.name === "ADMIN" || role.name === "PRICING ADMIN") > -1 ? true : false;
  const componentRefInv = useRef();
  const buttonRef = useRef();
  var token = localStorage.getItem("authToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const allInventoryLocations = useSelector(locations);
  const orgName = loggedInUser && loggedInUser.org && loggedInUser.org.name;
  const inventoryLocations = allInventoryLocations.filter((loc) => { return loc.type && loc.type.code !== "CORPORATE" && loc.GSTIN });
  const defaultLoc = inventoryLocations.find(loc => loc.isPrimary === true);
  const initialLoc = (defaultLoc) ? defaultLoc.id : inventoryLocations && inventoryLocations.length > 0 && inventoryLocations[0].id;
  const [store, setStore] = useState(null);
  const [costCenterName, setCostCenterName] = useState(null);
  const [locName, setLocName] = useState('');
  const [shipLoc, setShipLoc] = useState(initialLoc);
  const [shipLocName, setShipLocName] = useState('');
  const [customerInv, setCustomerInv] = useState('');
  const [calculate, setCalculate] = useState(null);
  const [payments, setPayments] = useState({ open: false });
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [isSaleWarning, setIsSaleWarning] = useState(false);
  // const [bankList, setOrgBankListData] = useState([]);
  const [bankName, setBankName] = useState('');
  const [bankId, setBank] = useState("");
  const [safetyStockWarning, setSafetyStockWarning] = useState(false);
  const [showPo, setShowSPo] = useState(false);
  const [isSendInvoice, setSendInvoice] = useState(false);
  const [selectedInvData, setSelectInvData] = useState('');
  const [remarkNote, setInvoiceRemark] = useState("")
  const [isSaleMsgWarning, setIsSaleMsgWarning] = useState(false);
  // const [paymentDetails, setPaymentDetails] = useState({}); 
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [emailSendData, setEmailSendData] = useState({
    emailId: '',
    emailSubject: ''
  });
  let { emailId, emailSubject } = emailSendData;
  const [invoiceNo, setInvoiceNo] = useState('');
  const [invoice, setInvoice] = useState([]);
  const [message, setMessage] = useState('');
  const [priceData, setPriceData] = useState({ open: false });
  const [productPriceData, setProductPriceData] = useState({ open: false });
  const [selectProduct, setSelectProduct] = useState({ open: false, test: null });
  const [dis, setDis] = useState([]);
  const [pro, setPro] = useState([]);
  const [selectedPo, setSelectedSPo] = useState({ invoiceNum: "", products: [] });
  const isPo = selectedPo.invoiceNum;
  const location = useLocation();
  const customerData = location.state;
  const customerId = customerData && customerData.id;
  let orgCode = customerData && customerData.orgCode;
  const [orgDetails, setOrgDetails] = useState(null);
  const [HSNCodeData, setHSNData] = useState([]);
  const [errHSNProIndex, setErrHSN] = useState([]);
  const [errQtyProIndex, setErrQtyProd] = useState([]);

  /* Payment Gateway Changes */
  // const payMethods = useSelector(paymentMethods);
  // const [paymentMethod, setPaymentMethod] = useState(null);
  // const [paymentMethodCode, setPaymentMethodCode] = useState(null);
  const [isConfirmBox, setIsConfirmBox] = React.useState(false);

  const onConfirmDialogBox = async (value, pData, returnResClback) => {
    if (customerData) {
      setIsLoading(true)
      dispatch(modalInProcessing());
      if (!selectedPo.invoiceNum) {
        dispatch(closeModal());
      } else {
        try {
          const clonedData = calculate ? _.cloneDeep(calculate) : {}
          //Below assignments needs to be updated
          const total = clonedData.total;
          const roundOff = total ? (Math.round((Math.round(total) - total) * 100) / 100).toFixed(2) : 0;
          clonedData.roundOff = Number(roundOff);
          let invNum = isPo ? selectedPo.invoiceNum : customerInv;
          if (invNum) {
            clonedData.invoiceId = invNum
          }
          clonedData.orgCode = customerData.orgCode;
          clonedData.customerId = (customerData.id) ? customerData.id : customerId;
          clonedData.locationId = store;
          clonedData.costCenter = costCenterName;
          clonedData.locationId = store;
          clonedData.orgBankId = bankId;
          clonedData.shipLocationId = shipLoc;
          clonedData.invoiceDate = moment().format('MM/DD/YYYY');
          clonedData.remarkNote = (remarkNote) ? remarkNote : "";
          clonedData.buyerDetails = (selectedInvData && selectedInvData.invCustmerDetails) ? selectedInvData.invCustmerDetails : selectedInvData && selectedInvData.customer;
          clonedData.sellerDetails = (selectedInvData && selectedInvData.invOrgDetails) ? selectedInvData.invOrgDetails : orgDetails;
          let result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/creditnote`,
            clonedData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const resStatus = result && result.data && result.data.status;
          if (resStatus === "SUCCESS") {
            let cnNum = result && result.data && result.data.creditNoteNum;
            if (value) {
              // let cnId = result && result.data && result.data.invoice && result.data.invoice.id
              //Generate e-Credit Note
              let eCreditNoteRes = await axios.post(
                `${process.env.REACT_APP_SERVICE_URL}/qrcode/creditnote/${cnNum}`,
                { creditNoteNum: cnNum },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              if (eCreditNoteRes && eCreditNoteRes.data && eCreditNoteRes.data.status && eCreditNoteRes.data.status === "SUCCESS") {
                setIsSuccess(true)
                setIsSaved(true)
                setMessage("e-Credit Note generated successfully")
                setInvoiceNo(cnNum)
                dispatch(closeModal());
              } else {
                setIsWarning(true)
                setIsSaved(true)
                setMessage("Credit Note created but Unble to generate e-Credit Note")
                setIsSaleMsgWarning(true);
                setInvoiceNo(cnNum)
                dispatch(closeModal());
              }
            } else {
              setIsSuccess(true)
              setIsSaved(true)
              setMessage("Credit Note created")
              setInvoiceNo(cnNum)
              dispatch(closeModal());
              returnResClback && returnResClback();
            }
          } else {
            setMessage(result && result.data && result.data.message)
            setIsError(true)
            dispatch(closeModal());
            setPayments({ ...payments, open: false });
          }
          setIsLoading(false);
        } catch (err) {
          console.log("err", err);
          setIsLoading(false);
          setMessage("Something went wrong please try again.")
          setIsError(true);
          dispatch(closeModal());
          setPayments({ ...payments, open: false });

        }
      }

    } else {
      dispatch(closeModal());
    }
  }

  const handleCheckout = async () => {
    if (selectedInvData && selectedInvData.eInvIRN && selectedInvData.eInvIRN.length > 0) {
      setIsConfirmBox(true)
      const dialogText = "Please confirm the e-Credit Note current Credit Note?";
      dispatch(
        openModal({
          dialogText: dialogText,
          secondayBtnText: "Yes",
          primaryBtnText: "No",
          handleCloseModal: onConfirmDialogBox,
        })
      );
    } else {
      onConfirmDialogBox();
    }
  }
  const { handleSubmit, control, errors, reset, register, setValue, getValues } = useForm({
    defaultValues: {
      sales: selectedPo.products ? selectedPo.products : [],
    },
  });

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "sales",
  });

  useEffect(() => {
    if (invoiceNo) {
      buttonRef && buttonRef.current && buttonRef.current.click()
    }
  }, [invoiceNo]);

  // useEffect(() => {
  //   reset();
  //   setSelectedSPo({ invoiceNum: "", products: [] })
  // }, [store]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedPo && selectedPo.invoiceNum) {
      setCalculate(null);
      setDis([])
      setPro([])
      append(selectedPo.products);
    }
  }, [selectedPo]); // eslint-disable-line react-hooks/exhaustive-deps


  const loadData = async () => {
    // let count = 0;
    try {
      const hsncoderes = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/hsncode`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (hsncoderes.status === 200) {
        if (hsncoderes.data && hsncoderes.data.length > 0) {
          let hsnCodes = [];
          await hsncoderes.data.map((hsnData) => {
            hsnCodes.push(hsnData.code);
            return hsnData;
          })
          setHSNData(hsnCodes);
        }
      }
      const resultBankDetails = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/bankdetials`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      let orgBankList = (resultBankDetails.data && resultBankDetails.data.bankDetails && resultBankDetails.data.bankDetails.length > 0) ? resultBankDetails.data.bankDetails : [];
      // setOrgBankListData(orgBankList);
      const defaultBank = orgBankList.find(bank => bank.isPrimary === true);
      const initialBank = (defaultBank) ? defaultBank.id : orgBankList && orgBankList.length > 0 && orgBankList[0].id;
      setBank(initialBank);
      if (id) {
        const result2 = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/creditnote/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLocName(result2.data.location?.name);
        setShipLocName(result2.data.shipToLocation?.name);
        setBankName(result2.data.companyBankdetails?.bankName);
        result2.data && result2.data.creditNoteNum && setCustomerInv(result2.data.creditNoteNum);
        const newlineItems = result2.data.lineItems;

        setInvoiceRemark(result2 && result2.data && result2.data.remarkNote)
        /* setPaymentDetails(result2.data.payments && result2.data.payments[0]) */
        // eslint-disable-next-line 
        // console.log("newlineItems",newlineItems);
        newlineItems.map((x) => {
          // count = (x.product.inventories.openingQty < 1 || !(x.product.inventories && x.product.inventories.openingQty)) && count++;
          append({
            productId: x.product.id,
            productName: x.product.productName,
            products: [{ ...x.product }],
            HSNCode: (x.HSNCode) ? x.HSNCode : x.product && x.product.HSNCode,
            qty: x.qty,
            rate: (x.productPrice) ? (Math.round(x.productPrice * 100) / 100).toFixed(2) : 0.00,
            disc: x.overridenDiscountPcnt > 0 ? x.overridenDiscountPcnt : 0,
            avail: result2.data.invoiceNum ? ((x.product.inventories && x.product.inventories.openingQty) ? (x.product.inventories.openingQty + x.qty) : (0 + x.qty)) :
              x.product.inventories.openingQty,
            uom: x.product.uom,
            isQtyDecimal: x.product.isQtyDecimal
          })
          return x
        })
      }
    }
    catch (err) {
      setIsError(true);
    }
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const loadOrgData = async (code) => {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/org/${code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setOrgDetails(result.data);
        // let sellerData = {
        //   GSTIN: result.data && result.data.GSTIN,
        //   tradeName: result.data && result.data.name,
        //   address1: result.data && result.data.address1,
        //   address2: result.data && result.data.address2,
        //   address3: null,
        //   locality: result.data && result.data.address3,
        //   pincode: result.data && result.data.pincode,
        //   state: result.data && result.data.state,
        //   phoneNo: result.data && result.data.phoneNo,
        //   email: result.data && result.data.email
        // };
        // setSellerDetails(sellerData);

      }
    };

    loadOrgData(orgCode);
  }, [orgCode, customerData, token, id]);


  const getCustomerInvoice = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/creditnote/${invoiceNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sales = result.data && result.data;
      return sales;
    }
    catch (err) {
      setIsError(err)
    }
  }

  const handleInvoicePrint = async () => {
    try {
      const data = await getCustomerInvoice();
      setInvoice(data);
    } catch (err) {
      setIsError(true);
    }
  }

  const openEmailInovice = async () => {
    try {
      const data = await getCustomerInvoice();
      setInvoice(data);
      setSendInvoice(true);
      setEmailSendData({
        emailSubject: `${orgName} - Credit Note`,
        emailId: customerData && customerData.email
      })
    } catch (err) {
      setIsError(true);
    }
  }
  const onCloseSendInvoice = () => {
    setSendInvoice(false);
    setEmailSendData({ emailId: '', emailSubject: '' })
  }
  const onSendEmailInvoice = async () => {
    let isEmail = false;
    let isSubject = (emailSubject && emailSubject.length > 1) ? true : false;
    if (emailId && emailId.length > 0) {
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds, (data) => {
        if (!mailformat.test(String(data).toLowerCase())) {
          isEmail = false;
        }
      })
    }
    if (isEmail && isSubject) {
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData = () => {
        return (
          <CompToPrintInv ref={componentRefInv} customerInv={invoice} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
        )
      }
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to', emailId)
      bodyFormData.append('subject', emailSubject)
      bodyFormData.append('invoice', renderToString(invoiceBodyData()))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
        bodyFormData,
        config
      );
      if (result.status === 200) {
        setIsSuccess(true)
        setMessage(result.data && result.data.message)
        setSendInvoice(false);
        setEmailSendData({ emailId: '' })
      }
    } else {
      if (!isEmail) {
        setEmailHelperText(true);
      }
      if (!isSubject) {
        setEmailSubjectHelperText(true);
      }
    }
  }
  const onCalculateClick = (index) => {
    const values = getValues({ nest: true })["sales"];
    const rowValues = values[index];
    setPriceData({ ...priceData, productId: rowValues.productId, productName: rowValues.productName, amount: rowValues.rate, qty: rowValues.qty, discount: rowValues.disc, open: true });
    // setStore(initialLoc)
  }

  const onInfoClick = (index) => {
    setProductPriceData({ ...productPriceData, pricingInfo: productPriceData[index] && productPriceData[index].pricingInfo && productPriceData[index].pricingInfo[0], open: true });
  }

  const onSubmit = async (data) => {
    // let count = 0;
    try {
      const req = {
        customerId: (customerData.id) ? customerData.id : customerId,
        locationId: store
      }
      let errHSN = [];
      let errqtyProd = [];
      const lineItems = data && data.sales && data.sales.map((x, y) => {
        let isErr = (_.includes(HSNCodeData, x.HSNCode)) ? false : true;
        let isQtyErr = false;
        if (x.isQtyDecimal === "false") {
          if (!(Number.isInteger(parseFloat(x.qty)))) {
            isQtyErr = true;
          }
        }
        if (isErr) {
          errHSN.push(y);
        }
        if (isQtyErr) {
          errqtyProd.push(y)
        }
        // (x.avail <= 0) && count++;
        let item = {
          productId: x.productId && Number(x.productId),
          qty: x.qty && Number(x.qty),
          overridenHSNCode: x.HSNCode && Number(x.HSNCode),
        }
        if (selectedPo.invoiceNum) {
          item.overridenUnitPrice = x.HSNCode && parseFloat(x.rate);
          item.overridenDiscountPcnt = x.HSNCode && parseFloat(x.disc);
        }
        if (!(selectedPo.invoiceNum)) {
          // pro[y] === x.rate || (pro.length === 0 && x.actualRate === x.rate) ?
          //   x.actualRate !== x.rate ? item.overridenUnitPrice = Number(x.rate) : "" :
          //   item.overridenUnitPrice = Number(x.rate)
          if (pro[y] === x.rate || (pro.length === 0 && x.actualRate === x.rate)) {
            if (x.actualRate !== x.rate) {
              item.overridenUnitPrice = Number(x.rate)
            }
          }
          else {
            item.overridenUnitPrice = Number(x.rate)
          }

          // dis[y] === x.disc || (dis.length === 0 && x.disc === "0.00") ?
          //   x.disc !== "0.00" ? item.overridenDiscountPcnt = Number(x.disc) : "" :
          //   item.overridenDiscountPcnt = Number(x.disc)

          if (dis[y] === x.disc || (dis.length === 0 && x.disc === "0.00")) {
            if (x.disc !== "0.00") {
              item.overridenDiscountPcnt = Number(x.disc)
            }
          }
          else {
            item.overridenDiscountPcnt = Number(x.disc)
          }
        }
        return item;
      })
      req.lineItems = lineItems;
      (selectedPo.invoiceNum) && (req.invoiceNum = selectedPo.invoiceNum);
      if (errqtyProd.length === 0) {
        if (errHSN.length === 0) {
          setErrHSN([]);
          setErrQtyProd([]);
          req.customerState = customerData && customerData.stateCode;
          const result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
            req,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const cloneDis = _.cloneDeep(dis)
          const clonePro = _.cloneDeep(pro)
          const salesValue = result.data.lineItems.map((x, y) => {
            if (!(cloneDis[y])) {
              cloneDis[y] = x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00'
            }
            if (!(clonePro[y])) {
              clonePro[y] = x.unitPrice ? (Math.round(x.unitPrice * 100) / 100).toFixed(2) : '0.00'
            }
            return {
              productId: x.productId,
              productName: x.productName,
              HSNCode: (x.HSNCode) ? x.HSNCode : x.product && x.product.HSNCode,
              qty: x.qty,
              rate: x.unitPrice ? (Math.round(x.unitPrice * 100) / 100).toFixed(2) : '0.00',
              disc: x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00',
              uom: x.uom,
              amount: x.amount ? (Math.round(x.amount * 100) / 100).toFixed(2) : '0.00',
              actualDisc: dis[y] ? dis[y] : x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00',
              //actualRate: x.unitPrice ? (Math.round(x.salePrice * 100) / 100).toFixed(2) : '0.00',
            }
          })
          setDis(cloneDis)
          setPro(clonePro)
          setValue("sales", salesValue)
          setCalculate(result.data)
          setProductPriceData(result.data.lineItems)



        } else {
          setErrHSN(errHSN);
        }
      } else {
        setErrQtyProd(errqtyProd);
      }
    }
    catch (err) {
      setIsError(true)
    }
  }

  const handleClose = () => {
    reset();
    setCalculate(null);
    setDis([])
    setPro([])
    setSelectedSPo({ invoiceNum: "", products: [] })
  }

  let handleCancel = () => {
    window.location.href = `/customers?from=creditnote`;
  }

  const rateValidation = (val) => {
    const valid = Number(val) > 0;
    return valid;
  }

  const handleReset = (e) => {
    history.push({
      pathname: "/creditnote",
      state: customerData && customerData,
    });
    window.location.reload();
    // setQuery({ page: 1, pageSize: pageSize })
  }

  let pageHeader = "Credit Note";

  const saleVals = getValues({ nest: true })["sales"];
  return (
    customerData ? <Grid container xs={12} spacing={2}>
      <Grid item container xs={12} direction="column">
        <Grid item>
          <Box mt={2}>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              {isAdmin && <Link color="inherit" href="" onClick={() => history.push("/accountshome")}>
                Accounting
              </Link>}
              {isAdmin && <Link color="inherit" href="" onClick={() => history.push("/customers?from=creditnote")}>
                Customer
              </Link>}
              <Typography component="span" color="textPrimary">
                {pageHeader}
              </Typography>
            </Breadcrumbs>
          </Box>
        </Grid>
      </Grid>
      <Grid item container xs={4} md={4} style={{alignContent: "flex-start" }}>
        <Box style={{ fontWeight: "bold" }}>Customer Name And Address:
        </Box>
        <Box ml={2}>
          <Typography>{customerData && customerData.fullName}</Typography>
          <Typography>{customerData && customerData.address1}</Typography>
          <Typography>{customerData && customerData.address2}</Typography>
          <Typography>{customerData && customerData.address3}</Typography>
        </Box>
      </Grid>
      <Grid item container xs={4} md={4}  >
        <Grid item xs={12}>
          <Box><Typography style={{ fontWeight: "bold" }}>Mobile Number:&nbsp;&nbsp;</Typography><Typography>{customerData && customerData.mobileNumber}</Typography></Box>
        </Grid>
        <Grid item xs={12}>
          <Box><Typography style={{ fontWeight: "bold" }}>Email:&nbsp;&nbsp;</Typography><Typography>{customerData && customerData.email}</Typography></Box>
        </Grid>
        <Grid item xs={12} style={{paddingTop:"36px"}}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Cost Center</Box>
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                name: "locName",
              }} value={costCenterName}
              size="small"
              name="locName" disabled /></Typography>
        </Grid>
      </Grid>
      <Grid item container xs={4} md={4}  >
        <Grid item xs={12}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Billing Location</Box>
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                name: "locName",
              }} value={locName}
              size="small"
              name="locName" disabled /></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Receiving Location <span style={{ color: "red" }}>*</span></Box>
            {(id) ? shipLocName : <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="locality"
                value={shipLoc}
                disabled={id}
                onChange={(e) => {
                  setShipLoc(e.target.value);
                }}
                variant="outlined"
                style={{ width: "100%" }}
                fullWidth
              >
                {inventoryLocations.map(function (locality) {
                  return (
                    <MenuItem key={locality} value={locality.id}>
                      {locality.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Bank Details</Box>
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                name: "bankName",
              }} value={bankName}
              size="small"
              name="bankName" disabled /></Typography>
        </Grid>
      </Grid>

      {inventoryLocations.length > 0 ? <Grid container xs={12} spacing={2}>
        <Grid item container xs={6} md={6} justifyContent="flex-start" >
          <IconButton
            onClick={() => {
              if (!isSaved) {
                setShowSPo(true);
              }
            }} disabled={customerInv || id}
            style={{ backgroundColor: 'transparent' }} >
            <Typography style={{ fontWeight: "bold", color: "black", pointerEvents: "none" }}>Sale Invoice No:</Typography>
            &nbsp;
            <Typography>{customerInv ? customerInv : selectedPo.invoiceNum}</Typography>
            &nbsp;
            {!customerInv && <SearchIcon />}
          </IconButton></Grid>
        <Grid item container xs={6} md={6} justifyContent="flex-end" >

        </Grid>
      </Grid> : <Grid container xs={12} spacing={2} justifyContent="center">
        <Box mt={10} variant="subtitle1" style={{ fontWeight: "bold" }} component="span">
          You don't have access to store.Kindly contact your Admin.
        </Box>
      </Grid>}
      {inventoryLocations.length > 0 ? <Grid item container xs={12} direction="column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell align="left" style={{ width: "20%" }}>Product Name</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "10%" }}>HSN Code</StyledHeaderCell>
                  <StyledHeaderCell align="left">Qty</StyledHeaderCell>
                  <StyledHeaderCell align="left">Rate</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "10%" }}>Disc %</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "5%" }}>Available Qty</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "5%" }}>UOM</StyledHeaderCell>
                  <StyledHeaderCell align="left">Amount</StyledHeaderCell>
                  <StyledHeaderCell align="left"></StyledHeaderCell>
                </TableRow>
              </TableHead>
              {fields.length === 0 ? (
                <TableBody>
                  {((selectedPo.invoiceNum && selectedPo.products && selectedPo.products.length === 0) || (customerInv)) && <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="subtitle1">
                        Customer doesn't have eligible products for return
                      </Typography>
                    </TableCell>
                  </TableRow>}
                  {!customerInv && <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography variant="subtitle1">
                        Click Search Icon And Select Sale Invoice For Credit Note
                      </Typography>
                    </TableCell>
                  </TableRow>}
                </TableBody>
              ) : null}
              <TableBody>
                {fields.map((creditNoteData, index) => {
                  return (
                    <TableRow key={creditNoteData.id}>
                      <TableCell align="left">
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productName
                          )}
                          inputRef={register({
                            required: "Product is required",
                          })}
                          onClick={(e) => setSelectProduct({ ...selectProduct, open: true, lineKey: index, create: true })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                                {!(selectedPo.invoiceNum) && <SearchIcon onClick={(e) => setSelectProduct({ ...selectProduct, open: true })} />}
                              </InputAdornment>
                            ),
                            style: { fontSize: "14px" },
                          }}
                          size="small"
                          name={`sales[${index}].productName`}
                          style={{ fontSize: "14px !important" }}
                          multiline
                          minRows={1}
                          maxRows={3}
                          key={creditNoteData.id} disabled
                          defaultValue={selectedPo.products[index] ? selectedPo.products[index].productName : creditNoteData.productName ? creditNoteData.productName : ''}
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productId
                          )}
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].productId`}
                          key={creditNoteData.id} disabled
                          defaultValue={selectedPo.products[index] ? selectedPo.products[index].productId : creditNoteData.productId ? creditNoteData.productId : ''}
                        // defaultValue={props.form.data.sales && (props.form.data.sales)[index] ? (props.form.data.sales)[index].product && (props.form.data.sales)[index].product.id:''}
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productData
                          )}
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].productData`}
                          key={creditNoteData.id} disabled={selectedPo.invoiceNum}
                          defaultValue={selectedPo.products[index] ? selectedPo.products[index].productData : ''}
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productName &&
                            errors.sales[index].productName.message}
                        </FormHelperText>

                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          name={`sales[${index}].HSNCode`}
                          defaultValue={creditNoteData.HSNCode}
                          inputRef={register({
                            required: "HSN is required",
                          })}
                          disabled={true}
                          variant="standard"
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].HSNCode
                          ) || (_.includes(errHSNProIndex, index)) ? true : false}
                        />
                        <FormHelperText error={Boolean(
                          errors.sales &&
                          errors.sales[index] &&
                          errors.sales[index].HSNCode
                        ) || (_.includes(errHSNProIndex, index)) ? true : false}>
                          {((errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].HSNCode &&
                            errors.sales[index].HSNCode.message) || (_.includes(errHSNProIndex, index)) ? true : false) ? "Enter Valid HSN Code" : ""}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].qty
                          ) || (_.includes(errQtyProIndex, index)) ? true : false}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Quantity is required",
                            pattern: {
                              value: /^[0-9]*.[0-9]{0,3}$/,
                              message: "Quantity is required",
                            },
                          })}
                          onChange={(e) => {
                            const qty = e.target.value;
                            const saleVal = getValues({ nest: true })["sales"];
                            // if (saleVal[index] && qty >= Number(saleVal[index].unFulFilledQty)) {
                            //   const qtyKey = `sales[${index}].qty`;
                            //   setValue(qtyKey, Number(saleVal[index].unFulFilledQty))
                            // }
                            if (selectedPo.invoiceNum) {
                              const poItems = selectedPo && selectedPo.products;
                              const poQty = poItems && poItems[index] ? poItems[index].qty : "";
                              if (qty >= Number(poQty)) {
                                if (saleVal[index] && qty >= Number(saleVal[index].unFulFilledQty)) {
                                  const qtyKey = `sales[${index}].qty`;
                                  setValue(qtyKey, Number(saleVal[index].unFulFilledQty))
                                }
                                else {
                                  const qtyKey = `sales[${index}].qty`;
                                  setValue(qtyKey, Number(poQty))
                                }

                              }
                            }
                            setCalculate(null);
                          }}
                          disabled={isPo ? false : (isSaved) ? true : !(fields && fields[index] && fields[index].productId) && !(saleVals && saleVals[index] && saleVals[index].productId)}
                          size="small"
                          name={`sales[${index}].qty`}
                          defaultValue={creditNoteData.qty}
                          variant="standard"
                        />
                        <FormHelperText error={Boolean(
                          errors.sales &&
                          errors.sales[index] &&
                          errors.sales[index].qty
                        ) || (_.includes(errQtyProIndex, index)) ? true : false}>
                          {((errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].qty &&
                            errors.sales[index].qty.message) || (_.includes(errQtyProIndex, index)) ? true : false) ? "Enter Valid Qty" : ""}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].rate
                          )}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Rate is required",
                            pattern: {
                              value: /^(?:\d*\.\d{1,2}|\d+)$/,
                              message: "Provide valid rate",
                            },
                            validate: (value) => rateValidation(value) || 'Price should be greater than 0'
                          })}
                          disabled={(isPo || isSaved) ? true : !(fields && fields[index] && fields[index].productId) && !(saleVals && saleVals[index] && saleVals[index].productId)}
                          size="small"
                          onChange={() => { setCalculate(null); }}
                          name={`sales[${index}].rate`}
                          defaultValue={creditNoteData.rate}
                          variant="standard"
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].actualRate`}
                          key={creditNoteData.id} disabled
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].rate &&
                            errors.sales[index].rate.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].disc
                          )}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Discount is required",
                            pattern: {
                              value: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/,
                              message: "Provide a valid discount rate",
                            },
                          })}
                          size="small"
                          onChange={() => { setCalculate(null); }}
                          name={`sales[${index}].disc`}
                          disabled={(isPo || isSaved) ? true : !(fields && fields[index] && fields[index].productId) && !(saleVals && saleVals[index] && saleVals[index].productId)}
                          defaultValue={creditNoteData.disc}
                          variant="standard"
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].actualDisc`}
                          key={creditNoteData.id} disabled
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].disc &&
                            errors.sales[index].disc.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          disabled
                          size="small"
                          name={`sales[${index}].avail`}
                          defaultValue={creditNoteData.avail}
                          inputRef={register({
                          })}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          disabled
                          size="small"
                          name={`sales[${index}].uom`}
                          inputRef={register({
                          })}
                          defaultValue={creditNoteData.uom}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          disabled
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          name={`sales[${index}].amount`}
                          inputRef={register({
                          })}
                          defaultValue={creditNoteData.amount}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell style={{ display: 'none' }}>
                        <TextField
                          disabled
                          inputProps={{ style: { textAlign: 'right', display: 'none' } }}
                          inputRef={register({})}
                          size="small"
                          name={`sales[${index}].isQtyDecimal`}
                          defaultValue={
                            (creditNoteData &&
                              creditNoteData.isQtyDecimal) ? true : false
                          }
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell align="right" style={{ width: isPo ? '14%' : '16%' }}>
                        {(isPo || customerInv) ? "" :
                          <IconButton
                            size="small"
                            disabled={isSaved}
                            onClick={(e) => {
                              insert(index + 1, {
                                saleId: "",
                                productName: "",
                                HSNCode: "",
                                qty: "",
                                rate: "",
                                disc: "",
                                avail: "",
                                unFulFilledQty: "",
                                uom: "",
                                amount: "",
                                productId: "",
                                isQtyDecimal: false
                              });
                              setCalculate(null);
                              setErrHSN([]);
                              setErrQtyProd([]);
                            }
                            }
                          >
                            <AddIcon color={(isSaved) ? "" : "secondary"} />
                          </IconButton>}
                        {(isPo || customerInv) ? "" : <span>&nbsp;</span>}
                        <IconButton
                          size="small"
                          disabled={isSaved}
                          onClick={(e) => {
                            remove(index);
                            setCalculate(null);
                            setErrHSN([]);
                            setErrQtyProd([]);
                          }}
                        >
                          <DeleteIcon color={(isSaved) ? "" : "secondary"} />
                        </IconButton>
                        &nbsp;
                        <IconButton size="small" disabled={isSaved} onClick={() => onCalculateClick(index)}>
                          <img src={(isSaved) ? calculateDisabledIcon : calculateIcon} alt="history" />
                        </IconButton>
                        &nbsp;
                        <IconButton size="small" disabled={(!calculate || isSaved)} onClick={() => onInfoClick(index)}>
                          {(!calculate || isSaved) ? <img src={infoIconDisabled} alt="history" /> : <img src={infoIcon} alt="history" />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {fields.length !== 0 && !isSaved && <Grid item container xs={12} justifyContent="flex-end">
            <Box mt={2}>
              <Button
                size="small"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSaved}
                style={{ marginRight: "10px" }}
              >
                Calculate
              </Button>
              <Button
                disabled={isSaved}
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                Clear
              </Button>
            </Box>
          </Grid>}
        </form>


        {calculate && <Box mt={18}>
          <List dense={true} style={{ backgroundColor: "#F5F5F5", fontSize: "14px" }}>
            <ListItem alignItems="flex-start" divider={true} >
              <Grid item container xs={12}>
                <Grid item xs={11}>
                  Subtotal
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" justifyContent="flex-end"> {calculate.subtotal && (Math.round(calculate.subtotal * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start" divider={true}>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  GST
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start" divider={true}>

              <Grid item container xs={12} >
                <Grid item xs={11} >
                  <Box ml={2} >IGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box display="flex" justifyContent="flex-end">{calculate.IGST && (Math.round(calculate.IGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
                <Grid item xs={11} >
                  <Box mt={0.3} ml={2}>CGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box mt={1} display="flex" justifyContent="flex-end">{calculate.CGST && (Math.round(calculate.CGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
                <Grid item xs={11} >
                  <Box ml={2} >SGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box display="flex" justifyContent="flex-end">{calculate.SGST && (Math.round(calculate.SGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start">
              <Grid item container xs={12} style={{ fontWeight: "bold" }}>
                <Grid item xs={11}>
                  Total
                </Grid>
                <Grid item xs={1} style={{ borderLeft: "4px solid #FFFFFF" }}>
                  <Box display="flex" justifyContent="flex-end"> {calculate.total && (Math.round(calculate.total * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
          </List>
          <Box mt={2} style={{ fontSize: "12px" }}>{`Amount Chargeable(In words): ${calculate.amountInWords}`}</Box>
          {!isSaved && <Grid container spacing={2} alignItems="baseline" style={{ marginTop: "15px" }}>

            <Grid item container md={6} xs={12}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Notes</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  name="remarkNote"
                  inputProps={{ maxLength: 150 }}
                  placeholder="Notes..."
                  minRows={2}
                  maxRows={5}
                  size="small"
                  onChange={e => setInvoiceRemark(e.target.value)}
                  value={remarkNote}
                />
                <FormHelperText >
                  Textfield allow only 500 character.
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item container md={6} xs={12}>
            </Grid>
          </Grid>}
          {!isSaved && <Box mt={3} display="flex" justifyContent="flex-end">
            {isLoading && <CircularProgress />}
            <Button
              disabled={isLoading}
              onClick={handleCancel}
              color="secondary" size="small" variant="contained" style={{ marginRight: '10px' }}>
              Cancel</Button>
            <Button
              disabled={isLoading}
              onClick={handleCheckout}
              color="primary" size="small" variant="contained" style={{ marginRight: '10px' }}>
              Save</Button>
          </Box>}
          {isSaved && <Box mt={3}>
            {isSaleMsgWarning && <Typography style={{ color: "red" }} mt={2}>View e-Credit Note Error Report</Typography>}
          </Box>}
          {isSaved &&
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                onClick={handleReset}
                color="primary"
                size="small"
                variant="contained"
                style={{ marginRight: 10 }}
              >
                New Credit Note
              </Button>
              <ReactToPrint
                onBeforeGetContent={() => handleInvoicePrint(invoiceNo)}
                trigger={() => <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  style={{ marginRight: 10 }}
                >
                  PRINT INVOICE
                </Button>}
                content={() => componentRefInv.current} />
              <Button
                onClick={openEmailInovice}
                color="primary"
                size="small"
                variant="contained"
              >
                Email
              </Button>
            </Box>
          }
        </Box>}

      </Grid> : ""}
      {/* Confirm box */}
      {isConfirmBox ?
        <ConfirmationDialog onClose={onConfirmDialogBox} />
        : null}
      {/* Select Sale Invoice*/}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={showPo}
        onClose={() => {
          setShowSPo(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Sale Invoices
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowSPo(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <CustomerInvSelect remove={remove}
            setIsError={setIsError}
            setSelectedSPo={setSelectedSPo}
            store={store}
            setShowSPo={setShowSPo}
            setStore={setStore}
            setSelectInvData={setSelectInvData}
            setCostCenterName={setCostCenterName}
            setLocName={setLocName}
            setBank={setBank}
            setBankName={setBankName} />
        </DialogContent>
      </Dialog>


      {/* calculator */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={priceData.open}
        onClose={() => {
          setPriceData({ ...priceData, open: false });
        }}
      >
        <DialogTitle style={{ paddingBottom: "0px" }}>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Price Calculator
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setPriceData({ ...priceData, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Calculator
            cancel={() => {
              setPriceData({ ...priceData, open: false });
            }}
            priceData={priceData}
            intraState={customerData && orgDetails && customerData.stateCode !== orgDetails.state}
          />
        </DialogContent>
      </Dialog>


      {/* price */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={productPriceData.open}
        onClose={() => {
          setProductPriceData({ ...productPriceData, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Product Price Information(Per Unit)
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProductPriceData({ ...productPriceData, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <PriceDetermine
            cancel={() => {
              setProductPriceData({ ...productPriceData, open: false });
            }}
            priceData={productPriceData}
          />
        </DialogContent>
      </Dialog>
      {/* Invoice email */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Email Invoice
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '180px' }}>
          <Grid container>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span" >Mail to *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                inputRef={register({
                  required: "Email Id is required",
                })}
                value={emailId}
                onChange={e => setEmailSendData({ ...emailSendData, emailId: e.target.value })}
              />
              {isEmailHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter valid email id
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: '10px' }}>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span">Subject *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                inputRef={register({
                  required: "Email Subject is required",
                })}
                value={emailSubject}
                onChange={e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value })}
              />
              {isEmailSubjectHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter email subject
              </FormHelperText>}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              onClick={onSendEmailInvoice}
              color="secondary"
              size="small"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Box display="none">
        <CompToPrintInv ref={componentRefInv} customerInv={invoice} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
        <ReactToPrint
          onBeforeGetContent={() => handleInvoicePrint(invoiceNo)}
          trigger={() => <Button
            color="primary"
            size="small"
            variant="contained"
            ref={buttonRef}
          >
            PRINT INVOICE
          </Button>}
          content={() => componentRefInv.current} />
      </Box>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {message ? message : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setIsSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message ? message : "Thanks for shopping!"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isWarning}
        autoHideDuration={3000}
        onClose={() => setIsWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          {message ? message : "Credit note generated but unble to generate e-credit note"}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={isSaleWarning}
        autoHideDuration={3000}
        onClose={() => setIsSaleWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsSaleWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          {message ? message : "Error form ASP port unble to generate eInvoice/EWB"}
        </MuiAlert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={safetyStockWarning}
        onClose={() => {
          setSafetyStockWarning(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={10} alignItems="center">
              <Grid item >
                Product availability is low. Check Safety Stock.
              </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSafetyStockWarning(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} >
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setSafetyStockWarning(false)}
                  color="primary"
                  size="medium"
                  variant="contained"
                  style={{ marginRight: 10 }}
                >
                  OK
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
      </Dialog>
    </Grid> : <Grid item container xs={12} direction="column">
      <Grid item>
        <Box mt={2}>
          <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
            <Link color="inherit" href="" onClick={() => history.push("/")}>
              Home
            </Link>
            {isAdmin && <Link color="inherit" href="" onClick={() => history.push("/suppliers")}>
              Customer
            </Link>}
            <Typography component="span" color="textPrimary">
              Credit Note
            </Typography>
          </Breadcrumbs>
        </Box>
      </Grid>
      <Grid><Box mt={2}>Kindly select a Customer to Credit Note</Box></Grid>
    </Grid>

  );
};

export default CreditNoteComp;
