import React, { useState } from "react";
import {
  Typography,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import axios from "axios";

let errorMsg;
function ManageProductImagesForm(props) {

  const { id } = useParams();
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ uploadImages, setUploadImages ] = useState([]);

  const onFileInputChange = async (e) => {
    setIsProcessing(true);
    try {
      const data = new FormData();
      data.append("file", e.target.files[0]);
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/uploadfile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUploadImages([...uploadImages, result.data.fileName])
      setIsProcessing(false);
    } catch (err) {
      setIsError(true)
    }
  };

  const handleUpload = async () => {
    
    try{
      const reqData = {
        images:JSON.stringify(uploadImages)
      }
      await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/products/${id}/images`,
        reqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      props.success();
    }catch(err){
      console.log(err);
    }
  }

  return (
    <div>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={12}
          justify={"flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : <Grid>
            <Grid item container alignItems="center">
          <input
            style={{ display: "none" }}
            id="user-manual-file"
            type="file"
            name="file"
            onChange={(e) => onFileInputChange(e, "U")}
          />
          <Grid item>
            <Typography variant="caption">Upload Files</Typography>
          </Grid>
          <Grid item>
            <label htmlFor="user-manual-file">
              <Button style={{marginLeft: '5px'}} variant="outlined" component="span" color="secondary">
                <CloudUploadIcon />
              </Button>
            </label>
          </Grid>
        </Grid>
            </Grid>}
            <Grid item container alignItems="center">
              {uploadImages.length>0 &&
              uploadImages.map(img =>
              <Grid item xs={12} md={12}>
                <Typography>{img}</Typography>
              </Grid>)}
            </Grid>
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid item container style={{minHeight: '10px'}} xs={12} spacing={2}
          alignItems="flex-start"
          justify="flex-end">
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button size="small" variant="contained" color="secondary"
              onClick={handleUpload}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ManageProductImagesForm;
