import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Checkbox,
  IconButton
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import {
  custTypes
} from "../common/lookupSlice";
import { useForm, Controller } from "react-hook-form";

const MissingPrice = (props) => {
  const {  control  } = useForm({});
  const customerTypes = useSelector(custTypes);
  const pageSize = 10;
  const history = useHistory();
  const typeRef = useRef();
  const [count, setCount] = useState();

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [typeId, setTypeId] = useState('');
  const [landingCost, setLandingCost] = useState(false);
  const [purchasePrice, setPurchasePrice] = useState(false);
  const [sellingPrice, setSellingPrice] = useState(false);
  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    if(!(query.landingCost)){
      delete query.landingCost;
    }
    if(!(query.sellingPrice)){
      delete query.sellingPrice;
    }
    if(!(query.purchasePrice)){
      delete query.purchasePrice;
    }
    if(!(query.customerTypeId)){
      delete query.customerTypeId;
    }

    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/reports/productPrice`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.priceList);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      customerTypeId: typeId,
      landingCost:landingCost,
      sellingPrice:sellingPrice,
      purchasePrice: purchasePrice,
      page: 1,
    });
  };
  
  const handleReset = (e) =>{
     if(typeRef && typeRef.current && typeRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator') && typeRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]){
      typeRef && typeRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
     }
     setTypeId('');
     setLandingCost(false);
     setPurchasePrice(false)
     setSellingPrice(false)
     setQuery({ page: 1, pageSize: pageSize })
  }

  const handleSellingPrice = () =>{
    setQuery({
      ...query,
      sellingPrice: !sellingPrice ? true:undefined,
      page: 1,
    });
    setSellingPrice(!sellingPrice)
  }

  const handleLandingCost = () =>{
    setQuery({
      ...query,
      landingCost: !landingCost ? true:undefined,
      page: 1,
    });
    setLandingCost(!landingCost)
  }

  const handlePurchasePrice = () =>{
    setQuery({
      ...query,
      purchasePrice: !purchasePrice ? true:undefined,
      page: 1,
    });
    setPurchasePrice(!purchasePrice)
  }
  return (
    <div>
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/reports")}>
                  Reports
                </Link>
                <Typography component="span" color="textPrimary">
                  Missing Price Report
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>
      <div style={{paddingTop:10}}>
        <form onSubmit={handleSearch}>
          <Grid container spacing={1} alignItems="center">
            <Grid item container md= {3} xs={6} lg={2}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Customer Type</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <Autocomplete
                  options={customerTypes}
                  size="small"
                  ref={typeRef}
                  getOptionLabel={(option) => option.description}
                  onChange={(e, val) => {
                    if (val) {
                      setTypeId(val.id);
                      setQuery({
                        ...query,
                        customerTypeId: val.id,
                        page: 1,
                      });
                    }
                  }}
                  onInputChange = {(e,val)=>{
                    if(!val){
                      setTypeId(val);
                      setQuery({
                        ...query,
                        customerTypeId: val,
                        page: 1,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Grid>
            </Grid>

            <Grid item container style={{paddingTop:"25px"}} md= {3} xs={6} lg={2} spacing={2}>
            <Grid md={1}>
            <Controller
              as={Checkbox}
              name="purchasePrice"
              type="checkbox"
              onChange={()=>handlePurchasePrice()}
              checked={purchasePrice}
              control={control}
            /></Grid>
            <Grid md={11}>
              <Box mt={1.3} ml={2}>
            <Typography>Purchase Price</Typography>
            </Box>
            </Grid>
            </Grid>

            <Grid item container style={{paddingTop:"25px"}} md= {3} xs={6} lg={2} spacing={2}>
            <Grid md={1}>
            <Controller
              as={Checkbox}
              name="landingPrice"
              type="checkbox"
              onChange={()=>handleLandingCost()}
              checked={landingCost}
              control={control}
            /></Grid>
            <Grid md={11}>
              <Box mt={1.3} ml={2}>
            <Typography>Landing Price</Typography>
            </Box>
            </Grid>
            </Grid>

            <Grid item container style={{paddingTop:"25px"}}  md= {3} xs={6} lg={2} spacing={2}>
            <Grid md={2}>
            <Controller
              as={Checkbox}
              name="sellingPrice"
              type="checkbox"
              onChange={()=>handleSellingPrice()}
              checked={sellingPrice}
              control={control}
            /></Grid>
            <Grid md={10}>
              <Box mt={1.3} >
            <Typography>Selling Price</Typography>
            </Box>
            </Grid>
            </Grid>

         
            <Grid item container md= {3} xs={6} lg={2} >
            <Box mt={2} mr={0}>
            <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Search
            </Button>
            </Box>
            <Box mt={2} mr={0}>
                <Button size="small" style={{marginLeft: '20px'}}
                  onClick={handleReset} variant="contained" color="secondary">
                  Clear</Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Purchase Price</StyledHeaderCell>
                <StyledHeaderCell align="left">Landing Cost</StyledHeaderCell>
                <StyledHeaderCell align="left">Selling Price</StyledHeaderCell>
                <StyledHeaderCell align="left">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row, index) => (
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell align="left">
                            {row.productName}
                          </TableCell>
                          <TableCell align="right">
                            {row.purchasePrice ? row.purchasePrice && (Math.round(row.purchasePrice * 100) / 100).toFixed(2):'NA'}
                          </TableCell>
                          <TableCell align="right">
                            {row.landingCost ? (Math.round(row.landingCost * 100) / 100).toFixed(2): 'NA'}
                          </TableCell>
                          <TableCell align="right">
                            {row.prices.length > 0 ? row.prices[0] && row.prices[0].price && (Math.round(row.prices[0].price * 100) / 100).toFixed(2):<b style={{color:"red"}}>NA</b>}
                          </TableCell>
                          <TableCell align="left">
                          <IconButton 
                            onClick={() => {
                              history.push("/manageProdPriceForm/" + row.id)
                            }}><EditIcon color="secondary" /></IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
          <div />
        )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default MissingPrice;
