import React from "react";
import {
  Typography,
  Grid,
  Box,
} from "@material-ui/core";

const PriceData = (props) => {
  return (
    <div>
      <Grid item container md={12} xs={12} spacing={2}>
      <Grid item container xs={12} md={12} >
      <Box ml={2}>
        <Typography style={{fontWeight:"bold"}}>Base Price Determination:</Typography>
         <Typography>{`Override:`}&nbsp;&nbsp;<span>{props.priceData.pricingInfo && props.priceData.pricingInfo.overridePrice}</span></Typography> 
         <Typography>{`Negotiated:`}&nbsp;&nbsp;<span style={{color:props.priceData.pricingInfo && props.priceData.pricingInfo.negotiatedPrice !=="NA" ? "green":"",
          fontWeight:props.priceData.pricingInfo && props.priceData.pricingInfo.negotiatedPrice !=="NA" ? "bold":""}}>{props.priceData.pricingInfo && props.priceData.pricingInfo.negotiatedPrice}</span></Typography> 
         <Typography>{`Customer Type Based:`}&nbsp;&nbsp;<span>{props.priceData.pricingInfo && props.priceData.pricingInfo.custTypePrice}</span></Typography> 
         <Typography>{`System Calculated:`}&nbsp;&nbsp;<span>{props.priceData.pricingInfo && props.priceData.pricingInfo.calculatedPrice}</span></Typography> 
          </Box>
        </Grid>
        <Grid item container xs={12} md={12} >
        <Box ml={2}>
        <Typography style={{fontWeight:"bold"}}>Product Discount Determination:</Typography>
         <Typography>{`Override:`}<span>&nbsp;&nbsp;{props.priceData.pricingInfo && props.priceData.pricingInfo.overrideDiscount}</span></Typography> 
         <Typography>{`Product Quantity Based:`}&nbsp;&nbsp;<span style={{color:props.priceData.pricingInfo && props.priceData.pricingInfo.negotiatedPrice !=="NA" ? "green":"",
          fontWeight:props.priceData.pricingInfo && props.priceData.pricingInfo.negotiatedPrice!=="NA" ? "bold":""}}>{props.priceData.pricingInfo && props.priceData.pricingInfo.qtyDiscount}</span></Typography> 
         <Typography>{`Product Group Based:`}&nbsp;&nbsp;<span>{props.priceData.pricingInfo && props.priceData.pricingInfo.grpDiscount}</span></Typography> 
          </Box>
        </Grid>
      </ Grid>
    </div>
  );
};

export default PriceData;
