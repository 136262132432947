import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import {
  Typography,
  TextField,
  Snackbar,
  FormControl,
  MenuItem,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Select,
  TableRow,
  Paper,
  CircularProgress,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

import { currentUser } from "../login/loginSlice";
import {
  jobCategories,
  jobStatuses,
  jobTypes,
  technicians,
} from "../common/lookupSlice";

import { API_ALL_JOBS } from "../../config/constants";

import "./jobCard.css";
const JobList = (props) => {
  let firstTimeQuery = false;
  const pageSize = 10;
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isTechnician =
    roles.findIndex((role) => role.name === "TECHNICIAN") > -1 ? true : false;
  const isAdmin =
      roles.findIndex((role) => role.name === "ADMIN") > -1 ? true : false;
  const history = useHistory();
  const jCategories = useSelector(jobCategories);
  const jStatuses = useSelector(jobStatuses);
  const jTypes = useSelector(jobTypes);
  const _techies = useSelector(technicians);

  const techies = isTechnician
    ? [
        {
          id: loggedInUser.id,
          firstName: loggedInUser.firstName,
          lastName: loggedInUser.lastName,
        },
      ]
    : _techies;
  const [count, setCount] = useState();

  const [data, setData] = useState([]);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const token = localStorage.getItem("authToken");
  const defaultValues = {
    id:
      props.location &&
      props.location &&
      props.location.state &&
      props.location.state.id,
    typeId:
      props.location &&
      props.location &&
      props.location.state &&
      props.location.state.typeId
        ? props.location.state.typeId
        : "",
    categoryId:
      props.location &&
      props.location &&
      props.location.state &&
      props.location.state.categoryId
        ? props.location.state.categoryId
        : "",
    statusId:
      props.location &&
      props.location &&
      props.location.state &&
      props.location.state.statusCode
        ? props.location.state.statusCode
        : "",
    technicianId: isTechnician
      ? loggedInUser.id
      : props.location &&
        props.location &&
        props.location.state &&
        props.location.state.technicianId
      ? props.location.state.technicianId
      : "",
    fromCreatedOn: "",
    toCreatedOn: "",
  };
  const {
    handleSubmit,
    control,
    getValues,
    errors,
    register,
    triggerValidation,
  } = useForm({
    defaultValues: defaultValues,
  });

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_JOBS}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.jobs);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const passedValues = getValues();
    setQuery({ ...query, ...passedValues });
    firstTimeQuery = true;// eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstTimeQuery) {
      loadData();
    }
    firstTimeQuery = false;// eslint-disable-line react-hooks/exhaustive-deps
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data) => {
    if (data.fromCreatedOn) {
      const d = data.fromCreatedOn.split("/");
      data.fromCreatedOn = d[1] + "/" + d[0] + "/" + d[2];
    }
    if (data.toCreatedOn) {
      const d = data.toCreatedOn.split("/");
      data.toCreatedOn = d[1] + "/" + d[0] + "/" + d[2];
    }
    setQuery({...data, page:1, pageSize:pageSize});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Grid item container xs={6} direction="column">
              <Grid item>
                <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                  <Link color="inherit" href="" onClick={() => history.push("/")}>
                    Home
                </Link>
                  <Typography component="span" color="textPrimary" style={{fontWeight: 'bold'}}>
                  Service Requests
                </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
        <Grid container spacing={2} alignItems="center" style={{marginTop: '10px'}}>
          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Job Number</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl style={{ display: "flex", color: "white" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  // InputProps={{
                  //   style: { color: "white" },
                  // }}
                  error={Boolean(errors.id)}
                  inputRef={register}
                  size="small"
                  name="id"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Type</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={Boolean(errors.typeId)}
              >
                <Controller
                  as={
                    <Select style={{ display: "flex" }}>
                      <MenuItem key={""} value={""}>
                        All
                      </MenuItem>
                      {jTypes.map(function (jType) {
                        return (
                          <MenuItem key={jType.id} value={jType.id}>
                            {jType.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  name="typeId"
                  size="small"
                  variant="outlined"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Technician</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                fullWidth
                disabled={isTechnician}
                placeholder="Assigned Technician"
                variant="outlined"
                size="small"
                error={Boolean(errors.technicianId)}
              >
                <Controller
                  as={
                    <Select style={{ display: "flex", color: "black" }}>
                      <MenuItem key={""} value={""}>
                        All
                      </MenuItem>
                      {techies &&
                        techies.map(function (technician) {
                          return (
                            <MenuItem key={technician.id} value={technician.id}>
                              {technician.firstName} {technician.lastName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  }
                  name="technicianId"
                  size="small"
                  variant="outlined"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Mobile Number</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl style={{ display: "flex" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    type: "number",
                    name: "mobileNumber",
                    //style: { color: "white" },
                  }}
                  error={Boolean(errors.mobileNumber)}
                  inputRef={register}
                  size="small"
                  name="mobileNumber"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Status</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                fullWidth
                placeholder="Current Status"
                variant="outlined"
                size="small"
                error={Boolean(errors.statusId)}
              >
                <Controller
                  as={
                    <Select style={{ display: "flex" }}>
                      <MenuItem key={""} value={""}>
                        All
                      </MenuItem>
                      {jStatuses &&
                        jStatuses.map(function (jStatus) {
                          return (
                            <MenuItem key={jStatus.id} value={jStatus.id}>
                              {jStatus.description}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  }
                  name="statusId"
                  size="small"
                  variant="outlined"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Category</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                fullWidth
                placeholder="Job Category"
                variant="outlined"
                size="small"
                error={Boolean(errors.categoryId)}
              >
                <Controller
                  as={
                    <Select style={{ display: "flex" }}>
                      <MenuItem key={""} value={""}>
                        All
                      </MenuItem>
                      {jCategories.map(function (jCat) {
                        return (
                          <MenuItem key={jCat.id} value={jCat.id}>
                            {jCat.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  name="categoryId"
                  size="small"
                  variant="outlined"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">From Date</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                className="search-input-text"
              >
                <KeyboardDatePicker
                  size="small"
                  inputVariant="outlined"
                  helperText={
                    errors.fromCreatedOn && errors.fromCreatedOn.message
                  }
                  format="dd/MM/yyyy"
                  disableFuture={true}
                  fullWidth
                  error={Boolean(errors.fromCreatedOn)}
                  onBlur={(e) => triggerValidation("fromCreatedOn")}
                  inputRef={register({
                    validate: (value) => {
                      if (!value) {
                        return true;
                      }
                      if (moment(value, "DD/MM/YYYY").isValid()) {
                        const toDate = getValues("toCreatedOn");

                        if (
                          moment(value, "DD/MM/YYYY").isAfter(
                            moment(toDate, "DD/MM/YYYY")
                          )
                        ) {
                          return "From Date should be earlier than to date";
                        } else {
                          return true;
                        }
                      } else {
                        return "Invalid date value for dd/mm/yyyy";
                      }
                    },
                  })}
                  value={fromDate}
                  defaultValue=""
                  name="fromCreatedOn"
                  onChange={async (d) => {
                    await setFromDate(d);
                    triggerValidation("fromCreatedOn");
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid item container md={3} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">To Date</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                className="search-input-text"
              >
                <KeyboardDatePicker
                  size="small"
                  inputVariant="outlined"
                  fullWidth
                  helperText={errors.toCreatedOn && errors.toCreatedOn.message}
                  format="dd/MM/yyyy"
                  disableFuture={true}
                  error={Boolean(errors.toCreatedOn)}
                  onBlur={(e) => triggerValidation("toCreatedOn")}
                  inputRef={register({
                    validate: (value) => {
                      if (!value) {
                        return true;
                      }
                      if (moment(value, "DD/MM/YYYY").isValid()) {
                        const fromDate = getValues("fromCreatedOn");

                        if (
                          moment(value, "DD/MM/YYYY").isBefore(
                            moment(fromDate, "DD/MM/YYYY")
                          )
                        ) {
                          return "To Date should be later than from date";
                        } else {
                          return true;
                        }
                      } else {
                        return "Invalid date value for dd/mm/yyyy";
                      }
                    },
                  })}
                  value={toDate}
                  name="toCreatedOn"
                  onChange={async (d) => {
                    await setToDate(d);
                    triggerValidation("toCreatedOn");
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justify="center"
            md={12}
            xs={12}
          >
            <Grid item>
              <Button variant="contained" type="submit" color="primary">
                Generate Report
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Job Number</StyledHeaderCell>                
                <StyledHeaderCell align="left">Customer</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Category</StyledHeaderCell>
                <StyledHeaderCell align="left">Service Type</StyledHeaderCell>
                <StyledHeaderCell align="left">Technician</StyledHeaderCell>
                <StyledHeaderCell align="left">Status</StyledHeaderCell>
                {isAdmin?<StyledHeaderCell align="left">E-Invoice</StyledHeaderCell>:null}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data && data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data &&
                  data.map((row, index) => (
                    <TableRow
                      style={{ cursor: "pointer" }}
                      key={row.id}
                      hover={true}
                      onClick={(e) => {
                        history.push({
                        pathname: "/jobs/" + row.id,
                        state: {
                          click: true,
                          jobLocData: [row.location]
                        },
                      })}}
                      className="underLine"
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">
                        {row.customer && row.customer.fullName}
                      </TableCell>
                      <TableCell align="left">
                        {row.product && row.product.productName}
                      </TableCell>
                      <TableCell align="left">
                        {row.category && row.category.description}
                      </TableCell>
                      <TableCell align="left">
                        {row.type && row.type.description}
                      </TableCell>
                      <TableCell align="left">
                        {row.technician &&
                          `${row.technician.firstName} ${row.technician.lastName}`}
                      </TableCell>
                      <TableCell align="left">
                        {row.status && row.status.description}
                      </TableCell>
                      {isAdmin?<TableCell align="left">
                        <Button disabled={(row.invoiceDetails)?(row.invoiceDetails.isSync || (row.invoiceDetails.isInvoiceStatus ==="COMPLETED") || !(row.invoiceDetails.isEInvoice)):true} color="primary" variant="contained" size="small" style={{textTransform:"none"}}>{(row.invoiceDetails && row.invoiceDetails.isEInvoice && (row.invoiceDetails.isInvoiceStatus ==="COMPLETED"))?"Generated":"Generate"}</Button>
                      </TableCell>:null}
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
    </form>
  );
};

export default JobList;
