import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import { accountTypes } from "../common/constants";
import { expensesAccount } from "../common/lookupSlice";
import { Autocomplete } from "@material-ui/lab";
import { extractERPNextErrorMessage } from "../common/util";

const Customer = (props) => {
  // const availCustLocalities = useSelector(localities);
  const expensesAccountList = useSelector(expensesAccount);
  // const custLocalities = availCustLocalities ? availCustLocalities.filter(loc => loc !== "") : [];
  const pageSize = 10;
  const history = useHistory();
  const titleRef = useRef();
  const accountTypeRef = useRef();
  // const loggedInUser = useSelector(currentUser);
  // const location = useLocation();
  // const customerData = location.state && !(location.state.click);
  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isNew = (chars[1]) ? chars[1].toUpperCase() === "NEW" : false;
  const [count, setCount] = useState();
  // const [deepMobileNumSearch, setDeepMobileNumSearch] = useState(false);
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [popupErrorMsg, setPopUpErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [accountData, setAccountData] = useState({ accountName: "", parentAccount: (isNew && expensesAccountList && expensesAccountList.length > 2) ? expensesAccountList[2].label : "", id: null, success: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isNewAccount, setAddAccount] = useState({ mode: "New", open: isNew ? true : false, data: {} });
  // const idRef = useRef();
  // const bankNameRef = useRef();
  // const acHolderNameRef = useRef();
  // const branchNameRef = useRef();

  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/Account`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.dataList);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };


  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps


  // On success of add or edit, reset the query to load data agai

  const onSubmit = async () => {
    if (accountData.accountName && accountData.parentAccount) {
      setPopUpErrorMessage("");
      setIsProcessing(true)
      var resAccount;
      try {
        let bankRes;
        let isBankAccount = (expensesAccountList && expensesAccountList.length > 2) ? (accountData.parentAccount === expensesAccountList[2].label) : false;
        if (isNewAccount.mode === "New" && isBankAccount) {
          let bankData = {
            bankName: accountData.accountName
          }
          bankRes = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/bank`,
            bankData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        let isContinue = (isBankAccount) ? (bankRes && bankRes.data && bankRes.data.status === "SUCCESS") ? true : false : true;
        if ((isNewAccount.mode === "Edit") && accountData.id) {
          resAccount = await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/${accountData.id}`,
            accountData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else if (isNewAccount.mode === "New" && isContinue) {
          resAccount = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/chartofaccount`,
            accountData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

        }
        if (isNew && resAccount) {
          window.location.href = `/companyconfigbankdetails`;
        } else if (resAccount) {
          setIsProcessing(false)
          setAccountData({ accountName: "", parentAccount: "", success: true })
          setAddAccount({ mode: "", open: false, data: {} })
          loadData();
        } else {
          setIsProcessing(false)
          if (!(bankRes && bankRes.data && bankRes.data.status === "SUCCESS")) {
            setIsError(true)
          }
        }
      } catch (err) {
        let errorMsg = extractERPNextErrorMessage(err.response.data.message);
        setPopUpErrorMessage(errorMsg);
        setIsError(true)
        setIsProcessing(false)
      }
    } else {
      setIsProcessing(false)
      setPopUpErrorMessage("Enter Account Name and Parent Account")
    }
  }


  const cancelClick = () => {
    if (isNew) {
      window.location.href = `/companyconfigbankdetails`;
    } else {
      setPopUpErrorMessage("");
      setIsProcessing(false)
      setAccountData({ accountName: "", parentAccount: "" })
      setAddAccount({ mode: "", open: false, data: {} })
    }
  }



  const handleCodeChange = (event) => {
    setAccountData({ ...accountData, accountName: event.target.value })
  };
  const handleDescriptionChange = (event) => {
    setAccountData({ ...accountData, parentAccount: event.target.value })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      accountName: e.target.elements.accountTitle.value,
      rootType: e.target.elements.rootType.value,
      page: 1,
    });
  };

  const resetQuery = () => {
    titleRef.current.value = "";
    accountTypeRef.current.value = "";
    setQuery({
      ...query,
      accountName: titleRef.current.value,
      rootType: accountTypeRef.current.value,
      page: 1,
    });
  };

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/configrationhome")}>
                Configuration
              </Link>
              <Typography component="span" color="textPrimary">
                Accounts
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setPopUpErrorMessage("");
              setAccountData({ accountName: "", parentAccount: "" })
              setAddAccount({ mode: "New", open: true, data: {} });
            }}
          >
            Add Account
          </Button>
        </Grid>
      </Grid>
      {/* <Grid container style={{ paddingTop: 20 }}>
        <Grid item container xs={6} direction="column">
          <Typography variant={props.right ? "h7" : "h6"}>
            {props.modal ? "Account Mapping Selection" : "Account Search"}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => { setAddAccount({ mode: "New", open: true, data: {} }); }}
            >
              Add Account
            </Button>
          </Grid>
        </Grid>
      </Grid> */}
      <div>
        <Typography variant={props.right ? "h7" : "h6"}>
          {props.modal ? "Account Selection" : "Account Search"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Account Name
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={titleRef}
                    InputProps={{
                      name: "accountTitle",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Account Root Type
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  fullWidth
                  options={accountTypes}
                  getOptionLabel={(option) => (typeof option.label === 'string' ? option.label : '')}
                  renderInput={(params) => <TextField {...params}
                    variant="outlined"
                    size="small"
                    name={`rootType`}
                    inputRef={accountTypeRef}
                  />}
                  filterOptions={(options, addrType) =>
                    options.filter((option) =>
                      typeof option.label === 'string' &&
                      option.label.toLowerCase().includes(addrType.inputValue.toLowerCase())
                    )
                  }
                />
              </Grid>
            </Grid>
            {(props.modal) ? <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item style={{ marginTop: '20px' }} md={12} xs={12}>
                <Button size="small" type="submit" variant="contained" color="primary">
                  Search</Button>
                <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                  onClick={() => resetQuery()} variant="contained" color="secondary">
                  clear</Button>
              </Grid>
            </Grid> :
              <Grid item container justifyContent={"flex-end"} md={2} xs={6}>
                <Box mt={2} mr={0}>
                  <Button size="small" type="submit" variant="contained" color="primary">
                    Search</Button>
                </Box>
                <Box mt={2} mr={0}>
                  <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                    onClick={() => resetQuery()} variant="contained" color="secondary">
                    clear</Button>
                </Box>
              </Grid>}
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 20 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left">Account Name </StyledHeaderCell>
                <StyledHeaderCell align="left">Parent Account</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={props.modal || props.right ? 5 : 6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => (
                    <TableRow
                      style={{
                        cursor: props.modal || props.right ? "pointer" : "",
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }
                    >
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{(row.parent_account) ? row.parent_account : "--"}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isNewAccount.open}
        onClose={() => {
          if (isNew) {
            window.location.href = `/companyconfigbankdetails`;
          } else {
            setAddAccount({ mode: "", open: false, data: {} });
            setAccountData({ accountName: "", parentAccount: "" });
          }
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {(isNewAccount && isNewAccount.mode === "New") ? "Add Chart Of Account" : "Edit Chart Of Account"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    if (isNew) {
                      window.location.href = `/companyconfigbankdetails`;
                    } else {
                      setAddAccount({ mode: "", open: false, data: {} });
                      setAccountData({ accountName: "", parentAccount: "" })
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={6} xs={6} spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography className="pop-up-form-labell" variant="caption">Account Name *</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    id="accountName"
                    variant="outlined"
                    fullWidth
                    value={accountData.accountName}
                    onChange={handleCodeChange}
                    size="small"
                  />
                </Grid>
              </Grid>
              <Grid item container md={6} xs={6} spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography className="pop-up-form-labell" variant="caption">Parent Account *</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    <Select
                      id="account-select"
                      // multiple
                      value={accountData.parentAccount}
                      onChange={handleDescriptionChange}
                      disabled={isNew ? true : false}
                    // onChange={(e) => {
                    //   handleDescriptionChange({ ...tempFilters, cost_center: e.target.value });
                    // }}
                    >
                      {expensesAccountList && expensesAccountList.length > 0 && expensesAccountList.map(function (account, index) {
                        return (
                          <MenuItem key={`account-${index + 1}`} value={account.label}>
                            {account.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "20px", color: "red" }}>
              <Grid item md={12} xs={12} >
                <Typography className="pop-up-form-labell" variant="caption" >{popupErrorMsg}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: 10 }}>
              <Grid
                item
                container
                xs={6}
                justifyContent={isProcessing ? "flex-end" : "flex-start"}
              >
                {isProcessing ? (
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                ) : null}
              </Grid>
              <Grid
                item
                container
                xs={6}
                spacing={2}
                alignItems="flex-start"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button size="small" onClick={() => { onSubmit() }}
                    variant="contained" color="primary">
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => cancelClick()}
                    color="secondary"
                    size="small"
                    type="reset"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {popupErrorMsg ? popupErrorMsg : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={accountData.success}
        onClose={() => setAccountData({ ...accountData, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setAccountData({ ...accountData, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {accountData.mode === "New"
            ? "Account created successfully"
            : "Account updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Customer;
