import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocalities, custStates } from "../common/lookupSlice";
import {
  Typography,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Grid,
  CircularProgress,
  Breadcrumbs,
  Link,
  Snackbar
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MuiAlert from "@material-ui/lab/Alert";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ErrorIcon from "@material-ui/icons/Error";
import { currentUser } from "../login/loginSlice";
import axios from "axios";
import { gstCategories } from "../common/constants";
import { useHistory, useParams } from "react-router-dom";

let errorMsg;

function CompanyForm(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { id } = useParams();
  const history = useHistory();
  const [uploads, setUploads] = React.useState({
    status: ""
  });
  const [uploadPrint, setPrintUploads] = React.useState({
    status: ""
  });
  const loggedInUser = useSelector(currentUser);
  const isPricingAdmin = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role=>role.name === "PRICING ADMIN");
  const orgCode = loggedInUser && loggedInUser.org && loggedInUser.org.code;
  const [isProcessing, setIsProcessing] = useState(false);
  const [companyAddressData, setSupplierData] = useState({});
  let { name, ERPAbbr, email, phoneNo, address1, address2, address3, pincode, locality, GSTIN, logo, printLogo, GSTINType } = companyAddressData;
  // const [supType, setSupType] = useState();
  const [isError, setIsError] = useState(false);
  const [logoErrMsg, setLogoErrorMsg] = useState("");
  const [printLogoErrMsg, setPrintLogoErrorMsg] = useState("");
  const [isItemRomve, setIsRemoveItem] = useState(false);
  const { handleSubmit, errors, register } = useForm({});
  // const [inputFields, setInputFields] = useState([]);
  const [create, setCreate] = React.useState({});
  const stateObj = useSelector(custStates);
  const [GSTType, setGSTType] = React.useState('');
  // const types = useSelector(supTypes);
  const states = stateObj ? stateObj.map(state => state.name) : [];
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [saved, setSaved] = React.useState(false);
  const [isGSTNErr, setGSTNErr] = useState(false);
  const [isPincodeErr, setPincodeErr] = useState(false);
  const [pincodeErrMsg, setPincodeErrMsg] = useState(false);
  // const [prodItemForm, setProdItemForm] = useState({ open: false, mode: "C" });
  // var queryStringData = window.location.search;
  // var chars = queryStringData.split('=');
  // let isQuotation = (chars[1]) ? chars[1].toUpperCase() === "QUOTATION" : false;
  // let isPO = (chars[1]) ? chars[1].toUpperCase() === "PURCHASEORDER" : false;

  const fetchData = async (orgCode) => {
    if (orgCode) {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/org/${orgCode}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setGSTType(result.data && result.data.GSTINType)
        setSupplierData(result.data);
        const stateIndex = states.indexOf(result.data && result.data.stateDetails && result.data.stateDetails.name)
        setValue(states[stateIndex])
      } catch (err) {
        console.log("eer", err);
        console.log(err);
        setIsError(true);
      }
    }
  }

  useEffect(() => {
    fetchData(orgCode);
  }, [orgCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (companyAddressData) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    let isContinue = true;
    const selectedState = stateObj.filter(x => x.name === value);
    // companyAddressData.contacts = inputFields;
    companyAddressData.GSTIN = GSTIN;
    companyAddressData.id = id;
    //companyAddressData.state = selectedState.length>0 ? selectedState[0].code:'';
    companyAddressData.state = selectedState && selectedState[0] ? selectedState[0].code : '';


    if (companyAddressData.pincode && selectedState && selectedState[0]) {
      let testPinCode = parseInt(companyAddressData.pincode.toString().slice(0, 3));
      if (testPinCode >= parseInt(selectedState[0].pinCodeSR) && testPinCode <= parseInt(selectedState[0].pinCodeER)) {
        setPincodeErr(false);
      } else {
        setPincodeErr(true);
        setPincodeErrMsg("Pincode not matching to state.");
        isContinue = false;
      }
    } else {
      isContinue = false;
      setPincodeErrMsg("Enter Valid Pincode.");
      setPincodeErr(true);
    }
    if (GSTINType && !(GSTINType === "Unregistered") && !(GSTIN && GSTIN.length === 15)) {
      setGSTNErr(true)
      isContinue = false;
      errorMsg = "Enter Valid GSTIN"
    }
    if (isContinue) {
      setGSTNErr(false);
      try {
        let newCompanyAddressData;
        if (id) {
          await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/roles/org/${id}`,
            companyAddressData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          newCompanyAddressData = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/roles/org/${orgCode}`,
            companyAddressData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        if (props.isPopup) {
          props.success(newCompanyAddressData.data.data);
        } else {
          dispatch(fetchLocalities());
          setIsProcessing(false);
          setCreate({
            success: true
          });
          setSaved(true);
        }

      } catch (err) {
        console.log("err.response.data.message", err.response.data.message);
        errorMsg = (err.response.data.message && err.response.data.message.length > 0 && err.response.data.message[1] === "name must be unique") ? "Supplier Name has to be unique" : err.response.data.message;
        setIsError(true);
        setIsProcessing(false);
        setSaved(false);
      }
    } else {
      setIsProcessing(false);
    }
  };

  const onFileInputChange = async (e, type) => {
    try {
      if (type === "LOGO") {
        setUploads({
          ...uploads,
          status: "WIP"
        });
      } else {
        setPrintUploads({
          ...uploadPrint,
          status: "WIP"
        });
      }

      const file = e.target.files[0];
      if (!file) {
        if (type === "LOGO") {
          setLogoErrorMsg('No file selected');
        } else {
          setPrintLogoErrorMsg('No file selected');
        }
        return;
      }

      const img = new Image();
      img.onload = async () => {
        if (type === "LOGO" && img.width !== img.height) {
          setLogoErrorMsg('The image must have a 1:1 aspect ratio.');
          setUploads({
            ...uploads,
            status: "ERR"
          });
          return;
        }
        if (!(type === "LOGO") && (img.width / img.height) !== 2) {
          setPrintLogoErrorMsg('The image must have a 2:1 aspect ratio.');
          setPrintUploads({
            ...uploadPrint,
            status: "ERR"
          });
          return;
        }

        const data = new FormData();
        data.append("file", file);
        const result = await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/uploadfile`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let payload;
        if (type === "LOGO") {
          payload = { logo: result && result.data && result.data.publicUrl }
        } else {
          payload = { printLogo: result && result.data && result.data.publicUrl }
        }
        await axios.put(
          `${process.env.REACT_APP_SERVICE_URL}/roles/org/${id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (type === "LOGO") {
          setUploads({
            ...uploads,
            status: "DONE"
          });
        } else {
          setPrintUploads({
            ...uploadPrint,
            status: "DONE"
          });
        }
        fetchData(orgCode);
        if (type === "LOGO") {
          setLogoErrorMsg('');
        } else {
          setPrintLogoErrorMsg('');
        }
      };
      img.src = URL.createObjectURL(file);
    } catch (err) {
      if (type === "LOGO") {
        setUploads({
          ...uploads,
          status: "ERR"
        });
      } else {
        setPrintUploads({
          ...uploadPrint,
          status: "ERR"
        });
      }
      console.log(err);
    }
  };
  let cancelClick = () => {
    window.location.href = `/configrationhome`;
  }
  return (
    <div>
      {!props.isPopup && <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/configrationhome")}>
                Configuration
              </Link>
              <Typography component="span" color="textPrimary">
                Company Configuration
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="supplier-form">
          <Grid container spacing={2}>
            {/* <Grid item container md={12} xs={12} spacing={1}>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Supplier Number</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={id}
                    disabled={true}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Company Name *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors.name)}
                    inputRef={register({
                      required: "Minimum 5 letters required. No digits.",
                      // pattern: {
                      //   value: /^[a-zA-Z ]{5,}$/,
                      //   message: "Minimum 5 letters required. No digits.",
                      // },
                    })}
                    disabled={(id || (!isPricingAdmin)) ? true : false}
                    InputProps={{
                      autoFocus: true,
                      name: "name",
                    }} value={name}
                    onChange={e => setSupplierData({ ...companyAddressData, name: e.target.value })}
                    size="small"
                    name="name"
                  />
                  <FormHelperText error>
                    {errors.name && errors.name.message}
                  </FormHelperText>
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption" style={{ float: "right" }}>
                    Abbreviation *
                  </Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors.ERPAbbr)}
                    inputRef={register({
                      required: "Minimum 5 letters required. No digits.",
                      // pattern: {
                      //   value: /^[a-zA-Z ]{5,}$/,
                      //   message: "Minimum 5 letters required. No digits.",
                      // },
                    })}
                    disabled={(id || (!isPricingAdmin)) ? true : false}
                    InputProps={{
                      autoFocus: true,
                      name: "ERPAbbr",
                    }} value={ERPAbbr}
                    onChange={e => setSupplierData({ ...companyAddressData, ERPAbbr: e.target.value })}
                    size="small"
                    name="ERPAbbr"
                  />
                  <FormHelperText error>
                    {errors.ERPAbbr && errors.ERPAbbr.message}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Address 1 *</Typography>
                </Grid>
                <Grid item md={10} xs={10}>
                  <TextField  disabled={(isPricingAdmin) ? false : true}
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.address1)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={address1}
                    onChange={e => setSupplierData({ ...companyAddressData, address1: e.target.value })}
                    size="small"
                    name="address1"
                  />
                  <FormHelperText error>
                    {errors.address1 && errors.address1.message}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Address 2</Typography>
                </Grid>
                <Grid item md={10} xs={10}>
                  <TextField disabled={(isPricingAdmin) ? false : true}
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.address2)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={address2}
                    onChange={e => setSupplierData({ ...companyAddressData, address2: e.target.value })}
                    size="small"
                    name="address2"
                  />
                  <FormHelperText error>
                    {errors.address2 && errors.address2.message}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">City/Town</Typography>
                </Grid>
                <Grid item md={10} xs={10}>
                  <TextField disabled={(isPricingAdmin) ? false : true}
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.address3)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={address3}
                    onChange={e => setSupplierData({ ...companyAddressData, address3: e.target.value })}
                    size="small"
                    name="address3"
                  />
                  <FormHelperText error>
                    {errors.address3 && errors.address3.message}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">Pincode *</Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <TextField disabled={(isPricingAdmin) ? false : true}
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.pincode) || isPincodeErr}
                  type="number"
                  inputRef={register({
                    required: "6 digit pincode is required",
                    pattern: {
                      value: /^[0-9]{6}$/,
                      message: "6 digit pincode is required",
                    },
                  })} value={pincode}
                  onChange={e => setSupplierData({ ...companyAddressData, pincode: e.target.value })}
                  size="small"
                  name="pincode"
                />
                <FormHelperText error={Boolean(errors.pincode) || isPincodeErr}>
                  {(isPincodeErr) ? pincodeErrMsg : errors.pincode && errors.pincode.message}
                </FormHelperText>
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">Locality</Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <TextField variant="outlined" value={locality}
                  size="small" name="locality"
                  disabled={(isPricingAdmin) ? false : true}
                  onChange={e => setSupplierData({ ...companyAddressData, locality: e.target.value })}
                  error={Boolean(errors.locality)}
                  fullWidth inputRef={register({
                    // pattern: {
                    //   value: /^[a-zA-Z0-9 ]{3,}$/,
                    //   message: "Minimum of 3 characters required",
                    // },
                  })} />
                <FormHelperText error>
                  {errors.locality && errors.locality.message}
                </FormHelperText>
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">Mobile Number *</Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <TextField variant="outlined" fullWidth error={Boolean(errors.phoneNo)}
                  onChange={e => setSupplierData({ ...companyAddressData, phoneNo: e.target.value })}
                  disabled={(isPricingAdmin) ? false : true}
                  type="number" inputRef={register({
                    required: "Enter valid 10 digit mobile number",
                    pattern: {
                      value: /^[6789]{1}[0-9]{9}$/,
                      message: "Enter valid 10 digit mobile number",
                    },
                  })} value={phoneNo} size="small" name="phoneNo" />
                <FormHelperText error>
                  {errors.phoneNo && errors.phoneNo.message}
                </FormHelperText>
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className="supplier-form-label" variant="caption">State *</Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <Autocomplete
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  disabled={(isPricingAdmin) ? false : true}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  // fullWidth
                  options={states}
                  // style={{ width: 215 }}
                  renderInput={(params) => <TextField {...params}
                    variant="outlined"
                    name="st"
                    fullWidth
                    size="small"
                    error={Boolean(errors.st)}
                    inputRef={register({
                      required: "Required"
                    })} />}
                />
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">GST Category *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        setSupplierData({ ...companyAddressData, GSTINType: newValue.label })
                        setGSTType(newValue.label)
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setGSTType(newInputValue)
                    }}
                    disabled={(isPricingAdmin) ? false : true}
                    inputValue={GSTType}
                    fullWidth
                    value={GSTINType}
                    options={gstCategories}
                    getOptionLabel={(option) => (typeof option.label === 'string' ? option.label : '')}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      size="small"
                      name={`GSTINType`}
                      error={Boolean(errors.GSTINType)}
                      inputRef={register({
                        required: "Select GST Category"
                      })}
                    />}
                    filterOptions={(options, gstinType) =>
                      options.filter((option) =>
                        typeof option.label === 'string' &&
                        option.label.toLowerCase().includes(gstinType.inputValue.toLowerCase())
                      )
                    }
                  />
                  <FormHelperText error={Boolean(errors.GSTINType)}>
                    {errors.GSTINType && errors.GSTINType.message}
                  </FormHelperText>
                </Grid>

                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">GSTIN *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField value={GSTIN}
                    variant="outlined" fullWidth
                    onChange={e => setSupplierData({ ...companyAddressData, GSTIN: e.target.value })}
                    size="small" name="gstin"
                    disabled={(isPricingAdmin) ? false : true}
                    error={Boolean(errors.gstin) || isGSTNErr}
                    // inputRef={register({
                    //   required: "GSTIN Required",
                    //   pattern: {
                    //     value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    //     message: "Enter Valid GSTIN",
                    //   },
                    // })}
                    inputRef={register({
                      required: "GSTIN Required",
                      pattern: {
                        value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                        message: "Enter Valid GSTIN",
                      },
                    })}
                  />
                  <FormHelperText error={Boolean(errors.gstin) || isGSTNErr}>
                    {(isGSTNErr) ? errorMsg : errors.gstin && errors.gstin.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Email</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    variant="outlined"
                    fullWidth onChange={e => setSupplierData({ ...companyAddressData, email: e.target.value })}
                    type="email" value={email}
                    error={Boolean(errors.email)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 5 characters required",
                      // },
                    })}
                    disabled={(isPricingAdmin) ? false : true}
                    size="small"
                    name="email"
                  />
                  <FormHelperText error>
                    {errors.email && errors.email.message}
                  </FormHelperText>
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Company Header Logo</Typography>
                </Grid>
                <Grid item md={4} xs={4} style={{ display: "inline-flex" }}>
                  <input
                    style={{ display: "none" }}
                    id="company-header-file"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/jfif , image/pjpeg , image/pjp"
                    name="logo"
                    disabled={(isPricingAdmin) ? false : true}
                    onChange={(e) => onFileInputChange(e, "LOGO")}
                  />
                  <Grid item xs={4}>
                    <label htmlFor="company-header-file">
                      <Button variant="outlined" component="span" color={(isPricingAdmin) ? "secondary" : "default"}>
                        <CloudUploadIcon />
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={3}>
                    {uploads.status === "DONE" ? (
                      <IconButton
                        onClick={(e) => window.open(logo)}
                      >
                        <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
                      </IconButton>
                    ) : uploads.status === "WIP" ? (
                      <CircularProgress color="secondary" />
                    ) : uploads.status === "ERR" ? (
                      <ErrorIcon color="error" />
                    ) : logo ? (
                      <IconButton
                        onClick={(e) => window.open(logo)}
                      >
                        <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    {logoErrMsg && <Typography className="supplier-form-label" variant="caption" color="error">{logoErrMsg}</Typography>}
                  </Grid>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Typography className="info-form-label" variant="caption" color="textPrimary">The company header logo should have equal width and height, maintaining a 1:1 aspect ratio.</Typography>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={6} xs={6}></Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Company Print Logo</Typography>
                </Grid>
                <Grid item md={4} xs={4} style={{ display: "inline-flex" }}>
                  <input
                    style={{ display: "none" }}
                    id="company-print-file"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/jfif , image/pjpeg , image/pjp"
                    name="printlogo"
                    onChange={(e) => onFileInputChange(e, "PRINTLOGO")}
                    disabled={(isPricingAdmin) ? false : true}
                  />
                  <Grid item xs={4}>
                    <label htmlFor="company-print-file">
                      <Button variant="outlined" component="span" color={(isPricingAdmin) ? "secondary" : "default"}>
                        <CloudUploadIcon />
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={3}>
                    {uploadPrint.status === "DONE" ? (
                      <IconButton
                        onClick={(e) => window.open(printLogo)}
                      >
                        <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
                      </IconButton>
                    ) : uploadPrint.status === "WIP" ? (
                      <CircularProgress color="secondary" />
                    ) : uploadPrint.status === "ERR" ? (
                      <ErrorIcon color="error" />
                    ) : printLogo ? (
                      <IconButton
                        onClick={(e) => window.open(printLogo)}
                      >
                        <VisibilityIcon style={{ paddingLeft: 5 }} color="secondary" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {printLogoErrMsg && <Grid item xs={5}>
                    <Typography className="supplier-form-label" variant="caption" color="error">{printLogoErrMsg}</Typography>
                  </Grid>}
                </Grid>
                <Grid item md={6} xs={6}></Grid>
                <Grid item xs={6}>
                  <Typography className="info-form-label" variant="caption" color="textPrimary">The company print logo should have a 2:1 aspect ratio, e.g., 150 pixels wide and 75 pixels high.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid
            item
            container
            xs={6}
            justifyContent={isProcessing ? "flex-end" : "flex-start"}
          >
            {isProcessing ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}

            {isError ? (
              <Grid item>
                <Typography error>
                  <FormHelperText error>{errorMsg}</FormHelperText>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Grid item>
              {(isPricingAdmin)?<Button size="small" type="submit"
                variant="contained" color="primary" disabled={saved}>
                Save
              </Button>:null}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => props.job ? props.cancel() : cancelClick()}
                color="secondary"
                size="small"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" name="isDirty" value="true" ref={register()} />
        <input type="hidden" name="id" ref={register()} />
      </form>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id ? "Company Detials Updated successfully"
            : "Company Detials Created successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isItemRomve}
        autoHideDuration={3000}
        onClose={() => setIsRemoveItem(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setIsRemoveItem(false)}
          severity="success">
          Item Removed by Supplier List
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default CompanyForm;
