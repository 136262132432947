import React, { useState } from "react";
import {
  paymentModes, paymentTypes
} from "../common/lookupSlice";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CircularProgress,
  FormHelperText,
  makeStyles
} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    marginBottom: '10px'
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  notpayable: {
    color: '#ff0000'
  },
  payable: {
    color: '#008000'
  }
}));

const Payment = (props) => {

  const classes = useStyles();
  // const token = localStorage.getItem("authToken");
  const { handleSubmit, errors, register } = useForm({});
  const [value, setValue] = React.useState(1);
  const [payment, setPayment] = React.useState(props.isInvEdit && props.paymentDetails.paymentDetails ? props.paymentDetails.paymentDetails : '');
  const [paymentId, setPaymentId] = React.useState(props.isInvEdit && props.paymentDetails.paymentId ? props.paymentDetails.paymentId : '');
  const [isLoading, setIsLoading] = useState(false);
  const payTypes = useSelector(paymentModes);
  let chipData = useSelector(paymentTypes);
  chipData = (chipData && chipData.length > 0 && props.isDirectPayment) ? chipData.filter((data) => {
    return (data && (data.code !== "CREDIT")) ? true : false;
  }):chipData;
  const [paymentType, setPaymentType] = useState();
  const [amountPaid, setAmountPaid] = useState(0);

  const handleRadioChange = (event) => {
    const selectedPayment = Number(event.target.value);
    if (props.job) {
      props.setPaymentId(((props.calculate.total <= 0) || (paymentType && paymentType.id && paymentType.id === 3)) ? null : selectedPayment)
    }
    else {
      setValue(selectedPayment);
    }
  };

  const handleChange = (event, field) => {
    if (props.job) {
      if (field === "details") {
        props.setPaymentDetails(event.target.value)
      }
      else if (field === "id") {
        props.setPaymentData(event.target.value)
      }
    }
    else {
      if (field === "details") {
        setPayment(event.target.value)
      }
      else if (field === "id") {
        setPaymentId(event.target.value)
      }
    }
  }

  const handleCancel = () => {
    props.cancel()
  }
  const handleSave = async (data) => {
    setIsLoading(true)
    let sendData = { paymentType: paymentType, paymentMode: value, payment: payment, paymentId: paymentId };
    let payloadData = { ...sendData, ...data }
    try {
      if (props.isDirectPayment && props.submitDirectPayment) {
        props.submitDirectPayment(true, payloadData,
          (returnRes) => {
            setIsLoading(false)
            props.cancel && props.cancel()
          }
        )
      }else if (props.savePayments) {
        props.savePayments(true, payloadData,
          (returnRes) => {
            setIsLoading(false)
            props.cancel && props.cancel()
          }
        )
      }  
    }
    catch (err) {
      console.log("err", err);
      setIsLoading(false)
      props.setIsError(true)
      props.cancel()
    }

  }

  const rateValidation = (val) => {
    const valid = (props.calculate.total && props.calculate.total < 0) ? (Number(val) > 0 && Number(val) < ((props.calculate.total && (Math.round(props.calculate.total * 100) / 100).toFixed(2) * -1))) : (Number(val) > 0 && Number(val) < (props.calculate.total && (Math.round(props.calculate.total * 100) / 100).toFixed(2)));
    return valid;
  }

  const handleChipClick = (data) => {
    (data.code === "CREDIT") ? setValue(null) : setValue(1);
    setPaymentType(data);
    if (props.job) {
      props.setPaymentType(data);
    }
  }
  
  return (
    <div style={{ marginTop: '-10px' }}>
      <Grid item md={12} xs={12}>
        <div component="ul" className={classes.root}>
          {chipData.map((data) => {
            return (
              <li key={data.id}>
                <Chip label={data.description} className={classes.chip} onClick={() => handleChipClick(data)} icon={paymentType && paymentType.description && data.description === paymentType.description && <DoneIcon style={{ color: '#FFFFFF' }} />} style={{ color: '#FFFFFF', background: data.id === 1 ? '#6CC17E' : data.id === 2 ? '#618976' : '#2B5E43' }} />
              </li>
            );
          })}
        </div>
        <Typography>Payment Mode</Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1" value={props.job ? props.paymentId : value} onChange={handleRadioChange}>
            <Grid container md={12} xs={12}>
              {payTypes.map(x => {
                return <Grid item xs={6}><FormControlLabel
                  name="paymentType"
                  style={{ color: 'red8' }}
                  value={!((props.calculate.total <= 0) || (paymentType && paymentType.id && paymentType.id === 3)) && x.id}
                  control={<Radio disabled={paymentType ? ((((props.calculate.total <= 0)) || (paymentType.id && paymentType.id === 3)) ? true : false) : true} />}
                  label={x.description} />
                  <label style={{fontSize:"10px", paddingLeft:"30px"}}>{(x.code === "ONLINE")?"(UPI/QR/Card/Netbanking)":""}</label>
                </Grid>
              })}
            </Grid>
            <Box ml={4}style={{marginTop:"10px"}}>
              <Typography variant="caption">Payment ID (Transaction ID/Cheque No/Approval Code)</Typography>
              <TextField
                error={Boolean(errors.id)}
                variant="outlined"
                fullWidth
                size="small"
                name="id" disabled={paymentType ? ((((props.calculate.total <= 0)) || (paymentType.id && paymentType.id === 3)) ? true : false) : true}
                onChange={(e) => handleChange(e, "id")}
                inputRef={register({
                  required: value && (value !== 1 && props.paymentId !== 1) && "Enter Payment id",
                })}
              />
              <FormHelperText error>
                {errors.id && errors.id.message}
              </FormHelperText>
            </Box>
            <Box ml={4}>
              <Typography variant="caption">Payment Details (Bank & Branch Name,Payee Details,Issuing Bank/Card Type)</Typography>
              <TextField
                error={Boolean(errors.details)}
                variant="outlined"
                fullWidth
                size="small"
                name="details" disabled={paymentType ? ((((props.calculate.total <= 0)) || (paymentType.id && paymentType.id === 3)) ? true : false) : true}
                inputRef={register({
                  required: value && (value !== 1 && props.paymentId !== 1) && "Enter Payment details",
                })}
                onChange={(e) => handleChange(e, "details")}
              />
              <FormHelperText error>
                {errors.details && errors.details.message}
              </FormHelperText>
            </Box>
            <Box ml={4}>
              <Typography>Total Amount</Typography>
              <TextField
                variant="outlined"
                fullWidth
                size="small" value={props.calculate && props.calculate.total ? Math.round(props.calculate.total)  : 0}
                name="amount" disabled
              />
            </Box>
            <Box ml={4}>
              <Typography>Amount Paid </Typography>
              <TextField inputProps={{ className: classes.payable }}
                error={Boolean(errors.amountPaid)}
                variant="outlined"
                fullWidth
                size="small" 
                value={paymentType ? (paymentType.id && paymentType.id === 1 ? props.calculate.total && Math.round(props.calculate.total) : paymentType.id === 3 ? 0 : amountPaid) : amountPaid}
                name="amountPaid" disabled={paymentType ? (paymentType.id && paymentType.id !== 2 ? true : false) : true}
                inputRef={register({
                  required: (paymentType && paymentType.id && paymentType.id === 2) && "Amount is required",
                  pattern: {
                    value: /^(?:\d*\.\d{1,2}|\d+)$/,
                    message: "Provide a valid Amount",
                  },
                  validate: (paymentType && paymentType.id && paymentType.id === 2) && ((value) => rateValidation(value) || 'Amount should be greater than 0 and less than Total Amount')
                })}
                onChange={(e) => { setAmountPaid(e.target.value); (props.job) && props.setAmountPaid(e.target.value); }}
              />
              <FormHelperText error>
                {errors.amountPaid && errors.amountPaid.message}
              </FormHelperText>
            </Box>
          </RadioGroup>
        </FormControl>

        <Grid
          item
          container
          xs={12}
          spacing={2}
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          <Grid item xs={12}>
            <Box mt={4} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                {isLoading && <CircularProgress />}
              </Box>
              <Box mr={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary" disabled={!paymentType || isLoading}
                  onClick={handleSubmit(handleSave)}
                >
                  {(props.isDirectPayment)?"Generate Receipt":"Generate Purchase Invoice"}
                </Button></Box>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary" disabled={isLoading}
                  onClick={handleCancel}
                >
                  Cancel
                </Button></Box>
            </Box>
          </Grid>
        </Grid>
      </ Grid>
    </div>
  );
};

export default Payment;