import { TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const StyledHeaderCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
