import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from '../common/util';
import { withStyles } from '@material-ui/styles';
import _ from "lodash";

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '1.6',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};
const tableStyle = {
  border: '1px solid #ccc',
  borderCollapse: 'collapse',
}

class CompToPrintInv extends React.Component {
  render() {
    const { classes, loggedInUser, orgTermsAndConditions } = this.props;
    const invDetails = this.props.customerInv != null && this.props.customerInv;
    const lineItems = this.props.customerInv != null && this.props.customerInv.lineItems;
    let isSpecialLineItems = [];
    let subtotal = 0;
    const filterLineItems = _.filter(lineItems, (data, index) => {
      let isReturn = true;
      if (data.isSpecial) {
        isReturn = false;
        isSpecialLineItems.push(data);
      } else {
        subtotal += data.amount
      }
      return isReturn;
    })
    const customer = this.props.customerInv != null && this.props.customerInv.customer && this.props.customerInv.customer;
    const custContacts = customer && customer.contacts && customer.contacts;
    const hsnSummary = invDetails && invDetails.HSNSummary;
    let hsnTotal = 0;
    let hsnCGSTTotal = 0;
    let hsnSGSTTotal = 0;
    let hsnIGSTTotal = 0;
    hsnSummary && hsnSummary.map((row) => (
      hsnTotal += row.Total));
    hsnSummary && hsnSummary.map((row) => (
      hsnCGSTTotal += row.CGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnSGSTTotal += row.SGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnIGSTTotal += row.IGSTtotal));
    let quantity = 0;
    invDetails.lineItems && invDetails.lineItems.map((row) => (
      quantity += row.qty));
    const invType = this.props.customerInv != null && this.props.customerInv.type;
    const isDeposit = invType && invType.code === "DEPOSIT";
    let locationDetails = (this.props.job && this.props.job.location) ? this.props.job.location : null;
    let isIntraState = false;
    if (invDetails && invDetails.org) {
      isIntraState = (customer && customer.state && invDetails.org.state && (invDetails.org.state === customer.state)) ? true : false;
    }
    const companyPrintLogo = loggedInUser && loggedInUser.org && loggedInUser.org.printLogo;
    let orgTerms = "";
    if (orgTermsAndConditions && orgTermsAndConditions.length > 0) {
      let findOrgCond = orgTermsAndConditions.find((orgCond) => {
        return (orgCond && orgCond.termsConditionsType && (orgCond.termsConditionsType === "Job Estimate")) ? orgCond : null;
      })
      orgTerms = findOrgCond && findOrgCond.termsConditions
    }
    return (
      <div>
        <div align="center">
          <img alt="comLogo" src={companyPrintLogo}
            style={{ height: "100px", width: "200px" }} />
        </div>
        {isDeposit ?
          <div style={divStyle} align="center">
            {/* Deposit Reciept */}
            {/* <Helmet><title>Deposit Reciept</title></Helmet> */}
            <Helmet><title></title></Helmet>
            <div width="100%" style={{ textTransform: "uppercase" }}>
              <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name}</b></span><br />
              <span style={pStyle}>{invDetails && invDetails.location && (invDetails.location.address1)}</span>
              <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address2)}</div></span>
              <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address3 + '-' + invDetails.location.pincode)}</div></span>
              <span style={pStyle}>{invDetails && invDetails.location && invDetails.location.mobileNumber ? invDetails.location.mobileNumber : ""}<br /></span>
              <span style={pStyle}>State :{invDetails && invDetails.location && (invDetails.location.state)}  Code: {invDetails && invDetails.org && invDetails.org.GSTIN.slice(0, 2)}<br /></span>
              <span style={pStyle}>GSTIN/UIN: {(invDetails && invDetails.org && invDetails.org.GSTIN) ? invDetails.org.GSTIN : "Not Available"}<br /></span>
              <span style={pStyle}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /><br /></span>
              <span style={pStyle}><b>Receipt Voucher<br /><br /><br /></b></span>
            </div>
            <div style={divStyle}>
              <table width="80%" padding="2px">
                <tr>
                  <td width="70%"><span style={pStyle}>No. : <b>{this.props.customerInv && this.props.customerInv.invoiceNum}</b></span></td>
                  <td width="30%"><span style={pStyle}>Dated : <b>{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</b></span></td>
                </tr>
              </table>
            </div>
            <div style={divStyle}>
              <table width="80%" style={{ borderCollapse: 'collapse' }} padding="2px">
                <thead>
                  <tr>
                    <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="75%">&nbsp;&nbsp;Particulars</td>
                    <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="25%" align="right">Amount</td>
                  </tr>
                </thead>
                <tbody>
                  {lineItems && lineItems.length === 0 ? (
                    <tr>
                      <td colspan="" align="center">
                        No records found
                      </td>
                    </tr>
                  ) : (
                    lineItems && lineItems.map((row, index) => (
                      <tr
                        key={index}
                        hover={true}
                      >
                        <td align="left" style={{ padding: '5px', borderRight: '1px solid #ccc' }} width="75%">
                          <span style={pStyle}><b>Account : </b><br /></span>
                          <span style={pStyle}><span style={{ marginLeft: '50px' }}>{customer && customer.fullName}</span><br /></span>
                          <span style={pStyle} width="100%">
                            <span style={{ marginLeft: '50px' }}>Agst Ref : {row.productName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'} Cr</b></span>
                          </span><br /><br />
                          <span style={pStyle}><b>Through :</b><br /><span style={{ marginLeft: '50px' }}>{this.props.customerInv &&
                            this.props.customerInv.payments && this.props.customerInv.payments.length > 0 && this.props.customerInv.payments[0].paymentMode &&
                            this.props.customerInv.payments[0].paymentMode.description}</span><br /></span>
                          <span style={pStyle}><b>Amount (in words) :</b><br /><span style={{ marginLeft: '50px' }}>{invDetails && invDetails.amountInWords}</span></span>
                        </td>
                        <td align="right" valign="top">
                          <br /><br /><br />{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}
                        </td>
                      </tr>
                    ))
                  )}
                  <tr>
                    <td colspan="2" align="right" style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>{invDetails && invDetails.total ? (Math.round(invDetails.total * 100) / 100).toFixed(2) : '0.00'}</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="right"><span style={pStyle}><br /><br />Authorised Signatory</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> :
          <div style={divStyle} align="center">
            {/* Invoice  */}
            {/* <Helmet><title>Print Invoice</title></Helmet> */}
            <Helmet><title></title></Helmet>
            <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', textAlign: 'center', fontWeight: 'bold', lineHeight: '2', }}>Job Estimation</span>
            <table style={tableStyle} width="95%" border="1px" color="#ccc">
              <tr>
                <td rowSpan="2" width="50%" style={{ textTransform: "uppercase" }}>
                  <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name}<br /></b></span>
                  <span style={pStyle2}>
                    {locationDetails && (locationDetails.address1)}
                    <div style={{ whiteSpace: 'pre-line' }}>{locationDetails && (locationDetails.address2)}</div>
                    <div style={{ whiteSpace: 'pre-line' }}>{locationDetails && (locationDetails.address3 + '-' + locationDetails.pincode)}</div>
                    <span style={pStyle2}>{locationDetails && locationDetails.mobileNumber ? locationDetails.mobileNumber : ""}<br /></span>
                  </span>
                  <span style={pStyle2}>GSTIN/UIN: {(invDetails && invDetails.org && invDetails.org.GSTIN) ? invDetails.org.GSTIN : "Not Available"}<br /></span>
                  <span style={pStyle2}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /></span>
                </td>
                <td width="25%" height="10%" valign="top"><span style={pStyle2}>Job No. <br /><b>{this.props.customerInv && "JC-" + this.props.customerInv.jobNo}</b></span></td>
                <td width="25%" valign="top"><span style={pStyle2}>Dated. <br /><b>{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</b></span></td>
              </tr>
              <tr>
                <td colspan="2">
                  <span style={pStyle2}>
                    Customer <br /> <b>{customer && customer.fullName}</b><br />
                    {customer && (customer.address1 + ' \n' + customer.address2 + ' \n' + customer.address3 + ' \n' + customer.state + '-' + customer.pincode)}<br />
                    GSTIN/UIN: {customer && customer.GSTIN}<br />
                    State: {customer && customer.state} {(customer && customer.GSTIN) ? ' Code: ' + customer.GSTIN.slice(0, 2) : ' '}<br />
                    Contact Person: {custContacts ? custContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                    Contact: {custContacts ? custContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                  </span>
                </td>
              </tr>
            </table>
            <div style={{ width: '100%', padding: '5px 2px' }}>
              <table style={tableStyle} width="95%">
                <thead>
                  <tr>
                    <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Sl No.</span></td>
                    <td width="25%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Description of Goods/Services</span></td>
                    <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                    <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>GST Rate</span></td>
                    <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Quantity</span></td>
                    <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                    <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>per</span></td>
                    <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Disc.%</span></td>
                    <td width="15%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                  </tr>
                </thead>
                <tbody>
                  {filterLineItems && filterLineItems.length === 0 && isSpecialLineItems && isSpecialLineItems.length === 0 ? (
                    <tr>
                      <td colspan="9" align="center">
                        <span style={pStyle2}>No records found</span>
                      </td>
                    </tr>
                  ) : (
                    filterLineItems && filterLineItems.map((row, index) => (
                      <tr
                        key={index}
                        hover={true}
                      >
                        <td align="center" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {index + 1}
                          </span>
                        </td>
                        <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            <b>{row.productName}</b>
                          </span>
                        </td>
                        <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.HSNCode}
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.IGST} %
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            <b>{row.qty} {row.uom}</b>
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.unitPrice ? (Math.round(row.unitPrice * 100) / 100).toFixed(2) : ''}
                          </span>
                        </td>
                        <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.uom}
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.discountPct ? (Math.round(row.discountPct * 100) / 100).toFixed(2) : ''}
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            <b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</b>
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                  {filterLineItems && filterLineItems.length > 0 ?
                    <tr>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                        <span style={pStyle2}>{subtotal ? (Math.round(subtotal * 100) / 100).toFixed(2) : '0.00'}</span>
                      </td>
                    </tr>
                    : null}
                  {isSpecialLineItems && isSpecialLineItems.length > 0 && isSpecialLineItems.map((row, index) => (
                    <tr>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>{row.productName}</b></span></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}><b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</b></span>
                      </td>
                    </tr>
                  ))
                  }
                  {isIntraState && <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>CGST</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.CGST ? (Math.round(invDetails.CGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr>}
                  {isIntraState && <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>SGST</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.SGST ? (Math.round(invDetails.SGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr>}
                  {(!isIntraState) && <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>IGST</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.IGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr>}
                  {invDetails.roundOff !== 0 && <tr>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}>&nbsp;</td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><i>{invDetails.roundOff < 0 ? 'Less:' : ''}</i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={pStyle2}><b><i>Round Off</i></b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.roundOff ? (invDetails.roundOff > 0 ? (Math.round(invDetails.roundOff * 100) / 100).toFixed(2) : '(-)' + (Math.round(invDetails.roundOff * (-100)) / 100).toFixed(2)) : '0.00'}</b></span>
                    </td>
                  </tr>}
                  <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{quantity}</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle}><b>{invDetails.total ? (Math.round(Math.ceil(invDetails.total) * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.printTogether}>
              <table style={tableStyle} width="95%">
                <tbody>
                  <tr>
                    <td width="50%" style={{ borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>Amount Chargeable(in words) :</span>
                    </td>
                    <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}><i>E. &amp; O.E</i>&nbsp;</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle}><b>{invDetails.amountInWords}</b><br /><br /></span>
                    </td>
                  </tr>
                  <tr style={{ fontFamily: 'Arial, sans-serif' }}>
                    <td colspan="2">
                      <span style={{ margin: "auto", display: "table", textDecoration: "underline", fontSize: "16px" }}><b>Terms and Conditions</b></span>
                      <ol type="1" style={{ fontSize: "13px", fontFamily: 'Arial, sans-serif' }}>
                        {orgTerms}
                      </ol>
                    </td>
                  </tr>

                  <tr>
                    <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>Customer Seal and Signature<br /><br /><br /></span>
                    </td>
                    <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(CompToPrintInv);