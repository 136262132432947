import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon
} from "@material-ui/core";
import { currentUser } from "../login/loginSlice";
import quotations from "../../images/quotations.svg";
import salesPurchaseInvoice from "../../images/salesPurchaseInvoice.svg";
import supplierPurchaseOrder from "../../images/supplierPurchaseOrder.svg";
import materialRequest from "../../images/materialRequest.svg";
import estimate from "../../images/estimate.svg";
import supplier16 from "../../images/supplier16.svg";
import products16x16 from "../../images/products16x16.svg";

const SupplierPurchaseHome = (props) => {
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAdmin =
    roles.findIndex((role) => role.name === "ADMIN") > -1
      ? true
      : false;
  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
                Purchase
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Grid item container xs={12} md={4} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold", display: "inline-block" }}>Quick Links</ListSubheader>} ></List>
          <List style={{ display: "inline-flex", marginTop: "-20px" }}>
            <ListItem style={{ width: "200px", fontWeight: "bold" }}>
              <img src={salesPurchaseInvoice} alt="Purchase-Invoice" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/suppliers")}
              >
                Purchase Invoice
              </Link>
            </ListItem>
            <ListItem style={{ width: "300px" }}>
              <img src={supplierPurchaseOrder} alt="Supplier-Purchase-Orders" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/suppliers?from=purchaseorder")}
              >
                Supplier Purchase Orders
              </Link>
            </ListItem>
            {isAdmin ? <ListItem style={{ width: "200px" }}>
              <img src={products16x16} alt="products16x16" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/products")}
              >
                Product Master
              </Link>
            </ListItem> : null}
          </List>
        </Grid>
      </div>
      <div>
        <Grid item container xs={12} md={3} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold" }}>Purchase</ListSubheader>} >
            <ListItem>
              <ListItemIcon>
                <img src={salesPurchaseInvoice} alt="Purchase-Invoice" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/suppliers")}
              >
                Purchase Invoice
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={quotations} alt="Rquest-Quotation" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/supplierquotationrequests")}
              >
                Request Quotations
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={estimate} alt="Supplier-Quotation" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/suppliers?from=quotation")}
              >
                Supplier Quotations
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={supplierPurchaseOrder} alt="Supplier-Purchase-Orders" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/suppliers?from=purchaseorder")}
              >
                Supplier Purchase Orders
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={supplier16} alt="errInv" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/suppliers")}
              >
                Supplier Master
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={materialRequest} alt="material-requests" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/materialrequests")}
              >
                Material Request
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>
    </div>
  );
};

export default SupplierPurchaseHome;
