import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import _ from 'lodash';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { API_CUSTOMER_DISCOUNT } from "../../../config/constants";
import DateFnsUtils from "@date-io/date-fns";

import axios from "axios";
import { useParams } from "react-router-dom";

let errorMsg;

function CustomerDiscountForm(props) {
  const { id } = useParams();
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [date, setDate] = useState({start: props.form.data.startDate ? moment(props.form.data.startDate).format('DD/MM/YYYY'):null,end:props.form.data.endDate ? moment(props.form.data.endDate).format('DD/MM/YYYY'):null});
  const { handleSubmit, errors, register } = useForm({
    defaultValues: {
      id: props.form.data.id,
      customerId: props.form.data ? props.form.data.customerId : id,
      saleFromRange: props.form.data.saleFromRange,
      saleToRange: props.form.data.saleToRange,
      discount: props.form.data.discount && (Math.round(props.form.data.discount * 100) / 100).toFixed(2),
      startDate: date.start,
      endDate: date.end,
    },
  });

  const onSubmit = async (data) => {
    if(data.saleToRange && data.saleFromRange && (parseInt(data.saleToRange) > parseInt(data.saleFromRange))){
      data.customerId = id;
      var startDateArray = data.startDate.split("/");
      var endDateArray = data.endDate.split("/");
      data.startDate = startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2];
      data.endDate = endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2];
      setIsProcessing(true);
      setIsError(false);
      errorMsg = "";
      if (data.lineItems) {
        let modifiedItems = data.lineItems.map((obj) => {
          obj.productId = obj.contactId;
          delete obj["contactId"];
          return obj;
        });
        data.lineItems = JSON.stringify(modifiedItems);
      }
      try {
        if (props.form.mode === "C") {
          await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/customers/${data.customerId}/${API_CUSTOMER_DISCOUNT}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else if (props.form.mode === "U") {
          await axios.patch(
            `${process.env.REACT_APP_SERVICE_URL}/customers/${data.customerId}/${API_CUSTOMER_DISCOUNT}/${data.id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        setIsProcessing(false);
        props.success();
      } catch (err) {
        console.log(err.response.data.message);
        errorMsg = err.response.data.message;
        setIsError(true);
        setIsProcessing(false);
      }
    }else{
        errorMsg = "Sale Value To Range should be greater than From Range";
        setIsError(true);
      }
    };

  const handleDateChange = (value,type) => {
    const clonedDate = _.cloneDeep(date);
    clonedDate[type] = value
    setDate(clonedDate)
  };

  const dateValidation=()=>{
    if (props.form.mode === "U"){
      let sDate = date.start instanceof Date;
      let eDate = date.end instanceof Date;
      if(!sDate && !(date.start === null)){
        var startDateArray = date.start && date.start.split("/");
        date.start = new Date(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
      }
      if(!eDate && !(date.end === null)){
        var endDateArray = date.end && date.end.split("/");
        date.end = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
      }
    }
    const start = moment(date.start);
    const end = moment(date.end);
    const diff = end.diff(start, 'day');
    return diff >= 0 || !date.start || !date.end;
  }

  const startDateAvl=props.form.data.startDate ? moment(props.form.data.startDate):moment();
  const today=moment();
  const diff = today.diff(startDateAvl, 'day');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={6}>
            <Typography variant="caption">Sales Value From</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.saleFromRange)}
              type="number"
              inputRef={register({
                required: "Required",
                pattern: {
                  value: /^[0-9]/,
                  message: "minimum 1 digit is required",
                },
              })}
              InputProps={{
                autoFocus: true,
                name: "saleFromRange",
              }}
              size="small"
              name="saleFromRange"
            />
            <FormHelperText error>
              {errors.saleFromRange && errors.saleFromRange.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="caption">Sales Value To</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.saleToRange)}
              type="number"
              inputRef={register({
                required: "Required",
                pattern: {
                  value: /^[0-9]/,
                  message: "minimum 1 digit is required",
                },
              })}
              size="small"
              name="saleToRange"
            />
            <FormHelperText error>
              {errors.saleToRange && errors.saleToRange.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
        <Grid item md={6} xs={6}>
            <Typography variant="caption">Discount %</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.discount)}
              inputRef={register({
                required: "Discount is required",
                pattern: {
                  required: "Enter discount",
                  value: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/,
                  message: "Provide a valid discount rate",
                },
              })}
              size="small"
              name="discount"
            />
            <FormHelperText error>
              {errors.discount && errors.discount.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
        <Grid item md={6} xs={6}>
          <Typography variant="caption">Start Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    error={Boolean(errors.startDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    inputRef={register({
                      required: "Start date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                      validate:()=> dateValidation() || 'Start date should be lesser than end date'
                    })}
                    name="startDate"
                    value={date.start}
                    onChange={(value)=>handleDateChange(value,"start")}
                    disablePast={true}
                    minDateMessage=''
                    maxDate="9999-12-31"
                    disabled = {props.form.mode !== "C" && !(diff<=0)}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.startDate && errors.startDate.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={6}>
          <Typography variant="caption">End Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    error={Boolean(errors.endDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    inputRef={register({
                      required: "End date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                    })}
                    name="endDate"
                    value={date.end}
                    onChange={(value)=>handleDateChange(value,"end")}
                    disablePast={true}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.endDate && errors.endDate.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={12}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <input type="hidden" name="id" ref={register()} />
    </form>
  );
}

export default CustomerDiscountForm;