import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import axios from "axios";
import PrintIcon from '@material-ui/icons/Print';
import MailIcon from '@material-ui/icons/Mail';
import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import PurchaseForm from "./PurchaseForm";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";
import { useReactToPrint } from "react-to-print";
import { formatDate } from '../../common/util';
import { StyledHeaderCell } from "../../common/StyledHeaderCell";
import { API_ALL_CUSTOMERS, API_CUSTOMER_ORDER, API_PURCHASE_ORDER } from "../../../config/constants";
import { currentUser } from "../../login/loginSlice";
import { bankDetails, termsAndConditions, locations } from "../../common/lookupSlice"
import "../customers.css";
import { useSelector } from "react-redux";
import CompToPrintEstimate from '../../jobs/CompOrderToPrintEstimate';
import CompToPrintCustomerSaleOrder from '../CompToPrintCustomerSaleOrder';
import _ from "lodash";

const PurchaseOrder = (props) => {
  const pageSize = 10;
  const { id } = useParams();
  const location = useLocation();
  const customerTypeId = location.state;
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const orgBankDetails = useSelector(bankDetails);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  const allInventoryLocations = useSelector(locations);
  const  corporateLoc = allInventoryLocations.find((loc) => { return loc.type && loc.type.code === "CORPORATE"});
  let locId = corporateLoc && corporateLoc.id;
  const [costCenterList, setCostCenterList] = useState([]);
  const orgName = loggedInUser && loggedInUser.org && loggedInUser.org.name;
  const [bankList, setOrgBankListData] = useState([]);
  const isAdminOrAccountant = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role => (role.name === "ADMIN") ||  (role.name === "ACCOUNTANT"));
  const [count, setCount] = useState();
  const [custForm, setCustForm] = useState({ open: false });
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [name, setName] = useState();
  const [existCustData, setExistCustData] = useState();
  const [no, setNo] = useState();
  const componentRefEstimate = useRef();
  const componentRefOrder = useRef();
  const [quote, setQuote] = useState({});
  const token = localStorage.getItem("authToken");
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSendInvoice, setSendInvoice] = useState(false);
  const [popHeader, setPopHeader] = useState();
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [emailSendData, setEmailSendData] = useState({
    emailId: '',
    emailSubject: ''
  });
  let { emailId, emailSubject } = emailSendData;

  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isQuotation = (chars[1]) ? chars[1].toUpperCase() === "QUOTATION" : false;
  let isPO = (chars[1]) ? chars[1].toUpperCase() === "PURCHASEORDER" : false;

  const onClickPo = async (poNum) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/${poNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      let modifiedContacts = result.data.purchaseOrders.lineItems.map((obj) => {
        obj.contactId = obj.id;
        obj.negotiatedPrice = obj.negotiatedPrice && (Math.round(obj.negotiatedPrice * 100) / 100).toFixed(2)
        obj.discountPct = obj.discountPct && (Math.round(obj.discountPct * 100) / 100).toFixed(2)
        obj.productCode = obj.product && obj.product.productName
        delete obj["id"];
        return obj;
      });
      result.data.purchaseOrders.lineItems = modifiedContacts;
      setCustForm({
        ...custForm,
        data: result.data.purchaseOrders,
        mode: "U",
        open: true,
      });
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_CUSTOMERS}/${id}/${API_CUSTOMER_ORDER}?typeCode=${(isQuotation) ? "QUOTATION" : (isPO) ? "ORDER" : ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setExistCustData(result.data.customer);
      setName(result.data.customer.fullName);
      setNo(result.data.customer.mobileNumber);
      setCount(result.data.count);
      setData(result.data.orders);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  const defaultLoadData = async (COMPANY_NAME_IN_ERPNEXT) => {
    try {
      let queryCostCenter = {
        fields: ["name", "owner"],
        filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT, disabled: 0, is_group: 0 })
      }
      const resultCostCenter = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Cost Center`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: queryCostCenter,
        }
      );
      setCostCenterList(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data);
      const resultBankDetails = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/bankdetials`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      let orgBankList = (resultBankDetails.data && resultBankDetails.data.bankDetails && resultBankDetails.data.bankDetails.length > 0) ? resultBankDetails.data.bankDetails : [];
      setOrgBankListData(orgBankList);
    }
    catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    defaultLoadData(COMPANY_NAME_IN_ERPNEXT);
  }, [COMPANY_NAME_IN_ERPNEXT]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [custForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleEstimatePrint = useReactToPrint({
    content: () => componentRefEstimate.current,
  });
  const handleOrderPrint = useReactToPrint({
    content: () => componentRefOrder.current,
  });


  const setPoNumAction = async (id, type) => {
    try {
      let ser = [];
      const getData = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      ser = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.lineItems && getData.data.purchaseOrders.lineItems.length > 0) ? getData.data.purchaseOrders.lineItems : [];
      let customerData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.customer) ? getData.data.purchaseOrders.customer : {};
      let orgData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.org) ? getData.data.purchaseOrders.org : {};
      let orderType = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.type) ? getData.data.purchaseOrders.type : {};
      const lineItemsReq = ser && ser.map(x => {
        const lineItem = {
          productId: x.product && x.product.id && Number(x.product.id),
          qty: x.orderQty && Number(x.orderQty),
          overridenUnitPrice: x.negotiatedPrice && Number(x.negotiatedPrice),
          overridenDiscountPcnt: x.discountPct && Number(x.discountPct),
        }
        return lineItem;
      });
      const reqObj = {
        customerId: customerData.id,
        custTypeId: customerData.custTypeId,
        locationId: locId,
        lineItems: lineItemsReq
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
        reqObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let calculate = (result && result.data) ? result.data : null
      const lineItemData = calculate && calculate.lineItems ? calculate.lineItems.map((item, key) => {
        return {
          "lineNo": key + 1,
          "productName": item.productName,
          "HSNCode": item.HSNCode,
          "IGST": item.IGST,
          "CGST": item.CGST,
          "SGST": item.SGST,
          "uom": item.uom,
          "qty": item.qty,
          "unitPrice": item.unitPrice,
          "salePrice": item.salePrice,
          "discountPct": item.discountPct,
          "amount": item.amount,
          "isSpecial": item.isSpecial
        }
      }) : []
      let estimateData = {
        "OrderNo": id,
        "invoiceNum": id,
        "type": orderType,
        "invoiceDate": (getData && getData.data && getData.data.purchaseOrders.orderDate) ? getData.data.purchaseOrders.orderDate : new Date(),
        "subtotal": calculate.subtotal,
        "CGST": calculate.CGST,
        "SGST": calculate.SGST,
        "IGST": calculate.IGST,
        "total": calculate.total,
        "roundOff": calculate.total ? (Math.round((Math.round(calculate.total) - calculate.total) * 100) / 100).toFixed(2) : 0,
        "org": orgData,
        "lineItems": lineItemData,
        "customer": customerData,
        "amountInWords": calculate.amountInWords,
        "location": (getData && getData.data && getData.data.purchaseOrders.location) ? getData.data.purchaseOrders.location : null,
        "companyBankdetails": (getData && getData.data && getData.data.purchaseOrders.companyBankdetails) ? getData.data.purchaseOrders.companyBankdetails : null,
      }
      // return estimateData;
      setQuote({ ...quote, ...estimateData });
      if (type === "ORDER") {
        handleOrderPrint();
      } else {
        handleEstimatePrint();
      }

    }
    catch (err) {
      console.log(err);
    }
  }



  const openEmailInovice = async (poNum) => {
    try {
      let ser = [];
      const getData = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/${poNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      ser = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.lineItems && getData.data.purchaseOrders.lineItems.length > 0) ? getData.data.purchaseOrders.lineItems : [];
      let customerData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.customer) ? getData.data.purchaseOrders.customer : {};
      let orderType = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.type) ? getData.data.purchaseOrders.type : {};
      let orgData = (getData && getData.data && getData.data.purchaseOrders && getData.data.purchaseOrders.org) ? getData.data.purchaseOrders.org : {};
      const lineItemsReq = ser && ser.map(x => {
        const lineItem = {
          productId: x.product && x.product.id && Number(x.product.id),
          qty: x.orderQty && Number(x.orderQty),
          overridenUnitPrice: x.negotiatedPrice && Number(x.negotiatedPrice),
          overridenDiscountPcnt: x.discountPct && Number(x.discountPct),
        }
        return lineItem;
      });
      const reqObj = {
        customerId: customerData.id,
        custTypeId: customerData.custTypeId,
        locationId: locId,
        lineItems: lineItemsReq
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
        reqObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let calculate = (result && result.data) ? result.data : null
      const lineItemData = calculate && calculate.lineItems ? calculate.lineItems.map((item, key) => {
        return {
          "lineNo": key + 1,
          "productName": item.productName,
          "HSNCode": item.HSNCode,
          "IGST": item.IGST,
          "CGST": item.CGST,
          "SGST": item.SGST,
          "uom": item.uom,
          "qty": item.qty,
          "unitPrice": item.unitPrice,
          "salePrice": item.salePrice,
          "discountPct": item.discountPct,
          "amount": item.amount,
          "isSpecial": item.isSpecial
        }
      }) : []
      let estimateData = {
        "OrderNo": poNum,
        "invoiceNum": poNum,
        "type": orderType,
        "invoiceDate": (getData && getData.data && getData.data.purchaseOrders.orderDate) ? getData.data.purchaseOrders.orderDate : new Date(),
        "subtotal": calculate.subtotal,
        "CGST": calculate.CGST,
        "SGST": calculate.SGST,
        "IGST": calculate.IGST,
        "total": calculate.total,
        "roundOff": calculate.total ? (Math.round((Math.round(calculate.total) - calculate.total) * 100) / 100).toFixed(2) : 0,
        "org": orgData,
        "lineItems": lineItemData,
        "customer": customerData,
        "amountInWords": calculate.amountInWords,
        "location": (getData && getData.data && getData.data.purchaseOrders.location) ? getData.data.purchaseOrders.location : null,
        "companyBankdetails": (getData && getData.data && getData.data.purchaseOrders.companyBankdetails) ? getData.data.purchaseOrders.companyBankdetails : null,
      }
      // return estimateData;
      setQuote({ ...quote, ...estimateData });
      setSendInvoice(true);
      setPopHeader(orderType && orderType.code === "ORDER" ? "Mail Customer Purchase Order" : "Mail " + orderType.description);
      setEmailSendData({
        emailSubject: orderType && orderType.code === "ORDER" ? `${orgName} - Customer Purchase Order` : `${orgName} - ` + orderType.description,
        emailId: customerData && customerData.email
      })
    }
    catch (err) {
      console.log(err);
    }
  }

  const onCloseSendInvoice = () => {
    setSendInvoice(false);
    setEmailSendData({ emailId: '', emailSubject: '' })
  }
  const onSendEmailInvoice = async () => {
    let isEmail = false;
    let isSubject = (emailSubject && emailSubject.length > 1) ? true : false;
    if (emailId && emailId.length > 0) {
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds, (data) => {
        if (!mailformat.test(String(data).toLowerCase())) {
          isEmail = false;
        }
      })
    }
    if (isEmail && isSubject) {
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData;
      if (quote && quote.type && quote.type.code && quote.type.code === "ORDER") {
        invoiceBodyData = () => {
          return (
            <CompToPrintCustomerSaleOrder ref={componentRefOrder} customerInv={quote} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
          )
        }
      } else {
        invoiceBodyData = () => {
          return (
            <CompToPrintEstimate ref={componentRefEstimate} customerInv={quote} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
          )
        }
      }

      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to', emailId)
      bodyFormData.append('subject', emailSubject)
      bodyFormData.append('invoice', renderToString(invoiceBodyData()))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
        bodyFormData,
        config
      );
      if (result.status === 200) {
        setIsSuccess(true)
        setSendInvoice(false);
        setEmailSendData({ emailId: '' })
      }
    } else {
      if (!isEmail) {
        setEmailHelperText(true);
      }
      if (!isSubject) {
        setEmailSubjectHelperText(true);
      }
    }
  }
  return (
    <div>
      {props.modal ? null : (
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push(
                  (isPO) ? "/customers?from=purchaseorder"
                    : (isQuotation) ? "/customers?from=quotation"
                      : "/customerForm/" + id)}>
                  {(isPO) ? "Customer Purchase Order"
                    : (isQuotation) ? "Quotation"
                      : "Customers"}
                </Link>
                <Typography component="span" color="textPrimary">
                  {(isQuotation) ? "Customer Quotation"
                    : "Customer Order"}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1} style={{ marginTop: '2%' }}>
            <Grid item xs={12} md={7}>
              <Typography>
                Customer Number : {no} | Customer Name: {name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} >
              {isAdminOrAccountant  && <Button color="primary" variant="contained" style={{ float: 'right', marginRight: "15px" }}
                onClick={() => {
                  setCustForm({ ...custForm, data: {}, mode: "C", open: true });
                }}> Add New {(isQuotation) ? "Quotation" : "Order"} </Button>}

            </Grid>
          </Grid>
        </Grid>
      )}
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Customer Purchase Order</StyledHeaderCell>
                <StyledHeaderCell align="left">Order Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Status</StyledHeaderCell>
                <StyledHeaderCell align="center">Print</StyledHeaderCell>
                <StyledHeaderCell align="center">Email</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => {
                    return (
                      <TableRow
                        style={{ cursor: props.modal ? "pointer" : "" }}
                        key={row.id}
                        hover={true}
                        onClick={(e) =>
                          props.onRowSelect && props.onRowSelect(row)
                        }>
                        <TableCell align="left" className="underLine" style={{ cursor: "pointer" }}>
                          <Link onClick={() => {
                            onClickPo(row.poNum);
                          }}>{row.poNum}
                          </Link></TableCell>

                        <TableCell align="left">{row.orderDate ? formatDate(row.orderDate) : ''}</TableCell>
                        <TableCell align="left">{row.status.description}</TableCell>
                        <TableCell align="center">
                          <span onClick={() => { setPoNumAction(row.poNum, row.type && row.type.code) }}>
                            <PrintIcon />
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span onClick={() => openEmailInovice(row.poNum)}>
                            <MailIcon />
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Box display="none">
        <CompToPrintEstimate ref={componentRefEstimate} customerInv={quote} isQuotation={isQuotation} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
        <CompToPrintCustomerSaleOrder ref={componentRefOrder} customerInv={quote} isQuotation={isQuotation} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={custForm.open}
        onClose={() => {
          setCustForm({ ...custForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={10} alignItems="center">
              <Grid item>
                {custForm.mode === "C"
                  ? (isQuotation) ? "Add Quotation" : "Add Customer Purchase Order"
                  : isAdminOrAccountant  ? (isQuotation) ? "Edit Quotation" : "Edit Customer Purchase Order"
                    : (isQuotation) ? "Quotation Details" : "Customer Purchase Order Details"}
              </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    setCustForm({ ...custForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <PurchaseForm
            form={custForm}
            isQuotation={isQuotation}
            isPO={isPO}
            cancel={() => {
              setCustForm({ ...custForm, open: false });
            }}
            success={() => {
              setCustForm({ ...custForm, open: false, success: true });
            }}
            readOnly={!isAdminOrAccountant }
            customerTypeId={customerTypeId}
            existCustData={existCustData}
            costCenterList={costCenterList}
            orgBankList={bankList}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {popHeader ? popHeader : "Email Customer Purchase Order"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '180px' }}>
          <Grid container>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span" >Mail to *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                value={emailId}
                onChange={e => setEmailSendData({ ...emailSendData, emailId: e.target.value })}
              />
              {isEmailHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter valid email id
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: '10px' }}>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span">Subject *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                value={emailSubject}
                onChange={e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value })}
              />
              {isEmailSubjectHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter email subject
              </FormHelperText>}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              onClick={onSendEmailInvoice}
              color="secondary"
              size="small"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={custForm.success}
        onClose={() => setCustForm({ ...custForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCustForm({ ...custForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {custForm.mode === "C"
            ? "Customer Purchase Order created successfully"
            : "Customer Purchase Order updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
        autoHideDuration={3000}
      >
        <MuiAlert
          onClose={() => {
            setIsSuccess(false);
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          Mail Sent successfully
        </MuiAlert>
      </Snackbar>

      <Grid item>

      </Grid>
    </div>
  );
};

export default PurchaseOrder;
