import React, { useState, useRef, useEffect } from "react";

import axios from "axios";

import {
  Typography,
  Box,
  TextField,
  Snackbar,
  FormControl,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

import { API_ALL_PRODUCTS } from "../../config/constants";

import "./products.css";
const ServicePartsProducts = (props) => {
  const pageSize = 10;
  const [count, setCount] = useState();
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize, custTypeId: props.custTypeId, locationId: props.locationId });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const itemNameRef = useRef();
  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/pos/${API_ALL_PRODUCTS}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.products);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      productName: itemNameRef.current.value,
      page: 1,
    });
  };

  return (
    <div>
      <div className="user-filter-grids">
        <Typography variant="h6">
          {props.modal ? "Product Selection" : "Product Search"}
        </Typography>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={props.modal ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={itemNameRef}
                    InputProps={{
                      name: "itemName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" md={2} xs={6}>
              <Grid item>
                <Box mt={2}>
                  <Button variant="contained" color="primary" size="small" autoFocus onClick={(e)=>handleSearch(e)}>
                  Search </Button>
              </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Available Qty</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Price</StyledHeaderCell>
                <StyledHeaderCell align="left">Location</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={props.modal ? 6 : 7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 6 : 7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      style={{ cursor: props.modal ? "pointer" : "" }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }>
                      <TableCell component="th" scope="row">{row.product && row.product.productName}</TableCell>
                      <TableCell align="right">
                        {row.availableQty && Math.round(row.availableQty)}
                      </TableCell>
                      <TableCell align="right">
                        {row.product && row.product.price && (Math.round(row.product.price * 100) / 100).toFixed(2)}
                      </TableCell>
                      <TableCell align="left">
                        {row.location && row.location.name}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ServicePartsProducts;
