import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { modalProps } from "./confirmationDialogSlice";

const ConfirmationDialog = (props) => {
  const dialogProps = useSelector(modalProps);
  const confirmationAction = (value) => {
    dialogProps.handleCloseModal(value);
  };

  return (
    <Dialog
      style={{ minHeight: 100 }}
      open={dialogProps.isModalOpen}
      onClose={() => {
        confirmationAction(false);
      }}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogProps.dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {dialogProps.isProcessing ? <CircularProgress /> : null}
        <Button
          variant="contained"
          onClick={() => confirmationAction(false)}
          color="primary"
          size="small"
          autoFocus
        >
          {dialogProps.primaryBtnText}
        </Button>
        <Button
          disableFocusRipple
          size="small"
          variant="contained"
          onClick={() => confirmationAction(true)}
          color="secondary"
        >
          {dialogProps.secondayBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
