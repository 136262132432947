import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice"
import axios from "axios";
import { Breadcrumbs, Grid, Link, Typography, FormControl, Select, MenuItem, FormControlLabel, Checkbox, FormHelperText, Box, Button } from "@material-ui/core";

const ERPNextProfitAndLossReport = (props) => {
  const history = useHistory();
  const [columsWithSubRows, setColumns] = useState([]);
  const [dataWithSubRows, setData] = useState([]);
  const token = localStorage.getItem("authToken");

  const loggedInUser = useSelector(currentUser);
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;

  const [fiscalYearList, setFiscalYearList] = useState([]);
  const [costCenterList, setCostCenterList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currentFiscalYear, setCurrentFiscalYear] = useState(null);
  const [isFiscalYearError, setFiscalYearError] = useState(false);
  const [fiscalYearDate, setFiscalYearDate] = useState({ fiscalYearStartDate: '', fiscalYearEndDate: '' });
  const [tempFilters, setTempFilter] = useState({
    company: COMPANY_NAME_IN_ERPNEXT,
    selected_view: 'Report',
    presentation_currency: '',
    filter_based_on: '',
    periodicity: '',
    from_fiscal_year: '',
    to_fiscal_year: '',
    period_start_date: '',
    period_end_date: '',
    cost_center: [],
    project: [],
    finance_book: '',
    accumulated_values: 0
  });

  const [filters, setFilter] = useState({
    company: COMPANY_NAME_IN_ERPNEXT,
    selected_view: 'Report',
    presentation_currency: '',
    filter_based_on: '',
    periodicity: '',
    from_fiscal_year: '',
    to_fiscal_year: '',
    period_start_date: '',
    period_end_date: '',
    cost_center: [],
    project: [],
    finance_book: '',
    accumulated_values: 0
  });

  let getFiscalYearDate = (fiscalYear) => {
    const [startYear, endYear] = fiscalYear.split('-').map(Number);

    // Assuming fiscal year starts on April 1st
    const fiscalYearStartMonth = 3;

    const fiscalYearStartDate = new Date(startYear, fiscalYearStartMonth, 1);
    const fiscalYearEndDate = new Date(endYear, fiscalYearStartMonth, 0);
    return { fiscalYearStartDate: fiscalYearStartDate, fiscalYearEndDate: fiscalYearEndDate }
  }

  const getCurrentFiscalYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    // Assuming fiscal year starts on April 1st
    const fiscalYearStartMonth = 3;
    if (today.getMonth() < fiscalYearStartMonth) {
      // Current date is before the fiscal year start month
      return `${currentYear - 1}-${currentYear}`;
    } else {
      // Current date is on or after the fiscal year start month
      return `${currentYear}-${currentYear + 1}`;
    }
  };

  const loadData = async (authToken, filters, currentFiscalYear, dataConvert) => {
    try {
      let query = {
        report_name: 'Balance Sheet',
        filters: `${JSON.stringify(filters)}`
      }
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextreport/balanceSheet`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: query,
        }
      );
      if (result && result.data && result.data.data.length > 0) {
        let resData;
        if (filters && filters.presentation_currency) {
          resData = await dataConvert(result.data.data, `${filters.presentation_currency}`);
        } else {
          resData = await dataConvert(result.data.data, "INR");
        }
        setData(resData);
        setColumns(result.data.columns);
      } else if (result && result.data && result.data.data.length === 0) {
        setData([]);
        setColumns(result.data.columns);
      }

    } catch (err) {
      setColumns([]);
      setData([]);
    }
  };

  let getDefaultData = async (authToken, COMPANY_NAME_IN_ERPNEXT) => {
    let queryCostCenter = {
      fields: ["name", "owner"],
      filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT })
    }
    const resultCostCenter = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Cost Center`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: queryCostCenter,
      }
    );
    setCostCenterList(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data);

    let queryFiscalYear = {
      fields: ["name", "owner"]
    }
    const resultFiscalYear = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Fiscal Year`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: queryFiscalYear,
      }
    );
    setFiscalYearList(resultFiscalYear && resultFiscalYear.data && resultFiscalYear.data.data)

    let queryCurrency = {
      fields: ["name", "owner"],
      filters: JSON.stringify({ enabled: 1 })
    }
    const resultCurrency = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Currency`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: queryCurrency,
      }
    );
    setCurrencyList(resultCurrency && resultCurrency.data && resultCurrency.data.data)
  }

  useEffect(() => {
    getDefaultData(token, COMPANY_NAME_IN_ERPNEXT);
  }, [token, COMPANY_NAME_IN_ERPNEXT]);

  useEffect(() => {
    const formatNumbersInJSON = (data, currency) => {
      const formattedData = JSON.parse(JSON.stringify(data)); // Create a deep copy of the original data

      const traverse = (node) => {
        for (const key in node) {
          if (typeof node[key] === 'object' && node[key] !== null) {
            traverse(node[key]);
          } else if (typeof node[key] === 'number') {
            // Format numbers with two decimal places and currency symbol
            node[key] = node[key].toLocaleString('en-IN', {
              style: 'currency',
              currency,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          }
          // else if (typeof node[key] === 'string') {
          //   // Remove single quotes in strings
          //   node[key] = node[key].replace(/'/g, '');
          // }
        }
      };

      traverse(formattedData);
      return formattedData;
    };
    if (currentFiscalYear) {
      loadData(token, filters, currentFiscalYear, formatNumbersInJSON);
    } else {
      let fiscalYear = getCurrentFiscalYear()
      let getFiscalYearDateRes = getFiscalYearDate(fiscalYear);
      setFiscalYearDate({ fiscalYearStartDate: getFiscalYearDateRes.fiscalYearStartDate, fiscalYearEndDate: getFiscalYearDateRes.fiscalYearEndDate })
      setCurrentFiscalYear(fiscalYear);
      setTempFilter({
        company: COMPANY_NAME_IN_ERPNEXT,
        selected_view: 'Report',
        presentation_currency: 'INR',
        filter_based_on: 'Fiscal Year',
        periodicity: 'Yearly',
        from_fiscal_year: fiscalYear,
        to_fiscal_year: fiscalYear,
        period_start_date: '',
        period_end_date: '',
        cost_center: [],
        project: [],
        finance_book: '',
        accumulated_values: 0
      })
      setFilter({
        ...filters,
        presentation_currency: 'INR',
        selected_view: 'Report',
        filter_based_on: 'Fiscal Year',
        periodicity: 'Yearly',
        from_fiscal_year: fiscalYear,
        to_fiscal_year: fiscalYear
      })
    }

  }, [token, filters, currentFiscalYear, COMPANY_NAME_IN_ERPNEXT]);

  const extractYears = (financeYear) => {
    const [start, end] = financeYear.split('-');
    return { start: parseInt(start), end: parseInt(end) };
  };
  const compareFinanceYears = (startYear, endYear) => {

    if (startYear && endYear) {
      const { start: start1 } = extractYears(startYear);
      const { start: start2 } = extractYears(endYear);
      console.log("startYear", start1);
      console.log("endYear", start2);
      if (start1 === start2) {
        setFiscalYearError(false)
      } else if (start1 > start2) {
        setFiscalYearError(true)
      }
    }

  };

  const handleSearch = (data) => {
    // data.preventDefault();
    // let from_date = "";
    // let to_date = "";
    // if (data && data.from_date) {
    //   from_date = moment(data.from_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    // }
    // if (data && data.to_date) {
    //   to_date = moment(data.to_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    // }
    setFilter({
      ...filters,
      selected_view: tempFilters.selected_view,
      presentation_currency: tempFilters.presentation_currency,
      filter_based_on: tempFilters.filter_based_on,
      periodicity: tempFilters.periodicity,
      from_fiscal_year: tempFilters.from_fiscal_year,
      to_fiscal_year: tempFilters.to_fiscal_year,
      period_start_date: tempFilters.period_start_date,
      period_end_date: tempFilters.period_end_date,
      cost_center: tempFilters.cost_center,
      project: tempFilters.project,
      finance_book: tempFilters.finance_book,
      accumulated_values: (tempFilters.accumulated_values) ? 1 : 0
    });
  };

  const handleReset = (e) => {
    setTempFilter({
      company: COMPANY_NAME_IN_ERPNEXT,
      selected_view: 'Report',
      presentation_currency: 'INR',
      filter_based_on: 'Fiscal Year',
      periodicity: 'Yearly',
      from_fiscal_year: currentFiscalYear,
      to_fiscal_year: currentFiscalYear,
      period_start_date: '',
      period_end_date: '',
      cost_center: [],
      project: [],
      finance_book: '',
      accumulated_values: 0
    });
    setFilter({
      ...filters,
      company: COMPANY_NAME_IN_ERPNEXT,
      selected_view: 'Report',
      presentation_currency: 'INR',
      filter_based_on: 'Fiscal Year',
      periodicity: 'Yearly',
      from_fiscal_year: currentFiscalYear,
      to_fiscal_year: currentFiscalYear,
      period_start_date: '',
      period_end_date: '',
      cost_center: [],
      project: [],
      finance_book: '',
      accumulated_values: 0
    });
  }

  const columns = useMemo(
    () => columsWithSubRows,
    [columsWithSubRows],
  );
  const data = useMemo(
    () => dataWithSubRows,
    [dataWithSubRows],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: true,
    enableExpanding: true,
    getSubRows: (row) => row.subRows,
    initialState: { expanded: false },
    paginateExpandedRows: false,
    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableTopToolbar: true,
    enableFilters: false,
    enableDensityToggle: false,
    enableHiding: false,
    getRowId: (row) => row.account
  });

  console.log("fiscalYearDate", fiscalYearDate);

  return (
    <>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href=""
                onClick={() => history.push("/")}
              >
                Home
              </Link>
              <Link color="inherit" href=""
                onClick={() => history.push("/reports")}
              >
                Reports
              </Link>
              <Typography component="span" color="textPrimary">
                Profit and Loss
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Filter Based on</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                disabled
              >
                <Select
                  id="filter_based_on-select"
                  value={tempFilters.filter_based_on}
                  onChange={(e) => {
                    setTempFilter({ ...tempFilters, filter_based_on: e.target.value });
                  }}
                >
                  <MenuItem key={`filter_based_on-0`} value={'Fiscal Year'}>
                    Fiscal Year
                  </MenuItem>
                  <MenuItem key={`filter_based_on-1`} value={'Date Range'}>
                    Date Range
                  </MenuItem>
                </Select>
              </FormControl>

            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Start Year</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  id="from_fiscal_year-select"
                  value={tempFilters.from_fiscal_year}
                  onChange={(e) => {
                    console.log("e.target.value", e.target.value);
                    setTempFilter({ ...tempFilters, from_fiscal_year: e.target.value });
                    compareFinanceYears(e.target.value, tempFilters.to_fiscal_year);
                  }}
                >
                  {fiscalYearList && fiscalYearList.length > 0 && fiscalYearList.map(function (fiscalYear, index) {
                    return (
                      <MenuItem key={`fiscalYear_from-${index + 1}`} value={fiscalYear.name}>
                        {fiscalYear.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {isFiscalYearError && <FormHelperText error={true}>
                Fiscal Start Year it should be less than Fiscal End Year
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">End Year</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  id="to_fiscal_year-select"
                  value={tempFilters.to_fiscal_year}
                  onChange={(e) => {
                    setTempFilter({ ...tempFilters, to_fiscal_year: e.target.value });
                    compareFinanceYears(tempFilters.from_fiscal_year, e.target.value);
                  }}
                >
                  {fiscalYearList && fiscalYearList.length > 0 && fiscalYearList.map(function (fiscalYear, index) {
                    return (
                      <MenuItem key={`fiscalYear_to-${index + 1}`} value={fiscalYear.name}>
                        {fiscalYear.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {isFiscalYearError && <FormHelperText error={true}>
                Fiscal End Year it should be greater than Fiscal Start Year
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Periodicity</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  id="periodicity-select"
                  value={tempFilters.periodicity}
                  onChange={(e) => {
                    setTempFilter({ ...tempFilters, periodicity: e.target.value });
                  }}
                >
                  <MenuItem key={`periodicity-0`} value={'Monthly'}>
                    Monthly
                  </MenuItem>
                  <MenuItem key={`periodicity-0`} value={'Quarterly'}>
                    Quarterly
                  </MenuItem>
                  <MenuItem key={`periodicity-0`} value={'Half-Yearly'}>
                    Half-Yearly
                  </MenuItem>
                  <MenuItem key={`periodicity-0`} value={'Yearly'}>
                    Yearly
                  </MenuItem>
                </Select>
              </FormControl>

            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Currency</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  id="currency-select"
                  value={tempFilters.presentation_currency}
                  onChange={(e) => {
                    setTempFilter({ ...tempFilters, presentation_currency: e.target.value });
                  }}
                >
                  {currencyList && currencyList.length > 0 && currencyList.map(function (currency, index) {
                    return (
                      <MenuItem key={`currency-${index + 1}`} value={currency.name}>
                        {currency.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Cost Center</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  id="Cost-Center-select"
                  multiple
                  value={tempFilters.cost_center}
                  onChange={(e) => {
                    console.log("e.target.value", e.target.value);
                    setTempFilter({ ...tempFilters, cost_center: e.target.value });
                  }}
                >
                  {costCenterList && costCenterList.length > 0 && costCenterList.map(function (cost_center, index) {
                    return (
                      <MenuItem key={`cost_center-${index + 1}`} value={cost_center.name}>
                        {cost_center.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container md={4} xs={6}>
            <FormControlLabel
              fullWidth
              variant="outlined"
              size="small"
              label="Accumulated Values"
              control={
                <Checkbox
                  value={tempFilters.accumulated_values}
                  onChange={(e, val) => {
                    setTempFilter({ ...tempFilters, accumulated_values: val });
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item container justifyContent={"flex-end"} md={12} xs={12}>
            <Box mt={2} mr={2}>
              <Button
                size="small"
                onClick={handleSearch}
                variant="contained"
                color="primary"
              >
                Generate Report
              </Button>
            </Box>
            <Box mt={2}>
              <Button size="small"
                onClick={handleReset}
                variant="contained"
                color="secondary">
                clear
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
      <Grid style={{ padding: "10px" }}></Grid>
      {columsWithSubRows && columsWithSubRows.length > 0 ?
        <MaterialReactTable table={table} /> :
        <label>Report Data not found</label>}
    </>
  );
};

export default ERPNextProfitAndLossReport;
