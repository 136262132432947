import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Box,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  FormControl,
  FormControlLabel,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Checkbox,
  Select,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import EditIcon from "@material-ui/icons/Edit";
import CustomerForm from "./CustomerForm";
import CustomerFullForm from "./CustomersForm";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";

import { StyledHeaderCell } from "../common/StyledHeaderCell";
import { localities } from "../common/lookupSlice";
import { API_ALL_CUSTOMERS } from "../../config/constants";

import "./customers.css";
import { currentUser } from "../login/loginSlice";

const Customer = (props) => {
  const isBillingAgent = props.isBillingAgent;
  const availCustLocalities = useSelector(localities);
  const custLocalities = availCustLocalities ? availCustLocalities.filter(loc => loc !== "") : [];
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  const pageSize = 10;
  const history = useHistory();
  const location = useLocation();
  const customerData = location.state && !(location.state.click);
  const [count, setCount] = useState();
  const [custForm, setCustForm] = useState({ data: {}, open: false });
  const [searchLocality, setSearchLocality] = useState("");
  const [deepMobileNumSearch, setDeepMobileNumSearch] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fullNameRef = useRef();
  const emailRef = useRef();
  const mobileNumberRef = useRef();
  const token = localStorage.getItem("authToken");

  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isQuotation = (chars[1]) ? chars[1].toUpperCase() === "QUOTATION" : false;
  let isPO = (chars[1]) ? chars[1].toUpperCase() === "PURCHASEORDER" : false;
  let isCreditNote = (chars[1]) ? chars[1].toUpperCase() === "CREDITNOTE" : false;
  const resetQuery = () => {
    fullNameRef.current.value = "";
    emailRef.current.value = "";
    mobileNumberRef.current.value = "";
    setSearchLocality("");
    setDeepMobileNumSearch(false);
    setQuery({
      ...query,
      fullName: fullNameRef.current.value,
      email: emailRef.current.value,
      mobileNumber: mobileNumberRef.current.value,
      mobNumInContacts: false,
      locality: "",
      page: 1,
    });
  };
  const handleCustomerClick = (row) => {
    if (isPO) {
      history.push({
        pathname: `/customerOrder/${row.id}?from=purchaseorder`,
        state: row
      })
    } else if (isQuotation) {
      history.push({
        pathname: `/customerOrder/${row.id}?from=quotation`,
        state: row
      })
    } else if (isCreditNote) {
      history.push({
        pathname: `/customerForm/${row.id}?from=creditnote`,
        state: row
      })
    } else {
      history.push({
        pathname: `/customerForm/${row.id}`,
        state: row
      })
    }
  }

  const handleCustomerIdClick = (row) => {
    if (isPO) {
      history.push({ state: row })
      window.location.href = `/customerOrder/${row.id}?from=purchaseorder`;
    } else if (isQuotation) {
      history.push({ state: row })
      window.location.href = `/customerOrder/${row.id}?from=quotation`;
    } else if (isCreditNote) {
      history.push({
        pathname: "/creditnote",
        state: row
      })
    } else {
      history.push({
        pathname: "/recordSales",
        state: row
      })
    }
  }

  const handleNewCust = () => {
    if (isPO) {
      window.location.href = `/customerForm?from=purchaseorder`;
    } else if (isQuotation) {
      window.location.href = `/customerForm?from=quotation`;
    } else if (isCreditNote) {
      window.location.href = `/customerForm?from=creditnote`;
    }
  }

  const handlePOSCustomer = (row) => {
    history.push("/posCustomerForm/" + row.id)
  }
  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_CUSTOMERS}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.customers);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      fullName: e.target.elements.fullName.value,
      email: e.target.elements.email.value,
      mobileNumber: e.target.elements.mobileNumber.value,
      locality:
        e.target.elements.locality.value !== "All"
          ? e.target.elements.locality.value
          : "",
      page: 1,
    });
  };

  // On success of add or edit, reset the query to load data again
  useEffect(() => {
    if (custForm.success) {
      resetQuery();
    }
  }, [custForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    (customerData && ((typeof customerData) === "object")) ? <Grid container >
      <Grid item xs={12} >
        <Box style={{ fontWeight: "600", fontSize: '20px' }}>
          Selected Customer
        </Box>
      </Grid>
      <Grid item xs={12} >
        <Box mt={1} >
          <span style={{ fontWeight: "600" }}>Name:</span> {customerData.fullName}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={1} >
          <span style={{ fontWeight: "600" }}>Mobile number:</span> {customerData.mobileNumber ? customerData.mobileNumber : 'NA'}
        </Box>
      </Grid>
      <Grid item xs={12} >
        <Box mt={1} >
          <span style={{ fontWeight: "600" }}>Locality:</span> {customerData.locality ? customerData.locality : 'NA'}
        </Box>
      </Grid>
      <Grid item xs={12} >
        <Box mt={1} >
          <span style={{ fontWeight: "600" }}>GSTIN:</span> {customerData.GSTIN ? customerData.GSTIN : 'NA'}
        </Box>
      </Grid>
    </Grid> :
      <div>
        {props.modal ? props.job ? <Grid item justify="flex-end" container>
          <Button
            color="primary"
            variant="contained"
            onClick={() => props.job ? setCustForm({ ...custForm, mode: "C", open: true }) : (isPO || isQuotation || isCreditNote) ? handleNewCust() : history.push("/customerForm")}
          >
            Add Customer
          </Button>
        </Grid> : null : (
          <Grid container>
            <Grid item container xs={6} direction="column">
              <Grid item>
                {!isBillingAgent && <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                  <Link color="inherit" href="" onClick={() => history.push("/")}>
                    Home
                  </Link>
                  {(isCreditNote) ? <Link color="inherit" href="" onClick={() => history.push("/accountshome")}>
                    Accounting
                  </Link> : null}
                  {(isPO) ? <Typography component="span" color="textPrimary">
                    Customer Purchase Order
                  </Typography> : (isQuotation) ? <Typography component="span" color="textPrimary">
                    Quotation
                  </Typography> : (isCreditNote) ? <Typography component="span" color="textPrimary">
                    Credit Note
                  </Typography> : <Typography component="span" color="textPrimary">
                    Customers
                  </Typography>}
                </Breadcrumbs>}
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={6}
              spacing={1}
              alignItems="center"
              justify="flex-end"
            >
              <Grid item>
                {(!isBillingAgent && !isAuditor) && <Button
                  color="primary"
                  variant="contained"
                  onClick={() => (isPO || isQuotation || isCreditNote) ? handleNewCust() : history.push("/customerForm")}
                >
                  Add Customer
                </Button>}
              </Grid>
            </Grid>
          </Grid>
        )}
        <div>
          <Typography variant={props.right ? "h7" : "h6"}>
            {props.modal ? "Customer Selection" : "Customer Search"}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
                <Grid item md={12} xs={12}>
                  <Typography variant="caption" component="span">
                    Customer Name
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl style={{ display: "flex" }}>
                    <TextField
                      inputRef={fullNameRef}
                      InputProps={{
                        name: "fullName",
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
                <Grid item md={12} xs={12}>
                  <Typography variant="caption" component="span">
                    Email
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl style={{ display: "flex" }}>
                    <TextField
                      inputRef={emailRef}
                      InputProps={{
                        name: "email",
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
                <Grid item md={12} xs={12}>
                  <Typography variant="caption" component="span">
                    Mobile Number
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl style={{ display: "flex" }}>
                    <TextField
                      inputRef={mobileNumberRef}
                      InputProps={{
                        type: "number",
                        name: "mobileNumber",
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {!isBillingAgent && <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={deepMobileNumSearch}
                        onChange={(e) => {
                          setDeepMobileNumSearch(e.target.checked);
                          setQuery({
                            ...query,
                            mobNumInContacts: e.target.checked,
                          });
                        }}
                        name="mobNumInContacts"
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="caption">
                        including contacts
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={12} xs={12}></Grid>
              </Grid>}
              <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
                <Grid item md={12} xs={12}>
                  <Typography variant="caption" component="span">
                    Locality
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ display: "flex" }}
                  >
                    <Select
                      name="locality"
                      style={{ display: "flex" }}
                      value={searchLocality}
                      onChange={(e) => {
                        setSearchLocality(e.target.value);
                        setQuery({
                          ...query,
                          locality:
                            e.target.value !== "All" ? e.target.value : "",
                          page: 1,
                        });
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {custLocalities.map(function (locality) {
                        return (
                          <MenuItem key={locality} value={locality}>
                            {locality}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {(props.modal) ? <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
                <Grid item style={{ marginTop: '20px' }} md={12} xs={12}>
                  <Button size="small" type="submit" variant="contained" color="primary">
                    Search</Button>
                  <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                    onClick={() => resetQuery()} variant="contained" color="secondary">
                    clear</Button>
                </Grid>
              </Grid> :

                <Grid item container justify={"flex-end"} md={2} xs={6}>
                  <Box mt={2} mr={0}>
                    <Button size="small" type="submit" variant="contained" color="primary">
                      Search</Button>
                  </Box>
                  <Box mt={2} mr={0}>
                    <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                      onClick={() => resetQuery()} variant="contained" color="secondary">
                      clear</Button>
                  </Box>
                </Grid>}

            </Grid>
          </form>
          <Grid item container xs={12} spacing={1}
            alignItems="center" justify="flex-end" style={(isBillingAgent && !props.right) ? { marginTop: "-50px" } : {}}>
            <Grid item>
              {isBillingAgent && !props.right && <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => history.push("/posCustomerForm?isFromBilling=true")}
              >
                Add Customer
              </Button>}
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingTop: 30 }}>
          <TableContainer component={Paper}>
            <Table style={{ paddingTop: 10 }} size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell>Customer Number</StyledHeaderCell>
                  <StyledHeaderCell align="left">Customer Name</StyledHeaderCell>
                  <StyledHeaderCell align="left">Locality</StyledHeaderCell>
                  <StyledHeaderCell align="left">Mobile Number</StyledHeaderCell>
                  <StyledHeaderCell align="left">Email</StyledHeaderCell>
                  {(isAuditor &&  !(isPO || isQuotation || isCreditNote)) ? null : isBillingAgent && !props.right && <StyledHeaderCell align="left">Action</StyledHeaderCell>}
                  {(isAuditor && !(isPO || isQuotation || isCreditNote)) ? null : !props.modal && <StyledHeaderCell align="left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</StyledHeaderCell>}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={props.modal || props.right ? 5 : 6} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={props.modal ? 5 : 6} align="center">
                        <Typography variant="subtitle1" component="span">
                          No records found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((row) => (
                      <TableRow
                        style={{
                          cursor: props.modal || props.right ? "pointer" : "",
                          whiteSpace: 'normal',
                          wordBreak: 'break-word'
                        }}
                        key={row.id}
                        hover={true}
                        onClick={(e) =>
                          props.onRowSelect && props.onRowSelect(row)
                        }
                      >
                        <TableCell component="th" scope="row">{row.id}</TableCell>
                        {(isBillingAgent || props.modal || isPO || isQuotation || isCreditNote) ? <TableCell align="left">{row.fullName}</TableCell> : <TableCell align="left">
                          <Link color="inherit" href="" className="underLine" onClick={() => handleCustomerClick(row)}>
                            {row.fullName}</Link></TableCell>}
                        <TableCell align="left">{row.locality}</TableCell>
                        <TableCell align="left">{row.mobileNumber}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        {!props.right && isBillingAgent && <TableCell align="center">
                          <IconButton onClick={() => handlePOSCustomer(row)}>
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>}
                        {(isAuditor && !(isPO || isQuotation || isCreditNote)) ? null : !props.modal &&
                          <TableCell align="left"><Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => handleCustomerIdClick(row)}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {(isPO) ? "Customer Purchase Order" : (isQuotation) ? "Quotation" : (isCreditNote) ? "Credit Note" : "Record Sale"}
                          </Button></TableCell>}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
        {data && count > pageSize ? (
          <Grid container justify="flex-end">
            <Grid item>
              <Pagination
                style={{ paddingTop: 15 }}
                count={
                  Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
                }
                color="secondary"
                variant="outlined"
                defaultPage={1}
                page={query.page}
                onChange={(event, value) => {
                  setQuery({ ...query, page: value });
                }}
                size="medium"
              />
            </Grid>
          </Grid>
        ) : (
          <div />
        )}

        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={custForm.open}
          onClose={() => {
            setCustForm({ ...custForm, open: false });
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item container xs={10} alignItems="center">
                <Grid item>
                  {custForm.mode === "C"
                    ? "Add Customer"
                    : custForm.data
                      ? custForm.data.externalCustNum
                        ? custForm.data.externalCustNum +
                        " : " +
                        custForm.data.fullName
                        : custForm.data.fullName
                      : ""}
                </Grid>
              </Grid>
              <Grid item container xs={2} alignItems="center" justify="flex-end">
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCustForm({ ...custForm, open: false });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {props.job ? <CustomerFullForm
              form={custForm}
              job={true}
              onCreate={props.onRowSelect}
              cancel={() => {
                setCustForm({ ...custForm, open: false });
              }}
              success={() => {
                setCustForm({ ...custForm, open: false, success: true });
              }}
              setCustForm={setCustForm}
            /> : <CustomerForm
              form={custForm}
              cancel={() => {
                setCustForm({ ...custForm, open: false });
              }}
              success={() => {
                setCustForm({ ...custForm, open: false, success: true });
              }}
            />}
          </DialogContent>
        </Dialog>
        <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={() => setIsError(false)}
        >
          <MuiAlert
            onClose={() => setIsError(false)}
            elevation={6}
            variant="filled"
            severity="error"
          >
            Some error occured. Please retry after sometime.
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={custForm.success}
          onClose={() => setCustForm({ ...custForm, success: false })}
          autoHideDuration={5000}
        >
          <MuiAlert
            onClose={() => setCustForm({ ...custForm, success: false })}
            elevation={6}
            variant="filled"
            severity="success"
          >
            {custForm.mode === "C"
              ? "Customer created successfully"
              : "Customer updated successfully"}
          </MuiAlert>
        </Snackbar>
      </div>
  );
};

export default Customer;
