import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ManageProductGroupForm from "./ManageProductGroupForm";
import UploadImage from '../../../images/upload_images.svg';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import { StyledHeaderCell } from "../../common/StyledHeaderCell";
import ManageProductPartsForm from './ManageProductPartsForm';
import ManageProductInvForm from './ManageProductInventoryForm';
import ManageProductImagesForm from './ManageProductImagesForm';
import { API_ALL_PRODUCT_GROUPS, API_ALL_PRODUCTS } from "../../../config/constants";
import { useSelector } from "react-redux";
import { currentUser } from "../../login/loginSlice";
const ManageProductGroups = (props) => {

  const { id } = useParams();
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  const [isError, setIsError] = useState(false);
  const [ prodData, setProdData ] = useState('');
  const [ isServiceable, setIsServiceable ] = useState(false);
  const [ isGSTApplicable, setIsGSTApplicable ] = useState(false);  
  const [ groups, setGroups ] = useState([]);
  const [selectProductGroup, setSelectProductGroup] = useState({ open: false});
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const [ parts, setParts ] = useState([]);
  const [prodItemForm, setProdItemForm] = useState({ open: false });
  const [ inventories, setInventories ] = useState([]);
  const [ prodInvForm, setProdInvForm ] = useState({ open: false });
  const [ deletedPart, setDeletedPart ] = useState({ open: false });
  const [ images, setImages ] =  useState([]);
  const [ prodImageForm, setProdImageForm ] = useState({ open: false });
  const [ deleteImage, setDeleteImage ] = useState({ open: false });
  const [ bucketName, setBucketName ] = useState();
  const gcpUrl = `${process.env.REACT_APP_GCP_URL}`+bucketName +'/';
  
  async function fetchData(){
    setIsError(false);
    setIsLoading(true);
    if(id){
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProdData(result.data);
        setIsServiceable(result.data.isServiceable?true:false);
        setIsGSTApplicable(result.data.isGSTApplicable?true:false);
        setGroups(result.data.groups&&result.data.groups);
        setParts(result.data.parts&&result.data.parts);
        setInventories(result.data.inventories);
        setImages(result.data.images);
        setBucketName(result.data.org && result.data.org.bucketName);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsError(true);
        setIsLoading(false);
      }
    }}

  useEffect(()=> {
    fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=> {
    fetchData();
  }, [selectProductGroup.success || prodImageForm.success]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(()=> {
    fetchData();
  }, [prodItemForm.success || deletedPart.success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=> {
    fetchData();
  }, [prodInvForm.success || deleteImage]); // eslint-disable-line react-hooks/exhaustive-deps

  const editProductGroup = async (itemId, row) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCT_GROUPS}/${itemId}/items/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resultItems = result.data;
      const reqObj = {
        items: resultItems,
        rowDetails: row
      }
      setSelectProductGroup({
        ...selectProductGroup,
        data: reqObj,
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  const editProductParts = async (itemId) => {
    try {
      setProdItemForm({
        ...prodItemForm,
        data: (parts.filter(part=> part.id === itemId))[0],
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  const deleteProductParts = async (itemId) => {
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}/parts/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setDeletedPart({
        ...deletedPart,
        success: true,
        message: result.data
      })
    } catch (err) {
      setIsError(true);
    }
  };

  const deleteProductImage = async (imageId) => {
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}/images/${imageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setDeleteImage({
        ...deleteImage,
        success: true,
        message: result.data
      })
    } catch (err) {
      setIsError(true);
    }
  };

  const editProductInventory = async (itemId) => {
    let query = { locationId: itemId, productName: prodData && prodData.productName };
    if(itemId){try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/inventory/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },          
          params: query,
        }
      );
      setProdInvForm({
        ...prodInvForm,
        data: result.data.rows[0],
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }}else{
      setIsError(true);
    }
  };
  return (
    <div>
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/products")}>
                  Products</Link>
                <Typography component="span" color="textPrimary">
                  Manage Products
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid item container xs={6} direction="column">
          <Grid item>
            <Button color="primary" variant="contained" style={{float:'right'}}
              onClick={() => history.push("/manageProdPriceForm/" + id)}>Manage Price</Button>
          </Grid>
        </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
        <Typography component="span" color="primary">
          Products Details : {prodData && prodData.productName}</Typography>
        </Grid>
        <Grid className="product-form">
        <Grid item container md={8} xs={8} spacing={1}>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">Product Code</Typography>
              <TextField variant="outlined" fullWidth value={prodData && prodData.productCode} size="small" name="productCode" disabled/>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">Manufacturer</Typography>
              <TextField variant="outlined" fullWidth value={prodData && prodData.manufacturer && prodData.manufacturer.description} size="small" name="mfgId" disabled/>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">Model</Typography>
            <TextField variant="outlined" fullWidth value={prodData && prodData.model} size="small" name="model" disabled/>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">UOM</Typography>
            <TextField variant="outlined" fullWidth value={prodData && prodData.uom} size="small" name="model" disabled/>
          </Grid>
        </Grid>

        <Grid item container md={8} xs={8} spacing={1}>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">Serviceable</Typography>
            <FormControlLabel style={{float: "center", marginLeft: "1%"}}
              control={
                <Checkbox checked={isServiceable} name="isServiceable"
                  style={{ color: "#0C4B60"}} color="primary"/>} disabled/>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography variant="caption">GST Applicable</Typography>
            <FormControlLabel style={{float: "center", marginLeft: "1%"}}
              control={
                <Checkbox checked={isGSTApplicable} name="isGSTApplicable"
                  style={{ color: "#0C4B60"}} color="primary"/>} disabled/>
          </Grid>
        </Grid>
        </Grid>
      </Grid>
      <div style={{ marginTop: 10 }}>
          <Grid container spacing={2} alignItems="center">
              <Grid item md={12} xs={12}>
              <Box display="flex" justifyContent="flex-end">
              <Button color="primary"variant="contained" disabled={isAuditor}
                onClick={() => {
                  setSelectProductGroup({ ...selectProductGroup, data: {}, mode: "C", open: true });
                }}>Add New Product Group </Button>
                </Box>
              </Grid>
            </Grid>
      </div>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
              <StyledHeaderCell align="left">Product Group Code</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Group Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Group Type</StyledHeaderCell>
                <StyledHeaderCell align="center">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading  ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {groups.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  groups.map((row, index) => (
                    <TableRow key={row.id} hover={true}>
                      <TableCell component="th" scope="row">
                        { row.code }
                      </TableCell>
                      <TableCell align="left">
                        { row.name }
                      </TableCell>
                      <TableCell align="left">
                        { row.type.description }
                      </TableCell>
                      <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              editProductGroup(row.id, row);
                            }}
                            disabled={isAuditor}
                          >
                            <EditIcon color={isAuditor?"disabled":"secondary"} />
                          </IconButton>
                        </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      <Grid container spacing={2}>
        <Grid item container xs={6} direction="column">
        <div style={{ marginTop: 10 }}>
          <Grid container spacing={2} alignItems="center">
              <Grid item md={6} xs={6}>
                <Typography color="primary">Parts Details</Typography>
              </Grid>
              <Grid item md={6} xs={6}>
              <Box display="flex" justifyContent="flex-end">
              <Button color="primary"variant="contained"
              onClick={() => {
                setProdItemForm({ ...prodItemForm, data: {}, mode: "C", open: true });
              }}
              disabled={!(prodData && prodData.isServiceable) || isAuditor}
              >Add New Part</Button>
                </Box>
              </Grid>
            </Grid>
      </div>
        <div style={{ paddingTop:'-10px' }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop:'-30px' }} size="small">
            <TableHead>
              <TableRow>
              <StyledHeaderCell align="left">Part Code</StyledHeaderCell>
                <StyledHeaderCell align="left">Part Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Type</StyledHeaderCell>
                <StyledHeaderCell align="center">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading  ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {parts.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  parts.map((row, index) => (
                    <TableRow key={row.id} hover={true}>
                      <TableCell component="th" scope="row">
                        { row.part.productCode }
                      </TableCell>
                      <TableCell align="left">
                        { row.part.productName }
                      </TableCell>
                      <TableCell align="left">
                        { row.type.description }
                      </TableCell>
                      <TableCell align="center">
                          <IconButton style={{marginLeft: '-35px'}} onClick={() => editProductParts(row.id)} disabled={isAuditor}>
                            <EditIcon color={isAuditor?"disabled":"secondary"} />
                          </IconButton>
                          <IconButton style={{marginLeft: '-15px'}} onClick={() => deleteProductParts(row.id)} disabled={isAuditor}>
                            <DeleteIcon color={isAuditor?"disabled":"secondary"} />
                          </IconButton>
                        </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      </Grid>
      <Grid item container xs={6} direction="column">
      <div style={{ marginTop: 10, marginLeft: 10 }}>
          <Grid container spacing={2} alignItems="center">
              <Grid item md={6} xs={6}>
                <Typography color="primary">Images</Typography>
              </Grid>
              <Grid item md={6} xs={6}>
              <Box display="flex" justifyContent="flex-end">
              <Button color={isAuditor?"disabled":"primary"} disabled={isAuditor} variant="contained" onClick={() => {
                setProdImageForm({ ...prodImageForm, open: true });
              }}>
                <img style={{marginRight: '10px'}} src={UploadImage} alt="upload"/>Upload
              </Button>
              </Box>
              </Grid>
            </Grid>
      </div>
      <div style={{ marginTop: '14px', paddingTop:'-10px' }}>
        <Grid className="product-image-form">
        <Grid item container md={12} xs={12} spacing={1}>
        {images.length === 0 ? 
        <Grid item>
          <Typography style={{marginLeft: '185px'}}>No Images Uploaded</Typography>
        </Grid>:
        images.map((row) => (
          <Grid item>
            <Typography>{row.image}
            <IconButton onClick={() => window.open(gcpUrl + row.image)}>
              <VisibilityIcon  color="secondary"/>
            </IconButton>
            <IconButton onClick={() => deleteProductImage(row.id)}>
              <DeleteIcon color="secondary" />
            </IconButton></Typography>
          </Grid>))}
          </Grid>
        </Grid>
      </div>
      </Grid>
      </Grid>
      <Grid>
      <div style={{ marginTop: 10 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={6} xs={6}>
            <Typography color="primary">Set Safety Stock</Typography>
          </Grid>
          <Grid item md={6} xs={6}>
              <Box display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" disabled={isAuditor} onClick={() => {
                setProdInvForm({
                  ...prodInvForm,
                  data: [],
                  mode: "C",
                  open: true,
                });
              }}>Add Safety Stock
              </Button>
              </Box>
              </Grid>
          <Grid item md={12} xs={12}>
          <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
              <StyledHeaderCell align="left">Inventory Location</StyledHeaderCell>
                <StyledHeaderCell align="right">Safety Stock</StyledHeaderCell>
                <StyledHeaderCell align="left">Sub-Location</StyledHeaderCell>
                <StyledHeaderCell align="center">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading  ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {inventories.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  inventories.map((row, index) => (
                    <TableRow key={row.id} hover={true}>
                      <TableCell component="th" scope="row">
                        { row.location ? row.location.name : null }
                      </TableCell>
                      <TableCell align="right">
                        { row.safetyStock }
                      </TableCell>
                      <TableCell align="left">
                        { row.subLocation }
                      </TableCell>
                      <TableCell align="center">
                          <IconButton
                          disabled={isAuditor}
                            onClick={() => {
                              editProductInventory( row.location&& row.location.id);
                            }}>
                            <EditIcon color={isAuditor?"disabled":"secondary"} />
                          </IconButton>
                        </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
          </Grid>
        </Grid>
      </div>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={selectProductGroup.open}
        onClose={() => {
          setSelectProductGroup({ ...selectProductGroup, open: false });
        }}>
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {selectProductGroup.mode === "C"
                  ? "Add Product Group Item"
                  : "Edit Product Group Item"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectProductGroup({ ...selectProductGroup, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{height:'280px'}}>
          <ManageProductGroupForm
            form={selectProductGroup}
            cancel={() => {
              setSelectProductGroup({ ...selectProductGroup, open: false });
            }}
            success={() => {
              setSelectProductGroup({ ...selectProductGroup, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={prodItemForm.open}
        onClose={() => {
          setProdItemForm({ ...prodItemForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {prodItemForm.mode === "C"
                  ? "Add Part Details"
                  : "Edit Part Details"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProdItemForm({ ...prodItemForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{height:'120px'}}>
          <ManageProductPartsForm
            form={prodItemForm}
            cancel={() => {
              setProdItemForm({ ...prodItemForm, open: false });
            }}
            success={() => {
              setProdItemForm({ ...prodItemForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={prodImageForm.open}
        onClose={() => {
          setProdImageForm({ ...prodImageForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {"Upload Product Images"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProdImageForm({ ...prodImageForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{minHeight: '140px'}}>
          <ManageProductImagesForm
            form={prodImageForm}
            cancel={() => {
              setProdImageForm({ ...prodImageForm, open: false });
            }}
            success={() => {
              setProdImageForm({ ...prodImageForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={prodInvForm.open}
        onClose={() => {
          setProdInvForm({ ...prodInvForm, open: false });
        }}>
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
              {prodInvForm.mode === "C"
                  ? "Add Inventory Details"
                  : "Edit Inventory Details"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProdInvForm({ ...prodInvForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{height:'200px'}}>
          <ManageProductInvForm
            form={prodInvForm}
            cancel={() => {
              setProdInvForm({ ...prodInvForm, open: false });
            }}
            success={() => {
              setProdInvForm({ ...prodInvForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={selectProductGroup.success}
        onClose={() => setSelectProductGroup({ ...selectProductGroup, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setSelectProductGroup({ ...selectProductGroup, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {selectProductGroup.mode === "C"
            ? "Product Group added successfully"
            : "Product Group updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={prodItemForm.success || deletedPart.success}
        onClose={() => {
          setProdItemForm({ ...prodItemForm, success: false });
          setDeletedPart({ ...deletedPart, success: false });
        }}
        autoHideDuration={5000}>
        <MuiAlert
          onClose={() => {
            setProdItemForm({ ...prodItemForm, success: false });
            setDeletedPart({ ...deletedPart, success: false });
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {deletedPart.success ? "Product Part deleted successfully" : prodItemForm.mode === "C"
            ? "Product Part added successfully"
            : "Product Part updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={prodImageForm.success || deleteImage.success}
        onClose={() => {
          setProdImageForm({ ...prodImageForm, success: false });
          setDeleteImage({ ...deleteImage, success: false });
        }}
        autoHideDuration={5000}>
        <MuiAlert onClose={() => {
          setProdImageForm({ ...prodImageForm, success: false });
          setDeleteImage({ ...deleteImage, success: false });
        }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          { prodImageForm.success 
          ? "Product Image uploaded successfully"
          : deleteImage.success && "Product Image deleted successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={prodInvForm.success}
        onClose={() => setProdInvForm({ ...prodInvForm, success: false })}
        autoHideDuration={5000}>
        <MuiAlert
          onClose={() => setProdInvForm({ ...prodInvForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          { (prodInvForm.mode === "C") ? "Product Inventory added successfully" : "Product Inventory updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ManageProductGroups;
