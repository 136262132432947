import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocalities, custTypes, custStates } from "../common/lookupSlice";
import { API_ALL_CUSTOMERS } from "../../config/constants";

import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  Breadcrumbs,
  Link,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

let errorMsg;

function POSCustomersForm() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { id } = useParams();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [ customerData, setCustomerData ] = useState({});
  let { fullName, email, mobileNumber, address1, address2, address3, pincode, locality, GSTIN} = customerData;
  const [ custType, setCustType ] = useState();
  const [isError, setIsError] = useState(false);
  const { handleSubmit, errors, register } = useForm({});
  const [inputFields, setInputFields] = useState([]);
  const [ create, setCreate ] = React.useState({});
  const stateObj = useSelector(custStates);
  const types = useSelector(custTypes);
  const states = stateObj ? stateObj.map(state=>state.name):[];
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [isGSTNErr, setGSTNErr] = useState(false);
  const [isPincodeErr, setPincodeErr] = useState(false);
  const [pincodeErrMsg, setPincodeErrMsg] = useState(false);
  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isFromBilling = (chars[1]&&chars[1]==="true") ? true : false;
  useEffect(()=> {
    async function fetchData(){
    if(id){
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_CUSTOMERS}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let modifiedContacts = result.data.contacts.map((obj) => {
          obj.contactId = obj.id;
          delete obj["id"];
          return obj;
        });
        result.data.contacts = modifiedContacts;
        setInputFields(result.data.contacts);
        setCustomerData(result.data);
        setCustType(result.data.customerType.id);
        const stateIndex = states.indexOf(result.data.customerState.name)
        setValue(states[stateIndex])
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
    }}
    fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (customerData) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    let isContinue = true;
    const selectedState = stateObj.filter(x=>x.name===value);
    customerData.contacts = inputFields;
    customerData.GSTIN = GSTIN;
    customerData.id = id;
    customerData.state = selectedState.length>0 && selectedState[0].code;
    
      if(customerData.GSTIN){
        if (/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(customerData.GSTIN)) {
          let stCode = customerData.GSTIN.slice(0, 2);
          if(parseInt(stCode) === selectedState[0].stateId){
            setGSTNErr(false);
          }else{
          isContinue = false;
          errorMsg = "GSTIN not matching to state."
          setGSTNErr(true);
          }
        }else{
          isContinue = false;
          errorMsg = "Enter Valid GSTIN."
          setGSTNErr(true);
        }
      }else{
        if(custType==="2"){
          isContinue = false;
          errorMsg = "Enter Valid GSTIN."
          setGSTNErr(true);
        }else{
            setGSTNErr(false);
        }
      }
    if(customerData.pincode && selectedState &&selectedState[0]  ){
      let testPinCode = parseInt(customerData.pincode.toString().slice(0, 3));
      if(testPinCode >= parseInt(selectedState[0].pinCodeSR) && testPinCode <= parseInt(selectedState[0].pinCodeER)){
        setPincodeErr(false);
      }else{
        setPincodeErr(true);
        setPincodeErrMsg("Pincode not matching to state.");
        isContinue=false;
      }
    }else{
      isContinue = false;
      setPincodeErrMsg("Enter Valid Pincode.");
        setPincodeErr(true);
    }
    customerData.custTypeId = custType?custType:1;
    if (customerData.contacts) {
      let modifiedContacts = customerData.contacts.map((obj) => {
        obj.id = obj.contactId;
        delete obj["contactId"];
        return obj;
      });
      customerData.contacts = JSON.stringify(modifiedContacts);
    }
    if(isContinue){
      setGSTNErr(false);
      setPincodeErr(false);
    try {
      let resData = {};
      if (id) {
        await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/customers/${id}`,
          customerData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }else {
        resData = await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/customers`,
          customerData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } 
      dispatch(fetchLocalities());
      setIsProcessing(false);
      setCreate({
        success: true
      });
      // window.location.href="/recordSales"
      if(isFromBilling){
        history.push({
          pathname: "/recordSales",
          state: (resData&&resData.data&&resData.data.customerData)?resData.data.customerData:{}
        })
        // window.location.href="/recordSales";
      }
    } catch (err) {
      errorMsg = err.response.data.message;
      setIsError(true);
      setIsProcessing(false);
    }
  }else{
    setIsProcessing(false);
  }
  };
  return (
    <div>
      <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Typography component="span" color="textPrimary">
                  {id ? "Edit Customer": "Add Customer"}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>
    
    <form onSubmit={handleSubmit(onSubmit)}>
    <Grid item container xs={12} direction="column">
    <Grid item className="customer-form" md={12} xs={12}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Full Name*</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.fullName)}
              inputRef={register({
                required: "Minimum 5 letters required. No digits.",
              })}
              InputProps={{
                autoFocus: true,
                name: "fullName",
              }} value={fullName}
              disabled={(id)?true:false}
              onChange = {e => setCustomerData({ ...customerData, fullName: e.target.value})}
              size="small"
              name="fullName"
            />
            <FormHelperText error>
              {errors.fullName && errors.fullName.message}
            </FormHelperText>
          </Grid>
            <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption" style={{float: "right"}}>
              Type
            </Typography>
            </Grid>
            <Grid item md={3} xs={3}>
                <TextField  select value={custType?custType:''} size="small" fullWidth
                 inputRef={register({
                  required: "Required",
                })} 
                name="cust"
                error={Boolean(errors.cust)}
                  onChange={e => setCustType(e.target.value)}
                    SelectProps={{ native: true }} variant="outlined">
                      <option key={"type"} value=''>Select Type</option>
                        {Object.values(types).map((obj) => {
                          const { id, description } = obj;
                          return <option key={id} value={id} >{description} </option>
                        })}
                </TextField>
                <FormHelperText error>
              {errors.cust && errors.cust.message}
            </FormHelperText>
              </Grid>
              <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Mobile Number *</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField variant="outlined" fullWidth error={Boolean(errors.mobileNumber)}
            onChange = {e => setCustomerData({ ...customerData, mobileNumber: e.target.value})}
              type="number" inputRef={register({
                required: "10 digit mobile number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "10 digit mobile number is required",
                },
              })} value={mobileNumber} size="small" name="mobileNumber" />
            <FormHelperText error>
              {errors.mobileNumber && errors.mobileNumber.message}
            </FormHelperText>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">GSTIN{(custType && custType==="2")?"*":""}</Typography>
          </Grid>
          <Grid item md={3} xs={3}>
            <TextField value={GSTIN}
              variant="outlined" fullWidth
              onChange = {e => setCustomerData({ ...customerData, GSTIN: e.target.value})}
              size="small" name="gstin"
              error={Boolean(errors.gstin) || isGSTNErr}
              inputRef={register({})} 
              />
              <FormHelperText error={Boolean(errors.gstin) || isGSTNErr}>
             {(isGSTNErr)?errorMsg:errors.gstin && errors.gstin.message}
           </FormHelperText>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Email</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              fullWidth onChange = {e => setCustomerData({ ...customerData, email: e.target.value})}
              type="email" value={email}
              error={Boolean(errors.email)}
              inputRef={register({
                minLength: {
                  value: 5,
                  message: "Minimum 5 characters required",
                },
              })}
              size="small"
              name="email"
            />
            <FormHelperText error>
              {errors.email && errors.email.message}
            </FormHelperText>
          </Grid>
        </Grid>
        </Grid>
      </Grid>
        <Grid className="customer-form"  md={12} xs={12}>
          <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Building/Floor No</Typography>
          </Grid>
          <Grid item md={9} xs={9}>
            <TextField
              variant="outlined"
              fullWidth
              type="adress"
              error={Boolean(errors.address1)}
              inputRef={register({
              })} 
              value={address1}
              onChange = {e => setCustomerData({ ...customerData, address1: e.target.value})}
              size="small"
              name="address1"
            />
            <FormHelperText error>
              {errors.address1 && errors.address1.message}
            </FormHelperText>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Street Name</Typography>
          </Grid>
          <Grid item md={9} xs={9}>
            <TextField
              variant="outlined"
              fullWidth
              type="adress"
              error={Boolean(errors.address2)}
              inputRef={register({
              })} 
              value={address2}
              onChange = {e => setCustomerData({ ...customerData, address2: e.target.value})}
              size="small"
              name="address2"
            />
            <FormHelperText error>
              {errors.address2 && errors.address2.message}
            </FormHelperText>
          </Grid>

          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">City</Typography>
          </Grid>
          <Grid item md={9} xs={9}>
            <TextField
              variant="outlined"
              fullWidth
              type="adress"
              error={Boolean(errors.address3)}
              inputRef={register({
              })} 
              value={address3}
              onChange = {e => setCustomerData({ ...customerData, address3: e.target.value})}
              size="small"
              name="address3"
            />
            <FormHelperText error>
              {errors.address3 && errors.address3.message}
            </FormHelperText>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Pincode *</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.pincode) || isPincodeErr}
              type="number"
              inputRef={register({                
                required: "6 digit pincode is required",
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: "6 digit pincode is required",
                },
              })} value={pincode}
              onChange = {e => setCustomerData({ ...customerData, pincode: e.target.value})}
              size="small"
              name="pincode"
            />
            <FormHelperText error={Boolean(errors.pincode) || isPincodeErr}>
             {(isPincodeErr)?pincodeErrMsg:errors.pincode && errors.pincode.message}
           </FormHelperText>
          </Grid>

          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Locality</Typography>
          </Grid>
          <Grid item md={3} xs={3}>
                <TextField variant="outlined" value={locality}
                  size="small" name="locality"
                  onChange = {e => setCustomerData({ ...customerData, locality: e.target.value})}
                  error={Boolean(errors.locality)}
                  fullWidth 
                  inputRef={register({
                  })}/>
            <FormHelperText error>
              {errors.locality && errors.locality.message}
            </FormHelperText>
          </Grid>

          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">State *</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Autocomplete size="small"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={states}
        style={{ width: 392 }}
        renderInput={(params) => <TextField {...params} 
        variant="outlined" 
        name="st"
        error={Boolean(errors.st)} 
        inputRef={register({
          required: "Required"
        })}/>
      }
      />
      <FormHelperText error>
              {errors.st && errors.st.message}
            </FormHelperText>
          </Grid>

          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        {!create.success ? <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
         <Grid item>
            <Button
              variant="contained"
              onClick={() => history.push("/customers")}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </Grid>
        </Grid>:''}
      </Grid>
      <input type="hidden" name="isDirty" value="true" ref={register()} />
      <input type="hidden" name="id" ref={register()} />
    </form>
    <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id?"Customer updated successfully"
            : "Customer created successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default POSCustomersForm;
