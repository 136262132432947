import React, { useState, useRef, useEffect } from "react";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Box,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  FormControl,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
// import { localities } from "../common/lookupSlice";

const Customer = (props) => {
  const isBillingAgent = props.isBillingAgent;
  // const availCustLocalities = useSelector(localities);
  // const custLocalities = availCustLocalities ? availCustLocalities.filter(loc => loc !== "") : [];
  const pageSize = 10;
  const history = useHistory();
  // const location = useLocation();
  // const customerData = location.state && !(location.state.click);
  const [count, setCount] = useState();
  const [custForm, setCustForm] = useState({ data: {}, open: false });
  // const [searchLocality, setSearchLocality] = useState("");
  // const [deepMobileNumSearch, setDeepMobileNumSearch] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const idRef = useRef();
  const bankNameRef = useRef();
  const acHolderNameRef = useRef();
  const branchNameRef = useRef();
  const token = localStorage.getItem("authToken");

  // var queryStringData = window.location.search;
  // var chars = queryStringData.split('=');
  // let isQuotation = (chars[1]) ? chars[1].toUpperCase() === "QUOTATION" : false;
  // let isPO = (chars[1]) ? chars[1].toUpperCase() === "PURCHASEORDER" : false;
  const resetQuery = () => {
    idRef.current.value = "";
    bankNameRef.current.value = "";
    acHolderNameRef.current.value = "";
    branchNameRef.current.value = "";
    // setSearchLocality("");
    // setDeepMobileNumSearch(false);
    setQuery({
      ...query,
      id: idRef.current.value,
      bankName: bankNameRef.current.value,
      acHolderName: acHolderNameRef.current.value,
      branchName: branchNameRef.current.value,
    });
  };
  const handleBankClick = (row) => {
    history.push({
      pathname: `/companyconfigbankdetails/${row.id}?isPrimary=${(row.isPrimary) ? "YES" : "NO"}`,
      state: row
    })
  }

  const handlePOSCustomer = (row) => {
    history.push(`/companyconfigbankdetails/${row.id}?isPrimary=${(row.isPrimary) ? "YES" : "NO"}`)
  }
  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/bankdetials`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.bankDetails);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      id: e.target.elements.id.value,
      bankName: e.target.elements.bankName.value,
      acHolderName: e.target.elements.acHolderName.value,
      branchName: e.target.elements.branchName.value,
      page: 1,
    });
  };

  // On success of add or edit, reset the query to load data again
  useEffect(() => {
    if (custForm.success) {
      resetQuery();
    }
  }, [custForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/configrationhome")}>
                Configuration
              </Link>
              <Typography component="span" color="textPrimary">
                Bank Details
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            {!isBillingAgent && <Button
              color="primary"
              variant="contained"
              onClick={() => { history.push("/companyconfigbankdetails") }}
            >
              Add Bank Details
            </Button>}
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Typography variant={props.right ? "h7" : "h6"}>
          {props.modal ? "Bank Details Selection" : "Bank Details Search"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Bank Details Id
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={idRef}
                    InputProps={{
                      name: "id",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Bank Name
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={bankNameRef}
                    InputProps={{
                      name: "bankName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Account Name
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={acHolderNameRef}
                    InputProps={{
                      name: "acHolderName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Branch Name
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={branchNameRef}
                    InputProps={{
                      name: "branchName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            {(props.modal) ? <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item style={{ marginTop: '20px' }} md={12} xs={12}>
                <Button size="small" type="submit" variant="contained" color="primary">
                  Search</Button>
                <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                  onClick={() => resetQuery()} variant="contained" color="secondary">
                  clear</Button>
              </Grid>
            </Grid> :

              <Grid item container justifyContent={"flex-end"} md={2} xs={6}>
                <Box mt={2} mr={0}>
                  <Button size="small" type="submit" variant="contained" color="primary">
                    Search</Button>
                </Box>
                <Box mt={2} mr={0}>
                  <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                    onClick={() => resetQuery()} variant="contained" color="secondary">
                    clear</Button>
                </Box>
              </Grid>}

          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Bank Details Id </StyledHeaderCell>
                <StyledHeaderCell align="left">Bank Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Branch Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Account Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={props.modal || props.right ? 5 : 6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => (
                    <TableRow
                      style={{
                        cursor: props.modal || props.right ? "pointer" : "",
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }
                    >
                      <TableCell component="th" scope="row">{row.id}</TableCell>
                      {(props.modal) ? <TableCell align="left">{(row.ERPId) ? row.ERPId : row.bankName}</TableCell> : <TableCell align="left">
                        <Link color="inherit" href="" className="underLine" onClick={() => handleBankClick(row)}>
                          {(row.ERPId) ? row.ERPId : row.bankName}</Link></TableCell>}
                      <TableCell align="left">{row.branchName}</TableCell>
                      <TableCell align="left">{row.acHolderName}</TableCell>
                      <TableCell align="left">
                        <IconButton onClick={() => handlePOSCustomer(row)}>
                          <EditIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={custForm.success}
        onClose={() => setCustForm({ ...custForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCustForm({ ...custForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {custForm.mode === "C"
            ? "Customer created successfully"
            : "Customer updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Customer;
