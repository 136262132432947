import React from "react";
import { useHistory } from "react-router-dom";

import { Typography, Button, Paper, Box, Grid } from "@material-ui/core";

const UnAuthorized = () => {
  const history = useHistory();

  return (
    <Grid
      className="dashboard-grid-container"
      container
      align="center"
      alignItems="center"
      justify="center"
      spacing={5}
    >
      <Grid item container xs={12} direction="column">
        <Paper elevation={3}>
          <Grid item>
            <Box
              style={{
                paddingTop: 30,
                color: "#0C4B60",
              }}
              fontSize={14}
              alignItems="center"
              justifyContent="center"
              display="flex"
              direction="column"
            >
              <Typography>
                <b>You are not authorized to access this feature</b>
              </Typography>
            </Box>
          </Grid>

          <Grid item style={{ padding: 30 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => history.push("/")}
            >
              Go to Home
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UnAuthorized;
