import React from "react";
import { Helmet } from "react-helmet";
import { withStyles } from '@material-ui/styles';
// import _ from "lodash";

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
// const pStyle = {
//   fontFamily: 'Arial, sans-serif',
//   fontSize: '15px',
//   textAlign: 'center',
//   lineHeight: '1.6',
// };
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};
const tableStyle = {
  border: '1px solid #ccc',
  borderCollapse: 'collapse',
}

class CompToPrintInv extends React.Component {
  componentWillReceiveProps() {
  }
  render() {
    const { classes, loggedInUser, orgTermsAndConditions } = this.props;
    const invDetails = this.props.customerInv != null && this.props.customerInv;
    const lineItems = this.props.customerInv != null && this.props.customerInv.lineItems && JSON.parse(this.props.customerInv.lineItems);
    let isSpecialLineItems = [];
    const companyPrintLogo = loggedInUser && loggedInUser.org && loggedInUser.org.printLogo;
    let orgDeclaration = "";
    if (orgTermsAndConditions && orgTermsAndConditions.length > 0) {
      let findOrgCond = orgTermsAndConditions.find((orgCond) => {
        return (orgCond && orgCond.termsConditionsType && (orgCond.termsConditionsType === "Supplier Purchase Quotation Request")) ? orgCond : null;
      })
      orgDeclaration = findOrgCond && findOrgCond.declaration
    }
    return (
      <div>
        <div align="center">
          <img alt="comLogo" src={companyPrintLogo}
            style={{ height: "100px", width: "200px" }} />
        </div>
        <div style={divStyle} align="center">
          {/* Invoice  */}
          {/* <Helmet><title>Print Invoice</title></Helmet> */}
          <Helmet><title></title></Helmet>
          <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', textAlign: 'center', fontWeight: 'bold', lineHeight: '2', }}>Request Quotation</span>
          {/* <table style={tableStyle} width="95%" border="1px" color="#ccc">
            <tr>
              <td rowSpan="2" width="100%">
                <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name}<br /></b></span>
                <span style={pStyle2}>
                  {invDetails && invDetails.org && (invDetails.org.address1)}
                  <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address2)}</div>
                  <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address3 + '-' + invDetails.org.pincode)}</div>
                  <span style={pStyle2}>{invDetails && invDetails.org && invDetails.org.phoneNo ? invDetails.org.phoneNo : ""}<br /></span>
                </span>
                <span style={pStyle2}>GSTIN/UIN: {invDetails && invDetails.org && invDetails.org.GSTIN ? invDetails.org.GSTIN : "Not Available"}<br /></span>
                <span style={pStyle2}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /></span>
              </td>
            </tr>
          </table> */}
          <div style={{ width: '100%', padding: '5px 2px' }}>
            <table style={tableStyle} width="95%">
              <thead>
                <tr>
                  <td width="3%" align="left" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Sl No.</span></td>
                  <td width="25%" align="left" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Product Name</span></td>
                  <td width="10%" align="left" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Required Qty</span></td>
                  <td width="7%" align="left" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>UOM</span></td>
                </tr>
              </thead>
              <tbody>
                {lineItems && lineItems.length === 0 && isSpecialLineItems && isSpecialLineItems.length === 0 ? (
                  <tr>
                    <td colspan="9" align="center">
                      <span style={pStyle2}>No records found</span>
                    </td>
                  </tr>
                ) : (
                  lineItems && lineItems.map((row, index) => (
                    <tr
                      key={index}
                      hover={true}
                    >
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {index + 1}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.productCode}</b>
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.requiredQty}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.uom}
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody>
                <tr style={{ fontFamily: 'Arial, sans-serif' }}>
                  <td colspan="2">
                    <span style={{ margin: "auto", display: "table", textDecoration: "underline", fontSize: "16px" }}><b>Terms and Conditions</b></span>
                    <ol type="1" style={{ fontSize: "13px", fontFamily: 'Arial, sans-serif' }}>
                      {orgDeclaration}
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Supplier Seal and Signature<br /><br /><br /></span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>{invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(CompToPrintInv);