import React, { useState, useRef, useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Breadcrumbs,
  Box,
  Link,
  TextField,
  Snackbar,
  FormControl,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Select,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ProductForm from "./ProductForm";

import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

import { currentUser } from "../login/loginSlice";
import {
  productGroups,
  manufacturers,
} from "../common/lookupSlice";

import { API_ALL_PRODUCTS } from "../../config/constants";

import "./products.css";
const Products = (props) => {
  const prodMfgs = useSelector(manufacturers);
  const prodGrps = useSelector(productGroups);
  const pageSize = 10;
  const loggedInUser = useSelector(currentUser);
  const isAdminOrAccountant = loggedInUser && loggedInUser.roles && loggedInUser.roles.some(role=>(role.name === "ADMIN") ||  (role.name === "ACCOUNTANT"));
  const history = useHistory();
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  const [count, setCount] = useState();
  const [prodForm, setProdForm] = useState({ open: false });
  const [mfgCode, setMfgCode] = useState("");
  const [group, setGroup] = useState("");
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const itemNameRef = useRef();
  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.products);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      productName: e.target.elements.itemName.value,
      mfgId:
        e.target.elements.mfgCode.value !== "All"
          ? e.target.elements.mfgCode.value
          : "",
      prdGrpId: group,    
      page: 1,
    });
  };

  const resetQuery = () => {
    window.location.reload();
  };

  const editProduct = async (id) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setProdForm({
        ...prodForm,
        data: result.data,
        mode: "U",
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  return (
    <div>
      {props.modal ? null : (
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Typography component="span" color="textPrimary">
                  Products
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          {isAdminOrAccountant  && <Grid
            item
            container
            xs={6}
            spacing={1}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setProdForm({ ...prodForm, data: {}, mode: "C", open: true });
                }}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>}
        </Grid>
      )}
      <div className="user-filter-grids">
        <Typography variant="h6">
          {props.modal ? "Product Selection" : "Product Search"}
        </Typography>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={props.modal ? 4 : 3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={itemNameRef}
                    InputProps={{
                      name: "itemName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
          <Grid item container md={props.modal ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Group</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ display: "flex" }}
                >
                  <Autocomplete
              id="combo-box-demo"
              options={prodGrps ? prodGrps:[]}
              getOptionLabel={(option) => option.code}
              size="small"
              onChange={(e, val) => {
                if (val) {
                  setGroup(val.id);
                  setQuery({
                    ...query,
                    prdGrpId: val.id,
                    page: 1,
                  });
                }
                else{
                  setQuery({
                    ...query,
                    prdGrpId: "",
                    page: 1,
                  });
                }
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={props.modal ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Manufacturer</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ display: "flex" }}
                >
                  <Select
                    name="mfgCode"
                    style={{ display: "flex" }}
                    value={mfgCode}
                    onChange={(e) => {
                      setMfgCode(e.target.value);
                      setQuery({
                        ...query,
                        mfgId: e.target.value !== "All" ? e.target.value : "",
                        page: 1,
                      });
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <MenuItem key={0} value={"All"}>
                      All
                    </MenuItem>
                    {prodMfgs.map((mfg, index) => {
                      return (
                        <MenuItem key={index + 1} value={mfg.id}>
                          {mfg.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {(props.modal)?
            <Grid item container justify={"flex-end"} xs={12}>
              <Box mt={2} mr={0}>
                <Button variant="contained" color="primary" size="small" autoFocus type="submit">
                Search </Button></Box>
                <Box mt={2} mr={0}>
                <Button size="small" style={{marginLeft:'10px'}} type="reset" onClick={() => resetQuery()}
                   variant="contained" color="secondary">
                  clear</Button></Box>
            </Grid>:
            <Grid item container alignItems="center" md={2} xs={6}>
              <Grid item>
                <Box mt={2} mr={2}>
                  <Button
              variant="contained"
              color="primary"
              size="small"
              autoFocus
              type="submit"
            >
            Search
            </Button>
            </Box>
              </Grid>
            <Grid item>
            <Box mt={2}>
                <Button size="small" type="reset" onClick={() => resetQuery()}
                   variant="contained" color="secondary">
                  clear</Button>
              </Box>
              </Grid>
            </Grid>}
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Manufacturer</StyledHeaderCell>
                <StyledHeaderCell align="left">Brand</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Type</StyledHeaderCell>
                <StyledHeaderCell align="left">
                  Product Category
                </StyledHeaderCell>
                <StyledHeaderCell align="center">Serviceable</StyledHeaderCell>
                {props.modal ? null : (
                  <StyledHeaderCell align="center">Action</StyledHeaderCell>
                )}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={props.modal ? 6 : 7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 6 : 7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      style={{ cursor: props.modal ? "pointer" : "" }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }>
                      {props.modal ? <TableCell component="th" scope="row">{row.productName}</TableCell>:
                        <TableCell component="th" scope="row"><Link color="inherit" href="" style={{}} className="underLine" 
                        onClick={() => history.push("/manageProdGroups/" + row.id)}>{row.productName}</Link></TableCell>}
                      <TableCell align="left">
                        {row.manufacturer && row.manufacturer.description}
                      </TableCell>
                      <TableCell align="left">
                        {row.brand && row.brand.description}
                      </TableCell>
                      <TableCell align="left">
                        {row.type && row.type.description}
                      </TableCell>
                      <TableCell align="left">
                        {row.category && row.category.description}
                      </TableCell>
                      <TableCell align="center">
                        {row.isServiceable ? (
                          <CheckBoxIcon color="secondary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}
                      </TableCell>
                      {props.modal ? null : (
                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              editProduct(row.id);
                            }}
                          >
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={prodForm.open}
        onClose={() => {
          setProdForm({ ...prodForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {prodForm.mode === "C"
                  ? "Add Product"
                  : "Edit Product"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProdForm({ ...prodForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ProductForm
            form={prodForm}
            cancel={() => {
              setProdForm({ ...prodForm, open: false });
            }}
            success={() => {
              setProdForm({ ...prodForm, open: false, success: true });
              loadData();
            }}
            disabled={isAuditor}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={prodForm.success}
        onClose={() => setProdForm({ ...prodForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setProdForm({ ...prodForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {prodForm.mode === "C"
            ? "Product created successfully"
            : "Product updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Products;
