import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Card,
  Collapse
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const PurchaseInvoice = (props) => {
  // const location = useLocation();
  const parentData = props.parentData
  const parentId = parentData && parentData.id;
  const [open, setOpen] = React.useState([]);
  const [spoData, setSPoData] = useState([]);
  // const [poInnerData, setPoInnerData] = useState([]);
  // const [spoNo, setPoNo] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [isPoLoading, setIsPoLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const loadData = async () => {
    setIsLoading(true);
    try {
      let spoResult;
      if (props.partyType === "CUSTOMER") {
        spoResult = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/reports/customerSales?customerId=${parentId}&paymentTypeId=2`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        spoResult = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseinvoice/invoice/lists?supplierId=${parentId}&paymentTypeId=2`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      const validPoData = spoResult.data.invoices ? spoResult.data.invoices : [];
      const spoData = validPoData ? validPoData.length : 0;
      const poCollapse = [];
      for (let step = 0; step < spoData; step++) {
        poCollapse[step] = false;
      }
      setOpen(poCollapse)
      setSPoData(validPoData);
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.message !== `List Not found`) {
        props.setIsError(true);
      }
    }
    setIsLoading(false);
    setRefresh(false);
  };

  // const loadPoData = async () => {
  //   setIsPoLoading(true)
  //   try {
  //     const result = await axios.get(
  //       `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseorder/${spoNo}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setPoInnerData(result.data.purchaseOrders && result.data.purchaseOrders.lineItems
  //       ? result.data.purchaseOrders.lineItems : [])

  //   } catch (err) {
  //     setPoInnerData([]);
  //   }
  //   setIsPoLoading(false)
  // }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (spoNo) {
  //     loadPoData();
  //   }
  // }, [spoNo]); // eslint-disable-line react-hooks/exhaustive-deps




  const handlePoClick = async (spInvNum, data) => {

    if (props.setSelectedSPInv) {
      props.setShowSPInv(false)
      props.setSelectedSPInv({ spInvNum: spInvNum, details: data })
    }
  }

  return (
    <div>
      <div>
        <Card>
          <TableContainer component={Paper} >
            <Table style={{ paddingTop: 10 }} size="small">
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {spoData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={props.modal ? 6 : 7} align="center">
                        <Typography variant="subtitle1">
                          Data lists not found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    spoData.map((row, index) => (
                      <React.Fragment>
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => {
                              const clone = _.cloneDeep(open)
                              clone.forEach((x, y) => {
                                if (y === index) {
                                  clone[y] = !clone[y]
                                }
                                else {
                                  clone[y] = false
                                }
                              })
                              // setPoNo(row.invoiceNum)
                              setOpen(clone)
                            }
                            }>
                              {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row" >
                            {row.invoiceNum}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open[index]} timeout="auto" unmountOnExit>
                              <Box mt={2}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  onClick={() => handlePoClick(row.invoiceNum, row)}
                                >Select Invoice</Button>
                              </Box>
                              <Box mt={2} mb={2}>
                                <TableContainer component={Paper} >
                                  <Table style={{ paddingTop: 10 }} size="small">
                                    <TableHead>
                                      <TableRow>
                                        <StyledHeaderCell>Invoice Number</StyledHeaderCell>
                                        <StyledHeaderCell align="left">Grand Total</StyledHeaderCell>
                                        <StyledHeaderCell align="left">Paid Amount</StyledHeaderCell>
                                        <StyledHeaderCell align="left">Outstanding Amount</StyledHeaderCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow
                                        style={{ cursor: props.modal ? "pointer" : "" }}
                                        key={row.id}
                                        hover={true}
                                        onClick={(e) =>
                                          props.onRowSelect && props.onRowSelect(row)
                                        }
                                      >
                                        <TableCell component="th" scope="row">
                                          {row && row.invoiceNum}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row && row.total}
                                        </TableCell>
                                        <TableCell align="left">
                                          {(row) ? row.total - row.outStandingAmount : 0}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row && row.outStandingAmount}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow></React.Fragment>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

        </Card>
      </div>
    </div >
  );
};

export default PurchaseInvoice;
