import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import customers from "../../images/Customers.svg";
import supplier100 from "../../images/supplier100.svg";
import products from "../../images/Products.svg";
import users from "../../images/Users.svg";
import { currentUser } from "../login/loginSlice";
import inventory from "../../images/Inventory.svg";
import batchJobs from "../../images/BatchJobs.svg";
import productGroups from "../../images/ProductGroups.svg";
import serviceRequests from "../../images/ServiceRequests.svg";
import configuration from "../../images/configuration.svg";
import purchaseHome from "../../images/purchaseHome.svg";
import salesHome from "../../images/salesHome.svg";
import accounting from "../../images/accounting.svg";
import reports from "../../images/Reports.svg";
import customerPurchaseOrder from "../../images/customerPurchaseOrder.svg";
import userGuide from "../../images/userGuide.svg";
import estimate from "../../images/estimate.svg";

import { Link, Typography, Box, Grid, Paper } from "@material-ui/core";

const AdminDashboard = () => {
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
 
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  
  const handleUserGuide = () =>{
    window.location.href = "/userguides";
  }

  const renderFeatureTiles = (features) => {
    return features.map((feature, index) => (
      <Grid item md={3} xs={12} key={index}>
        {feature.path &&
        <Link
          component="button"
          style={{ textDecoration: "none" }}
          onClick={() => {
            history.push(feature.path);
          }}
        >
          <Paper elevation={10} style={{ width: 200, height: 200 }}>
            <Box
              style={{ paddingTop: 10 }}
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <img
                src={feature.icon}
                alt= "feature icon"
                style={{ width: "70%", height: "70%" }}
              />
            </Box>
            <Box
              style={{
                paddingTop: 10,
                color: "#0C4B60",
              }}
              fontWeight={"fontWeightBold"}
              fontSize={12}
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <Typography>
                <b>{feature.description}</b>
              </Typography>
            </Box>
          </Paper>
        </Link>}
      </Grid>
    ))
  }

  const featuresRow1 = [
    {
      description: "Sales",
      icon: salesHome,
      path: "/saleshome",
    },
    {
      description: "Purchase",
      icon: purchaseHome,
      path: "/purchasehome",
    },
    {
      description: "Products",
      icon: products,
      path: "/products"
    },
    {
      description: "Inventory",
      icon: inventory,
      path: "/inventoryhome",
    },
  ];
  const featuresRow2 = [
    {
      description: "Customers",
      icon: customers,
      path: "/customers",
    },
    {
      description: "Suppliers",
      icon: supplier100,
      path: "/suppliers",
    },
    {
      description: "Product Groups",
      icon: productGroups,
      path: "/productGroups",
    },
    {
      description: "Reports",
      icon: reports,
      path: "/reports"
    }
  ];
  const featuresRow3 = [
    {
      description: "Service Requests",
      icon: serviceRequests,
      path: "/jobs",
    },
    {
      description: "Users",
      icon: users,
      path: "/users"
    },
    {
      description: "Data Management",
      icon: batchJobs,
      path: "/datamanagementhome"
    },
    {
      description: "Accounting",
      icon: accounting,
      path: "/accountshome",
    }
  ];
  const featuresRow4 = [
    {
      description: "Configurations",
      icon: configuration,
      path: "/configrationhome",
    }
  ];

  const featuresRowAccountant3 = [
    {
      description: "Service Requests",
      icon: serviceRequests,
      path: "/jobs",
    },
    {
      description: "Accounting",
      icon: accounting,
      path: "/accountshome",
    }
  ];
  
  const renderQuiclLinks = (links) => {
    return (
      <Grid item xs={12}>
        <Grid container
        align="center"
        alignItems="center"
        justifyContent="flex-end"
        style={{justifyContent:"end", paddingRight:"4%"}}
        >
        <Grid item>
          <span>Quick Links</span>
          {links && links.map((link, index) => (
            <span key={index} style={{paddingLeft:"15px", fontWeight:"bold"}}>
              {link.icon &&
                <img src={link.icon} alt={link.description} className="quickLinkHomeIconMargin" />
              }
              {link.path &&
              <Link
                key={`${link.description}`}
                component="span"
                className="underLine"
                style={{cursor: "pointer" }}
                onClick={() => {(link.description === "User Guides")?handleUserGuide()
                  :history.push(link.path);
                }}
              >
            
              {link.description}
              </Link>}
            </span>
            ))}
            </Grid>
      </Grid>
    </Grid>
    )
  }
  const quickLinks = [
    {
      description: "Quotations",
      path: "/customers?from=quotation",
      icon: estimate
    },
    {
      description: "Customer Purchase Orders",
      path: "/customers?from=purchaseorder",
      icon: customerPurchaseOrder
    },
    {
      description: "User Guides",
      path: "/userguides",
      icon: userGuide
    },
  ]

  return (
    <Grid
      className="dashboard-grid-container"
      container
      align="center"
      alignItems="center"
      justifyContent="flex-start"
      spacing={3}
    >
      {renderQuiclLinks(quickLinks)}
      {renderFeatureTiles(featuresRow1)}
      {renderFeatureTiles(featuresRow2)}
      {isAuditor?renderFeatureTiles(featuresRowAccountant3)
      :<>{renderFeatureTiles(featuresRow3)}
      {renderFeatureTiles(featuresRow4)}</>}
    </Grid>
  );
};

export default AdminDashboard;
