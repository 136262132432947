import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from '../common/util';
import { withStyles } from '@material-ui/styles';
// import _ from "lodash";

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '1.6',
};

class CompToPrintInv extends React.Component {
  render() {
    const invDetails = this.props.receiptInv != null && this.props.receiptInv;
    const lineItems = this.props.receiptInv != null && this.props.receiptInv.lineItems;
    return (
      <div>
        <div align="center">
          <img alt="comLogo" src={this.props.companyPrintLogo}
            style={{ height: "100px", width: "200px" }} />
        </div>
        <div style={divStyle} align="center">
          <Helmet><title></title></Helmet>
          <div width="100%" style={{ textTransform: "uppercase" }}>
            <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name}</b></span><br />
            {this.props.journalEntry ? <>
              <span style={pStyle}>{invDetails && invDetails.org && (invDetails.org.address1)}</span>
              <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address2)}</div></span>
              <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address3 + '-' + invDetails.org.pincode)}</div></span>
              <span style={pStyle}>{invDetails && invDetails.org && invDetails.org.mobileNumber ? invDetails.org.mobileNumber : ""}<br /></span>
              <span style={pStyle}>State :{invDetails && invDetails.org && (invDetails.org.state)}  Code: {invDetails && invDetails.org && invDetails.org.GSTIN.slice(0, 2)}<br /></span>
              <span style={pStyle}>GSTIN/UIN: {(invDetails && invDetails.org && invDetails.org.GSTIN) ? invDetails.org.GSTIN : "Not Available"}<br /></span>
            </> : <>
              <span style={pStyle}>{invDetails && invDetails.location && (invDetails.location.address1)}</span>
              <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address2)}</div></span>
              <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address3 + '-' + invDetails.location.pincode)}</div></span>
              <span style={pStyle}>{invDetails && invDetails.location && invDetails.location.mobileNumber ? invDetails.location.mobileNumber : ""}<br /></span>
              <span style={pStyle}>State :{invDetails && invDetails.location && (invDetails.location.state)}  Code: {invDetails && invDetails.location && invDetails.location.GSTIN.slice(0, 2)}<br /></span>
              <span style={pStyle}>GSTIN/UIN: {(invDetails && invDetails.location && invDetails.location.GSTIN) ? invDetails.location.GSTIN : "Not Available"}<br /></span>
            </>}
            <span style={pStyle}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /><br /></span>
            <span style={pStyle}><b>Journal Entry<br /><br /><br /></b></span>
          </div>
          <div style={divStyle}>
            <table width="80%" padding="2px">
              <tr>
                <td width="70%"><span style={pStyle}>No. : <b>{this.props.receiptInv && this.props.receiptInv.receiptNum}</b></span></td>
                <td width="30%"><span style={pStyle}>Dated : <b>{this.props.receiptInv && formatDate(this.props.receiptInv.createdAt)}</b></span></td>
              </tr>
            </table>
          </div>
          <div style={divStyle}>
            <table width="80%" style={{ borderCollapse: 'collapse' }} padding="2px">
              <thead>
                <tr>
                  <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="70%">&nbsp;&nbsp;Particulars</td>
                  <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="15%" align="right">Debit</td>
                  <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="15%" align="right">Credit</td>
                </tr>
              </thead>
              <tbody>
                {lineItems && lineItems.length === 0 ? (
                  <tr>
                    <td colspan="" align="center">
                      No records found
                    </td>
                  </tr>
                ) : (
                  lineItems && lineItems.map((row, index) => (
                    <tr
                      key={index}
                      hover={true}
                    >
                      <td align="left" style={{ padding: '5px', borderRight: '1px solid #ccc' }} width="70%">
                        <span style={pStyle}><b>{row && row.childAccount}</b><br /></span>
                        <span style={pStyle} width="100%"><span style={{ marginLeft: '20px' }}>{(row && row.customerId) ? row.customer && row.customer.fullName :
                          (row && row.supplierId) ? row.supplier && row.supplier.fullName : ""}&nbsp;&nbsp;&nbsp;
                          <b>{row.creditAmount ? `${row.creditAmount} Cr` : row.debitAmount ? `${row.debitAmount} Dr` : ""} </b></span><br /></span>
                      </td>
                      <td align="right" valign="top" style={{ padding: '5px', borderRight: '1px solid #ccc' }}>
                        <br />{row.debitAmount ? `${(Math.round(row.debitAmount * 100) / 100).toFixed(2)}` : "0.0"}
                      </td>
                      <td align="right" valign="top" style={{ padding: '5px' }}>
                        <br />{row.creditAmount ? `${(Math.round(row.creditAmount * 100) / 100).toFixed(2)}` : '0.00'}
                      </td>
                    </tr>
                  ))
                )}
                <tr>
                  <td align="right" style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}></td>
                  <td align="right" style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>{invDetails && invDetails.totalDebit ? (Math.round(invDetails.totalDebit * 100) / 100).toFixed(2) : '0.00'}</td>
                  <td colspan="3" align="right" style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>{invDetails && invDetails.totalCredit ? (Math.round(invDetails.totalCredit * 100) / 100).toFixed(2) : '0.00'}</td>
                </tr>
                <tr>
                  <td colspan="3" align="right"><span style={pStyle}><br /><br />Authorised Signatory</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(CompToPrintInv);