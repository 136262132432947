import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Typography,
  TextField,
  FormHelperText,
  Grid,
  // CircularProgress,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton

} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import SearchProduct from '../productGroups/searchProduct';

let errorMsg;
function ManageProductForm(props) {
  const [selectProduct, setSelectProduct] = useState({ open: false });
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [prodId, setProdId] = useState(props.form && props.form.data && props.form.data.product ? props.form.data.product.id : null);
  const { errors, register, getValues, setValue } = useForm({
    defaultValues: {
      productId: props.form && props.form.data && props.form.data.product && props.form.data.product.productName
    },
  });

  const onSubmit = async (productId) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    console.log("prodId", productId);
    let data = {}
    try {

      data.productId = Number(productId)
      const reqData = {
        items: [data]
      }
      await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/suppliers/${props.supplierId}/items`,
        reqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsProcessing(false);
      props.success();
      props.fetchData(props.supplierId)
    } catch (err) {
      errorMsg = 'Error occured while creating Supplier item';
      errorMsg = err.response && err.response.data.message &&
        (err.response.data.message[0] === "productId must be unique" || err.response.data.message[0] === "Validation error") ? "Product Item already exists" : errorMsg;
      setIsError(true);
      setIsProcessing(false);
    }
  };
  console.log("prodId non", prodId);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={12} xs={12} style={{marginTop:"-24px"}}>
            <Typography variant="caption">{'Search Product'}</Typography>
            <TextField
              variant="outlined"
              fullWidth
              disabled={true}
              error={Boolean(errors.productId)}
              inputRef={register({
                required: "Product is required",
              })}
              onClick={(e) => setSelectProduct({ ...selectProduct, open: true })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ cursor: "pointer" }}>
                    <SearchIcon onClick={(e) => setSelectProduct({ ...selectProduct, open: true })} />
                  </InputAdornment>
                ),
              }}
              size="small"
              name="productId"
              value={getValues('productId')}
            />
            <FormHelperText error>
              {errors.productId && errors.productId.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      {isError ? (
        <Grid item>
          <Typography error>
            <FormHelperText error>{errorMsg}</FormHelperText>
          </Typography>
        </Grid>
      ) : null}
      {isProcessing?"":""}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={selectProduct.open}
        onClose={() => {
          setSelectProduct({ ...selectProduct, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Select Product
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectProduct({ ...selectProduct, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <SearchProduct
            form={selectProduct}
            setValue={setValue}
            setProdId={setProdId}
            cancel={() => {
              setSelectProduct({ ...selectProduct, open: false });
            }}
            success={(prodId) => {
              onSubmit(prodId);
              setSelectProduct({ ...selectProduct, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
    </form>
  );
}

export default ManageProductForm;
