import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
  
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";

import _ from 'lodash';
import moment from 'moment'

let errorMsg;
function ManageDiscountForm(props) {
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [date, setDate] = useState({start: props.form.data.startDate ? moment(props.form.data.startDate).format('DD/MM/YYYY'):null,end:props.form.data.endDate ? moment(props.form.data.endDate).format('DD/MM/YYYY'):null});
  const { handleSubmit, control, errors, register } = useForm({
    defaultValues: {
      discount: props.form.data.discount,
      startDate: date.start,
      endDate: date.end,
      isActive: props.form.mode === "C" ? true:props.form.data.isActive
    },
  });
 
  const onSubmit = async (data) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    try {
      if (props.form.mode === "C") {
        data.discount = Number(data.discount)
        if((data.startDate)){
          var startDateArray = data.startDate && data.startDate.split("/");
          data.startDate = startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2];
        } else{
          data.startDate = null;
        }
        if((data.endDate)){
          var endDateArray = data.endDate && data.endDate.split("/");
          data.endDate = endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2];
        } else {
          data.endDate = null;
        }
        const reqData = {
          discounts:JSON.stringify([data])
        }
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/productGroups/${props.groupId}/discounts`,
          reqData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else if (props.form.mode === "U") {
      data.discount = Number(data.discount)
      if((data.startDate)){
        var startDateArrayD = data.startDate && data.startDate.split("/");
        data.startDate = startDateArrayD[1] + '/' + startDateArrayD[0] + '/' + startDateArrayD[2];
      } else{
        data.startDate = null;
      }
      if((data.endDate)){
        var endDateArrayD = data.endDate && data.endDate.split("/");
        data.endDate = endDateArrayD[1] + '/' + endDateArrayD[0] + '/' + endDateArrayD[2];
      } else {
        data.endDate = null;
      }
      const discountId = props.form.data.id;
      await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/productGroups/${props.groupId}/discounts/${discountId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setIsProcessing(false);
      props.success();
    } catch (err) {
      errorMsg = 'Error occured while creating product group discount';
      // errorMsg = err.response && err.response.data.message &&
      //   (err.response.data.message[0] === "productId must be unique" || err.response.data.message[0] === "Validation error") ? "Product Item already exists" : errorMsg;
      setIsError(true);
      setIsProcessing(false);
    }
  };
 
  const handleDateChange = (value,type) => {
    const clonedDate = _.cloneDeep(date);
    clonedDate[type] = value
    setDate(clonedDate)
  };

  const dateValidation=()=>{
    if (props.form.mode === "U"){
      let sDate = date.start instanceof Date;
      let eDate = date.end instanceof Date;
      if(!sDate && !(date.start === null)){
        var startDateArray = date.start && date.start.split("/");
        date.start = new Date(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
      }
      if(!eDate && !(date.end === null)){
        var endDateArray = date.end && date.end.split("/");
        date.end = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
      }
    }
    const start = moment(date.start);
    const end = moment(date.end);
    const diff = end.diff(start, 'day');
    return diff >= 0 || !date.start || !date.end;
  }

  const startDateAvl=props.form.data.startDate ? moment(props.form.data.startDate):moment();
  const today=moment();
  const diff = today.diff(startDateAvl, 'day');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="caption">{'Discount %'}</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.discount)}
              inputRef={register({
                required: "Discount is required",
                pattern: {
                  required: "Enter product group discount",
                  value: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/,
                  message: "Provide a valid discount rate",
                },
              })}
              size="small"
              name="discount"
            />
            <FormHelperText error>
              {errors.discount && errors.discount.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
          <Typography variant="caption">Start Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    helperText=""
                    size="small"
                    error={Boolean(errors.startDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    inputRef={register({
                      required: "Start date is required",
                      pattern: {
                       value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                      validate:()=> dateValidation() || 'Start date should be lesser than end date'
                    })}
                    name="startDate"
                    value={date.start}
                    onChange={(value)=>handleDateChange(value,"start")}
                    disablePast={true}
                    minDateMessage=''
                    maxDate="9999-12-31"
                    disabled = {props.form.mode !== "C" && !(diff<=0)}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.startDate && errors.startDate.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
          <Typography variant="caption">End Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    helperText=""
                    size="small"
                    error={Boolean(errors.endDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    inputRef={register({
                      required: "End date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                    })}
                    name="endDate"
                    value={date.end}
                    onChange={(value)=>handleDateChange(value,"end")}
                    disablePast={true}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.endDate && errors.endDate.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
                <Typography variant="caption">Active</Typography>
                <Controller
              as={Checkbox}
              name="isActive"
              type="checkbox"
              inputRef={register({})}
              control={control}
            />
              </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
        <Grid item>
          <Button
            size="small"
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="secondary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default ManageDiscountForm;
