import React, { useState, useRef, useEffect } from "react";
import { renderToString } from 'react-dom/server'
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { jsonToCsv, downloadCSVFile, formatDate } from '../common/util';
import moment from 'moment';
import {
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import ReactToPrint from "react-to-print";
import { locations } from "../common/lookupSlice";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { currentUser } from "../login/loginSlice";
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MailIcon from '@material-ui/icons/Mail';
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import { stockEntryTypes } from "../common/constants";

class CompToPrint extends React.Component {
  render() {
    return (
      <Table style={{ paddingTop: 10 }} size="small">
        <TableHead>
          <TableRow>
            <StyledHeaderCell>Stock Entry No.</StyledHeaderCell>
            <StyledHeaderCell align="left">Date</StyledHeaderCell>
            <StyledHeaderCell align="left">Source Location</StyledHeaderCell>
            <StyledHeaderCell align="left">Target Location</StyledHeaderCell>
            <StyledHeaderCell align="left">Stock Entry Type</StyledHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.invMovement.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography variant="subtitle1">
                  No records found
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            this.props.invMovement.map((row, index) => (
              <TableRow
                key={index}
                hover={true}
              >
                <TableCell component="th" scope="row">
                  {row.stockVoucherNum}
                </TableCell>
                <TableCell align="left">
                  {row.stockVoucherDate ? formatDate(row.stockVoucherDate) : ''}
                </TableCell>
                <TableCell align="right">
                  {row.sourceLocation}
                </TableCell>
                <TableCell align="left">
                  {row.destinationLocation}
                </TableCell>
                <TableCell align="left">
                  {row.stockEntryType}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    );
  }
}

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};

class CompToMailMove extends React.Component {
  render() {
    return (
      <div>
        <label style={{ textAlign: "left" }}>
          Hello, Here is the list of Inventory Movement. For more information, kindly login to the application.
        </label><br></br>
        <div style={divStyle} align="center">
          <table style={{ paddingTop: 10 }} size="small">
            <thead>
              <tr>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Stock Entry No.</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Date</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Source Location</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Target Location</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Stock Entry Type</span></td>
              </tr>
            </thead>
            <tbody>
              {this.props.invMovement.length === 0 ? (
                <tr>
                  <td colspan="9" align="center">
                    <span style={pStyle2}>No records found</span>
                  </td>
                </tr>
              ) : (
                this.props.invMovement.map((row, index) => (
                  <tr
                    id={index}
                  >
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.stockVoucherNum}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.stockVoucherDate && formatDate(row.stockVoucherDate)}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.sourceLocation}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.destinationLocation}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.stockEntryType}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const InventoryMovementReport = (props) => {
  const componentRef = useRef();
  const inventoryFromLocations = useSelector(locations);
  const inventoryLocations = inventoryFromLocations.filter(loc => loc.type !== "CORPORATE");
  const pageSize = 10;
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const orgName = loggedInUser && loggedInUser.org && loggedInUser.org.name;
  const [count, setCount] = useState();
  const [message, setMessage] = useState('');
  const [sendSale, setSendSale] = useState(false);
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [emailSendData, setEmailSendData] = useState({
    emailId: '',
    emailSubject: ''
  });
  let { emailId, emailSubject } = emailSendData;
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false)
  const [source, setSource] = useState('');
  const [stockEntryType, setStockEntryType] = useState('');
  const [destination, setDestination] = useState('')
  const [saleDate, setSaleDate] = useState({ startDate: null, endDate: null });
  const [invMovement, setInvMovement] = useState([]);
  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/stockvoucher`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.stockVouchers);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      sourceLocationId: source,
      destinationLocationId: destination,
      stockEntryType: stockEntryType,
      startDate: saleDate.startDate ? moment(saleDate.startDate).format('DD/MM/YYYY') : "",
      endDate: saleDate.endDate ? moment(saleDate.endDate).format('DD/MM/YYYY') : "",
      page: 1,
    });
  };

  const handleDownload = async () => {
    try {
      const invMoveData = await getInvMovememts();
      let data;
      if (invMoveData.length === 0) {
        data = ''
      }
      else {
        data = jsonToCsv(invMoveData)
      }
      downloadCSVFile(data, "inventoryMovements")
    } catch (err) {
      setIsError(true);
    }
  }

  const getInvMovememts = async () => {
    const clonedQuery = _.cloneDeep(query);
    delete clonedQuery.page;
    delete clonedQuery.pageSize;
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/stockvoucher`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: clonedQuery,
      }
    );
    const moves = result.data && result.data.stockVouchers.map(x => {
      return {
        id: x.id,
        stockVoucherNum: x.stockVoucherNum && x.stockVoucherNum.toString(),
        stockVoucherDate: x.stockVoucherDate,
        sourceLocation: (x.sourceLocation && x.sourceLocation.name) ? x.sourceLocation.name : "--NA--",
        destinationLocation: (x.destinationLocation && x.destinationLocation.name) ? x.destinationLocation.name : "--NA--",
        stockEntryType: (x.stockEntryType === "STOCKENTRY") ? "Stock Entry" : "Inventory Movement"
      }
    });
    return moves;
  }

  const handlePrint = async () => {
    try {
      const data = await getInvMovememts();
      setInvMovement(data);
    } catch (err) {
      setIsError(true);
    }
  }

  const handleOpenOrderMail = async () => {
    try {
      const data = await getInvMovememts();
      setInvMovement(data);
      setSendSale(true);
      setEmailSendData({ emailId: "", emailSubject: `${orgName} - Inventory Movements` });
    } catch (err) {
      setIsError(true);
    }
  }

  const handleDateChange = (value, field) => {
    const clonedDate = _.cloneDeep(saleDate);
    clonedDate[field] = value;
    setSaleDate(clonedDate)
  };

  const handleReset = (e) => {
    window.location.reload();
    // setQuery({ page: 1, pageSize: pageSize })
  }

  const onCloseSendInvoice = () => {
    setSendSale(false);
    setEmailSendData({ emailId: '', emailSubject: '' })
  }
  const onSendEmailInvoice = async () => {
    let isEmail = false;
    let isSubject = (emailSubject && emailSubject.length > 1) ? true : false;
    if (emailId && emailId.length > 0) {
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds, (data) => {
        if (!mailformat.test(String(data).toLowerCase())) {
          isEmail = false;
        }
      })
    }
    if (isEmail && isSubject) {
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData = "<div></div>"
      if (sendSale) {
        invoiceBodyData = () => {
          return (
            <CompToMailMove ref={componentRef} invMovement={invMovement} />
          )
        }
      }
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to', emailId)
      bodyFormData.append('subject', emailSubject)
      bodyFormData.append('invoice', renderToString(invoiceBodyData()))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
        bodyFormData,
        config
      );
      if (result.status === 200) {
        setIsSuccess(true)
        setMessage(result.data && result.data.message)
        setSendSale(false);
        setEmailSendData({ emailId: '' })
      }
    } else {
      if (!isEmail) {
        setEmailHelperText(true);
      }
      if (!isSubject) {
        setEmailSubjectHelperText(true);
      }
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/reports")}>
                Reports
              </Link>
              <Typography component="span" color="textPrimary">
                Stock Entry Report
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Start Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="startDate"
                    value={saleDate.startDate}
                    onChange={(value) => handleDateChange(value, "startDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">End Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    name="endDate"
                    value={saleDate.endDate}
                    onChange={(value) => handleDateChange(value, "endDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Source Location</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  options={inventoryLocations}
                  size="small"
                  getOptionLabel={(option) => option.name}
                  onChange={(e, val) => {
                    if (val) {
                      setSource(val.id);
                      setQuery({
                        ...query,
                        sourceLocationId: val.id,
                        page: 1,
                      });
                    }
                  }}
                  onInputChange={(e, val) => {
                    if (!val) {
                      setSource(val);
                      setQuery({
                        ...query,
                        sourceLocationId: val,
                        page: 1,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Target Location</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  options={inventoryLocations}
                  size="small"
                  getOptionLabel={(option) => option.name}
                  onChange={(e, val) => {
                    if (val) {
                      setDestination(val.id);
                      setQuery({
                        ...query,
                        destinationLocationId: val.id,
                        page: 1,
                      });
                    }
                  }}
                  onInputChange={(e, val) => {
                    if (!val) {
                      setDestination(val);
                      setQuery({
                        ...query,
                        destinationLocationId: val,
                        page: 1,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Stock Entry Type</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  options={stockEntryTypes}
                  size="small"
                  getOptionLabel={(option) => option.label}
                  onChange={(e, val) => {
                    if (val) {
                      setStockEntryType(val.code);
                      setQuery({
                        ...query,
                        stockEntryType: val.code,
                        page: 1,
                      });
                    }
                  }}
                  onInputChange={(e, val) => {
                    if (!val) {
                      setStockEntryType(val);
                      setQuery({
                        ...query,
                        stockEntryType: val,
                        page: 1,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  filterOptions={(options, addrType) =>
                    options.filter((option) =>
                      typeof option.label === 'string' &&
                      option.label.toLowerCase().includes(addrType.inputValue.toLowerCase())
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid item container justifyContent={"flex-end"} md={12} xs={12}>
              <Box mt={2} mr={2}>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Generate Report
                </Button>
              </Box>
              <Box mt={2} mr={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  startIcon={<MailIcon />}
                  onClick={handleOpenOrderMail}
                >
                  Mail
                </Button>
              </Box>

              <Grid item>
                <Box mt={2} mr={2}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Download
                  </Button></Box>
              </Grid>
              <Grid item>
                <Box mt={2} mr={2}>
                  <ReactToPrint
                    onBeforeGetContent={handlePrint}
                    trigger={() => <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<PrintIcon />}

                    >
                      Print
                    </Button>
                    }
                    content={() => componentRef.current}
                  />
                </Box>
              </Grid>

              <Grid item>
                <Box mt={2}>
                  <Button size="small"
                    onClick={handleReset} variant="contained" color="secondary">
                    clear</Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Stock Entry No.</StyledHeaderCell>
                <StyledHeaderCell align="left">Entry Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Source Location</StyledHeaderCell>
                <StyledHeaderCell align="left">Target Location</StyledHeaderCell>
                <StyledHeaderCell align="left">Stock Entry Type</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      style={{ cursor: props.modal ? "pointer" : "" }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }
                    >
                      <TableCell component="th" scope="row">
                        <Link onClick={() =>
                          history.push({
                            pathname: "/inventoryMovement",
                            state: row
                          })}>{row.stockVoucherNum}</Link>
                      </TableCell>
                      <TableCell align="left">
                        {row.stockVoucherDate && formatDate(row.stockVoucherDate)}
                      </TableCell>
                      <TableCell align="left">
                        {(row.sourceLocation && row.sourceLocation.name) ? row.sourceLocation.name : "--NA--"}
                      </TableCell>
                      <TableCell align="left">
                        {(row.destinationLocation && row.destinationLocation.name) ? row.destinationLocation.name : "NA"}
                      </TableCell>
                      <TableCell align="left">
                        {(row.stockEntryType === "STOCKENTRY") ? "Stock Entry" : "Inventory Movement"}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box display="none">
          <CompToPrint ref={componentRef} invMovement={invMovement} />
        </Box>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={sendSale}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {"Mail Inventory Movements"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '180px' }}>
          <Grid container>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span" >Mail to *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                value={emailId}
                onChange={e => setEmailSendData({ ...emailSendData, emailId: e.target.value })}
              />
              {isEmailHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter valid email id
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: '10px' }}>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span">Subject *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                value={emailSubject}
                onChange={e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value })}
              />
              {isEmailSubjectHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter email subject
              </FormHelperText>}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              onClick={onSendEmailInvoice}
              color="secondary"
              size="small"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setIsSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message ? message : "Thanks for shopping!"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default InventoryMovementReport;
