import React, { useState, useEffect } from "react";

import { API_REST_PASSWORD } from "../../config/constants";
import axios from "axios";
import logo from "../../images/logo.png";
import Visibility from '@material-ui/icons/Visibility';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Box,
  Snackbar,
  Container,
  Button,
  InputAdornment,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import MuiAlert from "@material-ui/lab/Alert";
import "./Login.css";
const ResetPassowrd = (props) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const [msg, setIsMsg] = useState("");
  const [isMsg, setIsMsgShow] = useState(false);
  const [isErrPassword, setIsErrPassword] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [passwordShow, handleClickShowPassword] = useState(false);
  const [passwordConfirmShow, handleClickShowConfirmPassword] = useState(false);
  
  

  useEffect(() => {
    setIsMsg("");
    setShowSpinner(false);
  }, [userName, password]);

  const isLoginDisabled = () => {
    if (userName.length < 5 || password.length < 8 || showSpinner) {
      return true;
    } else {
      return false;
    }
  };
  const backToLoginPage = () =>{
    window.location.href ='/';
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsMsg("");
    setIsErrPassword(false)
    setIsMsgShow(false);
    setShowSpinner(true);
    if(password === confirmPassword){
      await axios
      .patch(`${process.env.REACT_APP_SERVICE_URL}/${API_REST_PASSWORD}`, {
        userName: userName,
        password: password,
      })
      .then((res) => {
        if(res && res.data && (res.data.status === "SUCCESS")){
          setIsMsgShow(true);
          setIsMsg(res.data && res.data.message);
          setTimeout(()=>{
            window.location.href = "/";
          }, 2000);
        }else{
          setIsMsg(res.response && res.response.data && res.response.data.message);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setIsMsg(err.response.data.message);
        } else {
          setIsMsg("Cannot process request. Please try again.");
        }
      });
    }else{
      setIsErrPassword(true)
      setIsMsg("Those passwords didn’t match. Try again.");
    }
    setShowSpinner(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="md">
        <Paper elevation={12}>
          <Grid container direction="div">
            <Grid item xs container>
              <Grid item xs={12}>
                <Box
                  fontWeight="fontWeightBold"
                  bgcolor="primary.main"
                  color="primary.contrastText"
                >
                  <Typography className="header"><IconButton size="small" style={{color:"white"}} onClick={() => backToLoginPage()}><ArrowBackIcon /></IconButton> RESET PASSWORD</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              className="grid-container"
              container
              spacing={4}
              direction="row"
              justify="center"
            >
              <Grid item md container justify="center" alignItems="center">
                <Grid item>
                  <img src={logo} alt="logo" style={{width:"240px", height:"115px"}}/>
                </Grid>
              </Grid>
              <Grid
                item
                md
                container
                direction="column"
                justify="center"
                spacing={5}
              >
                <Grid item container spacing={2} justify="center">
                  <Box margin="20px 0px 0px 0px" padding="0px 10px">
                    <Grid item>
                      <Typography component="span" color="primary">
                        <Box fontWeight="fontWeightBold" fontSize="14px">E-MAIL ID</Box>
                      </Typography>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={userName}
                        fullWidth={true}
                        onChange={(e) => setUserName(e.target.value)}
                        InputProps={{
                          name: "userName",
                          autoFocus: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography component="span" color="primary">
                        <Box fontWeight="fontWeightBold" fontSize="14px">NEW PASSWORD</Box>
                      </Typography>
                      <TextField
                         type={passwordShow ? 'text' : 'password'}
                        variant="outlined"
                        size="small"
                        fullWidth
                        password={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                          name: "password",
                          endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(e)=>{handleClickShowPassword(!passwordShow)}}
                              edge="end"
                            >
                              {passwordShow ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                        }}
                      />
                      
                    </Grid>
                    <Grid item>
                      <Typography component="span" color="primary">
                        <Box fontWeight="fontWeightBold" fontSize="14px">CONFIRM PASSWORD</Box>
                      </Typography>
                      <TextField
                        error={isErrPassword}
                         type={passwordConfirmShow ? 'text' : 'password'}
                        variant="outlined"
                        size="small"
                        fullWidth
                        password={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                          name: "confirmPassword",
                          endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confrim password visibility"
                              onClick={(e)=>{handleClickShowConfirmPassword(!passwordConfirmShow)}}
                              edge="end"
                            >
                              {passwordConfirmShow ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                        }}
                      />
                      
                    </Grid>
                    <Grid item style={{marginTop:"10px"}}>
                      <Button
                        type="submit"
                        disabled={isLoginDisabled()}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        SUBMIT
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
                
              </Grid>
            </Grid>
            <Grid item xs container>
              <Grid container justify="center">
                <Grid item>
                  <Typography variant="caption" color="error">
                    {(msg && !isMsg)? (
                      msg
                    ) : showSpinner ? (
                      <CircularProgress />
                    ) : (
                      <div></div>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Snackbar
          open={isMsg}
          autoHideDuration={5000}
          onClose={() => setIsMsgShow(false)}
        >
          <MuiAlert
            onClose={() => setIsMsgShow(false)}
            elevation={6}
            variant="filled"
            severity="success"
          >
            {msg}
          </MuiAlert>
      </Snackbar>
      </Container>
    </form>
  );
};

export default ResetPassowrd;
