import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  Button,
  Grid,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import axios from "axios";
import { custTypes } from "../../common/lookupSlice";
import _ from 'lodash';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns";
import { API_ALL_PRODUCTS } from "../../../config/constants";
import { useParams } from "react-router-dom";
import Calculator from '../../recordSales/calculator';
import calculateIcon from '../../../images/calculate.svg';
import CloseIcon from "@material-ui/icons/Close";

let errorMsg;
function ProductPriceForm(props) {
  const { id } = useParams();
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [priceData, setPriceData] = useState({ open: false });
  const [date, setDate] = useState({start: props.form.data && props.form.data.startDate ? moment(props.form.data.startDate).format('DD/MM/YYYY'):moment().format('DD/MM/YYYY'),end: props.form.data && props.form.data.endDate ? moment(props.form.data.endDate).format('DD/MM/YYYY'):null});
  const { handleSubmit, control, errors, register } = useForm({
    defaultValues: {
      id: props.form.data.id,
      custTypeId: props.form.data.customerType ? props.form.data.customerType.id : '',
      price: props.form.data.price ? (Math.round(props.form.data.price * 100) / 100).toFixed(2):'',
      landingCost: props.landingCost ? (Math.round(props.landingCost * 100) / 100):"NA" ,
      purchasePrice: props.purchasePrice ? (Math.round(props.purchasePrice * 100) / 100):"NA",
      startDate: date.start,
      endDate: date.end,
    },
  });

  const [checked, setChecked] = React.useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onSubmit = async (data) => {
    setIsProcessing(true);
    setIsError(false);
    if(!(data.startDate)) data.startDate = null;
    if(!(data.endDate)) data.endDate = null;
    errorMsg = "";
    try {
      data = JSON.parse(JSON.stringify(data))
      if(data.startDate){
        var startDateArray = data.startDate && data.startDate.split("/");
        data.startDate = new Date(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
      }
      if(data.endDate){
        var endtDateArray = data.endDate && data.endDate.split("/");
        data.endDate = new Date(endtDateArray[1] + '/' + endtDateArray[0] + '/' + endtDateArray[2]);
      }
      if (props.form.mode === "C") {
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}/prices`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else if (props.form.mode === "U") {
        await axios.patch(
          `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}/${id}/prices/${props.form.data.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setIsProcessing(false);
      props.success();
    } catch (err) {
      errorMsg = err.response.data.message;
      setIsError(true);
      setIsProcessing(false);
    }
  };
  const customerTypes = useSelector(custTypes);

  const handleDateChange = (value,type) => {
    const clonedDate = _.cloneDeep(date);
    clonedDate[type] = value
    setDate(clonedDate)
  };

  // const dateValidation=()=>{
  //   const start = moment(date.start);
  //   const end = moment(date.end);
  //   const diff = end.diff(start, 'day');
  //   return diff >= 0 || !date.start || !date.end;
  // }

  
  const dateValidation=()=>{
    let sDate = date.start instanceof Date;
    let eDate = date.end instanceof Date;
    if(!sDate && !(date.start === null)){
      var startDateArray = date.start && date.start.split("/");
      date.start = new Date(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
    }
    if(!eDate && !(date.end === null)){
      var endDateArray = date.end && date.end.split("/");
      date.end = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
    }
  const start = moment(date.start);
  const end = moment(date.end);
  const diff = end.diff(start, 'day');
  return diff >= 0 || !date.start || !date.end;
}

  // const endDateValidation=()=>{
  //   const today = moment();
  //   const end = moment(date.end);
  //   const diff = end.diff(today, 'minutes');
  //   return diff > -2
  // }

  const endDateValidation=()=>{
    const today = moment();
    let eDate = date.end instanceof Date;
    if(!eDate && !(date.end === null)){
      var endDateArray = date.end && date.end.split("/");
      date.end = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
    }
    const end = moment(date.end);
    const diff = end.diff(today, 'minutes');
    return diff > -2
  }

  const startDateAvl=props.form.data.startDate ? moment(props.form.data.startDate):moment();
  const today=moment();
  const diff = today.diff(startDateAvl, 'day');

  const onCalculateClick = () => {
    setPriceData({ ...priceData, productName:props.prodData.productName, cgst: props.prodData.CGST, sgst: props.prodData.SGST, igst: props.prodData.IGST, open: true });
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={3} xs={12}>
            <Typography variant="caption">Customer Type</Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={Boolean(errors.custTypeId)}
            >
              <Controller
                as={
                  <Select>
                    {customerTypes.map(function (type,idx) {
                      return (
                        <MenuItem key={type.code} value={type.id}>
                          {type.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="custTypeId"
                size="small"
                variant="outlined"
                rules={{ required: "Customer type is required" }}
                control={control}
                defaultValue=""
              />
            </FormControl>
            <FormHelperText error>
              {errors.custTypeId && errors.custTypeId.message}
            </FormHelperText>
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption">Price</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.price)}
              inputRef={register({
                required: "Price is required",
                pattern: {
                  required: "Price is required",
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Provide a valid Price",
                },
              })}
              inputProps={{style: { textAlign: 'right' }}}
              size="small"
              name="price"
            />
            <FormHelperText error>
              {errors.price && errors.price.message}
            </FormHelperText>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant="caption">Product Price</Typography>
            <TextField
              variant="outlined"
              fullWidth
              inputRef={register({
              })}
              size="small"
              disabled
              name="purchasePrice"
              inputProps={{style: { color: '#0C4B60',fontWeight: 'bold',textAlign: 'right' }}}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant="caption">Landing Cost</Typography>
            <TextField
              variant="outlined"
              fullWidth
              inputRef={register({
              })}
              size="small"
              disabled
              inputProps={{style: { color: '#0C4B60',fontWeight: 'bold',textAlign: 'right' }}}
              name="landingCost"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
          <Typography variant="caption">Start Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    error={Boolean(errors.startDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    disabled = {props.form.mode !== "C" && !(diff<=0)}
                    inputRef={register({
                      required: "Start date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                      validate:()=> dateValidation() || 'Start date should be lesser than end date'
                    })}
                    name="startDate"
                    value={date.start}
                    onChange={(value)=>handleDateChange(value,"start")}
                    disablePast={true}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.startDate && errors.startDate.message}
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
          <Typography variant="caption">End Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    error={Boolean(errors.endDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    inputRef={register({
                      required: " End date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                     validate:()=> endDateValidation() || 'End date cannot be lesser than today'
                    })}
                    name="endDate"
                    value={date.end}
                    onChange={(value)=>handleDateChange(value,"end")}
                    disablePast={true}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.endDate && errors.endDate.message}
            </FormHelperText>
          </Grid>
        </Grid>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item style={{"marginRight":"-20px"}}>
            <Typography style={{"fontSize":"13px"}}>InterState GST
            <FormControlLabel style={{"marginTop":"-5px", "marginLeft": "1px"}}
              control={<Checkbox checked={checked} onChange={handleChange} />}/></Typography></Grid>
          <Grid item>
            <IconButton size="small" onClick={() => onCalculateClick()}>
              <img src={calculateIcon} alt="history" /></IconButton></Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.cancel();
              }}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/* calculator */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={priceData.open}
        onClose={() => {
          setPriceData({ ...priceData, open: false });
        }}
      >
        <DialogTitle style={{paddingBottom : "0px"}}>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Price Calculator
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setPriceData({ ...priceData, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Calculator
            cancel={() => {
              setPriceData({ ...priceData, open: false });
            }}
            priceData={priceData}
            intraState={checked}
          />
        </DialogContent>
      </Dialog>

    </form>
  );
}

export default ProductPriceForm;
