import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

// const theme = createMuiTheme({
//   palette: {
//     primary: green,
//   },
//   overrides: {
//     // Style sheet name ⚛️
//     MuiButton: {
//       // Name of the rule
//       label: {
//         // Some CSS
//         color: "#ffffff",
//       },
//     },
//   },
// });

const palette = {
  primary: { main: "#0C4B60", contrastText: "#ffffff" },
  secondary: { main: "#4B9170", contrastText: "#ffffff" },
  text: { main: "#0C4B60", contrastText: "#ffffff" },
  pos: {  main: "#0000", contrastText: "#00000" }
};

const typography = {
  fontFamily: [
    '"Montserrat"',
    '"Roboto"',
    '"Helvetica Neue"',
    '"Arial"',
    '"sans-serif"',
  ].join(","),
};
const themeName = "FSM";

let theme = createTheme({ palette, typography, themeName });
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
