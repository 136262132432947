/* eslint-disable */
import React, { useState, useEffect  } from "react";
import {
  useForm,
  Controller,
  FormContext,
} from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import "react-html5-camera-photo/build/css/index.css";
import SearchIcon from "@material-ui/icons/Search";
import { useSelector } from "react-redux";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import Link from "@material-ui/core/Link";
import Customers from "../customers/Customer";
import SupplierSelection from "../purchase/SupplierSelection";
import SalePayment from '../recordSales/Payment';
import PurchasePayment from '../purchase/purchasePayment';
import { currentUser } from "../login/loginSlice";
import SelectPurchaseOrSaleinvoice from "../purchase/purchaseOrSaleInvoiceSelect";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Grid,
  MenuItem,
  Box,
  FormControl,
  // FormHelperText,
  Select,
  TextField,
  Typography,
  Breadcrumbs,
  IconButton,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DirectPaymentComp = (props) => {
  const token = localStorage.getItem("authToken");
  const partyTypes = [{ id: "CUSTOMER", description: "Customer" }, { id: "SUPPLIER", description: "Supplier" }]
  const [partyType, setPartyTypes] = useState("CUSTOMER");
  const [customerModal, setCustomerModal] = useState(false);
  const loggedInUser = useSelector(currentUser);
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  const [isError, setIsError] = useState(false);
  const [costCenterName, setCostCenterName] = useState('');
  const [costCenterList, setCostCenterList] = useState([]);
  const [selectedPInv, setSelectedSPInv] = useState({ spInvNum: "", details: null });
  const [notes, setNotes] = useState("");
  const [showPInv, setShowSPInv] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [payments, setPayments] = useState({ open: false });
  const [calculate, setCalculate] = useState(null);
  const [custData, setCustData] = useState({
    customerId: null,
    customer: null
  });

  const [supplierModal, setSupplierModal] = useState(false);
  const [supplierData, setSupplierData] = useState({
    supplierId: null,
    supplier: null
  });

  const loadData = async (COMPANY_NAME_IN_ERPNEXT) => {
    let count = 0;
    try {
      if(COMPANY_NAME_IN_ERPNEXT){
        let queryCostCenter = {
          fields: ["name", "owner"],
          filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT, disabled: 0, is_group: 0 })
        }
        const resultCostCenter = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Cost Center`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: queryCostCenter,
          }
        );
        setCostCenterList(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data);
        setCostCenterName(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data && resultCostCenter.data.data.length > 0 && resultCostCenter.data.data[0].name)
      }
    }
    catch (err) {
      console.log("err",err);
    }
  };

  useEffect (()=>{
    loadData(COMPANY_NAME_IN_ERPNEXT)
  },[COMPANY_NAME_IN_ERPNEXT])



  const handleCustomerSelection = (customer) => {
    setCustData({ ...custData, customerId: customer.id, customer: customer })
  };
  const handleSupplierSelection = (supplier) => {
    setSupplierData({ ...supplierData, supplierId: supplier.id, supplier: supplier })
  };

  const makePayment = async () => {
    if (((custData && custData.customerId) || (supplierData && supplierData.supplierId)) && selectedPInv && selectedPInv.details && selectedPInv.details.id) {
      setPayments({ ...payments, open: true })
      setCalculate({
        ...calculate,
        total: selectedPInv.details.outStandingAmount
      })
    }
  }
  const submitDirectSalePayment = async (res) => {
    if (res) {
      try {
        let payments = {
          invoiceId: selectedPInv && selectedPInv.details && selectedPInv.details.id,
          status: res.status,
          paymentMethodId: res.paymentMethodId,
          paymentModeId: res.paymentModeId,
          paymentId: res.paymentId,
          amount: res.amount,
          totalAmount: res.totalAmount,
          paidAmount: res.paidAmount,
          paymentDetails: res.paymentDetails,
          erpInvoiceNum: selectedPInv && selectedPInv.details && selectedPInv.details.ERPInvoiceNum,
          customerId: custData && custData.customerId,
          razorPayOrderCreationId: res.razorPayOrderCreationId,
          razorpayPaymentId: res.razorpayPaymentId,
          razorpayOrderId: res.razorpayOrderId,
          razorpaySignature: res.razorpaySignature,
          refReceiptNumber: res.refReceiptNumber,
          notes: notes,
          costCenter: costCenterName
        }
        //Post or put the req to save changes to db
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/payment`,
          payments,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPayments({ ...payments, open: false })
        setIsSuccess(true);
        setIsSaved(true);
      } catch (err) {
        console.log("err", err);
        setIsError(true)
        setPayments({ ...payments, open: false })
      }
    } else {
      setIsError(true);
      setPayments({ ...payments, open: false })
    }
  }

  const submitDirectPurchasePayment = async (value, pData, returnResClback) => {
    if (value) {
      try {
        let paymentPayload = {
          invoiceId: selectedPInv && selectedPInv.details && selectedPInv.details.id,
          status: "PAID",
          paymentMethodId: (pData.paymentMode === 3) ? 2 : 1,
          paymentModeId: pData.paymentMode,
          paymentId: pData.paymentId,
          amount: (pData.amountPaid) ? parseFloat(pData.amountPaid) : 0,
          totalAmount: Math.round(selectedPInv && selectedPInv.details && selectedPInv.details.total),
          paidAmount: (pData.amountPaid) ? parseFloat(pData.amountPaid) : 0,
          paymentDetails: pData.payment,
          erpInvoiceNum: selectedPInv && selectedPInv.details && selectedPInv.details.ERPNum,
          supplierId: supplierData && supplierData.supplierId,
          notes: notes,
          costCenter: costCenterName
        }
        //Post or put the req to save changes to db
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/payment/purchase`,
          paymentPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPayments({ ...payments, open: false })
        setIsSuccess(true);
        setIsSaved(true);
        returnResClback();
      } catch (err) {
        console.log("err", err);
        setIsError(true);
        setPayments({ ...payments, open: false })
      }

    } else {
      setIsError(true);
      setPayments({ ...payments, open: false })
    }

  }

  const restClick = () => {
    setPartyTypes("CUSTOMER");
    setCustData({
      customerId: null,
      customer: null
    })
    setSelectedSPInv({ spInvNum: "", details: null });
    setSupplierData({
      supplierId: null,
      supplier: null
    })
    setCalculate(null)
  }

  let cancelClick = () => {
    window.location.href = `/accountshome`;
  }

  return (
    <FormContext >
      <form style={{ paddingTop: 10 }}>
        <Grid container >
          <Grid item xs={6}>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="/" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary" style={{ fontWeight: 'bold' }}>
                Create Payment Enrty
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={6}>

          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" style={{ marginTop: '10px' }}>
          <Grid item container md={3} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item md={12} xs={12}>
                <Grid item container justifyContent="flex-start">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Payment Number</Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  name="id"
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={"Auto Generate"}
                  disabled
                  // value={data.id}
                  InputProps={{
                    style: { color: "black" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={3} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item md={12} xs={12}>
                <Grid item container justifyContent="flex-start">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Date</Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    size="small"
                    inputVariant="outlined"
                    format="dd/MM/yyyy HH:mm:ss"
                    name="createdOn"
                    disabled={true}
                    value={new Date()}
                  // onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
                {/* <TextField
                  name="createdOn"
                  disabled
                  fullWidth
                  InputProps={{
                    style: { color: "black" },
                  }}
                  variant="outlined"
                  size="small"
                  inputRef={register}
                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={3} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item md={12} xs={12}>
                <Grid item container justifyContent="flex-start">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Party Type<span style={{ color: 'red' }}>*</span></Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  disabled={isSaved}
                  fullWidth
                >
                  <Select style={{ color: "black" }}
                    name="partyType"
                    value={partyType}
                    onChange={(e) => {
                      setPartyTypes(e.target.value);
                      setCustData({
                        customerId: null,
                        customer: null
                      })
                      setSelectedSPInv({ spInvNum: "", details: null });
                      setSupplierData({
                        supplierId: null,
                        supplier: null
                      })
                    }}
                    variant="outlined"
                  >
                    {partyTypes.map(function (partyType) {
                      return (
                        <MenuItem key={partyType.id} value={partyType.id} >
                          {partyType.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={3} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item md={12} xs={12}>
                <Grid item container justifyContent="flex-start">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Cost Center<span style={{ color: 'red' }}>*</span></Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  name="locality"
                  value={costCenterName}
                  style={{ width: "100%" }}
                  disabled={isSaved}
                  onChange={(e) => {
                    setCostCenterName(e.target.value);
                  }}
                  variant="outlined"
                >
                  {costCenterList.map(function (costCenter) {
                    return (
                      <MenuItem key={costCenter} value={costCenter.name}>
                        {costCenter.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item container xs={6} justifyContent="flex-start" >
            <IconButton
              disabled={isSaved}
              onClick={() => {
                if (partyType === "CUSTOMER") {
                  setCustomerModal(true)
                } else {
                  setSupplierModal(true)
                }
              }}
              style={{ backgroundColor: 'transparent', padding: "12px 12px 12px 0px" }} >
              <Typography style={{ fontWeight: "bold", color: "black", pointerEvents: "none" }}>{(partyType === "CUSTOMER") ? "Customer" : "Supplier"} :</Typography>
              &nbsp;
              <SearchIcon />
            </IconButton></Grid>
          <Grid item container xs={6} justifyContent="flex-end" >
          </Grid>
        </Grid>
        <Card style={{ width: "100%", padding: "10px", marginBottom: "20px" }}>
          <Grid container>
            <Grid item container xs={12} justifyContent="flex-start" >
              <Typography display="block" variant="overline" style={{ fontWeight: "Bold" }}>
                {((custData && custData.customer && custData.customer.fullName) || (supplierData && supplierData.supplier && supplierData.supplier.fullName))
                  ? "Name and Address:" : `Click Search Icon and Select ${(partyType === "CUSTOMER") ? "Customer" : "Supplier"}`}
              </Typography>
            </Grid>
            {(partyType === "CUSTOMER") ? custData && custData.customer && custData.customer.fullName &&
              <>
                <Grid item container xs={6} justifyContent="flex-start" >
                  <Typography display="block" style={{ fontWeight: "Bold" }}>
                    {custData.customer.fullName}<br />
                  </Typography>
                  <Typography display="block" variant="overline">
                    {custData.customer.address1} {custData.customer.address2} <br />
                    {custData.customer.address3} {custData.customer.locality} <br />
                    {custData.customer.pincode}
                  </Typography>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-start" >
                  <Typography display="block" variant="overline" style={{ fontWeight: "Bold" }}>
                    &nbsp;
                  </Typography><br></br>
                  <Typography display="block" variant="overline">
                    <b>Mobile Number</b> : {(custData.customer.mobileNumber) ? custData.customer.mobileNumber : "--"} <br />
                    <b>Email</b> : {(custData.customer.email) ? custData.customer.email : "--"}
                  </Typography>
                </Grid>
              </> : supplierData && supplierData.supplier && supplierData.supplier.fullName && <>
                <Grid item container xs={6} justifyContent="flex-start" >
                  <Typography display="block" style={{ fontWeight: "Bold" }}>
                    {supplierData.supplier.fullName}
                  </Typography><br />
                  <Typography display="block" variant="overline">
                    {supplierData.supplier.address1} {supplierData.supplier.address2} <br />
                    {supplierData.supplier.address3} {supplierData.supplier.locality} <br />
                    {supplierData.supplier.pincode}
                  </Typography>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-start" >
                  <Typography display="block" variant="overline" style={{ fontWeight: "Bold" }}>
                    &nbsp;
                  </Typography><br></br>
                  <Typography display="block" variant="overline">
                    <b>Mobile Number</b> : {(supplierData.supplier.mobileNumber) ? supplierData.supplier.mobileNumber : "--"} <br />
                    <b>Email</b> : {(supplierData.supplier.email) ? supplierData.supplier.email : "--"}
                  </Typography>
                </Grid>
              </>}
          </Grid>
        </Card>

        <Grid container>
          <Grid item container xs={6} justifyContent="flex-start" >
            <IconButton
              disabled={isSaved}
              onClick={() => {
                if ((custData && custData.customerId) || (supplierData && supplierData.supplierId)) {
                  setShowSPInv(true)
                }
              }}
              style={{ backgroundColor: 'transparent', padding: "12px 12px 12px 0px" }} >
              <Typography style={{ fontWeight: "bold", color: "black", pointerEvents: "none" }}>{(partyType === "CUSTOMER") ? "Sale Invoice" : "Purchase Invoice"} :</Typography>
              &nbsp;
              <Typography>{selectedPInv && selectedPInv.spInvNum}</Typography>
              &nbsp;
              <SearchIcon />
            </IconButton>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end" >
          </Grid>
        </Grid>
        {(selectedPInv && selectedPInv.details && selectedPInv.details.id) ?
          <Grid container>
            <Grid item container xs={12} justifyContent="flex-start">
              <TableContainer component={Paper} >
                <Table style={{ paddingTop: 10 }} size="small">
                  <TableHead>
                    <TableRow>
                      <StyledHeaderCell>Invoice Type</StyledHeaderCell>
                      <StyledHeaderCell>Invoice Number</StyledHeaderCell>
                      <StyledHeaderCell align="left">Grand Total</StyledHeaderCell>
                      <StyledHeaderCell align="left">Already Paid Amount</StyledHeaderCell>
                      <StyledHeaderCell align="left">Outstanding Amount</StyledHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {(partyType === "CUSTOMER") ? "Sale Invoice" : "Purchase Invoice"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {selectedPInv.details && selectedPInv.details.invoiceNum}
                      </TableCell>
                      <TableCell align="left">
                        {selectedPInv.details && selectedPInv.details.total}
                      </TableCell>
                      <TableCell align="left">
                        {(selectedPInv.details) ? selectedPInv.details.total - selectedPInv.details.outStandingAmount : 0}
                      </TableCell>
                      <TableCell align="left">
                        {selectedPInv.details && selectedPInv.details.outStandingAmount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid> :
          <Grid container>
            <Grid item container xs={12} justifyContent="flex-start">
              <TableContainer component={Paper} >
                <Table style={{ paddingTop: 10 }} size="small">
                  <TableHead>
                    <TableRow>
                      <StyledHeaderCell>Invoice Type</StyledHeaderCell>
                      <StyledHeaderCell>Invoice Number</StyledHeaderCell>
                      <StyledHeaderCell align="left">Grand Total</StyledHeaderCell>
                      <StyledHeaderCell align="left">Paid Amount</StyledHeaderCell>
                      <StyledHeaderCell align="left">Outstanding Amount</StyledHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Click search icon and Select invoice
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>}

        <Grid container spacing={1} style={{ marginTop: "15px" }}>
          <Grid item xs={12}>
            <Box fontWeight={"fontWeightBold"}>
              Notes
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              InputProps={{
                style: { color: "black" },
              }}
              onChange={(e) => { setNotes(e.target.value) }}
              disabled={((custData && custData.customerId) || (supplierData && supplierData.supplierId) || isSaved) ? false : true}
              name="notes"
              value={notes}
              placeholder="eg: XYZ Payment"
              minRows={3}
              maxRows={5}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={3} style={{ display: 'flex', alignItems: 'baseline', marginTop: "30px" }}>
          <Grid item>
            <Button
              onClick={makePayment}
              color="primary"
              variant="contained"
              disabled={isSaved}
            >
              Payment
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              style={{ marginRight: 10, width: 100 }}
              disabled={isSaved}
              onClick={restClick}
            >
              Rest
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={cancelClick}
              color="secondary"
              variant="contained"
              style={{ marginRight: 10, width: 100 }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        {/* Select Customer or Supplier */}
        <Dialog fullWidth={true} maxWidth={"md"} open={(partyType === "CUSTOMER") ? customerModal : supplierModal}>
          <Grid container>
            <Grid item container xs={12} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    if (partyType === "CUSTOMER") {
                      setCustomerModal(false);
                    } else {
                      setSupplierModal(false);
                    }

                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <DialogContent>
            {(partyType === "CUSTOMER") ?
              <Customers
                modal
                onRowSelect={async (customer) => {
                  await setCustData({
                    customerId: null,
                    customer: null
                  })
                  setSelectedSPInv({ spInvNum: "", details: null });
                  setSupplierData({
                    supplierId: null,
                    supplier: null
                  })
                  setCustomerModal(false);
                  handleCustomerSelection(customer);

                }}
              /> :
              <SupplierSelection
                modal
                onRowSelect={async (supplier) => {
                  await setSupplierData({
                    supplierId: null,
                    supplier: null
                  })
                  setCustData({
                    customerId: null,
                    customer: null
                  })
                  setSelectedSPInv({ spInvNum: "", details: null });

                  handleSupplierSelection(supplier);
                  setSupplierModal(false);
                }}
              />}
          </DialogContent>
        </Dialog>

        {/* Select Purchase Invoice or Sale Invoice*/}
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={showPInv}
          onClose={() => {
            setShowSPInv(false);
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item container xs={9} alignItems="center">
                <Grid item>
                  {(partyType === "CUSTOMER") ? "Sale Invoice" : "Purchase Invoice"}
                </Grid>
              </Grid>
              <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setShowSPInv(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent >
            <SelectPurchaseOrSaleinvoice
              setIsError={setIsError}
              setSelectedSPInv={setSelectedSPInv}
              partyType={partyType}
              setShowSPInv={setShowSPInv}
              parentData={(partyType === "CUSTOMER") ? custData.customer : supplierData.supplier}
            />
          </DialogContent>
        </Dialog>

        {/* payments */}
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={payments.open}
          onClose={() => {
            setPayments({ ...payments, open: false });
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item container xs={9} alignItems="center">
                <Grid item>
                  Payments
                </Grid>
              </Grid>
              <Grid item container xs={3} alignItems="center" justify="flex-end">
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setPayments({ ...payments, open: false });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent >
            {(partyType === "CUSTOMER") ?
              <SalePayment
                cancel={() => {
                  setPayments({ ...payments, open: false });
                }}
                setIsError={setIsError}
                calculate={calculate}
                customerData={custData.customer}
                isDirectPayment={true}
                submitDirectPayment={submitDirectSalePayment}
              /> : <PurchasePayment
                cancel={() => {
                  setPayments({ ...payments, open: false });
                }}
                calculate={calculate}
                supplierData={supplierData.supplier}
                isDirectPayment={true}
                // paymentDetails={paymentDetails}
                supplierId={supplierData.supplierId}
                submitDirectPayment={submitDirectPurchasePayment}
              />}
          </DialogContent>
        </Dialog>
        <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={() => setIsError(false)}
        >
          <MuiAlert
            onClose={() => setIsError(false)}
            elevation={6}
            variant="filled"
            severity="error"
          >
            Some error occured. Please retry after sometime.
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={isSuccess}
          autoHideDuration={5000}
          onClose={() => setIsSuccess(false)}
        >
          <MuiAlert
            onClose={() => setIsSuccess(false)}
            elevation={6}
            variant="filled"
            severity="success"
          >
            Payment Entry Successfully Created
          </MuiAlert>
        </Snackbar>
      </form>
    </FormContext>
  );
};

export default DirectPaymentComp;
