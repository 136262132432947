import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {useSelector } from "react-redux";
import {custStates } from "../common/lookupSlice";
import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router-dom";

let errorMsg;

function CustomersForm(props) {
  const { id } = useParams();
  const history = useHistory();
  const [ consigneeData, setCustomerData ] = useState({});
  let { tradeName,address1, address2, address3, pincode, locality, GSTIN} = consigneeData;
  const [isError, setIsError] = useState(false);
  const { handleSubmit, errors, register, getValues} = useForm({});
  const [ create, setCreate ] = React.useState({});
  const stateObj = useSelector(custStates);
  const states = stateObj ? stateObj.map(state=>state.name):[];
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [isGSTNErr, setGSTNErr] = useState(false);
  const [isPincodeErr, setPincodeErr] = useState(false);
  const [pincodeErrMsg, setPincodeErrMsg] = useState(false);

  useEffect(()=> {
    if(props.consigneeData){
      let consigneeDt = props.consigneeData;
      const findState = stateObj.find((data)=>{
        return data.code === props.consigneeData.stateCode
      })
      if(findState){
        setValue(findState.name);
        consigneeDt.state = findState.name;
      }
      setCustomerData(consigneeDt);
    }
  }, [props.consigneeData]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (consigneeDt) => {
    let isContinue = true;
    const selectedState = stateObj.find(x=>x.name===consigneeDt.st);
    let stateCode;
    if(selectedState){
      stateCode = selectedState.code
    }
    if(consigneeDt.GSTIN&&selectedState){
     let stCode = consigneeDt.GSTIN.slice(0, 2);
     if(parseInt(stCode) === selectedState.stateId){
      setGSTNErr(false)
      setGSTNErr(false)
     }else{
      setGSTNErr(true)
      isContinue = false;
     }
    }else{
      setGSTNErr(true)
    }
    if(consigneeDt.pincode && selectedState ){
      let testPinCode = parseInt(consigneeDt.pincode.toString().slice(0, 3));
      if(testPinCode >= parseInt(selectedState.pinCodeSR) && testPinCode <= parseInt(selectedState.pinCodeER)){
        setPincodeErr(false);
      }else{
        setPincodeErr(true);
        setPincodeErrMsg("Pincode not matching to state.");
        isContinue=false;
      }
    }else{
      isContinue = false;
      setPincodeErrMsg("Enter Valid Pincode.");
        setPincodeErr(true);
    }
    if(isContinue){
      setGSTNErr(false)
      let editCust = {
        tradeName: consigneeDt.tradeName,
        email: consigneeDt.email,
        mobileNumber: consigneeDt.mobileNumber,
        address1: consigneeDt.address1,
        address2: consigneeDt.address2,
        address3: consigneeDt.address3,
        pincode: consigneeDt.pincode,
        locality: consigneeDt.locality,
        state: stateCode,
        GSTIN: consigneeDt.GSTIN,
        stateCode
      }
      props.onEditDataSubmit(editCust);
      if(getValues){}
    }else{
      // setGSTNErr(true)
    }
    
  };
  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography className="customer-edit-form-label" variant="caption">Full Name*</Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.tradeName)}
              inputRef={register({
                required: "Minimum 5 letters required. No digits.",
                // pattern: {
                //   value: /^[a-zA-Z ]{5,}$/,
                //   message: "Minimum 5 letters required. No digits.",
                // },
              })}
              InputProps={{
                autoFocus: true,
                name: "tradeName",
              }} value={tradeName}
              onChange = {e => setCustomerData({ ...consigneeData, tradeName: e.target.value})}
              size="small"
              name="tradeName"
            />
            <FormHelperText error>
              {errors.tradeName && errors.tradeName.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography className="customer-edit-form-label" variant="caption">Building Street{props.required?"*":""}</Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              type="adress"
              error={Boolean(errors.address1)} 
              inputRef={props.required?register({
                required: "Minimum 5 letters required. No digits.",
                // pattern: {
                //   value: /^[a-zA-Z ]{5,}$/,
                //   message: "Minimum 5 letters required. No digits.",
                // },
              }):register({})}
              value={address1}
              onChange = {e => setCustomerData({ ...consigneeData, address1: e.target.value})}
              size="small"
              name="address1"
            />
            <FormHelperText error>
              {errors.address1 && errors.address1.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography className="customer-edit-form-label" variant="caption">Land mark</Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              type="adress"
              error={Boolean(errors.address2)}
              inputRef={register({
                // minLength: {
                //   value: 5,
                //   message: "Minimum 10 characters required",
                // },
              })} 
              value={address2}
              onChange = {e => setCustomerData({ ...consigneeData, address2: e.target.value})}
              size="small"
              name="address2"
            />
            <FormHelperText error>
              {errors.address2 && errors.address2.message}
            </FormHelperText>
          </Grid>
          </Grid>

        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">City</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Locality{props.required?"*":""}</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">Pincode *</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="adress"
              error={Boolean(errors.address3)}
              inputRef={register({
                // minLength: {
                //   value: 5,
                //   message: "Minimum 10 characters required",
                // },
              })} 
              value={address3}
              onChange = {e => setCustomerData({ ...consigneeData, address3: e.target.value})}
              size="small"
              name="address3"
            />
            <FormHelperText error>
              {errors.address3 && errors.address3.message}
            </FormHelperText>
          </Grid>
          <Grid item md={4} xs={4}>
               <TextField variant="outlined" value={locality}
                 size="small" name="locality"
                 onChange = {e => setCustomerData({ ...consigneeData, locality: e.target.value})}
                 error={Boolean(errors.locality)}
                 fullWidth 
                 inputRef={props.required?register({
                  required: "Minimum 5 letters required. No digits.",
                    // pattern: {
                    //   value: /^[a-zA-Z ]{5,}$/,
                    //   message: "Minimum 5 letters required. No digits.",
                    // },
                  }):register({})}
                 />
           <FormHelperText error>
             {errors.locality && errors.locality.message}
           </FormHelperText>
         </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.pincode) || isPincodeErr}
              type="number"
              inputRef={register({                
                required: "6 digit pincode is required",
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: "6 digit pincode is required",
                },
              })} value={pincode}
              onChange = {e => setCustomerData({ ...consigneeData, pincode: e.target.value})}
              size="small"
              name="pincode"
            />
            <FormHelperText error={Boolean(errors.pincode) || isPincodeErr}>
             {(isPincodeErr)?pincodeErrMsg:errors.pincode && errors.pincode.message}
           </FormHelperText>
          </Grid>
         
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={4} xs={4}>
            <Typography className="customer-edit-form-label" variant="caption">State *</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
          <Typography className="customer-edit-form-label" variant="caption">GSTIN{props.required?"*":""}</Typography>
        </Grid>
        <Grid item md={4} xs={4}></Grid>
          <Grid item md={4} xs={4}>
            <Autocomplete size="small"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={states}
              style={{ width: 215 }}
              renderInput={(params) => <TextField {...params} 
              variant="outlined"
              name="st"
              error={Boolean(errors.st)} 
              inputRef={register({
                required: "Required"
              })}/>}
            />
          </Grid>
          <Grid item md={4} xs={4}>
              <TextField variant="outlined" fullWidth error={Boolean(errors.GSTIN) || isGSTNErr}
                onChange = {e => setCustomerData({ ...consigneeData, GSTIN: e.target.value})}
                  // type="number" 
                  inputRef={(props.required || (consigneeData.GSTIN && consigneeData.GSTIN.length>0))?register({
                    required: "Valid GSTIN is required",
                    pattern: {
                      value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                      message: "Valid GSTIN is required",
                    },
                  }):register({})} value={GSTIN} size="small" name="GSTIN" />
            <FormHelperText error={Boolean(errors.GSTIN) || isGSTNErr}>
             {(isGSTNErr)?"GSTIN not matching to state":errors.GSTIN && errors.GSTIN.message}
           </FormHelperText>
          </Grid>
       </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={6}
          justify={"flex-start"}
        >
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button size="small" type="submit"
              variant="contained" color="primary" >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => props.onEditCancel ? props.onEditCancel():history.push("/recordSales")}
              color="secondary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
    <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={() => setIsError(false)}
        >
          <MuiAlert
            onClose={() => setIsError(false)}
            elevation={6}
            variant="filled"
            severity="error"
          >
            Some error occured. Please retry after sometime.
        </MuiAlert>
        </Snackbar>
    <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id?"Customer updated successfully"
            : "Customer created successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default CustomersForm;
