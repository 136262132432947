import React from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon
} from "@material-ui/core";
import OutboundJobs from "../../images/outboundJobs.svg";
import InboundJobs from "../../images/inboundJobs.svg";

const AccountHome = (props) => {
  const history = useHistory();
  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
                Data Management
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Grid item container xs={12} md={4} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold", display: "inline-block" }}>Quick Links</ListSubheader>} ></List>
          <List style={{ display: "inline-flex", marginTop: "-20px" }}>
            <ListItem style={{ width: "200px", fontWeight: "bold" }}>
              <img src={InboundJobs} alt="Inbound" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push(`/inbounddatasync`)}
              >
                Inbound Jobs
              </Link>
            </ListItem>
            <ListItem style={{ width: "200px" }}>
              <img src={OutboundJobs} alt="Outbound" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/outbounddatasync")}
              >
                Outbound Jobs
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>
      <div>
        <Grid item container xs={12} md={3} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold" }}>Data Sync</ListSubheader>} >
            <ListItem>
              <ListItemIcon>
                <img src={InboundJobs} alt="InboundJobs" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/inbounddatasync")}
              >
                Inbound Jobs
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={OutboundJobs} alt="OutboundJobs" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/outbounddatasync")}
              >
                Outbound Jobs
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>
    </div>
  );
};

export default AccountHome;
