import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocalities, custStates, ERPBankList, ERPBankChartOfAccountList } from "../common/lookupSlice";
import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Grid,
  CircularProgress,
  Breadcrumbs,
  Link,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

let errorMsg;

function BankAccountFrom(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { id } = useParams();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [comapnyBankData, setSupplierData] = useState({});
  let { bankName, acNumber, ifsCode } = comapnyBankData;
  const [isError, setIsError] = useState(false);
  const { handleSubmit, errors, register } = useForm({});
  const [create, setCreate] = React.useState({});
  const stateObj = useSelector(custStates);
  const states = stateObj ? stateObj.map(state => state.name) : [];
  const bankList = useSelector(ERPBankList);
  const bankChartOfAccountList = useSelector(ERPBankChartOfAccountList);
  const [value, setValue] = React.useState('');
  const [accountValue, setAccountValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [inputAccountalue, setInputAccountValue] = React.useState('');
  const [saved, setSaved] = React.useState(false);
  // var queryStringData = window.location.search;
  // var chars = queryStringData.split('=');
  // let isPm = (chars[1]) ? chars[1].toUpperCase() === "YES" : false;

  const fetchData = async (id) => {
    if (id) {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/bankdetials/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSupplierData(result.data);
        const stateIndex = states.indexOf(result.data.companyBankdetailsState.name)
        setValue(states[stateIndex])
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
    }
  }

  useEffect(() => {
    fetchData(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (comapnyBankData) => {
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    let isContinue = true;
    const selectedState = stateObj.filter(x => x.name === value);
    comapnyBankData.id = id;
    //comapnyBankData.state = selectedState.length>0 ? selectedState[0].code:'';
    comapnyBankData.state = selectedState && selectedState[0] ? selectedState[0].code : '';

    if (isContinue) {
      try {
        let newSupplier;
        if (id) {
          await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/bankaccount/${id}`,
            comapnyBankData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          newSupplier = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/chartofaccount/bankaccount`,
            comapnyBankData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        if (props.isPopup) {
          props.success(newSupplier.data.comapnyBankData);
        } else {
          dispatch(fetchLocalities());
          setIsProcessing(false);
          if (props.job) {
            props.cancel();
            props.onCreate(newSupplier && newSupplier.data && newSupplier.data.comapnyBankData)
          }
          else {
            setCreate({
              success: true
            });
            setSaved(true);
          }
          window.location.href = `/bankaccountstatementimportlist`;
        }

      } catch (err) {
        console.log("err.response.data.message", err.response.data.message);
        errorMsg = (err.response.data.message && err.response.data.message.length > 0 && err.response.data.message[1] === "fullName must be unique") ? "Supplier Name has to be unique" : err.response.data.message;
        setIsError(true);
        setIsProcessing(false);
        setSaved(false);
      }
    } else {
      setIsProcessing(false);
    }
  };

  let cancelClick = () => {
    window.location.href = `/bankaccountstatementimportlist`;
  }

  return (
    <div>
      {!props.isPopup && <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/accountshome")}>
              Accounting
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/bankaccountstatementimportlist")}>
                Bank Statement Upload
              </Link>
              <Typography component="span" color="textPrimary">
                {id ? "Edit Bank Account" : "New Bank Account"}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="supplier-form">
          <Grid container spacing={2}>
            <Grid item container md={12} xs={12} spacing={1}>
            </Grid>
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Account Name*</Typography>
                </Grid>
                <Grid item md={10} xs={10}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.bankName)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={bankName}
                    onChange={e => setSupplierData({ ...comapnyBankData, bankName: e.target.value })}
                    size="small"
                    name="bankName"
                  />
                  <FormHelperText error>
                    {errors.bankName && errors.bankName.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Account Number *</Typography>
                </Grid>
                <Grid item md={10} xs={10}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.acNumber)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={acNumber}
                    onChange={e => setSupplierData({ ...comapnyBankData, acNumber: e.target.value })}
                    size="small"
                    name="acNumber"
                  />
                  <FormHelperText error>
                    {errors.acNumber && errors.acNumber.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Branch Code *</Typography>
                </Grid>
                <Grid item md={10} xs={10}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="adress"
                    error={Boolean(errors.ifsCode)}
                    inputRef={register({
                      // minLength: {
                      //   value: 5,
                      //   message: "Minimum 10 characters required",
                      // },
                    })}
                    value={ifsCode}
                    onChange={e => setSupplierData({ ...comapnyBankData, ifsCode: e.target.value })}
                    size="small"
                    name="ifsCode"
                  />
                  <FormHelperText error>
                    {errors.ifsCode && errors.ifsCode.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item container md={12} xs={12} spacing={1}>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Bank Name *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    options={bankList}
                    getOptionLabel={(option) => (typeof option.name === 'string' ? option.name : '')}
                    filterOptions={(options, addrType) =>
                      options.filter((option) =>
                        typeof option.name === 'string' &&
                        option.name.toLowerCase().includes(addrType.inputValue.toLowerCase())
                      )
                    }
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      name="bankId"
                      fullWidth
                      size="small"
                      error={Boolean(errors.bankId)}
                      inputRef={register({
                        required: "Required"
                      })} />}
                  />
                </Grid>
                <Grid item md={2} xs={2}>
                  <Typography className="supplier-form-label" variant="caption">Company Account *</Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Autocomplete
                    value={accountValue}
                    onChange={(event, newValue) => {
                      setAccountValue(newValue);
                    }}
                    inputValue={inputAccountalue}
                    onInputChange={(event, newInputValue) => {
                      setInputAccountValue(newInputValue);
                    }}
                    options={bankChartOfAccountList}
                    getOptionLabel={(option) => (typeof option.name === 'string' ? option.name : '')}
                    filterOptions={(options, addrType) =>
                      options.filter((option) =>
                        typeof option.name === 'string' &&
                        option.name.toLowerCase().includes(addrType.inputValue.toLowerCase())
                      )
                    }
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      name="chartOfAccount"
                      fullWidth
                      size="small"
                      error={Boolean(errors.chartOfAccount)}
                      inputRef={register({
                        required: "Required"
                      })} />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid
            item
            container
            xs={6}
            justifyContent={isProcessing ? "flex-end" : "flex-start"}
          >
            {isProcessing ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}

            {isError ? (
              <Grid item>
                <Typography error>
                  <FormHelperText error>{errorMsg}</FormHelperText>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button size="small" type="submit"
                variant="contained" color="primary" disabled={saved}>
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => props.job ? props.cancel() : cancelClick()}
                color="secondary"
                size="small"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" name="id" ref={register()} />
      </form>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id ? "Bank Account updated successfully"
            : "Bank Account created successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default BankAccountFrom;
