import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from '../common/util';
import { withStyles } from '@material-ui/styles';
// import { transportModes } from "../common/constants"

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '1.6',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};
const tableStyle = {
  border: '1px solid #ccc',
  borderCollapse: 'collapse',
}

class CompToPrintInv extends React.Component {
  render() {
    const { classes, loggedInUser, orgTermsAndConditions } = this.props;
    const invDetails = this.props.supplierPurInv != null && this.props.supplierPurInv;
    const lineItems = this.props.supplierPurInv != null && this.props.supplierPurInv.lineItems;
    const labourCharges = this.props.supplierPurInv != null && this.props.supplierPurInv.labourCharges;
    const transportCharges = this.props.supplierPurInv != null && this.props.supplierPurInv.transportCharges;
    const supplier = this.props.supplierPurInv != null && this.props.supplierPurInv.supplier && this.props.supplierPurInv.supplier;
    const supContacts = supplier && supplier.contacts && supplier.contacts;
    const hsnSummary = invDetails && invDetails.HSNSummary;
    // const paymentSummary = (invDetails && invDetails.paymentSummary) ? invDetails.paymentSummary : [];
    const shipToAddrs = (invDetails && invDetails.shipToLocation) ? invDetails.shipToLocation : invDetails && invDetails.location;
    // const invOrgDetails = this.props.supplierPurInv != null && this.props.supplierPurInv.org && this.props.supplierPurInv.org;
    let hsnTotal = 0;
    let hsnCGSTTotal = 0;
    let hsnSGSTTotal = 0;
    let hsnIGSTTotal = 0;
    hsnSummary && hsnSummary.map((row) => (
      hsnTotal += row.Total));
    hsnSummary && hsnSummary.map((row) => (
      hsnCGSTTotal += row.CGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnSGSTTotal += row.SGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnIGSTTotal += row.IGSTtotal));
    let quantity = 0;
    invDetails.lineItems && invDetails.lineItems.map((row) => (
      quantity += row.qty));
    // const invType = this.props.supplierPurInv != null && this.props.supplierPurInv.type;
    // const isDeposit = invType && invType.code === "DEPOSIT";
    let isIntraState = false;
    if (invDetails && invDetails.location) {
      isIntraState = (supplier && supplier.state && invDetails.location.state && (invDetails.location.state === supplier.state)) ? true : false;
    }
    const companyPrintLogo = loggedInUser && loggedInUser.org && loggedInUser.org.printLogo;
    let orgDeclaration = "";
    if (orgTermsAndConditions && orgTermsAndConditions.length > 0) {
      let findOrgCond = orgTermsAndConditions.find((orgCond) => {
        return (orgCond && orgCond.termsConditionsType && (orgCond.termsConditionsType === "Purchase Invoice")) ? orgCond : null;
      })
      orgDeclaration = findOrgCond && findOrgCond.declaration
    }
    return (
      <div >
        <div style={divStyle} align="center">
          {/* Invoice  */}
          {/* <Helmet><title> Print Invoice</title></Helmet> */}
          <Helmet><title></title></Helmet>
          <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', textAlign: 'center', fontWeight: 'bold', lineHeight: '2', }}>Purchase Invoice</span>
          <table style={tableStyle} width="95%" border="1px" color="#ccc">
            <tr>
              <td rowSpan="3" width="60%">
                <table width="100%" border="0px" color="white" >
                  <tr>
                    <td width="60%">
                      <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name.toUpperCase()}<br /></b></span>
                      <span style={pStyle2}>
                        {invDetails && invDetails.location && (invDetails.location.address1.toUpperCase())}
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address2.toUpperCase())}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address3.toUpperCase() + '-' + invDetails.location.pincode)}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && invDetails.location.state ? invDetails.location.state : invDetails.location && invDetails.location.state}</div>
                        <span style={pStyle2}>{invDetails && invDetails.location && invDetails.location.mobileNumber ? invDetails.location.mobileNumber : ""}<br /></span>
                      </span>
                      <span style={pStyle2}>GSTIN/UIN: {invDetails && invDetails.location && invDetails.location.GSTIN ? invDetails.location.GSTIN : "Not Available"}<br /></span>
                      <span style={pStyle2}>Email: {invDetails && invDetails.location && invDetails.location.email ? invDetails.location.email : "Not Available"}<br /></span>

                    </td>
                    <td rowSpan="4" width="40%" align="right">
                      <img alt="comLogo" src={companyPrintLogo}
                        style={{ height: "100px", width: "200px" }} />
                    </td>
                  </tr>
                </table>
              </td>
              <td width="20%">

                <div><span style={pStyle2}>Invoice No.</span><br />
                  <span style={pStyle2}><b>{this.props.supplierPurInv && this.props.supplierPurInv.invoiceNum}</b></span></div>


              </td>
              <td width="20%"><span style={pStyle2}>Dated. <br /><b>{this.props.supplierPurInv && formatDate(this.props.supplierPurInv.invoiceDate)}</b></span></td>
            </tr>
            <tr>
              <td><span style={pStyle2}>Supplier Invoice No.<br />&nbsp;</span><span style={pStyle2}><b>{invDetails && invDetails.supplierInvoiceNo}</b></span></td>
              <td><span style={pStyle2}>Other Reference(s)<br />&nbsp;</span><span style={pStyle2}><b></b></span></td>

            </tr>
            <tr>
              <td><span style={pStyle2}>Supplier's Order No.<br /><b>{this.props.supplierPurInv && this.props.supplierPurInv.supplierPurchaseOrder && this.props.supplierPurInv.supplierPurchaseOrder.spoNum}</b></span></td>
              <td><span style={pStyle2}>Order Required Date<br /><b>{this.props.supplierPurInv && this.props.supplierPurInv.supplierPurchaseOrder && formatDate(this.props.supplierPurInv.supplierPurchaseOrder.orderRequiredDate)}</b></span></td>
            </tr>
            <tr>
              {/* <td rowSpan="1"> */}
              <td>
                <span style={pStyle2}>
                  Consignee(Ship To)<br /> <b>{invDetails && invDetails.org && invDetails.org.name.toUpperCase()}</b><br />
                  {shipToAddrs && (shipToAddrs.address1 + ' \n' + shipToAddrs.address2 + ' \n' + shipToAddrs.address3 + ' \n' + shipToAddrs.state + '-' + shipToAddrs.pincode)}<br />
                  GSTIN/UIN: {shipToAddrs && shipToAddrs.GSTIN}<br />
                  State: {shipToAddrs && shipToAddrs.state} {(shipToAddrs && shipToAddrs.GSTIN) ? ' Code: ' + shipToAddrs.GSTIN.slice(0, 2) : ' '}<br />
                  Pan Number: {shipToAddrs && shipToAddrs.panNumber}<br />
                  Contact: {shipToAddrs && shipToAddrs.GSTIN}
                </span>
              </td>
              <td colspan="2">
                <span style={pStyle2}>
                  Supplier(Bill From)<br /> <b>{supplier && supplier.fullName}</b><br />
                  {supplier && (supplier.address1 + ' \n' + supplier.address2 + ' \n' + supplier.address3 + ' \n' + supplier.state + '-' + supplier.pincode)}<br />
                  GSTIN/UIN: {supplier && supplier.GSTIN}<br />
                  State: {supplier && supplier.state} {(supplier && supplier.GSTIN) ? ' Code: ' + supplier.GSTIN.slice(0, 2) : ' '}<br />
                  Contact Person: {supContacts ? supContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                  Contact: {supContacts ? supContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                </span>
              </td>
            </tr>
          </table>
          <div style={{ width: '100%', padding: '5px 2px' }}>
            <table style={tableStyle} width="95%">
              <thead>
                <tr>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Sl No.</span></td>
                  <td width="25%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Description of Goods/Services</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>GST Rate</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Quantity</span></td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                  <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>per</span></td>
                  <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Disc.%</span></td>
                  <td width="15%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                </tr>
              </thead>
              <tbody>
                {((lineItems && lineItems.length === 0) && (labourCharges === 0 && transportCharges === 0)) ? (
                  <tr>
                    <td colspan="9" align="center">
                      <span style={pStyle2}>No records found</span>
                    </td>
                  </tr>
                ) : (
                  lineItems && lineItems.map((row, index) => (
                    <tr
                      key={index}
                      hover={true}
                    >
                      <td align="center" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {index + 1}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.productName}</b>
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.HSNCode}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.IGST} %
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.qty} {row.uom}</b>
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.unitPrice ? (Math.round(row.unitPrice * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.uom}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          {row.discountPct ? (Math.round(row.discountPct * 100) / 100).toFixed(2) : ''}
                        </span>
                      </td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                        <span style={pStyle2}>
                          <b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</b>
                        </span>
                      </td>
                    </tr>
                  ))
                )}
                {lineItems && lineItems.length > 0 &&
                  <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>{invDetails.subtotal ? (Math.round(invDetails.subtotal * 100) / 100).toFixed(2) : '0.00'}</span>
                    </td>
                  </tr>
                }
                {labourCharges && labourCharges > 0 ? <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Labour Charges</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.labourCharges ? (Math.round(invDetails.labourCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr> : ''}
                {transportCharges && transportCharges > 0 ? <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Transportation Charges (Sales)/packing</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.transportCharges ? (Math.round(invDetails.transportCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr> : ''}
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>CGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.CGST ? (Math.round(invDetails.CGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {isIntraState && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>SGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.SGST ? (Math.round(invDetails.SGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {(!isIntraState) && <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>IGST</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.IGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>}
                {invDetails.roundOff !== 0 && <tr>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}>&nbsp;</td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><i>{invDetails.roundOff < 0 ? 'Less:' : ''}</i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={pStyle2}><b><i>Round Off</i></b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                    <span style={pStyle2}><b>{invDetails.roundOff ? (invDetails.roundOff > 0 ? (Math.round(invDetails.roundOff * 100) / 100).toFixed(2) : '(-)' + (Math.round(invDetails.roundOff * (-100)) / 100).toFixed(2)) : '0.00'}</b></span>
                  </td>
                </tr>}
                <tr>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{quantity}</b></span></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                  <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.total ? (Math.round(invDetails.total * 100) / 100).toFixed(2) : '0.00'}</b></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody>
                <tr>
                  <td width="50%" style={{ borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>Amount Chargeable(in words) :</span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}><i>E. &amp; O.E</i>&nbsp;</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                    <span style={pStyle}><b>{invDetails.amountInWords}</b><br /><br /></span>
                  </td>
                </tr>
                {invDetails.remarkNote ? <tr>
                  <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                    <span style={pStyle}><b>Remarks :</b>&nbsp;{invDetails.remarkNote}<br /><br /></span>
                  </td>
                </tr> : null}
                <tr>
                  <td colspan="2">
                    &nbsp;<br />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <span style={pStyle2}>Company’s PAN : <b>{invDetails && invDetails.location && invDetails.location.panNumber ? invDetails.location.panNumber : "Not Available"}</b></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" width="100%" align="left">
                    <span style={pStyle2}><u>Declaration</u><br />{orgDeclaration}</span>
                  </td>
                  {/* <td>
                      <span style={pStyle2}>Company's Bank Details<br />
                        Bank Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{orgBankDetails && orgBankDetails.bankName}</b><br />
                        A/c No. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{orgBankDetails && orgBankDetails.acNumber}</b><br />
                        Branch &amp; IFS Code &nbsp;: <b>{orgBankDetails && orgBankDetails.branchName} &amp; {orgBankDetails && orgBankDetails.ifsCode}</b>
                      </span>
                    </td> */}
                </tr>
                <tr>
                  <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>supplier Seal and Signature<br /><br /><br /></span>
                  </td>
                  <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* HSN Summary Section */}
          {/* {(hsnSummary && hsnSummary.length > 0) &&
              <div className={classes.print}>
                <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '15px', textAlign: 'center', fontWeight: 'bold', lineHeight: '1.6', }}>
                  Purchase Invoice<br />(Tax Analysis)
                </span>
                <div>
                  <table style={tableStyle} width="90%" border="1px" color="#ccc">
                    <tr>
                      <td align="left" width="50%" style={{ borderRight: '1px solid #fff', borderBottom: '1px solid #fff' }}>
                        <span style={pStyle}>Invoice No. <b>{this.props.supplierPurInv && this.props.supplierPurInv.invoiceNum}</b></span>
                      </td>
                      <td align="right" width="50%" style={{ borderBottom: '1px solid #fff' }}>
                        <span style={pStyle}>Dated <b>{this.props.supplierPurInv && formatDate(this.props.supplierPurInv.invoiceDate)}</b>&nbsp;</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" align="center">
                        <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name}</b></span><br />
                        <span style={pStyle}>{invDetails && invDetails.org && (invDetails.org.address1)}</span><br />
                        <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address2)}</div></span>
                        <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address3 + '-' + invDetails.org.pincode)}</div></span>
                        <span style={pStyle}>{invDetails && invDetails.org && invDetails.org.phoneNo ? invDetails.org.phoneNo : ""}<br /></span>
                        <span style={pStyle}>State :{invDetails && invDetails.org && (invDetails.org.state)} Code: {invDetails && invDetails.org && invDetails.org.GSTIN.slice(0, 2)}</span><br />
                        <span style={pStyle}>GSTIN/UIN: {invDetails && invDetails.org && invDetails.org.GSTIN ? invDetails.org.GSTIN : "Not Available"}</span><br />
                        <span style={pStyle}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}</span><br />
                        <span style={pStyle}>Party : <span style={{ fontWeight: 'bold' }}>{supplier && supplier.fullName}</span><br />
                          <span style={pStyle}>
                            <div style={{ whiteSpace: 'pre-line' }}>
                              {(supplier && supplier.address1) ? supplier.address1 + ' \n' : ''}
                              {(supplier && supplier.address2) ? supplier.address2 + ' \n' : ''}
                              {(supplier && supplier.address3 + '-' + supplier.pincode) ? supplier.address3 + '-' + supplier.pincode + ' \n' : ''}
                            </div>
                          </span>
                          <span style={pStyle}>GSTIN/UIN: {(supplier && supplier.GSTIN) ? supplier.GSTIN : 'Not Available'}</span><br />
                          <span style={pStyle}></span>State: {(supplier && supplier.state) ? supplier.state : 'Not Available'} {(supplier && supplier.GSTIN) ? ' Code: ' + supplier.GSTIN.slice(0, 2) : ' '}</span><br />
                      </td>
                    </tr>
                  </table>
                  {(!isIntraState) ?
                    (
                      <div>
                        <table style={tableStyle} width="90%">
                          <thead>
                            <tr>
                              <td align="center" width="50%" rowSpan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                              <td align="center" width="15%" rowSpan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Taxable <br />Value</span></td>
                              <td align="center" width="20%" colSpan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Integrated Tax</span></td>
                              <td align="center" width="15%" rowSpan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Total <br /> Tax Amount</span></td>
                            </tr>
                            <tr>
                              <td align="center" width="7%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                              <td align="center" width="13%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                            </tr>
                          </thead>
                          <tbody>
                            {hsnSummary && hsnSummary.length === 0 ? (
                              <tr>
                                <td colSpan={7} align="center">
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              hsnSummary && hsnSummary.map((row, index) => (
                                <tr
                                  key={index}
                                  hover={true}
                                >
                                  <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.HSNCode}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.Total ? (Math.round(row.Total * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.IGST ? (Math.round(row.IGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.IGSTtotal ? (Math.round(row.IGSTtotal * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{(row.IGSTtotal ? (Math.round(row.IGSTtotal * 100) / 100).toFixed(2) : '0.00')}</span></td>
                                </tr>
                              ))
                            )}
                            <tr>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnTotal ? (Math.round(hsnTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnIGSTTotal ? (Math.round(hsnIGSTTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{invDetails && invDetails.totalTax ? (Math.round(invDetails.totalTax * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className={classes.printTogether}>
                          <table style={tableStyle} width="90%">
                            <tbody>
                              <tr>
                                <td colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                  <span style={pStyle2}>Tax Amount (in words) :</span> <span style={pStyle}><b>{invDetails && invDetails.taxAmountInWords}</b></span><br /><br />
                                </td>
                              </tr>
                              <tr>
                                <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                  <span style={pStyle2}><br /><br /><br /></span>
                                </td>
                                <td width="50%" align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <table style={tableStyle} width="90%">
                          <thead>
                            <tr>
                              <td align="center" width="30%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                              <td align="center" width="15%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Taxable <br />Value</span></td>
                              <td align="center" width="20%" colspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Central Tax</span></td>
                              <td align="center" width="20%" colspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>State Tax</span></td>
                              <td align="center" width="15%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Total <br /> Tax Amount</span></td>
                            </tr>
                            <tr>
                              <td align="center" width="7%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                              <td align="center" width="13%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                              <td align="center" width="7%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                              <td align="center" width="13%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                            </tr>
                          </thead>
                          <tbody>
                            {hsnSummary && hsnSummary.length === 0 ? (
                              <tr>
                                <td colSpan={5} align="center">
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              hsnSummary && hsnSummary.map((row, index) => (
                                <tr
                                  key={index}
                                  hover={true}
                                >
                                  <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.HSNCode}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.Total ? (Math.round(row.Total * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.CGST ? (Math.round(row.CGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.CGSTtotal ? (Math.round(row.CGSTtotal * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.SGST ? (Math.round(row.SGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.SGSTtotal ? (Math.round(row.SGSTtotal * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{(Math.round((row.CGSTtotal + row.SGSTtotal) * 100) / 100).toFixed(2)}</span></td>
                                </tr>
                              ))
                            )}
                            <tr>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnTotal ? (Math.round(hsnTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnCGSTTotal ? (Math.round(hsnCGSTTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnSGSTTotal ? (Math.round(hsnSGSTTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{invDetails && invDetails.totalTax ? (Math.round(invDetails.totalTax * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className={classes.printTogether}>
                          <table style={tableStyle} width="90%">
                            <tbody>
                              <tr>
                                <td colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                  <span style={pStyle2}>Tax Amount (in words) :</span> <span style={pStyle}><b>{invDetails && invDetails.taxAmountInWords}</b></span><br /><br />
                                </td>
                              </tr>
                              <tr>
                                <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}><br /><br /><br /></span>
                                </td>
                                <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                  <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>} */}
          {/* Payment Summary */}
          {/* {(paymentSummary && paymentSummary.length > 0) &&
              <div className={classes.print}>
                <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '15px', textAlign: 'center', fontWeight: 'bold', lineHeight: '1.6', }}>
                  <br /><br />Payment Summary<br /><br /><br /><br />
                </span>
                <div style={divStyle}>
                  <table width="80%" padding="2px">
                    <tr>
                      <td width="70%"><span style={pStyle}>Invoice No. : <b>{this.props.supplierPurInv && this.props.supplierPurInv.invoiceNum}</b></span></td>
                      <td width="30%"><span style={pStyle}>Dated : <b>{this.props.supplierPurInv && formatDate(this.props.supplierPurInv.invoiceDate)}</b></span></td>
                    </tr>
                  </table>
                </div>
                <div style={divStyle}>
                  <table width="80%" style={{ borderCollapse: 'collapse' }} padding="2px">
                    <thead>
                      <tr>
                        <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="5%">&nbsp;&nbsp;S.No</td>
                        <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="70%">&nbsp;&nbsp;Particulars</td>
                        <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="25%" align="right">Amount</td>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentSummary && paymentSummary.length === 0 ? (
                        <tr>
                          <td colspan="" align="center">
                            No records found
                          </td>
                        </tr>
                      ) : (
                        paymentSummary && paymentSummary.map((row, index) => (
                          <tr
                            key={index}
                            hover={true}
                          >
                            <td align="center" style={{ padding: '5px', borderRight: '1px solid #ccc' }} valign="top" width="5%">
                              <span style={pStyle}>{row.lineNo}</span>
                            </td>
                            <td align="left" style={{ padding: '5px', borderRight: '1px solid #ccc' }} width="70%">
                              <span style={pStyle}>{row.desc}</span>
                            </td>
                            <td align="right" valign="top" width="25%">
                              <span style={pStyle}>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</span>
                            </td>
                          </tr>
                        ))
                      )}
                      <tr>
                        <td colspan="3" align="right" style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>{invDetails && invDetails.netPayable ? (Math.round(invDetails.netPayable * 100) / 100).toFixed(2) : '0.00'}</td>
                      </tr>
                      <tr>
                        <td colspan="3" align="left"><span style={pStyle}><b>Amount (in words) :</b><br /><span style={{ marginLeft: '50px' }}>{invDetails && invDetails.paymentAmtinWords}</span></span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>} */}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CompToPrintInv);