import React, { useState, useRef, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { renderToString } from 'react-dom/server'
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Snackbar,
  CircularProgress
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CompToPrintInv from './CompToPrintPurchaseInv';
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { currentUser } from "../login/loginSlice";
import { locations, bankDetails, termsAndConditions } from "../common/lookupSlice"
import { useForm, useFieldArray } from "react-hook-form";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import calculateIcon from '../../images/calculate.svg';
import calculateDisabledIcon from '../../images/calculator_disabled.svg';
import infoIcon from '../../images/info.svg';
import infoIconDisabled from '../../images/infoDisabled.svg';
import SearchProduct from "../productGroups/searchProductSale";
import axios from "axios";
import Payment from './purchasePayment';
/* import Payment from '../RecordSaleNew/payment'; */
import Calculator from '../recordSales/calculator';
import PriceDetermine from '../recordSales/priceDetermination';
// import Discount from './discounts';
// import ErrorLists from './errorLists';
import SupplierPOSelect from './supplierPOSelect';
// import ConfirmationDialog from "../common/ConfirmationDialog";
import {
  // openModal,
  closeModal,
  modalInProcessing
} from "../common/confirmationDialogSlice";
import moment from 'moment';
// import "./recordSale.css";


const RecordPurchaseComp = (props) => {
  const loggedInUser = useSelector(currentUser);
  const orgBankDetails = useSelector(bankDetails);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const { id } = useParams();
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const orgName = loggedInUser && loggedInUser.org && loggedInUser.org.name;
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  const isAdmin =
    roles.findIndex((role) => role.name === "ADMIN" || role.name === "PRICING ADMIN") > -1 ? true : false;
  const componentRefInv = useRef();
  const buttonRef = useRef();
  const token = localStorage.getItem("authToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const allInventoryLocations = useSelector(locations);
  const [costCenterList, setCostCenterList] = useState([]);
  const inventoryLocations = allInventoryLocations.filter((loc) => { return loc.type && loc.type.code !== "CORPORATE" && loc.GSTIN });
  const defaultLoc = inventoryLocations.find(loc => loc.isPrimary === true);
  const initialLoc = (defaultLoc) ? defaultLoc.id : inventoryLocations && inventoryLocations.length > 0 && inventoryLocations[0].id;
  const  corporateLoc = allInventoryLocations.find((loc) => { return loc.type && loc.type.code === "CORPORATE"});
  let corporateLocId = corporateLoc && corporateLoc.id;
  const [costCenterName, setCostCenterName] = useState('');
  const [store, setStore] = useState(initialLoc);
  const [shipLoc, setShipLoc] = useState(initialLoc);
  const [locName, setLocName] = useState('');
  const [shipLocName, setShipLocName] = useState('');
  const [supplierPO, setSupplierPO] = useState('');
  const [calculate, setCalculate] = useState(null);
  const [payments, setPayments] = useState({ open: false });
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [isSaleWarning, setIsSaleWarning] = useState(false);
  const [safetyStockWarning, setSafetyStockWarning] = useState(false);
  const [supplierInvoiceNum, setSupplierInvNum] = useState("");
  const [showPo, setShowSPo] = useState(false);
  const [isSendInvoice, setSendInvoice] = useState(false);

  const [remarkNote, setInvoiceRemark] = useState("")
  // const [paymentDetails, setPaymentDetails] = useState({}); 
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [emailSendData, setEmailSendData] = useState({
    emailId: '',
    emailSubject: ''
  });
  let { emailId, emailSubject } = emailSendData;
  const [invoiceNo, setInvoiceNo] = useState('');
  const [tempInvNo, setTempInvNo] = useState('');
  const [erpInvNo, setERPInvNo] = useState('');
  const [invoice, setInvoice] = useState([]);
  const [message, setMessage] = useState('');
  const [priceData, setPriceData] = useState({ open: false });
  const [productPriceData, setProductPriceData] = useState({ open: false });
  const [selectProduct, setSelectProduct] = useState({ open: false, test: null });
  const [dis, setDis] = useState([]);
  const [pro, setPro] = useState([]);
  const [selectedPo, setSelectedSPo] = useState({ spoNum: "", products: [] });
  const isPo = selectedPo.spoNum;
  const location = useLocation();
  const supplierData = location.state;
  const SupplierId = supplierData && supplierData.id;
  let orgCode = supplierData && supplierData.orgCode;
  const [orgDetails, setOrgDetails] = useState(null);
  const [HSNCodeData, setHSNData] = useState([]);
  const [errHSNProIndex, setErrHSN] = useState([]);
  const [errQtyProIndex, setErrQtyProd] = useState([]);

  /* Payment Gateway Changes */
  // const payMethods = useSelector(paymentMethods);
  // const [paymentMethod, setPaymentMethod] = useState(null);
  // const [paymentMethodCode, setPaymentMethodCode] = useState(null);
  // const [isConfirmBox, setIsConfirmBox] = React.useState(false);

  // const onPaymentmethodChange = async (event) => {
  //   let paymentMethodId = Number(event.target.value);
  //   // setPaymentMethod(paymentMethodId);
  //   let findPaymentMethodCode = await payMethods.find((pmData) => {
  //     return (pmData.id === paymentMethodId) ? pmData : null;
  //   })
  //   if (findPaymentMethodCode) {
  //     setIsConfirmBox(true)
  //     setPaymentMethodCode(findPaymentMethodCode.code)
  //     const dialogText = "Please confirm the payment process?";
  //     dispatch(
  //       openModal({
  //         dialogText: dialogText,
  //         secondayBtnText: "Yes",
  //         primaryBtnText: "Cancel",
  //         handleCloseModal: confirmDialogBox,
  //       })
  //     );
  //   }

  // }

  const confirmDialogBox = async (value, pData, returnResClback) => {
    if (value) {
      setIsLoading(true)
      dispatch(modalInProcessing());
      if (tempInvNo && erpInvNo) {
        dispatch(closeModal());
      } else {
        try {
          const clonedData = calculate ? _.cloneDeep(calculate) : {}
          //Below assignments needs to be updated
          const total = clonedData.total;
          const roundOff = total ? (Math.round((Math.round(total) - total) * 100) / 100).toFixed(2) : 0;
          clonedData.roundOff = Number(roundOff);
          let poNumber = isPo ? selectedPo.spoNum : supplierPO;
          if (poNumber) {
            clonedData.spoNum = poNumber
          }
          if (supplierInvoiceNum) {
            clonedData.supplierInvoiceNo = supplierInvoiceNum;
          }
          clonedData.orgCode = supplierData.orgCode;
          clonedData.supplierId = supplierData.id;
          clonedData.locationId = store;
          clonedData.costCenter = costCenterName;
          clonedData.shipLocationId = shipLoc;
          clonedData.invoiceDate = moment().format('MM/DD/YYYY');
          clonedData.remarkNote = (remarkNote) ? remarkNote : "";
          let result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseinvoice`,
            clonedData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const resStatus = result && result.data && result.data.status;
          if (resStatus === "SUCCESS") {
            let invNum = result && result.data && result.data.invoiceNum;
            let erpInv = result && result.data && result.data.erpRes && result.data.erpRes.docs && result.data.erpRes.docinfo && result.data.erpRes.docinfo.name;
            let invId = result && result.data && result.data.invoice && result.data.invoice.id
            let paymentPayload = {
              invoiceId: invId,
              status: "PAID",
              paymentMethodId: (pData.paymentMode === 3) ? 2 : 1,
              paymentModeId: pData.paymentMode,
              paymentId: pData.paymentId,
              amount: (pData.amountPaid) ? parseFloat(pData.amountPaid) : 0,
              totalAmount: Math.round(clonedData.total),
              paidAmount: (pData.amountPaid) ? parseFloat(pData.amountPaid) : 0,
              paymentDetails: pData.payment,
              erpInvoiceNum: erpInv,
              supplierId: supplierData.id,
              costCenter: (costCenterName)? costCenterName:""
            }
            //Post or put the req to save changes to db
            await axios.post(
              `${process.env.REACT_APP_SERVICE_URL}/payment/purchase`,
              paymentPayload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            setERPInvNo(erpInv)
            setTempInvNo(invNum);
            // setIsPaymentMethod(value)
            setIsSuccess(true)
            setIsSaved(true)
            setMessage("Purchase Invoice created")
            setInvoiceNo(invNum)
            dispatch(closeModal());
            returnResClback();
          } else {
            setMessage(result && result.data && result.data.message)
            setIsError(true)
            dispatch(closeModal());
            setPayments({ ...payments, open: false });
          }
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          setMessage("Something went wrong please try again.")
          setIsError(true);
          dispatch(closeModal());
          setPayments({ ...payments, open: false });

        }
      }

    } else {
      dispatch(closeModal());
    }
  }


  const { handleSubmit, control, errors, reset, register, setValue, getValues } = useForm({
    defaultValues: {
      sales: selectedPo.products ? selectedPo.products : [],
    },
  });

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "sales",
  });

  useEffect(() => {
    if (invoiceNo) {
      buttonRef && buttonRef.current && buttonRef.current.click()
    }
  }, [invoiceNo]);

  useEffect(() => {
    reset();
    setSelectedSPo({ spoNum: "", products: [] })
  }, [store]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedPo && selectedPo.spoNum) {
      setCalculate(null);
      setDis([])
      setPro([])
      append(selectedPo.products);
    }
  }, [selectedPo]); // eslint-disable-line react-hooks/exhaustive-deps


  const loadData = async (COMPANY_NAME_IN_ERPNEXT) => {
    // let count = 0;
    try {
      const hsncoderes = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/hsncode`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (hsncoderes.status === 200) {
        if (hsncoderes.data && hsncoderes.data.length > 0) {
          let hsnCodes = [];
          await hsncoderes.data.map((hsnData) => {
            hsnCodes.push(hsnData.code);
            return hsnData;
          })
          setHSNData(hsnCodes);
        }
      }
      if (id) {
        const result2 = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseinvoice/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLocName(result2.data.location?.name);
        setShipLocName(result2.data.shipToLocation?.name);
        result2.data.supplierPurchaseOrder && result2.data.supplierPurchaseOrder.spoNum && setSupplierPO(result2.data.purchaseOrder.spoNum);
        const newlineItems = result2.data.lineItems;

        setInvoiceRemark(result2 && result2.data && result2.data.remarkNote)
        setCostCenterName(result2.data && result2.data.erpNextcostCenter)
        /* setPaymentDetails(result2.data.payments && result2.data.payments[0]) */
        // eslint-disable-next-line 
        // console.log("newlineItems",newlineItems);
        newlineItems.map((x) => {
          // count = (x.product.inventories.openingQty < 1 || !(x.product.inventories && x.product.inventories.openingQty)) && count++;
          append({
            productId: x.product.id,
            productName: x.product.productName,
            products: [{ ...x.product }],
            HSNCode: (x.HSNCode) ? x.HSNCode : x.product && x.product.HSNCode,
            qty: x.qty,
            rate: (x.productPrice) ? (Math.round(x.productPrice * 100) / 100).toFixed(2) : 0.00,
            disc: x.overridenDiscountPcnt > 0 ? x.overridenDiscountPcnt : 0,
            avail: result2.data.invoiceNum ? ((x.product.inventories && x.product.inventories.openingQty) ? (x.product.inventories.openingQty + x.qty) : (0 + x.qty)) :
              x.product.inventories.openingQty,
            uom: x.product.uom,
            isQtyDecimal: x.product.isQtyDecimal
          })
          return x
        })
      }
      let queryCostCenter = {
        fields: ["name", "owner"],
        filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT, disabled: 0, is_group: 0 })
      }
      const resultCostCenter = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Cost Center`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: queryCostCenter,
        }
      );
      setCostCenterList(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data);
      setCostCenterName(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data && resultCostCenter.data.data.length > 0 && resultCostCenter.data.data[0].name)

      // (count > 0) && setIsWarning(true);
    }
    catch (err) {
      setIsError(true);
    }
  };

  useEffect(() => {
    loadData(COMPANY_NAME_IN_ERPNEXT);
  }, [COMPANY_NAME_IN_ERPNEXT]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const loadOrgData = async (code) => {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/org/${code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setOrgDetails(result.data);
        // let sellerData = {
        //   GSTIN: result.data && result.data.GSTIN,
        //   tradeName: result.data && result.data.name,
        //   address1: result.data && result.data.address1,
        //   address2: result.data && result.data.address2,
        //   address3: null,
        //   locality: result.data && result.data.address3,
        //   pincode: result.data && result.data.pincode,
        //   state: result.data && result.data.state,
        //   phoneNo: result.data && result.data.phoneNo,
        //   email: result.data && result.data.email
        // };
        // setSellerDetails(sellerData);

      }
    };

    loadOrgData(orgCode);
  }, [orgCode, supplierData, token, id]);


  const getSupplierInvoice = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseinvoice/${invoiceNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sales = result.data && result.data;
      return sales;
    }
    catch (err) {
      setIsError(err)
    }
  }

  const handleInvoicePrint = async () => {
    try {
      const data = await getSupplierInvoice();
      setInvoice(data);
    } catch (err) {
      setIsError(true);
    }
  }

  const openEmailInovice = async () => {
    try {
      const data = await getSupplierInvoice();
      setInvoice(data);
      setSendInvoice(true);
      setEmailSendData({
        emailSubject: `${orgName} - Invoice`,
        emailId: supplierData && supplierData.email
      })
    } catch (err) {
      setIsError(true);
    }
  }
  const onCloseSendInvoice = () => {
    setSendInvoice(false);
    setEmailSendData({ emailId: '', emailSubject: '' })
  }
  const onSendEmailInvoice = async () => {
    let isEmail = false;
    let isSubject = (emailSubject && emailSubject.length > 1) ? true : false;
    if (emailId && emailId.length > 0) {
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds, (data) => {
        if (!mailformat.test(String(data).toLowerCase())) {
          isEmail = false;
        }
      })
    }
    if (isEmail && isSubject) {
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData = () => {
        return (
          <CompToPrintInv ref={componentRefInv} supplierPurInv={invoice} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
        )
      }
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to', emailId)
      bodyFormData.append('subject', emailSubject)
      bodyFormData.append('invoice', renderToString(invoiceBodyData()))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseinvoice/mail`,
        bodyFormData,
        config
      );
      if (result.status === 200) {
        setIsSuccess(true)
        setMessage(result.data && result.data.message)
        setSendInvoice(false);
        setEmailSendData({ emailId: '' })
      }
    } else {
      if (!isEmail) {
        setEmailHelperText(true);
      }
      if (!isSubject) {
        setEmailSubjectHelperText(true);
      }
    }
  }
  const onCalculateClick = (index) => {
    const values = getValues({ nest: true })["sales"];
    const rowValues = values[index];
    setPriceData({ ...priceData, productId: rowValues.productId, productName: rowValues.productName, amount: rowValues.rate, qty: rowValues.qty, discount: rowValues.disc, open: true });
  }

  const onInfoClick = (index) => {
    setProductPriceData({ ...productPriceData, pricingInfo: productPriceData[index] && productPriceData[index].pricingInfo && productPriceData[index].pricingInfo[0], open: true });
  }

  const onSubmit = async (data) => {
    // let count = 0;
    try {
      const req = {
        supplierId: supplierData.id,
        locationId: store
      }
      let errHSN = [];
      let errqtyProd = [];
      const lineItems = data && data.sales && data.sales.map((x, y) => {
        let isErr = (_.includes(HSNCodeData, x.HSNCode)) ? false : true;
        let isQtyErr = false;
        if (x.isQtyDecimal === "false") {
          if (!(Number.isInteger(parseFloat(x.qty)))) {
            isQtyErr = true;
          }
        }
        if (isErr) {
          errHSN.push(y);
        }
        if (isQtyErr) {
          errqtyProd.push(y)
        }
        // (x.avail <= 0) && count++;
        let item = {
          productId: x.productId && Number(x.productId),
          qty: x.qty && Number(x.qty),
          overridenHSNCode: x.HSNCode && Number(x.HSNCode),
        }
        if (selectedPo.spoNum) {
          item.overridenUnitPrice = x.HSNCode && parseFloat(x.rate);
          item.overridenDiscountPcnt = x.HSNCode && parseFloat(x.disc);
        }
        if (!(selectedPo.spoNum)) {
          // pro[y] === x.rate || (pro.length === 0 && x.actualRate === x.rate) ?
          //   x.actualRate !== x.rate ? item.overridenUnitPrice = Number(x.rate) : "" :
          //   item.overridenUnitPrice = Number(x.rate)
          if (pro[y] === x.rate || (pro.length === 0 && x.actualRate === x.rate)) {
            if (x.actualRate !== x.rate) {
              item.overridenUnitPrice = Number(x.rate)
            }
          }
          else {
            item.overridenUnitPrice = Number(x.rate)
          }

          // dis[y] === x.disc || (dis.length === 0 && x.disc === "0.00") ?
          //   x.disc !== "0.00" ? item.overridenDiscountPcnt = Number(x.disc) : "" :
          //   item.overridenDiscountPcnt = Number(x.disc)

          if (dis[y] === x.disc || (dis.length === 0 && x.disc === "0.00")) {
            if (x.disc !== "0.00") {
              item.overridenDiscountPcnt = Number(x.disc)
            }
          }
          else {
            item.overridenDiscountPcnt = Number(x.disc)
          }
        }
        return item;
      })
      req.lineItems = lineItems;
      (selectedPo.spoNum) && (req.spoNum = selectedPo.spoNum);
      if (errqtyProd.length === 0) {
        if (errHSN.length === 0) {
          setErrHSN([]);
          setErrQtyProd([]);
          // if (count > 0) {
          //   setIsWarning(true);
          // } else {}
          req.supplierState = supplierData && supplierData.stateCode;
          const result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/pos/spo/calculate`,
            req,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const cloneDis = _.cloneDeep(dis)
          const clonePro = _.cloneDeep(pro)
          const salesValue = result.data.lineItems.map((x, y) => {
            if (!(cloneDis[y])) {
              cloneDis[y] = x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00'
            }
            if (!(clonePro[y])) {
              clonePro[y] = x.unitPrice ? (Math.round(x.unitPrice * 100) / 100).toFixed(2) : '0.00'
            }
            return {
              productId: x.productId,
              productName: x.productName,
              HSNCode: (x.HSNCode) ? x.HSNCode : x.product && x.product.HSNCode,
              qty: x.qty,
              rate: x.unitPrice ? (Math.round(x.unitPrice * 100) / 100).toFixed(2) : '0.00',
              disc: x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00',
              uom: x.uom,
              amount: x.amount ? (Math.round(x.amount * 100) / 100).toFixed(2) : '0.00',
              actualDisc: dis[y] ? dis[y] : x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00',
              //actualRate: x.unitPrice ? (Math.round(x.salePrice * 100) / 100).toFixed(2) : '0.00',
            }
          })
          setDis(cloneDis)
          setPro(clonePro)
          setValue("sales", salesValue)
          setCalculate(result.data)
          setProductPriceData(result.data.lineItems)



        } else {
          setErrHSN(errHSN);
        }
      } else {
        setErrQtyProd(errqtyProd);
      }
    }
    catch (err) {
      setIsError(true)
    }
  }

  const handleClose = () => {
    reset();
    setCalculate(null);
    setDis([])
    setPro([])
    setSelectedSPo({ spoNum: "", products: [] })
  }

  const handleCheckout = () => {
    if (supplierData) {

      setPayments({ ...payments, open: true })
    }
    else {
      setIsError(true)
    }
  }

  const rateValidation = (val) => {
    const valid = Number(val) > 0;
    return valid;
  }

  const handleReset = (e) => {
    history.push({
      pathname: "/recordpurchases",
      state: supplierData && supplierData,
    });
    window.location.reload();
    // setQuery({ page: 1, pageSize: pageSize })
  }

  let pageHeader = "Record Purchase";

  const saleVals = getValues({ nest: true })["sales"];

  return (
    supplierData ? <Grid container xs={12} spacing={2}>
      <Grid item container xs={12} direction="column">
        <Grid item>
          <Box mt={2}>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              {isAdmin && <Link color="inherit" href="" onClick={() => history.push("/suppliers")}>
                Supplier
              </Link>}
              <Typography component="span" color="textPrimary">
                {pageHeader}
              </Typography>
            </Breadcrumbs>
          </Box>
        </Grid>
      </Grid>
      <Grid item container xs={4} md={4} style={{alignContent: "flex-start" }}>
        <Box style={{ fontWeight: "bold", alignContent: "flex-start" }}>Supplier Name And Address:
        </Box>
        <Box ml={2}>
          <Typography>{supplierData && supplierData.fullName}</Typography>
          <Typography>{supplierData && supplierData.address1}</Typography>
          <Typography>{supplierData && supplierData.address2}</Typography>
          <Typography>{supplierData && supplierData.address3}</Typography>
        </Box>
      </Grid>
      <Grid item container xs={4} md={4}  >
        <Grid item xs={12}>
          <Box><Typography style={{ fontWeight: "bold" }}>Mobile Number:&nbsp;&nbsp;</Typography><Typography>{supplierData && supplierData.mobileNumber}</Typography></Box>
        </Grid>
        <Grid item xs={12}>
          <Box><Typography style={{ fontWeight: "bold" }}>Email:&nbsp;&nbsp;</Typography><Typography>{supplierData && supplierData.email}</Typography></Box>
        </Grid>
        <Grid item xs={12} style={{paddingTop:"36px"}}>
          <Box style={{ width: '100%' }}>
            <Typography style={{ fontWeight: "bold" }}>Supplier Invoice No. <span style={{ color: "red" }}>*</span></Typography></Box>
            <TextField
              inputProps={{ style: { textAlign: 'right' } }}
              size="small"
              name={`supplierInvoiceNo`}
              inputRef={register({
                required: "Supplier Invoice No. is required",
              })}
              fullWidth
              onChange={(e) => { setSupplierInvNum(e.target.value) }}
              disabled={(isSaved) ? true : false}
              variant="outlined"
              error={Boolean(
                errors.supplierInvoiceNo
              )}
            />
            <FormHelperText error={Boolean(
              errors.supplierInvoiceNo
            )}>
              {(errors.supplierInvoiceNo) ? "Enter Supplier Invoice No." : ""}
            </FormHelperText>
          
        </Grid>
      </Grid>
      <Grid item container xs={4} md={4}  >
        <Grid item xs={12}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Billing Location <span style={{ color: "red" }}>*</span></Box>
            {(id) ? locName : <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="locality"
                value={store}
                disabled={id}
                onChange={(e) => {
                  setStore(e.target.value);
                }}
                variant="outlined"
                style={{ width: "100%" }}
                fullWidth
              >
                {inventoryLocations.map(function (locality) {
                  return (
                    <MenuItem key={locality} value={locality.id}>
                      {locality.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Shipping Location <span style={{ color: "red" }}>*</span></Box>
            {(id) ? shipLocName : <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="locality"
                value={shipLoc}
                disabled={id}
                onChange={(e) => {
                  setShipLoc(e.target.value);
                }}
                variant="outlined"
                style={{ width: "100%" }}
                fullWidth
              >
                {inventoryLocations.map(function (locality) {
                  return (
                    <MenuItem key={locality} value={locality.id}>
                      {locality.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Cost Center <span style={{ color: "red" }}>*</span></Box>
            {(id) ? costCenterName : <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="locality"
                value={costCenterName}
                style={{ width: "100%" }}
                disabled={id}
                onChange={(e) => {
                  setCostCenterName(e.target.value);
                }}
                variant="outlined"
                fullWidth
              >
                {costCenterList.map(function (costCenter) {
                  return (
                    <MenuItem key={costCenter} value={costCenter.name}>
                      {costCenter.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>}</Typography>
        </Grid>

      </Grid>

      {inventoryLocations.length > 0 ? <Grid container xs={12} spacing={2}>
        <Grid item container xs={6} md={6} justifyContent="flex-start" >
          <IconButton
            onClick={() => {
              if (!isSaved) {
                setShowSPo(true);
              }
            }} disabled={supplierPO || id}
            style={{ backgroundColor: 'transparent' }} >
            <Typography style={{ fontWeight: "bold", color: "black", pointerEvents: "none" }}>Supplier Purchase Order No:</Typography>
            &nbsp;
            <Typography>{supplierPO ? supplierPO : selectedPo.spoNum}</Typography>
            &nbsp;
            {!supplierPO && <SearchIcon />}
          </IconButton></Grid>
        <Grid item container xs={6} md={6} justifyContent="flex-end" >

        </Grid>
      </Grid> : <Grid container xs={12} spacing={2} justifyContent="center">
        <Box mt={10} variant="subtitle1" style={{ fontWeight: "bold" }} component="span">
          You don't have access to store.Kindly contact your Admin.
        </Box>
      </Grid>}
      {inventoryLocations.length > 0 ? <Grid item container xs={12} direction="column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell align="left" style={{ width: "20%" }}>Product Name</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "10%" }}>HSN Code</StyledHeaderCell>
                  <StyledHeaderCell align="left">Qty</StyledHeaderCell>
                  <StyledHeaderCell align="left">Rate</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "10%" }}>Disc %</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "5%" }}>Available Qty</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "5%" }}>UOM</StyledHeaderCell>
                  <StyledHeaderCell align="left">Amount</StyledHeaderCell>
                  <StyledHeaderCell align="left"></StyledHeaderCell>
                </TableRow>
              </TableHead>
              {fields.length === 0 ? (
                <TableBody>
                  {((selectedPo.spoNum && selectedPo.products && selectedPo.products.length === 0) || (supplierPO)) && <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="subtitle1">
                        Supplier Purchase Order doesn't have eligible products for Purchase
                      </Typography>
                    </TableCell>
                  </TableRow>}
                  {!supplierPO && <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="primary"
                        onClick={(e) => {
                          setSelectedSPo({ spoNum: "", products: [] })
                          append({
                            saleId: "",
                            productName: "",
                            HSNCode: "",
                            qty: "",
                            rate: "",
                            disc: "",
                            avail: "",
                            unFulFilledQty: "",
                            uom: "",
                            amount: "",
                            isQtyDecimal: false
                          });
                        }}
                      >
                        Add Product
                      </Button>
                    </TableCell>
                  </TableRow>}
                </TableBody>
              ) : null}
              <TableBody>
                {fields.map((purchase, index) => {
                  return (
                    <TableRow key={purchase.id}>
                      <TableCell align="left">
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productName
                          )}
                          inputRef={register({
                            required: "Product is required",
                          })}
                          onClick={(e) => setSelectProduct({ ...selectProduct, open: true, lineKey: index, create: true })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                                {!(selectedPo.spoNum) && <SearchIcon onClick={(e) => setSelectProduct({ ...selectProduct, open: true })} />}
                              </InputAdornment>
                            ),
                            style: { fontSize: "14px" },
                          }}
                          size="small"
                          name={`sales[${index}].productName`}
                          style={{ fontSize: "14px !important" }}
                          multiline
                          minRows={1}
                          maxRows={3}
                          key={purchase.id} disabled
                          defaultValue={selectedPo.products[index] ? selectedPo.products[index].productName : purchase.productName ? purchase.productName : ''}
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productId
                          )}
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].productId`}
                          key={purchase.id} disabled
                          defaultValue={selectedPo.products[index] ? selectedPo.products[index].productId : purchase.productId ? purchase.productId : ''}
                        // defaultValue={props.form.data.sales && (props.form.data.sales)[index] ? (props.form.data.sales)[index].product && (props.form.data.sales)[index].product.id:''}
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productData
                          )}
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].productData`}
                          key={purchase.id} disabled={selectedPo.spoNum}
                          defaultValue={selectedPo.products[index] ? selectedPo.products[index].productData : ''}
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productName &&
                            errors.sales[index].productName.message}
                        </FormHelperText>

                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          name={`sales[${index}].HSNCode`}
                          defaultValue={purchase.HSNCode}
                          inputRef={register({
                            required: "HSN is required",
                          })}
                          disabled={(isSaved) ? true : false}
                          variant="standard"
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].HSNCode
                          ) || (_.includes(errHSNProIndex, index)) ? true : false}
                        />
                        <FormHelperText error={Boolean(
                          errors.sales &&
                          errors.sales[index] &&
                          errors.sales[index].HSNCode
                        ) || (_.includes(errHSNProIndex, index)) ? true : false}>
                          {((errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].HSNCode &&
                            errors.sales[index].HSNCode.message) || (_.includes(errHSNProIndex, index)) ? true : false) ? "Enter Valid HSN Code" : ""}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].qty
                          ) || (_.includes(errQtyProIndex, index)) ? true : false}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Quantity is required",
                            pattern: {
                              value: /^[0-9]*.[0-9]{0,3}$/,
                              message: "Quantity is required",
                            },
                          })}
                          onChange={(e) => {
                            const qty = e.target.value;
                            const saleVal = getValues({ nest: true })["sales"];
                            // if (saleVal[index] && qty >= Number(saleVal[index].unFulFilledQty)) {
                            //   const qtyKey = `sales[${index}].qty`;
                            //   setValue(qtyKey, Number(saleVal[index].unFulFilledQty))
                            // }
                            if (selectedPo.spoNum) {
                              const poItems = selectedPo && selectedPo.products;
                              const poQty = poItems && poItems[index] ? poItems[index].qty : "";
                              if (qty >= Number(poQty)) {
                                if (saleVal[index] && qty >= Number(saleVal[index].unFulFilledQty)) {
                                  const qtyKey = `sales[${index}].qty`;
                                  setValue(qtyKey, Number(saleVal[index].unFulFilledQty))
                                }
                                else {
                                  const qtyKey = `sales[${index}].qty`;
                                  setValue(qtyKey, Number(poQty))
                                }

                              }
                            }
                            setCalculate(null);
                          }}
                          disabled={isPo ? false : (isSaved) ? true : !(fields && fields[index] && fields[index].productId) && !(saleVals && saleVals[index] && saleVals[index].productId)}
                          size="small"
                          name={`sales[${index}].qty`}
                          defaultValue={purchase.qty}
                          variant="standard"
                        />
                        <FormHelperText error={Boolean(
                          errors.sales &&
                          errors.sales[index] &&
                          errors.sales[index].qty
                        ) || (_.includes(errQtyProIndex, index)) ? true : false}>
                          {((errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].qty &&
                            errors.sales[index].qty.message) || (_.includes(errQtyProIndex, index)) ? true : false) ? "Enter Valid Qty" : ""}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].rate
                          )}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Rate is required",
                            pattern: {
                              value: /^(?:\d*\.\d{1,2}|\d+)$/,
                              message: "Provide valid rate",
                            },
                            validate: (value) => rateValidation(value) || 'Price should be greater than 0'
                          })}
                          disabled={(isPo || isSaved) ? true : !(fields && fields[index] && fields[index].productId) && !(saleVals && saleVals[index] && saleVals[index].productId)}
                          size="small"
                          onChange={() => { setCalculate(null); }}
                          name={`sales[${index}].rate`}
                          defaultValue={purchase.rate}
                          variant="standard"
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].actualRate`}
                          key={purchase.id} disabled
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].rate &&
                            errors.sales[index].rate.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].disc
                          )}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Discount is required",
                            pattern: {
                              value: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/,
                              message: "Provide a valid discount rate",
                            },
                          })}
                          size="small"
                          onChange={() => { setCalculate(null); }}
                          name={`sales[${index}].disc`}
                          disabled={(isPo || isSaved) ? true : !(fields && fields[index] && fields[index].productId) && !(saleVals && saleVals[index] && saleVals[index].productId)}
                          defaultValue={purchase.disc}
                          variant="standard"
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].actualDisc`}
                          key={purchase.id} disabled
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].disc &&
                            errors.sales[index].disc.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          disabled
                          size="small"
                          name={`sales[${index}].avail`}
                          defaultValue={purchase.avail}
                          inputRef={register({
                          })}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          disabled
                          size="small"
                          name={`sales[${index}].uom`}
                          inputRef={register({
                          })}
                          defaultValue={purchase.uom}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          disabled
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          name={`sales[${index}].amount`}
                          inputRef={register({
                          })}
                          defaultValue={purchase.amount}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell style={{ display: 'none' }}>
                        <TextField
                          disabled
                          inputProps={{ style: { textAlign: 'right', display: 'none' } }}
                          inputRef={register({})}
                          size="small"
                          name={`sales[${index}].isQtyDecimal`}
                          defaultValue={
                            (purchase &&
                              purchase.isQtyDecimal) ? true : false
                          }
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell align="right" style={{ width: isPo ? '14%' : '16%' }}>
                        {(isPo || supplierPO) ? "" :
                          <IconButton
                            size="small"
                            disabled={isSaved}
                            onClick={(e) => {
                              insert(index + 1, {
                                saleId: "",
                                productName: "",
                                HSNCode: "",
                                qty: "",
                                rate: "",
                                disc: "",
                                avail: "",
                                unFulFilledQty: "",
                                uom: "",
                                amount: "",
                                productId: "",
                                isQtyDecimal: false
                              });
                              setCalculate(null);
                              setErrHSN([]);
                              setErrQtyProd([]);
                            }
                            }
                          >
                            <AddIcon color={(isSaved) ? "" : "secondary"} />
                          </IconButton>}
                        {(isPo || supplierPO) ? "" : <span>&nbsp;</span>}
                        <IconButton
                          size="small"
                          disabled={isSaved}
                          onClick={(e) => {
                            remove(index);
                            setCalculate(null);
                            setErrHSN([]);
                            setErrQtyProd([]);
                          }}
                        >
                          <DeleteIcon color={(isSaved) ? "" : "secondary"} />
                        </IconButton>
                        &nbsp;
                        <IconButton size="small" disabled={isSaved} onClick={() => onCalculateClick(index)}>
                          <img src={(isSaved) ? calculateDisabledIcon : calculateIcon} alt="history" />
                        </IconButton>
                        &nbsp;
                        <IconButton size="small" disabled={(!calculate || isSaved)} onClick={() => onInfoClick(index)}>
                          {(!calculate || isSaved) ? <img src={infoIconDisabled} alt="history" /> : <img src={infoIcon} alt="history" />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={selectProduct.open}
            onClose={() => {
              setSelectProduct({ ...selectProduct, open: false });
            }}
          >
            <DialogTitle>
              <Grid container>
                <Grid item container xs={9} alignItems="center">
                  <Grid item>
                    Select Product
                  </Grid>
                </Grid>
                <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setSelectProduct({ ...selectProduct, open: false });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent >
              <SearchProduct
                form={selectProduct}
                setValue={setValue}
                getValues={getValues}
                sales={getValues({ nest: true })["sales"]}
                setIsWarning={setIsWarning}
                setSafetyStockWarning={setSafetyStockWarning}
                setCalculate={setCalculate}
                purchaseIdentifier={true}
                addonData={{ customerType: null, locationId: corporateLocId }}
                cancel={() => {
                  setSelectProduct({ ...selectProduct, open: false });
                }}
                success={() => {
                  setSelectProduct({ ...selectProduct, open: false, success: true });
                }}
              />
            </DialogContent>
          </Dialog>
          {fields.length !== 0 && !isSaved && <Grid item container xs={12} justifyContent="flex-end">
            <Box mt={2}>
              <Button
                size="small"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSaved}
                style={{ marginRight: "10px" }}
              >
                Calculate
              </Button>
              <Button
                disabled={isSaved}
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                Clear
              </Button>
            </Box>
          </Grid>}
        </form>


        {calculate && <Box mt={18}>
          <List dense={true} style={{ backgroundColor: "#F5F5F5", fontSize: "14px" }}>
            <ListItem alignItems="flex-start" divider={true} >
              <Grid item container xs={12}>
                <Grid item xs={11}>
                  Subtotal
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" justifyContent="flex-end"> {calculate.subtotal && (Math.round(calculate.subtotal * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start" divider={true}>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  GST
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start" divider={true}>

              <Grid item container xs={12} >
                <Grid item xs={11} >
                  <Box ml={2} >IGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box display="flex" justifyContent="flex-end">{calculate.IGST && (Math.round(calculate.IGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
                <Grid item xs={11} >
                  <Box mt={0.3} ml={2}>CGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box mt={1} display="flex" justifyContent="flex-end">{calculate.CGST && (Math.round(calculate.CGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
                <Grid item xs={11} >
                  <Box ml={2} >SGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box display="flex" justifyContent="flex-end">{calculate.SGST && (Math.round(calculate.SGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start">
              <Grid item container xs={12} style={{ fontWeight: "bold" }}>
                <Grid item xs={11}>
                  Total
                </Grid>
                <Grid item xs={1} style={{ borderLeft: "4px solid #FFFFFF" }}>
                  <Box display="flex" justifyContent="flex-end"> {calculate.total && (Math.round(calculate.total * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
          </List>
          <Box mt={2} style={{ fontSize: "12px" }}>{`Amount Chargeable(In words): ${calculate.amountInWords}`}</Box>
          {!isSaved && <Grid container spacing={2} alignItems="baseline" style={{ marginTop: "15px" }}>

            <Grid item container md={6} xs={12}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Notes</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  name="remarkNote"
                  inputProps={{ maxLength: 150 }}
                  placeholder="Notes..."
                  minRows={2}
                  maxRows={5}
                  size="small"
                  onChange={e => setInvoiceRemark(e.target.value)}
                  value={remarkNote}
                />
                <FormHelperText >
                  Textfield allow only 500 character.
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item container md={6} xs={12}>
            </Grid>
          </Grid>}
          {!isSaved && <Box mt={3} display="flex" justifyContent="flex-end">
            {isLoading && <CircularProgress />}
            <Button
              // onClick={handleCheckout}
              disabled={isLoading}
              onClick={handleCheckout}
              color="primary" size="small" variant="contained" style={{ marginRight: '10px' }}>
              Check Out </Button>
          </Box>}
          {isSaved &&
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                onClick={handleReset}
                color="primary"
                size="small"
                variant="contained"
                style={{ marginRight: 10 }}
              >
                New Purchase
              </Button>
              <ReactToPrint
                onBeforeGetContent={() => handleInvoicePrint(invoiceNo)}
                trigger={() => <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  style={{ marginRight: 10 }}
                >
                  PRINT INVOICE
                </Button>}
                content={() => componentRefInv.current} />
              <Button
                onClick={openEmailInovice}
                color="primary"
                size="small"
                variant="contained"
              >
                Email
              </Button>
              <Button onClick={handleCheckout} style={{ display: "none" }}>
                Test
              </Button>
            </Box>
          }
        </Box>}

      </Grid> : ""}
      {/* Confirm box */}
      {/* {isConfirmBox ?
        <ConfirmationDialog onClose={confirmDialogBox} />
        : null} */}
      {/* payments */}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={payments.open}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <h2 style={{ color: "#0c4b60" }}>
                Payments
              </h2>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setPayments({ ...payments, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Grid item xs={12}>
            <Payment
              cancel={() => {
                setPayments({ ...payments, open: false });
              }}
              calculate={calculate}
              supplierData={supplierData}
              costCenter={costCenterName}
              shipLocationId={shipLoc}
              // paymentDetails={paymentDetails}
              supplierId={SupplierId}
              savePayments={confirmDialogBox}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      {/* SupplierPOSelect */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={showPo}
        onClose={() => {
          setShowSPo(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Supplier Purchase Orders
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowSPo(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <SupplierPOSelect remove={remove} setIsError={setIsError} setSelectedSPo={setSelectedSPo} store={corporateLocId} setShowSPo={setShowSPo} />
        </DialogContent>
      </Dialog>


      {/* calculator */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={priceData.open}
        onClose={() => {
          setPriceData({ ...priceData, open: false });
        }}
      >
        <DialogTitle style={{ paddingBottom: "0px" }}>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Price Calculator
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setPriceData({ ...priceData, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Calculator
            cancel={() => {
              setPriceData({ ...priceData, open: false });
            }}
            priceData={priceData}
            intraState={supplierData && orgDetails && supplierData.stateCode !== orgDetails.state}
          />
        </DialogContent>
      </Dialog>


      {/* price */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={productPriceData.open}
        onClose={() => {
          setProductPriceData({ ...productPriceData, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Product Price Information(Per Unit)
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProductPriceData({ ...productPriceData, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <PriceDetermine
            cancel={() => {
              setProductPriceData({ ...productPriceData, open: false });
            }}
            priceData={productPriceData}
          />
        </DialogContent>
      </Dialog>
      {/* Invoice email */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Email Invoice
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '180px' }}>
          <Grid container>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span" >Mail to *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                inputRef={register({
                  required: "Email Id is required",
                })}
                value={emailId}
                onChange={e => setEmailSendData({ ...emailSendData, emailId: e.target.value })}
              />
              {isEmailHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter valid email id
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: '10px' }}>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span">Subject *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                inputRef={register({
                  required: "Email Subject is required",
                })}
                value={emailSubject}
                onChange={e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value })}
              />
              {isEmailSubjectHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter email subject
              </FormHelperText>}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              onClick={onSendEmailInvoice}
              color="secondary"
              size="small"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Box display="none">
        <CompToPrintInv ref={componentRefInv} supplierPurInv={invoice} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
        <ReactToPrint
          onBeforeGetContent={() => handleInvoicePrint(invoiceNo)}
          trigger={() => <Button
            color="primary"
            size="small"
            variant="contained"
            ref={buttonRef}
          >
            PRINT INVOICE
          </Button>}
          content={() => componentRefInv.current} />
      </Box>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {message ? message : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setIsSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message ? message : "Thanks for shopping!"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isWarning}
        autoHideDuration={3000}
        onClose={() => setIsWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          {"Product is currently not available"}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={isSaleWarning}
        autoHideDuration={3000}
        onClose={() => setIsSaleWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsSaleWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          {message ? message : "Error form ASP port unble to generate eInvoice/EWB"}
        </MuiAlert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={safetyStockWarning}
        onClose={() => {
          setSafetyStockWarning(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={10} alignItems="center">
              <Grid item >
                Product availability is low. Check Safety Stock.
              </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSafetyStockWarning(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} >
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setSafetyStockWarning(false)}
                  color="primary"
                  size="medium"
                  variant="contained"
                  style={{ marginRight: 10 }}
                >
                  OK
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
      </Dialog>
    </Grid> : <Grid item container xs={12} direction="column">
      <Grid item>
        <Box mt={2}>
          <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
            <Link color="inherit" href="" onClick={() => history.push("/")}>
              Home
            </Link>
            {isAdmin && <Link color="inherit" href="" onClick={() => history.push("/suppliers")}>
              Supplier
            </Link>}
            <Typography component="span" color="textPrimary">
              Record Purchase
            </Typography>
          </Breadcrumbs>
        </Box>
      </Grid>
      <Grid><Box mt={2}>Kindly select a Supplier to Record Purchase</Box></Grid>
    </Grid>

  );
};

export default RecordPurchaseComp;
