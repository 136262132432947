import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import axios from "axios";
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice"
import { Breadcrumbs, Grid, Link, Typography, FormControl, Select, MenuItem, FormControlLabel, Checkbox, FormHelperText, Box, Button } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import _ from "lodash";
import moment from 'moment';

const ERPNextGeneRalLedagerReport = (props) => {
  const history = useHistory();
  const [columsWithSubRows, setColumns] = useState([]);
  const [dataWithSubRows, setData] = useState([]);
  const token = localStorage.getItem("authToken");

  const loggedInUser = useSelector(currentUser);
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;

  // const [filterDateRange, setDateRangeFilter] = useState({ from_date: null, to_date: null });
  const [isDateRangeFilterError, setDateRangeFilterError] = useState(false);
  const [costCenterList, setCostCenterList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [fiscalYearDate, setFiscalYearDate] = useState({ fiscalYearStartDate: '', fiscalYearEndDate: '' });
  const [currentFiscalYear, setCurrentFiscalYear] = useState(null);
  const [tempFilters, setTempFilter] = useState({
    company: COMPANY_NAME_IN_ERPNEXT,
    from_date: fiscalYearDate.fiscalYearStartDate,
    to_date: fiscalYearDate.fiscalYearEndDate,
    account: [],
    party: [],
    group_by: "Group by Account",
    presentation_currency: "INR",
    cost_center: [],
    project: [],
    include_dimensions: 1,
    show_opening_entries: 0,
    include_default_book_entries: 1,
    show_cancelled_entries: 0,
    show_net_values_in_party_account: 0,
    add_values_in_transaction_currency: 0,
    show_remarks: 0,
    ignore_err: 0
  });

  const [filters, setFilter] = useState({
    company: COMPANY_NAME_IN_ERPNEXT,
    from_date: '',
    to_date: '',
    account: [],
    party: [],
    group_by: "Group by Account",
    presentation_currency: "INR",
    cost_center: [],
    project: [],
    include_dimensions: 1,
    show_opening_entries: 0,
    include_default_book_entries: 1,
    show_cancelled_entries: 0,
    show_net_values_in_party_account: 0,
    add_values_in_transaction_currency: 0,
    show_remarks: 0,
    ignore_err: 0
  });


  let getDefaultData = async (authToken, COMPANY_NAME_IN_ERPNEXT) => {
    let queryCostCenter = {
      fields: ["name", "owner"],
      filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT })
    }
    const resultCostCenter = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Cost Center`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: queryCostCenter,
      }
    );
    setCostCenterList(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data);

    // let queryFiscalYear = {
    //   fields: ["name", "owner"]
    // }
    // const resultFiscalYear = await axios.get(
    //   `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Fiscal Year`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${authToken}`,
    //     },
    //     params: queryFiscalYear,
    //   }
    // );
    // setFiscalYearList(resultFiscalYear && resultFiscalYear.data && resultFiscalYear.data.data)

    let queryCurrency = {
      fields: ["name", "owner"],
      filters: JSON.stringify({ enabled: 1 })
    }
    const resultCurrency = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Currency`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: queryCurrency,
      }
    );
    setCurrencyList(resultCurrency && resultCurrency.data && resultCurrency.data.data)
  }

  useEffect(() => {
    getDefaultData(token, COMPANY_NAME_IN_ERPNEXT);
  }, [token, COMPANY_NAME_IN_ERPNEXT]);

  let getFiscalYearDate = (fiscalYear) => {
    const [startYear, endYear] = fiscalYear.split('-').map(Number);

    // Assuming fiscal year starts on April 1st
    const fiscalYearStartMonth = 3;

    const fiscalYearStartDate = new Date(startYear, fiscalYearStartMonth, 1);
    const fiscalYearEndDate = new Date(endYear, fiscalYearStartMonth, 0);
    return { fiscalYearStartDate: fiscalYearStartDate, fiscalYearEndDate: fiscalYearEndDate }
  }

  const getCurrentFiscalYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    // Assuming fiscal year starts on April 1st
    const fiscalYearStartMonth = 3;
    if (today.getMonth() < fiscalYearStartMonth) {
      // Current date is before the fiscal year start month
      return `${currentYear - 1}-${currentYear}`;
    } else {
      // Current date is on or after the fiscal year start month
      return `${currentYear}-${currentYear + 1}`;
    }
  };

  const loadData = async (authToken, filterData, dataConvert) => {
    try {
      let query = {
        report_name: 'General Ledger',
        filters: `${JSON.stringify(filterData)}`
      }
      console.log("filterData", filterData);
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextreport/generalLedger`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: query,
        }
      );
      if (result && result.data && result.data.data.length > 0) {
        const resData = await dataConvert(result.data.data, 'INR');
        setData(resData);
        setColumns(result.data.columns);
      } else if (result && result.data && result.data.data.length === 0) {
        setData([]);
        setColumns(result.data.columns);
      }

    } catch (err) {
      setColumns([]);
      setData([]);
    }
  };

  useEffect(() => {
    if (currentFiscalYear) {
      const formatNumbersInJSON = (data, currency) => {
        const formattedData = JSON.parse(JSON.stringify(data)); // Create a deep copy of the original data

        const traverse = (node) => {
          for (const key in node) {
            if (typeof node[key] === 'object' && node[key] !== null) {
              traverse(node[key]);
            } else if (typeof node[key] === 'number') {
              // Format numbers with two decimal places and currency symbol
              node[key] = node[key].toLocaleString('en-IN', {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            }
            // else if (typeof node[key] === 'string') {
            //   // Remove single quotes in strings
            //   node[key] = node[key].replace(/'/g, '');
            // }
          }
        };

        traverse(formattedData);
        return formattedData;
      };
      loadData(token, filters, formatNumbersInJSON);
    } else {
      let fiscalYear = getCurrentFiscalYear()
      let getFiscalYearDateRes = getFiscalYearDate(fiscalYear);
      setFiscalYearDate({ fiscalYearStartDate: getFiscalYearDateRes.fiscalYearStartDate, fiscalYearEndDate: getFiscalYearDateRes.fiscalYearEndDate })
      // setDateRangeFilter({ from_date: getFiscalYearDateRes.fiscalYearStartDate, to_date: getFiscalYearDateRes.fiscalYearEndDate })
      let from_date = "";
      let to_date = "";
      if (getFiscalYearDateRes && getFiscalYearDateRes.fiscalYearStartDate) {
        from_date = moment(getFiscalYearDateRes.fiscalYearStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      if (getFiscalYearDateRes && getFiscalYearDateRes.fiscalYearEndDate) {
        to_date = moment(getFiscalYearDateRes.fiscalYearEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      setFilter({
        ...filters,
        from_date: from_date,
        to_date: to_date
      })
      setTempFilter({
        company: COMPANY_NAME_IN_ERPNEXT,
        from_date: getFiscalYearDateRes.fiscalYearStartDate,
        to_date: getFiscalYearDateRes.fiscalYearEndDate,
        account: [],
        party: [],
        group_by: "Group by Account",
        presentation_currency: "INR",
        cost_center: [],
        project: [],
        include_dimensions: 1,
        show_opening_entries: 0,
        include_default_book_entries: true,
        show_cancelled_entries: 0,
        show_net_values_in_party_account: 0,
        add_values_in_transaction_currency: 0,
        show_remarks: 0,
        ignore_err: 0
      })
      setCurrentFiscalYear(fiscalYear);
    }
  }, [token, currentFiscalYear, filters, COMPANY_NAME_IN_ERPNEXT]);





  const dateValidation = (from_date, to_date) => {
    if (from_date && to_date) {
      const start = moment(from_date);
      const end = moment(to_date);
      const diff = end.diff(start, 'day');
      return (diff >= 0) ? true : false;
    }
    return true
  }

  const handleDateChange = (value, field) => {
    if (field === 'from_date') {
      let isValidate = dateValidation(value, tempFilters.to_date);
      setTempFilter({ ...tempFilters, from_date: value })
      setDateRangeFilterError(!isValidate);
    } else {
      let isValidate = dateValidation(tempFilters.from_date, value);
      setTempFilter({ ...tempFilters, to_date: value })
      setDateRangeFilterError(!isValidate);
    }

  };


  const handleSearch = (data) => {
    // data.preventDefault();
    // let from_date = "";
    // let to_date = "";
    // if (data && data.from_date) {
    //   from_date = moment(data.from_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    // }
    // if (data && data.to_date) {
    //   to_date = moment(data.to_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    // }
    setFilter({
      ...filters,
      presentation_currency: tempFilters.presentation_currency,
      filter_based_on: tempFilters.filter_based_on,
      periodicity: tempFilters.periodicity,
      from_fiscal_year: tempFilters.from_fiscal_year,
      to_fiscal_year: tempFilters.to_fiscal_year,
      period_start_date: tempFilters.period_start_date,
      period_end_date: tempFilters.period_end_date,
      cost_center: tempFilters.cost_center,
      project: tempFilters.project,
      finance_book: tempFilters.finance_book,
      accumulated_values: (tempFilters.accumulated_values) ? 1 : 0,
      include_default_book_entries: (tempFilters.include_default_book_entries) ? 1 : 0,
    });
    let from_date = "";
    let to_date = "";
    if (fiscalYearDate && fiscalYearDate.fiscalYearStartDate) {
      from_date = moment(fiscalYearDate.fiscalYearStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (fiscalYearDate && fiscalYearDate.fiscalYearEndDate) {
      to_date = moment(fiscalYearDate.fiscalYearEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    setFilter({
      ...filters,
      company: COMPANY_NAME_IN_ERPNEXT,
      from_date: from_date,
      to_date: to_date,
      account: tempFilters.account,
      party: tempFilters.party,
      group_by: tempFilters.group_by,
      presentation_currency: tempFilters.presentation_currency,
      cost_center: tempFilters.cost_center,
      project: tempFilters.project,
      include_dimensions: (tempFilters.include_dimensions) ? 1 : 0,
      show_opening_entries: (tempFilters.show_opening_entries) ? 1 : 0,
      include_default_book_entries: (tempFilters.include_default_book_entries) ? 1 : 0,
      show_cancelled_entries: (tempFilters.show_cancelled_entries) ? 1 : 0,
      show_net_values_in_party_account: (tempFilters.show_net_values_in_party_account) ? 1 : 0,
      add_values_in_transaction_currency: (tempFilters.add_values_in_transaction_currency) ? 1 : 0,
      show_remarks: (tempFilters.show_remarks) ? 1 : 0,
      ignore_err: (tempFilters.ignore_err) ? 1 : 0,
    });
  };

  const handleReset = (e) => {
    setTempFilter({
      ...tempFilters,
      company: COMPANY_NAME_IN_ERPNEXT,
      from_date: fiscalYearDate.fiscalYearStartDate,
      to_date: fiscalYearDate.fiscalYearEndDate,
      account: [],
      party: [],
      group_by: "Group by Account",
      presentation_currency: "INR",
      cost_center: [],
      project: [],
      include_dimensions: 1,
      show_opening_entries: 0,
      include_default_book_entries: 1,
      show_cancelled_entries: 0,
      show_net_values_in_party_account: 0,
      add_values_in_transaction_currency: 0,
      show_remarks: 0,
      ignore_err: 0
    });
    let from_date = "";
    let to_date = "";
    if (fiscalYearDate && fiscalYearDate.fiscalYearStartDate) {
      from_date = moment(fiscalYearDate.fiscalYearStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (fiscalYearDate && fiscalYearDate.fiscalYearEndDate) {
      to_date = moment(fiscalYearDate.fiscalYearEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    setFilter({
      ...filters,
      company: COMPANY_NAME_IN_ERPNEXT,
      from_date: from_date,
      to_date: to_date,
      account: [],
      party: [],
      group_by: "Group by Account",
      presentation_currency: "INR",
      cost_center: [],
      project: [],
      include_dimensions: 1,
      show_opening_entries: 0,
      include_default_book_entries: 1,
      show_cancelled_entries: 0,
      show_net_values_in_party_account: 0,
      add_values_in_transaction_currency: 0,
      show_remarks: 0,
      ignore_err: 0
    });
  }

  const columns = useMemo(
    () => columsWithSubRows,
    [columsWithSubRows],
  );
  const data = useMemo(
    () => dataWithSubRows,
    [dataWithSubRows],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false,
    enableSubRowSelection: true,
    enableExpanding: true,
    getSubRows: (row) => row.subRows,
    initialState: { expanded: false },
    paginateExpandedRows: false,
    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableTopToolbar: true,
    enableFilters: false,
    enableDensityToggle: false,
    enableHiding: false,
    getRowId: (row) => row.account
  });

  console.log("tempFilters", tempFilters);
  return (
    <>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href=""
                onClick={() => history.push("/")}
              >
                Home
              </Link>
              <Link color="inherit" href=""
                onClick={() => history.push("/reports")}
              >
                Reports
              </Link>
              <Typography component="span" color="textPrimary">
                General Ledger
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">From Date</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={false}
                  size="small"
                  helperText=''
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  name="from_date"
                  value={tempFilters.from_date}
                  onChange={(value) => handleDateChange(value, "from_date")}
                />
              </MuiPickersUtilsProvider>
              {isDateRangeFilterError && <FormHelperText error={true}>
                Filter Form Date Should be less than To Date
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">To Date</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  helperText=""
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  name="to_date"
                  value={tempFilters.to_date}
                  onChange={(value) => handleDateChange(value, "to_date")}
                />
              </MuiPickersUtilsProvider>
              {isDateRangeFilterError && <FormHelperText error={true}>
                Filter To Date Should be greater than From Date
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Currency</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  id="currency-select"
                  value={tempFilters.presentation_currency}
                  onChange={(e) => {
                    setTempFilter({ ...tempFilters, presentation_currency: e.target.value });
                  }}
                >
                  {currencyList && currencyList.length > 0 && currencyList.map(function (currency, index) {
                    return (
                      <MenuItem key={`currency-${index + 1}`} value={currency.name}>
                        {currency.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Cost Center</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  id="Cost-Center-select"
                  multiple
                  value={tempFilters.cost_center}
                  onChange={(e) => {
                    setTempFilter({ ...tempFilters, cost_center: e.target.value });
                  }}
                >
                  {costCenterList && costCenterList.length > 0 && costCenterList.map(function (cost_center, index) {
                    return (
                      <MenuItem key={`cost_center-${index + 1}`} value={cost_center.name}>
                        {cost_center.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}>
            <Grid item md={12} xs={12}>
              <Typography variant="caption">Group By</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  id="groupby-select"
                  value={tempFilters.group_by}
                  onChange={(e) => {
                    setTempFilter({ ...tempFilters, group_by: e.target.value });
                  }}
                >
                  <MenuItem key={`groupby-0`} value="Group by Voucher">
                    Group by Voucher
                  </MenuItem>
                  <MenuItem key={`groupby-1`} value="Group by Voucher (Consolidated)">
                    Group by Voucher (Consolidated)
                  </MenuItem>
                  <MenuItem key={`groupby-2`} value="Group by Account">
                    Group by Account
                  </MenuItem>
                  <MenuItem key={`groupby-3`} value="Group by Party">
                    Group by Party
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container md={2} xs={6}></Grid>
          <Grid item container md={4} xs={6}>
            <FormControlLabel
              fullWidth
              variant="outlined"
              size="small"
              label="Consider Accounting Dimensions"
              control={
                <Checkbox
                  value={tempFilters.include_dimensions}
                  defaultChecked
                  onChange={(e, val) => {
                    setTempFilter({ ...tempFilters, include_dimensions: val });
                  }}
                />
              }
            />
          </Grid>
          <Grid item container md={2} xs={6}>
            <FormControlLabel
              fullWidth
              variant="outlined"
              size="small"
              label="Show Opening Entries"
              control={
                <Checkbox
                  value={tempFilters.show_opening_entries}
                  onChange={(e, val) => {
                    setTempFilter({ ...tempFilters, show_opening_entries: val });
                  }}
                />
              }
            />
          </Grid>
          <Grid item container md={2} xs={6}>
            <FormControlLabel
              fullWidth
              variant="outlined"
              size="small"
              label="Include Default FB Entries"
              control={
                <Checkbox
                  value={tempFilters.include_default_book_entries}
                  defaultChecked
                  onChange={(e, val) => {
                    setTempFilter({ ...tempFilters, include_default_book_entries: val });
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item container justifyContent={"flex-end"} md={12} xs={12}>
            <Box mt={2} mr={2}>
              <Button
                size="small"
                onClick={handleSearch}
                variant="contained"
                color="primary"
              >
                Generate Report
              </Button>
            </Box>
            <Box mt={2}>
              <Button size="small"
                onClick={handleReset}
                variant="contained"
                color="secondary">
                clear
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
      <Grid style={{ padding: "10px" }}></Grid>
      {columsWithSubRows && columsWithSubRows.length > 0 ?
        <MaterialReactTable table={table} /> :
        <label>Report Data not found</label>}
    </>
  );
};

export default ERPNextGeneRalLedagerReport;
