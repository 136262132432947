import React from "react";
import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  List,
  ListItem,
  ListSubheader
} from "@material-ui/core";
// import { currentUser } from "../login/loginSlice";
// import quotations from "../../images/quotations.svg";
// import salesPurchaseInvoice from "../../images/salesPurchaseInvoice.svg";
// import supplierPurchaseOrder from "../../images/supplierPurchaseOrder.svg";
// import materialRequest from "../../images/materialRequest.svg";
// import estimate from "../../images/estimate.svg";
// import supplier16 from "../../images/supplier16.svg";

const SupplierPurchaseHome = (props) => {
  const history = useHistory();
  // const loggedInUser = useSelector(currentUser);
  // const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  // const isAdmin =
  //   roles.findIndex((role) => role.name === "ADMIN") > -1
  //     ? true
  //     : false;
  const handleUserGuide = (userguidepath) => {
    window.open(`/${userguidepath}`, '_blank');
  }

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
                User Guides
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Grid item container xs={12} md={6} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold" }}>User Guides</ListSubheader>} >
            <ListItem>
              <Link
                component="button"
                variant="body2"
                onClick={() =>{handleUserGuide("salepurchaseuserguide.pdf")}}
              >
                1. Sale, Purchase, Customer, Supplier User Guide
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component="button"
                variant="body2"
                onClick={() =>{handleUserGuide("productinventoryuserguide.pdf")}}
              >
                2. Product, Product Group, Inventory User Guide
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component="button"
                variant="body2"
                onClick={() =>{handleUserGuide("serviceuserguide.pdf")}}
              >
                3. Service Module User Guide
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component="button"
                variant="body2"
                onClick={() =>{handleUserGuide("accountinguserguide.pdf")}}
              >
                4. Accounting User Guide
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component="button"
                variant="body2"
                onClick={() =>{handleUserGuide("userdatamanagementuserguide.pdf")}}
              >
                5. Users, Data Management, Configurations User Guide
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component="button"
                variant="body2"
                onClick={() =>{handleUserGuide("reportuserguide.pdf")}}
              >
                6. Reports User Guide
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>
    </div>
  );
};

export default SupplierPurchaseHome;
