import React from "react";
import { useHistory } from "react-router-dom";

import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon
} from "@material-ui/core";

import paymentEntry from "../../images/paymentEntry.svg";
import journalEntry from "../../images/journalEntry.svg";
import creditNote from "../../images/creditNote.svg";
import bankStatementUpload from "../../images/bankStatementUpload.svg";
import bankReconciliationStatement from "../../images/bankReconciliationStatement.svg";
import debitNote from "../../images/debitNote.svg";
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice";

const AccountHome = (props) => {
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
                Accounting
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      {isAuditor ? null : <div>
        <Grid item container xs={12} md={4} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold", display: "inline-block" }}>Quick Links</ListSubheader>} ></List>
          <List style={{ display: "inline-flex", marginTop: "-20px" }}>
            <ListItem style={{ width: "200px", fontWeight: "bold" }}>
              <img src={journalEntry} alt="journalEntry" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/journalentries")}
              >
                Journal Entry
              </Link>
            </ListItem>
            <ListItem style={{ width: "200px" }}>
              <img src={paymentEntry} alt="paymentEntry" className="quickIconPadding" />
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/paymentsentries")}
              >
                Payment Entry
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>}
      <div>
        <Grid item container xs={12} md={6} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold" }}>Accounting</ListSubheader>} >
            {isAuditor ? null : <ListItem>
              <ListItemIcon>
                <img src={journalEntry} alt="Purchase-Invoice" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/journalentries")}
              >
                Journal Entry
              </Link>
            </ListItem>}
            {isAuditor ? null : <ListItem>
              <ListItemIcon>
                <img src={paymentEntry} alt="Rquest-Quotation" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/paymentsentries")}
              >
                Payment Entry
              </Link>
            </ListItem>}
            {isAuditor ? null : <ListItem>
              <ListItemIcon>
                <img src={debitNote} alt="Supplier-Quotation" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/suppliers?from=debitnote")}
              >
                Debit Note
              </Link>
            </ListItem>}
            {isAuditor ? null : <ListItem>
              <ListItemIcon>
                <img src={creditNote} alt="Supplier-Purchase-Orders" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/customers?from=creditnote")}
              >
                Credit Note
              </Link>
            </ListItem>}
            <ListItem>
              <ListItemIcon>
                <img src={bankStatementUpload} alt="bank-account-statement" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/bankaccountstatementimportlist")}
              >
                Bank Statement - Upload
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={bankReconciliationStatement} alt="bank-account-statement" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/bankReconciliationStatement")}
              >
                Bank Reconciliation Statement Report
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>
    </div>
  );
};

export default AccountHome;
