import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  FormControl,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Checkbox,
  Select,
  TableRow,
  Paper,
  CircularProgress,
  Box
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import UserForm from "./UserForm";
import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

import { currentUser } from "../login/loginSlice";
import { roles } from "../common/lookupSlice";
import { API_ALL_USERS } from "../../config/constants";

import {
  openModal,
  closeModal,
  modalInProcessing,
} from "../common/confirmationDialogSlice";

import "./users.css";
let deleteUser = null;

const Users = () => {
  const userRoles = useSelector(roles);
  const mode = ""; //"MODAL";
  const pageSize = 10;
  const loggedInUser = useSelector(currentUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const [count, setCount] = useState();
  const [userForm, setUserForm] = useState({ open: false });
  const [searchRole, setSearchRole] = useState("");
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailOrUsernameRef = useRef();
  const mobileNumberRef = useRef();
  const token = localStorage.getItem("authToken");

  const getRoleNames = (roles) => {
    if(roles && roles.length>1){
      let findPrice = roles.find((role) => {return role.name === "PRICING ADMIN"});
      return [`${findPrice.description}`]
    }else{
      return roles.map((role) => role.description);
    }
  };

  const resetQuery = () => {
    firstNameRef.current.value = "";
    lastNameRef.current.value = "";
    emailOrUsernameRef.current.value = "";
    mobileNumberRef.current.value = "";
    setSearchRole("");
    setQuery({
      ...query,
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      emailOrUsername: emailOrUsernameRef.current.value,
      mobileNumber: mobileNumberRef.current.value,
      role: "",
      page: 1,
    });
  };

  const performDelete = async () => {
    dispatch(modalInProcessing());
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_USERS}/${deleteUser.userName}`,
        {
          isDeleted: deleteUser.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(closeModal());
      setRefresh(true);
      deleteUser = null;
    } catch (err) {
      setIsError(true);
    }
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_USERS}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.users);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const processDelete = (userToDelete) => {
    if (userToDelete) {
      deleteUser = userToDelete;
      const dialogText = userToDelete.value
        ? "Are you sure to deactivate this user ?"
        : "Are you sure to activate this user ?";
      dispatch(
        openModal({
          dialogText: dialogText,
          secondayBtnText: "Yes",
          primaryBtnText: "Cancel",
          handleCloseModal: handleCloseModal,
        })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      firstName: e.target.elements.firstName.value,
      lastName: e.target.elements.lastName.value,
      emailOrUsername: e.target.elements.emailOrUsername.value,
      mobileNumber: e.target.elements.mobileNumber.value,
      role:
        e.target.elements.role.value !== "All"
          ? e.target.elements.role.value
          : "",
      page: 1,
    });
  };

  // On success of add or edit, reset the query to load data again
  useEffect(() => {
    if (userForm.success) {
      resetQuery();
    }
  }, [userForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = (value) => {
    if (value) {
      performDelete();
    } else {
      dispatch(closeModal());
    }
  };

  return (
    <div>
      <ConfirmationDialog onClose={handleCloseModal} />
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
                Users
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justify="flex-end"
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUserForm({ ...userForm, data: {}, mode: "C", open: true });
              }}
            >
              Add User
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Typography variant="h6">User Search</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">First Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={firstNameRef}
                    InputProps={{
                      name: "firstName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Last Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={lastNameRef}
                    InputProps={{
                      name: "lastName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Email or UserName</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={emailOrUsernameRef}
                    InputProps={{
                      name: "emailOrUsername",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Mobile Number</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={mobileNumberRef}
                    InputProps={{
                      type: "number",
                      name: "mobileNumber",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Role</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ display: "flex" }}
                >
                  <Select
                    name="role"
                    style={{ display: "flex" }}
                    value={searchRole}
                    onChange={(e) => {
                      setSearchRole(e.target.value);
                      setQuery({
                        ...query,
                        role: e.target.value !== "All" ? e.target.value : "",
                        page: 1,
                      });
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {userRoles.map(function (role) {
                      return (
                        <MenuItem key={role.id} value={role.name}>
                          {role.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container alignItems="center" md={2} xs={6} spacing={1}>
              <Grid item>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    Search
            </Button></Box></Grid>
              <Grid item>
                <Box mt={2}>
                  <Button size="small" type="reset" onClick={() => resetQuery()}
                    variant="contained" color="secondary">
                    clear</Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>FirstName</StyledHeaderCell>
                <StyledHeaderCell align="left">LastName</StyledHeaderCell>
                <StyledHeaderCell align="left">Email</StyledHeaderCell>
                <StyledHeaderCell align="left">User Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Mobile Number</StyledHeaderCell>
                <StyledHeaderCell align="left">Role</StyledHeaderCell>
                <StyledHeaderCell align="left">Active</StyledHeaderCell>
                {mode === "MODAL" ? null : (
                  <StyledHeaderCell align="center">Action</StyledHeaderCell>
                )}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row) => (
                        <TableRow key={row.id} hover={true}>
                          <TableCell component="th" scope="row">
                            {row.firstName}
                          </TableCell>
                          <TableCell align="left">{row.lastName}</TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">{row.userName}</TableCell>
                          <TableCell align="left">{row.mobileNumber}</TableCell>
                          <TableCell align="left">
                            {row.roles && getRoleNames(row.roles).toString()}
                          </TableCell>
                          <TableCell align="left">
                            <Checkbox
                              disabled={
                                row.userName === loggedInUser.userName ||
                                mode === "MODAL"
                              }
                              color="secondary"
                              checked={!row.isDeleted}
                              onChange={() => {
                                processDelete({
                                  userName: row.userName,
                                  value: !row.isDeleted,
                                });
                              }}
                            />
                          </TableCell>
                          {mode === "MODAL" ? null : (
                            <TableCell align="center">
                              <IconButton
                                onClick={() => {
                                  setUserForm({
                                    ...userForm,
                                    data: row,
                                    mode: "U",
                                    open: true,
                                  });
                                }}
                              >
                                <EditIcon color="secondary" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
          <div />
        )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={userForm.open}
        onClose={() => {
          setUserForm({ ...userForm, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={6} alignItems="center">
              <Grid item>
                {userForm.mode === "C" ? "Add User" : "Edit User"}
              </Grid>
            </Grid>
            <Grid item container xs={6} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setUserForm({ ...userForm, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <UserForm
            form={userForm}
            cancel={() => {
              setUserForm({ ...userForm, open: false });
            }}
            success={() => {
              setUserForm({ ...userForm, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={userForm.success}
        onClose={() => setUserForm({ ...userForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setUserForm({ ...userForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {userForm.mode === "C"
            ? "User created successfully"
            : "User updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Users;
