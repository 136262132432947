import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import productJobImg from "../../images/Product 50x50.svg";
import inventoryjobImg from "../../images/Inventory 50x50.svg";
import customerjobImg from "../../images/Customer 50x50.svg";
import salesjobImg from "../../images/Sales new 50x50.svg";
import purchasejobImg from "../../images/Purchase 50x50.svg";
import supplierjobImg from "../../images/Supplier 50x50.svg";
import moment from "moment";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { currentUser } from "../login/loginSlice";

const DailyInboundJobSync = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isInvPopup, setInvPopup] = useState(false);
  const [pData, setPData] = useState(null);
  const [invData, setInvData] = useState(null);
  const [custData, setCustData] = useState(null);
  const [supData, setSupData] = useState(null);
  const [salesData, setSalesData] = useState(null);
  const [purchaseData, setPuchaseData] = useState(null);
  const loggedInUser = useSelector(currentUser);
  const allInventoryLocations = loggedInUser && loggedInUser.locations ? loggedInUser.locations : [];
  const inventoryLocations = allInventoryLocations.filter((loc) => { return loc.type !== "CORPORATE" && loc.GSTIN && loc.ERPWarehouseId });


  const { handleSubmit, control, errors } = useForm();
  const getRecentBatchJob = async () => {
    const result = await axios.get(`${process.env.REACT_APP_SERVICE_URL}/batch/recntbatchjob?syncType=inbound`);
    if (result && result.data && result.data.batchJob) {
      setPData(result.data.batchJob.product);
      setInvData(result.data.batchJob.productInv);
      setCustData(result.data.batchJob.customer);
      setSupData(result.data.batchJob.supplier);
      setSalesData(result.data.batchJob.voucher);
      setPuchaseData(result.data.batchJob.purchase);
    }
  }
  useEffect(() => {
    // Run! Like go get some data from an API.
    getRecentBatchJob()
  }, []);

  let onProductJobClick = async () => {
    setIsLoading(true)
    await axios.post(`${process.env.REACT_APP_SERVICE_URL}/batch/inbound/products`);
    getRecentBatchJob()
    setIsLoading(false)
  }
  let onInvJobClick = async () => {
    setInvPopup(true)
  }
  let onClosePopupClick = async () => {
    setInvPopup(false);
  }
  let onRunJob = async (data) => {
    setInvPopup(false);
    setIsLoading(true)
    await axios.post(`${process.env.REACT_APP_SERVICE_URL}/batch/inbound/productinventory?destinationLocationId=${data.destLocation}&&userId=${loggedInUser.id}`);
    getRecentBatchJob()
    setIsLoading(false)
  }
  let onCustomerJobClick = async () => {
    setIsLoading(true)
    await axios.post(`${process.env.REACT_APP_SERVICE_URL}/batch/inbound/customer`);
    getRecentBatchJob()
    setIsLoading(false)
  }
  let onSupplierJobClick = async () => {
    setIsLoading(true)
    await axios.post(`${process.env.REACT_APP_SERVICE_URL}/batch/inbound/supplier`);
    getRecentBatchJob()
    setIsLoading(false)
  }
  let onSalesJobClick = async () => {
    setIsLoading(true)
    // await axios.get(`${process.env.REACT_APP_SERVICE_URL}/batch/products`);
    getRecentBatchJob()
    setIsLoading(false)
  }
  let onPurchaseJobClick = async () => {
    setIsLoading(true)
    // await axios.get(`${process.env.REACT_APP_SERVICE_URL}/batch/products`);
    getRecentBatchJob()
    setIsLoading(false)
  }

  let onHistoryClick = async (jobType) => {
    window.location.href = `/jobhistory?jobType=${jobType}&&parent=inbound`;
  }
  return (
    <div>
      {isLoading ? <div className="loader">
        <CircularProgress />
      </div> : null}
      <div>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/datamanagementhome")}>
                Data Management
              </Link>
              <Typography component="span" color="textPrimary" style={{ fontWeight: 'bold' }}>
                Inbound Jobs
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </div >
      <div style={{ paddingTop: 10, pointerEvents: (isLoading) ? "none" : "auto" }} >
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Inbound Jobs</StyledHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Grid container>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Products
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={productJobImg}
                            alt="product job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(pData && pData.createdAt) ? moment(pData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}</Typography>
                          <Typography>Product in file : {pData && pData.totalItem}</Typography>
                          <Typography>Newly created products : {pData && pData.newItem}</Typography>
                          <Typography>Updated products : {pData && pData.updatedItem}</Typography>
                          <Typography>Status : {(pData && pData.status) ? <span style={{ color: (pData.status === "SUCCESS") ? "green" : "red" }}>{pData.status}</span> : ""}</Typography>
                          <Typography>Errors : {pData && pData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(pData && pData.message) ? pData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                            onClick={() => { onHistoryClick("INBOUND_PRODUCT") }}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onProductJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Inventory
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={inventoryjobImg}
                            alt="Inv job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(invData && invData.createdAt) ? moment(invData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}</Typography>
                          <Typography>Product Inventory in file : {invData && invData.totalItem}</Typography>
                          <Typography>Newly created inventory : {invData && invData.newItem}</Typography>
                          <Typography>Updated inventory : {invData && invData.updatedItem}</Typography>
                          <Typography>Status : {(invData && invData.status) ? <span style={{ color: (invData.status === "SUCCESS") ? "green" : "red" }}>{invData.status}</span> : ""}</Typography>
                          <Typography>Errors : {invData && invData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(invData && invData.message) ? invData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                            onClick={() => { onHistoryClick("INBOUND_INVENTORY") }}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onInvJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ paddingTop: 10, pointerEvents: (isLoading) ? "none" : "auto" }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableBody>
              <Grid container>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Customer
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={customerjobImg}
                            alt="customer job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(custData && custData.createdAt) ? moment(custData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}</Typography>
                          <Typography>Customer in file : {custData && custData.totalItem}</Typography>
                          <Typography>Newly created customer : {custData && custData.newItem}</Typography>
                          <Typography>Updated customer : {custData && custData.updatedItem}</Typography>
                          <Typography>Status : {(custData && custData.status) ? <span style={{ color: (custData.status === "SUCCESS") ? "green" : "red" }}>{custData.status}</span> : ""}</Typography>
                          <Typography>Errors : {custData && custData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(custData && pData.message) ? custData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                            onClick={() => { onHistoryClick("INBOUND_CUSTOMER") }}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onCustomerJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Supplier
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={supplierjobImg}
                            alt="supplier job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(supData && supData.createdAt) ? moment(supData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}</Typography>
                          <Typography>Supplier in file : {supData && supData.totalItem}</Typography>
                          <Typography>Newly created supplier : {supData && supData.newItem}</Typography>
                          <Typography>Updated supplier : {supData && supData.updatedItem}</Typography>
                          <Typography>Status : {(supData && supData.status) ? <span style={{ color: (supData.status === "SUCCESS") ? "green" : "red" }}>{supData.status}</span> : ""}</Typography>
                          <Typography>Errors : {supData && supData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(supData && supData.message) ? supData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                            onClick={() => { onHistoryClick("INBOUND_SUPPLIER") }}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onSupplierJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ paddingTop: 10, pointerEvents: (isLoading) ? "none" : "auto" }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableBody>
              <Grid container>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Sales
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={salesjobImg}
                            alt="sales job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(salesData && salesData.createdAt) ? moment(salesData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}</Typography>
                          <Typography>Sales in file : {salesData && salesData.totalItem}</Typography>
                          <Typography>Newly created Sales : {salesData && salesData.newItem}</Typography>
                          <Typography>Updated Sales : {salesData && salesData.updatedItem}</Typography>
                          <Typography>Status : {(salesData && salesData.status) ? <span style={{ color: (salesData.status === "SUCCESS") ? "green" : "red" }}>{salesData.status}</span> : ""}</Typography>
                          <Typography>Errors : {salesData && salesData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(salesData && salesData.message) ? salesData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                            onClick={() => { onHistoryClick("INBOUND_SALE") }}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onSalesJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Purchase
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={purchasejobImg}
                            alt="purchase job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(purchaseData && purchaseData.createdAt) ? moment(purchaseData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}</Typography>
                          <Typography>Purchase in file : {purchaseData && purchaseData.totalItem}</Typography>
                          <Typography>Newly created purchase : {purchaseData && purchaseData.newItem}</Typography>
                          <Typography>Updated purchase : {purchaseData && purchaseData.updatedItem}</Typography>
                          <Typography>Status : {(purchaseData && purchaseData.status) ? <span style={{ color: (purchaseData.status === "SUCCESS") ? "green" : "red" }}>{purchaseData.status}</span> : ""}</Typography>
                          <Typography>Errors : {purchaseData && purchaseData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(purchaseData && purchaseData.message) ? purchaseData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                            onClick={() => { onHistoryClick("INBOUND_PURCHASE") }}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onPurchaseJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isInvPopup}
        onClose={() => { }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Selecting Location for Stock Entry
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={onClosePopupClick}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '150px' }}>
          <form onSubmit={handleSubmit(onRunJob)} style={{ marginTop: '-10px' }}>
            <Grid container>
              <Grid xs={6}>
                <Typography style={{ width: '100%' }}>
                  <Box variant="caption" style={{ fontWeight: "bold" }}>Location *</Box>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={Boolean(errors.destLocation)}
                  >
                    <Controller
                      as={
                        <Select style={{ color: "black" }}>
                          {inventoryLocations.map(function (locality) {
                            return (
                              <MenuItem key={locality} value={locality.id}>
                                {locality.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      }
                      name="destLocation"
                      size="small"
                      variant="outlined"
                      rules={{ required: "Location is required" }}
                      control={control}
                    />
                  </FormControl>
                  <FormHelperText error>
                    {errors.destLocation && errors.destLocation.message}
                  </FormHelperText></Typography>

              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                size="small"
                type="submit"
                variant="contained"
                style={{ marginRight: "10px" }}
              >
                Run Job
              </Button>
              <Button
                onClick={onClosePopupClick}
                color="secondary"
                size="small"
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DailyInboundJobSync;
