import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  CircularProgress,
  FormHelperText
} from "@material-ui/core";
import { useForm } from "react-hook-form";

const DeleteSale = (props) => {
  const token = localStorage.getItem("authToken");
  const { handleSubmit, errors, register } = useForm({
    defaultValues: {
      reason: props.reason,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    props.cancel()
  }
  const handleSave = async (data) => {
    setIsLoading(true);
    try {
      const payload = {
          "reason": data.reason,
        };

      //put req to soft delete the sale invoice to db
      const result = await axios.put(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/delete/${props.saleId}`,
          payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resStatus = result && result.data && result.data.status;
      setIsLoading(false)
      if(resStatus==="SUCCESS"){
      props.setMessage(result && result.data && result.data.message)
      props.setIsSuccess(true)
      !(props.report) && props.setIsSaved(true);
      !(props.report) && props.setDeleteSuccess(true);      
      props.cancel();
    }
    else{
      props.setMessage(result && result.data && result.data.message)
      props.setIsError(true)
      props.cancel()
    }
    }
    catch (err) {
      setIsLoading(false)
      props.setIsError(true)
      props.cancel()
    }

  }
  return (
    <div>
      <Grid item md={12} xs={12}>
        <FormControl component="fieldset" fullWidth>
              <TextField
                error={Boolean(errors.reason)}
                variant="outlined"
                fullWidth
                size="small"
                name="reason"
                inputRef={register({
                  required: "Enter reason for cancel sale",
                })}
              />
              <FormHelperText error>
              {errors.reason && errors.reason.message}
              </FormHelperText>
        </FormControl>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item xs={12}>
            <Box mt={4} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                {isLoading && <CircularProgress />}
              </Box>
              <Box mr={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(handleSave)}
                >
                  Save Reason
            </Button></Box>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                >
                  Cancel
            </Button></Box>
            </Box>
          </Grid>
        </Grid>
      </ Grid>
    </div>
  );
};

export default DeleteSale;
