import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Typography,
  TextField,
  Snackbar,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  Radio,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";

import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import { useForm } from "react-hook-form";

const ManageProducts = (props) => {
  const { register, getValues } = useForm()
  const pageSize = 6;
  const [count, setCount] = useState();
  const [product, setProduct] = useState(null);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      let result;
      let finaliZedQuery = (query) ? JSON.parse(JSON.stringify(query)) : {};
      finaliZedQuery.productName = search;
      if (props.lineIdentifier) {
        const custTypeId = props.addonData ? props.addonData.customerType : 1
        const locationId = props.addonData ? props.addonData.locationId : 1
        finaliZedQuery.custTypeId = custTypeId;
        finaliZedQuery.locationId = locationId;
        result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/pos/products`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: finaliZedQuery,
          }
        );
      }
      else {
        result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/products`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: finaliZedQuery,
          }
        );
      }
      setCount(result.data.count);
      setData(result.data.products);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = async() => {
    let existing = false;
    if (props.exist) {
      const lineItems = props.getValues()
      const lineItemskeys = Object.keys(lineItems);
      const productIdLineItem = lineItemskeys.filter(x => x.includes("productId"))
      const productIds = [];
      productIdLineItem.forEach(x => {
        productIds.push(lineItems[x])
      })
      existing = productIds.some(x => product.product && Number(x) === product.product.id)
    }

    if (existing) {
      setIsWarning(true)
    }
    else {
      if (product) {
        if (props.lineIdentifier) {
          const productCodeKey = `lineItems[${props.form.lineKey}].productCode`;
          const availableQtyKey = `lineItems[${props.form.lineKey}].availableQty`;
          const fullfilledQtyKey = `lineItems[${props.form.lineKey}].fulfilledQty`;
          const productIdKey = `lineItems[${props.form.lineKey}].productId`;
          const productPrice = `lineItems[${props.form.lineKey}].negotiatedPrice`;
          const isQtyDecimal = `lineItems[${props.form.lineKey}].isQtyDecimal`;
          const uom = `lineItems[${props.form.lineKey}].uom`;
          const orderQty = `lineItems[${props.form.lineKey}].orderQty`;

          props.setValue(productPrice, product.product.price ? (Math.round(product.product.price * 100) / 100).toFixed(2) : '0.00') //set the product price if we are getting it from api
          props.setValue(productCodeKey, product.product.productName)
          props.setValue(isQtyDecimal, product.product.isQtyDecimal ? true : false)
          props.setValue(availableQtyKey, product.availableQty ? product.availableQty : 0)
          props.setValue(productIdKey, product.product.id)
          if (props.isSupplierQuotationReq || props.isMaterialReq) {
            props.setValue(uom, product.product.uom);
          }
          if (props.isSupplierPO) {
            props.setValue(orderQty, 0);
          }else{
            props.setValue(fullfilledQtyKey, product.fulfilledQty ? product.fulfilledQty : 0)
          }
        }
        else {
          props.setValue("productId", product.productName);
          product.uom && props.setUom && props.setUom(product.uom);
          props.setProdId(product.id);
        }
        if (props.isSupplierQuotationReq) {
          let jsonData = JSON.parse(JSON.stringify(props.getValues()))
          const lineItemsArray = [];

          for (const key in jsonData) {
            if (key.startsWith("lineItems")) {
              const index = parseInt(key.match(/\d+/)[0], 10); // Extract index from key
              const lineItemTest = {
                contactId: jsonData[`lineItems[${index}].contactId`],
                productCode: jsonData[`lineItems[${index}].productCode`],
                productId: jsonData[`lineItems[${index}].productId`],
                requiredQty: jsonData[`lineItems[${index}].requiredQty`],
                uom: jsonData[`lineItems[${index}].uom`]
              };
              lineItemsArray.push(lineItemTest);
            }
          }
          const uniqueProductIds = new Set();
          // Filter lineItemsArray to get unique line items based on productId
          const uniqueLineItems = lineItemsArray.filter(item => {
            if (uniqueProductIds.has(item.productId)) {
              return false; // If productId already exists, discard this item
            } else {
              uniqueProductIds.add(item.productId); // Add productId to the Set
              return true; // Include this item in the unique array
            }
          });
          let apiResProductIds = uniqueLineItems && uniqueLineItems.length > 0 && uniqueLineItems.map((prod) => {
            return prod.productId;
          })
          if (apiResProductIds && apiResProductIds.length > 0) {
            const supplierResData = await axios.get(
              `${process.env.REACT_APP_SERVICE_URL}/suppliers/turn/supplieritems`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                params: {productIds: `${(apiResProductIds)}`},
              }
            );
            props.setSupplierData(supplierResData.data.supplier);
          }
        }
        props.success(product.id);
      }
      else {
        props.cancel()
      }
    }
  }


  const handleKeyDownProductName = (event) => {
    if (event.key === 'Enter') {
      setSearch(getValues('productId'));
    }
  };

  return (
    <div>
      <Grid item md={6} xs={12}>
        <Typography variant="caption">Product Name</Typography>
        <TextField
          variant="outlined"
          fullWidth
          inputRef={register({})}
          InputProps={{
            name: "productId",
            autoFocus: true,
            endAdornment: (
              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                <SearchIcon onClick={() => { setSearch(getValues('productId')) }} />
              </InputAdornment>
            ),
          }}
          size="small"
          name="productId"
          onKeyDown={handleKeyDownProductName}
        />
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="center">Select</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Name</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      style={{ cursor: props.modal ? "pointer" : "", }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }
                    >
                      <TableCell component="th" scope="row">
                        <Radio
                          // checked={props.lineIdentifier ? 
                          //   product && product.product ? product.product.id === row.product.id : 
                          //   props.form.create ? "":
                          //   (props.form && props.form.lineItems && (props.form.lineItems[props.form.lineKey]) && (props.form.lineItems[props.form.lineKey]).product.id === row.product.id):
                          //  product && product.id === row.id}

                          checked={props.lineIdentifier ?
                            product && product.product ? product.product.id === row.product.id :
                              props.form.create ? "" :
                                "" :
                            product && product.id === row.id}

                          onChange={() => setProduct(row)}
                          value={props.lineIdentifier ? row.product.id : row.id}
                          name="productRadio"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {row.productName || (row.product && row.product.productName)}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>

      ) : (
        <div />
      )}
      <Grid
        item
        container
        xs={12}
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <Grid item>
          <Box mt={8}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isWarning}
        autoHideDuration={5000}
        onClose={() => setIsWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          Product already exists in the list
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ManageProducts;
