import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import {
  Typography,
  Breadcrumbs,
  Link,
  FormControl,
  TextField,
  Snackbar,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  CardHeader,
  Card,
  Checkbox
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import { formatDate } from '../common/util';
import moment from 'moment';
import {
  locations
} from "../common/lookupSlice";

import { API_INVENTORY } from "../../config/constants";
import Ok from '../../images/Ok.svg';
import Reconcile from '../../images/Reconcile.svg';
import { jsonToCsv, downloadCSVFile } from '../common/util';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useForm, Controller } from "react-hook-form";

class CompToPrint extends React.Component {
  render() {
    return (
      <Table style={{ paddingTop: 10 }} size="small">
        <TableHead>
          <TableRow>
            <StyledHeaderCell>Location</StyledHeaderCell>
            <StyledHeaderCell align="left">Product Name</StyledHeaderCell>
            <StyledHeaderCell align="left">Product Code</StyledHeaderCell>
            <StyledHeaderCell align="left">Safety Stock</StyledHeaderCell>
            <StyledHeaderCell align="left">Count</StyledHeaderCell>
            <StyledHeaderCell align="left">UOM</StyledHeaderCell>
            <StyledHeaderCell align="left">Last Verified</StyledHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.inventoryData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography variant="subtitle1">
                  No records found
                      </Typography>
              </TableCell>
            </TableRow>
          ) : (
              this.props.inventoryData.map((row, index) => (
                <TableRow
                  key={row.id}
                  hover={true}
                >
                  <TableCell component="th" scope="row">
                    {row.Location}
                  </TableCell>
                  <TableCell align="left">
                    {row.ProductName}
                  </TableCell>
                  <TableCell align="left">
                    {row.ProductCode}
                  </TableCell>
                  <TableCell align="right">
                    {row.SafetyStock}
                  </TableCell>                  
                  <TableCell align="right">
                    {row.Count}
                  </TableCell>
                  <TableCell align="left">
                    {row.UOM}
                  </TableCell>
                  <TableCell align="left">
                    {row.LastVerified ? formatDate(row.LastVerified) : ''}
                  </TableCell>
                </TableRow>
              ))
            )}
        </TableBody>
      </Table>
    );
  }
}

const Inventory = (props) => {
  const { control } = useForm({});
  const componentRef = useRef();
  const inventoryLocations = useSelector(locations);
  const locationNames = inventoryLocations ? inventoryLocations.map(loc => loc.name) : [];
  const pageSize = 10;
  const history = useHistory();
  const location = useLocation();
  const id = location.state && location.state.id ? location.state.id : null;
  const [count, setCount] = useState();
  const itemNameRef = useRef();
  let defaultLoc = inventoryLocations.filter(x => x.id === id)
  const [value, setValue] = React.useState(id && defaultLoc.length > 0 ? defaultLoc[0].name : inventoryLocations && inventoryLocations[0] ? inventoryLocations[0].name : '');
  const [inputValue, setInputValue] = React.useState('');
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize, locationId: id ? id : inventoryLocations && inventoryLocations[0] ? inventoryLocations[0].id : '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [oss, setOss] = useState(false);
  const [success, setSuccess] = useState(false)
  const token = localStorage.getItem("authToken");
  const [inventoryData, setInventoryData] = useState([])
  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_INVENTORY}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.rows);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (success) {
      loadData();
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductClick = (name, id, uom) => {
    history.push({
      pathname: '/inventoryProduct',
      state: { id: name, prodId: id, uom: uom }
    })
  }

  const handleOss = () => {
    setQuery({
      ...query,
      OOSCheck: !oss ? true : undefined,
      page: 1,
    });
    setOss(!oss)
  }

  const handleReset = () => {
    setQuery({
      ...query,
      OOSCheck: undefined,
      productName: undefined,
      page: 1,
    });
    setOss(false)
    itemNameRef.current.value = null;
  }

  const handleOkClick = async (id) => {
    setIsLoading(true);
    try {
      const inventoryData = {
        lastVerfiedOn: new Date()
      }
      await axios.patch(
        `${process.env.REACT_APP_SERVICE_URL}/inventory/${id}`,
        inventoryData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess(true)
    }
    catch (err) {
      setIsError(true)
    }
    setIsLoading(false);
  }

  const enableClick = (date) => {
    const verifiedDate = moment(date);
    const today = moment();
    const isToday = today.isSame(verifiedDate, 'day');
    return isToday;
  }

  const handleDownload = async () => {
    try {
      const data = await getInventoryData();
      let location = data && data[0].Location;
      const csvData = jsonToCsv(data)
      downloadCSVFile(csvData, `inventoryLocation_${location ? location.replace(/\s/g, "") : ''}`)
    } catch (err) {
      setIsError(true);
    }
  }

  const handlePrint = async () => {
    try {
      const data = await getInventoryData();
      setInventoryData(data);
    } catch (err) {
      setIsError(true);
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      productName: e.target.elements.itemName.value,
      page: 1,
    });
  };

  const getInventoryData = async () => {
    const clonedQuery = _.cloneDeep(query);
    delete clonedQuery.page;
    delete clonedQuery.pageSize;
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/${API_INVENTORY}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: clonedQuery,
      }
    );
    const inventoryDatas = result.data && result.data.rows.map(inventory => {
      return {
        Location: inventory.location.name,
        ProductName: inventory.product.productName,
        ProductCode: inventory.product.productCode,
        SafetyStock: inventory.safetyStock,
        Count: inventory.openingQty,
        UOM: inventory.product.uom,
        LastVerified: inventory.lastVerfiedOn
      }
    });
    return inventoryDatas;
  }

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
                </Link>
              <Link color="inherit" href="" onClick={() => history.push("/inventory")}>
                Inventory
                </Link>
              <Typography component="span" color="textPrimary">
                Inventory Location- Reconcile
                </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Location</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete size="small"
                  value={value}
                  onChange={(event, newValue) => {
                    const locIndex = event.target.getAttribute("data-option-index");
                    const inventoryId = locIndex ? inventoryLocations[locIndex].id : inventoryLocations[0].id;
                    setQuery({
                      ...query,
                      locationId: inventoryId,
                    });
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={locationNames}
                  disableClearable
                  renderInput={(params) => <TextField {...params}
                    variant="outlined"
                    name="loc"
                  />}
                />
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={itemNameRef}
                    InputProps={{
                      name: "itemName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Box mt={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Search
            </Button>
              </Box>
              <Box mt={2} ml={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleReset()}
                >
                  Clear
            </Button>
              </Box>
            </Grid>

            <Grid item container justify={"flex-end"} md={3} xs={6}>
              <Grid item>
                <Box mt={2} mr={4}>
                  <ReactToPrint
                    onBeforeGetContent={handlePrint}
                    trigger={() => <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<PrintIcon />}
                    >
                      Print
    </Button>}
                    content={() => componentRef.current}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box mt={2}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Download
            </Button></Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={6} xs={12}>
            <Box ml={-2}>
              <Controller
                as={Checkbox}
                name="oos"
                type="checkbox"
                onChange={() => handleOss()}
                checked={oss}
                control={control}
              /></Box>
            <Box mt={1.3}>
              <Typography>Filter items below safety stock</Typography>
            </Box>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <Card>
          <CardHeader title={
            (<Typography display="block" variant="overline" style={{ fontWeight: "Bold", marginBottom: "-11px" }}>
              Location : {value}
              &nbsp;&nbsp;&nbsp;&nbsp;
              { query.productName && `Filtered by product: ${query.productName}`}
            </Typography>)} />
          <TableContainer component={Paper} >
            <Table style={{ paddingTop: 10 }} size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell>Product Name</StyledHeaderCell>
                  <StyledHeaderCell align="left">Product Code</StyledHeaderCell>
                  <StyledHeaderCell align="left">Safety Stock</StyledHeaderCell>
                  <StyledHeaderCell align="left">Product Count</StyledHeaderCell>
                  <StyledHeaderCell align="left">UOM</StyledHeaderCell>
                  <StyledHeaderCell align="left">Last Verified</StyledHeaderCell>
                  <StyledHeaderCell align="left">Action</StyledHeaderCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                  <TableBody>
                    {data.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={props.modal ? 6 : 7} align="center">
                          <Typography variant="subtitle1">
                            No records found
                      </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                        data.map((row, index) => (
                          <TableRow
                            style={{ cursor: props.modal ? "pointer" : "" }}
                            key={row.id}
                            hover={true}
                            onClick={(e) =>
                              props.onRowSelect && props.onRowSelect(row)
                            }
                          >
                            <TableCell component="th" scope="row">
                              {row.product && row.product.productName}
                            </TableCell>
                            <TableCell align="left">
                              {row.product && row.product.productCode}
                            </TableCell>
                            <TableCell align="right">
                              {row.safetyStock}
                            </TableCell>
                            <TableCell align="right">
                              {row.openingQty}
                            </TableCell>
                            <TableCell align="left">
                              {row.product && row.product.uom}
                            </TableCell>
                            <TableCell align="left">
                              {row.lastVerfiedOn && formatDate(row.lastVerfiedOn)}
                            </TableCell>
                            <TableCell align="left">
                              {enableClick(row.lastVerfiedOn) || (row.location && row.location.type && row.location.type.code === "CORPORATE") ?
                                <img style={{ opacity: 0.5, cursor: "pointer" }} src={Ok} alt="ok disable" />
                                :
                                <img src={Ok} style={{ cursor: "pointer" }} onClick={() => handleOkClick(row.id)} alt="ok disable" />
                              }
                            &nbsp;&nbsp;&nbsp;
                             <img src={Reconcile} style={{ cursor: "pointer" }} alt="reconcile" onClick={() => handleProductClick(row.product && row.product.productName, row.product && row.product.id, row.product && row.product.uom)} />
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                  </TableBody>
                )}
            </Table>
          </TableContainer>
          <Box display="none">
            <CompToPrint ref={componentRef} inventoryData={inventoryData} /></Box>
        </Card>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
          <div />
        )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={success}
        onClose={() => setSuccess(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {
            "Verified successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Inventory;
