import React, { useState, useRef, useEffect } from "react";
import { renderToString } from 'react-dom/server'
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { jsonToCsv, downloadCSVFile, formatDate} from '../common/util';
import { useForm } from "react-hook-form";
import moment from 'moment';
import {
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
  IconButton,
  FormControl,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import CompToPrintInv from '../purchase/CompToPrintPurchaseInv';
import ReactToPrint from "react-to-print";
import {
  // invoiceTypes,
  // paymentModes,
  // locations,
  paymentTypes
} from "../common/lookupSlice";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { currentUser } from "../login/loginSlice";
import { bankDetails, termsAndConditions } from "../common/lookupSlice"
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MailIcon from '@material-ui/icons/Mail';
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
// import { "receiverEmailId" } from "../common/constants";

class CompToPrint extends React.Component {
  render() {
    return (
      <Table style={{ paddingTop: 10 }} size="small">
        <TableHead>
          <TableRow>
            <StyledHeaderCell>Invoice No</StyledHeaderCell>
            <StyledHeaderCell align="left">Supplier Name</StyledHeaderCell>
            <StyledHeaderCell align="left">Invoice Date</StyledHeaderCell>
            <StyledHeaderCell align="left">Invoice Total</StyledHeaderCell>
            <StyledHeaderCell align="left">Payment Type</StyledHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.supplierPurchase.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography variant="subtitle1">
                  No records found
                      </Typography>
              </TableCell>
            </TableRow>
          ) : (
              this.props.supplierPurchase.map((row, index) => (
                <TableRow
                  key={index}
                  hover={true}
                >
                  <TableCell component="th" scope="row">
                    {row.invoiceNo}
                  </TableCell>
                  <TableCell align="left">
                    {row.supplierName}
                  </TableCell>
                  <TableCell align="left">
                    {row.invoiceDate ? formatDate(row.invoiceDate):''}
                  </TableCell>
                  <TableCell align="right">
                    {row.invoiceTotal && (Math.round(row.invoiceTotal * 100) / 100).toFixed(2)}
                  </TableCell>
                  <TableCell align="left">
                    {row.paymentType}
                  </TableCell>
                </TableRow>
              ))
            )}
        </TableBody>
      </Table>
    );
  }
}

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};

class CompToPrintPurchase extends React.Component {
  render() {
    return (
      <div>
        <label style={{ textAlign: "left" }}>
          Hello, Here is the list of Supplier Sales. For more information, kindly login to the application.
        </label><br></br>
        <div style={divStyle} align="center">
        <table style={{ paddingTop: 10 }} size="small">
            <thead>
              <tr>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Invoice No.</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Supplier Name</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Invoice Date</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Invoice Total</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Payment Type</span></td>
              </tr>
            </thead>
            <tbody>
              {this.props.supplierPurchase.length === 0 ? (
                <tr>
                  <td colspan="9" align="center">
                    <span style={pStyle2}>No records found</span>
                  </td>
                </tr>
              ) : (
                this.props.supplierPurchase.map((row, index) => (
                  <tr
                    id={index}
                  >
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.invoiceNo}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.supplierName}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.invoiceDate && formatDate(row.invoiceDate)}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                      {row.invoiceTotal && (Math.round(row.invoiceTotal * 100) / 100).toFixed(2)}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.paymentType}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
      </div>
      </div>
    );
  }
}

const SupplierPurchases = (props) => {
  const componentRef = useRef();
  const componentRefInv = useRef();
  const custNameRef = useRef();
  // const payments = useSelector(paymentModes);
  const payTypes = useSelector(paymentTypes);
  // const invoices = useSelector(invoiceTypes);
  // let inventoryLocations = useSelector(locations);
  // inventoryLocations = inventoryLocations && inventoryLocations.length && inventoryLocations.filter((loc)=>{
  //   return loc && loc.type && loc.type.code && loc.type.code !== "CORPORATE"
  // })
  const pageSize = 10;
  const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const orgName = loggedInUser && loggedInUser.org && loggedInUser.org.name;
  const orgBankDetails = useSelector(bankDetails);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const [count, setCount] = useState();
  const [message, setMessage] = useState('');
  const [isSendInvoice, setSendInvoice] = useState(false);
  const [sendSale, setSendSale] = useState(false);
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [ emailSendData, setEmailSendData ] = useState({
    emailId:'',
    emailSubject:''
  });
  let  { emailId, emailSubject} = emailSendData;
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess,setIsSuccess]=useState(false)
  // const [typeId, setTypeId] = useState('');
  // const [paymentId,setPaymentId] = useState('')
  const [paymentTypeId,setPaymentTypeId] = useState('')
  // const [locationId,setLocation] = useState('')
  const [saleDate, setSaleDate] = useState({startDate:null,endDate:null});
  const [supplierPurchase, setSupplierPurchase] = useState([]);
  const [supplierPurInv, setSupplierInv] = useState([]);
  const token = localStorage.getItem("authToken");

  const { handleSubmit, errors, register } = useForm();

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseinvoice/invoice/lists`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.invoices);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    // e.preventDefault();
    setQuery({
      ...query,
      supplierName: custNameRef.current.value,
      paymentTypeId: paymentTypeId,
      startDate: saleDate.startDate ? moment(saleDate.startDate).format('DD/MM/YYYY'):"",
      endDate: saleDate.endDate ? moment(saleDate.endDate).format('DD/MM/YYYY'):"",
      page: 1,
    });
  };
  
  const handleDownload = async () => {
    try {
      const supplierPurchaseData = await getSupplierPurchases();
      let data;
      if(supplierPurchaseData.length === 0){
        data=''
      }
      else{
       data = jsonToCsv(supplierPurchaseData)
      }
      downloadCSVFile(data,"supplierPurchases")
    } catch (err) {
      setIsError(true);
    }
  }

  const getSupplierPurchases = async () => {
    const clonedQuery = _.cloneDeep(query);
    delete clonedQuery.page;
    delete clonedQuery.pageSize;
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseinvoice/invoice/lists`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: clonedQuery,
      }
    );
    const sales = result.data && result.data.invoices.map(sale => {
      return {
        id: sale.id,
        invoiceNo: sale.invoiceNum && sale.invoiceNum.toString(),
        supplierName: sale.supplier.fullName,
        invoiceDate: sale.invoiceDate,
        invoiceTotal: sale.total,
        paymentType: sale.paymentType && sale.paymentType.description
      }
    });
    return sales;
  }

  const getSupplierInvoice = async (invNo) => {
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/supplierpurchaseinvoice/${invNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const sales = result.data && result.data;
    return sales;
  }

  const handlePrint = async () => {
    try {
      const data = await getSupplierPurchases();
      setSupplierPurchase(data);
    } catch (err) {
      setIsError(true);
    }
  }

  const handleOpenOrderMail = async () => {
    try {
      const data = await getSupplierPurchases();
      setSupplierPurchase(data);
      setSendSale(true);
      setEmailSendData({ emailId: "", emailSubject: `${orgName} - Purchase Invoice` });
    } catch (err) {
      setIsError(true);
    }
  }

  const handlePrintInv = async (invNo) => {
    try {
      const data = await getSupplierInvoice(invNo);
      setSupplierInv(data);      
    } catch (err) {
      setIsError(true);
    }
  }

  const handleDateChange = (value,field) => {
    const clonedDate = _.cloneDeep(saleDate);
    clonedDate[field] = value;
    setSaleDate(clonedDate)
  };

  const handleReset = (e) =>{
    window.location.reload();
    // setTypeId('');
    // setPaymentId('');
    // setSaleDate({startDate:null,endDate:null});
    // setQuery({ page: 1, pageSize: pageSize })
  }

  const openEmailInovice = async (fData) =>{
    try {
      const data = await getSupplierInvoice(fData&&fData.invoiceNum);
      setSupplierInv(data);
      setSendInvoice(true);
      setEmailSendData({
        emailSubject: `${orgName} - Purchase Invoice`,
        emailId: fData&&fData.supplier && fData.supplier.email
      })
     } catch (err) {
      setIsError(true);
     }
    }
  const onCloseSendInvoice = () =>{
     setSendInvoice(false);
     setSendSale(false);
     setEmailSendData({emailId:'',emailSubject:''})
    }
  const onSendEmailInvoice =async () =>{
        let isEmail = false;
        let isSubject = (emailSubject&&emailSubject.length>1)?true:false;
        if(emailId&&emailId.length>0){
          isEmail = true;
          let emailIds = emailId.split(';');
          const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          _.forEach(emailIds,(data)=>{
            if (!mailformat.test(String(data).toLowerCase())){
              isEmail = false;
            }
          })
        }
        if(isEmail&&isSubject){
            setEmailHelperText(false);
            setEmailSubjectHelperText(false);
            let invoiceBodyData = "<div></div>"
            if (sendSale) {
              invoiceBodyData = () => {
                return (
                  <CompToPrintPurchase ref={componentRef} supplierPurchase={supplierPurchase} />
                )
              }
            } else {
              invoiceBodyData = () => {
                return (
                  <CompToPrintInv ref={componentRefInv} supplierPurInv={supplierPurInv} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions}/>
                )
              }}  
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('to',emailId)
            bodyFormData.append('subject',emailSubject)
            bodyFormData.append('invoice',renderToString(invoiceBodyData()))
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/x-www-form-urlencoded',
                'Content-Length': bodyFormData.length
              }
            }
            const result = await axios.post(
              `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
              bodyFormData,
              config
            );
            if(result.status===200){
              setIsSuccess(true)
              setMessage(result.data&&result.data.message)
              setSendInvoice(false);
              setSendSale(false);
              setEmailSendData({emailId:''})
            }
        }else{
          if(!isEmail){
            setEmailHelperText(true);
          }
          if(!isSubject){
            setEmailSubjectHelperText(true);
          }
        }
    }

    
  const dateValidation=()=>{
    let sDate = saleDate.startDate instanceof Date;
      let eDate = saleDate.endDate instanceof Date;
      if(!sDate && !(saleDate.startDate === null) ){
        var startDateArray = saleDate.startDate && saleDate.startDate.split("/");
        saleDate.startDate = new Date(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
      }
      if(!eDate && !(saleDate.endDate === null)){
        var endDateArray = saleDate.endDate && saleDate.endDate.split("/");
        saleDate.endDate = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
      }
    const start = moment(saleDate.startDate);
    const end = moment(saleDate.endDate);
    const diff = end.diff(start, 'day');
    return diff >= 0 || !saleDate.startDate || !saleDate.endDate;
  }

  const endDateValidation=()=>{
    const today = moment(saleDate.startDate);
    let eDate = saleDate.endDate instanceof Date;
    if(!eDate && !(saleDate.endDate === null)){
      var endDateArray = saleDate.endDate && saleDate.endDate.split("/");
      saleDate.endDate = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
    }
    const end = moment(saleDate.endDate);
    const diff = end.diff(today, 'minutes');
    return diff > -2
  }

  return (
    <div>
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/reports")}>
                  Reports
                </Link>
                <Typography component="span" color="textPrimary">
                  Supplier Purchase
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>
      <div style={{paddingTop:10}}>
        <form onSubmit={handleSubmit(handleSearch)}>
          <Grid container spacing={2} alignItems="center">
          <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Supplier Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={custNameRef}
                    InputProps={{
                      name: "custName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Start Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=''
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="startDate"
                    error={Boolean(errors.startDate)}
                    value={saleDate.startDate}
                    onChange={(value)=>handleDateChange(value,"startDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                    inputRef={register({
                      validate:(saleDate.startDate && saleDate.endDate)?()=> dateValidation() || 'Start date should be lesser than end date':""
                    })}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.startDate && errors.startDate.message}
            </FormHelperText>
              </Grid>
            </Grid>
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">End Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    name="endDate"
                    value={saleDate.endDate}
                    error={Boolean(errors.endDate)}
                    onChange={(value)=>handleDateChange(value,"endDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                    inputRef={register({
                      validate:(saleDate.startDate && saleDate.endDate)?()=> endDateValidation() || 'End date cannot be lesser than start date':""
                    })}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
              {errors.endDate && errors.endDate.message}
            </FormHelperText>
              </Grid>
            </Grid>
           
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Payment Type</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <Autocomplete
                  options={payTypes}
                  size="small"
                  getOptionLabel={(option) => option.description}
                  onChange={(e, val) => {
                    if (val) {
                      setPaymentTypeId(val.id);
                      setQuery({
                        ...query,
                        paymentTypeId: val.id,
                        page: 1,
                      });
                    }
                  }}
                  onInputChange = {(e,val)=>{
                    if(!val){
                      setPaymentTypeId(val);
                      setQuery({
                        ...query,
                        paymentTypeId: val,
                        page: 1,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Grid>
            </Grid>
           
            <Grid item container  justifyContent={"flex-end"}  md={12} xs={12}>
            <Box mt={2} mr={2}>
            <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Generate Report
            </Button>
            </Box>
            <Box mt={2} mr={2}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<MailIcon />}
                    onClick={handleOpenOrderMail}
                  >
                    Mail
                  </Button>
                </Box>
              <Box mt={2} mr={2}>
                <ReactToPrint
                    onBeforeGetContent={handlePrint}
                    trigger={() => <Button
                      size="small"
                      variant="contained"
                      color="primary"
                     startIcon={<PrintIcon />}

                    >
                      Print
    </Button>
    }
                    content={() => componentRef.current}
                  />
                 </Box>
                <Box mt={2} mr={2}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Download
            </Button></Box>
            <Box mt={2}>
                <Button size="small"
                  onClick={handleReset} variant="contained" color="secondary">
                  clear</Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Invoice No.</StyledHeaderCell>
                <StyledHeaderCell align="left">Supplier Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Invoice Date</StyledHeaderCell>
                <StyledHeaderCell align="right">Invoice Total</StyledHeaderCell>
                <StyledHeaderCell align="left">Payment Type</StyledHeaderCell>
                <StyledHeaderCell align="center">Mail</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row, index) => (
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                        <ReactToPrint 
                        onBeforeGetContent={()=>handlePrintInv(row.invoiceNum)}
                          trigger={() =>  <TableCell component="th" scope="row">
                          <Link >{row.invoiceNum}</Link>
                        </TableCell>}
                        content={() => componentRefInv.current}/>
                          <TableCell align="left">
                            {row.supplier && row.supplier.fullName}
                          </TableCell>
                          <TableCell align="left">
                            {row.invoiceDate && formatDate(row.invoiceDate)}
                          </TableCell>
                          <TableCell align="right">
                            {row.total && (Math.round(row.total * 100) / 100).toFixed(2)}
                          </TableCell>
                          <TableCell align="left">
                            {row.paymentType && row.paymentType.description}
                          </TableCell>
                          <TableCell align="center">
                            <span onClick={()=>openEmailInovice(row)}>
                            <MailIcon />
                            </span>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
        <Box display="none">
          <CompToPrint ref={componentRef} supplierPurchase={supplierPurchase} />
        </Box>
        <Box display="none">
          <CompToPrintInv ref={componentRefInv} supplierPurInv={supplierPurInv} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions}/>
        </Box>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
          <div />
        )}
         <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice || sendSale}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {sendSale ? "Mail Open Order" : "Mail Invoice"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                    onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{height:'180px'}}>
            <Grid container>
               <Grid xs={3}>
                <Typography className='recordSaleInvoiceLabel' style={{fontWeight:"bold"}} component="span" >Mail to *</Typography>
               </Grid>
               <Grid item md={9}>
                <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                value={emailId}
                onChange = {e => setEmailSendData({ ...emailSendData, emailId: e.target.value})}
                />
                {isEmailHelpertext&&<FormHelperText style={{color:'red'}}>
                  Enter valid email id
                </FormHelperText>}
              </Grid>
              </Grid>
              <Grid container style={{paddingTop:'10px'}}>
               <Grid xs={3}>
                <Typography className='recordSaleInvoiceLabel' style={{fontWeight:"bold"}} component="span">Subject *</Typography>
               </Grid>
               <Grid item md={9}>
                <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                value={emailSubject}
                onChange = {e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value})}
                />
                {isEmailSubjectHelpertext&&<FormHelperText style={{color:'red'}}>
                  Enter email subject
                </FormHelperText>}
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                onClick={onSendEmailInvoice}
                color="secondary"
                size="small"
                variant="contained"
              >
                Send
              </Button>
            </Box>    
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setIsSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message ? message : "Thanks for shopping!"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default SupplierPurchases;
