import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { formatDate } from '../common/util';
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  Typography,
  Box,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  FormControl,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloudUpload from '@material-ui/icons/CloudUpload';
import Pagination from "@material-ui/lab/Pagination";
import { currentUser } from "../login/loginSlice";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
// import { localities } from "../common/lookupSlice";

const Customer = (props) => {
  const isBillingAgent = props.isBillingAgent;
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  // const availCustLocalities = useSelector(localities);
  // const custLocalities = availCustLocalities ? availCustLocalities.filter(loc => loc !== "") : [];
  const pageSize = 10;
  const history = useHistory();
  // const location = useLocation();
  // const customerData = location.state && !(location.state.click);
  const [count, setCount] = useState();
  const [custForm, setCustForm] = useState({ data: {}, open: false });
  // const [searchLocality, setSearchLocality] = useState("");
  // const [deepMobileNumSearch, setDeepMobileNumSearch] = useState(false);
  const [data, setData] = useState([]);
  const [sliceData, setSliceData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const idRef = useRef();
  const bankNameRef = useRef();
  const acHolderNameRef = useRef();
  const branchNameRef = useRef();
  const token = localStorage.getItem("authToken");

  // var queryStringData = window.location.search;
  // var chars = queryStringData.split('=');
  // let isQuotation = (chars[1]) ? chars[1].toUpperCase() === "QUOTATION" : false;
  // let isPO = (chars[1]) ? chars[1].toUpperCase() === "PURCHASEORDER" : false;
  const resetQuery = () => {
    idRef.current.value = "";
    bankNameRef.current.value = "";
    acHolderNameRef.current.value = "";
    branchNameRef.current.value = "";
    // setSearchLocality("");
    // setDeepMobileNumSearch(false);
    setQuery({
      ...query,
      id: idRef.current.value,
      bankName: bankNameRef.current.value,
      acHolderName: acHolderNameRef.current.value,
      branchName: branchNameRef.current.value,
    });
  };

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    let queryCostCenter = {
      fields: '["name","owner","creation","modified","modified_by","_user_tags","_comments","_assign","_liked_by","docstatus","idx","import_file","status","reference_doctype","import_type"]',
      filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT }),
      limit_start: 0,
      limit_page_length: 10000,
      order_by: "modified DESC"
    }
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Bank Statement Import`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: queryCostCenter,
        }
      );
      let allData = result && result.data && result.data.data;
      setCount(allData && allData.length);
      setData(allData);
      let startIndex = (query.page * query.pageSize) - 10;
      let lastIndex = (query.page * query.pageSize);
      let dataSlice = allData.slice(startIndex, lastIndex)
      setSliceData(dataSlice);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data && data.length > 0) {
      let startIndex = (query.page * query.pageSize) - 10;
      let lastIndex = (query.page * query.pageSize);
      let dataSlice = data.slice(startIndex, lastIndex)
      setSliceData(dataSlice);
    }
  }, [query, data]);

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      id: e.target.elements.id.value,
      bankName: e.target.elements.bankName.value,
      acHolderName: e.target.elements.acHolderName.value,
      branchName: e.target.elements.branchName.value,
      page: 1,
    });
  };

  // On success of add or edit, reset the query to load data again
  useEffect(() => {
    if (custForm.success) {
      resetQuery();
    }
  }, [custForm.success]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/accountshome")}>
                Accounting
              </Link>
              <Typography component="span" color="textPrimary">
                Bank Statement Upload
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >

        </Grid>
      </Grid>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Typography variant={props.right ? "h7" : "h6"}>
            Bank Statement Upload
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item style={{ display: "none" }}>
            {!isBillingAgent && <Button
              color="primary"
              variant="contained"
              onClick={() => { history.push("/bankaccountconfig") }}
            >
              Add Bank Account
            </Button>}
          </Grid>
          <Grid item>
            {(!isBillingAgent && !isAuditor)&& <Button
              color="primary"
              variant="contained"
              onClick={() => { history.push("/uploadbanktransaction") }}
              startIcon={<CloudUpload />}
            >
              Upload File
            </Button>}
          </Grid>
        </Grid>
      </Grid>
      <div style={{ display: "none" }}>
        <Typography variant={props.right ? "h7" : "h6"}>
          {props.modal ? "Bank Statement Selection" : "Bank Statement Search"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Bank Statement Upload Id
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={idRef}
                    InputProps={{
                      name: "id",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Bank Name
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={bankNameRef}
                    InputProps={{
                      name: "bankName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  A/C Holder Name
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={acHolderNameRef}
                    InputProps={{
                      name: "acHolderName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption" component="span">
                  Branch Name
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={branchNameRef}
                    InputProps={{
                      name: "branchName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            {(props.modal) ? <Grid item container md={props.modal || props.right ? 4 : 2} xs={6}>
              <Grid item style={{ marginTop: '20px' }} md={12} xs={12}>
                <Button size="small" type="submit" variant="contained" color="primary">
                  Search</Button>
                <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                  onClick={() => resetQuery()} variant="contained" color="secondary">
                  clear</Button>
              </Grid>
            </Grid> :

              <Grid item container justifyContent={"flex-end"} md={2} xs={6}>
                <Box mt={2} mr={0}>
                  <Button size="small" type="submit" variant="contained" color="primary">
                    Search</Button>
                </Box>
                <Box mt={2} mr={0}>
                  <Button size="small" style={{ marginLeft: '20px' }} type="reset"
                    onClick={() => resetQuery()} variant="contained" color="secondary">
                    clear</Button>
                </Box>
              </Grid>}

          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>SL No.</StyledHeaderCell>
                <StyledHeaderCell align="left">File Upload Name</StyledHeaderCell>
                {/* <StyledHeaderCell align="left">Document Type</StyledHeaderCell> */}
                <StyledHeaderCell align="left">Created Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Status</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={props.modal || props.right ? 5 : 6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {sliceData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={props.modal ? 5 : 6} align="center">
                      <Typography variant="subtitle1" component="span">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sliceData.map((row, index) => {
                    return (
                      <TableRow
                        style={{
                          cursor: props.modal || props.right ? "pointer" : "",
                          whiteSpace: 'normal',
                          wordBreak: 'break-word'
                        }}
                        key={row.id}
                        hover={true}
                        onClick={(e) =>
                          props.onRowSelect && props.onRowSelect(row)
                        }
                      >
                        <TableCell component="th" scope="row">{(query.page) ? ((Number(query.page * query.pageSize) - pageSize) + 1) + Number(index) : ""}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        {/* <TableCell align="left">{row.reference_doctype}</TableCell> */}
                        <TableCell align="left"> {row.creation && formatDate(row.creation)}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {sliceData && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={custForm.success}
        onClose={() => setCustForm({ ...custForm, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCustForm({ ...custForm, success: false })}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {custForm.mode === "C"
            ? "Customer created successfully"
            : "Customer updated successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Customer;
